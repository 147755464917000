import constants from '../constants/app-constants';

const initialState = {
  loginCtx: null
};


const LoginCtx = (state = initialState , action) => {
  switch(action.type) {
  case constants.auth.AUTH_LOGIN: {
    return {...state , loginCtx: action.payload};
  }

  default: {
    return state;
  }
  
  }
};

export default LoginCtx;