import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as demoStoreActions from '../../../../actions/admin/demoStoreActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import CampaignDropbox from '../../../shared/dropbox/CampaignDropbox';
import CampaignVersionDropbox from '../../../shared/dropbox/CampaignVersionDropbox';
import EnvironmentDropbox from '../../../shared/dropbox/EnvironmentDropbox';

@connect((store) => {
  return {
    demoStoreGet: store.adminDemoStore.demoStoreGetById,
    demoStoreEdit: store.adminDemoStore.demoStoreEdit
  };
})

class DemoStoreEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      demoStoreDetails = {
        _id: '',
        clientId: '',
        clientName: '',
        environment: '',
        notes: '',
        storeName: '',
        storeLink: '',
        campaignId: '',
        campaignName: '',
        campaignVersionId: '',
        campaignVersionName: '',
        country: '',
        firstNameAvailable: '',
        emailAvailable: ''
      }
    } = props;

    this.state = {
      demoStoreDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      demoStoreId: ''
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onCampaignChange = this.onCampaignChange.bind(this);
    this.onCampaignVersionChange = this.onCampaignVersionChange.bind(this);
    this.onEnvironmentChange = this.onEnvironmentChange.bind(this);
  }

  componentDidMount() {
    var demoStoreId = this.props.params.demoStoreId;
    this.setState({ demoStoreId });
    if(demoStoreId){
      this.props.dispatch(demoStoreActions.getDemoStore(demoStoreId));
    }

    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New demo store added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.demoStoreGet) {
      this.setState({ demoStoreDetails : nextProps.demoStoreGet });
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.demoStoreEdit !== nextProps.demoStoreEdit){
      if(_.get(nextProps,'demoStoreEdit')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Demo Store saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'demoStoreEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.demoStoreEdit.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onClientChange(e){

    const { demoStoreDetails } = this.state;

    if (e.value && e.value !== null) {
      _.set(demoStoreDetails, 'clientId', e.value);
      _.set(demoStoreDetails, 'clientName', e.originalEvent.target.innerText);
      _.set(demoStoreDetails, 'campaignId', null);
      _.set(demoStoreDetails, 'campaignName', '');
      _.set(demoStoreDetails, 'campaignVersionId', null);
    } else {
      _.set(demoStoreDetails, 'clientId', '');
      _.set(demoStoreDetails, 'clientName', '');
      _.set(demoStoreDetails, 'campaignId', null);
      _.set(demoStoreDetails, 'campaignName', '');
      _.set(demoStoreDetails, 'campaignVersionId', null);
      _.set(demoStoreDetails, 'campaignVersionName', '');
    }

    this.setState({ demoStoreDetails });
  }

  onCampaignChange(e){
    const {demoStoreDetails} = this.state;
    _.set(demoStoreDetails, 'campaignId', e.value);
    _.set(demoStoreDetails, 'campaignName', e.originalEvent.target.innerText);
    _.set(demoStoreDetails, 'campaignVersionId', null);
    _.set(demoStoreDetails, 'campaignVersionName', '');
    this.setState({demoStoreDetails});
  }

  onCampaignVersionChange(e){
    const {demoStoreDetails} = this.state;
    _.set(demoStoreDetails, 'campaignVersionId', e.value);
    _.set(demoStoreDetails, 'campaignVersionName', e.originalEvent.target.innerText);
    this.setState({demoStoreDetails});
  }

  onChange(name, { target }) {
    const { demoStoreDetails } = this.state;
    _.set(demoStoreDetails, name, target.value);
    this.setState({ demoStoreDetails });
  }

  onEnvironmentChange(e) {
    const { demoStoreDetails } = this.state;
    _.set(demoStoreDetails, 'environment', e.value);
    this.setState({ demoStoreDetails });
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { demoStoreDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }


    if( !checkErrors ){
      demoStoreDetails.updatedAt = new Date();
      this.props.dispatch(demoStoreActions.updateDemoStore(demoStoreDetails));
    }

    console.log(errors); //for debugging left in for internal use only as it is an admin tool.

  }

  validateForm() {
    const { demoStoreDetails, errors } = this.state;

    errors[ 'storeLink' ] = validator.isEmpty(demoStoreDetails.storeLink);
    errors[ 'clientId' ] = validator.isEmpty(demoStoreDetails.clientId);
    errors[ 'campaingId' ] = false;
    errors[ 'campaignVersionId' ] = false;
    errors[ 'country' ] = false;
    errors[ 'firstNameAvailable' ] = validator.isEmpty(demoStoreDetails.firstNameAvailable);
    errors[ 'emailAvailable' ] = validator.isEmpty(demoStoreDetails.emailAvailable);
    errors[ 'environment' ] = validator.isEmpty(demoStoreDetails.environment);

    return this.setState({ errors });
  }

  render (){
    const { demoStoreDetails = { info: {} }, errors } = this.state;

    const {
      clientId,
      campaignId,
      campaignVersionId,
      storeName,
      environment,
      notes,
      storeLink,
      country,
      firstNameAvailable,
      emailAvailable
    } = demoStoreDetails;

    return (
      <div className="campaign-new-campaign">
        <h3>Edit demo store</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Store Name'
            name='storeName'
            type='text'
            value={storeName}
            error={errors.storeName}
            id='form-storeName'
            required
            disabled
          />

          <FormElement
            label='Store Link'
            name='storeLink'
            type='input'
            onChange={this.onChange.bind(this, 'storeLink')}
            value={storeLink}
            error={errors.storeLink}
            id='form-storeLink'
            required
          />

          <ClientsDropbox
            label="Client"
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId}
            value={clientId}
          />

          <FormElement
            label='Client ID'
            name='clientId'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors.clientId}
            id='form-clientId'
            required
            disabled='true'
          />

          <CampaignDropbox
            client={this.state.demoStoreDetails.clientId}
            onChange={this.onCampaignChange}
            error={errors.campaignId}
            value={campaignId}
          />

          <CampaignVersionDropbox
            campaign={this.state.demoStoreDetails.campaignId}
            onChange={this.onCampaignVersionChange}
            error={errors.campaignVersionId}
            value={campaignVersionId}
          />

          <FormElement
            label='Country'
            name='country'
            type='select'
            onChange={this.onChange.bind(this, 'country')}
            value={country}
            error={errors.country}
            id='form-country'
          >
            <option value=""> </option>
            <option value="GB">GB</option>
            <option value="IE">IE</option>
            <option value="FR">FR</option>
            <option value="DE">DE</option>
          </FormElement>

          <FormElement
            label='Email available'
            name='emailAvailable'
            type='select'
            onChange={this.onChange.bind(this, 'emailAvailable')}
            value={emailAvailable}
            error={errors.emailAvailable}
            id='form-email-available'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='First name available'
            name='firstNameAvailable'
            type='select'
            onChange={this.onChange.bind(this, 'firstNameAvailable')}
            value={firstNameAvailable}
            error={errors.firstNameAvailable}
            id='form-first-name-available'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <EnvironmentDropbox
            label="Environment"
            name='environment'
            required
            value={environment}
            onChange={ (e) => this.onEnvironmentChange(e) }
            error={errors.environment}
          />

          <FormElement
            label='Notes'
            name='notes'
            type='textArea'
            onChange={this.onChange.bind(this, 'notes')}
            value={notes}
            error={errors.notes}
            id='form-notes'
          />

        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Save</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/demoStore')}>
          Back
        </button>

      </div>
    );
  }
}

export default DemoStoreEdit;