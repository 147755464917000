import AssociateEmailToCampaignVersionList from './List';
import AssociateEmailToCampaignVersionCreate from './Create';
import AssociateEmailToCampaignVersionEdit from './Edit';

const AssociateEmailToCampaignVersionIndex = {
  AssociateEmailToCampaignVersionList, 
  AssociateEmailToCampaignVersionCreate,
  AssociateEmailToCampaignVersionEdit
};

export default AssociateEmailToCampaignVersionIndex;