import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const EmailTemplateType = (state = initialState , action) => {
  switch(action.type){
  
  case constants.admin.EMAIL_TEMPLATE_TYPE_GET_ALL: {
    return {...state, getAll: action.payload};
  }

  case constants.admin.EMAIL_TEMPLATE_TYPE_GET_BY_ID: {
    return {...state, getById: action.payload};
  }

  case constants.admin.EMAIL_TEMPLATE_TYPE_EDIT: {
    return {...state, edit: action.payload};
  }

  case constants.admin.EMAIL_TEMPLATE_TYPE_NEW: {
    return {...state, new: action.payload};
  }

  default: return state;

  }
};

export default EmailTemplateType;
