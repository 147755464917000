import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const CampaignVersion = (state = initialState , action) => {
  
  switch(action.type){

  case constants.admin.CAMPAIGN_VERSION_NEW : 
    return {...state , campaignVersionNew : action.payload};

  case constants.admin.CAMPAIGN_VERSION_GET: {
    return {...state, campaignVersionGet: action.payload};
  }

  case constants.admin.CAMPAIGN_VERSION_EDIT: {
    return {...state, campaignVersionEdit: action.payload};
  }

  case constants.admin.CAMPAIGN_VERSION_LIST_BY_CAMPAIGN_ID_GET: {
    return {...state, campaignVersionListByCampaignIdGet: action.payload};
  }
    
  case constants.admin.CAMPAIGN_VERSION_LIST_EDIT: {
    return {...state, campaignVersionListEdit: action.payload};
  }

  case constants.admin.CAMPAIGN_VERSION_COPY: {
    return {...state, campaignVersionCopy: action.payload};
  }
  default: 
    return state;
  }
};

export default CampaignVersion;