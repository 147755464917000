import React from 'react';

class SettingsReferrer extends React.Component{
  
  render(){
    return (
      <div>Referrer</div>
    );
  }
}

export default SettingsReferrer;