import React from 'react';
import FormElement from '../form/FormElement';

const dateRange = ({ startDate, endDate, onChangeStartDate, onChangeEndDate }) => {
  const calendarStyle = {
    marginLeft: '5px',
    width: '172px',
    top: '-12px',
    position: 'relative',
    border: '1px solid #cccccc'
  };

  return (
    <div className="ui-g">
      <div className="ui-g-5 ui-md-2">
        <span>Date range:</span>
      </div>
      <div className="ui-g-10 ui-md-2">
        <FormElement
          name="dateFrom"
          type="calendar"
          onChange={e => onChangeStartDate(e)}
          value={startDate}
          id="dateFrom"
          required
          style={calendarStyle}
        />
      </div>
      <div className="ui-g-10 ui-md-2">
        <FormElement
          name="dateTo"
          type="calendar"
          onChange={e => onChangeEndDate(e)}
          value={endDate}
          id="dateTo"
          required
          style={calendarStyle}
        />
      </div>
    </div>
  );
};

export default dateRange;
