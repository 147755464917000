import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Panel } from 'primereact/components/panel/Panel';
import moment from 'moment';

class SharedUrlPanel extends React.Component {
  constructor(props) {
    super(props);

    for (let row of this.props.list) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
    }

    this.state = {
      list: this.props.list
    };
  }

  render = () =>
    <div>
      <Panel header={<span style={{color: '#485465'}} >Shared Urls</span>}>
        <DataTable
          value={this.state.list}
          responsive={true}
          sortMode="multiple"
          lazy={true}
          ref={(el) => this.dt = el}
        >
          <Column field="shortUrlId" header="Id" style={{textOverflow:'ellipsis', display: process.env.NODE_ENV === 'development' ? '' : 'none'}} />
          <Column field="createdAt" header="Date" />
          <Column field="type" header="Type" />
          <Column field="shortUrl" header="Short Url" />
          <Column field="socialPlatform" header="Social Platform" />
        </DataTable>
      </Panel>
    </div>
}

export default SharedUrlPanel;
