import React from 'react';
import Slider from'react-slick';
import _ from 'lodash';

const settings = {
  dots: true,
  autoplaySpeed: 5000,
  autoplay: true
};

const Slides = (props) => {
  console.log('props' , props);
  return (
    <Slider {...settings}>
      {
        _.map(props.slides , function(slide){
          return(
            <div key={slide.id} className="onboarding-slide-item text-center" style={{
              backgroundSize: 'cover',
              backgroundImage: slide.backgroundImage,
              height: '400px',
            }}>
              <div className="onboarding-overlay"></div>
              {
                slide.logo ?
                  <div className="image-holder">
                    <img src="/images/onBoarding/boarding-logo.png" alt="Soreto"/>
                  </div>
                  :
                  false
              }

              <p className="title">{slide.title}</p>
              <p className="onboarding-text">{slide.text}</p>
              <div className="button-holder">
                <button onClick={props.closeOnBoarding} className="primary">Close</button>
              </div>
            </div>
          );
        })
      }
    </Slider>
  );
}

export default Slides;