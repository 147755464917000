import constants from '../../constants/app-constants';

const initialState = {

};

const Layout = (state = initialState , action) => {
  switch(action.type) {

    case constants.admin.LAYOUT_COUNTRIES_GET: {
      return { ...state, layoutCountriesGet: action.payload };
    }
    case constants.admin.LAYOUT_COUNTRY_GET_BY_ID: {
      return { ...state, layoutCountryGetById: action.payload };
    }
    case constants.admin.LAYOUT_COUNTRY_SET: {
      return { ...state, layoutCountrySet: action.payload };
    }

    default: return state;
  }
};

export default Layout;