import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import moment from 'moment';

import CountryDataTable from '../campaign/countryDataTable';

import clientService from '../../../../../../utils/services/client';
import config from '../../../../../../config/config';

class ClientDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      expandedRows: [],
      searchField: this.props.searchField,
      ignoreArchived: this.props.ignoreArchived,
      first: 0
    };
  }

  getQuery = e => {
    const query = {
      $offset: e.first,
      $limit: e.rows
    };
    if (this.state.searchField) query.$search = this.state.searchField;

    return query;
  }

  getExtendedQuery = e => {
    var extendedQuery = '?';
    if (e.multiSortMeta != null && e.multiSortMeta.length > 0) {
      for (let sortMeta of e.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-'}${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  setSearch = (search) => {
    const { searchField, ignoreArchived, campaignType } = search;
    this.setState({ searchField: searchField, ignoreArchived: ignoreArchived, campaignType, first: 0 }, () => this.onLazyLoad({}));
  }

  editAction = rowData => <span className="fa fa-cog" style={{ cursor: 'pointer' }} onClick={() => {
    window.open(`${config.URL.BASEFRONT}/admin/client/${rowData._id}`, '_blank');
  }} />
  
  openGlobalVarIcon = rowData => <span className="fa fa-sliders" style={{ cursor: 'pointer' }} onClick={() => {
    window.open(`${config.URL.BASEFRONT}/admin/client/${rowData._id}/configuration`, '_blank');
  }} />

  openCountryCode = rowData => <span className="fa fa-map-signs" style={{ cursor: 'pointer' }} onClick={() => {
    window.open(`${config.URL.BASEFRONT}/admin/countryCode?clientId=${rowData._id}`, '_blank');
  }} />

  rowExpansionTemplate = data => <CountryDataTable clientId={data._id} ignoreArchived={this.state.ignoreArchived} campaignType={this.state.campaignType}/>

  onLazyLoad = (e) => {
    this.state.first = isNaN(e.first) ? 0 : e.first;

    this.setState({ loading: true});

    clientService.getClientPage(this.getQuery(e), this.getExtendedQuery(e))
      .then(response => this.setState({
        list: response.data.page.map(this.format),
        totalRecords: response.data.totalCount,
        loading: false
      }))
      .then(() => {
        if (this.state.list.length === 1) {
          this.setState({ expandedRows: [this.state.list[0]] });
        }
      });
  }

  statusTemplate = (rowData) => {

    return (<i class="fa fa-circle" style ={{ color: (rowData.active) ? 'green' : 'red' }} aria-hidden="true"></i>);
  }

  format = client => ({
    ...client,
    createdAt: moment(client.createdAt).format('YYYY-MM-DD'),
    updatedAt: moment(client.updatedAt).format('YYYY-MM-DD'),

  });

  render = () =>
    <DataTable
      value={this.state.list}
      responsive={true}
      sortMode="multiple"
      paginator={true} rows={10}
      rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
      totalRecords={this.state.totalRecords}
      lazy={true}
      onLazyLoad={this.onLazyLoad}
      loading={this.state.loading}
      ref={(el) => this.dt = el}
      onRowToggle={e => this.setState({ expandedRows: e.data })}
      rowExpansionTemplate={(e) => this.rowExpansionTemplate(e)}
      expandedRows={this.state.expandedRows}
      first = {this.state.first}

    >
      <Column expander={true} style={{ width: '45px' }} />
      <Column field="_id" header="ID" />
      <Column field="name" header="Client" sortable={true} />
      <Column field="countryName" header="Country" sortable={true} style={{ width: '160px' }} />
      <Column field="createdAt" header="Created" sortable={true} />
      <Column field="updatedAt" header="Updated" sortable={true} />
      <Column field="active" header="Active" body={this.statusTemplate} style={{ width: '60px' }} sortable={true} />
      <Column body={this.openCountryCode} style={{ textAlign: 'center', width: '50px' }} />
      <Column body={this.openGlobalVarIcon} style={{ textAlign: 'center', width: '50px' }} />
      <Column body={this.editAction} style={{ textAlign: 'center', width: '50px' }} />
    </DataTable>
}

export default ClientDataTable;