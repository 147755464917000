import rewardPoolService from '../../../../../../utils/services/rewardPool';
import rewardService from '../../../../../../utils/services/reward';
import discountService from '../../../../../../utils/services/rewardDiscountCode';
import _ from 'lodash';

const rewardNames = new Map([
  ['advocatePreConversionRewardId', 'Sharer pre-conversion reward'],
  ['advocatePostConversionRewardId', 'Sharer post-conversion reward'],
  ['refereeRewardId', 'Friend pre-conversion reward'],
  ['friendPostRewardId', 'Friend post-conversion reward'],
]);

const getDiscountCode = rewardId  => discountService.getValidRewardDiscountCode(rewardId)
  .then((response) => response && response.data && response.data[0])
  .then(discountCode => _.pick(discountCode, ['_id', 'discountType', 'valueAmount', 'code']));

const withDiscountCode = reward => {
  if(reward.type ==='batch-discount'){
    return ({...reward, discountCode: {valueAmount: '-', code: '-'}});
  }

  return getDiscountCode(reward._id).then(discountCode => ({...reward, discountCode: discountCode}));
};

const getReward = (rewardId, type) => rewardService.getReward(rewardId)
  .then(response => response.data)
  .then(reward => _.pick(reward, ['_id', 'name', 'type', 'rewardType','meta', 'giftCardReward']))
  .then(withDiscountCode)
  .then(reward => ({...reward, rewardType: rewardNames.get(type)}));

const getRewardPool = rewardPoolId => rewardPoolService.getRewardPool(rewardPoolId)
  .then(response => response.data);

const getRewardsData = rewardPool => {

  const getRewardPromises = [];

  const  rewardIds = _.pick(rewardPool,['advocatePreConversionRewardId', 'advocatePostConversionRewardId', 'refereeRewardId', 'friendPostRewardId']);

  for(const key in rewardIds){
    const id = rewardIds[key];
    if (id){
      getRewardPromises.push(getReward(id, key));
    }
  }

  return Promise.all(getRewardPromises).then(values => ({rewardPoolId: rewardPool._id, rewardPoolName: rewardPool.name, rewards: [...values]}));
};

export const getData = rewardPoolId => getRewardPool(rewardPoolId).then(getRewardsData);