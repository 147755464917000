import campaignService from '../../utils/services/campaign';
import constants from '../../constants/app-constants';


function timezoneOffsetIn(dateString){
  var dateIn = new Date(dateString);
  dateIn.setTime(dateIn.getTime() - dateIn.getTimezoneOffset()*60*1000);
  return dateIn;
}

function timezoneOffsetOut(dateString){
  var dateIn = new Date(dateString);
  dateIn.setTime(dateIn.getTime() + dateIn.getTimezoneOffset()*60*1000);
  return dateIn;
}


export const newCampaign = clientObj => dispatch => {
  return new Promise((resolve, reject) => {
    
    let dataIn = { ...clientObj};

    dataIn.expiry = timezoneOffsetIn(dataIn.expiry);
    dataIn.startDate = timezoneOffsetIn(dataIn.startDate);
    
    campaignService.newCampaign(dataIn)
      .then(res => {
        dispatch({
          type: constants.admin.CAMPAIGN_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.CAMPAIGN_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateCampaign = (clientObj) => {
  return function(dispatch){

    let dataIn = { ...clientObj};
    dataIn.expiry = timezoneOffsetIn(dataIn.expiry);
    dataIn.startDate = timezoneOffsetIn(dataIn.startDate);

    campaignService.updateCampaign(dataIn)
      .then(function(res){
        dispatch({
          type: constants.admin.CAMPAIGN_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_EDIT,
          payload: err.response
        });
      });
  };
};

export const getCampaign = (campaignId) => {
  return function(dispatch){
    campaignService.getCampaign(campaignId)
      .then(function(res){
        let dataOut = { ...res.data};
        dataOut.expiry = timezoneOffsetOut(dataOut.expiry);
        dataOut.startDate = timezoneOffsetOut(dataOut.startDate);

        dispatch({
          type: constants.admin.CAMPAIGN_GET,
          payload: dataOut
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_GET,
          payload: err.response
        });
      });
  };
};

export const copyCampaign = (clientObj) => {
  return function(dispatch){
    campaignService.copyCampaign(clientObj)
      .then(function(res){
        dispatch({
          type: constants.admin.CAMPAIGN_COPY,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_COPY,
          payload: err.response
        });
      });
  };
};