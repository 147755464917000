import Activity from './pages/activity/Activity';
import Analytics from './pages/analytics/Analytics';
import  AnalyticsBi  from './pages/analytics/AnalyticsBi';
import ByChannel from './pages/byChannel/ByChannel';
import ByChannelBi from './pages/byChannel/ByChannelBi';
import Dashboard from './pages/dashboard/Dashboard';
import UserData from './pages/userData/UserData';
import Layout from './layout/Layout';
import Reports from './pages/reports/Reports';
import DailyTractionReport from './pages/reports/dailyTractionReport/DailyTractionReport';
import ProductTractionReport from './pages/reports/productTractionReport/ProductTractionReport';
import Settings from './pages/settings/Settings';
import SettingsCompany from './pages/settings/company/Settings.Company';
import SettingsCommission from './pages/settings/commission/Settings.Commission';
import SettingsReferrer from './pages/settings/referrer/Settings.Referrer';
import SettingsAssociatedManagers from './pages/settings/associatedManagers/Settings.AssociatedManagers';
import SettingsSharePopupCustom from './pages/settings/sharePopup/Settings.SharePopupCustom';
import SettingsCustomTags from './pages/settings/customTags/Settings.CustomTags';

import React from 'react';
import { Route, IndexRedirect, withRouter } from 'react-router';

const clientRoutes = (
  <Route path="/client" component={withRouter(Layout)}>
    <IndexRedirect to={'/client/analyticsBi'} />
    <Route path="dashboard" component={withRouter(Dashboard)} />
    <Route path="reports" component={Reports}>
      <IndexRedirect to={'/client/reports/producttractionreport'} />
      <Route path="dailytractionreport" component={DailyTractionReport} />
      <Route path="producttractionreport" component={ProductTractionReport} />
    </Route>
    <Route path="settings" component={Settings}>
      <IndexRedirect to={'/client/settings/company'} />
      <Route path="company" component={SettingsCompany} />
      <Route path="commission" component={SettingsCommission} />
      <Route path="referrer" component={SettingsReferrer} />
      <Route path="associatedmanagers" component={SettingsAssociatedManagers} />
      <Route path="sharepopupcustom" component={SettingsSharePopupCustom} />
      <Route path="customtags" component={SettingsCustomTags} />
    </Route>
    <Route path="analytics" component={Analytics} index={1} />
    <Route path="analyticsBi" component={AnalyticsBi} index={2} />

    <Route path="byChannel" component={ByChannel} index={1} />
    <Route path="byChannelBi" component={ByChannelBi} index={1} />
    <Route path="activity" component={Activity} />
    <Route path="userData" component={UserData} index={1} />
  </Route>
);

export default clientRoutes;
