import React from 'react';
import RetailerListing from './RetailerListing';
import {connect} from 'react-redux';
import * as retailerActions from '../../../../actions/user/retailerActions';

@connect((store)=>{
  return {
    retailers: store.userRetailers.retailers
  };
})

class Retailers extends React.Component{
  componentWillMount(){
    this.props.dispatch(retailerActions.getRetailers());
  }

  render(){
    return(
      <div className="grid-container">
        <h1 className="text-center">Brands</h1>
        <RetailerListing retailers={this.props.retailers}  />
      </div>
    );
  }
}

export default Retailers;