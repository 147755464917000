import clientService from '../../utils/services/client';
import constants from '../../constants/app-constants';

const updateClientHelper = (clientId, clientObj, dispatch) => {
  clientService.updateClient(clientId, clientObj)
    .then(function(res){
      dispatch({
        type: constants.admin.CLIENT_EDIT,
        payload: res.data
      });
    })
    .catch(function(err){
      dispatch({
        type: constants.admin.CLIENT_EDIT,
        payload: err.response
      });
    });
};

export const getClient = (clientId) => {
  return function(dispatch){
    clientService.getClient(clientId)
      .then(function(res){
        dispatch({
          type: constants.admin.CLIENTOBJ_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CLIENTOBJ_GET,
          payload: err.response
        });
      });
  };
};

export const getClients = () => {
  return function(dispatch){
    clientService.getAllClients()
      .then(function(res){
        dispatch({
          type: constants.admin.CLIENTS_GET,
          payload: res.data
        });
      })
      .catch(function(){

      });
  };
};

export const createNewClient = (clientObj, dispatch) => {
  return new Promise((resolve, reject) => {
    clientService.newClient(clientObj)
      .then(res => {
        dispatch({
          type: constants.admin.CLIENT_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.CLIENT_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const newClient = clientObj => dispatch => {
  if(clientObj.imageData){
    return clientService.uploadImage(clientObj.imageData).then(res => {
      const imageId = res.data.fileId;
      clientObj.imageId = imageId;
      return createNewClient(clientObj, dispatch);

    });
  }else return createNewClient(clientObj, dispatch);
};

export const updateClient = (clientId, clientObj) => {
  return function(dispatch){
    if (clientObj.imageData) {
      clientService.uploadImage(clientObj.imageData) 
        .then(function(res) {
          delete clientObj.imageData;
          const imageId = res.data.fileId;
          clientObj.imageId = imageId;

          updateClientHelper(clientId, clientObj, dispatch); 
        });
    }
    else {
      updateClientHelper(clientId, clientObj, dispatch);
    }   
  };
};

export const updateClientStatus = (clientId,disableObj) => {
  return function(dispatch) {
    clientService.setClientStatus(clientId, disableObj)
      .then((res) => {
        clientService.getAllClients()
          .then(function(res){
            dispatch({
              type: constants.admin.CLIENTS_GET,
              payload: res.data
            });
          })
          .catch(function(){
        
          });
      });
  };
};

export const updateClientRank = (clientId,rank) => {
  return function(dispatch) {
    clientService.setClientRank(clientId, rank)
      .then((res) => {
        clientService.getAllClients()
          .then(function(res){
            dispatch({
              type: constants.admin.CLIENTS_GET,
              payload: res.data
            });
          })
          .catch(function(){

          });
      });
  };
};

export const updateClientMerchantAssoc = (clientId,payload) => {
  return function(dispatch) {
    clientService.updateClientMerchantAssoc(clientId, payload)
      .then((res) => {
        clientService.getAllClients()
          .then(function(res){
            dispatch({
              type: constants.admin.CLIENTS_GET,
              payload: res.data
            });
          })
          .catch(function(){

          });
      });
  };
};
