import constants from '../../constants/app-constants';

const initialState = {
  sharedUrls : [],
  sharedUrlsClicks : [],
  earningsPerSocialPlatform: [],
  modalIsOpen : false,
  userWallet : null
}

const UserDashboard = (state = initialState , action) => {
  switch(action.type){
  case constants.user.SHAREDURLS_GET: {
    return {...state , sharedUrls: action.payload};
  }

  case constants.user.SHAREDURLS_COUNTCLICKS: {
    return {...state, sharedUrlsClicks: action.payload};
  }

  case constants.user.DASHBOARD_EARNINGSPERPLATFORM: {
    return {...state , earningsPerSocialPlatform: action.payload};
  }

  default: {
    return state;
  }
  }
}

export default UserDashboard;