import request from '../wrappers/axios';
import config from '../../config/config';

class EnvironmentService {

  getEnvironmentList(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/environment/list${extendedQuery}`,
      method: 'get',
      params: query
    });
  }
}

export default new EnvironmentService();