import React from 'react';
import config from '../../../../config/config';
import {authService} from '../../../../utils/services/auth';
import trackingService from '../../../../utils/services/tracking';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import {connect} from 'react-redux';
import GlobalMessages from '../../../shared/globalMessage/GlobalMessage';

@connect()

class AccountVerify extends React.Component {
  constructor(){
    super();
    this.state = {
      verified: false
    };

    this.goToPage = this.goToPage.bind(this);
  }

  componentWillMount () {
    
    this.setState({
      verificationMessage: 'Verifying...'
    },function(){

      this.verify();     

    });

  }

  verify () {
    var that = this;
    var query = this.props.location.query;
    var userId = query.userId;
    var token = query.token;

    authService.verify(userId, token, null).then(() => {

      trackingService.accountVerify(userId);

      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'success',
          message: 'Your account has been verified',
          timeout: 10000
        }
      ));
      that.setState({
        verified: true
      });
    }).catch((err) => {
      that.setState({
        verificationMessage: err.response.data.message
      });
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: err.response.data.message,
          timeout: 10000
        }
      ));
    });
  }

  goToPage(page){

    if(page == '/login'){
      window.location.href = config.MARKETPLACE.URL;
      return;
    }

    this.props.router.push({ pathname: page });
  }

  render () {
    return(
      <div className="front-page grid-container grid-parent">
        
        <div className="grid-100 front-page-mid-section grid-parent">
          <GlobalMessages message={this.props.globalMessage}/>

          <div className="grid-100 front-page-form-holder text-center">
            <div className="soreto__logo"></div>

            {
              this.state.verified ?
                <div className="form-container" style={{height:'auto'}}>
                  <h2>Account Activation</h2>
                  <p className="top-text">Your account has been verified</p>
                  <div>
                    <button onClick={() => this.goToPage('/login')} className="login--btn">LOG IN</button>
                  </div>
                </div>
                :
                <div>
                  <h2>Account Activation</h2>
                  <p className="top-text">{ this.state.verificationMessage }</p>
                </div>
            }
       
          </div>
        </div>
      </div>      
    );
  }
}

export default AccountVerify;