import constants from '../../constants/app-constants';

const initialState = {
  clients: [],
}

const AdminDashboard = (state = initialState , action) => {
  switch(action.type){
    case constants.admin.CLIENTS_GET: {
      return {
        ...state,
        clients: action.payload
      };
    }

    case constants.admin.CLIENTOBJ_UPDATE_STATUS: {
      return {
        ...state,
        clients: state.clients
      };
    }


    default: {
      return state;
    }
  }
}

export default AdminDashboard;