import constants from '../../constants/app-constants';

const initialState = {
  headerMenuVisible: ''
};

const AdminHeader = (state = initialState , action) => {
  switch(action.type){

  case constants.user.HEADER_TOGGLEMENU : {
    return {...state, headerMenuVisible: action.payload };
  }

  default:
    return state;
  }
};

export default AdminHeader;