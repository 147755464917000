import request from '../wrappers/axios';
import config from '../../config/config';

class adminService {


  updateWithdrawalRequest(userId , requestId , data){
    return request({
      url: config.API.BASEURL + `/users/${userId}/withdrawals/${requestId}`,
      method: 'put',
      data: data
    });
  }

  getUserPaymentRequests(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/paymentrequests/process${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getUserPaymentRequestsCount(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/paymentrequests/count${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

}

export default new adminService;