import { isNumber } from 'lodash';
import moment from 'moment';

export class QueryHelper {
  constructor() {}

  /**
   * Use this query for everything related with Client Stats
   */
  getQuery(event, usePreviousDate = false, self) {
    var first = self.state.first;
    var rows = self.state.rows;

    if (isNumber(event.first)) {
      first = event.first;
      self.setState({ first: first });
    }

    if (isNumber(event.rows)) {
      rows = event.rows;
      self.setState({ rows: rows });
    }

    var query = {
      $offset: first,
      $limit: rows
    };

    if (self.state.searchField && self.state.searchField != '') {
      var value = { $search: self.state.searchField };
      query = Object.assign(query, value);
    }

    if (!self.state.showDaysWithoutSales) {
      query = Object.assign(query, { $countSoretoSales_$gt: 0 });
    }

    if (!self.state.showDaysWithoutClicks) {
      query = Object.assign(query, { $clicks_$gt: 0 });
    }

    if (!self.state.showDaysWithoutShares) {
      query = Object.assign(query, { $shares_$gt: 0 });
    }

    var gteDate = 0;
    var lteDate = 0;

    if (usePreviousDate) {
      gteDate = self.state.previousStartDate
        ? { $date_$gte: moment(self.state.previousStartDate).format('YYYY-MM-DD') }
        : {};
      lteDate = self.state.previousEndDate
        ? { $date_$lte: moment(self.state.previousEndDate).format('YYYY-MM-DD') }
        : {};
    } else {
      gteDate = self.state.startDate ? { $date_$gte: moment(self.state.startDate).format('YYYY-MM-DD') } : {};
      lteDate = self.state.endDate ? { $date_$lte: moment(self.state.endDate).format('YYYY-MM-DD') } : {};
    }

    query = Object.assign(query, gteDate);
    query = Object.assign(query, lteDate);

    query = Object.assign(query, { dateGrouping: self.state.showDaily ? 'daily' : null });
    query = Object.assign(query, { showCampaignLevel: self.state.showCampaignLevel });
    query = Object.assign(query, { showCampaignVersionLevel: self.state.showCampaignVersionLevel });
    
    if(self.state.showActiveClients){
      query = Object.assign(query, { showActiveClients: self.state.showActiveClients });
    }

    return query;
  }

  getSimpleQuery = (event, self) => {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };

    if (self.state.filterByRoleField && self.state.filterByRoleField !== '') {
      const value = { $filterByRole: self.state.filterByRoleField };
      query = Object.assign(query, value);
    }

    if (self.state.filterByClient && self.state.filterByClient !== '') {
      const value = { $filterByClient: self.state.filterByClient };
      query = Object.assign(query, value);
    }

    if (self.state.filterByStatus && self.state.filterByStatus !== '') {
      const value = { $filterByStatus: self.state.filterByStatus };
      query = Object.assign(query, value);
    }

    if (self.state.searchInputField && self.state.searchInputField !== '') {
      const value = { $search: self.state.searchInputField };
      query = Object.assign(query, value);
    }

    var gteDate = self.state.startDate ? { $createdAt_$gte: moment(self.state.startDate).format('YYYY-MM-DD') } : {};
    var lteDate = self.state.endDate
      ? {
        $createdAt_$lte: moment(self.state.endDate)
          .add(1, 'd')
          .format('YYYY-MM-DD')
      }
      : {};
    query = Object.assign(query, gteDate);
    query = Object.assign(query, lteDate);

    return query;
  };

  getExtendedQuery(event, self) {
    if (event.multiSortMeta) {
      self.setState({ multiSortMeta: event.multiSortMeta });
    }

    var extendedQuery = '?';

    if (self.state.multiSortMeta != null && self.state.multiSortMeta.length > 0) {
      for (let sortMeta of self.state.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-'}${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }

    return extendedQuery;
  }
}
