import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const Campaign = (state = initialState , action) => {
  switch(action.type){
  case constants.admin.CAMPAIGN_NEW : return {...state , requests : action.payload};

  case constants.admin.CAMPAIGN_GET: {
    return {...state, campaign: action.payload};
  }

  case constants.admin.CAMPAIGN_EDIT: {
    return {...state, campaignEdit: action.payload};
  }

  case constants.admin.CAMPAIGN_COPY: {
    return {...state, campaignCopy: action.payload};
  }

  default: return state;
  }
};

export default Campaign;