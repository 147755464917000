class DataFormatter {

  constructor() {}

  formatter_GBP = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  formatter_USD = {
    format : (value) => {
      return `$${this.formatter_generic.format(value)}`;
    }
  };
  
  formatter_EUR = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  formatter_generic = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  formatter_originalValue = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  _currencyFormatter = {};
  dynamicFormatter = (currencyCode) => {
    
    if(!currencyCode){
      return this.formatter_generic;
    }

    let f = this._currencyFormatter[currencyCode];

    if(f){
      return f;
    }else{

      this._currencyFormatter[currencyCode] = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return this._currencyFormatter[currencyCode];
    }    
  };
  
  formatCurrency = (value, currency, clientCountryCurrencyCode) => {
    let currencyFormater = null; 
    switch(currency){
    case 'GBP':
      currencyFormater = this.formatter_GBP;
      break;
    case 'USD':
      currencyFormater = this.formatter_USD;
      break;
    case 'EUR':
      currencyFormater = this.formatter_EUR;
      break;
    case 'OriginalValue':
      currencyFormater = this.formatter_originalValue;
      break;
    case 'ClientCountryCurrency':
      currencyFormater = this.dynamicFormatter(clientCountryCurrencyCode);
      break;
    default:
      currencyFormater = this.formatter_generic;
    }
    return currencyFormater.format(value || 0);
  }

}

export default new DataFormatter();
