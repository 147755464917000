import React from 'react';

export default {
sourceTagOptions : (that, sourceTags) => {

    if(!sourceTags){
        return;
    }

    let removeTagItem = (item) => {

      sourceTags.splice(sourceTags.indexOf(item), 1);

      that.setState({sourceTags});
    }

    return sourceTags.map(t => {
      return(
        <div style={{display:'inline-block', borderStyle: 'solid', borderWidth: '1px', marginLeft: '7px', paddingLeft: '3px', marginTop: '5px'}}>
          {t}
          <span onClick={() => removeTagItem(t)} style={{cursor:'pointer', paddingLeft: '5px'}}>X</span>
        </div>
      );      
    });      
  },

  addTagOption : (that, sourceTag) => {

    if(!sourceTag){
      return;
    }

    const {campaignVersionDetails, errors } = that.state;
    var { sourceTags } = campaignVersionDetails;

    if(!sourceTags){
        sourceTags = [];
    }

    let alphaNumericRegex=/^[a-zA-Z0-9_]*$/;
    
    if(!alphaNumericRegex.test(sourceTag) || sourceTag.length > 50){
      errors.sourceTag = 'The value must be alphanumeric with no white space and 50 chars or less longer. Undescore is allowed';
      that.setState({errors});
      return;
    }else{
      errors.sourceTag = null;
    }
    
    if(sourceTags.find(st => st == sourceTag)){
      return;
    }

    campaignVersionDetails.sourceTags = sourceTags;

    sourceTags.push(sourceTag); 
    that.setState({ campaignVersionDetails, sourceTag : ''});
  }
}