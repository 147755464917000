import request from '../wrappers/axios';
import config from '../../config/config';

class RewardPoolService {

  getRewardPoolList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/rewardPool/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getAllRewardPool(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/rewardPool${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  newRewardPool (data) {
    return request({
      url: `${config.API.BASEURL}/rewardPool`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateRewardPool (data) {
    return request({
      url: `${config.API.BASEURL}/rewardPool/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getRewardPool (rewardId) {
    return request({
      url: `${config.API.BASEURL}/rewardPool/${rewardId}`,
      method: 'get'
    });
  }
}

export default new RewardPoolService();