import AffiliateIndex from './pages/affiliate/Index';
import AssociateEmailToCampaignVersionIndex from './pages/associateEmailToCampaignVersion/Index';
import CampaignIndex from './pages/campaign/Index';
import CampaignVersionIndex from './pages/campaignVersion/Index';
import ClientIndex from './pages/client/Index';
import CodeBlockIndex from './pages/codeBlock/Index';
import DemoStoreIndex from './pages/demoStore/Index';
import DisplayBlockIndex from './pages/displayBlock/Index';
import EmailTemplateIndex from './pages/emailTemplate/Index';
import EmailTemplateTypeIndex from './pages/emailTemplateType/Index';
import OrderIndex from './pages/orders/Index';
import RewardIndex from './pages/reward/Index';
import RewardDiscountCodeIndex from './pages/rewardDiscountCode/Index';
import RewardPoolIndex from './pages/rewardPool/Index';
import UserManagementIndex from './pages/userManagement/Index';
import LiveClientsIndex from './pages/reports/liveClients/Index';
import LiveRewardsIndex from './pages/reports/liveRewards/Index';
import LiveRewardsBasicIndex from './pages/reports/liveRewardsBasic/Index';
import LiveStatsIndexOld from './pages/reports/liveStatsOld/Index';
import LiveStatsIndex from './pages/reports/liveStats/Index';
import LiveStatsValidityIndex from './pages/reports/liveStatsValidity/Index';
import LiveStatsSummaryIndex from './pages/reports/liveStatsSummary/Index';
import LiveStatsCommissionPerMonthIndex from './pages/reports/liveStatsCommissionPerMonth/Index';
import UserBlacklistIndex from './pages/userBlacklist/Index';
import wizardIndex from './pages/wizard/Index';
import ExplorerIndex from './pages/explorer/Index';
import LiveIntegrationIndex from './pages/liveIntegration/index';
import CountryCodeIndex from './pages/countryCode/Index';
import DynamicPage from './pages/dynamicPage/Index';


const Index = {
  AffiliateIndex,
  AssociateEmailToCampaignVersionIndex,
  CampaignIndex,
  CampaignVersionIndex,
  ClientIndex,
  CodeBlockIndex,
  DemoStoreIndex,
  DisplayBlockIndex,
  EmailTemplateIndex,
  EmailTemplateTypeIndex,
  OrderIndex,
  RewardIndex,
  RewardDiscountCodeIndex,
  RewardPoolIndex,
  UserManagementIndex,
  LiveClientsIndex,
  LiveRewardsIndex,
  LiveRewardsBasicIndex,
  LiveStatsIndex,
  LiveStatsValidityIndex,
  UserBlacklistIndex,
  wizardIndex,
  ExplorerIndex,
  LiveIntegrationIndex,
  CountryCodeIndex,
  LiveStatsIndexOld,
  DynamicPage,
  LiveStatsSummaryIndex,
  LiveStatsCommissionPerMonthIndex
};

export default Index;
