import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import moment from 'moment';
import _ from 'lodash';
import * as globalMessageActions from '../../../../../actions/globalMessageActions';
import { connect } from 'react-redux';

import reportServiceService from '../../../../../utils/services/reports';
import countryHelper from '../../../../../utils/helpers/country';
import FormElement from '../../../../shared/form/FormElement';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

import { withClientStats } from '../../../../../decorators/clientStatsDecorator/clientStats-decorator-bi-v2-summary';
import AffiliateDropbox from '../../../../shared/dropbox/AffiliateDropbox';
import ResponsibleDropbox from '../../../../shared/dropbox/ResponsibleDropbox';
import CountryDropbox from '../../../../shared/dropbox/CountryDropbox';

const _calendarStyle = {
  marginLeft: '5px', width: '150px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
};

@connect()
@withClientStats
export default class ClientListStatsSummary extends React.Component {

  constructor(props) {
    super();
    
    this.state = this.getDefaultState();
    if (props.location.pathname.includes('liveStatsSummaryMarketPlace')) {   
      this.state.product = 'marketplace';
    }

    if (props.location.query && props.location.query.utm_campaign) {
      this.state.utmSearchField = props.location.query.utm_campaign;
    }

    this.cellTemplate = this.cellTemplate.bind(this);
    this.onAffiliateChange = this.onAffiliateChange.bind(this);
    this.onResponsibleChange = this.onResponsibleChange.bind(this);
    this.onChangePeriod = this.onChangePeriod.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate (props) {
    if (props.location.key !== this.props.location.key) {
      location.reload();
    }
  }  

  resetFilter = (event) => {
    this.setState(this.getDefaultState(), () => this.onLazyLoad(event));
  }

  getDefaultState() {
    
    const affiliateDetails = {
      affiliateId: '',
      name: '',
      label: ''
    };

    return {
      campaignStats: [],
      startDate: moment().subtract(7, 'd').toDate(),
      endDate: moment().subtract(1, 'd').toDate(),
      showInactiveDays: true,
      dateGrouping: '',
      product: 'on_site_referral',
      showCampaignLevel: false,
      showCampaignVersionLevel: false,
      showCampaignCountryLevel: false,
      showCampaignVersionAliasLevel : false,
      showResponsible: false,
      showActiveClients: 'true',
      showCountry: false,
      searchField: '',
      utmSearchField: '',
      searchCountry: null,
      searchCountryExcept: null,
      searchCountryCampaignExcept: null,
      searchCampaignCountry: null,
      currency: 'GBP',
      exceptUk: false,
      exceptUkCampaing: false,
      cols: [],
      colsFooter: [],
      first: 0,
      rows: 1000,
      affiliateDetails,
      responsibleId: null,
      searchPeriod: 'last7Days',
      socialPostSocialPlatform: false,
      campaignVersionSourceTagGroup: false,
      showNewRepeatComparison: false,
      showDaily: false
    };
  }

  getQuery(event) {
    var first = this.state.first;
    var rows = this.state.rows;
    if (_.isNumber(event.first)) {
      first = event.first;
      this.setState({ first: first });
    }
    if (_.isNumber(event.rows)) {
      rows = event.rows;
      this.setState({ rows: rows });
    }

    var query = {
      $offset: first,
      $limit: rows
    };

    if (this.state.searchField && this.state.searchField != '') {
      var value = { '$search': `*${this.state.searchField}*` };
      query = Object.assign(query, value);
    }

    var gteDate = this.state.startDate ? { $date_$gte: moment(this.state.startDate).format('YYYY-MM-DD') } : {};
    var lteDate = this.state.endDate ? { $date_$lte: moment(this.state.endDate).format('YYYY-MM-DD') } : {};
    query = Object.assign(query, gteDate);
    query = Object.assign(query, lteDate);
    query = Object.assign(query, { dateGrouping: this.state.dateGrouping });
    query = Object.assign(query, { product: this.state.product });
    query = Object.assign(query, { showInactiveDays: this.state.showInactiveDays });
    query = Object.assign(query, { showCampaignLevel: this.state.showCampaignLevel });
    query = Object.assign(query, { showCampaignVersionLevel: this.state.showCampaignVersionLevel });
    query = Object.assign(query, { showCampaignVersionAliasLevel: this.state.showCampaignVersionAliasLevel });
    query = Object.assign(query, { showCampaignCountryLevel: this.state.showCampaignCountryLevel });
    query = Object.assign(query, { showResponsible: this.state.showResponsible });
    query = Object.assign(query, { showNewRepeatComparison: this.state.showNewRepeatComparison });
    query = Object.assign(query, { socialPostSocialPlatform: this.state.socialPostSocialPlatform });
    query = Object.assign(query, { campaignVersionSourceTagGroup: this.state.campaignVersionSourceTagGroup });
    query = Object.assign(query, { utmSearch: this.state.utmSearchField });

    if (Boolean(this.state.showActiveClients)) {
      query = Object.assign(query, { showActiveClients: this.state.showActiveClients });
    }

    if (this.state.affiliateDetails && this.state.affiliateDetails.affiliateId) {
      query = Object.assign(query, { affiliateId: this.state.affiliateDetails.affiliateId });
    }

    if (this.state.responsibleId) {
      query = Object.assign(query, { responsibleId: this.state.responsibleId });
    }

    if(this.state.searchCountry){
      query = Object.assign(query, { clientCountryId: this.state.searchCountry });
    }else if(this.state.searchCountryExcept){
      query = Object.assign(query, { clientCountryExceptId: this.state.searchCountryExcept });
    }

    if(this.state.searchCampaignCountry){
      query = Object.assign(query, { campaingCountryId: this.state.searchCampaignCountry });
    }else if(this.state.searchCountryCampaignExcept){
      query = Object.assign(query, { campaignCountryExceptId: this.state.searchCountryCampaignExcept });
    }

    if (this.isExportingToXLSX) {
      query = Object.assign(query, { 
        format: 'XLSX', 
        currency: this.state.currency, 
        showCountry: this.state.showCountry, 
        isClientSummary: true
      });
    }
    
    query.apiVersion = 2;

    return query;
  }

  getExtendedQuery(event) {
 
    //cloning so when we assign to it it doesn't change the next events
    let multiSortMetaClone = _.cloneDeep(event.multiSortMeta)
    
    if (multiSortMetaClone) {
    this.setState({ multiSortMeta: multiSortMetaClone });
    }
    
    //When clicking the search button or exporting to xlsx the event.multiSortMeta is undefined
    //so we use the value previously stored on the state to bring the data sorted
    if (this.isExportingToXLSX || (event.currentTarget && event.currentTarget.id === 'searchButtonId')) {
    multiSortMetaClone = this.state.multiSortMeta;
    }
    
    var extendedQuery = '?';
    if (multiSortMetaClone != null && multiSortMetaClone.length > 0) {
      for (let sortMeta of multiSortMetaClone) {
        if ((this.state.dateGrouping === 'daily' || this.state.dateGrouping === 'monthly' || (this.state.dateGrouping === '' && sortMeta.field != 'date'))
        && (this.state.showCampaignLevel || this.state.showCampaignLevel == false && sortMeta.field != 'campaignName')
        && (this.state.showCampaignVersionLevel || this.state.showCampaignVersionLevel == false && sortMeta.field != 'campaignVersionName')
        && (this.state.showCampaignVersionAliasLevel || this.state.showCampaignVersionAliasLevel == false && sortMeta.field != 'campaignVersionAlias')
        && (this.state.showCampaignCountryLevel || this.state.showCampaignCountryLevel == false && sortMeta.field != 'campaignCountryName')
        && (this.state.showResponsible || this.state.showResponsible == false && sortMeta.field != 'clientResponsibleName')
        ) {
          var sortField = `$sort=${sortMeta.order == 1 ? '' : '-'}${sortMeta.field}&`;
          extendedQuery = extendedQuery + sortField;
        }
      }
    }
    
    return extendedQuery;
    }

  formatData(result) {

    let resOriginal = JSON.parse(JSON.stringify(result.data.data));
    this.totals = this.setClientTotalStats({ data: { page: result.data.data } }, false, this.state.currency);

    this.setState({
      resOriginal,
      campaignStats: result.data.data,
      totalRecords: result.data.metaData.totalResults,
      loading: false,
      cols: this.columns(this.state, true, false, this.state.showCountry, this.state.currency),
      colsFooter: this.columnsFooter(this.state, this.totals, true, false, this.state.showCountry)
    });
  }

  bindResult(showCountry, currency){

    let campaignStats = JSON.parse(JSON.stringify(this.state.resOriginal));
    let totals = this.setClientTotalStats({ data: { page: campaignStats } }, false, currency);

    this.setState({
      campaignStats,
      showCountry,
      currency,
      cols: this.columns(this.state, true, false, showCountry, currency),
      colsFooter: this.columnsFooter(this.state, totals, true, false, showCountry)
    });
  }

  onLazyLoad = (event) => {
    
    if(this.state.dateGrouping === 'monthly' && !this.state.searchField){
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'error',
        message: `Please restrict search with the 'Search' filter in order to use the 'Date Grouping: Monthly'`,
        timeout: 5000
      }));

      return;
    }

    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});

    reportServiceService.getLiveStats(this.getQuery(event), this.getExtendedQuery(event)).then((result) => {
      this.formatData(result);
    });
  }

  exportToXLSX = (event) => {

    this.setState({ loading: true });
    this.isExportingToXLSX = true;
    let that = this;

    reportServiceService.getLiveStats( this.getQuery(event), this.getExtendedQuery(event), 'blob').then((response) => {
      console.log("response",response);
        
        this.setState({ loading: false });
        this.isExportingToXLSX = false;

        //create 'a' element from response.data
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response.data]));
        
        //get filename from header
        const filename = response.headers['content-disposition'].split('filename=')[1];
        a.setAttribute('download', filename);

        //append to DOM and click on it to force download
        document.body.appendChild(a);
        a.click();

        //clean up
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
        
    }).catch(function (error) {

      that.setState({ loading: false });
      that.isExportingToXLSX = false;

      that.props.dispatch(globalMessageActions.showMessage({
        type: 'error',
        message: 'An error has ocurred while trying to download the XLSX file',
        timeout: 10000
      }));

    })
  }

  onChangeCheckbox = (e) => {
    const name = e.target.name;

    let obj = { [name]: e.target.checked };

    if(name == 'showCountry'){
      this.bindResult(e.target.checked, this.state.currency);
      return;
    }
    let gb = countryHelper.getCountryFromStorageByName('Great Britain');

    if(name == "exceptUk"){
      
      obj.searchCountry = null;

      if(e.target.checked){

 
        if(gb){
          obj.searchCountryExcept = gb._id;
        }

      }else {
        obj.searchCountryExcept = null;
      }
    }

    if(name == "exceptUkCampaing"){
      
      obj.searchCampaignCountry = null;

      if(e.target.checked){

        obj.searchCountryCampaignExcept = gb._id;

      }else {
        obj.searchCountryCampaignExcept = null;
      }
    }

    this.setState(obj);
  }

  cellTemplate = (rowData, column) => {

    if (rowData.refClicksUntracked && rowData.refClicksUntracked > 0) {
      return <span title={'Unallocated: ' + rowData.refClicksUntracked + ' added to this point for Report purpose'}><b>{rowData.refClicks}</b></span>
    } else {
      return <span>{rowData.refClicks}</span>
    }
  }

  onAffiliateChange(e) {
    const { affiliateDetails } = this.state;
    const id = _.get(e, 'value.value') ? e.value.value : undefined;
    const name = _.get(e, 'value.name') ? e.value.name : undefined;
    const label = _.get(e, 'value.label') ? e.value.label : undefined;

    _.set(affiliateDetails, 'affiliateId', id);
    _.set(affiliateDetails, 'name', name);
    _.set(affiliateDetails, 'label', label);

    this.setState({ affiliateDetails });
  }

  onChangePeriod(e) {
    
    let startDate, endDate;
    switch(e.value){
      case 'custom':
        startDate = moment(this.state.startDate);
        endDate = moment(this.state.endDate);
        break;
      case 'today':
        startDate = endDate = this.state.endDate = moment();
        break;
      case 'yesterday':
        startDate = endDate = this.state.endDate = moment().subtract(1, 'days');
        break;
      case 'thisWeek':
        startDate = this.state.endDate = moment().startOf('isoWeek');
        endDate = this.state.endDate = moment();
        break;
      case 'thisMonth':
        startDate = this.state.endDate = moment().startOf('month');
        endDate = this.state.endDate = moment();
        break;
      case 'lastWeek':
        startDate = this.state.endDate = moment().subtract(1, 'week').startOf('isoWeek');
        endDate = this.state.endDate = moment().subtract(1, 'week').endOf('isoWeek');
        break;
      case 'lastMonth':
        startDate = this.state.endDate = moment().subtract(1, 'month').startOf('month');
        endDate = this.state.endDate = moment().subtract(1, 'month').endOf('month');
        break;
      case 'last7Days':
        startDate = moment().subtract(7, 'days');
        endDate = moment().subtract(1, 'days');
        break;
      case 'last30Days':
        startDate = moment().subtract(30, 'days');
        endDate = moment().subtract(1, 'days');
        break;
    }

    startDate = startDate.toDate();
    endDate = endDate.toDate();

    this.setState({ startDate, endDate, searchPeriod: e.value });
  }

  onResponsibleChange(e) {
    this.setState({ responsibleId: e.value });
  }

  render() {

    const { affiliateDetails, responsibleId } = this.state;

    const {
      affiliateId,
      name,
      label
    } = affiliateDetails;

    let columnsFooter = this.state.colsFooter.map((col, i) => {
      return <Column key={i} footer={col.footer} colSpan={col.colSpan} />;
    });

    let footerGroup = <ColumnGroup><Row>{columnsFooter}</Row></ColumnGroup>;

    let columns = this.state.cols.map((col, i) => {

      if (col.field !== 'refClicks') {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} />;
      }else {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} body={this.cellTemplate} />;
      }
    });

    /**
     * Client status option
     */
    const clientsActiveSelectItems = [
      { label: 'All', value: '' },
      { label: 'Active', value: 'true' },
      { label: 'Inactive', value: 'false' },
    ];

    let periods = [
      { label:'Custom', value: 'custom' },
      { label:'Today', value: 'today' },
      { label:'Yesterday', value: 'yesterday' },
      { label:'This week', value: 'thisWeek' },
      { label:'This month', value: 'thisMonth' },
      { label:'Last week', value: 'lastWeek' },
      { label:'Last month', value: 'lastMonth' },
      { label:'Last 7 days', value: 'last7Days' },
      { label:'Last 30 days', value: 'last30Days' },
    ];

    let dateGroupings = [
      { label: 'None', value: ''},
      { label: 'Monthly', value: 'monthly'},
      { label: 'Daily', value: 'daily'},
    ];

    let products = [
      { label: 'On-site referral', value: 'on_site_referral'},
      { label: 'Marketplace', value: 'marketplace'},
      { label: 'ALL', value: ''},
    ];

    return (
      
        <div className="content-section implementation">

          {/* title */}
          <h3>Client Statistics</h3>

          {/* line 1 */}
          <div className="content-section implementation">
            <div className="ui-g">

              <div className="ui-md-12" style={{display:'flex'}} >
                <div style={{display:'flex'}}>
                  <span>Inactive Days:</span>
                  <input
                      name="showInactiveDays"
                      type="checkbox"
                      checked={this.state.showInactiveDays}
                      onChange={this.onChangeCheckbox} />
                </div>
                
                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>New X Repeated:</span>
                  <input
                      name="showNewRepeatComparison"
                      type="checkbox"
                      checked={this.state.showNewRepeatComparison}
                      onChange={this.onChangeCheckbox} />
                </div>

                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>Campaign:</span>
                  <input
                      name="showCampaignLevel"
                      type="checkbox"
                      checked={this.state.showCampaignLevel}
                      onChange={this.onChangeCheckbox} />
                </div>
              
                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>Campaign Version:</span>
                  <input
                      name="showCampaignVersionLevel"
                      type="checkbox"
                      checked={this.state.showCampaignVersionLevel}
                      onChange={this.onChangeCheckbox} />
                </div>

                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>AB test:</span>
                  <input
                      name="showCampaignVersionAliasLevel"
                      type="checkbox"
                      checked={this.state.showCampaignVersionAliasLevel}
                      onChange={this.onChangeCheckbox} />
                </div>
              
                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>Campaign Country:</span>
                  <input
                      name="showCampaignCountryLevel"
                      type="checkbox"
                      checked={this.state.showCampaignCountryLevel}
                      onChange={this.onChangeCheckbox} />
                </div>
              
                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>Client Country:</span>
                  <input
                      name="showCountry"
                      type="checkbox"
                      checked={this.state.showCountry}
                      onChange={this.onChangeCheckbox} />
                </div>

              <div style={{display:'flex', paddingLeft: '20px'}}>
                <span>Representative:</span>
                <input
                    name="showResponsible"
                    type="checkbox"
                    checked={this.state.showResponsible}
                    onChange={this.onChangeCheckbox} />
              </div>
              
              <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>Social Platform:</span>
                  <input
                      name="socialPostSocialPlatform"
                      type="checkbox"
                      checked={this.state.socialPostSocialPlatform}
                      onChange={this.onChangeCheckbox} />
                </div>

                <div style={{display:'flex', paddingLeft: '20px'}}>
                  <span>Source Tag:</span>
                  <input
                      name="campaignVersionSourceTagGroup"
                      type="checkbox"
                      checked={this.state.campaignVersionSourceTagGroup}
                      onChange={this.onChangeCheckbox} />
                </div>
                    
                    
              </div>
            </div>
            <br/>

              <div className="ui-g">
                <div className="ui-md-2" >
                  <div style={{paddingRight:'15px', paddingTop:'8px'}}>
                    <span>Date Grouping:</span>
                  </div>
                  <Dropdown
                    value={this.state.dateGrouping}
                    options={dateGroupings}
                    onChange={(e) => this.setState({ dateGrouping: e.value }) }
                    style={{height:'30px'}}
                    />
                </div>
                <div className="ui-md-3" >
                  <div style={{paddingRight:'15px', paddingTop:'8px'}}>
                    <span>Product:</span>
                  </div>
                  <Dropdown
                    value={this.state.product}
                    options={products}
                    onChange={(e) => this.setState({ product: e.value })}
                    style={{height:'30px', width: '200px'}}/>
                </div>                
              </div>

            <br/>

            <div className="ui-g">

              <div className="ui-md-2" >
                <div style={{paddingRight:'15px', paddingTop:'8px'}}>
                  <span>Client Status:</span>
                </div>
                <Dropdown
                  value={this.state.showActiveClients}
                  options={clientsActiveSelectItems}
                  onChange={(e) => { this.setState({ showActiveClients: e.value }) }}
                  placeholder="All" />
              </div>

              <div className="ui-md-3">
                <div style={{paddingRight:'15px', paddingTop:'8px'}}>
                  <span>Client Country:</span>
                </div>
                <CountryDropbox value={this.state.searchCountry}
                  overwriteStyle={{ width: '200px', marginTop:'-8px', display: 'inline-block' }}
                  className={'inline-block c-form-element-210'}
                  onChange={(e) => { this.setState({searchCountry: e.countryId}) }}
                  placeholder={ this.state.exceptUk ? 'All countries except GB' : null }
                  disabled={this.state.exceptUk}></CountryDropbox>

                <span>All Except GB:</span>
                <input
                    name="exceptUk"
                    type="checkbox"
                    checked={this.state.exceptUk}
                    onChange={this.onChangeCheckbox} />
              </div>

              <div className="ui-md-3">
                <div style={{paddingRight:'15px', paddingTop:'8px'}}>
                  <span>Campaign Country:</span>
                </div>
                <CountryDropbox value={this.state.searchCampaignCountry}
                  overwriteStyle={{ width: '200px', marginTop:'-8px', display: 'inline-block' }}
                  className={'inline-block c-form-element-210'}
                  onChange={(e) => { 
                    this.setState({searchCampaignCountry: e.countryId}) 
                  }}
                  placeholder={ 
                    this.state.exceptUkCampaing ? 'All countries except GB' : this.state.searchCampaignCountry  
                  }
                  disabled={this.state.exceptUkCampaing}></CountryDropbox>
                <span>All Except GB:</span>
                <input
                    name="exceptUkCampaing"
                    type="checkbox"
                    checked={this.state.exceptUkCampaing}
                    onChange={this.onChangeCheckbox} />
              </div>


              <div className="ui-md-2">
                <div style={{paddingRight:'15px', paddingTop:'8px'}}>
                  <span>Currency:</span>
                </div>
                <FormElement 
                  type='dropdown'
                  style={{ marginTop:'-8px' }}
                  value={this.state.currency} 
                  options={[{ key: 'GBP', label: 'GBP', value:'GBP' }, 
                    { key: 'USD', label: 'USD', value:'USD'}, 
                    { key: 'EUR', label: 'EUR', value:'EUR'},
                    { key: 'ClientCountryCurrency', label: 'CLIENT CURRENCY', value:'ClientCountryCurrency'},
                    { key: 'OriginalValue', label: ' ORIGINAL VALUE', value:'OriginalValue'}]}
                  onChange={(e) => { this.bindResult(this.state.showCountry, e.value); }} />
              </div>
            </div>
          </div>

          {/* line 2 */}
          <div className="content-section implementation">
            <div className="ui-g">
              
              <div className="ui-g-1 ui-md-2">
                <div style={{paddingBottom:'5px'}}>
                  <span>Date range:</span>
                </div>
                <FormElement
                  type='dropdown'
                  onChange={this.onChangePeriod}
                  value={this.state.searchPeriod}
                  style={_calendarStyle}
                  options={periods}                  
                />
              </div>
              
              <div className="ui-g-2 ui-md-2">
                <div style={{paddingBottom:'5px'}}>
                  <span>from:</span>
                </div>
                <FormElement
                  name='dateFrom'
                  type='calendar'             
                  onChange={(e) => this.setState({ startDate: e.value })}
                  value={this.state.startDate}
                  id='dateFrom'
                  required
                  style={_calendarStyle}
                  disabled={ this.state.searchPeriod != 'custom'}
                />
              </div>
              <div className="ui-g-1 ui-md-1">
                <div style={{paddingBottom:'5px'}}>
                  <span>to:</span>
                </div>
                <FormElement
                  name='dateTo'
                  type='calendar'
                  onChange={(e) => this.setState({ endDate: e.value })}
                  value={this.state.endDate}
                  id='dateTo'
                  required
                  style={_calendarStyle}
                  disabled={ this.state.searchPeriod != 'custom'}
                />
              </div>
            </div>
          </div>
          
          {/* line 3 */}
          <div className="content-section implementation">

              <div className="ui-g">
                
                <div className="ui-g-2 ui-md-2">
                  <div style={{paddingBottom:'5px'}}>
                    <span>Search:</span>
                  </div>
                  <InputText onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField} />
                  
                </div>
                <div className="ui-g-2 ui-md-2">
                  <div style={{paddingBottom:'5px'}}>
                    <span>Network:</span>
                  </div>
                  <AffiliateDropbox value={affiliateId} filterPlaceholder="Select Network" showInternalOption="true" onChange={this.onAffiliateChange} overwriteStyle={{ marginLeft: '0px', width: '200px', marginTop: '-7px' }} />
                </div>
                <div className="ui-g-2 ui-md-2">
                  <div style={{paddingBottom:'5px'}}>
                    <span>Representative:</span>
                  </div>
                  <ResponsibleDropbox value={responsibleId} onChange={e => this.onResponsibleChange(e)} overwriteStyle={{ marginLeft: '0px', width: '200px', marginTop: '-7px' }} />
                
                </div>
                <div className="ui-g-2 ui-md-2">
                  <div style={{paddingBottom:'5px'}}>
                    <span>UTM:</span>
                  </div>
                  <InputText onChange={(e) => this.setState({ utmSearchField: e.target.value })} value={this.state.utmSearchField} />
                  
                </div>
              </div>

          </div>
          
          {/* line 4 */}
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
              <Button label="Search" id="searchButtonId" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
              <div className="ui-g-4 ui-md-2">
                <Button label="Export to XLSX" onClick={this.exportToXLSX} />
              </div>
            </div>
          </div>

          {/* Result Table */}
          <DataTable
              value={this.state.campaignStats}
              responsive={true}
              sortMode="multiple"
              paginator={true}
              rows={1000}
              loading={this.state.loading}
              rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
              totalRecords={this.state.totalRecords}
              lazy={true}
              onLazyLoad={this.onLazyLoad}
              //header={header}
              ref={(el) => this.dt = el}
              globalFilter={this.state.globalFilter}
              footerColumnGroup={footerGroup}
              first = {this.state.first}>
              {columns}
            </DataTable>
          
        </div>
    );
  }
}