import request from '../wrappers/axios';
import config from '../../config/config';

class socialService {
  postSocialPost (data){
    return request({
      url: config.API.BASEURL + '/socialpost',
      method: 'post',
      data: data
    });
  }

  getSocialAuth (params){
    return request({
      url: config.API.BASEURL + '/socialauths',
      method: 'get',
      params: params
    });
  }

  deleteSocialAuth (socialPlatform) {
    return request({
      url: config.API.BASEURL + '/socialauths',
      method: 'delete',
      params: {
        p: socialPlatform
      }
    });
  }
  createSocialPost(userId,sharedUrlId,socialPlatform){
    return request({
      url: config.API.BASEURL + '/sharedurls/socialpost',
      method: 'post',
      data: {
        userId: userId,
        sharedUrlId: sharedUrlId,
        socialPlatform: socialPlatform
      }
    });
  }
}

export default new socialService();