import React from 'react';
import classnames from 'classnames';

import affiliateService from '../../../utils/services/affiliate';

import { Dropdown } from 'primereact/components/dropdown/Dropdown';

const INTERNAL_CLIENTS = 'Internal';

export default class AffiliateDropbox extends React.Component {

  constructor(props) {
    super(props);

    this.affiliates = [];
    this.getAffiliate = this.getAffiliate.bind(this);

    this.getAffiliate();

    this.state = {
      affiliate: this.props.value ? this.props.value : null,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.value === 'object') {
      this.setState({ affiliate: null });
    } else if (typeof nextProps.value === 'string') {
      
      
      if(this.affiliates){
        var aux = this.affiliates.find((o => o.value === nextProps.value));

        if(this._isMounted){
          this.setState({ affiliate: aux }, () => {
            this.forceUpdate();
          });      
        }
      }
      
      this.setState({ affiliateId: nextProps.value });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  getAffiliate() {
    affiliateService.getAllAffiliate().then((data) => {
      this.affiliates = data.data.map((affiliate, int) => ({
        key:  int+'X',
        label: `${affiliate.name}`,
        name: affiliate.name,
        value: affiliate._id,
        imageUrl: affiliate.imageUrl,
      }));

      // inlcudes a new fake value that represents Interal clients
      if(this.props.showInternalOption){
        this.affiliates.push({
          key:  (this.affiliates.length + 1)+'X',
          label: INTERNAL_CLIENTS,
          name: INTERNAL_CLIENTS,
          value: '-1',
          imageUrl: '/images/affiliate/soreto-internal-32x32.png',
        });
      }

      let { affiliateId } = this.state;

      var aux = this.affiliates.find((o => o.value === affiliateId));

      if(this._isMounted){
        this.setState({ affiliate: aux }, () => {
          this.forceUpdate();
        });      
      } 

    });
  }


  affiliateTemplate(option) {
    return (
      <div className="ui-helper-clearfix " style={{ width: '250px',backgroundColor: option.disabled && option.disabled == 'true' ? 'red' : 'white'}}>
        <img alt={option.name}
          src={option.imageUrl? option.imageUrl: '/images/logo.png'}
          style={{display: 'inline-block', margin:'5px 0 0 5px' }}
          width="24" 
          onError={(e)=>{e.target.src='/images/logo.png';}} 
        />
        <span style={{float:'right', margin:'.5em .25em 0 0 '}}>{option.name}</span>
      </div>
    );

  }

  onChange(e){
    this.setState({affiliate: e.value});
    this.props.onChange(e);
  }

  render() {

    const { label, required, className, error, flex, inline, disabled, filterPlaceholder, showInternalOption, overwriteStyle } = this.props;

    const cx = classnames('c-form-element', className, {
      'c-form-element__error': error,
      'c-form-element__flex': flex,
      'c-form-element--inline': inline
    });

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>
      );
    }

    return (
      <div className={ cx }>

        { showlabel }       

        <Dropdown 
          dataKey="key"
          value={this.state.affiliate}
          options={this.affiliates}
          disabled={disabled}
          optionLabel="label"
          showClear
          onChange={this.onChange} 
          itemTemplate={this.affiliateTemplate}
          style={ overwriteStyle ? overwriteStyle : { marginLeft: '5px', width:'300px', marginTop: '4px' }}
          panelStyle={{width:'300px'}} 
          filter={true} 
          filterPlaceholder={filterPlaceholder ? filterPlaceholder : 'Select Affiliate'}
          filterBy="name,value" 
          placeholder={this.state.affiliate? this.state.affiliate.name : 'Select a Affiliate'}
          showInternalOption={showInternalOption ? showInternalOption : false}/>
      </div>);
  }
}
