import constants from '../../constants/app-constants';

const initialState = {
  slides: [
    {
      id: 'slider_1',
      title: 'Love',
      text: 'Soreto is the new way to share the products you love.',
      logo: '/images/onBoarding/boarding-logo.png',
      backgroundImage: 'url(/images/onBoarding/slider-1.jpg)'
    },
    {
      id: 'slider_2',
      title: 'Share',
      text: 'Share your favourite products with your frans.',
      backgroundImage: 'url(/images/onBoarding/slider-2.jpg)'
    },
    {
      id: 'slider_3',
      title: 'Earn',
      text: ' Earn cash when your frans make a purchase.',
      backgroundImage: 'url(/images/onBoarding/slider-3.jpg)'
    }
  ],
  show: false
};

const UserOnBoarding = (state = initialState , action) => {
  switch(action.type){
  case constants.user.ONBOARDING_UPDATE_STATUS : return{ ...state , show : action.payload };
  default: return state;
  }
};

export default UserOnBoarding;