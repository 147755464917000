import React from 'react';
import {authService} from '../../../utils/services/auth';
import {logout} from '../../../actions/index';
import {connect} from 'react-redux';

@connect()
class Logout extends React.Component{



  componentWillMount(){
    var that = this;
    authService.logout().then(() => {
      that.props.dispatch(logout());
      this.props.router.push({ pathname: '/login'});
    }).catch((err) => {
      console.log('CAUGHT:', err);
    });
  }

  render(){
    return(
      <div></div>
    );
  }
}

export default Logout;