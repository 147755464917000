import React from 'react';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import * as withdrawalActions from '../../../../actions/user/withdrawalActions';
import * as paymentMethodActions from '../../../../actions/user/paymentMethodActions';
import * as userActions from '../../../../actions/user/userActions';
import userService from '../../../../utils/services/user';
import trackingService from '../../../../utils/services/tracking';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import _ from 'lodash';
import serialize from 'form-serialize';


const paymentModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

const confirmationModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '370px'
  }
};


@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    withdrawalModalIsOpen: store.userWithdrawal.withdrawalModalIsOpen,
    paymentMethods: store.userPaymentMethod.paymentMethods,
    userBalance: store.user.userWalletWithBalance
  };
})
class Withdrawal extends React.Component{

  constructor(){
    super();
    this.state = {
      showEnterDetailsScreen: true,
      withdrawalError: null
    };

    this.checkIfNewAccountRequested = this.checkIfNewAccountRequested.bind(this);
  }

  componentWillMount(){
    this.props.dispatch(userActions.getUserWalletWithBalance(this.props.loginCtx._id));
    this.props.dispatch(paymentMethodActions.getPaymentMethods(this.props.loginCtx._id));
  }

  handleSubmit(e){
    e.preventDefault();
    var that = this;

    userService.withdraw(this.props.loginCtx._id ,serialize(this.withdrawalForm , { hash: true }))
      .then(function(res){

        trackingService.withdrawalRequest(that.props.loginCtx,res.data);

        that.props.dispatch(userActions.getUserWalletWithBalance(that.props.loginCtx._id));
        that.props.dispatch(withdrawalActions.setWithdrawalModalStatus(false));
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Withdrawal Request Success',
            timeout: 10000
          }
        ));
        that.setState({
          showEnterDetailsScreen : true,
          withdrawalError: null
        });
      })
      .catch(function(err){
        that.setState({
          withdrawalError: err.response.data.message,
          showEnterDetailsScreen: true
        });
      });
  }

  cancelWithdrawalRequest(e){
    e.preventDefault();
    this.props.dispatch(withdrawalActions.setWithdrawalModalStatus(!this.props.withdrawalModalIsOpen));
    this.setState({
      showEnterDetailsScreen : true,
      withdrawalError: null
    });
  }

  toggleEnterDetailsScreen(e){
    e.preventDefault();
    this.setState({
      showEnterDetailsScreen : !this.state.showEnterDetailsScreen
    });
  }

  continueToConfirmScreen(e){
    e.preventDefault();

    if(!this.withdrawalAmount.value || !this.accountSelect.value){
      this.setState({
        withdrawalError: 'Please ensure you have put in a withdrawal amount and selected a bank account.'
      })
      return;
    }

    this.setState({
      showEnterDetailsScreen : false,
      withdrawalError: null
    });
  }

  checkIfNewAccountRequested(){
    if(this.accountSelect.value == 'new'){
      this.props.dispatch(withdrawalActions.setWithdrawalModalStatus(false));
      this.props.router.push({ pathname: '/user/settings/paymentmethods' });
    }
  }

  render(){
    var that = this;
    return(
      <div>
        <Modal isOpen={this.props.withdrawalModalIsOpen} contentLabel={'Withdrawal'} style={this.state.showEnterDetailsScreen ? paymentModalStyle : confirmationModalStyle}>
          <div id="withdrawal-popup">
            <div className={this.state.showEnterDetailsScreen ? '' : 'hide-on-mobile hide-on-desktop hide-on-tablet'}>
              <form ref={(form) => this.withdrawalForm = form} onSubmit={(e) => this.handleSubmit(e) } action="">
                <h3 className="text-center">Cash Withdrawal</h3>
                {
                  this.state.withdrawalError ?
                    <p className="error text-center">{this.state.withdrawalError}</p>
                    :
                    false
                }
                {
                  this.props.userBalance ?
                    <div className="available-funds grid-100 text-center">
                      Available funds <span className="amount">£{this.props.userBalance.availableBalance}</span>
                    </div>
                    :
                    false
                }
                <div className="input-holder">
                  <input required={true} ref={(input) => this.withdrawalAmount = input} type="number" name="amount" placeholder="Amount"/>
                </div>
                <div className="input-holder">
                  <div className="styled-select">
                    <select name="bankInfoId" ref={select => this.accountSelect = select} onChange={() => this.checkIfNewAccountRequested()}>
                      <option value="">Select Account...</option>
                      <option value="new">New Account</option>
                      {
                        _.map(this.props.paymentMethods , function(payment){
                          return(
                            <option key={payment._id} value={payment._id}>{payment.preview}</option>
                          );
                        })
                      }
                    </select>
                  </div>
                </div>
                <p className="withdrawalNote">
                  Please note, all withdrawal requests are subject to
                  approval and usually take <strong>3-4 working days</strong> to arrive in your
                  account.
                </p>
                <div className="action-buttons">
                  <button onClick={(e) => this.continueToConfirmScreen(e)} className="primary">Continue</button> &nbsp;
                  <button className="secondary-v2" onClick={(e) => this.cancelWithdrawalRequest(e)}>Cancel</button>
                </div>
              </form>
            </div>
            <div className={!this.state.showEnterDetailsScreen ? '' : 'hide-on-mobile hide-on-desktop hide-on-tablet'}>
              <h3 className="text-center">Cash Withdrawal</h3>
              <div className="withdrawalNote text-center">
                <table>
                  {
                    this.withdrawalAmount ?
                      <tr>
                        <td><strong>Amount: &nbsp;</strong></td>
                        <td>£{this.withdrawalAmount.value}</td>
                      </tr>
                      :
                      false
                  }
                  {
                    this.accountSelect && this.accountSelect.value && _.find(this.props.paymentMethods , {_id : this.accountSelect.value})?
                      <tr>
                        <td><strong>Account: &nbsp;</strong></td>
                        <td>{ _.find(this.props.paymentMethods , {_id : this.accountSelect.value}).preview }</td>
                      </tr>
                      :
                      false
                  }
                </table>
                <div className="clearfix"></div>
              </div>
              <div className="action-buttons">
                <button onClick={(e) => this.handleSubmit(e)} className="primary">Confirm</button>
                <p className="text-center"><a onClick={(e) => this.toggleEnterDetailsScreen(e)} href="">Edit</a></p>
                <button className="secondary-v2" onClick={(e) => this.cancelWithdrawalRequest(e)}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Withdrawal;