import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const Client = (state = initialState , action) => {
  switch(action.type){
  case constants.admin.CLIENT_NEW : return {...state , requests : action.payload};

  case constants.admin.CLIENTOBJ_GET: {
    return {...state, client: action.payload};
  }

  case constants.admin.CLIENT_EDIT: {
    return {...state, clientEdit: action.payload};
  }

  default: return state;
  }
};

export default Client;