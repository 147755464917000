const _ = require('lodash');

export const formatter_GBP = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatter_USD = {
  format: (value) => {
    return `$${formatter_generic.format(value)}`;
  },
};

export const formatter_EUR = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatter_generic = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatter_originalValue = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

var _currencyFormatter = {};
export const dynamicFormatter = (currencyCode) => {
  if (!currencyCode) {
    return formatter_generic;
  }

  let f = _currencyFormatter[currencyCode];

  if (f) {
    return f;
  } else {
    _currencyFormatter[currencyCode] = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return _currencyFormatter[currencyCode];
  }
};

export const formatterNumber = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatterNumberOneCase = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export class ClientStats {
  constructor() {}

    setClientTotalStats = (data, excludeReachMultiple = false, currency) => {
      var totals = {
        clientSales: 0,
        offerClicks: 0,
        soretoTraffic: 0,
        countSoretoSales: 0,
        countSoretoSalesPending: 0,
        countSoretoSalesPaid: 0,
        countSoretoSalesDeclined: 0,
        totalValueSales: 0,
        totalValueSoretoSales: 0,
        totalValueSoretoSalesPending: 0,
        totalValueSoretoSalesPaid: 0,
        totalValueSoretoSalesDeclined: 0,
        totalValueRawCommission: 0,
        totalValueSoretoCommission_USD: 0,
        totalValueSoretoCommission_CLI_CURRENCY: 0,
        totalValueSoretoCommission: 0,
        totalValueSoretoCommissionPending: 0,
        totalValueSoretoCommissionPaid: 0,
        totalValueSoretoCommissionDeclined: 0,
        clicks: 0,
        shares: 0,
        shareRate: 0,
        purchaseRate: 0,
        reachMultiple: 0,
        interstitialRate: 0,
        conversionRate: 0,
      };

      let currencyFormater = null;

      switch (currency) {
      case 'GBP':
        currencyFormater = formatter_GBP;
        break;
      case 'USD':
        currencyFormater = formatter_USD;
        break;
      case 'EUR':
        currencyFormater = formatter_EUR;
        break;
      case 'OriginalValue':
        currencyFormater = formatter_originalValue;
        break;
      default:
        currencyFormater = formatter_generic;
      }

      for (let row of data.data.page) {
        let specificFormatter = null;

        if (currency == 'ClientCountryCurrency') {
          specificFormatter = dynamicFormatter(
            row.clientCountryCurrencyCode
          );
        } else {
          specificFormatter = currencyFormater;
        }

        totals.clientSales += +row.clientSales;
        totals.offerClicks += +row.offerClicks;
        totals.soretoTraffic += +row.interstitialClicks;

        // SALES COUNT
        totals.countSoretoSales += +row.soretoSales;
        totals.countSoretoSalesPending += +row.pendingSalesCount;
        totals.countSoretoSalesPaid += +row.paidSalesCount;
        totals.countSoretoSalesDeclined += +row.declinedSalesCount;

        // SALES TOTALS SUM
        if (currency === 'OriginalValue') {
          totals.totalValueSales += +row[`totalValueSales`];
        } else {
          totals.totalValueSales += +row[`totalValueSales_${currency}`];
        }

        totals.totalValueSoretoSales += +row.totalValueSales;
        totals.totalValueSoretoSalesPending += +row.totalValueSalesPending;
        totals.totalValueSoretoSalesPaid += +row.totalValueSalesPaid;
        totals.totalValueSoretoSalesDeclined += +row.totalValueSalesDeclined;

        // SALES COMMISSION SUM
        if (currency === 'OriginalValue') {
          totals.totalValueRawCommission += +row.salesCommission;
          totals.totalValueSoretoCommission_USD += +row.salesCommission_USD;
          totals.totalValueSoretoCommission_CLI_CURRENCY += +row.salesCommission_ClientCountryCurrency;
          totals.totalValueSoretoCommission += +row[`salesCommission`];
          totals.totalValueSoretoCommissionPending += +row[
            `pendingSalesCommission`
          ];
          totals.totalValueSoretoCommissionPaid += +row[
            `paidSalesCommission`
          ];
          totals.totalValueSoretoCommissionDeclined += +row[
            `declinedSalesCommission`
          ];
        } else {
          totals.totalValueRawCommission += +row.salesCommission;
          totals.totalValueSoretoCommission_USD += +row.salesCommission_USD;
          totals.totalValueSoretoCommission_CLI_CURRENCY += +row.salesCommission_ClientCountryCurrency;
          totals.totalValueSoretoCommission += +row[
            `salesCommission_${currency}`
          ];
          totals.totalValueSoretoCommissionPending += +row[
            `pendingSalesCommission_${currency}`
          ];
          totals.totalValueSoretoCommissionPaid += +row[
            `paidSalesCommission_${currency}`
          ];
          totals.totalValueSoretoCommissionDeclined += +row[
            `declinedSalesCommission_${currency}`
          ];
        }

        // CLICKS
        totals.clicks += Number(row.refClicks);
        totals.shares += +row.shares;

        row.refClicks = Number(row.refClicks);
        row.pendingSalesCount = row.pendingSalesCount;
        row.paidSalesCount = row.paidSalesCount;
        row.declinedSalesCount = row.declinedSalesCount;

        row.purchaseRate = formatterNumber.format(row.purchaseRate) + '%';
        row.conversionRate =
                formatterNumber.format(row.conversionRate) + '%';
        row.shareRate = formatterNumber.format(row.shareRate) + '%';
        row.reachMultiple = formatterNumberOneCase.format(
          row.reachMultiple
        );
        row.interstitialClicksRate =
                formatterNumber.format(row.interstitialClicksRate) + '%';

        row.totalValueSoretoSales = row.totalValueSoretoSales;
        row.totalValueSoretoSalesPending = specificFormatter.format(
          row.pendingSales
        );
        row.totalValueSoretoSalesPaid = specificFormatter.format(
          row.paidSales
        );
        row.totalValueSoretoSalesDeclined = specificFormatter.format(
          row.declinedSales
        );

        if (currency === 'OriginalValue') {
          row[`totalValueSales`] = specificFormatter.format(
            row[`totalValueSales`]
          );
          // row[`salesCommission`] = formatterNumber.format(row.salesCommission);
          row[`salesCommission`] = specificFormatter.format(
            row[`salesCommission`]
          );
          row[`pendingSalesCommission`] = specificFormatter.format(
            row[`pendingSalesCommission`]
          );
          row[`paidSalesCommission`] = specificFormatter.format(
            row[`paidSalesCommission`]
          );
          row[`declinedSalesCommission`] = specificFormatter.format(
            row[`declinedSalesCommission`]
          );
        } else {
          row[`totalValueSales_${currency}`] = specificFormatter.format(
            row[`totalValueSales_${currency}`]
          );
          row[`salesCommission`] = formatterNumber.format(
            row.salesCommission
          );
          //row[`salesCommission_USD`] = formatter_USD.format(row.salesCommission_USD);
          //row[`salesCommission_ClientCountryCurrency`] = `${row.clientCountryCurrencyCode} ${formatterNumber.format(row.salesCommission_ClientCountryCurrency)}`;
          row[`salesCommission_${currency}`] = specificFormatter.format(
            row[`salesCommission_${currency}`]
          );
          row[
            `pendingSalesCommission_${currency}`
          ] = specificFormatter.format(
            row[`pendingSalesCommission_${currency}`]
          );
          row[
            `paidSalesCommission_${currency}`
          ] = specificFormatter.format(
            row[`paidSalesCommission_${currency}`]
          );
          row[
            `declinedSalesCommission_${currency}`
          ] = specificFormatter.format(
            row[`declinedSalesCommission_${currency}`]
          );
        }
      }

      /**
         * FORMAT
         */

      totals.purchaseRate =
            formatterNumber.format(
              +totals.soretoTraffic > 0
                ? (+totals.countSoretoSales / +totals.soretoTraffic) * 100
                : 0
            ) + '%';
      totals.conversionRate =
            formatterNumber.format(
              +totals.clicks > 0
                ? (+totals.countSoretoSales / +totals.clicks) * 100
                : 0
            ) + '%';
      totals.shareRate =
            formatterNumber.format(
              +totals.clientSales > 0
                ? (+totals.shares / +totals.clientSales) * 100
                : 0
            ) + '%';
      totals.reachMultiple = formatterNumberOneCase.format(
        +totals.shares > 0 ? +totals.clicks / +totals.shares : 0
      );
      totals.interstitialRate =
            formatterNumber.format(
              +totals.clicks > 0
                ? (+totals.soretoTraffic / +totals.clicks) * 100
                : 0
            ) + '%';

      totals.totalValueSales = currencyFormater.format(
        totals.totalValueSales
      );
      totals.totalValueSoretoSales = totals.totalValueSoretoSales.toFixed(0);
      totals.totalValueSoretoSalesPending = currencyFormater.format(
        totals.totalValueSoretoSalesPending
      );
      totals.totalValueSoretoSalesPaid = currencyFormater.format(
        totals.totalValueSoretoSalesPaid
      );
      totals.totalValueSoretoSalesDeclined = currencyFormater.format(
        totals.totalValueSoretoSalesDeclined
      );

      totals.totalValueRawCommission = currencyFormater.format(
        totals.totalValueRawCommission
      );
      totals.totalValueSoretoCommission_USD = formatter_USD.format(
        totals.totalValueSoretoCommission_USD
      );
      totals.totalValueSoretoCommission_CLI_CURRENCY = formatterNumber.format(
        totals.totalValueSoretoCommission_CLI_CURRENCY
      );
      totals.totalValueSoretoCommission = currencyFormater.format(
        totals.totalValueSoretoCommission
      );
      totals.totalValueSoretoCommissionPending = currencyFormater.format(
        totals.totalValueSoretoCommissionPending
      );
      totals.totalValueSoretoCommissionPaid = currencyFormater.format(
        totals.totalValueSoretoCommissionPaid
      );
      totals.totalValueSoretoCommissionDeclined = currencyFormater.format(
        totals.totalValueSoretoCommissionDeclined
      );

      if (excludeReachMultiple) {
        delete totals.reachMultiple;
      }

      return totals;
    };

    columns = (
      state,
      showClient = true,
      excludeReachMultiple = false,
      showClientCountry = false,
      currency = 'GBP'
    ) => {
      var s = new Set();

      if (
        state.dateGrouping === 'daily' ||
            state.dateGrouping === 'monthly'
      ) {
        s.add({
          field: 'eventDateTerm',
          header: 'Date',
          sortable: true,
          style: { width: '100px' },
        });
      }

      if (showClient) {
        s.add({
          field: 'clientName',
          header: 'Client',
          sortable: true,
          style: { width: '120px' },
        });
      }

      s.add({
        field: 'clientLaunchDiffDays',
        header: 'Client Type',
        sortable: true,
        style: { width: '70px' },
      });

      if (state.showResponsible) {
        s.add({
          field: 'clientResponsibleName',
          header: 'Representative',
          sortable: true,
          style: { width: '100px' },
        });
      }

      if (state.showUtmCampaign) {
        s.add({
          field: 'utmCampaign',
          header: 'Utm Campaign',
          sortable: false,
          style: { width: '120px' },
        });
      }

      if (showClientCountry) {
        s.add({
          field: 'clientCountry',
          header: 'Client Country',
          sortable: true,
          style: { width: '50px' },
        });
      }

      if (state.showCampaignCountryLevel) {
        s.add({
          field: 'campaignCountryName',
          header: 'Campaign Country',
          sortable: true,
          style: { width: '100px' },
        });
      }

      if (state.showCampaignLevel) {
        s.add({
          field: 'campaignName',
          header: 'Campaign',
          sortable: true,
          style: { width: '100px' },
        });
      }

      if (state.showCampaignVersionLevel) {
        s.add({
          field: 'campaignVersionName',
          header: 'Version',
          sortable: true,
          style: { width: '100px' },
        });
      }

      if (state.showCampaignVersionAliasLevel) {
        s.add({
          field: 'campaignVersionAlias',
          header: 'Alias',
          sortable: true,
          style: { width: '100px' },
        });
      }

      if (state.campaignVersionSourceTagGroup) {
        s.add({
          field: 'campaignVersionSourceTagGroup',
          header: 'Source Tag',
          sortable: true,
          style: { width: '120px' },
        });
      }

      if (state.socialPostSocialPlatform) {
        s.add({
          field: 'socialPostSocialPlatform',
          header: 'Social Platform',
          sortable: true,
          style: { width: '120px' },
        });
      }

      if (!state.socialPostSocialPlatform) {
        s.add({
          field: 'clientSales',
          header: 'Lightbox Views',
          sortable: true,
          style: { width: '50px' },
        });
      }

      s.add({
        field: 'shares',
        header: 'Shares',
        sortable: true,
        style: { width: '50px' },
      });

      if (!state.socialPostSocialPlatform) {
        s.add({
          field: 'shareRate',
          header: 'Share Rate',
          sortable: true,
          style: { width: '50px' },
        });
      }

      s.add({
        field: 'refClicks',
        header: 'Ref Clicks',
        sortable: true,
        style: { width: '50px' },
      });

      if (!excludeReachMultiple) {
        s.add({
          field: 'reachMultiple',
          header: 'Reach Multiple',
          sortable: true,
          style: { width: '50px' },
        });
      }

      if (showClient) {
        s.add({
          field: 'interstitialClicks',
          header: 'Int.',
          sortable: true,
          style: { width: '50px' },
        });
      }

      let currencySign = '';
      switch (currency) {
      case 'GBP':
        currencySign = '£';
        break;
      case 'USD':
        currencySign = '$';
        break;
      case 'EUR':
        currencySign = '€';
        break;
      case 'ClientCountryCurrency':
        currencySign = '';
        break;
      }

      if (currency === 'OriginalValue') {
        s.add({
          field: 'soretoSales',
          header: 'Soreto Sales',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: 'conversionRate',
          header: 'Conv. Rate',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: `totalValueSales`,
          header: 'Revenue',
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: 'pendingSalesCount',
          header: 'Pend #',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: 'paidSalesCount',
          header: 'Conf #',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: 'declinedSalesCount',
          header: 'Decl #',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: `salesCommission`,
          header: `Com. ${currencySign}`,
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: `pendingSalesCommission`,
          header: `Com. Pen. ${currencySign}`,
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: `paidSalesCommission`,
          header: `Com. Paid ${currencySign}`,
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: `declinedSalesCommission`,
          header: `Com. Decl. ${currencySign}`,
          sortable: true,
          style: { width: '70px', whiteSpace: 'nowrap' },
        });
      } else {
        s.add({
          field: 'soretoSales',
          header: 'Soreto Sales',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: 'conversionRate',
          header: 'Conv. Rate',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: `totalValueSales_${currency}`,
          header: 'Revenue',
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: 'pendingSalesCount',
          header: 'Pend #',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: 'paidSalesCount',
          header: 'Conf #',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: 'declinedSalesCount',
          header: 'Decl #',
          sortable: true,
          style: { width: '50px' },
        });
        s.add({
          field: `salesCommission_${currency}`,
          header: `Com. ${currencySign}`,
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: `pendingSalesCommission_${currency}`,
          header: `Com. Pen. ${currencySign}`,
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: `paidSalesCommission_${currency}`,
          header: `Com. Paid ${currencySign}`,
          sortable: true,
          style: { width: '70px' },
        });
        s.add({
          field: `declinedSalesCommission_${currency}`,
          header: `Com. Decl. ${currencySign}`,
          sortable: true,
          style: { width: '70px', whiteSpace: 'nowrap' },
        });
      }

      return Array.from(s);
    };

    columnsFooter = (
      state,
      totals,
      showClient = true,
      excludeReachMultiple = false,
      showClientCountry = false
    ) => {
      var s = new Set();

      let cl = [
        state.dateGrouping === 'daily' || state.dateGrouping === 'monthly',
        showClient,
        showClientCountry,
        state.showCampaignCountryLevel,
        state.showCampaignLevel,
        state.showCampaignVersionLevel,
        state.showCampaignVersionAliasLevel,
        state.showResponsible,
        state.showUtmCampaign,
        state.clientLaunchDiffDays,
        state.showValidity,
      ];
      let span = cl.filter((c) => c === true).length;

      s.add({ footer: 'Totals:', colSpan: span });

      if (state.campaignVersionSourceTagGroup) {
        s.add({ footer: '', colSpan: 1 });
      }

      if (!state.socialPostSocialPlatform) {
        s.add({ footer: totals.clientSales, colSpan: 1 });
      } else {
        s.add({ footer: '', colSpan: 1 });
      }

      s.add({ footer: totals.shares, colSpan: 1 });

      if (!state.socialPostSocialPlatform) {
        s.add({ footer: totals.shareRate, colSpan: 1 });
      }

      s.add({ footer: totals.clicks, colSpan: 1 });

      if (!excludeReachMultiple) {
        s.add({ footer: totals.reachMultiple, colSpan: 1 });
      }

      s.add({ footer: totals.soretoTraffic, colSpan: 1 });
      s.add({ footer: totals.countSoretoSales, colSpan: 1 });
      s.add({ footer: totals.conversionRate, colSpan: 1 });
      s.add({ footer: totals.totalValueSales, colSpan: 1 });
      s.add({ footer: totals.countSoretoSalesPending, colSpan: 1 });
      s.add({ footer: totals.countSoretoSalesPaid, colSpan: 1 });
      s.add({ footer: totals.countSoretoSalesDeclined, colSpan: 1 });
      s.add({ footer: totals.totalValueSoretoCommission, colSpan: 1 });
      s.add({ footer: totals.totalValueSoretoCommissionPending, colSpan: 1 });
      s.add({ footer: totals.totalValueSoretoCommissionPaid, colSpan: 1 });
      s.add({
        footer: totals.totalValueSoretoCommissionDeclined,
        colSpan: 1,
      });

      return Array.from(s);
    };
}
