import React from 'react';

const getHeader = (tableKeysArray) => {
  return tableKeysArray.map((dataKey , i) => {
    return(
      <th key={i}>
        {
          dataKey.icon ?
            <i className={dataKey.icon}>&nbsp;</i>
            :
            false
        }
        { dataKey.title }
      </th>
    );
  });
};

const getColumns = (data , tableKeysArray) => {
  return tableKeysArray.map((dataKey , i) => {
    return(
      <td key={i}>
        { data[dataKey.key] }
      </td>
    );
  });
};

const getRows = ( data , tableKeysArray , rowEventListener = null) => {
  return data.map((dataItem , i) => {
    return(
      <tr key={i} onClick={rowEventListener? () => rowEventListener(dataItem) : false}>
        { getColumns(dataItem , tableKeysArray) }
      </tr>
    );
  });
};

export const setTable = (data , tableKeysArray , rowEventListener = null , meta = {className : '' , id: ''}) => {
  return(
    <table id={meta.id} className={meta.className}>
      <thead>
      <tr>
        {
          getHeader(tableKeysArray)
        }
      </tr>
      </thead>
      <tbody>
      {
        getRows(data , tableKeysArray , rowEventListener || null)
      }
      </tbody>
    </table>
  );
};