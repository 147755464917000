import React from 'react';
import config from '../../../config/config';
import GlobalMessages from '../globalMessage/GlobalMessage';
import {connect} from 'react-redux';

@connect((store)=>{
  return {
    globalMessage : store.globalMessages.message
  };
})
class FrontPageHeader extends React.Component{

  goToPage(page){
    window.location = `${config.URL.BASEFRONT}/${page}`;
  }

  render(){
    return (
      <div>
        <div className="front-page-header">
          {/* <div className="grid-50 tablet-grid-100 text-right top-buttons">
            <button onClick={() => this.goToPage ('user.html')} className={this.props.appCtx == 'user' ? 'primary current' : 'primary'}>Consumer</button>
            <button onClick={() => this.goToPage ('client.html')} className={this.props.appCtx == 'client' ? 'primary current' : 'primary'}>Business</button>
          </div> */}
          <div className="top-logo-holder grid-50 tablet-grid-100">
            &nbsp;
            <img className="front-page-logo" src="/images/logo-white-front.png" alt="Soreto / Love it share it"/>
          </div>
          {/*<ul className="front-page-menu grid-100 text-right grid-parent inline">*/}
            {/*<li><a href="">How it works</a></li>*/}
            {/*<li><a href="">About us</a></li>*/}
          {/*</ul>*/}
          <div className="clearfix"></div>
        </div>
        <GlobalMessages message={this.props.globalMessage}/>
      </div>
    );
  }
}

export default FrontPageHeader;