import request from '../wrappers/axios';

import config from '../../config/config';

class CampaignService {

  getCampaignList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/campaign/list${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  newCampaign (data) {
    return request({
      url: `${config.API.BASEURL}/campaign`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateCampaign (data) {
    return request({
      url: `${config.API.BASEURL}/campaign/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getCampaign (campaignId) {
    return request({
      url: `${config.API.BASEURL}/campaign/${campaignId}`,
      method: 'get'
    });
  }

  getAllCampaigns (clientId) {
    return request({
      url: `${config.API.BASEURL}/campaign/all?clientId=${clientId}`,
      method: 'get'
    });
  }
  
  getActiveCampaign (clientId, countryCode) {
    return request({
      url: `${config.API.BASEURL}/campaign/active?clientId=${clientId}&countryCode=${countryCode}`,
      method: 'get'
    });
  }
  
  copyCampaign (data){
    return request({
      url: `${config.API.BASEURL}/campaign/${data._id}/copyDeep`,
      method: 'post',
      data: data
    }, true, 500);
  }

  refreshCache (){
    return request({
      url: `${config.API.BASEURL}/campaign/cache/refreshSuperCampaign`,
      method: 'post',
      data: {}
    });
  }
}

export default new CampaignService();