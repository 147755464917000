import displayBlockService from '../../utils/services/displayBlock';
import constants from '../../constants/app-constants';

export const newDisplayBlock = obj => dispatch => {
  return new Promise((resolve, reject) => {
    displayBlockService.newDisplayBlock(obj)
      .then(res => {
        dispatch({
          type: constants.admin.DISPLAY_BLOCK_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.DISPLAY_BLOCK_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateDisplayBlock = (displayBlockObj) => {
  return function(dispatch){
    displayBlockService.updateDisplayBlock(displayBlockObj)
      .then(function(res){
        dispatch({
          type: constants.admin.DISPLAY_BLOCK_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.DISPLAY_BLOCK_EDIT,
          payload: err.response
        });
      });
  };
};

export const getDisplayBlock = (displayBlockId) => {
  return function(dispatch){
    displayBlockService.getDisplayBlock(displayBlockId)
      .then(function(res){
        dispatch({
          type: constants.admin.DISPLAY_BLOCK_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.DISPLAY_BLOCK_GET,
          payload: err.response
        });
      });
  };
};
