import React from 'react';
import validator from 'validator';
import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import * as emailTemplateActions from '../../../../actions/admin/emailTemplateActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import FormElement from '../../../shared/form/FormElement';
import JsonMicroForm from '../../../shared/form/JsonMicroForm';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import KeysEmailTemplateTypeDropbox from '../../../shared/dropbox/KeysEmailTemplateTypeDropbox';

@connect((store) => {
  return {
    emailTemplateNew: store.adminEmailTemplate.emailTemplateNew
  };
})
class EmailTemplateCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      emailTemplateDetails = {
        clientName: '',
        name:'',
        type: '',
        externalTemplateId: '',
        meta: [],
        externalServiceName: 'MANDRILL',
        emailTemplateTypeId:''
      }
    } = props;

    this.state = {
      emailTemplateDetails,
      errors: {},
      displayErrorMessage: false
    };

    this.onClientChange = this.onClientChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.emailTemplateNew != nextProps.emailTemplateNew){
      if(_.get(nextProps,'emailTemplateNew.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.emailTemplateNew.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onChange(name, {target}) {
    const { emailTemplateDetails } = this.state;
    _.set(emailTemplateDetails, name, target.value);
    this.setState({emailTemplateDetails});
  }

  onKeysTemplateChange({value}){
    const { emailTemplateDetails } = this.state;
    if(value){
      value.forEach((item)=>{
        if(item.label.indexOf('- (') < 0){
          item.label = item.label +' - ('+item.templateKey+')';
        }
        return item;
      });
      _.set(emailTemplateDetails, 'meta', value);
      _.set(emailTemplateDetails, 'emailTemplateTypeId', value[0].emailTemplateTypeId);

      const mandrilDefaultexternalIds = {
        'general-email':'generic-email',
        'email-me-code':'' // fill with future default email template IDs
      };

      if(emailTemplateDetails.externalServiceName === 'MANDRILL' && !_.isUndefined(mandrilDefaultexternalIds[value[0].emailTemplateType])){
        _.set(emailTemplateDetails, 'externalTemplateId', mandrilDefaultexternalIds[value[0].emailTemplateType]);
      }

    }else{
      _.set(emailTemplateDetails, 'meta', []);
      _.set(emailTemplateDetails, 'emailTemplateTypeId', '');
      _.set(emailTemplateDetails, 'externalTemplateId', '');
    }

    this.setState({ emailTemplateDetails });
  }

  onJsonTestChange(name, i, {target}){
    const { emailTemplateDetails } = this.state;
    emailTemplateDetails.meta[i].value = target.value;
    this.setState({emailTemplateDetails});
  }

  onClientChange(e){
    const { emailTemplateDetails } = this.state;
    _.set(emailTemplateDetails, 'clientId', e.value);
    _.set(emailTemplateDetails, 'clientName', e.originalEvent.target.innerText);
    this.setState({ emailTemplateDetails });
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { emailTemplateDetails,errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      const emailTemplateDetailsReduced = emailTemplateDetails.meta.reduce((obj,el)=>{  //Reduce template settings
        obj[el.templateKey] = el.value;
        return obj;
      },{});
      emailTemplateDetails.templateValues = JSON.stringify(emailTemplateDetailsReduced);
      const emailTemplateDetailsFliteredEmpty = _.omitBy(emailTemplateDetails,(item)=>item ==='');

      this.props.dispatch(emailTemplateActions.newEmailTemplate(emailTemplateDetailsFliteredEmpty))
        .then(created => browserHistory.push({ pathname: `/admin/emailTemplate/${created._id}` , state: { fromCreatePage: true } }));
    }
  }

  validateForm() {
    const { emailTemplateDetails, errors } = this.state;

    errors['templateErr'] = _.find(emailTemplateDetails.meta, (o) => {
      return (o.required && !o.value);
    });
    errors[ 'name' ] = validator.isEmpty( emailTemplateDetails.name );
    errors[ 'externalTemplateId' ] = validator.isEmpty( emailTemplateDetails.externalTemplateId );
    errors[ 'externalServiceName' ] = validator.isEmpty( emailTemplateDetails.externalServiceName );

    return this.setState({ errors });
  }

  render (){
    const { emailTemplateDetails = { info: {} }, errors } = this.state;

    const {
      name,
      externalTemplateId,
      externalServiceName,
      meta,
      type
    } = emailTemplateDetails;

    return (
      <div className="displayBlock-new-displayBlock">
        <h3>Create email</h3>
        <form  className="c-form--flex">


          <FormElement
            label='Email Template Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-email-template-name'
            required
          />

          <ClientsDropbox
            label="Client"
            name="client"
            required='true'
            onChange={this.onClientChange}
            error={errors.client}
          />

          <FormElement
            label='External Service'
            name='externalServiceName'
            type='select'
            onChange={this.onChange.bind(this, 'externalServiceName')}
            value={externalServiceName}
            error={errors.externalServiceName}
            id='form-externalService'
            required
          >
            <option value="MANDRILL">Mandrill</option>
            <option value="SENDINBLUE">Sendinblue</option>
          </FormElement >

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            error={errors.type}
            id='form-type'
            required
          >
            <option value="">-</option>
            <option value="reward_email">Thank you email</option>
            <option value="email_self_reward">Email me code</option>
            <option value="share_with_friend_email">Share With Friend Email</option>
            <option value="share_with_friend_email_reminder">(Forward Email) Share With Friend Reminder</option>
            <option value="post_purchase_discount_reward_email">Post Purchase Discount Reward Email</option>
            <option value="post_purchase_friend_reward_email">Post Purchase Friend Reward Email</option>
            <option value="shared_url_notification_personal_no_click">Shared Url Notification - No Click (Personal)</option>
            <option value="shared_url_notification_personal_no_order">Shared Url Notification - No Order (Personal)</option>
            <option value="shared_url_notification_sharer_post_reward_no_click">Shared Url Notification - No Click (Sharer Post Reward)</option>
            <option value="shared_url_notification_sharer_post_reward_no_order">Shared Url Notification - No Order (Sharer Post Reward)</option>
            <option value="shared_url_notification_friend_post_reward_no_click">Shared Url Notification - No Click (Friend Post Reward)</option>
            <option value="shared_url_notification_friend_post_reward_no_order">Shared Url Notification - No Order (Friend Post Reward)</option>
          </FormElement >

          <KeysEmailTemplateTypeDropbox
            label="Template Type"
            name="client"
            onChange={this.onKeysTemplateChange.bind(this)}
          />

          <FormElement
            label='External Template Id'
            name='externalTemplateId'
            type='text'
            onChange={this.onChange.bind(this, 'externalTemplateId')}
            value={externalTemplateId}
            error={errors.externalTemplateId}
            id='form-externalTemplateId'
            required
          />

          <JsonMicroForm json={meta}  onChange={this.onJsonTestChange.bind(this, 'meta')}/>

        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Save</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}


export default EmailTemplateCreate;