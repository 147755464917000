import React from 'react';
import _ from 'lodash';

import FormElement from '../../../shared/form/FormElement';
import LiveFrame from './liveFrame';
import PreviewPopup from './PreviewPopup';
import ejs from 'ejs';
import config from '../../../../config/config';
import * as displayBlockActions from '../../../../actions/admin/displayBlockActions';

import { connect } from 'react-redux';

@connect((store) => {
  return {
    displayBlock: store.adminDisplayBlock.displayBlock
  };
})

class PreviewHtml extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      size:{
        height:'510px',
        width:'810px'
      },
      details: {
        layout: 'desktop',
        pageState: 'LOAD',

      },
      previewHtml : '',
      campaignDetailsLightbox: {
        'clientId':'PREVIEW',
        'sharedUrlAccessId':'PREVIEW',
        'page':'emailRequest',
        'discountDetails':{
          'discountCode':'codeNotShown',
          'discountValue':'0',
          'linkExpired':false
        },
        'urlType': 'SHARED',
        'campaignVersion':'PREVIEW',
        'campaignVersionId':'PREVIEW',
        'sharedUrlId':'PREVIEW',
        'requiresUserEmail':false,'testMode':true
      },
      campaignDetails: {
        'discountDetails': {
          'expires': {
            'days': 30,
            'hours': 8
          },
          'discountCode': 'Not-Available',
          'discountValue': '0'
        },
        'requiresUserEmail': true,
        'testMode': true,
        'sharedUrlInfo': {
          'type': 'SHARED',
          'state': 'VALID',
          'expires': {
            'days': 30,
            'hours': 8
          }
        },
        'pageInfo': {
          'loadState': 'LOAD'
        }
      },
      errors: {},
    };
  }

  componentDidMount() {


    this.updatePreview(
      this.props.cssExternal,
      this.props.htmlBody,
      this.props.css,
      this.props.jsExternal,
      this.props.javascript,
      this.state.campaignDetails
    );
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.displayBlockId != this.props.displayBlockId){
      this.props.dispatch(displayBlockActions.getDisplayBlock(nextProps.displayBlockId));
    }

    this.updatePreview(
      nextProps.cssExternal,
      nextProps.htmlBody,
      nextProps.css,
      nextProps.jsExternal,
      nextProps.javascript,
      this.state.campaignDetails
    );
  }

  updatePreview(
    cssExternal = '',
    htmlBody = '',
    css = '',
    jsExternal = '',
    javascript = '',
    campaignDetails = {},
  ){
    var sharerEmail = '';
    var htmlContent = `
          <!DOCTYPE html>
          <html>
          <head>
            <title>Soreto Placement</title>
            ${cssExternal}
            <style>
              .srt-hide { display: none; }
              ${css}
            </style>
          </head>

          <body>
            <script id="reverb-sdk" src="<%= apiBaseUrl %>/scripts/reverb-placement-sdk.min.js"></script>
            ${htmlBody}
            ${jsExternal}
            <script>
              var campaignDetails = ${JSON.stringify(campaignDetails)};
              var details = ${JSON.stringify(campaignDetails)};
              var sharerEmail = '${sharerEmail}';

              document.addEventListener("DOMContentLoaded", document.querySelectorAll('[data-srt-reward-group-alias]').forEach((element)=>{
              setTimeout(function(){ element.classList.remove("srt-hide"); }, 500);
              }))
              

            </script>
            <script>
              var interstitialStateHandling = Webpack.interstitialStateHandling();
              interstitialStateHandling.initInterstitialStateHandling(details);
              ${javascript}
              function sendTracking(empty,empty,cb){ //null tracking on testing env
                if(cb){
                  return cb
                }
              }
            </script>
          </body>
          </html>
    `;
    let templateParams = {
      apiBaseUrl:config.URL.BASEBACK,
      backUrl:config.URL.BASEBACK,
      clientId: '',
      hostingSrc: '',
      title:  'Soreto Discount!',
      discountCode: 'discount code',
      discountValue: 'discount val',
      imageUrl: '',
      productUrl: '',
      firstName: 'a friend',
      sharedUrlId: '',
    };
    try {
      let html = ejs.render(htmlContent, templateParams);
      this.setState({previewHtml:html});
    }
    catch(error) {
      console.log('ejs template error', error);
      this.setState({previewHtml:htmlContent});
    }
  }

  onResize(name, {target}) {
    const { size, details } = this.state;
    if(target.value === 'desktop') {
      _.set(size, 'height', '801px');
      _.set(size, 'width', '1200px');
    } else if(target.value === 'mobile') {
      _.set(size, 'height', '451px');
      _.set(size, 'width', '301px');
    }

    _.set(details, 'layout', target.value);
    this.setState({details});
    this.setState({size});
  }

  onUpdateStore(name, {target}) {
    const { campaignDetails } = this.state;

    if(target.id == 'page-state'){
      _.set(campaignDetails, 'pageInfo', {loadState: target.value } );
    }
    if(target.id == 'url-state'){
      _.set(campaignDetails, 'sharedUrlInfo.state', target.value );
    }
    if(target.id == 'url-type'){
      _.set(campaignDetails, 'sharedUrlInfo.type', target.value );
    }
    if(target.id == 'email-required'){
      _.set(campaignDetails, 'requiresUserEmail', target.value );
    }

    this.updatePreview(
      this.props.cssExternal,
      this.props.htmlBody,
      this.props.css,
      this.props.jsExternal,
      this.props.javascript,
      campaignDetails
    );
    this.setState({campaignDetails});
  }


  render() {
    const { details, campaignDetails, size, previewHtml } = this.state;

    return (
      <div>
        <FormElement
          label='Layout'
          name='layout'
          type='select'
          onChange={this.onResize.bind(this, 'layout')}
          value={details.layout}
          id='form-active'
        >
          <option value="desktop">desktop</option>
          <option value="mobile">mobile</option>
        </FormElement>

        <br/>

        {this.props.displayBlock && this.props.displayBlock.type == 'interstitial' &&

        <div>
          <FormElement
            label='Page Load state'
            name='loadState'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'loadState')}
            value={campaignDetails.pageInfo.loadState}
            id='page-state'
          >
            <option value="LOAD">LOAD</option>
            <option value="SHOWCODE">SHOWCODE</option>
          </FormElement>
          <div className="drop-down-info">
            data-srt-page-state="{campaignDetails.pageInfo.loadState}"
          </div>
          <br/>
          <br/>

          <FormElement
            label='Page URL state'
            name='urlState'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'urlState')}
            value={campaignDetails.sharedUrlInfo.state}
            id='url-state'
          >
            <option value="EXPIRED">EXPIRED</option>
            <option value="VALID">VALID</option>
            <option value="BLOCKED_SHARER_ACCESS">BLOCKED SHARER ACCESS</option>
          </FormElement>
          <div className="drop-down-info">
          data-srt-shared-url-state="{campaignDetails.sharedUrlInfo.state}"
          </div>
          <br/>
          <br/>
          <FormElement
            label='shared url type'
            name='urlType'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'urlType')}
            value={campaignDetails.sharedUrlInfo.type}
            id='url-type'
          >
            <option value="SHARED">SHARED</option>
            <option value="PERSONAL">PERSONAL</option>
            <option value="SHARER_POST_REWARD">SHARER POST REWARD</option>
            <option value="FRIEND_POST_REWARD">FRIEND POST REWARD</option>
          </FormElement>
          <div className="drop-down-info">
          data-srt-shared-url-type="{campaignDetails.sharedUrlInfo.type}"
          </div>
          <br/>
          <br/>
          <FormElement
            label='require email'
            name='emailRequired'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'emailRequired')}
            value={campaignDetails.requiresUserEmail}
            id='email-required'
          >
            <option value="TRUE">TRUE</option>
            <option value="FALSE">FALSE</option>
          </FormElement>
          <div className="drop-down-info">
          data-srt-require-email="{!campaignDetails.requiresUserEmail || campaignDetails.requiresUserEmail == 'FALSE'  ?'FALSE':'TRUE'}"
          </div>
          <br/>
          <br/>
        </div>
        }
        <LiveFrame size={size} htmlContent={previewHtml} />

        <PreviewPopup htmlContent={previewHtml}/>
      </div>
    );
  }
}

export default PreviewHtml;
