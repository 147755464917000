import React from 'react';
import classnames from 'classnames';
import {camelCase} from 'lodash';

import { Controlled as CodeMirror } from 'react-codemirror2';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { Password } from 'primereact/components/password/Password';
import FormElement from './FormElement';

import './FormElement.scss';

require('codemirror/mode/htmlembedded/htmlembedded');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/css/css');
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');

export default function JsonMicroForm( { ...props } ) {

  const cx = classnames('c-form-element', props.className, {
    'c-form-element__error': props.error,
    'c-form-element__flex': props.flex,
    'c-form-element--inline': props.inline
  });

  const { onChange, json = [] } = props;


  const onItemChange = function(i,item){
    onChange(i,item);
  };

  const createFrom = () =>{
    let form = [];
    json.forEach(function(element,i) {
      var value = element.value || element.defaultValue || '';
      form.push(
        <FormElement
          label={element.label}
          key={'microForm-item-'+i}
          name={camelCase(element.name)}
          type={element.inputType}
          onChange={onItemChange.bind(this,i)}
          value={value}
          id='form-email-template-name'
          required={element.required ? element.required :false}
        />
      );
    });

    return form;
  };

  return (
    <div className={ cx }>
      {createFrom()}
    </div>
  );

}