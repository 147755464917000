import constants from '../../constants/app-constants';
import config from '../../config/config';

const initialState = {
  userSocialPlatforms: [],
  currentlyPosting: false,
  modalIsOpen: false
}

const UserSocial = (state = initialState , action) => {
  switch(action.type){
  case constants.user.SOCIALPOST_SETUSERSOCIALPLATFORMS : {
    return {...state , userSocialPlatforms: action.payload};
  }

  case constants.user.SOCIALPOST_UPDATECURRENTLYPOSTINGSTATUS : {
    return {...state , currentlyPosting : action.payload};
  }

    case constants.user.SOCIALPOST_SETCONNECTMODALSTATUS : {
      return {...state , modalIsOpen : action.payload};
    }

  default:{
    return state;
  }
  }
};

export default UserSocial;