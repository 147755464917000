import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import{ InputText  } from 'primereact/components/inputtext/InputText';
import moment from 'moment';

import reportService from '../../../../../utils/services/reports';

class LiveClientsList extends React.Component{
  constructor() {
    super();
    this.state = {
      liveClientList: []
    };
    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.formatHits = this.formatHits.bind(this);
    this.withToolTip = this.withToolTip.bind(this);
  }

  getQuery(event) {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }
    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {

    for(let row of data.data.page) {
      row.placementCreatedAt = moment(row.placementCreatedAt).format('YYYY-MM-DD HH:MM:SS');
      row.pixelCreatedAt = moment(row.pixelCreatedAt).format('YYYY-MM-DD HH:MM:SS');
    }

    this.setState({
      liveClientList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});;

    reportService.getLiveClients (this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });

  }

  formatHits(rowData, column) {
    return rowData[column.field] ? <span className="fa fa-check-circle" style={{color: 'green'}}></span> : <span className="fa fa-times-circle" style={{color: 'red'}}></span>;
  }

  withToolTip(rowData, column)
  {
    const value = rowData[column.field];
    return <span title={value}>{value}</span>;
  }


  resetFilter = (event) => {
    this.setState({
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  renderDataTable() {

    return (
      <DataTable
        value={this.state.liveClientList}
        responsive={true}
        paginator={true} rows={30}
        rowsPerPageOptions={[5,10,20,30, 50, 100,500,1000]}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        loading={this.state.loading}
        globalFilter={this.state.globalFilter} 
        first = {this.state.first}>

        <Column field="lightboxHit" header="Lightbox Hit" body={this.formatHits} style={{width:'25px'} }/>
        <Column field="trackingHit" header="Tracking Hit" body={this.formatHits} style={{width:'25px'}}/>
        <Column field="clientId" header="Client ID" style={{width:'65px'}}/>
        <Column field="name" header="Client Name" style={{width:'50px'}}/>
        <Column field="placementCreatedAt" header="Placement Date" style={{width:'55px'}}/>
        <Column field="placementEmail" header="Placement Email" body = {this.withToolTip} style={{width:'70px', overflow: 'hidden', maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'noWrap'}}/>
        <Column field="placementFirstName" header="First Name" style={{width:'40px'}}/>
        <Column field="placementReferrer" header="Placement Referrer" body = {this.withToolTip} style={{width:'70px', overflow: 'hidden', maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'noWrap'}}/>
        <Column field="pixelCreatedAt" header="Pixel Date" style={{width:'55px'}}/>
        <Column field="pixelEmail" header="Pixel Email" body = {this.withToolTip} style={{width:'70px', overflow: 'hidden' , maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'noWrap'} }/>
        <Column field="pixelOrderId" header="Order Id" body = {this.withToolTip} style={{width:'30px', overflow: 'hidden', maxWidth: '100px', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}/>
        <Column field="pixelOrderTotal" header="Pixel Order Total" style={{width:'30px'}}/>
      </DataTable>
    );
  }
  render() {

    return (
      <div>
        <div className="content-section implementation">

          <h3>Live Clients - Last 24 Hours</h3>
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText 
                  onChange={(e) => this.setState({ searchField: e.target.value })} 
                  onKeyDown = {(e) => {if(e.keyCode === 13) this.onLazyLoad(e);}}
                  value={this.state.searchField}/>
              </span>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
            </div>
          </div>
        </div>
        {this.renderDataTable()}
      </div>
    );
  }

}

export default LiveClientsList;