import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Panel } from 'primereact/components/panel/Panel';
import Label from '../../../shared/label/label';
import reportServiceService from '../../../../utils/services/reports';
import moment from 'moment';

export default class PanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: 0,
      resultData: [],
      totalCount: 0,
      rows: 10,
      loading: true
    };
  }

  urlDisplay = rowData => {
    return <div style={{ wordWrap: 'break-word' }}>{rowData.key}</div>;
  };

  dateField = rowData => {
    return moment(rowData.date).format('DD-MM-YYYY HH:mm:ss');
  };

  status = rowData => {
    let presentInWhiteList = this.props.whiteList 
      && this.props.whiteList.find(whiteListItem => rowData.key.includes(whiteListItem));

    let presentInBlackList = this.props.blackList 
      && this.props.blackList.find(blackListItem => rowData.key.includes(blackListItem));

    return (
      <React.Fragment>
        {!presentInWhiteList && !presentInBlackList ? (
          <Label color="grey" text={'Not mapped'} />
        ) : (
          <React.Fragment>
            {presentInWhiteList && (
              <Label color="white" text={'White Listed'} textColor="black" />
            )}
            {presentInBlackList && (
              <Label color="black" text={'Black Listed'} />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  onLazyLoad = event => {
    this.setState({ loading: true });
    let first, rows;
    if (event) {
      first = event.first;
      rows = event.rows;
      this.setState({ ...this.state, ...event });
    } else {
      first = this.state.first;
      rows = this.state.rows;
    }

    let externalId = this.props.externalId;
    let internalId = this.props.internalId;
    const startDate = moment(new Date())
      .subtract(7, 'days')
      .format('YYYY-MM-DD');
    const endDate = moment(new Date()).format('YYYY-MM-DD');

    let query = { internalId, externalId, first, rows, startDate, endDate };
    reportServiceService.getOriginUrls(query).then(response => {
      let result = [];
      for (let row of JSON.parse(JSON.stringify(response.data.resultData))) {
        result.push({ key: row.url, date: row.date });
      }
      this.setState({
        resultData: result,
        totalCount: response.data.totalCount,
        loading: false
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.update !== this.props.update) {
      this.onLazyLoad();
    }
  }

  render() {
    return (
      <div className="content-section implementation">
        <Panel
          header={<span style={{ color: '#485465' }}>{this.props.name}</span>}
        >
          <DataTable
            first={this.state.first}
            value={this.state.resultData}
            responsive={true}
            scrollable 
            scrollHeight="717px"
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
            lazy={true}
            onLazyLoad={this.onLazyLoad}
            ref={el => (this.dt = el)}
            totalRecords={this.state.totalCount}
            loading={this.state.loading}
          >
            <Column
              body={this.urlDisplay}
              header="Url"
              // sortable={true}
              style={{ width: '100px' }}
            />
            <Column
              body={this.dateField}
              header="Date"
              style={{ width: '20px' }}
            />
            <Column
              body={this.status}
              header="Mapping"
              style={{ width: '20px' }}
            />
          </DataTable>
        </Panel>
      </div>
    );
  }
}
