import request from '../wrappers/axios';
import config from '../../config/config';

class AffiliateService {

  getAffiliateList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/affiliate/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  newAffiliate (data) {
    return request({
      url: `${config.API.BASEURL}/affiliate`,
      method: 'post',
      data: data
    }, true, 500);
  }

  getAllAffiliate(){
    return request({
      url: `${config.API.BASEURL}/affiliate`,
      method: 'get',
    });
  }

  updateAffiliate (data) {
    return request({
      url: `${config.API.BASEURL}/affiliate/${data._id}`,
      method: 'patch',
      data: data
    }, true, 500);
  }

  getAffiliate (affiliateId) {
    return request({
      url: `${config.API.BASEURL}/affiliate/${affiliateId}`,
      method: 'get'
    });
  }
}

export default new AffiliateService();