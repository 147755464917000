import request from '../wrappers/axios';
import config from '../../config/config';

class DisplayBlockService {

  getDisplayBlockList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/displayBlock${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  newDisplayBlock (data) {
    return request({
      url: `${config.API.BASEURL}/displayBlock`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateDisplayBlock (data) {
    return request({
      url: `${config.API.BASEURL}/displayBlock/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getDisplayBlock (displayBlockId) {
    return request({
      url: `${config.API.BASEURL}/displayBlock/${displayBlockId}`,
      method: 'get'
    });
  }

  getAllDisplayBlocks(campaignVersionId) {
    return request({
      url: `${config.API.BASEURL}/displayBlock/all?campaignVersionId=${campaignVersionId}`,
      method: 'get'
    });
  }
}

export default new DisplayBlockService();