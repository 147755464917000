import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Row } from 'primereact/components/row/Row';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

import  reportServiceService from '../../../../utils/services/reports';

import ChartDataComparison from './Analytics.ChartDataComparison';
import DataBox from './Analytics.DataBox';
import { DataRangePicker, dateIndexSelection } from '../../../shared/dataRangePicker/dataRangePicker';
import * as currencySwitcherActions from '../../../../actions/shared/currencySwitcherActions';

import { withClientStats } from '../../../../decorators/clientStatsDecorator/clientStats-decorator-bi-v2';
import { withQueryHelper } from '../../../../decorators/queryHelperDecorator/queryHelper-decorator';
import { formatterNumber } from '../../../../utils/helpers/ClientStatsBI';
import {getCurrencySymbolByCode, dynamicFormatter} from '../../../../utils/helpers/money';

let _chartLabels = ['Shares', 'Clicks', 'Soreto Sales'];
const labelMapper = {
  SHARES: 0,
  CLICKS: 1,
  SORETO_SALES: 2,
  PREVIOUS_SHARES: 3,
  PREVIOUS_CLICKS: 4,
  PREVIOUS_SORETO_SALES: 5
};

var _defaultCurrencies = [
  { key: 'GBP', label: 'GBP', value:'GBP', default: true }, 
  { key: 'USD', label: 'USD', value:'USD', default: true }, 
  { key: 'EUR', label: 'EUR', value:'EUR', default: true }
];

const esc = ['6698083122e444167ac95858'];

@withClientStats
@withQueryHelper
export class AnalyticsBi extends React.Component {
  constructor(props) {
    super(props);

    this.event = {};

    this.state = {
      loading: false,
      chartData: {},

      reachMultiple: '',
      shares: '',
      sharesRate: '',
      clicks: '',
      clientSales: '',
      conversionRate: '',
      soretoSales: '',
      soretoSalesRate: '',
      totalValueSoretoSales: '',
      totalValueSoretoCommission: '',
      ROI: '',
      totalValueSoretoCommissionPending: 0,
      totalValueSoretoCommissionPaid: 0,
      totalValueSoretoCommissionDeclined: 0,

      previousShares: null,
      previousSoretoSales: null,
      previousClientSales: null,
      previousClicks: null,
      previousSharesRate: null,
      previousSoretoSalesRate: null,
      previousConversionRate: null,
      previousTotalValueSoretoSales: null,
      previousTotalCommissionSoretoSales: null,
      previousROI: null,

      variationInReachMultiple: '',
      variationInShares: '',
      variationInSoretoSales: '',
      variationInClientSales: '',
      variationInClicks: '',
      variationInSharesRate: '',
      variationInSoretoSalesRate: '',
      variationInConverionRate: '',
      variationInTotalValueSoretoSales: '',
      variationInTotalCommissionSoretoSales: '',
      variationInROI: '',

      clientStats: [],
      startDate: props.calendar[0].startDate,
      endDate: props.calendar[0].endDate,
      previousStartDate: '',
      previousEndDate: '',
      showDaysWithoutSales: true,
      showDaysWithoutClicks: true,
      showDaysWithoutShares: true,
      showDaily: true,
      showCampaignLevel: false,
      showCampaignVersionLevel: false,
      //showActiveClients: true,
      searchField: '',
      cols: [],
      colsFooter: [],
      first: 0,
      rows: 100,
      multiSortMeta: [{field: 'eventDateTerm', order: 1}],
      excludeRevenue: true,
      currencyFieldValue: _defaultCurrencies.find(c => c.value == props.selectedCurrency).value || 'ClientCountryCurrency' ,
      currencyCode: props.selectedCurrency,
      currencySign: getCurrencySymbolByCode(props.selectedCurrency),
      resOriginal: null
    };
  }

  componentDidMount = () => {
    this.fetchCurrentData();
  };

  fetchCurrentData = (setPreviousData = false) => {
    const query = this.getQuery(this.event, false, this);
    query.$limit = 1000000;

    this.event.multiSortMeta = this.state.multiSortMeta;
    const extendedQuery = this.getExtendedQuery(this.event, this);

    reportServiceService.getLiveStats(query, extendedQuery).then(result => {
      // Call this method firts to set this.totals
      this.formatData(result.data, false);
      this.setChartDataComparison(result);

      if (setPreviousData) {
        this.fetchPreviousData(setPreviousData);
      }
    });
  };

  fetchPreviousData = (previousData = false) => {
    const query = this.getQuery(this.event, previousData, this);
    query.$limit = 1000000;
    const extendedQuery = this.getExtendedQuery(this.event, this);

    reportServiceService
      .getLiveStats(query, extendedQuery)
      .then(result => {
        const excludeReachMultiple = true;
        this.previousTotals = this.setClientTotalStats({ data : { page : result.data.data } }, excludeReachMultiple, this.state.currencyCode);
        this.updateDataVariation();
        this.setPreviousChartDataComparison({ data : { page : result.data.data } });
      });
  };

  updateDateRange = date => {
    if (date && Array.isArray(date) && date.length === 1) {
      const startDate = date[dateIndexSelection.FIRST].startDate;
      const endDate = date[dateIndexSelection.FIRST].endDate;

      this.setState(
        {
          startDate,
          endDate,
          previousShares: null,
          previousSoretoSales: null,
          previousClientSales: null,
          previousClicks: null,
          previousSharesRate: null,
          previousSoretoSalesRate: null,
          previousConversionRate: null,
          previousReachMultiple: null,
          previousTotalValueSoretoSales: null,
          previousTotalCommissionSoretoSales: null,
          previousROI: null,
          variationInReachMultiple: null,
          variationInShares: null,
          variationInSoretoSales: null,
          variationInClientSales: null,
          variationInClicks: null,
          variationInSharesRate: null,
          variationInSoretoSalesRate: null,
          variationInConverionRate: null,
          variationInTotalValueSoretoSales: null,
          variationInTotalCommissionSoretoSales: null,
          variationInROI: null,
        },
        () => {

          this.onLazyLoad();
          this.fetchCurrentData();
        }
      );
    } else if (date && Array.isArray(date) && date.length === 2) {
      const startDate = date[dateIndexSelection.FIRST].startDate;
      const endDate = date[dateIndexSelection.FIRST].endDate;
      const previousStartDate = date[dateIndexSelection.SECOND].startDate;
      const previousEndDate = date[dateIndexSelection.SECOND].endDate;
      const setPreviousData = true;

      this.setState(
        { startDate, endDate, previousStartDate, previousEndDate },
        () => {
          this.onLazyLoad();
          this.fetchCurrentData(setPreviousData);
        }
      );
    }
  };
  
  componentWillReceiveProps(props){
    this.bindResult(props.selectedCurrency);
  }

  updateDataVariation = () => {

    const currentShares = this.state.shares;
    const currentSoretoSales = this.state.soretoSales;
    const currentClientSales = this.state.clientSales;
    const currentClicks = this.state.clicks;
    const currentSharesRate = this.state.sharesRate;
    const currentSoretoSalesRate = this.state.soretoSalesRate;
    const currentConversionRate = this.state.conversionRate;
    const currentReachMultiple = this.state.reachMultiple;
    const currentTotalValueSoretoSales = this.state.totalValueSoretoSales;
    const currentTotalCommissionSoretoSales = this.state.totalValueSoretoCommission;
    const currentROI = this.state.ROI;

    const previousShares = this.previousTotals.shares;
    const previousSoretoSales = this.previousTotals.countSoretoSales;
    const previousClientSales = this.previousTotals.clientSales;
    const previousClicks = this.previousTotals.clicks;
    const previousSharesRate = this.previousTotals.shareRate;
    const previousReachMultiple = this.previousTotals.reachMultiple;
    const previousSoretoSalesRate = formatterNumber.format(+this.previousTotals.shares > 0 ? (+this.previousTotals.countSoretoSales / +this.previousTotals.shares)*100 : 0);
    const previousConversionRate = formatterNumber.format(+this.previousTotals.clicks > 0 ? (+this.previousTotals.countSoretoSales / +this.previousTotals.clicks)*100 : 0);
    const previousTotalValueSoretoSales = this.previousTotals.totalValueSoretoSales.replace('£', '');
    const previousTotalCommissionSoretoSales = this.previousTotals.totalValueSoretoCommission.replace('£', '');
    const previousROI = ((previousTotalValueSoretoSales - previousTotalCommissionSoretoSales) / previousTotalCommissionSoretoSales) * 100;

    let variationInShares = (((currentShares / previousShares) - 1) * 100).toFixed(2);
    variationInShares = (variationInShares === 'Infinity' || isNaN(variationInShares)) ? '0%' : variationInShares + '%';

    let variationInSoretoSales = (((currentSoretoSales / previousSoretoSales) - 1) * 100).toFixed(2);
    variationInSoretoSales = (variationInSoretoSales === 'Infinity' || isNaN(variationInSoretoSales)) ? '0%' : variationInSoretoSales + '%';

    let variationInClientSales = (((currentClientSales / previousClientSales) - 1) * 100).toFixed(2);
    variationInClientSales = (variationInClientSales === 'Infinity' || isNaN(variationInClientSales)) ? '0%' : variationInClientSales + '%';

    let variationInClicks = (((currentClicks / previousClicks) - 1) * 100).toFixed(2);
    variationInClicks = (variationInClicks === 'Infinity' || isNaN(variationInClicks)) ? '0%' : variationInClicks + '%';

    let variationInSharesRate = (((currentSharesRate.replace('%', '') / previousSharesRate.replace('%', '') ) - 1) * 100).toFixed(2);
    variationInSharesRate = (variationInSharesRate === 'Infinity' || isNaN(variationInSharesRate)) ? '0%' : variationInSharesRate + '%';

    let variationInSoretoSalesRate = (((currentSoretoSalesRate.replace('%', '') / previousSoretoSalesRate.replace('%', '') ) - 1) * 100).toFixed(2);
    variationInSoretoSalesRate = (variationInSoretoSalesRate === 'Infinity' || isNaN(variationInSoretoSalesRate)) ? '0%' : variationInSoretoSalesRate + '%';

    let variationInConverionRate = (((currentConversionRate.replace('%', '') / previousConversionRate.replace('%', '') ) - 1) * 100).toFixed(2);
    variationInConverionRate = (variationInConverionRate === 'Infinity' || isNaN(variationInConverionRate)) ? '0%' : variationInConverionRate + '%';

    let variationInReachMultiple = (((currentReachMultiple / previousReachMultiple) - 1) * 100).toFixed(2);
    variationInReachMultiple = (variationInReachMultiple === 'Infinity' || isNaN(variationInReachMultiple)) ? '0%' : variationInReachMultiple + '%';

    let variationInTotalValueSoretoSales = (((currentTotalValueSoretoSales / previousTotalValueSoretoSales) - 1) * 100).toFixed(2);
    variationInTotalValueSoretoSales = (variationInTotalValueSoretoSales === 'Infinity' || isNaN(variationInTotalValueSoretoSales)) ? '0%' : variationInTotalValueSoretoSales + '%';

    let variationInTotalCommissionSoretoSales = (((currentTotalCommissionSoretoSales / previousTotalCommissionSoretoSales) - 1) * 100).toFixed(2);
    variationInTotalCommissionSoretoSales = (variationInTotalCommissionSoretoSales === 'Infinity' || isNaN(variationInTotalCommissionSoretoSales)) ? '0%' : variationInTotalCommissionSoretoSales + '%';

    let variationInROI = (((currentROI / previousROI) - 1) * 100).toFixed(2);
    variationInROI = (variationInROI === 'Infinity' || isNaN(variationInROI)) ? '0%' : variationInROI + '%';

    this.setState({

      previousShares,
      previousSoretoSales,
      previousClientSales,
      previousClicks,
      previousSharesRate,
      previousSoretoSalesRate,
      previousConversionRate,
      previousReachMultiple,
      previousTotalValueSoretoSales,
      previousTotalCommissionSoretoSales,
      previousROI,

      variationInReachMultiple,
      variationInShares,
      variationInSoretoSales,
      variationInClientSales,
      variationInClicks,
      variationInSharesRate,
      variationInSoretoSalesRate,
      variationInConverionRate,
      variationInTotalValueSoretoSales,
      variationInTotalCommissionSoretoSales,
      variationInROI
    });
  };

  setChartDataComparison = data => {
    const sharesColor = '#236a62', clicksColor = '#46b29d', SoretoSalesRateColor = '#73f9e5';
    const datasetsColor = [sharesColor, clicksColor, SoretoSalesRateColor];

    var chartData = {
      labels: [],
      datasets: []
    };

    for (let i = 0; i < _chartLabels.length; i++) {
      chartData.datasets[i] = {
        label: _chartLabels[i],
        data: [],
        backgroundColor: datasetsColor[i],
        borderColor: datasetsColor[i],
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        fill: false,
        xAxisID: 'x-axis-1'
      };
    }

    data.data.data.forEach(data => {
      chartData.labels.push(moment(data.eventDateTerm).format('DD/MMM/YYYY'));
      chartData.datasets[labelMapper.SHARES].data.push(data.shares);
      chartData.datasets[labelMapper.CLICKS].data.push(data.refClicks);
      chartData.datasets[labelMapper.SORETO_SALES].data.push(data.soretoSales);
    });

    this.setState({ chartData: chartData });
  };

  setPreviousChartDataComparison = data => {
    const previousSharesColor = '#961238', previousClicksColor = '#f53855', previousSoretoSalesColor = '#fb9fad';
    const datasetsColor = [previousSharesColor, previousClicksColor, previousSoretoSalesColor];
    const previousChartLabels = [ 'Previous Shares', 'Previous Clicks', 'Previous Soreto Sales' ];

    let currentChartData = Object.assign({}, this.state.chartData);
    let counter = currentChartData.datasets.length;

    for (let i = 0; i < previousChartLabels.length; i++, counter++) {
      currentChartData.datasets[counter] = {
        label: previousChartLabels[i],
        data: [],
        backgroundColor: datasetsColor[i],
        borderColor: datasetsColor[i],
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        fill: false,
        xAxisID: 'x-axis-2'
      };
    }

    let label = [];

    data.data.page.forEach(data => {
      label.push(moment(data.eventDateTerm).format('DD/MMM/YYYY'));
      currentChartData.datasets[labelMapper.PREVIOUS_SHARES].data.push(data.shares);
      currentChartData.datasets[labelMapper.PREVIOUS_CLICKS].data.push(data.refClicks);
      currentChartData.datasets[labelMapper.PREVIOUS_SORETO_SALES].data.push(data.soretoSales);
    });

    for (let i = 0; i < currentChartData.labels.length; i++) {
      currentChartData.labels[i] = currentChartData.labels[i] + '#' + label[i];
    }

    this.setState({ chartData: currentChartData });
  };

  formatData = (result, setState) => {
    
    const excludeReachMultiple = true;

    let resOriginal = _.get(result, 'data') ? JSON.parse(JSON.stringify(result.data)) : null;

    this.totals = this.setClientTotalStats({ data : { page : result.data } }, excludeReachMultiple, this.state.currencyFieldValue);
    const showClientColumn = false;

    if (setState == true || setState == null) {
      this.setState({
        resOriginal,
        clientStats: result.data,
        totalRecords: result.data.length,
        loading: false,
        cols: this.dataTableColumns(this.state.currencyFieldValue, this.state.currencyCode),
        colsFooter: this.dataTableColumnsFooter(showClientColumn)
      });
    } 
    
    if (setState == false || setState == null) {

      const totalValueSoretoSales = this.totals.totalValueSales.replace(/[^\d|.-]/g, '');
      let commission = this.totals.totalValueSoretoCommission.replace(/[^\d|.-]/g, '');
      
      let ROI = ((totalValueSoretoSales - Number(commission.replace(',',''))) / Number(commission.replace(',',''))) * 100;
      
      ROI = ROI === Infinity ? '-' : ROI; 

      this.setState({
        shares: this.totals.shares,
        sharesRate: this.totals.shareRate,
        clicks: this.totals.clicks,
        clientSales: this.totals.clientSales,
        conversionRate: this.totals.conversionRate,
        soretoSales: this.totals.countSoretoSales,
        soretoSalesRate: this.totals.purchaseRate,
        reachMultiple: this.totals.reachMultiple,
        totalValueSoretoSales: totalValueSoretoSales,
        totalValueSoretoCommission: commission,
        ROI: isNaN(ROI) ? '-' : ROI.toFixed(2)
      });
    }
  };

  dataTableColumns = (currencyField = 'GBP', currencyCode) => {

    var s = new Set();
    
    s.add({ field:'eventDateTerm', header:'Date', sortable:true, style:{width:'100px'} });
    s.add({ field:'clientSales', header:'Lightbox Views', sortable:true, style:{width:'60px'} });
    s.add({ field:'shares', header:'Shares', sortable:true, style:{width:'60px'} });
    s.add({ field:'shareRate', header:'Share Rate', sortable:true, style:{width:'60px'}  });
    s.add({ field:'refClicks', header:'Ref Clicks', sortable:true, style:{width:'60px'} });

    
    let currencySign = getCurrencySymbolByCode(currencyCode);

    s.add({ field:'soretoSales', header:'Soreto Sales', sortable:true, style:{width:'60px'} });
    s.add({ field:'purchaseRate', header:'Soreto Sales Rate', sortable:true, style:{width:'60px'} });
    
    s.add({ field:'conversionRate', header:'Conv. Rate', sortable:true, style:{width:'60px'} });
    s.add({ field:'pendingSalesCount', header:'Pend #', sortable:true, style:{width:'60px'} });
    s.add({ field:'paidSalesCount', header:'Conf #', sortable:true, style:{width:'60px'} });
    s.add({ field:'declinedSalesCount', header:'Decl #', sortable:true, style:{width:'60px'} });
    s.add({ field:`salesCommission_${currencyField}`, header:`Total ${currencySign}`, sortable:true, style:{width:'80px'} });
    s.add({ field:`pendingSalesCommission_${currencyField}`, header:`Pend ${currencySign}`, sortable:true, style:{width:'80px'} });
    s.add({ field:`paidSalesCommission_${currencyField}`, header:`Conf ${currencySign}`, sortable:true, style:{width:'80px'} });
    s.add({ field:`declinedSalesCommission_${currencyField}`, header:`Decl ${currencySign}`, sortable:true, style:{width:'80px', whiteSpace:'nowrap'} });

    return Array.from(s);
  }

  dataTableColumnsFooter = (showClientColumn) => {
    var s = new Set();

    s.add({ footer: 'Totals', style:{width:'100px'} });
    s.add({ footer: this.totals.clientSales, style:{width:'60px'} });
    s.add({ footer: this.totals.shares, style:{width:'60px'} });
    s.add({ footer: this.totals.shareRate, style:{width:'60px'}  });
    s.add({ footer: this.totals.clicks, style:{width:'60px'} });

    if(showClientColumn){
      s.add({ footer: this.totals.soretoTraffic, style:{width:'60px'} });
      s.add({ footer: this.totals.interstitialRate, style:{width:'60px'} });
    }
    s.add({ footer: this.totals.countSoretoSales, style:{width:'60px'} });
    s.add({ footer: this.totals.purchaseRate, style:{width:'60px'} });
    s.add({ footer: this.totals.conversionRate, style:{width:'60px'} });
    s.add({ footer: this.totals.countSoretoSalesPending, style:{width:'60px'} });
    s.add({ footer: this.totals.countSoretoSalesPaid, style:{width:'60px'} });
    s.add({ footer: this.totals.countSoretoSalesDeclined, style:{width:'60px'} });

    let notFormatted = !isNaN(this.totals.totalValueSoretoCommission);
    
    // if it is not formmated, format it
    if(notFormatted){

      let f = dynamicFormatter(this.state.currencyCode);
      this.totals.totalValueSoretoCommission = f.format(this.totals.totalValueSoretoCommission);
      this.totals.totalValueSoretoCommissionPending = f.format(this.totals.totalValueSoretoCommissionPending);
      this.totals.totalValueSoretoCommissionPaid = f.format(this.totals.totalValueSoretoCommissionPaid);
      this.totals.totalValueSoretoCommissionDeclined = f.format(this.totals.totalValueSoretoCommissionDeclined);
    }    

    s.add({ footer: this.totals.totalValueSoretoCommission, style:{width:'80px'} });
    s.add({ footer: this.totals.totalValueSoretoCommissionPending, style:{width:'80px'} });
    s.add({ footer: this.totals.totalValueSoretoCommissionPaid, style:{width:'80px'} });
    s.add({ footer: this.totals.totalValueSoretoCommissionDeclined, style:{width:'80px'} });

    return Array.from(s);

  }

  dateTemplate = rowData => {
    return (
      <span style={{ color: '#324D5C', background: 'none' }}>
        {moment(rowData.eventDateTerm).format('D MMM YYYY')}
      </span>
    );
  };

  onLazyLoad = event => {
    this.event = event ? event : this.event;
    const setPreviousData = false;
    this.event.multiSortMeta = this.state.multiSortMeta;
    const query = this.getQuery(this.event, setPreviousData, this);
    const extendedQuery = this.getExtendedQuery(this.event, this);

    this.setState({ loading: true });

    reportServiceService
      .getLiveStats(query, extendedQuery)
      .then(result => {

        let clientName = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data[0].clientName : '';
        this.setState({ clientName: clientName });
        const setState = true;
        this.formatData(result.data, setState);
      });
  };

  bindResult(currency){

    if(!this.state.resOriginal){
      return;
    }

    let currencyFieldValue = '';
    let currencyCode = '';
    let currencyItem = _defaultCurrencies.find(c => c.value == currency)

    if(currencyItem.default){
      currencyFieldValue = currencyCode = currency;      
    }else{
      currencyFieldValue = 'ClientCountryCurrency';
      currencyCode = currencyItem.key;
    }

    let currencySign = getCurrencySymbolByCode(currencyItem.key);

    this.state.currencyFieldValue = currencyFieldValue;
    this.state.currencyCode = currencyCode;
    this.state.currencySign = currencySign;

    this.setState({
      currencyFieldValue,
      currencyCode,
      currencySign
    })

    this.formatData({ data: this.state.resOriginal }, null);
  }

  renderDataBoxes = () => {
    let dataBoxObjs = [
      {
        title: 'TOTAL LIGHTBOX VIEWS',
        currentValue: this.state.clientSales,
        variation: this.state.variationInClientSales,
        previousValue: this.state.previousClientSales
      },
      {
        title: 'SHARES',
        currentValue: this.state.shares,
        variation: this.state.variationInShares,
        previousValue: this.state.previousShares
      },
    ];

    let othersObj = [
      {
        title: 'SHARE RATE',
        currentValue: this.state.sharesRate,
        variation: this.state.variationInSharesRate,
        previousValue: this.state.previousSharesRate
      },
      {
        title: 'REFERRED CLICKS',
        currentValue: this.state.clicks,
        variation: this.state.variationInClicks,
        previousValue: this.state.previousClicks
      },
      {
        title: 'SORETO SALES',
        currentValue: this.state.soretoSales,
        variation: this.state.variationInSoretoSales,
        previousValue: this.state.previousSoretoSales
      },
      {
        title: 'CONVERSION RATE',
        currentValue: this.state.conversionRate,
        variation: this.state.variationInConverionRate,
        previousValue: this.state.previousConversionRate
      },
      {
        title: 'SALES VALUE',
        currentValue: this.state.totalValueSoretoSales,
        currencySign: this.state.currencySign,
        variation: this.state.variationInTotalValueSoretoSales,
        previousValue: this.state.previousTotalValueSoretoSales
      },
      {
        title: 'COMMISSION',
        currentValue: this.state.totalValueSoretoCommission,
        currencySign: this.state.currencySign,
        variation: this.state.variationInTotalCommissionSoretoSales,
        previousValue: this.state.previousTotalCommissionSoretoSales
      },
      {
        title: 'ROI',
        currentValue: this.state.ROI,
        variation: this.state.variationInROI,
        previousValue: this.state.previousROI
      }
    ];

    if (!esc.includes(this.props.clientId)) {
      dataBoxObjs.splice(dataBoxObjs.length, 0, ...othersObj);
    }
    
    const boxes = dataBoxObjs.map((dataBox, i) => {
      const dataBoxclass = dataBox.variation ? 'number__variation' : 'number';
      return (
        <DataBox
          key={`dataBox-${i}`}
          title={dataBox.title}
          currentValue={dataBox.currentValue}
          className={dataBoxclass}
          variation={dataBox.variation}
          previousValue={dataBox.previousValue}
          currencySign={dataBox.currencySign}
        />
      );
    });

    const cssItem = boxes.length > 2 ? 'item item--1' : 'item item--1-1';

    return <div className={cssItem}>{boxes}</div>;
  };

  renderChartDataComparison = chartDate => {
    return (
      <ChartDataComparison
        title="GENERAL VIEW ( Select two or more to compare... )"
        chartData={this.state.chartData}
        type="LineChart"
        chartDate={chartDate && chartDate}
        className="item item--2 chart"
      />
    );
  };

  renderDataTable = chartDate => {
    var header = (
      <div className="header">
        <span className="header-title">DAILY STATISTICS</span>
        <span className="header-date-range">{chartDate && chartDate}</span>
      </div>
    );

    let columnsFooter = this.state.colsFooter.map((col, i) => {
      return <Column key={i} footer={col.footer} colSpan={col.colSpan} style={col.style}/>;
    });

    let footerGroup = (
      <ColumnGroup>
        <Row>{columnsFooter}</Row>
      </ColumnGroup>
    );

    let columns = this.state.cols.map((col, i) => {
      if (col.field === 'eventDateTerm') {
        return (
          <Column
            key={i}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            style={col.style}
            body={this.dateTemplate}
          />
        );
      } else {
        return (
          <Column
            key={i}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            style={col.style}
          />
        );
      }
    });

    return (
      <DataTable
        value={this.state.clientStats}
        responsive={true}
        scrollable={true}
        autoLayout={true}
        selection={this.state.selectedTableRow}
        onSelectionChange={e => this.setState({ selectedTableRow: e.data })}
        selectionMode="single"
        multiSortMeta={this.state.multiSortMeta}
        onSort={(e) => this.setState({multiSortMeta: e.multiSortMeta})}
        sortMode="multiple"
        paginator={true}
        rows={100}
        loading={this.state.loading}
        rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        header={header}
        ref={el => (this.dt = el)}
        globalFilter={this.state.globalFilter}
        footerColumnGroup={footerGroup}
        className="item item--3 data-grid"
        first = {this.state.first}
      >
        {columns}
      </DataTable>
    );
  };

  render() {
    
    if(this.state.clientStats && this.state.clientStats.length > 0){
      
      let cliCurrencyCode = this.state.clientStats[0].clientCountryCurrencyCode;

      let def = _defaultCurrencies.find(cc => cc.value == cliCurrencyCode);

      if(!def){
        _defaultCurrencies.push({ key: cliCurrencyCode, label: cliCurrencyCode, value: cliCurrencyCode});
      }      
    } 

    return (
      <div className="analytics-page grid-container grid-parent">
        <div className="analytics-page--header">
          <h3 className="clientName">{this.state.clientName}</h3>

          <DataRangePicker dates={data => this.updateDateRange(data)} />

          {!esc.includes(this.props.clientId) && (
          <div className="currencySwitcher currencySwitcher--dropdown">
            <Dropdown
              options={_defaultCurrencies}
              value={this.props.selectedCurrency}
              onChange={(val) => {
                this.props.dispatch(currencySwitcherActions.selectedCurrency(val.value));
              }}
              autoWidth={false}/>
          </div>
        )}
          
        </div>

        <div className="wrapper">
          {this.renderDataBoxes()}

          {!esc.includes(this.props.clientId) ? this.renderChartDataComparison() : ''}

          {!esc.includes(this.props.clientId) ? this.renderDataTable() : ''}
        </div>
      </div>
    );
  };
}

export default connect(store => {
  return {
    calendar: store.sharedDateRangePicker.setCalendarDates,
    selectedCurrency: store.currencySwitcher.selectedCurrency,
    clientId: store.loginCtx.loginCtx.clientId
  };
})(AnalyticsBi);
