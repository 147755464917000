import React from 'react';
import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import { connect } from 'react-redux';

import layoutService from '../../../../utils/services/layout';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import FormElement from '../../../shared/form/FormElement';
import clientService from '../../../../utils/services/client';
import campaignService from '../../../../utils/services/campaign';
import wizardService from '../../../../utils/services/wizard';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

@connect((store) => ({ }))
class wizardCreate extends React.Component{
  initialState = {
    clientDetails: {
      _id: '',
      name: '',
      customIdentifier: '',
      siteUrl: '',
      siteShortUrl: '',
      productUrl: '',
      about: '',
      campaigns: [],
    },
    campaignDetails: {
      description: '',
      startDate: '',
      expiry: '',
      campaignVersions: [],
      countryId: ''
    },
    campaignVersionDetails : {
      discountCodes: [],
      name: '',
      actionButtonDefaultBackgroundColor: '#0B2D61',
      actionButtonDefaultTextColor: '#FFFFFF'
    },
    sharerRewardDetails :{
      discountCodes: [],
      name: '',
      _meta_reward_type: 'sharerPre'
    },
    friendRewardDetails :{
      name: '',
      _meta_reward_type: 'friendPre'
    },
    sharerDiscountCodeDetails: {
      valueAmount: '',
      code: '',
      validFrom: '',
      validTo: ''
    },
    friendDiscountCodeDetails: {
      valueAmount: '',
      code: '',
      validFrom: '',
      validTo: ''
    },
    fieldErrors : {},
    hasFieldErrors : false,
    clientSelected: false,
    creationAllowed: true
  }
  
  constructor(props) {
    super(props);

    this.state = _.cloneDeep(this.initialState);     
  }

  componentWillMount(){
    layoutService.getCountries()
      .then((res) => {
        if(res.data){

          let countries = [];
          res.data.forEach(country => {
            countries.push({ label: country.code, value: country._id });
          });

          this.setState({countries});
        }
      })
      .then(this.loadTemplates);
  }

  loadTemplates = () => clientService.getClientPage({template_$: true})
    .then(res => res.data.page.map(item => _.pick(item, ['_id', 'name', 'customIdentifier'])))
    .then(templates => Promise.all(templates.map(this.withVersion)))
    .then(templatesWithVersion => this.setState({templates: templatesWithVersion}))

  withVersion = template => campaignService.getAllCampaigns(template._id)
    .then(res => ({...template, versions: res.data && res.data.map(item => _.pick(item, '_id', 'description'))}));
  
  onClientDropboxChange(e) {
    clientService.getClient(e.value).then(response => {
      const clientDetails = {
        _id: response.data && response.data._id,
        name: response.data && response.data.name || '',
        customIdentifier: response.data && response.data.customIdentifier || '',
        siteUrl: response.data && response.data.referer && response.data.referer[0] || ''
      };

      const clientSelected = !!response.data._id;

      this.setState({ clientDetails, clientSelected });
    });
  }

  onChange(e, name) {
    const _name = (e.target && e.target.name) ? e.target.name : name;
    const _value = e.target ? e.target.value : e.value;
    const _state = this.state;

    _.setWith(_state, _name, _value);
    this.setState(_state);
  }

  onTemplateChange = e => {
    this.onChange(e, 'selectedTemplate');
    this.setState({selectedVersion: undefined});
    this.forceUpdate();
  }

  resetForm () {
    this.setState(_.cloneDeep(this.initialState));
  }

  mapFieldValidation () {    
    const fieldValidation = this.state.response.fieldValidation;

    let fieldErrors = {};

    if (fieldValidation) {
      fieldErrors = fieldValidation && fieldValidation.reduce((acc, cur) => {
        const field = cur.field.replace(/\./g, '_');
        acc[field] = cur.message;
        return acc;
      }, []);
    }

    const hasFieldErrors = Object.values(fieldErrors).some(o => !!o);

    return this.setState({ fieldErrors, hasFieldErrors });
  }

  onSubmit (e) {
    e.preventDefault();

    const payload = this.getPayload();

    wizardService.setup(payload,this.state.selectedTemplate.customIdentifier, this.state.selectedVersion.description).then(response => {
      this.setState({response: response}, () => {

        this.mapFieldValidation();

        const messageType = response.status == 200 ? 'success' : 'error';
        let message = '';
  
        switch (response.code) {
        case 400 : 
          message = 'Please check the fields validation';
          break;
        case 500 :
          message = response.message;
          break;
        default :

          if(messageType == 'success')
          {
            this.setState({creationAllowed: false});
          }

          message = messageType == 'success' ? 'Setup completed with success!' :  'An unknown error occurred.';
          break;
        }
  
        this.props.dispatch(globalMessageActions.showMessage({
          type: messageType,
          message: message,
          timeout: 20000
        }));

      });
    });
  }

  getPayload() {
    const data = this.state;
    data.campaignDetails.campaignVersions = [data.campaignVersionDetails];

    data.sharerRewardDetails.discountCodes = [data.sharerDiscountCodeDetails];
    data.friendRewardDetails.discountCodes = [data.friendDiscountCodeDetails];

    const client = data.clientDetails;
    client.campaigns = [data.campaignDetails];
    client.rewards = [data.sharerRewardDetails, data.friendRewardDetails];

    return { client: client };
  }


  render() {

    const {
      clientDetails,
      campaignDetails,
      campaignVersionDetails,
      sharerRewardDetails,
      friendRewardDetails,
      sharerDiscountCodeDetails,
      friendDiscountCodeDetails,
      fieldErrors,
      clientSelected,
      creationAllowed,
      countries,
      templates,
      selectedTemplate,
      selectedVersion
    } = this.state;

    const disabled = !selectedTemplate || ! selectedVersion;

    return (
      <div>
        <h1>Client Setup Wizard</h1>

        <div className="ui-g">

          <div className="ui-g-3"></div>

          <div className="ui-g-6">

            <form className="c-form--flex" style={{marginBottom: '15px'}}>

              <Panel header="Template Selection" toggleable={true} >
                <FormElement
                  label='Template'
                  type='dropdown'
                  id='form-template'
                  style={{width: '300px', marginLeft: '5px'}}
                  name='selecttedTemplate'
                  value={selectedTemplate}
                  options={templates && templates.map(template => ({label: template.name, value: template}))}
                  onChange= { e => { this.onTemplateChange(e);} }
                  error={null}
                  placeholder="Template"
                  required
                />
                <FormElement
                  label='version'
                  type='dropdown'
                  id='form-template-version'
                  style={{width: '300px', marginLeft: '5px'}}
                  name='selectedVersion'
                  value={selectedVersion}
                  options={selectedTemplate && selectedTemplate.versions && selectedTemplate.versions.map(version => ({label: version.description, value: version}))}
                  onChange= { (e) => { this.onChange(e, 'selectedVersion'); } }
                  error={null}
                  placeholder="Version"
                  required
                />
              </Panel>
              <br />
  
              <div disabled={disabled} style={disabled ? {opacity: 0.5} : {} } >
                <Panel header="Client" toggleable={true}>
                  <ClientsDropbox disabled={disabled} onChange={(e) => this.onClientDropboxChange(e)} ></ClientsDropbox>
  
                  <FormElement
                    id='form-name'
                    label='Name'
                    name='clientDetails.name'
                    type='text'
                    value= { clientDetails.name }
                    onChange= { (e) => this.onChange(e) }
                    required
                    error={fieldErrors.client_name}
                    disabled={!clientSelected}
                  />
  
                  <FormElement
                    id='form-custom-identifier'
                    label='Custom Identifier'
                    name='clientDetails.customIdentifier'
                    type='text'
                    value= { clientDetails.customIdentifier }
                    onChange= { (e) => this.onChange(e) }
                    required
                    error={fieldErrors.client_customIdentifier}
                    disabled={!clientSelected}
                  />
  
                  <FormElement
                    id='form-site-url'
                    label='Site'
                    name='clientDetails.siteUrl'
                    type='text'
                    value= {clientDetails.siteUrl}
                    onChange= { (e) => this.onChange(e) }
                    required
                    error={fieldErrors.client_siteUrl}
                    disabled={!clientSelected}
                  />
  
                  <FormElement
                    id='form-site-short-url'
                    label='Site short url'
                    name='clientDetails.siteShortUrl'
                    type='text'
                    value= { clientDetails.siteShortUrl}
                    onChange= { (e) => this.onChange(e) }
                    required
                    error={fieldErrors.client_siteShortUrl}
                    disabled={!clientSelected}
                  />
  
                  <FormElement
                    id='form-product-url'
                    label='Product url'
                    name='clientDetails.productUrl'
                    type='text'
                    value= { clientDetails.productUrl}
                    onChange= { (e) => this.onChange(e) }
                    required
                    error={fieldErrors.client_productUrl}
                    disabled={!clientSelected}
                  />
               
                  <FormElement
                    id='form-about'
                    label='About'
                    name='clientDetails.about'
                    type='textArea'
                    value= {clientDetails.about}
                    onChange= { (e) => this.onChange(e, 'clientDetails.about') }
                    required
                    error={fieldErrors.client_about}
                    disabled={!clientSelected}
                  />   
  
                </Panel>

                <br />
                
                <Panel header="Campaigns" toggleable={true} >
                
                  <div className="ui-g">
                    
                    <div className="ui-g-6">
                      <FormElement
                        id='form-campaign-description'
                        label='Description'
                        name='campaignDetails.description'
                        type='text'
                        value= {campaignDetails.description}
                        onChange= { (e) => this.onChange(e) }
                        required
                        error={fieldErrors.client_campaign_description}
                        disabled={!clientSelected}
                      />
  
                      <FormElement
                        id='form-campaign-start-date'
                        label='Start Date'
                        name='campaignDetails.startDate'
                        type='calendar'
                        value={campaignDetails.startDate}
                        onChange={(e) => this.onChange(e, 'campaignDetails.startDate')}
                        required
                        style={{ marginLeft: '5px', width: '300px' }}
                        error={fieldErrors.client_campaign_startDate}
                        disabled={!clientSelected}
                      />
  
                      <FormElement
                        id='form-campaign-expiry'
                        label='Expiry'
                        name='campaignDetails.expiry'
                        type='calendar'
                        value={campaignDetails.expiry}
                        onChange={(e) => this.onChange(e, 'campaignDetails.expiry')}
                        required
                        style={{ marginLeft: '5px', width: '300px' }}
                        error={fieldErrors.client_campaign_expiry}
                        disabled={!clientSelected}
                      />                
  
                      <FormElement
                        id='form-campaign-version-name'
                        label='Campaign Version name'
                        name='campaignVersionDetails.name'
                        type='text'
                        value= {campaignVersionDetails.name}
                        onChange= { (e) => this.onChange(e) }
                        required
                        error={fieldErrors.client_campaign_campaignVersion_name}
                        disabled={!clientSelected}
                      />

                      <FormElement
                        id='form-marketplace-offer-title'
                        label='Marketplace Offer Title'
                        name='campaignVersionDetails.mpOfferTitle'
                        type='text'
                        value= {campaignVersionDetails.mpOfferTitle}
                        onChange= { (e) => this.onChange(e) }
                        required
                        error={fieldErrors.client_campaign_campaignVersion_mpOfferTitle}
                        disabled={!clientSelected}
                      />
                    </div>
                    <div className="ui-g-6">
                      
                      
                      <FormElement
                        label='Country'
                        type='dropdown'
                        id='form-campaign-country'
                        name='campaignDetails.countryId'
                        value={campaignDetails.countryId}
                        options={countries}
                        onChange= { (e) => { this.onChange(e, 'campaignDetails.countryId'); } }
                        error={fieldErrors.client_campaign_countryId}
                        placeholder="Country"
                        required
                        disabled={disabled}
                      />
                      
                      <FormElement
                        id='form-campaign-version-action-button-default-background-color'
                        label='Action Button Background Color'
                        name='campaignVersionDetails.actionButtonDefaultBackgroundColor'
                        type='color'
                        value= {campaignVersionDetails.actionButtonDefaultBackgroundColor}
                        onChange= { (e) => this.onChange(e) }
                        required
                        error={fieldErrors.client_campaign_campaignVersion_actionButtonDefaultBackgroundColor}
                        disabled={!clientSelected} />
                        
                      <FormElement
                        id='form-campaign-version-action-button-default-text-color'
                        label='Action Button Text Color'
                        name='campaignVersionDetails.actionButtonDefaultTextColor'
                        type='color'
                        value= {campaignVersionDetails.actionButtonDefaultTextColor}
                        onChange= { (e) => this.onChange(e) }
                        required
                        error={fieldErrors.client_campaign_campaignVersion_actionButtonDefaultTextColor}
                        disabled={!clientSelected} />
                      
                    </div>
                  </div>                
                </Panel>
                <br/>
  
                <Panel header="Rewards" toggleable={true} >
                  <div className="ui-g">
                    
                    <div className="ui-g-6">
                      <Panel style={{border: 1 }}>
                        <h4>Sharer Reward</h4>
                        
                        <FormElement
                          id='form-sharer-reward-name'
                          label='Name'
                          name='sharerRewardDetails.name'
                          type='text'
                          value= {sharerRewardDetails.name}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_sharer_pre_name}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-sharer-reward-literalDescription'
                          label='Literal Description'
                          name='sharerRewardDetails.literalDescription'
                          type='text'
                          value= {sharerRewardDetails.literalDescription}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_sharer_pre_literalDescription}
                          disabled={!clientSelected}
                          placeholder="eg: 25% off"
                        />
   
                        <h4>Discount Code</h4>
  
                        <FormElement
                          id='form-sharer-discount-valueAmount'
                          label='valueAmount'
                          name='sharerDiscountCodeDetails.valueAmount'
                          type='number'
                          validate
                          value= {sharerDiscountCodeDetails.valueAmount}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_sharer_pre_discountCode_valueAmount}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-sharer-discount-code'
                          label='Code'
                          name='sharerDiscountCodeDetails.code'
                          type='text'
                          value= {sharerDiscountCodeDetails.code}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_sharer_pre_discountCode_code}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-sharer-discount-valid-from'
                          label='Valid from'
                          name='sharerDiscountCodeDetails.validFrom'
                          type='calendar'
                          value={sharerDiscountCodeDetails.validFrom}
                          onChange={(e) => this.onChange(e, 'sharerDiscountCodeDetails.validFrom')}
                          required
                          style={{ marginLeft: '5px', width: '300px' }}
                          nullerror={fieldErrors.client_rewards_sharer_pre_discountCode_validFrom}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-sharer-discount-valid-to'
                          label='Valid to'
                          name='sharerDiscountCodeDetails.validTo'
                          type='calendar'
                          value={sharerDiscountCodeDetails.validTo}
                          onChange={(e) => this.onChange(e, 'sharerDiscountCodeDetails.validTo')}
                          style={{ marginLeft: '5px', width: '300px' }}
                          error={fieldErrors.client_rewards_sharer_pre_discountCode_validTo}
                          disabled={!clientSelected}
                        />
  
                      </Panel>
                    </div>
  
                    <div className="ui-g-6">
                      <Panel style={{border: '1px'}}>
                        <h4>Friend Reward</h4>
                        
                        <FormElement
                          id='form-friend-reward-name'
                          label='Name'
                          name='friendRewardDetails.name'
                          type='text'
                          value= {friendRewardDetails.name}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_friend_pre_name}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-friend-reward-literalDescription'
                          label='Literal Description'
                          name='friendRewardDetails.literalDescription'
                          type='text'
                          value= {friendRewardDetails.literalDescription}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_friend_pre_literalDescription}
                          disabled={!clientSelected}
                          placeholder="eg: 20% off"
                        />
  
                        <h4>Discount Code</h4>
  
                        <FormElement
                          id='form-friend-discount-valueAmount'
                          label='valueAmount'
                          name='friendDiscountCodeDetails.valueAmount'
                          type='number'
                          validate
                          value= {friendDiscountCodeDetails.valueAmount}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_friend_pre_discountCode_valueAmount}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-friend-discount-code'
                          label='Code'
                          name='friendDiscountCodeDetails.code'
                          type='text'
                          value= {friendDiscountCodeDetails.code}
                          onChange= { (e) => this.onChange(e) }
                          required
                          error={fieldErrors.client_rewards_friend_pre_discountCode_code}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-friend-discount-valid-from'
                          label='Valid from'
                          name='friendDiscountCodeDetails.validFrom'
                          type='calendar'
                          value={friendDiscountCodeDetails.validFrom}
                          onChange={(e) => this.onChange(e, 'friendDiscountCodeDetails.validFrom')}
                          required
                          style={{ marginLeft: '5px', width: '300px' }}
                          error={fieldErrors.client_rewards_friend_pre_discountCode_validFrom}
                          disabled={!clientSelected}
                        />
  
                        <FormElement
                          id='form-friend-discount-valid-to'
                          label='Valid to'
                          name='friendDiscountCodeDetails.validTo'
                          type='calendar'
                          value={friendDiscountCodeDetails.validTo}
                          onChange={(e) => this.onChange(e, 'friendDiscountCodeDetails.validTo')}
                          style={{ marginLeft: '5px', width: '300px' }}
                          error={fieldErrors.client_rewards_friend_pre_discountCode_validTo}
                          disabled={!clientSelected}
                        />
                    
                      </Panel>
                    </div>
                  </div>
  
                </Panel>
                <br />
              </div>

            </form>
            &nbsp;&nbsp;
            <button className="button icon-left" id="form-submit" hidden={!creationAllowed} onClick={(e)=>this.onSubmit(e)}>Create</button>
            <button className="button icon-left" id="form-new" hidden={creationAllowed} onClick={()=>this.resetForm()}>New</button>
          </div>
          <div className="ui-g-3"></div>
        </div>
        
      </div>
    );
  }
}


export default wizardCreate;
