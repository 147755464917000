import BigNumber from 'bignumber.js';
import getSymbolFromCurrency from 'currency-symbol-map'

const getCurrencySymbol = () => '£';

export const getCurrencySymbolByCode = (code) => {

    let currencySign = getSymbolFromCurrency(code);
    
    if(!currencySign){
        currencySign = code;
    }

    return currencySign;

}

export const dynamicFormatter = (currencyCode) => {
   
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const getFormattedAmount = (amount) => `${getCurrencySymbol()}${new BigNumber(amount).toFixed(2)}`;