import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as affiliateActions from '../../../../actions/admin/affiliateActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    affiliateObject: store.adminAffiliate.requests
  };
})

class AffiliateCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      affiliateDetails = {
        name:'',
        module:'',
        imageUrl:'',
      }
    } = props;

    this.state = {
      affiliateDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };

  }

  onChange(name, {target}) {
    const {affiliateDetails} = this.state;
    _.set(affiliateDetails, name, target.value);
    this.setState({affiliateDetails});
  }

  componentWillUpdate(nextProps){
    if(this.props.affiliateObject !=nextProps.affiliateObject){
      if(_.get(nextProps,'affiliateObject.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.affiliateObject.data.message,
          timeout: 10000
        }));
      }
    }    
  }

  onClientChange(e){
    const {affiliateDetails} = this.state;
    _.set(affiliateDetails, 'clientId', e.value);
    this.setState({affiliateDetails});
  }


  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const {affiliateDetails,errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      this.props.dispatch(affiliateActions.newAffiliate(affiliateDetails))
        .then(created => browserHistory.push({ pathname: `/admin/affiliate/${created._id}` , state: { fromCreatePage: true } }));
    }
    
    console.log(errors); //for debugging left in for internal use only as it is an admin tool.
    
  }



  validateForm() {
    const { affiliateDetails, errors } = this.state;

    errors[ 'name' ] = validator.isEmpty(affiliateDetails.name.toString());
    
    return this.setState({ errors });
  }

  render (){
    const {affiliateDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      name,
      module,
      imageUrl,
    } = affiliateDetails;

    return (
      <div className="affiliate-new-affiliate">
        <h3>Create affiliate</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />
       
          <FormElement
            label='Module'
            name='module'
            type='text'
            onChange={this.onChange.bind(this, 'module')}
            value={module}
            id='module'
          />

          <FormElement
            label='Image Url'
            name='imageUrl'
            type='text'
            onChange={this.onChange.bind(this, 'imageUrl')}
            value={imageUrl}
            error={errors.imageUrl}
            id='image-url'
          />


        </form>
        <br />
        <button style={{ marginTop: '10px' }} onClick={()=>this.onSubmit()}>Create affiliate</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default AffiliateCreate;