import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as userManagementActions from '../../../../actions/admin/userManagementActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';
import RolesDropbox from '../../../shared/dropbox/RolesDropbox';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';

@connect((store) => {
  return {
    userManagementNew: store.adminUserManagement.userManagementNew
  };
})

class UserManagementCreate extends React.Component {

  constructor(props) {
    super(props);

    const  userManagementDetails = {
      firstName: '',
      lastName: '',
      roles: [],
      email: '',
      verifiedEmail: false,
      clientId: '',
      newPassword: null,
      confirmedPassword: null,
      isClientUser: false
    };

    this.state = {
      userManagementDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.userManagementNew !== nextProps.userManagementNew){
      if(_.get(nextProps,'userManagementNew.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.userManagementNew.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onChange(name, { target }) {
    const { userManagementDetails } = this.state;
    _.set(userManagementDetails, name, target.value);
    this.setState({ userManagementDetails });
  }

  onChangeVerifiedEmail({ target }) {
    const { userManagementDetails } = this.state;
    _.set(userManagementDetails, 'verifiedEmail', target.checked );
    this.setState({ userManagementDetails });
  }

  onClientChange(e){

    const { userManagementDetails } = this.state;

    if (e.value && e.value !== null) {
      _.set(userManagementDetails, 'clientId', e.value);
    } else {
      _.set(userManagementDetails, 'clientId', '');
    }

    this.setState({ userManagementDetails });
  }

  onRoleChange(e) {
    const { userManagementDetails } = this.state;

    if (e && e.length > 0) {
      _.set(userManagementDetails, 'roles', e);
      _.set(userManagementDetails, 'clientId', '');
      _.set(userManagementDetails, 'isClientUser', e.length === 1 && e[0] === '58aaefe78c0c6c18b208ce8d');
    } else {
      _.set(userManagementDetails, 'roles', []);
      _.set(userManagementDetails, 'clientId', '');
      _.set(userManagementDetails, 'isClientUser', false);
    }

    this.setState({ userManagementDetails });
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { userManagementDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    if(errors.newPassword === true && errors.confirmedPassword === true
      && !errors.firstName && !errors.lastName && !errors.email && !errors.roleId && !errors.clientId) {
      checkErrors = true;
      this.setState({ displayErrorMessage: true });

      this.props.dispatch(globalMessageActions.showMessage({
        type: 'warning',
        message: 'Your password and confimation password do not math or are empty',
        timeout: 10000
      }));

    } else {
      for( const key in errors ) {
        if( errors[key] ) {
          checkErrors = true;
          this.setState({ displayErrorMessage: true });
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Please fill all the required',
            timeout: 10000
          }));
          break;
        }
      }
    }

    if( !checkErrors ) {
      this.props.dispatch(userManagementActions.createUserManagement(userManagementDetails))
        .then(created => browserHistory.push({ pathname: `/admin/userManagement/${created._id}` , state: { fromCreatePage: true } }));
    }
  }

  validateForm() {

    this.checkPasswords();
    const { userManagementDetails, errors } = this.state;

    errors[ 'firstName' ] = validator.isEmpty(userManagementDetails.firstName);
    errors[ 'lastName' ] = validator.isEmpty(userManagementDetails.lastName);
    errors[ 'roles' ] =  (!userManagementDetails.roles) || userManagementDetails.roles.length === 0;
    errors[ 'email' ] = validator.isEmpty(userManagementDetails.email);

    if(userManagementDetails.isClientUser) {
      errors[ 'clientId' ] = validator.isEmpty(userManagementDetails.clientId);
    }

    return this.setState({ errors });
  }

  checkPasswords() {

    const { userManagementDetails, errors } = this.state;

    if ((userManagementDetails.newPassword === userManagementDetails.confirmedPassword)
        && (userManagementDetails.newPassword !== null && userManagementDetails.confirmedPassword !== null)
        && (userManagementDetails.newPassword !== '' && userManagementDetails.confirmedPassword !== '')) {

      errors[ 'newPassword' ] = false;
      errors[ 'confirmedPassword' ] = false;

    } else if ((userManagementDetails.newPassword !== userManagementDetails.confirmedPassword)
    || (userManagementDetails.newPassword === null || userManagementDetails.newPassword === undefined)
    || (userManagementDetails.confirmedPassword === null || userManagementDetails.confirmedPassword === undefined)) {
      errors[ 'newPassword' ] = true;
      errors[ 'confirmedPassword' ] = true;
    }
    this.setState({ errors,  displayErrorMessage: true });
  }

  render (){
    const { userManagementDetails = { info: {} }, errors } = this.state;

    const {
      firstName,
      lastName,
      roles,
      email,
      verifiedEmail,
      clientId,
      newPassword,
      confirmedPassword,
      isClientUser
    } = userManagementDetails;

    return (
      <div className="campaign-new-campaign">
        <h3>Create new user</h3>
        <form  className="c-form--flex">

          <FormElement
            label='First Name'
            name='firstName'
            type='input'
            onChange={this.onChange.bind(this, 'firstName')}
            value={firstName}
            error={errors.firstName}
            id='form-firstName'
            required
          />

          <FormElement
            label='Last Name'
            name='lastName'
            type='input'
            onChange={this.onChange.bind(this, 'lastName')}
            value={lastName}
            error={errors.lastName}
            id='form-lastName'
            required
          />

          <FormElement
            label='Email'
            name='email'
            type='input'
            onChange={this.onChange.bind(this, 'email')}
            value={email}
            error={errors.email}
            id='form-email'
            required
          />

          <div style={{display:'block', marginTop: '8px'}}>
            <span>Verified Email:</span>
            <div style={{marginLeft: '3px'}}>
              <input
                name="verifiedEmail"
                type="checkbox"
                checked={this.state.verifiedEmail}
                value={verifiedEmail}
                onChange={this.onChangeVerifiedEmail.bind(this)}
              />
            </div>
          </div>

          <RolesDropbox
            label="Roles"
            id="role"
            onChange={ (e) => this.onRoleChange(e) }
            value={roles}
            error={errors.roles}
            required
          />

          <ClientsDropbox
            label="Client"
            required={ isClientUser }
            disabled={ !isClientUser }
            onChange={ (e) => this.onClientChange(e)}
            value={clientId}
            error={errors.clientId}
            id='form-clientName'
          />

          <FormElement
            label='Password'
            name='newPassword'
            type='password'
            passwordLabel="Please enter a new password"
            onChange={this.onChange.bind(this, 'newPassword')}
            value={newPassword}
            error={errors.newPassword}
            id='form-newPassowrd'
            required
          />

          <FormElement
            label='Confirm Password'
            name='confirmedPassword'
            type='password'
            passwordLabel="Please confirm the new password"
            onChange={this.onChange.bind(this, 'confirmedPassword')}
            value={confirmedPassword}
            error={errors.confirmedPassword}
            id='form-confirmNewPassword'
            required
          />

        </form>
        <br />
        <button style={{ marginTop: '10px' }} onClick={()=>this.onSubmit()}>Create user</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default UserManagementCreate;