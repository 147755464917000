import constants from '../../constants/app-constants';

export const toggleEditLocationModal = (modalIsOpen) =>{
  return {
    type: constants.client.SETTINGS_COMPANY_TOGGLELOCATIONMODAL,
    payload: modalIsOpen
  };
};

export const updateSharePopupPreview = (previews) => {
  return {
    type: constants.client.SHAREPOPUP_UPDATEPREVIEWS,
    payload: previews
  };
};

export const updateShareBgEnabled = (shareBgEnabled) => {
  return {
    type: constants.client.SETTINGS_SHAREPOPUPCUSTOM_UPDATESHAREBGENABLED,
    payload: shareBgEnabled
  };
};