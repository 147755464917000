import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as rewardDiscountCodeActions from '../../../../actions/admin/rewardDiscountCodeActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';
import RewardsDropbox from '../../../shared/dropbox/RewardsDropbox';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';

import {Slider} from 'primereact/components/slider/Slider';

export class RewardDiscountCodeCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      discountDetails = {
        discountType:'',
        code:'',
        valueAmount: 0,
        activeFrom:'',
        activeTo:'',
        validFrom:'',
        validTo:'',
        rewardId:'',
        clientId: '',
        active: false,
        hasExpirationDate: true,
        hasVoucherExpirationDate: true
      }
    } = props;

    this.state = {
      discountDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      clientId: null,
      rewardId: null,
    };
  }

  onChange = ({target}, name) => {
    const { discountDetails } = this.state;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if(name === 'hasExpirationDate' && value === false) {
      _.set(discountDetails, 'activeTo', null);  
    }

    if(name === 'hasVoucherExpirationDate' && value === false) {
      _.set(discountDetails, 'validTo', null);  
    }
    
    _.set( discountDetails, name, value);
    this.setState({discountDetails});
  }

  onChangeActiveFrom = (e) =>  {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'activeFrom', e.value);
    this.setState({discountDetails});
  }

  onChangeActiveTo = (e) => {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'activeTo', e.value);
    this.setState({discountDetails});
  }

  onChangeValidFrom = (e) => {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'validFrom', e.value);
    this.setState({discountDetails});
  }

  onChangeValidTo = (e) => {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'validTo', e.value);
    this.setState({discountDetails});
  }

  onRewardChange = (e) => {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'rewardId', e.value);
    this.setState({discountDetails, rewardId: e.value});
  }

  onChangeSlider = (e) => {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'valueAmount', e.value);
    this.setState({discountDetails});
  }

  onClientChange = (e) => {
    const {discountDetails} = this.state;
    _.set(discountDetails, 'clientId', e.value);
    this.setState({discountDetails, clientId: e.value});
  }

  componentWillUpdate(nextProps){
    if(this.props.adminRewardDiscountCode != nextProps.adminRewardDiscountCode){
      if(_.get(nextProps,'adminRewardDiscountCode.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.adminRewardDiscountCode.data.message,
          timeout: 10000
        }));
      }
    }
  }


  onSubmit = () => {
    this.setState({ displayErrorMessage: false });
    const { discountDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }
    if( !checkErrors ){

      discountDetails.activeTo = discountDetails.activeTo || undefined;
      discountDetails.validTo = discountDetails.validTo || undefined;

      this.props.dispatch(rewardDiscountCodeActions.newRewardDiscountCode(discountDetails))
        .then(created => browserHistory.push({ pathname: `/admin/rewardDiscountCode/${created._id}` , state: { fromCreatePage: true } }));
    }

  }

  validateForm = () => {
    const { discountDetails, errors } = this.state;

    errors[ 'clientId' ] = validator.isEmpty((discountDetails.clientId).toString());
    errors[ 'rewardId' ] = validator.isEmpty((discountDetails.rewardId).toString());
    errors[ 'clientId' ] = validator.isEmpty((discountDetails.rewardId).toString());
    errors[ 'discountType' ] = validator.isEmpty(discountDetails.discountType);
    errors[ 'valueAmount' ] = validator.isEmpty((discountDetails.valueAmount).toString());
    errors[ 'code' ] = validator.isEmpty(discountDetails.code);
    errors[ 'activeFrom' ] = validator.isEmpty((discountDetails.activeFrom).toString());
    errors[ 'activeTo' ] = discountDetails.hasExpirationDate && !discountDetails.activeTo;
    errors[ 'validFrom' ] = validator.isEmpty((discountDetails.validFrom).toString());
    errors[ 'validTo' ] = discountDetails.hasVoucherExpirationDate && !discountDetails.validTo;
    errors[ 'active' ] = validator.isEmpty((discountDetails.active).toString());

    return this.setState({ errors });
  }

  render (){
    const { discountDetails = { info: {} }, errors } = this.state;

    const {
      discountType,
      valueAmount,
      activeFrom,
      activeTo,
      validFrom,
      validTo,
      code,
      active,
      rewardId,
      clientId,
      hasExpirationDate,
      hasVoucherExpirationDate

    } = discountDetails;

    const calendarStyle = {
      marginLeft: '5px', width: '300px'
    };

    return (
      <div className="reward-new-reward">
        <h3>Create reward discount code</h3>
        <form  className="c-form--flex">
          <label>Clients<span className="u-color-pink">&nbsp;*</span></label>
          <ClientsDropbox
            required='true'
            value={clientId}
            onChange={this.onClientChange}
            error={errors.client}
          />

          <label>Reward<span className="u-color-pink">&nbsp;*</span></label>
          <RewardsDropbox
            required='true'
            value={rewardId}
            onChange={this.onRewardChange}
            error={errors.rewardId}
            client={clientId}
          />

          <FormElement
            label='Discount Type'
            name='discountType'
            type='select'
            onChange={(event) => this.onChange(event, 'discountType')}
            value={discountType}
            error={errors.discountType}
            id='form-discountType'
            required
          >
            <option value=""> </option>
            <option value="percentage">percentage</option>
          </FormElement >

          <label style={{ marginTop: '10px', marginBottom: '5px'}}>Value Amount<span className={'u-color-pink'}>&nbsp;*</span>: {valueAmount}%</label>
          <Slider style={{ width: '295px', marginLeft: '10px' }} value={valueAmount} onChange={this.onChangeSlider}/>

          <FormElement
            label='Code'
            name='code'
            type='text'
            onChange={(event) => this.onChange(event, 'code')}
            value={code}
            error={errors.code}
            id='form-code'
            required
          />

          <FormElement
            label='Has expiry date'
            name='hasExpirationDate'
            type='checkbox'
            onChange={e => this.onChange(e, 'hasExpirationDate')} 
            checked={(!!(hasExpirationDate))}
            id='form-activeCheck'
          />

          <FormElement
            label='Active From'
            name='activeFrom'
            type='calendar'
            onChange={this.onChangeActiveFrom}
            value={activeFrom}
            error={errors.activeFrom}
            id='form-activeFrom'
            required
            style={calendarStyle}
          />

          <FormElement
            label='Active To'
            name='activeTo'
            type='calendar'
            onChange={this.onChangeActiveTo}
            value={activeTo}
            error={errors.activeTo}
            id='form-activeTo'
            disabled={!hasExpirationDate}
            style={calendarStyle}
          />

          <FormElement
            label='Has the voucher a expiry date'
            name='hasVoucherExpirationDate'
            type='checkbox'
            onChange={e => this.onChange(e, 'hasVoucherExpirationDate')} 
            checked={(!!(hasVoucherExpirationDate))}
            id='form-validCheck'
          />
          
          <FormElement
            label='Valid From'
            name='validFrom'
            type='calendar'
            onChange={this.onChangeValidFrom}
            value={validFrom}
            error={errors.validFrom}
            id='form-validFrom'
            required
            style={calendarStyle}
          />

          <FormElement
            label='Valid To'
            name='validTo'
            type='calendar'
            onChange={this.onChangeValidTo}
            value={validTo}
            error={errors.validTo}
            id='form-validTo'
            disabled={!hasVoucherExpirationDate}
            style={calendarStyle}
          />

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={(event) => this.onChange(event, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement >

        </form>
        <br />
        <button onClick={this.onSubmit}>Create</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    adminRewardDiscountCode: store.adminRewardDiscountCode.requests
  };
})(RewardDiscountCodeCreate);
