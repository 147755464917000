import demoStoreService from '../../utils/services/demoStore';
import constants from '../../constants/app-constants';

export const getDemoStore = (id) => {
  return function(dispatch){
    demoStoreService.getDemoStoreById(id)
      .then(function(res){
        dispatch({
          type: constants.admin.DEMO_STORE_GET_BY_ID,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.DEMO_STORE_GET_BY_ID,
          payload: err.response
        });
      });
  };
};

export const updateDemoStore = (data) => {
  return function(dispatch){
    demoStoreService.updateDemoStore(data)
      .then(function(res){
        dispatch({
          type: constants.admin.DEMO_STORE_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.DEMO_STORE_EDIT,
          payload: err.response
        });
      });
  };
};

export const newDemoStore = data => dispatch => {
  return new Promise((resolve, reject) => {
    demoStoreService.newDemoStore(data)
      .then(res => {
        dispatch({
          type: constants.admin.DEMO_STORE_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.DEMO_STORE_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};