import constants from '../../constants/app-constants';
import clientService from '../../utils/services/client';
import sortHelper from '../../utils/helpers/sortHelper';
import clientHelper from '../../utils/helpers/client';

export const getClientActivity = (clientId , query ,  sortDirection , sortIndex) => {
  return function(dispatch){
    clientService.getClientActivity(clientId , query)
      .then(function(res){
        var clientActivity = clientHelper.getActivityProducts(sortHelper.sortResults(res.data , sortDirection , sortIndex));
        dispatch({
          type: constants.client.ACTIVITY_UPDATECLIENTACTIVITY,
          payload: clientActivity
        });
      });
  };
};

export const updateClientActivity = (clientActivity) => {
  return {
    type: constants.client.ACTIVITY_UPDATECLIENTACTIVITY,
    payload: clientActivity
  };
};