import constants from '../../constants/app-constants';
import clientService from '../../utils/services/client';
import clientHelper from '../../utils/helpers/client';
import sortHelper from '../../utils/helpers/user';

export const getEngagementProducts = function(clientId){
  return function(dispatch){
    clientService.getSharedUrlsAccess(clientId, {}).then((response) => {
      dispatch({
        type: constants.client.ENGAGEMENT_UPDATEPRODUCTS,
        payload: clientHelper.getEngagementProducts(response.data)
      });
    });
  };
};

//userId, query ,sortDirection , sortIndex
export const getSortedEngagementProducts = function(clientId , query , sortDirection , sortIndex){
  return function(dispatch){
    clientService.getSharedUrlsAccess(clientId, query).then((response) => {

      var products = response.data;
      sortHelper.sortResults(products, sortDirection , sortIndex);

      dispatch({
        type: constants.client.ENGAGEMENT_UPDATEPRODUCTS,
        payload: clientHelper.getEngagementProducts(response.data)
      });
    });
  };
};

export const updateEngagementProducts = (engagementProducts) => {
  return {
    type: constants.client.ENGAGEMENT_UPDATEPRODUCTS,
    payload: engagementProducts
  };
};

export const updateEngagementPageCount = (pageCount) => {
  return {
    type : constants.client.ENGAGEMENT_UPDATEPAGECOUNT ,
    payload : pageCount
  };
};