import React from 'react';

const Label = ({color, text, textColor}) => {
  const style = {
    display: 'inline-block',
    marginLeft:'2px',
    marginBottom:'2px',
    borderRadius:3,
    color: textColor || 'white',
    fontWeight:'bolder',
    padding:'3px',
    textShadow: '5px black'
  };
  return (
    <span style={{...style,
      backgroundColor: color,
      boxShadow: '1px 2px 2px -2px black'
    }}>{text}</span>
  );
};

export default Label; 