import React from 'react';
import classnames from 'classnames';

import rewardService from '../../../utils/services/reward';

import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import './RewardsDropbox.scss';

export default class RewardsDropbox extends React.Component {

  constructor(props) {
    super(props);

    this.rewards = [];

    this.state = {
      reward: this.props.value ? this.props.value : null
    };
  }

  componentDidMount() {

    this.getRewardList(this.props.client);    
  }

  componentWillUpdate(nextProps){

    if(this.props.client != nextProps.client){
      if(nextProps.client){
        this.getRewardList(nextProps.client);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    
    if (typeof nextProps.value === 'object') {
      this.setState({ reward: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ reward: nextProps.value });
    }
  }

  getRewardList(clientId) {
    rewardService.getRewardList({clientId : clientId}).then((data) => {
      this.rewards = data.data.page
        .filter(item => item.archived !== true)
        .map(reward => ({
          label: `${reward.name} - ${reward._id}`,
          name: reward.name,
          value: reward._id,
          clientId: reward.clientId
        }));
      this.forceUpdate();
    });
  }

  onChange = (e) => {
    this.setState({reward: e.value});
    this.props.onChange(e);
  }

  campaignTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <div><b>Name:</b> {option.name}</div>
          <span><b>ID:</b> {option.value}</span>
        </div>
      );
    }
  }

  render() {
    const { client, disabled } = this.props;
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });
    const filteredRewards = client ? this.rewards.filter( reward => reward.clientId === client) : this.rewards;
    return (
      <div className={ cx }>
        <label>
          {this.props.label}
        </label>
        <Dropdown
          disabled={disabled}
          value={this.state.reward}
          options={filteredRewards}
          onChange={this.onChange}
          itemTemplate={this.campaignTemplate}
          style={{ marginLeft: '5px', width:'300px', marginTop: '4px'}}
          panelStyle={{width:'350px'}}
          filter={true}
          filterPlaceholder="Select Reward"
          filterBy="name,value"
          placeholder="Select a reward"
          showClear
        />
      </div>);
  }
}
