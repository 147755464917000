import { applyMiddleware , createStore , compose } from 'redux';
import config from '../config/config';
import thunk from 'redux-thunk';
import clientApp from '../reducers/';

const middleware = [thunk];


if (config.ENVIRONMENT === 'development') {
  const createLogger = require('redux-logger');
  const logger = createLogger();
  middleware.push(logger);
}

const store = compose(applyMiddleware(...middleware))(createStore)(clientApp);

export default store;
