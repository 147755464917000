import React from 'react';
import {connect} from 'react-redux';
import serialize from 'form-serialize';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import adminService from '../../../../utils/services/admin';
import objectHelper from '../../../../utils/helpers/objectHelper';
import {getFormattedAmount} from '../../../../utils/helpers/money';

import List from './WithdrawalRequestList';
import WithdrawalRequestModal from './WithdrawalRequestModal';

import * as withdrawalRequestActions from '../../../../actions/admin/withdrawalRequests';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

@connect((store)=>{
  return {
    withdrawalRequests: store.adminWithdrawalRequests.requests
  };
})
class WithdrawalRequests extends React.Component{

  constructor(){
    super();
    this.state = {
      withdrawalRequestSettings: {
        modalIsOpen: false,
        currentlyEditing: false,
        currentlyEditingStatus: false,
        showRejectStatusReasonInput: false,
        showBankReferenceInput: false
      },
      tableKeysArray: [
        {key: 'createdAt' , title: 'Date' , icon: 'fa fa-calendar'},
        {key: 'email' , title: 'Email' , icon: 'fa fa-envelope'},
        {key: 'bankInfoPreview' , title: 'Bank Preview' , icon: 'fa fa-credit-card'},
        {key: 'amount' , title: 'Amount' , icon: 'fa fa-money'},
        {key: 'status' , title: 'Status' , icon: 'fa fa-flag'}
      ],
      startDate: moment(),
      endDate: moment(),
      pageCount: 20,
      showFilters: false,
      currentSort: 'createdAt',
      direction: 'desc',
      searchTerm: '',
      currentPage: 1,
      nextPageAvailable: false,
      fetchingData: false
    };


  }

  changeWithdrawalRequestStatus(e){
    if(e.currentTarget.value === 'REJECTED'){
      this.setState({
        withdrawalRequestSettings: this.getUpdatedWithdrawalRequestSettings({showRejectStatusReasonInput: true , showBankReferenceInput : false})
      });

      return;
    }

    if(e.currentTarget.value === 'PAID'){
      this.setState({
        withdrawalRequestSettings: this.getUpdatedWithdrawalRequestSettings({showBankReferenceInput: true , showRejectStatusReasonInput: false})
      });

      return;
    }

    this.setState({
      withdrawalRequestSettings: this.getUpdatedWithdrawalRequestSettings({showRejectStatusReasonInput: false , showBankReferenceInput: false})
    });
  }

  clearSearch(){
    this.setState({
      searchQuery: null,
      searchTerm: ''
    });
    this.initialLoad();
  }

  closeModal(){
    this.setState({
      withdrawalRequestSettings: this.getUpdatedWithdrawalRequestSettings({modalIsOpen : false}),
      currentlyEditing: null
    });
  }

  componentWillMount(){
    this.initialLoad();
  }

  filterByDateRange(){
    var that = this;

    if (this.state.endDate.isBefore(this.state.startDate)) {
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'End date is before start date.',
          timeout: 10000
        }
      ));

      this.dateRangeHolder.classList.add('error');
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=email`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount,
      $type: 'WITHDRAWAL'
    };

    var dateQuery  = {
      $createdAt_$gt: this.state.startDate.format('YYYY-MM-DD'),
      $createdAt_$lt: this.state.endDate.add(1, 'days').format('YYYY-MM-DD')
    };

    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    adminService.getUserPaymentRequestsCount(query)
      .then(function(res){
        var totalCount = res.data[0].count;
        adminService.getUserPaymentRequests(query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });

            _.map(res.data , (request) =>{
              request.createdAt = moment(request.createdAt).format('DD/MM/YY');
              request.amount = getFormattedAmount(request.amount);
            });

            that.props.dispatch(withdrawalRequestActions.populateWithdrawalRequests(res.data));
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });

            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDays(){
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=email`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount,
      $type: 'WITHDRAWAL'
    };

    var dateQuery  = this.daysFilter.value ? {$createdAt_$gt: moment().subtract(this.daysFilter.value, 'days').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true,
      startDate: moment().subtract(this.daysFilter.value, 'days'),
      endDate: moment()
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    adminService.getUserPaymentRequestsCount(query)
      .then(function(res){
        var totalCount = res.data[0].count;
        adminService.getUserPaymentRequests(query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });

            _.map(res.data , (request) =>{
              request.createdAt = moment(request.createdAt).format('DD/MM/YY');
              request.amount = getFormattedAmount(request.amount);
            });

            that.props.dispatch(withdrawalRequestActions.populateWithdrawalRequests(res.data));

          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });

            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=email`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount,
      $type: 'WITHDRAWAL'
    };

    if(that.state.dateQuery){
      Object.assign(query , that.state.dateQuery);
    }

    that.setState({
      fetchingData: true
    });

    adminService.getUserPaymentRequestsCount(that.state.dateQuery ? that.state.dateQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        adminService.getUserPaymentRequests(query ,extendedQuery)
          .then(function(res){

            _.map(res.data , (request) =>{
              request.createdAt = moment(request.createdAt).format('DD/MM/YY');
              request.amount = getFormattedAmount(request.amount);
            });

            var requests = that.props.withdrawalRequests.concat(res.data);

            that.props.dispatch(withdrawalRequestActions.populateWithdrawalRequests(requests));
            that.setState({
              nextPageAvailable: requests.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  getUpdatedWithdrawalRequestSettings(settings){
    var withdrawalRequestSettings = objectHelper.getObjectClone(this.state.withdrawalRequestSettings);
    Object.assign(withdrawalRequestSettings , settings);
    return withdrawalRequestSettings;
  }

  handleChangeEnd(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ endDate: date });
  }

  handleChangeStart(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ startDate: date });
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=email`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount,
      $type: 'WITHDRAWAL'
    };

    adminService.getUserPaymentRequestsCount({})
      .then((res) => {
        var totalCount = res.data[0].count;
        adminService.getUserPaymentRequests(query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            _.map(res.data , (request) =>{
              request.createdAt = moment(request.createdAt).format('DD/MM/YY');
              request.amount = getFormattedAmount(request.amount);
            });

            that.props.dispatch(withdrawalRequestActions.populateWithdrawalRequests(res.data));
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  openModal(withdrawalRequestItem){
    this.setState({
      withdrawalRequestSettings: this.getUpdatedWithdrawalRequestSettings({
        modalIsOpen : true ,
        currentlyEditing: withdrawalRequestItem,
        showRejectStatusReasonInput : withdrawalRequestItem.status === 'REJECTED' ? true : false,
        showBankReferenceInput : withdrawalRequestItem.status === 'PAID' ? true : false
      })
    });
  }

  toggleCurrentlyEditingStatus(){
    this.setState({
      withdrawalRequestSettings: this.getUpdatedWithdrawalRequestSettings({currentlyEditingStatus : !this.state.withdrawalRequestSettings.currentlyEditingStatus})
    });
  }

  search (e) {
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=email`;

    var query = {
      $offset: 0,
      $type: 'WITHDRAWAL'
    };

    var searchQuery = that.searchBox.value ? {$search: that.searchBox.value} : null;
    query = Object.assign(query ,searchQuery);
    that.setState({
      searchQuery: searchQuery,
      fetchingData: true,
      searchTerm: that.searchBox.value
    });

    if(!searchQuery){
      that.initialLoad();
      return;
    }

    adminService.getUserPaymentRequests(query , extendedQuery)
      .then(function(res){

        that.setState({
          nextPageAvailable: false,
          range: {
            from: '',
            to: ''
          },
          currentPage: 1,
          fetchingData: false
        });

        _.map(res.data , (request) =>{
          request.createdAt = moment(request.createdAt).format('DD/MM/YY');
          request.amount = getFormattedAmount(request.amount);
        });

        that.props.dispatch(withdrawalRequestActions.populateWithdrawalRequests(res.data));

      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });

        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  updateStatus(e){
    e.preventDefault();
    var that = this;

    var formData = serialize(e.currentTarget , { hash: true });
    var withdrawalRequest = objectHelper.getObjectClone(this.state.withdrawalRequestSettings.currentlyEditing);

    var payload = {
      status : formData.status,
      meta:  Object.assign(withdrawalRequest.meta , {reason : formData.status === 'REJECTED' ? formData.reason : null , reference: formData.status ? formData.reference : null })
    };

    adminService.updateWithdrawalRequest(formData.userId , formData.requestId , payload)
      .then(function(){
        adminService.getUserPaymentRequests()
          .then((res) => {
            _.map(res.data , (request) =>{
              request.createdAt = moment(request.createdAt).format('DD/MM/YY');
              request.amount = getFormattedAmount(request.amount);
            });

            that.props.dispatch(withdrawalRequestActions.populateWithdrawalRequests(res.data));

            that.setState({
              withdrawalRequestSettings : that.getUpdatedWithdrawalRequestSettings({ currentlyEditing : _.find(res.data, {_id:formData.requestId}) })
            });

            that.toggleCurrentlyEditingStatus();
          });
      })
      .catch(function(err){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }


  render(){
    return(
      <div className="withdrawal-request-admin">
        <h4 className="mobile-alt-style section-heading hide-on-tablet hide-on-desktop">Withdrawal Requests</h4>
        <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
          {
            this.state.showFilters ?
              <div>Hide Filters <i className="fa fa-minus"></i></div>
              :
              <div>Show Filters <i className="fa fa-plus"></i></div>
          }
        </div>
        <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
          <div className="reports-filters search-holder">
            <form onSubmit={(e) => this.search(e) } action="">
              <input placeholder="Search" ref={ (input) => this.searchBox = input } type="text" />
              <button className="primary">Search</button>
            </form>
            {
              this.state.searchTerm != '' ?
                <div className="toolbar-tab">
                  {this.state.searchTerm}
                  <i onClick={() => this.clearSearch() } className="fa fa-times"></i>
                </div>
                :
                false
            }
          </div>
          <div ref={(div) => this.dateRangeHolder = div} className="reports-filter-section">
            <div className="reports-filters select-holder">
              <div className="styled-select">
                <select ref={ (select) => this.daysFilter = select } onChange={() => this.filterByDays() } name="" id="">
                  <option value="">Date Range</option>
                  <option value="60">Last 60 days</option>
                  <option value="90">Last 90 days</option>
                  <option value="365">Last 12 months</option>
                </select>
              </div>
            </div>
            <div className="reports-filters date-picker-holder">
              <p>Date from</p>
              <DatePicker
                selected={this.state.startDate}
                selectsStart  startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(e) => this.handleChangeStart(e) } />
              <i className="fa fa-calendar-o"></i>
            </div>
            <div className="reports-filters date-picker-holder">
              <p>Date to</p>
              <DatePicker
                selected={this.state.endDate}
                selectsEnd  startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(e) => this.handleChangeEnd (e)} />
              <i className="fa fa-calendar-o"></i>
            </div>
            <div className="search-date-range-button-holder"><button onClick={() => this.filterByDateRange() } className="primary">Apply</button></div>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
        <h4 className="section-heading hide-on-mobile">Withdrawal Requests</h4>
        {
          this.props.withdrawalRequests && this.props.withdrawalRequests.length ?
            <div className="reports-page">
              <div className="table-container">
                <List rowEventListener={(withdrawalRequestItem) => this.openModal(withdrawalRequestItem)} withdrawalRequests={this.props.withdrawalRequests} tableKeysArray={this.state.tableKeysArray} />
                {
                  this.state.fetchingData ?
                    <div className="fetching loading-gif text-center">
                      <img src="images/loading-gif.gif"/>
                    </div>
                    :
                    false
                }
                {
                  this.state.nextPageAvailable ?
                    <div className="text-center">
                      <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
                    </div>
                    :
                    false
                }
              </div>
            </div>
            :
            <div className="text-center">
              {
                this.props.withdrawalRequests ?
                  <div className="no-data">
                    <p>There is currently no data</p>
                  </div>
                  :
                  <img src="/images/loading-gif.gif" style={{maxWidth: '400px'}} alt="Loading"/>
              }
            </div>
        }
        <WithdrawalRequestModal
          settings={this.state.withdrawalRequestSettings}
          withdrawalRequestItem={this.state.withdrawalRequestSettings.currentlyEditing}
          toggleCurrentlyEditingStatus={() => this.toggleCurrentlyEditingStatus()}
          closeModal={() => this.closeModal()}
          changeWithdrawalRequestStatus={(e) => this.changeWithdrawalRequestStatus(e)}
          updateStatus={(e) => this.updateStatus(e)}
        />
      </div>
    );
  }
}

export default WithdrawalRequests;