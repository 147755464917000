import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Dialog } from 'primereact/components/dialog/Dialog';

import { withQueryHelper } from '../../../../decorators/queryHelperDecorator/queryHelper-decorator';
import userService from '../../../../utils/services/user';
import SharedUrlPanel from './innerComponents/sharedUrl/panel'

const _chartLabels = [
                      { label:'E-mail', socialPlatform: 'EMAIL' },
                      { label:'Facebook', socialPlatform: 'FACEBOOK' },
                      { label:'Pinterest', socialPlatform: 'PINTEREST' },
                      { label:'Twitter', socialPlatform: 'TWITTER' },
                      { label:'Whatsapp', socialPlatform: 'WHATSAPP' },
                      { label:'Messenger', socialPlatform: 'MESSENGER' },
                      { label: 'Unallocated', socialPlatform: 'UNTRACKED' }
                    ];

@withQueryHelper
export class UserRemoval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isVisibleGrid: false,
      userList: [],
      dialogConfirmationVisible: false,

      startDate: props.calendar[0].startDate,
      endDate: props.calendar[0].endDate,
      searchField: ''
    };
  }

  renderComparisonData = () => {
    const previousStartDate = moment(this.state.previousStartDate).format('DD/MM/YYYY');
    const previousEndDate = moment(this.state.previousEndDate).format('DD/MM/YYYY');
    const { isPreviousChartHidden } = this.state;

    let previousData = !isPreviousChartHidden && (
      <div className={classnames('byChannel__comparisonData', { hideComparisonData: isPreviousChartHidden })}>
        <div className="byChannel__charts--header">
          <div className="header ">
            {previousStartDate} to {previousEndDate}
          </div>
        </div>

        {this.renderPreviousPieChart()}

        {this.renderPreviousFirstLineChart()}

        {this.renderPreviousSecondLineChart()}
      </div>
    );

    return previousData;
  };

  renderSocialPlatformItems = () => {
    let counter = 0;
    const { isPreviousChartHidden, showUntrackedClicks } = this.state;

    // Show untracked clicks only if it has results
    let chartDataLables = _.filter(_chartLabels, chartLable => {
      if((chartLable.socialPlatform !== 'UNTRACKED') || showUntrackedClicks){
        return chartLable;
      }
    });

    return chartDataLables.map(label => {
      counter++;
      return (
        <div 
          key={`platform-${label.label}`} 
          className={classnames(`item item-${counter}`, { [`noComparison__${label.label}`]: isPreviousChartHidden })}>
          <i
            className={`fa fa-square fa-square--${label.label}`}
            aria-hidden="true"
          />
          {label.label}
        </div>
      );
    });
  };

  onLazyLoad = () => {
    let email = this.state.searchField;

    if (email) {
      this.setState({
        userList: [],
        loading: true,
        isVisibleGrid: true
      });
      const objJsonStr = JSON.stringify(email);
      const objJsonB64 = Buffer.from(objJsonStr).toString('base64');
      const encodeEmail = encodeURIComponent(objJsonB64);
  
      userService.getUsersToRemoval(encodeEmail).then((response) => {
        for (let row of response.data.resultData) {
          row.userCreatedAt = moment(row.userCreatedAt).format('YYYY-MM-DD');
        }

        this.setState({
          userList: response.data.resultData,
          loading: false
        });
      })
      .catch(function(err){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      }).finally(() => this.setState({ loading: false }));
    }
    else {
      this.setState({
        userList: [],
        isVisibleGrid: false
      });      
    }
  }

  removal = () => {
    this.setState({ loading: true});

    userService.removal(this.state.userList[0]._id).then((response) => {
        this.setState({
        userList: [],
        loading: false,
        isVisibleGrid: false,
        dialogConfirmationVisible: false
      });
    })
    .catch(function(err){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: err.response.data.message,
          timeout: 10000
        }
      ));
    });
  };

  rowExpansionTemplate = data => <SharedUrlPanel list={data.sharedUrlFields} />

  renderConfirmationDialog = () => {
    var headerName = `Confirmation`;

    return (

      <Dialog
        header={headerName}
        footer={this.dialogConfirmationFooter()}
        visible={this.state.dialogConfirmationVisible}
        width="550px"
        modal={true}
        resizable={true}
        responsive={true}
        onHide={this.closeConfirmationPopup}
      >
        <div className="content-section implementation">
          <span>Are you sure you want remove this user data?</span>
        </div>

      </Dialog>
    );
  };

  dialogConfirmationFooter = () => {
    return <div style={{ display: 'inline-block', width: '100%' }} className="ui-dialog-buttonpanel p-clearfix">
      <Button label="Removal" onClick={(e) => this.removal()} />
      <Button label="Cancel" onClick={this.closeConfirmationPopup} />
    </div>;
  };

  closeConfirmationPopup = () => {
    var reset = {
      dialogConfirmationVisible: false
    };

    this.setState(reset);
  }

  renderSoretoDataTable = () => {
    const grid = this.state.isVisibleGrid && (
      <div className="byChannel__elements">
      <DataTable value={this.state.userList} 
        responsive={true}
        sortMode="multiple"
        onRowToggle={e => this.setState({ expandedRows: e.data })}
        expandedRows={this.state.expandedRows}
        rowExpansionTemplate={(e) => this.rowExpansionTemplate(e)}
        lazy={true} onLazyLoad={this.onLazyLoad} header="User data"
        ref={(el) => this.dt = el}
        loading={this.state.loading}
        >
        <Column  expander={true} style={{ width: '45px' }} />
        
        <Column field="_id" header="User Id" style={{width:'250px', display: process.env.NODE_ENV === 'development' ? '' : 'none'}} />
        <Column field="userCreatedAt" header="User Created" style={{width:'230px'}} />
        <Column field="email" header="Email" style={{width:'450px'}} />
        <Column field="firstName" header="First Name" style={{width:'310px'}} />
      </DataTable>            
    </div>
    );

    return grid;
  };

  render() {
    return (
      <div className="byChannel-page grid-container grid-parent">
        <div className="byChannel-page--header">
          <h3 className="clientName"
            dangerouslySetInnerHTML={{ __html: this.state.clientName }}
          />
        </div>

        <div className="wrapper">
          <div className="byChannel__charts">
            <div className="header ">
                <div className="ui-g-2 ui-md-2">
                    <div style={{paddingBottom:'5px'}}>
                      <span>Email:</span>
                    </div>
                    <InputText style={{width: '800px'}} onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField} />   
                </div>
            </div>
            <div style={{margin: '35px 700px', display: 'flex'}}>
              <div className="ui-g">
                <div className="ui-g-2 ui-md-1">
                  <Button label="Search" onClick={this.onLazyLoad} />         
                </div>
              </div>
              <div className="ui-g">
              <div className="ui-g-2 ui-md-2">
                <Button disabled={this.state.userList.length === 0} label="Remove" onClick={ () => this.setState({dialogConfirmationVisible: true})} />   
              </div>            
              </div>
            </div>
          </div>
          
          {this.renderSoretoDataTable()}
          {this.renderConfirmationDialog()}
          
        </div>
      </div>
    );
  }
}

export default connect(store => {
  return {
    clientStats: store.clientStats.clientStatsGet,
    clientStatsPerChannelByPeriod:
      store.clientByChannel.byChannelClientStatsPerChannelByPeriodGet,
    calendar: store.sharedDateRangePicker.setCalendarDates
  };
})(UserRemoval);
