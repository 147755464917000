import React from 'react';
import Slides from './Slides';
import {connect} from 'react-redux';
import * as onBoardingActions from '../../../../actions/user/onBoardingActions';

@connect((store)=>{
  return {
    slides: store.userOnBoarding.slides
  };
})
class OnBoarding extends React.Component{

  closeOnBoarding(){
    this.props.dispatch(onBoardingActions.updateOnBoardingStatus(false));
  }

  render(){
    return(
      <div style={{overflow: 'hidden' , position: 'relative'}} className="grid-100 grid-parent">
        <Slides closeOnBoarding={() => this.closeOnBoarding()} slides={this.props.slides} />
      </div>
    );
  }
}
export default OnBoarding;