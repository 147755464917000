import React from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { browserHistory } from 'react-router';
import { Button } from 'primereact/components/button/Button';
import _ from 'lodash';
import moment from 'moment';

import * as clientActions from '../../../../actions/admin/clientActions';
import * as assocClientAffilliateActions from '../../../../actions/admin/assocClientAffilliateActions';
import clientService from '../../../../utils/services/client';

import AffiliateDropbox from '../../../shared/dropbox/AffiliateDropbox';
import ResponsibleDropbox from '../../../shared/dropbox/ResponsibleDropbox';

import * as globalMessageActions from '../../../../actions/globalMessageActions';

import FormElement from '../../../shared/form/FormElement';
import ClientLogo from '../dashboard/ClientLogo';
import { getImageData, handleImageChange } from './client-functions';
import {Panel} from 'primereact/components/panel/Panel';
import * as layoutActions from '../../../../actions/admin/layoutActions';
import common from './common';
import DomainsPanel from './DomainsPanel';
import UrlsPanel from './UrlsPanel';
import { constants } from '../../../../constants/contants';

var reportSourceDataType = [ 'JOINED', 'SORETO', 'EXTERNAL-AFFILIATE' ];

const _fixedMetaEditKey = 's0rmet@edit';

export class ClientEdit extends React.Component {
  constructor(props) {
    super(props);

    this.clientLogo = React.createRef();

    const {
      affiliates = [],
      clientDetails = {
        _id: '',
        name: '',
        email: '',
        countryId: '',
        referer: '',
        percentCommission: '',
        tier: '',
        industry: '',
        feeBased: false,
        launchedAt:'',
        primaryContactFirstName: '',
        primaryContactLastName: '',
        primaryContactEmail: '',
        primaryContactPhone: '',
        primaryContactAddressLine1: '',
        primaryContactAddressLine2: '',
        primaryContactTownCity: '',
        primaryContactAreaCounty: '',
        primaryContactCountry: '',
        primaryContactPostCode: '',
        ContactFirstName: '',
        billingContactLastName: '',
        billingContactEmail: '',
        billingContactPhone: '',
        billingContactAddressLine1: '',
        billingContactAddressLine2: '',
        billingContactTownCity: '',
        billingContactAreaCounty: '',
        billingContactCountry: '',
        billingContactPostCode: '',
        active: '',
        mpActive: '',
        responsibleId: '',
        meta: {},
        urlWhitelist: [],
        urlBlacklist: [],
        externalId: '',
        shopifyEnabled: false,
        shopifyDomain: ''
      }
    } = props;

    this.state = {
      affiliates,
      clientDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      uploadError: null,
      askElasticReindex: false,
      whiteListTag: null,
      blackListTag: null,
      showIncomingOrigins: false,
      updateOriginsData: false
    };

  }

  onChange(name, { target }) {
    const { clientDetails } = this.state;
    _.set(clientDetails, name, target.value);
    this.setState({ clientDetails });
  }
  
  onResponsibleChange(e){
    const { clientDetails } = this.state;
    clientDetails.responsibleId = e.value;
    this.setState({clientDetails});
  }

  onTierChange (tierValue){
    const {clientDetails}= this.state;
    clientDetails.tier = tierValue;
    this.setState({clientDetails});
  }

  onIndustryChange (industry){
    const {clientDetails}= this.state;
    clientDetails.industry = industry;
    this.setState({clientDetails});
  }

  onfeeBasedChange(value){
    const {clientDetails}= this.state;
    clientDetails.feeBased= value;
    this.setState({clientDetails});
  }

  handleLaunchedAtChange(date){
    const {clientDetails}= this.state;
    clientDetails.launchedAt= date;
    this.setState({clientDetails});
  }


  onAffiliateChange(name, _id, { target }) {
    
    const { affiliates } = this.state;

    let conn = _.find(affiliates, (a) => a._id == _id);

    if(target.type == 'checkbox'){
      _.set(conn, name, target.checked);
    }else {
      _.set(conn, name, target.value);  
    }
    
    this.setState({ affiliates });
  }

  onAffiliateDateChange(name, _id, e){

    const { affiliates } = this.state;

    let conn = _.find(affiliates, (a) => a._id == _id);

    _.set(conn, name, e.value);
    this.setState({ affiliates });
  }

  onAffiliatePickerChange(_id, e) {

    const { affiliates } = this.state;

    let conn = _.find(affiliates, (a) => a._id == _id);

    conn.affiliateId = _.get(e, 'value.value') ? e.value.value : '';

    if(e.value.label == 'awin'){
      conn.autoUntrackedOrderInquiry = true;
    }

    this.setState({ affiliates });
  }

  onShopifyChange(target, name){
    const {clientDetails}= this.state;

    if(target.type == 'checkbox'){
      clientDetails[name] = target.checked;
    }else {
      clientDetails[name]= target.value;
    }

    this.setState({clientDetails});
  }

  onAddAffiliate() {

    const { affiliates } = this.state;

    let globalCountry = this.state.countries.find(c => c.label == 'GLOBAL');

    // clean the values
    let affiliate = {
      _id: affiliates ? affiliates.length + 1 : 1,
      affiliateId: '',
      merchantId: '',
      connectedAt: '',
      disconnectedAt: '',
      reportOrderSource: '',
      reportClickSource: '',
      countryId: globalCountry.value,
      autoUntrackedOrderInquiry: false,
    };
    
    affiliates.push(affiliate);

    this.setState({ affiliates });
  }

  onAffiliateToDelete(_id) {

    const { affiliates } = this.state;

    let conn = _.find(affiliates, (a) => a._id == _id);

    conn.toDelete = true;

    this.setState({ affiliates });
    this.forceUpdate();
  }

  onEditMetaPasswordChange({ target }){
    let { editMetaPassword } = this.state;
    editMetaPassword = target.value;
    this.setState({ editMetaPassword });
  }

  componentWillMount() {
    var clientId = this.props.params.clientId;

    let countries = [];
    layoutActions.getCountriesFromCache()
      .forEach(country => {
        countries.push({ label: country.code, value: country._id });
      });

    this.setState({countries});

    if (clientId) {
      this.props.dispatch(clientActions.getClient(clientId));
      this.props.dispatch(clientActions.getClients());
      this.props.dispatch(assocClientAffilliateActions.getAssocClientAffilliateByClientId( clientId ));
    }
  }

  onRefreshDataRequest = () =>{
    this.setState({updateOriginsData: !this.state.updateOriginsData});
  }
  
  componentDidMount () {
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New client added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.client != nextProps.client) {
      _.set(nextProps.client, 'referer', _.map(nextProps.client.referer).join(','));
      _.set(nextProps.client, 'percentCommission', nextProps.client.percentCommission.default.toString());
    }

    this.setState({ clientDetails: nextProps.client, affiliates : nextProps.affiliates });
  }

  componentDidUpdate = prevProps => {

    if (this.props.clientEdit != prevProps.clientEdit) {

      if (_.get(this.props, 'clientEdit.name')) {

        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'Client saved with success',
            timeout: 20000
          })
        );

        let { affiliates } = this.state;

        affiliates = _.sortBy(affiliates, ['connectedAt']);

        this.setState({affiliates});

        // iterate over the affiliate list and add each item of the list
        _.each(affiliates, (affiliate) => {

          let affiliateWithoutNull = _.pickBy(affiliate, (e) => e != null ? e.toString() : null);

          // deal with  dates
          affiliateWithoutNull.connectedAt = moment(affiliate.connectedAt).format('YYYY-MM-DD');
          affiliateWithoutNull.disconnectedAt = Boolean(affiliate.disconnectedAt) == false ? null : moment(affiliate.disconnectedAt).format('YYYY-MM-DD');

          if (affiliateWithoutNull.toDelete) {

            // DELETE
            return this.props.dispatch(
              assocClientAffilliateActions.deleteAssocClientAffilliate(affiliateWithoutNull._id)
            );

          } else if (isNaN(affiliateWithoutNull._id)) {
            
            // UPDATE
            return this.props.dispatch(
              assocClientAffilliateActions.updateAssocClientAffilliate(affiliateWithoutNull)
            );

          } else if (!isNaN(affiliateWithoutNull._id)) {

            // CREATE
            let affiliateObj = affiliateWithoutNull;
            affiliateObj.clientId = this.state.clientDetails._id;

            return this.props.dispatch(
              assocClientAffilliateActions.newAssocClientAffilliate(affiliateObj)
            );
          }
        });
      }

      if (_.get(this.props, 'clientEdit.data')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error when saving client: ' + this.props.clientEdit.data.message,
            timeout: 10000
          })
        );
      }
    }

    if (this.props.affiliateEdit != prevProps.affiliateEdit) {
      if (_.get(this.props, 'affiliateEdit.data')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message:
              'Error when saving affilate association: ' +
              this.props.affiliateEdit.status +
              ' ' +
              this.props.affiliateEdit.data.message,
            timeout: 10000
          })
        );
      }

      if (_.get(this.props, 'affiliateEdit._id')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'edited with affiliate',
            timeout: 20000
          })
        );
      }

      if (_.get(this.props, 'affiliateNew._id')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'created new affiliate association',
            timeout: 20000
          })
        );
      }
    }
  };

  askReIndex(state) {
    this.setState({ askElasticReindex: state });
  }

  reIndex(clientId) {

    clientService.reIndexClient(clientId)
      .then(() => {

        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'The reindex was sent to be processed.',
            timeout: 20000
          })
        );
      })
      .catch((err) => {

        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: `Something went wrong with the Reindex request: ${err}.`,
            timeout: 20000
          })
        );
      });
  }

  flushTagDetails(clientId) {
    clientService.flushTagDetails(clientId)
      .then(() => {

        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'Tag Details Flushed',
            timeout: 20000
          })
        );

      })
      .catch((err) => {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: `Something went wrong while trying to flush the client's Tag Details: ${err}.`,
            timeout: 20000
          })
        );

      });
  }

  onSubmit() {
    if (this.state.clientDetails.launchedAt) {
      this.state.clientDetails.launchedAt = moment(this.state.clientDetails.launchedAt).startOf('date').format();
    }

    this.setState({ displayErrorMessage: false });

    const { clientDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    var recursiveValidation = (errors) => {

      for (const key in errors) {

        if(key == '_id'){
          continue;
        }

        if(Array.isArray(errors[key])){
          _.map(errors[key], (e)=> recursiveValidation(e));
        }else if (errors[key]) {
          checkErrors = true;
          this.setState({ displayErrorMessage: true });
          this.props.dispatch(
            globalMessageActions.showMessage({
              type: 'error',
              message: 'Please fill all the required',
              timeout: 10000
            })
          );
          break;
        }
      }
    };

    recursiveValidation(errors);

    var clientDetailsLocal = _.clone(clientDetails);
    clientDetailsLocal.referer = clientDetailsLocal.referer.toString().split([',']);
    clientDetailsLocal.percentCommission = _.toNumber(clientDetails.percentCommission);

    if (!checkErrors) {
      const imageData = getImageData(this.clientLogo);
      clientDetailsLocal.imageData = imageData && imageData;

      this.props.dispatch(clientActions.updateClient(clientDetailsLocal._id, clientDetailsLocal));
    }
  }

  makePrimaryEqualBilling(e) {
    if (e) e.preventDefault();
    const { clientDetails } = this.state;
    clientDetails.billingContactFirstName = clientDetails.primaryContactFirstName;
    clientDetails.billingContactLastName = clientDetails.primaryContactLastName;
    clientDetails.billingContactEmail = clientDetails.primaryContactEmail;
    clientDetails.billingContactPhone = clientDetails.primaryContactPhone;
    clientDetails.billingContactAddressLine1 = clientDetails.primaryContactAddressLine1;
    clientDetails.billingContactAddressLine2 = clientDetails.primaryContactAddressLine2;
    clientDetails.billingContactTownCity = clientDetails.primaryContactTownCity;
    clientDetails.billingContactAreaCounty = clientDetails.primaryContactAreaCounty;
    clientDetails.billingContactCountry = clientDetails.primaryContactCountry;
    clientDetails.billingContactPostCode = clientDetails.primaryContactPostCode;
    clientDetails.active = clientDetails.active;
    clientDetails.mpActive = clientDetails.mpActive;
    this.setState({ clientDetails });
  }

  validateForm() {
    const { clientDetails, affiliates, errors } = this.state;

    errors['name'] = _.isEmpty(clientDetails.name);
    errors['countryId'] = _.isEmpty(clientDetails.countryId);
    errors['email'] = _.isEmpty(clientDetails.email) || !validator.isEmail(clientDetails.email);
    if (typeof clientDetails.percentCommission === 'number') {
      errors['percentCommission'] = validator.isEmpty(clientDetails.percentCommission.toString());
    } else {
      errors['percentCommission'] = _.isEmpty(clientDetails.percentCommission);
    }
    errors['active'] = validator.isEmpty(clientDetails.active.toString());
    errors['mpActive'] = validator.isEmpty(clientDetails.mpActive.toString());
    errors['industry'] = validator.isEmpty(clientDetails.industry || '');

    errors['affiliates'] = [];

    _.each(affiliates, (affiliate) => {

      if(!affiliate.toDelete){

        let conflict = _.some(affiliates, (a) => !a.toDelete && a.affiliateId == affiliate.affiliateId && a.merchantId == affiliate.merchantId && a._id != affiliate._id );

        errors['affiliates'].push({
          _id: affiliate._id,
          conflict: conflict,
          merchantId : _.isEmpty(affiliate.merchantId),
          connectedAt : ((_.isNull(affiliate.connectedAt) || _.isEmpty(affiliate.connectedAt.toString())))
        });
      }      
    });

    if (clientDetails.shopifyEnabled) {
      errors['shopifyDomain'] = _.isEmpty(clientDetails.shopifyDomain);
    }
    else {
      errors['shopifyDomain'] = '';
    }

    return this.setState({ errors });
  }

  buildAffiliateAssocEditor(affiliates, countries, errors) {

    if(!affiliates){
      return;
    }

    let affilatesNotToDelete = affiliates.filter(a => !a.toDelete);

    let buildLine =(affiliate) => {

      let error = (errors && errors.affiliates)
        ? (errors.affiliates.find(e => e._id == affiliate._id) || {})
        : {};

      let messages = [];

      if(error.conflict){
        messages.push('This register is duplicated');
      }

      if(error.affiliateId){
        messages.push('Affiliate is required');
      }

      if(error.merchantId){
        messages.push('Merchant Id is required');
      }

      if(error.connectedAt){
        messages.push('Connected Date is required');
      }

      if(error.shopifyDomain){
        messages.push('Shopify Domain is required');
      }

      if(affiliate.connectedAt){
        affiliate.connectedAt = moment(affiliate.connectedAt, 'YYYY-MM-DD HH:mm:ss.SS').utc(false).toDate();
      } 

      if(affiliate.disconnectedAt){
        affiliate.disconnectedAt = new Date(affiliate.disconnectedAt);
      }

      return (
        <div className="ui-g" style={(messages.length > 0) ? {backgroundColor:'#f7a49e'} : {}}>

          <div className="ui-g-2">
            <AffiliateDropbox
              value={affiliate.affiliateId}
              overwriteStyle={{ height:35, marginLeft:0, width:'60%'}}
              onChange={this.onAffiliatePickerChange.bind(this, affiliate._id)}>
            </AffiliateDropbox>

          </div>

          <div className="ui-g-1">
            <FormElement
              name="merchantId"
              type="text"
              error={error.merchantId ? ' ' : false}
              onChange={this.onAffiliateChange.bind(this, 'merchantId', affiliate._id)}
              value={affiliate.merchantId}
              style={{width:100, marginTop:0, height:35, marginLeft:0, borderRadius:4}}
              id="form-merchantId"
              affiliateId
            />
          </div>

          <div className="ui-g-1">
            <FormElement
              name="connectedAt"
              type="calendar"
              error={error.connectedAt ? ' ' : false}
              onChange={this.onAffiliateDateChange.bind(this, 'connectedAt', affiliate._id)}
              value={affiliate.connectedAt}
              id="form-connectedAt"
              affiliateId
              style={{ width: '100px', marginLeft:0, marginTop:0, height:35 }}
            />
          </div>

          <div className="ui-g-1">
            <FormElement
              name="disconnectedAt"
              type="calendar"
              onChange={this.onAffiliateDateChange.bind(this, 'disconnectedAt', affiliate._id)}
              value={affiliate.disconnectedAt}
              id="form-disconnectedAt"
              affiliateId
              style={{ width: '100px', marginLeft:0, marginTop:0, height:35 }}
            />
          </div>

          <div className="ui-g-2">
            <FormElement
              name="reportOrderSource"
              type="select"
              onChange={this.onAffiliateChange.bind(this, 'reportOrderSource', affiliate._id)}
              value={affiliate.reportOrderSource}
              id="form-report-order-data-source"
              affiliateId
              style={{ width: '150px', marginLeft:0, marginTop:0, height:35, borderRadius:4 }} >

              {reportSourceDataType.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}

            </FormElement>
          </div>

          <div className="ui-g-2">
            <FormElement
              name="reportClickSource"
              type="select"
              onChange={this.onAffiliateChange.bind(this, 'reportClickSource', affiliate._id)}
              value={affiliate.reportClickSource}
              id="form-report-click-data-source"
              affiliateId
              style={{ width: '150px', marginLeft:0, marginTop:0, height:35, borderRadius:4 }}>

              {reportSourceDataType.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}

            </FormElement>
          </div>

          <div className="ui-g-1">
            <FormElement
              type='dropdown'
              id='form-campaign-country'
              name='campaignDetails.countryId'
              value={affiliate.countryId}
              options={countries}
              onChange={ (e) => { this.onAffiliateChange('countryId', affiliate._id, {target : e}); } }
              error={error.countryId}
              placeholder="Country"
              style={{marginTop:0, height:35, width: '100px', minWidth: '100px'}}
              required
            />
          </div>

          <div className="ui-g-1">
            <FormElement
              type='checkbox'
              id='form-affiliate-auto-inquiry'
              name='autoUntrackedOrderInquiry'
              checked={affiliate.autoUntrackedOrderInquiry}
              onChange={ (e) => { 
                var target = e.target;
                this.onAffiliateChange('autoUntrackedOrderInquiry', affiliate._id, {target : target}); } }
              error={error.autoUntrackedOrderInquiry}
              placeholder="Auto Order Inquiry"
              style={{marginTop:0, height:35, width: '50px', minWidth: '50px'}}
            />
          </div>

          <div className="ui-g-1">
            <Button
              type="button"
              icon="fa-trash"
              className="ui-button-warning"
              onClick={this.onAffiliateToDelete.bind(this, affiliate._id)}
              style={{marginTop:7, height:35, padding: '0px 10px 0px 10px'}}></Button>
          </div>

          {messages && _.map(messages, (e, i) => (<small style={{color:'red', marginTop:20}}>{(i > 0 ? ', ': '')}{e}</small>))}

        </div>);
    };

    return (

      <div>

        {/* HEADER */}
        {affilatesNotToDelete.length > 0 && (
          <div className="ui-g">
            <div className="ui-g-2">Affiliate</div>
            <div className="ui-g-1">Merchant Id</div>
            <div className="ui-g-1">Connected Date</div>
            <div className="ui-g-1">Disconnected Date</div>
            <div className="ui-g-2">Order Source</div>
            <div className="ui-g-2">Click Source</div>
            <div className="ui-g-1">Country</div>
            <div className="ui-g-1">Order Auto-Inquiry</div>
          </div>
        )}

        {/* LINE LOOP */}
        {_.map(affilatesNotToDelete, function(a) { return buildLine(a); })}

        {/* ADD BTN */}
        <div className="ui-g">
          <div className="ui-g-1">
            <Button
              type="button"
              icon="fa-plus"
              className="ui-button-warning"
              style={{marginTop:7, height:35, padding: '0px 10px 0px 10px'}}
              onClick={this.onAddAffiliate.bind(this)}>
            </Button>
          </div>
        </div>

      </div>
    );

  }

  toggleShowIncomingOrigins = () =>{
    this.setState({showIncomingOrigins: !this.state.showIncomingOrigins});
  }

  render() {
    const {
      clientDetails = { info: {} },
      affiliates,
      errors,
      askElasticReindex,
      editMetaShowPassword,
      editMetaPassword,
      countries,
      whiteListTag,
      blackListTag
    } = this.state;

    const {
      _id,
      name,
      email,
      countryId,
      referer,
      percentCommission,
      tier,
      industry,
      feeBased,
      launchedAt,
      primaryContactFirstName,
      primaryContactLastName,
      primaryContactEmail,
      primaryContactPhone,
      primaryContactAddressLine1,
      primaryContactAddressLine2,
      primaryContactTownCity,
      primaryContactAreaCounty,
      primaryContactCountry,
      primaryContactPostCode,
      billingContactFirstName,
      billingContactLastName,
      billingContactEmail,
      billingContactPhone,
      billingContactAddressLine1,
      billingContactAddressLine2,
      billingContactTownCity,
      billingContactAreaCounty,
      billingContactCountry,
      billingContactPostCode,
      active,
      mpActive,
      responsibleId,
      meta,
      urlWhitelist,
      urlBlacklist,
      externalId,
      shopifyEnabled,
      shopifyDomain,
    } = clientDetails;


    const clientLogoValues = {};
    this.props.clients.forEach(client => {
      if (_id === client._id) {
        clientLogoValues['_id'] = client._id;
        clientLogoValues['name'] = client.name;
        clientLogoValues['imageId'] = client.imageId;
        clientLogoValues['imageUrl'] = client.imageUrl;
        clientDetails['fileInput'] = client.fileInput;
      }
    });

    return (

      <div className="client-new-client">
        <h1>Edit client</h1>

        <form className="c-form--flex">
          
          <FormElement
            label="Company name"
            name="name"
            type="text"
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id="form-name"
            required
          />
          <FormElement label="Company id" name="id" type="text" value={_id} disabled id="form-id" />

          <FormElement
            label="Email"
            name="email"
            type="email"
            onChange={this.onChange.bind(this, 'email')}
            value={email}
            error={errors.email}
            id="form-email"
            required
          />

          <FormElement
            label="Client Location"
            type='dropdown'
            id='form-country'
            name='countryId'
            style={{width:'100%', marginLeft:7}}
            value={countryId}
            options={countries}
            onChange={ (e) => { this.onChange('countryId', {target : e}); } }
            error={errors.countryId}
            placeholder="Client Location"
            required
          />

          <FormElement
            label="Referers split by commas"
            name="referer"
            type="text"
            onChange={this.onChange.bind(this, 'referer')}
            value={referer}
            error={errors.referer}
            id="form-referer"
            required
          />

          <FormElement
            label="Percent commission"
            name="percentCommission"
            type="text"
            onChange={this.onChange.bind(this, 'percentCommission')}
            value={percentCommission}
            error={errors.percentCommission}
            id="form-percentCommission"
            required
          />

          <FormElement
            label="Tier"
            type='dropdown'
            name='tier'
            style={{width:'100%', marginLeft:7}}
            value={tier}
            options={
              [
                {label: 'Tier 1', value: 1}, 
                {label: 'Tier 2', value: 2},
                {label: 'Tier 3', value: 3},
              ]}
            onChange={(e)=>{ this.onTierChange(e.value);}}
            placeholder="Client Tier"
            required
          />

          <div>
            <p style={{fontSize:'14px', margin:'0px', marginBottom:'5px', marginTop:'10px'}}>Fee Based</p>
            <label style={{display:'inline', margin:'0px', marginBottom:'5px'}}>
              <input
                type="radio"
                name="feeBased"
                value={true}
                onClick={() => {
                  this.onfeeBasedChange(true);
                }}
                checked={feeBased}
              />
              Yes
            </label>

      
            <label>
              <input
                type="radio"
                name="feeBased"
                value={false}
                onClick={() => {
                  this.onfeeBasedChange(false);
                }}
                checked={!feeBased}
              />
              No
            </label>
          </div>

          <FormElement
            label="Industry"
            type='dropdown'
            name='industry'
            style={{width:'100%', marginLeft:7}}
            value={industry}
            options={constants.DOMAIN_OPTIONS.industries.dropdownValues}
            onChange={(e)=>{ this.onIndustryChange(e.value);}}
            placeholder="Client Industry"
            error={errors.industry}
            required
          />

          <div>
            <p style={{fontSize:'14px', margin:'0px', marginBottom:'5px', marginTop:'10px'}}>Launched at</p>
            
            <FormElement
              id='launchedAt'
              name="launchedAt"
              type="calendar"
              error={errors.launchedAt ? ' ' : false}
              onChange={(e) => 
                this.handleLaunchedAtChange(e.value)
              } 
              value={ launchedAt ? new Date(launchedAt) : ''}
              style={{ marginLeft:0, marginTop:0, height:35 }}
            />
          </div>
          

          <h2>Primary contact</h2>

          <FormElement
            label="Primary contact first name"
            name="primaryContactFirstName"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactFirstName')}
            value={primaryContactFirstName}
            error={errors.primaryContactFirstName}
            id="form-primaryContactFirstName"
          />

          <FormElement
            label="Primary contact Last Name"
            name="primaryContactLastName"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactLastName')}
            value={primaryContactLastName}
            error={errors.primaryContactLastName}
            id="form-primaryContactLastName"
          />

          <FormElement
            label="Primary contact email"
            name="primaryContactEmail"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactEmail')}
            value={primaryContactEmail}
            id="form-primaryContactEmail"
          />

          <FormElement
            label="Primary contact phone"
            name="primaryContactPhone"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactPhone')}
            value={primaryContactPhone}
            id="form-primaryContactPhone"
          />

          <FormElement
            label="Primary contact address line 1"
            name="primaryContactAddressLine1"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactAddressLine1')}
            value={primaryContactAddressLine1}
            id="form-primaryContactAddressLine1"
          />

          <FormElement
            label="Primary contact address line 2"
            name="primaryContactAddressLine2"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactAddressLine2')}
            value={primaryContactAddressLine2}
            id="form-primaryContactAddressLine2"
          />

          <FormElement
            label="Primary contact town city"
            name="primaryContactTownCity"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactTownCity')}
            value={primaryContactTownCity}
            id="form-primaryContactTownCity"
          />

          <FormElement
            label="Primary contact area county"
            name="primaryContactAreaCounty"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactAreaCounty')}
            value={primaryContactAreaCounty}
            id="form-primaryContactAreaCounty"
          />

          <FormElement
            label="Primary contact country"
            name="primaryContactCountry"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactCountry')}
            value={primaryContactCountry}
            id="form-primaryContactCountry"
          />

          <FormElement
            label="Primary contact postcode"
            name="primaryContactPostCode"
            type="text"
            onChange={this.onChange.bind(this, 'primaryContactPostCode')}
            value={primaryContactPostCode}
            id="form-primaryContactPostCode"
          />

          <h2>Billing contact</h2>
          <button onClick={e => this.makePrimaryEqualBilling(e)}>Same as primary</button>

          <FormElement
            label="Billing contact first name"
            name="billingContactFirstName"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactFirstName')}
            value={billingContactFirstName}
            error={errors.billingContactFirstName}
            id="form-billingContactFirstName"
          />

          <FormElement
            label="Billing contact Last Name"
            name="billingContactLastName"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactLastName')}
            value={billingContactLastName}
            error={errors.billingContactLastName}
            id="form-billingContactLastName"
          />

          <FormElement
            label="Billing contact email"
            name="billingContactEmail"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactEmail')}
            value={billingContactEmail}
            id="form-billingContactEmail"
          />

          <FormElement
            label="Billing contact phone"
            name="billingContactPhone"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactPhone')}
            value={billingContactPhone}
            id="form-billingContactPhone"
          />

          <FormElement
            label="Billing contact address line 1"
            name="billingContactAddressLine1"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactAddressLine1')}
            value={billingContactAddressLine1}
            id="form-billingContactAddressLine1"
          />

          <FormElement
            label="Billing contact address line 2"
            name="billingContactAddressLine2"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactAddressLine2')}
            value={billingContactAddressLine2}
            id="form-billingContactAddressLine2"
          />

          <FormElement
            label="Billing contact town city"
            name="billingContactTownCity"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactTownCity')}
            value={billingContactTownCity}
            id="form-billingContactTownCity"
          />

          <FormElement
            label="Billing contact area county"
            name="billingContactAreaCounty"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactAreaCounty')}
            value={billingContactAreaCounty}
            id="form-billingContactAreaCounty"
          />

          <FormElement
            label="Billing contact country"
            name="billingContactCountry"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactCountry')}
            value={billingContactCountry}
            id="form-billingContactCountry"
          />

          <FormElement
            label="Billing contact postcode"
            name="billingContactPostCode"
            type="text"
            onChange={this.onChange.bind(this, 'billingContactPostCode')}
            value={billingContactPostCode}
            id="form-billingContactPostCode"
          />

          <FormElement
            label="Active"
            name="active"
            type="select"
            onChange={this.onChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id="form-active"
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label="Marketplace Active"
            name="mpActive"
            type="select"
            onChange={this.onChange.bind(this, 'mpActive')}
            value={mpActive}
            error={errors.mpActive}
            id="form-mp-active"
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>          

          <h3>Add/Edit Logo</h3>
          <ClientLogo
            handleChange={() => handleImageChange(this)}
            item={clientLogoValues}
            ref={this.clientLogo}
          />

          <h3>Responsible</h3>
          <ResponsibleDropbox
            label="Responsible"
            value={responsibleId}
            onChange={ e => this.onResponsibleChange(e)}
          />

          <div className="ui-g" style={{marginTop:30}}>
            <Panel header="Affiliates" toggleable={true} className="ui-g-12">
              { this.buildAffiliateAssocEditor(affiliates, countries, errors)}
            </Panel>
          </div>

          <div className="ui-g" style={{marginTop:30}}>
            <Panel header="Integration" toggleable={true} className="ui-g-12" >

              <div style={{'display':'flex','justifyContent':'space-between'}}>

                <div>
                  <FormElement
                    label='External Id'
                    name='externalId'
                    type='text'
                    value={externalId}
                    onChange={this.onChange.bind(this, 'externalId')}
                    id='form-source-tag'
                  >
                  </FormElement> 

                </div>
                <div>
                  <FormElement
                    label='White List'
                    name='urlWhitelist'
                    type='text'
                    onChange={ (e) => {  
                      if(e.target.fromSugestion){ 
                        common.addRestrictionOptionWhiteList(this, e.target.value, 'urlWhitelist') ;
                      } else { 
                        this.setState({whiteListTag : e.target.value}); 
                      }
                    }}
                    onKeyPress={
                      (e) => { 
                        if(e.key == 'Enter'){ 
                          common.addRestrictionOptionWhiteList(this, whiteListTag, 'urlWhitelist');
                        } 
                      }
                    }
                    value={whiteListTag}
                    id='form-source-tag'
                  >
                  </FormElement> 

                  <div>
                    { common.sourceTagOptionsWhiteList(this, urlWhitelist) }
                  </div>

                </div>
                <div>

                  <FormElement
                    label='Black List'
                    name='urlBlacklist'
                    type='text'
                    onChange={ (e) => {  
                      if(e.target.fromSugestion){ 
                        common.addRestrictionOptionBlackList(this, e.target.value, 'urlBlacklist') ;
                      } else { 
                        this.setState({blackListTag : e.target.value}); 
                      }
                    }}
                    onKeyPress={
                      (e) => { 
                        if(e.key == 'Enter'){ 
                          common.addRestrictionOptionBlackList(this, blackListTag, 'urlBlacklist');
                        } 
                      }
                    }
                    value={blackListTag}
                    id='form-source-tag'
                  >
                  </FormElement> 

                  <div>
                    { common.sourceTagOptionsBlackList(this, urlBlacklist) }
                  </div>
                </div>
              </div>
     
            </Panel>
          </div>

          <div className="ui-g" style={{ marginTop: 30 }}>
            <Panel
              header="Incoming Origins"
              toggleable={true}
              className="ui-g-12"
              collapsed={!this.state.showIncomingOrigins}
              onCollapse={()=>{this.toggleShowIncomingOrigins();}}
            >
              <React.Fragment>
                <Button
                  type='button'
                  label="Refresh data"
                  icon="fa-refresh"
                  style={{ marginLeft: 4, marginTop: 4, marginRight: 6,marginBottom: '20px', display: 'block' }}
                  onClick={this.onRefreshDataRequest}
                />
              </React.Fragment>
              <div style={{'display':'flex','justifyContent':'space-between'}}>

                {this.state.showIncomingOrigins && 
              <React.Fragment>
                <div className="ui-g-6">
                  <DomainsPanel
                    name="Incoming domains"
                    internalId={this.state.clientDetails._id}
                    externalId={this.state.clientDetails.externalId}
                    whiteList= {this.state.clientDetails.urlWhitelist}
                    blackList= {this.state.clientDetails.urlBlacklist}
                    update= {this.state.updateOriginsData}
                  />
                </div>
                <div className="ui-g-6">
                  <UrlsPanel
                    name="Incoming URLs"
                    internalId={this.state.clientDetails._id}
                    externalId={this.state.clientDetails.externalId}
                    whiteList= {this.state.clientDetails.urlWhitelist}
                    blackList= {this.state.clientDetails.urlBlacklist}
                    update= {this.state.updateOriginsData}
                  />
                </div>
              </React.Fragment>
                }
              </div>
            </Panel>
          </div>

          <div className="ui-g" style={{marginTop:30}}>
            <Panel header="Shopify details" toggleable={true} className="ui-g-12" >

              <div style={{'display':'flex','justifyContent':'space-evenly'}}>

                <div>
                  <FormElement
                    label='Shopify enabled'
                    type='checkbox'
                    id='form-shopify-enabled'
                    name='shopifyEnabled'
                    checked={shopifyEnabled}
                    onChange={ (e) => { 
                      var target = e.target;
                      this.onShopifyChange(target, 'shopifyEnabled');
                    }}
                    style={{marginTop:0, height:35, width: '50px', minWidth: '50px'}}
                  />
                </div>
                <div>
                  <FormElement
                    label='Shopify domain'
                    name='shopifyDomain'
                    type='text'
                    disabled={!shopifyEnabled}
                    onChange={ (e) => { 
                      var target = e.target;
                      this.onShopifyChange(target, 'shopifyDomain');
                    }}
                    onKeyPress={
                      (e) => { 
                        if(e.key == 'Enter'){ 
                          this.onShopifyChange(e.target, 'shopifyDomain');
                        } 
                      }
                    }
                    value={shopifyDomain}
                    error={errors.shopifyDomain}
                    id='form-source-tag'
                  >
                  </FormElement> 

                  <div>
                    { common.sourceTagOptionsWhiteList(this, urlWhitelist) }
                  </div>

                </div>
              </div>
     
            </Panel>
          </div>

        </form>

        <br></br>
        <button className="ui-button ui-widget ui-state-default ui-corner-all ui-button-info ui-button-icon-only" onClick={() => this.flushTagDetails(_id)}>Flush Tag Details</button>

        <br></br>
        {!askElasticReindex &&
          <button className="ui-button ui-widget ui-state-default ui-corner-all ui-button-info ui-button-icon-only" onClick={() => this.askReIndex(true)}>Reindex Elastic Search</button>
        }

        {askElasticReindex &&

          <div>
            <button className="ui-button ui-widget ui-state-default ui-corner-all ui-button-danger ui-button-icon-only" onClick={() => this.askReIndex(false)}>cancel</button>
            <button className="ui-button ui-widget ui-state-default ui-corner-all ui-button-success ui-button-icon-only" onClick={() => this.reIndex(_id)}>proceed</button>
          </div>
        }
        <br/>
        {!editMetaShowPassword &&
            <button
              className="ui-button ui-widget ui-state-default ui-corner-all ui-button-info ui-button-icon-only"
              style={{width: '250px'}}
              onClick={() => this.setState({ editMetaShowPassword: !editMetaShowPassword })}>
                Technical Purpose (META)
            </button>
        }
        {editMetaShowPassword && editMetaPassword != _fixedMetaEditKey &&
            <FormElement
              type="text"
              placeholder="password"
              value={editMetaPassword}
              onChange={this.onEditMetaPasswordChange.bind(this)}
            />
        }
        {editMetaShowPassword && editMetaPassword == _fixedMetaEditKey &&
            <FormElement
              label='meta'
              type='codearea'
              mode='javascript'
              className='client-meta'
              onChange={this.onChange.bind(this, 'meta')}
              value={JSON.stringify(meta)}
            >
            </FormElement>
        }

        <br></br>
        <br></br>

        <hr></hr>

        <button id="editButton" onClick={() => this.onSubmit()}>Save client</button>

        <button className="button icon-left" onClick={() => browserHistory.push('/admin/client')}>
          Back
        </button>
      </div>
    );
  }
}

export default connect(store => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    client: store.adminClient.client,
    clientEdit: store.adminClient.clientEdit,
    clients: store.adminDashboard.clients,
    affiliates: store.adminAssociateClientAffiliate.associateClientAffiliate,
    affiliateEdit: store.adminAssociateClientAffiliate.associateClientAffiliateEdit,
    affiliateNew: store.adminAssociateClientAffiliate.associateClientAffiliateNew,
    affiliateDelete: store.adminAssociateClientAffiliate.associateClientAffiliateDelete,
    countryId: store.adminLayout.layoutCountrySet
  };
})(ClientEdit);
