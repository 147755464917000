import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import { Button } from 'primereact/components/button/Button';
import moment from 'moment';
import _ from 'lodash';
import * as globalMessageActions from '../../../../../actions/globalMessageActions';
import { connect } from 'react-redux';
import ClientsDropbox from '../../../../shared/dropbox/ClientsDropbox';
import MultiSelectClientsDropbox from '../../../../shared/dropbox/MultiSelectClientsDropbox';
import reportServiceService from '../../../../../utils/services/reports';
import FormElement from '../../../../shared/form/FormElement';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import dataFormatter from '../../../../../../src/utils/helpers/DataFormatter';

const _calendarStyle = {
  marginLeft: '5px', width: '150px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
};
@connect()
export default class LiveStatsCommissionPerMonth extends React.Component {

  constructor() {
    super();

    this.state = this.getDefaultState();
    this.clientsDropboxRef = React.createRef();
    this.onChangePeriod = this.onChangePeriod.bind(this);
    this.isExportingToXLSX = false;
  }

  componentDidMount() {
  }

  resetFilter = (event) => {
    this.setState(this.getDefaultState(), () => this.onLazyLoad(event));
    this.clientsDropboxRef.current.onChange({ value: null })
  }

  getDefaultState() {

    return {
      stateColumns: [],
      stateData: [],
      currency: 'GBP',
      showActiveClients: 'active',
      feeBased: 'all',
      selectedClientTier: 'all',
      startDate: moment().subtract(12, 'months').subtract(1, 'days').startOf('month').toDate(),
      endDate: moment().subtract(1, 'days').toDate(),
      searchPeriod: 'last12months',
      selectedClientIds: [],
      clientName: '',
      originalResultData: {},
      colsFooter: [],
    };
  }

  getQuery(event) {

    var query = {};

    var gteDate = this.state.startDate ? { $date_$gte: moment(this.state.startDate).format('YYYY-MM-DD') } : {};
    var lteDate = this.state.endDate ? { $date_$lte: moment(this.state.endDate).format('YYYY-MM-DD') } : {};
    query = Object.assign(query, gteDate);
    query = Object.assign(query, lteDate);
    query = Object.assign(query, { '$clientIds': this.state.selectedClientIds });

    query = Object.assign(query, { showActiveClients: this.state.showActiveClients });
    query = Object.assign(query, { clientTier: this.state.selectedClientTier });
    query = Object.assign(query, { feeBased: this.state.feeBased });

    if (this.isExportingToXLSX) {
      query = Object.assign(query, {
        format: 'XLSX',
        currency: this.state.currency
      });
    }

    return query;
  }

  getExtendedQuery(event) {

    //cloning so when we assign to it it doesn't change the next events
    let multiSortMetaClone = _.cloneDeep(event.multiSortMeta)

    if (multiSortMetaClone) {
      this.setState({ multiSortMeta: multiSortMetaClone });
    }

    //When clicking the search button or exporting to xlsx the event.multiSortMeta is undefined
    //so we use the value previously stored on the state to bring the data sorted
    if (this.isExportingToXLSX || (event.currentTarget && event.currentTarget.id === 'searchButtonId')) {
      multiSortMetaClone = this.state.multiSortMeta;
    }

    var extendedQuery = '?';
    if (multiSortMetaClone != null && multiSortMetaClone.length > 0) {
      for (let sortMeta of multiSortMetaClone) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-'}${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }

    return extendedQuery;
  }

  onLazyLoad = (event) => {
    this.setState({ loading: true });

    reportServiceService.getCommissionsByMonth(this.getQuery(event), this.getExtendedQuery(event)).then((response) => {
      this.setState({
        originalResultData: JSON.parse(JSON.stringify(response.data)),
      });
      this.buildTableData(response.data, this.state.currency);
    });
  }

  buildTableData(resultData, currency) {

    //data array and monthsToDisplay in the format [yyyy-mm, yyyy-mm, etc]
    let { data, monthsToDisplay } = resultData;
    let cols = [];
    let totals = {};

    //Insert column 'Client'
    cols.push({ field: 'clientName', header: 'Client', sortable: true, style: { width: '100px' } })
    cols.push({ field: 'clientTier', header: 'Tier', sortable: false, style: { width: '50px' } })
    cols.push({ field: 'clientFeeBased', header: 'Fee Based', sortable: false, style: { width: '70px' } })

    //Iterate over each month (i.e 2021-09, 2021-10, etc) generating columns and formatting values
    let fieldName;
    for (let monthToDisplay of monthsToDisplay) {

      if (currency === 'OriginalValue') {
        fieldName = 'orderCommissionSum' + '_' + monthToDisplay;
      } else {
        fieldName = 'orderCommissionSum' + '_' + currency + '_' + monthToDisplay;
      }

      //Insert a column for each month yyyy-mm
      cols.push({
        field: fieldName,
        header: monthToDisplay,
        sortable: true,
        style: { width: '100px' }
      });

      //Generates the totals and format each row value with the currency
      for (let dataItem of data) {
        totals[fieldName] = totals[fieldName] || 0;
        totals[fieldName] += dataItem[fieldName] || 0;
        dataItem[fieldName] = dataFormatter.formatCurrency(dataItem[fieldName], currency, dataItem.clientCountryCurrencyCode);
      }
    }

    //Create 'Totals' row
    let colsFooter = [{ footer: 'Totals' }, {}, {}]
    for (let key in totals) {
      colsFooter.push({ footer: dataFormatter.formatCurrency(totals[key], currency) })
    }

    this.setState({
      stateColumns: cols,
      colsFooter,
      stateData: data,
      loading: false,
    })

  };

  exportToXLSX = (event) => {

    this.setState({ loading: true });
    this.isExportingToXLSX = true;
    let that = this;

    reportServiceService.getCommissionsByMonth(this.getQuery(event), this.getExtendedQuery(event), 'blob').then((response) => {

      this.setState({ loading: false });
      this.isExportingToXLSX = false;

      //create 'a' element from response.data
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));

      //get filename from header
      const filename = response.headers['content-disposition'].split('filename=')[1];
      a.setAttribute('download', filename);

      //append to DOM and click on it to force download
      document.body.appendChild(a);
      a.click();

      //clean up
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

    }).catch(function (error) {

      that.setState({ loading: false });
      that.isExportingToXLSX = false;

      that.props.dispatch(globalMessageActions.showMessage({
        type: 'error',
        message: 'An error has ocurred while trying to download the XLSX file',
        timeout: 10000
      }));

    })
  }

  onChangeCurrency(currency) {
    this.setState({
      currency,
    });
    this.buildTableData(JSON.parse(JSON.stringify(this.state.originalResultData)), currency);
  }

  onChangePeriod(e) {

    let startDate, endDate;
    switch (e.value) {
      case 'custom':
        startDate = moment(this.state.startDate);
        endDate = moment(this.state.endDate);
        break;
      case 'last12months':
        startDate = moment().subtract(12, 'months').subtract(1, 'days').startOf('month');
        endDate = moment().subtract(1, 'days');
        break;
      case 'last6months':
        startDate = moment().subtract(6, 'months').subtract(1, 'days').startOf('month');
        endDate = moment().subtract(1, 'days');
        break;
      case 'thisYear':
        startDate = moment().startOf('year');
        endDate = moment().subtract(1, 'days');
        break;
    }

    startDate = startDate.toDate();
    endDate = endDate.toDate();

    this.setState({ startDate, endDate, searchPeriod: e.value });
  }

  clientSelectionChanged(event) {
    this.setState(
      {
        selectedClientIds: event.value,
      });
  }

  render() {

    const clientsActiveSelectItems = [
      { label: 'All', value: 'all' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
    
    const clientTierOptions = [
      { label: 'All', value: 'all' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ];

    const feeBasedOptions = [
      { label: 'All', value: 'all' },
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];

    let periods = [
      { label: 'Custom', value: 'custom' },
      { label: 'Last 12 months', value: 'last12months' },
      { label: 'Last 6 months', value: 'last6months' },
      { label: 'This year', value: 'thisYear' },
    ];

    let columnsFooter = this.state.colsFooter.map((col, i) => {
      return <Column key={i} footer={col.footer} colSpan={col.colSpan} />;
    });

    let footerGroup = <ColumnGroup><Row>{columnsFooter}</Row></ColumnGroup>;

    let tableColumns = this.state.stateColumns.map((col, i) => {
      return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} />;
    });

    return (
      <div className="content-section implementation">

        {/* title */}
        <h3>Client Commission Per Month</h3>

        {/* line 1 */}
        <div className="content-section implementation">
          <div className="ui-g">

            {/* Client Status  */}
            <div className="ui-md-2" >
              <div style={{}}>
                <span>Client Status:</span>
              </div>
              <Dropdown
                value={this.state.showActiveClients}
                options={clientsActiveSelectItems}
                onChange={(e) => { this.setState({ showActiveClients: e.value }) }}
                style={{ height: '30px' }}
                placeholder="All" />
            </div>

            <div className="ui-md-2" >
              <div style={{}}>
                <span>Currency</span>
              </div>
              <FormElement
                type='dropdown'
                style={{ marginTop: '-8px' }}
                value={this.state.currency}
                options={[
                  { key: 'GBP', label: 'GBP', value: 'GBP' },
                  { key: 'USD', label: 'USD', value: 'USD' },
                  { key: 'EUR', label: 'EUR', value: 'EUR' },
                  { key: 'ClientCountryCurrency', label: 'CLIENT CURRENCY', value: 'ClientCountryCurrency' },
                  { key: 'OriginalValue', label: ' ORIGINAL VALUE', value: 'OriginalValue' }
                ]}
                onChange={(e) => { this.onChangeCurrency(e.value); }}
              />
            </div>

            {/* Select a client */}
            <div className="ui-md-4">
              <div style={{ marginTop: '14px' }}>
           
                <MultiSelectClientsDropbox 
                  ref={this.clientsDropboxRef}
                  onChange={(e) => this.clientSelectionChanged(e)} 
                />
              </div>
            </div>

          </div>
        </div>

        {/* line 2 */}
        <div className="content-section implementation">
          <div className="ui-g">

            <div className="ui-g-1 ui-md-2">
              <div style={{ paddingBottom: '5px' }}>
                <span>Date range:</span>
              </div>
              <FormElement
                type='dropdown'
                onChange={this.onChangePeriod}
                value={this.state.searchPeriod}
                style={_calendarStyle}
                options={periods}
              />
            </div>

            <div className="ui-g-2 ui-md-2">
              <div style={{ paddingBottom: '5px' }}>
                <span>from:</span>
              </div>
              <FormElement
                name='dateFrom'
                type='calendar'
                onChange={(e) => this.setState({ startDate: e.value })}
                value={this.state.startDate}
                id='dateFrom'
                required
                style={_calendarStyle}
                disabled={this.state.searchPeriod != 'custom'}
              />

            </div>

            <div className="ui-g-2 ui-md-2">
              <div style={{ paddingBottom: '5px' }}>
                <span>to:</span>
              </div>
              <FormElement
                name='dateTo'
                type='calendar'
                onChange={(e) => this.setState({ endDate: e.value })}
                value={this.state.endDate}
                id='dateTo'
                required
                style={_calendarStyle}
                disabled={this.state.searchPeriod != 'custom'}
              />
            </div>

             {/* Client Tier  */}
             <div className="ui-g-2 ui-md-2" >
              <div style={{}}>
                <span>Tier:</span>
              </div>
              <Dropdown
                value={this.state.selectedClientTier}
                options={clientTierOptions}
                onChange={(e) => { this.setState({ selectedClientTier: e.value }) }}
                style={{ height: '30px' }}
                placeholder="All" />
            </div>

             {/* Client Tier  */}
             <div className="ui-md-2" >
              <div style={{}}>
                <span>Fee Based:</span>
              </div>
              <Dropdown
                value={this.state.feeBased}
                options={feeBasedOptions}
                onChange={(e) => { this.setState({ feeBased: e.value }) }}
                style={{ height: '30px' }}
                placeholder="All" />
            </div>


          </div>
        </div>

        {/* line 3 */}
        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-3 ui-md-1">
              <Button label="Search" id="searchButtonId" onClick={this.onLazyLoad} />
            </div>
            <div className="ui-g-3 ui-md-1">
              <Button label="Reset" onClick={this.resetFilter} />
            </div>
            <div className="ui-g-4 ui-md-2">
              <Button label="Export to XLSX" onClick={this.exportToXLSX} />
            </div>
          </div>
        </div>

        <DataTable
          value={this.state.stateData}
          responsive={true}
          sortMode="multiple"
          loading={this.state.loading}
          lazy={true}
          onLazyLoad={this.onLazyLoad}
          footerColumnGroup={footerGroup}
        >
          {tableColumns}
        </DataTable>

      </div>


    );
  }
}