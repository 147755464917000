import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const Affiliate = (state = initialState , action) => {
  switch(action.type){
  case constants.admin.AFFILIATE_NEW : return {...state , requests : action.payload};

  case constants.admin.AFFILIATE_GET: {
    return {...state, affiliate: action.payload};
  }

  case constants.admin.AFFILIATE_EDIT: {
    return {...state, affiliateEdit: action.payload};
  }

  default: return state;
  }
};

export default Affiliate;