import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import CampaignDataTable from '../campaign/dataTable';

class CampaignTypeDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list,
      ignoreArchived: this.props.ignoreArchived,
      campaignTypes: this.getCampaignTypes()
    };

  }

  getCampaignTypes = () => {
    let campaignTypes = [
      { label: 'On Site Referral', value: 'on_site_referral' }, 
      { label: 'Marketplace', value: 'marketplace' }
    ];

    if (this.props.campaignType) {
      //Filter by the value selected in the dropdown
      return campaignTypes.filter(campaignType => campaignType.value === this.props.campaignType);
    }

    // Filter by only those present at least one time in the campaign list 
    return campaignTypes.filter(campaignType => this.props.list.some(campaign => campaign.type === campaignType.value));    
  }

  rowExpansionTemplate = (data) => {
    return <CampaignDataTable list={this.state.list.filter(campaign => campaign.type === data.value)} ignoreArchived={this.state.ignoreArchived}/>;
  }

  render = () => {
    return <div className="campaign-datatable">
      <DataTable
        value={this.state.campaignTypes}
        responsive={true}
        tableClassName="no-header"
        ref={(el) => this.dt = el}
        onRowToggle={(e) => { this.setState({ expandedRows: e.data });}}
        rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
        expandedRows={this.state.expandedRows}
        rowClassName={(r) => ({'running-campaign': r.running})}
      >
        <Column expander={true} style={{ width: '45px' }} />
        <Column body={(rowData) => { return (<div> {rowData.label} </div>); }} />
      </DataTable> 
    </div>;
  }
}

export default CampaignTypeDataTable;