import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const EmailTemplate = (state = initialState , action) => {
  switch(action.type){
  
    case constants.admin.EMAIL_TEMPLATE_GET_ALL: {
      return {...state, emailTemplateGetAll: action.payload};
    }

    case constants.admin.EMAIL_TEMPLATE_GET_BY_ID: {
      return {...state, emailTemplateGetById: action.payload};
    }

    case constants.admin.EMAIL_TEMPLATE_EDIT: {
      return {...state, emailTemplateEdit: action.payload};
    }

    case constants.admin.EMAIL_TEMPLATE_COPY: {
      return {...state, emailTemplateCopy: action.payload};
    }

    case constants.admin.EMAIL_TEMPLATE_NEW: {
      return {...state, emailTemplateNew: action.payload};
    }

    default: return state;

  }
};

export default EmailTemplate;