import React from 'react';
import classname from 'classnames';
import config from '../../../config/config';
import userService from '../../../utils/services/user';

import { connect } from 'react-redux';
import * as clientGlobalMessageActions from '../../../actions/globalMessageActions';
import GlobalMessages from '../globalMessage/GlobalMessage';

@connect((store) => {
  return {
    baseUrl: config.URL.BASEFRONT,
    globalMessage : store.globalMessages.message
  };
})
class ForgotPassword extends React.Component {

  constructor(){
    super();
    this.state = {
      passwordRequestSent: false, 
      isCancelBtnHovered: false
    };

    this.goToPage = this.goToPage.bind(this);
  }

  componentWillMount(){
    this.setState({
      globalMessageActions: clientGlobalMessageActions
    });
  }

  resetPassword(e){
    e.nativeEvent.preventDefault();
    var that = this;
    userService.forgotPassword({
      email: this.email.value
    })
      .then(function(res){
        that.setState({
          passwordRequestSent: true
        });

        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'success',
            message: res.data.message,
            timeout: 10000
          }
        ));
      })
      .catch(function(){
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: 'Something went wrong, Please try again',
            timeout: 10000
          }
        ));
      });
  }

  goToPage(page){
    this.props.router.push({ pathname: page });
  }

  hoverOn = () => {
    this.setState({ isCancelBtnHovered: true });
  }

  hoverOff = () => {
    this.setState({ isCancelBtnHovered: false });
  }

  render () {
    const { isCancelBtnHovered } = this.state;
    
    return (
      <div className="front-page grid-container grid-parent">
        
        <div className="grid-100 front-page-mid-section grid-parent">
          <GlobalMessages message={this.props.globalMessage}/>

          <div className="grid-100 front-page-form-holder text-center">
          <div className="soreto__logo"></div>
            <div className="">
              {
                !this.state.passwordRequestSent ?
                  <div className="form-container">
                    <form onSubmit={(e) => this.resetPassword(e)}>
                      <h2>FORGOT PASSWORD</h2>
                      <p className="top-text form-container__title">Provide your email address to receive a link to reset your password</p>
                      <div className="front-page-input-holder">
                        <input required="true" ref={(input) => this.email = input} type="email" placeholder="E-mail"/>
                      </div>
                      <div>
                        <button 
                          className={classname('btn btn--reset', {
                            blurResetBtn: isCancelBtnHovered
                          })}
                        >RESET PASSWORD
                      </button>
                      </div>
                    </form>
                    <div className="forgot-password-bottom-section">
                      <button 
                        onClick={() => this.goToPage('/login')} 
                        className="btn btn--cancel"
                        onMouseEnter={this.hoverOn}
                        onMouseLeave={this.hoverOff}
                      >CANCEL
                      </button>
                    </div>
                  </div>
                  :
                  <div id="form-container" className="form-container">
                    <h2 className="form-container__header">FORGOT PASSWORD</h2>
                    <p className="top-text form-container__title margin-bottom-small">If a user account was found, an email has been sent with further instructions.</p>
                    <button onClick={() => this.goToPage('/login')} className="login--btn">LOG IN</button>
                  </div>
              }
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default ForgotPassword;