import emailTemplateTypeService from '../../utils/services/emailTemplateType';

import constants from '../../constants/app-constants';

export const getPage = (query, extendedQuery) => {
  return function(dispatch){
    emailTemplateTypeService.getPage(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_TYPE_GET_ALL,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_TYPE_GET_ALL,
          payload: err.response
        });
      });
  };
};

export const getById = (id) => {
  return function(dispatch){
    return new Promise(function(resolve) {
      emailTemplateTypeService.getById(id)
        .then(function(res){
          dispatch({
            type: constants.admin.EMAIL_TEMPLATE_TYPE_GET_BY_ID,
            payload: res.data
          });
          resolve(res.data);
        })
        .catch(function(err){
          dispatch({
            type: constants.admin.EMAIL_TEMPLATE_TYPE_GET_BY_ID,
            payload: err.response
          });
        });
    });
  };
};

export const update = (emailTemplate) => {
  return function(dispatch){
    emailTemplateTypeService.update(emailTemplate)
      .then(function(res){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_TYPE_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_TYPE_EDIT,
          payload: err.response
        });
      });
  };
};


export const create = (emailTemplate) => {
  return function(dispatch){
    return new Promise(function(resolve) {
      emailTemplateTypeService.create(emailTemplate)
        .then(function(res){
          dispatch({
            type: constants.admin.EMAIL_TEMPLATE_TYPE_NEW,
            payload: res.data
          });
          resolve(res.data);
        })
        .catch(function(err){
          dispatch({
            type: constants.admin.EMAIL_TEMPLATE_TYPE_NEW,
            payload: err.response
          });
        });
    });
  };
};