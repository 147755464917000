import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import * as transactionsActions from '../../../../actions/user/transactionsActions';
import * as withdrawalActions from '../../../../actions/user/withdrawalActions';
import {connect} from 'react-redux';
import WithdrawalList from '../../shared/withdrawal/WithdrawalList';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import userService from '../../../../utils/services/user';
import trackingService from '../../../../utils/services/tracking';
import BigNumber from 'bignumber.js';


@connect((store) => {
  return {
    loginCtx : store.loginCtx.loginCtx,
    transactions: store.userTransactions.transactions
  };
})
class Transactions extends React.Component{
  constructor(){
    super();
    this.state = {
      startDate: moment(),
      endDate: moment(),
      pageCount: 30,
      showFilters: false,
      currentSort: 'createdAt',
      direction: 'desc',
      searchTerm: '',
      currentPage: 1,
      nextPageAvailable: false,
      fetchingData: false
    };
  }

  handleChangeStart(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ startDate: date });
  }

  handleChangeEnd(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ endDate: date });
  }

  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  componentWillMount(){
    this.initialLoad();
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=transactionName`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount
    };

    userService.getTransactionsCount(that.props.loginCtx._id , {})
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getTransactions(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(transactionsActions.updateTransactions(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  search (e) {
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=transactionName`;

    var query = {
      $offset: 0,
    };

    var searchQuery = that.searchBox.value ? {$search: that.searchBox.value} : null;
    query = Object.assign(query ,searchQuery);
    that.setState({
      searchQuery: searchQuery,
      fetchingData: true,
      searchTerm: that.searchBox.value
    });


    if(!searchQuery){
      that.initialLoad();
      return;
    }

    trackingService.reportSearch(that.props.loginCtx , 'Transactions' , that.searchBox.value);

    userService.getTransactions(that.props.loginCtx._id , query , extendedQuery)
      .then(function(res){

        that.setState({
          nextPageAvailable: false,
          range: {
            from: '',
            to: ''
          },
          currentPage: 1,
          fetchingData: false
        });
        that.props.dispatch(transactionsActions.updateTransactions(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  clearSearch(){
    this.setState({
      searchQuery: null,
      searchTerm: ''
    });
    this.initialLoad();
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=transactionName`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount
    };

    if(that.state.dateQuery){
      Object.assign(query , that.state.dateQuery);
    }

    that.setState({
      fetchingData: true
    });

    userService.getTransactionsCount(that.props.loginCtx._id , that.state.dateQuery ? that.state.dateQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getTransactions(that.props.loginCtx._id , query ,extendedQuery)
          .then(function(res){
            var transactions = that.props.transactions.concat(res.data);
            that.props.dispatch(transactionsActions.updateTransactions(transactions));
            that.setState({
              nextPageAvailable: transactions.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDateRange(){
    var that = this;

    if (this.state.endDate.isBefore(this.state.startDate)) {
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'End date is before start date.',
          timeout: 10000
        }
      ));

      this.dateRangeHolder.classList.add('error');
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=transactionName`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = {
      $createdAt_$gt: this.state.startDate.format('YYYY-MM-DD'),
      $createdAt_$lt: this.state.endDate.add(1, 'days').format('YYYY-MM-DD')
    };

    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    trackingService.reportFilter(that.props.loginCtx, 'Transactions' , 'createdAt' , {from: that.state.startDate.format('DD/MM/YYYY') , to: this.state.endDate.format('DD/MM/YYYY') })

    userService.getTransactionsCount(that.props.loginCtx._id , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getTransactions(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(transactionsActions.updateTransactions(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDays(){
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=transactionName`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = this.daysFilter.value ? {$createdAt_$gt: moment().subtract(this.daysFilter.value, 'days').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true,
      startDate: moment().subtract(this.daysFilter.value, 'days'),
      endDate: moment()
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    trackingService.reportFilter(that.props.loginCtx, 'Transactions' , 'createdAt' , {from: moment().subtract(this.daysFilter.value, 'days').format('DD/MM/YYYY') , to: moment().format('DD/MM/YYYY')});

    userService.getTransactionsCount(that.props.loginCtx._id , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getTransactions(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(transactionsActions.updateTransactions(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  getSortDirectionImage(direction){
    return(
      direction == 'desc' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>
    );
  }

  sort(sortBy){
    var that = this;
    var direction = this.state.direction === 'desc' ? 'asc' : 'desc';


    this.setState({
      currentSort: sortBy,
      direction: direction,
      fetchingData: true
    });

    var sortString = that.state.direction === 'desc' ? sortBy : `-${sortBy}` ;
    var extendedQuery = `?$sort=${sortString}&$sort=transactionName`;

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.currentPage * that.state.pageCount,
    };

    if(that.state.dateQuery){
      query = Object.assign(query,that.state.dateQuery);
    }

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    trackingService.reportSort(that.props.loginCtx ,'Transactions', sortBy , direction);

    userService.getTransactions(that.props.loginCtx._id , query , extendedQuery)
      .then(function(res){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(transactionsActions.updateTransactions(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  openWithdrawalList(){
    this.props.dispatch(withdrawalActions.setWithdrawalListModalStatus(true));
  }

  render(){
    return(
      <div className="transactions-page reports-page">
        <WithdrawalList />
        <h4 className="mobile-alt-style section-heading hide-on-tablet hide-on-desktop">Transactions</h4>
        <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
          {
            this.state.showFilters ?
              <div>Hide Filters <i className="fa fa-minus"></i></div>
              :
              <div>Show Filters <i className="fa fa-plus"></i></div>
          }
        </div>
        <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
          <div className="reports-filters search-holder">
            <form onSubmit={(e) => this.search(e) } action="">
              <input placeholder="Search" ref={ (input) => this.searchBox = input } type="text" />
              <button className="primary">Search</button>
            </form>
            {
              this.state.searchTerm != '' ?
                <div className="toolbar-tab">
                  {this.state.searchTerm}
                  <i onClick={() => this.clearSearch() } className="fa fa-times"></i>
                </div>
                :
                false
            }
          </div>
          <div ref={(div) => this.dateRangeHolder = div} className="reports-filter-section">
            <div className="reports-filters select-holder">
              <div className="styled-select">
                <select ref={ (select) => this.daysFilter = select } onChange={() => this.filterByDays() } name="" id="">
                  <option value="">Date Range</option>
                  <option value="60">Last 60 days</option>
                  <option value="90">Last 90 days</option>
                  <option value="365">Last 12 months</option>
                </select>
              </div>
            </div>
            <div className="reports-filters date-picker-holder">
              <p>Date from</p>
              <DatePicker
                selected={this.state.startDate}
                selectsStart  startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(e) => this.handleChangeStart(e) } />
              <i className="fa fa-calendar-o"></i>
            </div>
            <div className="reports-filters date-picker-holder">
              <p>Date to</p>
              <DatePicker
                selected={this.state.endDate}
                selectsEnd  startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(e) => this.handleChangeEnd (e)} />
              <i className="fa fa-calendar-o"></i>
            </div>
            <div className="search-date-range-button-holder"><button onClick={() => this.filterByDateRange() } className="primary">Apply</button></div>
            <div className="reports-filters right">
              <button onClick={() => this.openWithdrawalList()} className="primary">Withdrawals</button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
        <h4 className="section-heading hide-on-mobile">Transactions</h4>
        {
          this.props.transactions && this.props.transactions.length  ?
          <div className="table-container">
            <table id="transactions-table" className="responsive report-table">
            <thead>
            <tr>
              <th className={this.state.currentSort === 'createdAt' ? 'current' : ''} width="20%"><a  onClick={() => this.sort('createdAt')}><i className="fa fa-calendar"></i> Date { this.state.currentSort === 'createdAt' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
              <th className={this.state.currentSort === 'transactionName' ? 'current' : ''} width="20%"><a onClick={() => this.sort('transactionName')}><i className="fa fa-credit-card"></i> Name { this.state.currentSort === 'transactionName' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
              <th className={this.state.currentSort === 'referenceType' ? 'current' : ''} width="20%"><a  onClick={() => this.sort('referenceType')}><i className="fa fa-clipboard"></i> Type { this.state.currentSort === 'referenceType' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
              <th className={this.state.currentSort === 'transactionId' ? 'current' : ''} width="20%"><a onClick={() => this.sort('transactionId')}><i className="fa fa-list-alt"></i> Reference  { this.state.currentSort === 'transactionId' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
              <th className={this.state.currentSort === 'amount' ? 'current' : ''} width="20%"><a onClick={() => this.sort('amount')}><i className="fa fa-money"></i> Amount { this.state.currentSort === 'amount' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
            </tr>
            </thead>
            <tbody>
            {
              _.map(this.props.transactions,function(transaction){
                return(
                  <tr key={transaction.transactionId}>
                    <td>{moment(transaction.createdAt).format('DD/MM/YY')}</td>
                    <td>{transaction.transactionName}</td>
                    <td>{transaction.referenceType}</td>
                    <td>{transaction.transactionId}</td>
                    <td className={transaction.type == 'DEBIT' ? 'debit' : 'credit'}>
                      <span className="negative-sign">-</span> £{new BigNumber(transaction.amount).toFixed(2)}
                    </td>
                  </tr>
                );
              })
            }
            </tbody>
            </table>
            {
              this.state.fetchingData ?
                <div className="fetching loading-gif text-center">
                  <img src="/images/loading-gif.gif"/>
                </div>
                :
                false
            }
            {
              this.state.nextPageAvailable ?
                <div className="text-center">
                  <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
                </div>
                :
                false
            }
          </div>

          :

            <div className="text-center">
              {
                this.props.transactions ?
                  <div className="no-data">
                    <p>There is currently no data</p>
                  </div>
                  :
                  <img src="/images/loading-gif.gif" style={{maxWidth: '400px'}} alt="Loading"/>
              }
            </div>
        }
      </div>
    );
  }
}

export default Transactions;