import React from 'react';
import _ from 'lodash';

class LiveFrameBeta extends React.Component {

  constructor(props) {
    super(props);
    this.previewHtml = React.createRef();

    this.state = {
      html: '',
      errors: {}
    };
  }

  updateFrameContent(nextProps) {
    const previewFrame = this.previewHtml.current;
    previewFrame.src = 'about:blank'; // reload iframe to clear javascript
    previewFrame.onload = function(){
      var preview =  previewFrame.contentDocument ||  previewFrame.contentWindow.document;
      preview.open();
      preview.write(nextProps.htmlContent);
      preview.close();
    };
  }

  updateFrameContentDebounced = _.debounce(this.updateFrameContent, 1000);

  componentWillReceiveProps(nextProps) {
    this.updateFrameContentDebounced(nextProps);
  }

  render() {
    return (
      <iframe frameBorder="0" style={{border: 'solid 1px', position: 'relative',  width: this.props.size.width, height: this.props.size.height,resize: 'both', direction: 'rtl', textAlign: 'left'}} ref={this.previewHtml}></iframe>
    );
  }
}

export default LiveFrameBeta;