import ClientList from './List';
import ClientCreate from './Create';
import ClientEdit from './Edit';
import ClientConfiguration from './Configuration';

const ClientIndex = {
  ClientList, 
  ClientCreate,
  ClientEdit,
  ClientConfiguration
};

export default ClientIndex;