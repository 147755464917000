import React from 'react';
import classnames from 'classnames';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import userSegmentationPoolService from '../../../utils/services/userSegmentationPool';

export default class UserSegmentationPoolDropbox extends React.Component {
  constructor(props) {
    super(props);

    this.userSegmentationPools = [];
    this.getUserSegmentationPool = this.getUserSegmentationPool.bind(this);

    this.state = {
      userSegmentationPool: this.props.value ? this.props.value : null,
      userSegmentationPoolAvailable: 'hidden',
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = () => {
    this.getUserSegmentationPool();
  };

  componentWillUpdate = prevProps => {
    if (prevProps.value != this.props.value) {
      if (typeof this.props.value === 'object') {
        this.setState({ userSegmentationPool: null });
      } else if (typeof this.props.value === 'string') {
        this.setState({ userSegmentationPool: prevProps.value });
      }
    }
  }

  getUserSegmentationPool() {
    userSegmentationPoolService
      .get()
      .then((result) => {
        if (result.data.resultData) {
          this.userSegmentationPools = result.data.resultData.page
            .map((displayBlock) => ({
              label: displayBlock.name,
              value: displayBlock._id,
            }));
          this.setState({ userSegmentationPoolAvailable: 'hidden' });
        } else {
          this.userSegmentationPools = null;
          this.setState({ userSegmentationPoolAvailable: 'visible' });
        }
        this.forceUpdate();
      });
  }

  displayBlockTemplate(option) {
    if (option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e) {
    this.setState({ userSegmentationPool: e.value });
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline,
    });

    return (
      <div className={cx}>
        <label>
          {this.props.label}{' '}
          <span
            style={{ visibility: !this.state.userSegmentationPoolAvailable }}
          >
            User Segmentation Pools
          </span>
        </label>
        <Dropdown
          value={this.state.userSegmentationPool}
          options={this.userSegmentationPools}
          onChange={this.onChange}
          itemTemplate={this.displayBlockTemplate}
          style={{ marginLeft: '5px', width: '100%' }}
          filter={true}
          filterPlaceholder="Select User Segmentation Pool"
          filterBy="label,value"
          placeholder="Select a User Segmentation Pool"
        />
      </div>
    );
  }
}
