import React from 'react';
import DatePicker from 'react-datepicker';
import * as clientActions from '../../../../../actions/client/clientActions';
import * as dailyTractionActions from '../../../../../actions/client/reports.dailyTractionActions';
import * as globalMessageActions from '../../../../../actions/globalMessageActions';
import {connect} from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import clientService from '../../../../../utils/services/client';
import BigNumber from 'bignumber.js';

var style = require('react-datepicker/dist/react-datepicker.css');

@connect((store)=>{
  return{
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    dailyTractionData: store.clientReportsDailyTraction.dailyTractionData,
    pageCount: store.clientReportsDailyTraction.pageCount,
    pageCountItems: 30
  };
})
class DailyTractionReport extends React.Component{

  constructor(){
    super();
    this.state = {
      currentSort: 'createdAt',
      direction: 'desc',
      searchTerm: '',
      showFilters: false,
      startDate: moment(),
      endDate: moment(),
      currentPage: 1,
      pageCount: 30
    };
  }

  componentWillMount(){
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
    this.initialLoad();
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=createdAt`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount
    };


    clientService.getProductTractionDataCount(that.props.loginCtx.clientId , {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getProductTractionData(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1
            });
            that.props.dispatch(dailyTractionActions.updateDailyTractionData(res.data));
          })
          .catch(function(err){
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  handleChangeStart(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ startDate: date });
  }

  handleChangeEnd(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ endDate: date });
  }

  filterByDateRange(){
    var that = this;

    if (this.state.endDate.isBefore(this.state.startDate)) {
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'End date is before start date.',
          timeout: 10000
        }
      ));

      this.dateRangeHolder.classList.add('error');
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=createdAt`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = {
      $createdAt_$gt: this.state.startDate.format('YYYY-MM-DD'),
      $createdAt_$lt: this.state.endDate.add(1, 'days').format('YYYY-MM-DD')
    };

    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    clientService.getProductTractionDataCount(that.props.loginCtx.clientId , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getProductTractionData(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1
            });
            that.props.dispatch(dailyTractionActions.updateDailyTractionData(res.data));
          })
          .catch(function(err){
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDays(){
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=createdAt`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = this.daysFilter.value ? {$createdAt_$gt: moment().subtract(this.daysFilter.value, 'days').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true,
      startDate: moment().subtract(this.daysFilter.value, 'days'),
      endDate: moment()
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    clientService.getProductTractionDataCount(that.props.loginCtx.clientId , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getProductTractionData(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1
            });
            that.props.dispatch(dailyTractionActions.updateDailyTractionData(res.data));
          })
          .catch(function(err){
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  getSortDirectionImage(direction){
    return(
      direction == 'desc' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>
    );
  }

  sort(sortBy){
    var that = this;
    var direction = this.state.direction === 'desc' ? 'asc' : 'desc';


    this.setState({
      currentSort: sortBy,
      direction: direction,
      fetchingData: true
    });

    var sortString = that.state.direction === 'desc' ? sortBy : `-${sortBy}` ;
    var extendedQuery = `?$sort=${sortString}&$sort=createdAt`;

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.currentPage * that.state.pageCount,
    };

    if(that.state.dateQuery){
      query = Object.assign(query,that.state.dateQuery);
    }

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    clientService.getProductTractionData(that.props.loginCtx.clientId , query , extendedQuery)
      .then(function(res){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(dailyTractionActions.updateDailyTractionData(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=createdAt`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount
    };

    if(that.state.dateQuery){
      Object.assign(query , that.state.dateQuery);
    }

    that.setState({
      fetchingData: true
    });

    clientService.getProductTractionDataCount(that.props.loginCtx.clientId , that.state.dateQuery ? that.state.dateQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getProductTractionData(that.props.loginCtx.clientId , query ,extendedQuery)
          .then(function(res){
            var products = that.props.dailyTractionData.concat(res.data);
            that.props.dispatch(dailyTractionActions.updateDailyTractionData(products));
            that.setState({
              nextPageAvailable: products.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }


  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  render(){

    return(
      <div className="daily-traction-report">
        <style>
          { style[0] }
        </style>
        {
          this.props.clientObj ?
            <div className="grid-100">
              <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
                {
                  this.state.showFilters ?
                    <div>Hide Filters <i className="fa fa-minus"></i></div>
                    :
                    <div>Show Filters <i className="fa fa-plus"></i></div>
                }
              </div>
              <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
                <div ref={(div) => this.dateRangeHolder = div} className="reports-filter-section">
                  <div className="reports-filters select-holder">
                    <div className="styled-select">
                      <select ref={ (select) => this.daysFilter = select } onChange={() => this.filterByDays() } name="" id="">
                        <option value="">All</option>
                        <option value="60">Last 60 days</option>
                        <option value="90">Last 90 days</option>
                        <option value="365">Last 12 months</option>
                      </select>
                    </div>
                  </div>
                  <div className="reports-filters date-picker-holder">
                    <p>From</p>
                    <DatePicker
                      selected={this.state.startDate}
                      selectsStart  startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={(e) => this.handleChangeStart(e) } />
                    <i className="fa fa-calendar-o"></i>
                  </div>
                  <div className="reports-filters date-picker-holder">
                    <p>To</p>
                    <DatePicker
                      selected={this.state.endDate}
                      selectsEnd  startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={(e) => this.handleChangeEnd (e)} />
                    <i className="fa fa-calendar-o"></i>
                  </div>
                  <div className="search-date-range-button-holder">
                    <button onClick={() => this.filterByDateRange()} className="primary">Apply</button>
                  </div>
                </div>
              </div>
              {
                this.props.dailyTractionData && this.props.dailyTractionData.length ?
                  <div className="table-container">
                    <table id="retailer-daily-traction" className="report-table responsive">
                      <thead>
                      <tr>
                        <th width={'20%'}  className={this.state.currentSort === 'createdAt' ? 'current' : ''}><a onClick={() => this.sort('createdAt')}><i className="fa fa-calendar"></i> Date { this.state.currentSort === 'createdAt' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                        <th width={'20%'}  className={this.state.currentSort === 'shares' ? 'current' : ''}><a onClick={() => this.sort('shares')}><i className="fa fa-share-square-o"></i> Shares { this.state.currentSort === 'shares' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                        <th width={'20%'} className={this.state.currentSort === 'clicks' ? 'current' : ''}><a onClick={() => this.sort('clicks')}><i className="fa fa-hand-pointer-o"></i> Clicks { this.state.currentSort === 'clicks' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                        <th width={'20%'} className={this.state.currentSort === 'cpc' ? 'current' : ''}><a title="Cost Per Click" onClick={() => this.sort('cpc')}><i className="fa fa-line-chart"></i> CPC { this.state.currentSort === 'cpc' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                        <th width={'20%'} className={this.state.currentSort === 'revenue' ? 'current' : ''}><a onClick={() => this.sort('revenue')}><i className="fa fa-money"></i> Revenue { this.state.currentSort === 'revenue' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        _.map(this.props.dailyTractionData, function (dailyTractionItem){
                          return(
                            <tr key={dailyTractionItem.createdAt}>
                              <td>{moment(dailyTractionItem.createdAt).format('DD/MM/YY')}</td>
                              <td>{dailyTractionItem.shares}</td>
                              <td>{dailyTractionItem.clicks}</td>
                              <td>£{new BigNumber(dailyTractionItem.cpc).toFixed(2)}</td>
                              <td>£{new BigNumber(dailyTractionItem.revenue).toFixed(2)}</td>
                            </tr>
                          );
                        })
                      }
                      </tbody>
                    </table>
                    {
                      this.state.nextPageAvailable ?
                        <div className="text-center">
                          <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
                        </div>
                        :
                        false
                    }
                  </div>
                  :
                  <div>
                    {
                      this.props.dailyTractionData ?
                        <div className="no-data">
                          <p>There is currently no data</p>
                        </div>
                        :
                        <div className="loading-gif text-center">
                          <img src="/images/loading-gif.gif"/>
                        </div>
                    }
                  </div>
              }
            </div>
          :
            false
        }
      </div>
    );
  }
}

export default DailyTractionReport;