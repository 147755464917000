import React from 'react';
import countryHelper from '../../../../../utils/helpers/country';
import _ from 'lodash';
import store from '../../../../../stores/store';
import * as settingsActions from '../../../../../actions/client/settingsActions';
import clientService from '../../../../../utils/services/client';
import * as clientActions from '../../../../../actions/client/clientActions';

class EditCountry extends React.Component{

  constructor(){
    super();
    this.state = {
      countryList: []
    };
  }

  searchCountryList(){

    if(this.countrySearch.value.length){
      this.setState({
        searchTerm: this.countrySearch.value
      })

      var searchTerm = this.countrySearch.value.toLowerCase();

      var filterCountry = (country) => {
        return country.toLowerCase().indexOf(searchTerm) === 0 && this.props.clientObj.location.indexOf(country) === -1;
      }

      this.setState({
        countryList: countryHelper.getCountryList().filter(filterCountry)
      });
    }else{
      this.setState({
        countryList: []
      });
    }


  }

  addCountry(country){
    var that = this;
    var locations = that.props.clientObj.location.slice(0);
    locations.push(country);
    clientService.updateClient(that.props.clientObj._id,{ location : locations})
      .then(function(res){
        store.dispatch(clientActions.updateClient(res.data));
        that.countrySearch.value = '';
        that.setState({
          countryList: []
        });
      });
  }

  removeCountry(country){
    var that = this;
    var locations = this.props.clientObj.location.slice(0);
    var index = locations.indexOf(country);
    locations.splice(index , 1);

    clientService.updateClient(that.props.clientObj._id,{ location : locations})
      .then(function(res){
        store.dispatch(clientActions.updateClient(res.data));
      });

  }

  closeModal(){
    store.dispatch(settingsActions.toggleEditLocationModal(false));
  }

  render(){
    var that = this;
    return(
      <div id="edit-client-locations">
        <h1>Edit Locations</h1>
        <ul className="current-client-country-list">
          {
            _.map(this.props.clientObj.location,function(country){
              return(
                <li key={country}>{ country } <i className="fa fa-times" onClick={() => that.removeCountry(country)}></i> </li>
              );
            })
          }
        </ul>
        <hr />
        <input className="search-for-country" ref={(input) => this.countrySearch = input} onChange={() => this.searchCountryList()} type="text" placeholder="Search for country"/>
          {
            this.state.countryList.length ?
              <div>
                <h4 className="active">
                  {this.state.countryList.length} Results
                </h4>
                <ul className="add-client-country-list">
                  {  _.map(_.slice(this.state.countryList , 0 , 5),function(country){
                    return(
                      <li key={country}>{ country } <i onClick={() => that.addCountry(country)} className="fa fa-plus"></i></li>
                    );
                  }) }
                </ul>
              </div>
            :
              false

          }
        <p>
          <button className="primary" onClick={() => this.closeModal() }>Finish</button>
        </p>
      </div>
    );
  }
}

export default EditCountry;