import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import{ InputText  } from 'primereact/components/inputtext/InputText';
import moment from 'moment';

import reportService from '../../../../../utils/services/reports';

class LiveRewardsList extends React.Component{
  constructor() {
    super();
    this.state = {
      liveClientList: [],
      searchField:''
    };
    this.onLazyLoad = this.onLazyLoad.bind(this);

  }

  getQuery(event) {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }
    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
    this.setState({
      liveRewardList: data.data,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});

    reportService.getLiveRewards(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });

  }

  statusIndicator(rowData) {
    const remaining = rowData.totalRemaining;
    if(remaining > 200){
      return <span className="fa fa-check-circle" style={{color: 'green'}}></span>;
    }
    if(remaining < 200 && remaining > 100){
      return <span className="fa fa-exclamation-circle" style={{color: 'yellow'}}></span>;
    }
    return <span className="fa fa-exclamation-circle" style={{color: 'red'}}></span>;
  }

  resetFilter = (event) => {
    this.setState({
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  columnFormatDate = (rowData, column) => {
    return moment(rowData[column.field]).format('YYYY-MM-DD');
  }

  columnFormatDaysLeft = (rowData, column) => {

    if(rowData['usedLast30Days'] == 0 && rowData['totalRemaining'] > 0){
      return '-';
    }else{
      return rowData[column.field];
    }
  }

  renderDataTable() {

    return (
      <DataTable
        value={this.state.liveRewardList}
        responsive={true}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        loading={this.state.loading}
        globalFilter={this.state.globalFilter} 
        first = {this.state.first}>

        <Column field="status" header="Status" body={this.statusIndicator} style={{width:'10px'}}/>
        <Column field="clientName" header="Client Name" style={{width:'40px'}}/>
        <Column field="rewardId" header="Reward Id" style={{width:'50px'}}/>
        <Column field="rewardName" header="Reward Name" style={{width:'65px'}}/>
        <Column field="totalRemaining" header="Total Remaining" style={{width:'20px'}}/>
        <Column field="usedLast30Days" header="Total Used Last 30 days" style={{width:'20px'}}/>
        <Column field="daysLeft" header="Days Left Forecast" body={this.columnFormatDaysLeft} style={{width:'20px'}}/>
        <Column field="expiryDate" header="Expiry Date" body={this.columnFormatDate} style={{width:'20px'}}/>
      </DataTable>
    );
  }
  render() {

    return (
      <div>
        <div className="content-section implementation">

          <h3>Live Rewards</h3>
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText 
                  onChange={(e) => this.setState({ searchField: e.target.value })} 
                  onKeyDown = {(e) => {if(e.keyCode === 13) this.onLazyLoad(e);}}
                  value={this.state.searchField}/>
              </span>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
            </div>
          </div>
        </div>
        {this.renderDataTable()}
      </div>
    );
  }

}

export default LiveRewardsList;