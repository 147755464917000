import constants from '../../constants/app-constants';

const initialState = {
  clientActivity: null
};

const ClientActivity = (state = initialState , action) => {

  switch(action.type){

  case constants.client.ACTIVITY_UPDATECLIENTACTIVITY : {
    return {...state , clientActivity: action.payload};
  }
  default: {
    return state;
  }
  }

};

export default ClientActivity;

