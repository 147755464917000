import React from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import moment from 'moment';

import codeBlockService from '../../../../utils/services/codeBlock';
import FormElement from '../../../shared/form/FormElement';

import config from '../../../../config/config';

export class CodeBlockList extends React.Component {

  constructor() {
    super();

    this.event = {
      filters: null,
      multiSortMeta: null,
      rows: 10,
      sortField: null,
      sortOrder: null
    };

    this.state = {
      codeBlockList: [],
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
      showArchived:false,
      first: 0
    };

    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.export = this.export.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onCreateCodeBlock = this.onCreateCodeBlock.bind(this);
    this.onEditCodeBlock = this.onEditCodeBlock.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.onPreviewCodeBlock = this.onPreviewCodeBlock.bind(this);
  }

  export() {
    this.dt.exportCSV();
  }

  componentDidMount() {
  }

  resetFilter(event) {
    this.setState({
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  getQuery(event) {

    var query = {
      $offset: event.first,
      $limit: event.rows,
      archived_$eq: this.state.showArchived,
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }

    var gteDate  = this.state.startDate ? {$createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$createdAt_$lte: moment(this.state.endDate).add(1,'d').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {

    for(let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');  
      row.active = row.active ? 'true': 'false';
    }

    this.setState({
      codeBlockList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});
    
    codeBlockService.getCodeBlockList(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });
    
  } 

  onCreateCodeBlock(){
    this.props.router.push({ pathname: '/admin/codeBlock/create'});
  }

  onEditCodeBlock(e,rowData){
    this.props.router.push({ pathname: '/admin/codeBlock/' + rowData._id });
  }
  onPreviewCodeBlock(e,rowData){
    window.open(`${config.API.BASEURL}/codeBlock/previewHtml/${rowData._id}`, 
      'newwindow', 
      'width=800,height=500');
  }

  actionTemplate(rowData, column) {
    return <div>
      <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={(e) => {this.onEditCodeBlock(e, rowData);}}> </Button>
      <Button type="button" icon="fa-search" className="ui-button-warning" onClick={(e) => {this.onPreviewCodeBlock(e, rowData);}}> </Button>
    </div>;
  }

  render() {
    var header = <div style={{textAlign:'left'}}><Button type="button" icon="fa-file-o" iconPos="left" label="CSV" onClick={this.export}></Button></div>;

    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };

    return (
      <div>
        <div className="content-section implementation">

          <h3>Placement Content</h3>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Archived:</span>
              </div>
              <div className="ui-g-10 ui-md-2 archivedCheckbox">
                <FormElement
                  name='archived'
                  type='checkbox'
                  onChange={(e) => this.setState({showArchived: e.target.checked})} 
                  checked={this.state.showArchived}
                  id='form-active'
                />
              </div>
            </div>
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Date range:</span>
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateFrom'
                  type='calendar'
                  onChange={(e) => this.setState({startDate: e.value})}
                  value={this.state.startDate}
                  id='dateFrom'
                  required
                  style={calendarStyle}
                />
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateTo'
                  type='calendar'
                  onChange={(e) => this.setState({endDate: e.value})}
                  value={this.state.endDate}
                  id='dateTo'
                  required
                  style={calendarStyle}
                />
              </div>
            </div>
          </div>
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField}/>
              </span>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />         
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />         
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Create Code Block" onClick={this.onCreateCodeBlock} />         
              </div>
            </div>
          </div>

          <DataTable value={this.state.codeBlockList} responsive={true} sortMode="multiple" paginator={true} rows={10} rowsPerPageOptions={[5,10,20,100,500,1000]} totalRecords={this.state.totalRecords}
            lazy={true} onLazyLoad={this.onLazyLoad} header={header}
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter} first = {this.state.first}>
            <Column field="countryName" header="Country" sortable={true} style={{width:'35px'}} />
            <Column field="clientName" header="Client" sortable={true} style={{width:'100px'}} />
            <Column field="campaignName" header="Campaign" sortable={true} style={{width:'100px'}} />
            <Column field="displayBlockName" header="Placement" sortable={true} style={{width:'100px'}} />
            <Column field="name" header="Name" sortable={true} style={{width:'100px'}} />
            <Column field="createdAt" header="Created" sortable={true} style={{width:'50px'}} />
            <Column field="updatedAt" header="Updated" sortable={true} style={{width:'50px'}} />
            <Column field="active" header="Active" sortable={true} style={{width:'40px'}} />
            <Column field="Archived" header="Archived" body={({archived})=>(!!(archived)).toString()} style={{width:'30px'}} />
            <Column body={this.actionTemplate} style={{textAlign:'center', width: '60px'}}/>
          </DataTable>
        </div>
      </div>
    );
  }
}

export default connect((store) => {
  return {
  };
})(CodeBlockList);