import React from 'react';
import config from '../../../../config/config';

class Footer extends React.Component {
  render () {
    return (
      <div className="grid-container page-footer-wrapper main-container">
        <footer className="footer">
          <div className="grid-container">
            <div className="grid-40 tablet-grid-100">
              <div className="grid-100 grid-parent top-footer-contacts">
                <span className="get-in-touch">get in touch</span>
                <span><a href="mailto:info@soreto.com">info@soreto.com</a></span>
              </div>
              <div className="clearfix"></div>
              {/* <div className="social-media-footer-holder left">
                <ul className="inline">
                  <li><a target="_blank" href={config.SOCIAL.FACEBOOK.SORETO_URL}><i className="fa fa-facebook"></i></a></li>
                  <li><a target="_blank" href={config.SOCIAL.TWITTER.SORETO_URL}><i className="fa fa-twitter"></i></a></li>
                  <li><a target="_blank" href={config.SOCIAL.GOOGLE.SORETO_URL}><i className="fa fa-google-plus"></i></a></li>
                  <li><a target="_blank" href={config.SOCIAL.INSTAGRAM.SORETO_URL}><i className="fa fa-instagram"></i></a></li>
                  <li><a target="_blank" href={config.SOCIAL.LINKEDIN.SORETO_URL}><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div> */}
            </div>
            <div className="grid-20 footer-copywrite-holder tablet-grid-100 text-center">
              <div>
                <ul className="inline">
                  <li>Copyright 2019</li>
                </ul>
              </div>
            </div>
            <div className="grid-40 tablet-grid-100">
              <div className="footer-menu-holder">
                <ul className="inline">
                  <li><a href='https://www.soreto.com/terms-and-conditions'>Terms &amp; Conditions</a></li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;