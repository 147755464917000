import moment from 'moment';
import _ from 'lodash';

class CommonFunctions {
  constructor() {
    this.defaultDateFormat = 'YYYY-MM-DD';

  }

  formatData = data => {
    if (data.page && Array.isArray(data.page)) {
      for (let row of data.page) {
        row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
        row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');
        row.expiry = moment(row.expiry).format('YYYY-MM-DD');
      }
    }
    return data;
  };

  sortData = (data, { value }) => {
    if (data.data && Array.isArray(data.data)) {
      return data.data.sort((a, b) => {
        const x = a[value].toLowerCase();
        const y = b[value].toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    }
  };

  formatDate = (date, format = null) =>{

    format = _.isNil(format) ? this.defaultDateFormat : format;

    return moment(date).format(format);
  }

  // In a list, remove field value repetition by ignoring its rows 
  suppressArraySequencialFieldValueRepetition = (input, field) => {

    let suppressedArray = [];

    input.forEach((item, index) => {

      if(index > 0){
        if(_.get(item, field)  ==  _.get(_.last(suppressedArray), field)){
          return;
        }
      }

      suppressedArray.push(item);
    });

    return suppressedArray;
  }
}

export default new CommonFunctions();
