import constants from '../../constants/app-constants';
import clientService from '../../utils/services/client';


export const getSharedUrls = (clientId, query) => {
  return function(dispatch){
    clientService.getSharedUrlsAccess(clientId, query).then((response) => {
      dispatch({
        type: constants.client.DASHBOARD_SHAREDURLS_GET,
        payload: response.data
      });
    });
  };
};

export const getSharedUrlsCountClicks = (clientId, query) => {
  return function(dispatch){
    clientService.getSharedUrlsCountClick(clientId, query).then((response) => {
      dispatch({
        type: constants.client.SHAREDURLS_COUNTCLICKS,
        payload: response.data
      });
    });
  };
};

export const getSharedUrlsCountShares = (clientId, query) => {
  return function(dispatch){
    clientService.getSharedUrlsCountShare(clientId, query).then((response) => {
      dispatch({
        type: constants.client.SHAREDURLS_COUNTSHARES,
        payload: response.data
      });
    });
  };
};

export const getEarningsPerSocialPlatform = (clientId, query) => {
  return function(dispatch){
    clientService.getEarningsPerSocialPlatform(clientId, query)
      .then(function(response){
        dispatch({
          type: constants.client.DASHBOARD_UPDATEEARNINGSPERPLATFORM,
          payload: response.data
        });
      });
  };
};