import React from 'react';

export default {
  sourceTagOptionsWhiteList: (that, sourceTags) => {
    if (!sourceTags) {
      return;
    }

    let removeTagItem = (item) => {
      sourceTags.splice(sourceTags.indexOf(item), 1);

      const { clientDetails } = that.state;

      clientDetails.urlWhitelist = sourceTags;

      that.setState({ clientDetails });
    };

    return sourceTags.map((t) => {
      return (
        <div
          style={{
            display: 'inline-block',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginLeft: '7px',
            paddingLeft: '3px',
            marginTop: '5px',
          }}
        >
          {t}
          <span
            onClick={() => removeTagItem(t)}
            style={{ cursor: 'pointer', paddingLeft: '5px' }}
          >
            X
          </span>
        </div>
      );
    });
  },

  sourceTagOptionsBlackList: (that, sourceTags) => {
    if (!sourceTags) {
      return;
    }

    let removeTagItem = (item) => {
      sourceTags.splice(sourceTags.indexOf(item), 1);

      const { clientDetails } = that.state;

      clientDetails.urlBlacklist = sourceTags;

      that.setState({ clientDetails });
    };

    return sourceTags.map((t) => {
      return (
        <div
          style={{
            display: 'inline-block',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginLeft: '7px',
            paddingLeft: '3px',
            marginTop: '5px',
          }}
        >
          {t}
          <span
            onClick={() => removeTagItem(t)}
            style={{ cursor: 'pointer', paddingLeft: '5px' }}
          >
            X
          </span>
        </div>
      );
    });
  },

  addRestrictionOptionWhiteList: (that, sourceRestriction) => {
    if (!sourceRestriction) {
      return;
    }
    const { clientDetails } = that.state;
    var { urlWhitelist } = clientDetails;

    if (!urlWhitelist) {
      urlWhitelist = [];
    }

    if (urlWhitelist.find((st) => st == sourceRestriction)) {
      return;
    }

    urlWhitelist.push(sourceRestriction);

    clientDetails.urlWhitelist = urlWhitelist;

    that.setState({ clientDetails, whiteListTag: '' });
  },

  addRestrictionOptionBlackList: (that, sourceRestriction) => {
    if (!sourceRestriction) {
      return;
    }
    const { clientDetails } = that.state;
    var { urlBlacklist } = clientDetails;

    if (!urlBlacklist) {
      urlBlacklist = [];
    }

    if (urlBlacklist.find((st) => st == sourceRestriction)) {
      return;
    }

    urlBlacklist.push(sourceRestriction);

    clientDetails.urlBlacklist = urlBlacklist;

    that.setState({ clientDetails, blackListTag: '' });
  },
};
