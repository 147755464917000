import React from 'react';
import * as activityActions from '../../../../actions/client/activityActions';
import * as clientActions from '../../../../actions/client/clientActions';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';
import clientService from '../../../../utils/services/client';
import json2csv from 'json2csv';
import {connect} from 'react-redux';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import Modal from 'react-modal';
import OrderChangeModal from './ChangeOrderModal';

var style = require('react-datepicker/dist/react-datepicker.css');

const customModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '370px',
    padding               : '7px'
  }
};

@connect((store) => {
  return{
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    clientActivity: store.clientActivity.clientActivity,
    showFilters: false
  };
})

class Activity extends React.Component{
  constructor () {
    super();
    this.state = {
      direction: 'desc',
      currentSort: 'createdAt',
      startDate: moment(),
      endDate: moment(),
      pageCount: 30,
      modalIsOpen: false,
      currentlyEditingOrder: null,
      currentPage: 1,
      nextPageAvailable: false,
      voidReasonText: '',
      voidReason:'Return',
    };
  }

  handleChangeStart(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ startDate: date });
  }

  handleChangeEnd(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ endDate: date });
  }

  searchActivities (e) {
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0,
    };

    var searchQuery = that.searchBox.value ? {$search: that.searchBox.value} : null;
    query = Object.assign(query ,searchQuery);
    that.setState({
      searchQuery: searchQuery,
      fetchingData: true,
      searchTerm: that.searchBox.value
    });


    if(!searchQuery){
      that.initialLoad();
      return;
    }

    clientService.getClientActivity(that.props.loginCtx.clientId , query , extendedQuery)
      .then(function(res){

        that.setState({
          nextPageAvailable: false,
          range: {
            from: '',
            to: ''
          },
          currentPage: 1,
          fetchingData: false
        });
        that.props.dispatch(activityActions.updateClientActivity(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  componentWillMount () {
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
    this.initialLoad();
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount
    };

    clientService.getClientActivityCount(that.props.loginCtx.clientId , {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getClientActivity(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(activityActions.updateClientActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDateRange(){
    var that = this;

    if (this.state.endDate.isBefore(this.state.startDate)) {
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'End date is before start date.',
          timeout: 10000
        }
      ));

      this.dateRangeHolder.classList.add('error');
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = {
      $createdAt_$gt: this.state.startDate.format('YYYY-MM-DD'),
      $createdAt_$lt: this.state.endDate.add(1, 'days').format('YYYY-MM-DD')
    };

    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    clientService.getClientActivityCount(that.props.loginCtx.clientId , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getClientActivity(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(activityActions.updateClientActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDays(){
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = this.daysFilter.value ? {$createdAt_$gt: moment().subtract(this.daysFilter.value, 'days').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true,
      startDate: moment().subtract(this.daysFilter.value, 'days'),
      endDate: moment()
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    clientService.getClientActivityCount(that.props.loginCtx.clientId , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getClientActivity(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(activityActions.updateClientActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  getSortDirectionImage(direction){
    return(
      direction == 'desc' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>
    );
  }

  sort(sortBy){
    var that = this;
    var direction = this.state.direction === 'desc' ? 'asc' : 'desc';


    this.setState({
      currentSort: sortBy,
      direction: direction,
      fetchingData: true
    });

    var sortString = that.state.direction === 'desc' ? sortBy : `-${sortBy}` ;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.currentPage * that.state.pageCount,
    };

    if(that.state.dateQuery){
      query = Object.assign(query,that.state.dateQuery);
    }

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    clientService.getClientActivity(that.props.loginCtx.clientId , query , extendedQuery)
      .then(function(res){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(activityActions.updateClientActivity(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount
    };

    if(that.state.dateQuery){
      Object.assign(query , that.state.dateQuery);
    }

    that.setState({
      fetchingData: true
    });

    clientService.getClientActivityCount(that.props.loginCtx.clientId , that.state.dateQuery ? that.state.dateQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getClientActivity(that.props.loginCtx.clientId , query ,extendedQuery)
          .then(function(res){
            var products = that.props.clientActivity.concat(res.data);
            that.props.dispatch(activityActions.updateClientActivity(products));
            that.setState({
              nextPageAvailable: products.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  setCurrentlyEditingOrder(e,order){
    e.stopPropagation();
    this.setState({
      currentlyEditingOrder: order
    });
  }

  updateOrderStatus(order , status , voidReasonText='',voidReason = this.state.voidReason){
    var that = this;

    var payload = {
      status: status,
      meta: {
        voidReason:{
          reasonType:voidReason,
          description:voidReasonText
        }
      }
    };
    clientService.updateOrder(this.props.clientObj._id, order._id, payload)
      .then(function(res){
        var clientActivity = Object.assign(that.props.clientActivity);

        //this gets the updated order status and assigns it to clientActivity
        _.forEach(clientActivity , function(order){
          if(order._id == res.data._id){
            order.meta = res.data.meta;
            order.status = res.data.status;
          }
        });

        //update client activity and close speech bubble
        that.props.dispatch(activityActions.updateClientActivity(clientActivity));
        that.closeSpeechBubble();
      });
  }

  handleVoidReasonText(e){
    this.setState({voidReasonText: e.target.value});
    if(this.state.voidReasonText.length > 500){
      this.setState({voidModalDialogue: 'reason must no exceed 500 characters'});
    }else{
      this.setState({voidModalDialogue: ''});
    }
  }

  handleVoidReason(e){
    this.setState({voidReason: e.target.value});
  }

  closeSpeechBubble(){
    this.setState({
      currentlyEditingOrder: null,
      voidModalDialogue:null
    });
  }

  openModal(e) {
    e.stopPropagation();
    if(this.state.currentlyEditingOrder.meta.voidReason){
      this.setState({voidReason:this.state.currentlyEditingOrder.meta.voidReason.reasonType});
      this.setState({voidReasonText:this.state.currentlyEditingOrder.meta.voidReason.description});
    }
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.closeSpeechBubble();
    this.setState({voidReasonText:''});
    this.setState({voidReason:'Return'});
    this.setState({modalIsOpen: false});
  }

  checkStatus(){
    return this.state.voidReason == 'PENDING' ? 'PENDING' : 'VOID';
  }

  downloadCSV(){

    var fields = ['productUrl','clientOrderId','status','calculatedTotal'];
    var fieldNames = ['item' , 'order id' , 'status' , 'total'];

    try {
      var data, filename, link;
      var csv = json2csv({ data: this.props.clientActivity , fields: fields , fieldNames: fieldNames });
      if (csv == null) return;

      filename = 'activity.csv';
      if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
      }
      data = encodeURI(csv);
      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err) {
      console.error(err);
    }
  }

  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  render() {
    var that = this;
    return(
      <div className="activity-page">
        <style>
          { style[0] }
        </style>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customModalStyles}
          contentLabel="Example Modal"
            >
          <OrderChangeModal
            updateOrderStatus={() => this.updateOrderStatus(this.state.currentlyEditingOrder,this.checkStatus(),this.state.voidReasonText,this.state.voidReason)}
            voidReasonText={this.state.voidReasonText}
            voidReason={this.state.voidReason}
            handleVoidReasonText={(e)=>this.handleVoidReasonText(e)}
            handleVoidReason={(e)=>this.handleVoidReason(e)}
            closeModal={() => this.closeModal()}
            order={this.state.currentlyEditingOrder}
            dialogue={this.state.voidModalDialogue}
            />
        </Modal>

        <div className="grid-100 reports-page">
          { this.props.clientObj ?
            <div>
              <h4 className="section-heading">Activity</h4>
              <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
                {
                  this.state.showFilters ?
                    <div>Hide Filters <i className="fa fa-minus"></i></div>
                    :
                    <div>Show Filters <i className="fa fa-plus"></i></div>
                }
              </div>
              <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
                <div className="reports-filters search-holder">
                  <form onSubmit={(e) => this.searchActivities(e) } action="">
                    <input placeholder="Search" ref={ (input) => this.searchBox = input } type="text" />
                    <button className="primary">Search</button>
                  </form>
                </div>
                <div ref={(div) => this.dateRangeHolder = div} className="reports-filter-section">
                  <div className="reports-filters select-holder">
                    <div className="styled-select">
                      <select ref={ (select) => this.daysFilter = select } onChange={() => this.filterByDays() } name="" id="">
                        <option value="">Date Range</option>
                        <option value="60">Last 60 days</option>
                        <option value="90">Last 90 days</option>
                        <option value="365">Last 12 months</option>
                      </select>
                    </div>
                  </div>
                  <div className="reports-filters date-picker-holder">
                    <p>Date from</p>
                    <DatePicker
                      selected={this.state.startDate}
                      selectsStart  startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={(e) => this.handleChangeStart(e) } />
                    <i className="fa fa-calendar-o"></i>
                  </div>
                  <div className="reports-filters date-picker-holder">
                    <p>Date to</p>
                    <DatePicker
                      selected={this.state.endDate}
                      selectsEnd  startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={(e) => this.handleChangeEnd (e)} />
                    <i className="fa fa-calendar-o"></i>
                  </div>
                  <div className="search-date-range-button-holder"><button onClick={() => this.filterByDateRange() } className="primary">Apply</button></div>
                  <div className="download-button-holder text-right">
                    { this.props.clientActivity && this.props.clientActivity.length ?
                      <button className="primary" onClick={ () => this.downloadCSV() }>Download CSV</button>
                      :
                      false
                    }
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
              </div>
              { this.props.clientActivity && this.props.clientActivity.length?
                <div className="table-container">
                  <table id="activity-table" className="report-table responsive" onClick={() => this.closeSpeechBubble()}>
                    <thead>
                    <tr>
                      <th width="15%" className={this.state.currentSort === 'createdAt' ? 'current' : ''}>
                        <a onClick={() => this.sort('createdAt')}><i className="fa fa-calendar-o"></i> Date { this.state.currentSort === 'createdAt' ? this.getSortDirectionImage(this.state.direction) : false }</a>
                      </th>
                      <th width="25%" className={this.state.currentSort === 'productUrl' ? 'current' : ''}>
                        <a  onClick={() => this.sort('productTitle')}><i className="fa fa-link"></i> Item { this.state.currentSort === 'productTitle' ? this.getSortDirectionImage(this.state.direction) : false }</a>
                      </th>
                      <th width="15%" className={this.state.currentSort === 'clientOrderId' ? 'current' : ''}>
                        <a  onClick={() => this.sort('clientOrderId')} ><i className="fa fa-barcode"></i> Order ID { this.state.currentSort === 'clientOrderId' ? this.getSortDirectionImage(this.state.direction) : false }</a>
                      </th>
                      <th width="15%" className={this.state.currentSort === 'currentSort' ? 'current' : ''}>
                        <a onClick={() => this.sort('status')} ><i className="fa fa-exchange"></i> Status { this.state.currentSort === 'status' ? this.getSortDirectionImage(this.state.direction) : false }</a>
                      </th>
                      <th width="15%" className={this.state.currentSort === 'calculatedTotal' ? 'current' : ''}>
                        <a onClick={() => this.sort('calculatedTotal')} ><i className="fa fa-money"></i> Value { this.state.currentSort === 'calculatedTotal' ? this.getSortDirectionImage(this.state.direction) : false }</a>
                      </th>
                      <th width="5%" className={''}>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      _.map(this.props.clientActivity, function(order){
                        return (
                          <tr key={order._id} className={that.state.currentlyEditingOrderId == order._id ? 'currently-editing' : ''}>
                            <td className="">
                              {
                                moment(order.createdAt).format('DD/MM/YY')
                              }
                            </td>
                            <td className="text-left">
                              <div className="table-image-holder" style={{backgroundImage : order.productImage ? `url(${order.productImage})`  : 'url(/images/table-holding-image.jpg)'}}>
                              </div>
                              <a title={order.productTitle} className="truncate" href={order.productUrl} target='_blank'>{order.productTitle}</a>
                            </td>
                            <td className="">{ order.clientOrderId }</td>
                            <td className="">{ order.status }</td>
                            <td className="">£{ order.calculatedTotal }</td>
                            <td className="">
                              {
                                // order.status === 'PAID' || order.status === 'CANCELLED' ?
                                //   <i className="bubble-opener fa fa-ellipsis-v"></i>
                                //   :
                                //   <i onClick={(e) => that.setCurrentlyEditingOrder(e,order)} className="bubble-opener active fa fa-ellipsis-v"></i>
                              }
                              {
                                that.state.currentlyEditingOrder && that.state.currentlyEditingOrder._id == order._id ?
                                  <div className="edit-status-container">
                                    <div className="edit-status-inner">
                                      <div className="bubble">
                                        <ul>
                                          {
                                            order.status === 'PENDING' ?
                                              <li onClick={(e) => that.openModal(e)}>Decline</li>
                                              :
                                              false
                                          }
                                          {
                                            order.status === 'VOID' ?
                                              <li onClick={(e) => that.openModal(e)}>STATUS</li>
                                              :
                                              false
                                          }
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  :

                                  false
                              }

                            </td>
                          </tr>
                        );
                      })
                    }
                    </tbody>
                  </table>
                  {
                    this.state.fetchingData ?
                      <div className="fetching loading-gif text-center">
                        <img src="/images/loading-gif.gif"/>
                      </div>
                      :
                      false
                  }
                </div>
                :
                <div className="text-center">
                  {
                    this.props.clientActivity ?
                      <div className="no-data">
                        <p>There is currently no data</p>
                      </div>
                      :
                      <div className="loading-gif text-center">
                        <img src="/images/loading-gif.gif"/>
                      </div>
                  }
                </div>
              }
            </div>
            :

            false
          }
          {
            this.state.nextPageAvailable ?
              <div className="text-center">
                <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
              </div>
              :
              false
          }
        </div>
      </div>
    );
  }
}

export default Activity;

