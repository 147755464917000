import request from '../wrappers/axios';
import config from '../../config/config';

class ReportsService {

  getLiveRewards(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/reports/liveRewards${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getLiveRewardsBasic(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/reports/liveRewardsBasic${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getLiveClients(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/reports/liveClients${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getLiveStats(query = '' , extendedQuery = '', responseType){
    return request({
      url: `${config.API.BASEURL}/reports/bi/stats${extendedQuery}`,
      method: 'get',
      params: query,
      responseType //responseType 'blob' is required to download xlsx
    });
  }

  getLiveIntegration(query = ''){
    return request({
      url: `${config.API.BASEURL}/reports/liveintegration`,
      method: 'get',
      params: query
    });
  }

  getCommissionsByMonth(query = '' , extendedQuery = '', responseType){
    return request({
      url: `${config.API.BASEURL}/reports/bi/commissionsByMonth${extendedQuery}`,
      method: 'get',
      params: query,
      responseType //responseType 'blob' is required to download xlsx
    });
  }

  getOriginDomains(query = ''){
    return request({
      url: `${config.API.BASEURL}/reports/auditOriginDomains`,
      method: 'get',
      params: query,
    });
  }
  
  getOriginUrls(query = ''){
    return request({
      url: `${config.API.BASEURL}/reports/auditOriginUrls`,
      method: 'get',
      params: query,
    });
  }
}

export default new ReportsService();
