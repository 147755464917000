import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';


class OrderChangeModal extends React.Component{

  submitAndClose(e){
    this.props.updateOrderStatus();
    this.setState({
	      editing: null,
	    });
    this.props.closeModal();
  }
  componentWillMount(){
    this.setState({
	      editing: null,
	    });
  }
  switchToEditing(){
    this.setState({editing:true});
    this.props.syncText;
  }

  render(){
    return (
			<span className="voidModal">
				<div onClick={this.props.closeModal} ><i className="fa fa-times" aria-hidden="true"></i></div>
				{this.props.order.status != 'VOID' || this.state.editing ?
				<div className="voidBody">
				<h3>Void Transaction</h3>
				<div className='orderID'>Order ID: <span>{this.props.order.clientOrderId}</span></div>
					<select value={this.props.voidReason} placeholder="Reason..." onChange={this.props.handleVoidReason}>
					  <option value="return">Return</option>
					  <option value="cancelled">Cancelled</option>
					  <option value="fraud">Fraud</option>
					  <option value="other">Other</option>
					  {this.props.order.meta.voidReason ? <option value="PENDING">Approve</option> : null} 
					</select>
					<textarea value={this.props.voidReasonText} onChange={this.props.handleVoidReasonText} >
					</textarea>
					<div>{this.props.dialogue}</div>
					<button disabled={this.props.dialogue} className="primary submit-btn" onClick={() =>this.submitAndClose()} >Save</button>
					<button className="secondary close-btn" onClick={this.props.closeModal}>Cancel</button>
				</div>
				: 
				<div className="voidBody">
					<h3>{this.props.order.clientOrderId}</h3>
					<h4>{this.props.order.meta.voidReason.reasonType}</h4>
					<p>{this.props.order.meta.voidReason.description ? this.props.order.meta.voidReason.description : 'No reason given'}</p>
					<button className="primary submit-btn" onClick={() => this.switchToEditing()} >Edit</button>
					<button className="secondary close-btn" onClick={this.props.closeModal}>Cancel</button>
				</div>
			}
			</span>
    );
  }
}

export default OrderChangeModal;
