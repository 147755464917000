import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

class ChartRevenue extends React.Component {

  render () {
    return (
    <div>
      <div className="text-center"><span className="big-titles">Revenue</span></div>
      <div className="revenue-chart-holder">
        <Doughnut data={this.props.data} options={this.props.options} />
        <div className="revenue-amount">
          £{
            _.reduce(this.props.data.datasets[0].data, function(sum, n) {
              return new BigNumber(sum).plus(new BigNumber(n)).toFixed(2);
            }, 0)
          }
        </div>
      </div>
    </div>
    );
  }
}

export default ChartRevenue;