import constants from '../../constants/app-constants';
import _ from 'lodash';

const initialState = {
  requests: null
};

const AssociateClientAffiliate = (state = initialState , action) => {
  switch(action.type) {
  
  case constants.admin.ASSOC_CLIENT_AFFILIATE_GET_ALL: {
    return { ...state, associateClientAffiliateGetAll: action.payload };
  }

  case constants.admin.ASSOC_CLIENT_AFFILIATE_DELETE: {
    return { ...state, associateClientAffiliateDelete: action.payload };
  }

  case constants.admin.ASSOC_CLIENT_AFFILIATE_GET_BY_ID: {
    return { ...state, associateClientAffiliateGetById: action.payload };
  }

  case constants.admin.ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT: {
    return { ...state, associateClientAffiliate: _.sortBy(action.payload, ['connectedAt']) };
  }

  case constants.admin.ASSOC_CLIENT_AFFILIATE_EDIT: {
    return { ...state, associateClientAffiliateEdit: action.payload };
  }

  case constants.admin.ASSOC_CLIENT_AFFILIATE_NEW: {
    return { ...state, associateClientAffiliateNew: action.payload };
  }

  

  default: return state;

  }
};

export default AssociateClientAffiliate;