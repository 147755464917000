import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import * as activityActions from '../../../../actions/user/activityActions';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import _ from 'lodash';
import userService from '../../../../utils/services/user';
import trackingService from '../../../../utils/services/tracking';
import BigNumber from 'bignumber.js';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    activity: store.userActivity.activity
  };
})
class Activity extends React.Component{

  constructor(){
    super();
    this.state = {
      startDate: moment(),
      endDate: moment(),
      showFilters: false,
      range: {
        from: '',
        to: ''
      },
      searchTerm: '',
      statusFilter: null,
      pageCount: 30,
      currentSort: 'createdAt',
      direction: 'desc',
      currentPage: 1,
      nextPageAvailable: false
    };
  }

  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  componentWillMount(){
    this.initialLoad();
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-createdAt`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount
    };

    userService.getUserActivityCount(that.props.loginCtx._id , {})
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getUserActivity(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(activityActions.updateUserActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterStatus(activity){
    if(this.state.statusFilter){
      return _.filter(activity , { 'status' : this.state.statusFilter});
    }

    return activity;
  }

  changeStatusFilter(){
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-createdAt`;

    var statusFilter = this.statusFilter.value ? {$status_$eq: this.statusFilter.value} : null;

    if(!statusFilter){
      that.setState({
        statusFilter: null
      });
      that.initialLoad();
    }

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    Object.assign(query,statusFilter);

    if(that.state.dateQuery){
      Object.assign(query , that.state.dateQuery);
    }

    trackingService.reportFilter(this.props.loginCtx,'Activity',this.statusFilter.value ,null);

    userService.getUserActivityCount(that.props.loginCtx._id , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getUserActivity(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false,
              statusFilter: statusFilter
            });
            that.props.dispatch(activityActions.updateUserActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=-createdAt`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount
    };

    var countQuery = {};

    if(that.state.dateQuery){
      Object.assign(query , that.state.dateQuery);
      Object.assign(countQuery , that.state.dateQuery);
    }

    if(that.state.statusFilter){
      Object.assign(query , that.state.statusFilter);
      Object.assign(countQuery , that.state.statusFilter);
    }

    that.setState({
      fetchingData: true
    });

    userService.getUserActivityCount(that.props.loginCtx._id , countQuery)
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getUserActivity(that.props.loginCtx._id , query ,extendedQuery)
          .then(function(res){
            var products = that.props.activity.concat(res.data);
            that.props.dispatch(activityActions.updateUserActivity(products));
            that.setState({
              nextPageAvailable: products.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  handleChangeStart(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ startDate: date });
  }

  handleChangeEnd(date) {
    this.dateRangeHolder.classList.remove('error');
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({ endDate: date });
  }

  filterByDateRange(){
    var that = this;

    if (this.state.endDate.isBefore(this.state.startDate)) {
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'End date is before start date.',
          timeout: 10000
        }
      ));

      this.dateRangeHolder.classList.add('error');
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-createdAt`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = {
      $createdAt_$gt: this.state.startDate.format('YYYY-MM-DD'),
      $createdAt_$lt: this.state.endDate.add(1, 'days').format('YYYY-MM-DD')
    };

    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    if(that.state.statusFilter){
      Object.assign(query , that.state.statusFilter);
    }

    trackingService.reportFilter(that.props.loginCtx, 'Activity' , 'createdAt' , {from: that.state.startDate.format('DD/MM/YYYY') , to: this.state.endDate.format('DD/MM/YYYY') })

    userService.getUserActivityCount(that.props.loginCtx._id , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getUserActivity(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(activityActions.updateUserActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  filterByDays(){
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-createdAt`;

    var query = {
      $offset: 0,
      $limit: that.state.pageCount
    };

    var dateQuery  = this.daysFilter.value ? {$createdAt_$gt: moment().subtract(this.daysFilter.value, 'days').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,dateQuery);
    that.setState({
      dateQuery: dateQuery,
      fetchingData: true,
      startDate: moment().subtract(this.daysFilter.value, 'days'),
      endDate: moment()
    });

    if(!dateQuery){
      that.initialLoad();
      return;
    }

    if(that.state.statusFilter){
      Object.assign(query , that.state.statusFilter);
    }

    trackingService.reportFilter(that.props.loginCtx, 'Activity' , 'createdAt' , {from: moment().subtract(this.daysFilter.value, 'days').format('DD/MM/YYYY') , to: moment().format('DD/MM/YYYY') });

    userService.getUserActivityCount(that.props.loginCtx._id , query)
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getUserActivity(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: that.state.pageCount < totalCount,
              currentPage: 1,
              fetchingData: false
            });
            that.props.dispatch(activityActions.updateUserActivity(res.data));
          })
          .catch(function(err){
            that.state({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  getSortDirectionImage(direction){
    return(
      direction == 'desc' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>
    );
  }

  sort(sortBy){
    var that = this;
    var direction = this.state.direction === 'desc' ? 'asc' : 'desc';


    this.setState({
      currentSort: sortBy,
      direction: direction,
      fetchingData: true
    });

    var sortString = that.state.direction === 'desc' ? sortBy : `-${sortBy}` ;
    var extendedQuery = `?$sort=${sortString}&$sort=-createdAt`;

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.currentPage * that.state.pageCount,
    };

    if(that.state.dateQuery){
      query = Object.assign(query,that.state.dateQuery);
    }

    if(that.state.statusFilter){
      query = Object.assign(query,that.state.statusFilter);
    }

    trackingService.reportSort(that.props.loginCtx ,'Activity', sortBy , direction);

    userService.getUserActivity(that.props.loginCtx._id , query , extendedQuery)
      .then(function(res){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(activityActions.updateUserActivity(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  render(){
    return(
      <div className="reports-page">
        <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
          {
            this.state.showFilters ?
              <div>Hide Filters <i className="fa fa-minus"></i></div>
              :
              <div>Show Filters <i className="fa fa-plus"></i></div>

          }
        </div>
        <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
          <div className="reports-filters status-filter">
            <div className="styled-select">
              <select onChange={() => this.changeStatusFilter()} ref={(select) => this.statusFilter = select} name="" id="">
                <option value="">Filter Status</option>
                <option value="PAID">Paid</option>
                <option value="PENDING">Pending</option>
                <option value="VOID">Void</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
            </div>
          </div>
          <div ref={(div) => this.dateRangeHolder = div} className="reports-filter-section right">
            <div className="reports-filters select-holder">
              <div className="styled-select">
                <select ref={ (select) => this.daysFilter = select } onChange={() => this.filterByDays() } name="" id="">
                  <option value="">Date Range</option>
                  <option value="60">Last 60 days</option>
                  <option value="90">Last 90 days</option>
                  <option value="365">Last 12 months</option>
                </select>
              </div>
            </div>
            <div className="reports-filters date-picker-holder">
              <p>Date from</p>
              <DatePicker
                selected={this.state.startDate}
                selectsStart  startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(e) => this.handleChangeStart(e) } />
              <i className="fa fa-calendar-o"></i>
            </div>
            <div className="reports-filters date-picker-holder">
              <p>Date to</p>
              <DatePicker
                selected={this.state.endDate}
                selectsEnd  startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(e) => this.handleChangeEnd (e)} />
              <i className="fa fa-calendar-o"></i>
            </div>
            <div className="search-date-range-button-holder"><button onClick={() => this.filterByDateRange() } className="primary">Apply</button></div>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
        <h4 className="section-heading hide-on-mobile">Activity</h4>
        {
          this.props.activity && this.props.activity.length ?
            <div className="table-container">
              <table id="user-activity" className="report-table responsive">
                <thead>
                <tr>
                  <th className={this.state.currentSort === 'createdAt' ? 'current' : ''} width="25%"><a  onClick={() => this.sort('createdAt')}>Date { this.state.currentSort === 'createdAt' ? this.getSortDirectionImage(this.state.direction) : false }<i></i></a></th>
                  <th className={this.state.currentSort === 'productTitle' ? 'current' : ''} width="25%"  onClick={() => this.sort('productTitle')}><a>Item { this.state.currentSort === 'productTitle' ? this.getSortDirectionImage(this.state.direction) : false }<i></i></a></th>
                  <th className={this.state.currentSort === 'status' ? 'current' : ''} width="25%"  onClick={() => this.sort('status')}><a>Status { this.state.currentSort === 'status' ? this.getSortDirectionImage(this.state.direction) : false }<i></i></a></th>
                  <th className={this.state.currentSort === 'commission' ? 'current' : ''} width="25%"><a  onClick={() => this.sort('commission')}>Value { this.state.currentSort === 'commission' ? this.getSortDirectionImage(this.state.direction) : false }<i></i></a></th>
                </tr>
                </thead>
                <tbody>
                {
                  _.map(this.props.activity,function(activity){
                    return(
                      <tr key={activity.orderId}>
                        <td><strong>{ moment(activity.createdAt).format('DD/MM/YY') }</strong></td>
                        <td className="text-left">
                          <div className="table-image-holder"  style={{backgroundImage : activity.productImage ? `url(${activity.productImage})`  : 'url(/images/table-holding-image.jpg)'}}>
                          </div>
                          <a title={activity.productTitle} className="truncate" href={activity.productUrl} target='_blank'>{activity.productTitle}</a>
                        </td>
                        <td className="capitalize">{ activity.status.toLowerCase() }</td>
                        <td>£{new BigNumber(activity.commission).toFixed(2)}</td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
              {
                this.state.nextPageAvailable ?
                  <div className="text-center">
                    <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
                  </div>
                  :
                  false
              }
            </div>

            :

            <div className="text-center">
              {
                this.props.activity ?
                  <div className="no-data">
                    <p>There is currently no data</p>
                  </div>
                  :
                  <img src="/images/loading-gif.gif" style={{maxWidth: '400px'}} alt="Loading"/>
              }
            </div>
        }
      </div>
    );
  }
}

export default Activity;