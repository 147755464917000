import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const keyEmailTemplateType = (state = initialState , action) => {

  switch(action.type){
  
  case constants.admin.KEY_EMAIL_TEMPLATE_TYPE_GET_BY_ID: {
    return {...state, keysEmailTemplateGetById: action.payload};
  }

  case constants.admin.KEY_EMAIL_TEMPLATE_TYPE_GET: {
    return {...state, keysEmailTemplateGetAll: action.payload};
  }

  case constants.admin.KEY_EMAIL_TEMPLATE_TYPE_UPDATE: {
    return {...state, keysEmailTemplateUpdate: action.payload};
  }

  case constants.admin.KEY_EMAIL_TEMPLATE_TYPE_NEW: {
    return {...state, new: action.payload};
  }

  default: return state;

  }
};

export default keyEmailTemplateType;