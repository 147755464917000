import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as codeBlockActions from '../../../../actions/admin/codeBlockActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';
import {Message} from 'primereact/components/message/Message';

import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import CampaignDropbox from '../../../shared/dropbox/CampaignDropbox';
import CampaignVersionDropbox from '../../../shared/dropbox/CampaignVersionDropbox';
import DisplayBlockDropbox from '../../../shared/dropbox/DisplayBlockDropbox';

import PreviewHtml from './codeBlockPreview';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    codeBlockObject: store.adminCodeBlock.requests
  };
})



class CodeBlockCreate extends React.Component {

  constructor(props) {
    super(props);
    this.previewHtml = React.createRef();
    const {
      codeBlockDetails = {
        displayBlockId:'',
        active:'',
        name:'',
        htmlBody: '',
        css: '',
        scss: '',
        javascript: '',
        cssExternal: '',
        javascriptExternal: ''
      }
    } = props;

    this.state = {
      cssPreProsessor: 'SCSS',
      sassError:{},
      codeBlockDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      client: null,
      campaign: null,
      campaignVersion: null,
      displayBlock: null
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onCampaignChange = this.onCampaignChange.bind(this);
    this.onCampaignVersionChange = this.onCampaignVersionChange.bind(this);
    this.onDisplayBlockChange = this.onDisplayBlockChange.bind(this);
  }

  preProcessCountDownStarted = false;
  preProcessCount = 2;

  onChange(name, {target}) {
    const { codeBlockDetails } = this.state;
    _.set( codeBlockDetails, name, target.value);
    this.setState({codeBlockDetails});
  }

  onProssessorChange(name, {target}) {
    this.setState({ cssPreProsessor: target.value });
  }

  preProcessCountdown(){
    var count = this.preProcessCount;
    const { codeBlockDetails } = this.state;
    let self = this;

    let httpTimer = setTimeout(function() {
      if(count > 0 ){
        self.preProcessCount = count - 1;
        clearTimeout(httpTimer);
        return self.preProcessCountdown();

      }else{
        self.props.dispatch(codeBlockActions.convertScss({
          sass:codeBlockDetails.scss
        })).then(({css,error})=>{
          const { codeBlockDetails } = self.state;
          self.preProcessCountDownStarted = false;
          if(error){
            return self.setState({ sassError:error});
          }
          _.set(codeBlockDetails, 'css', css);
          return self.setState({ sassError:{}, codeBlockDetails });

        }).catch(()=>{
          self.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Error: Unable to update CSS SASS_API Network error',
            timeout: 10000
          }));
          return self.preProcessCountDownStarted = false;
        });
      }
    }, 1000 );
  }

  onScssChange(name, {target}) {
    const { codeBlockDetails} = this.state;
    let preProcessCountDownStarted = this.preProcessCountDownStarted;
    _.set(codeBlockDetails, name, target.value);
    this.preProcessCount = 2;
    this.setState({ codeBlockDetails });
    if(!preProcessCountDownStarted){
      this.preProcessCountDownStarted = true;
      this.preProcessCountdown();
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.codeBlockObject !=nextProps.codeBlockObject){
      if(_.get(nextProps,'codeBlockObject.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.codeBlockObject.data.message,
          timeout: 10000
        }));
      }
    }


  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { codeBlockDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({codeErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      this.props.dispatch(codeBlockActions.newCodeBlock(codeBlockDetails))
        .then(created => browserHistory.push({ pathname: `/admin/codeBlock/${created._id}` , state: { fromCreatePage: true } }));
    }

  }

  validateForm() {
    const { codeBlockDetails, errors } = this.state;

    errors[ 'displayBlockId' ] = validator.isEmpty(codeBlockDetails.displayBlockId);
    errors[ 'active' ] = validator.isEmpty( codeBlockDetails.active );
    errors[ 'name' ] = validator.isEmpty( codeBlockDetails.name );

    return this.setState({ errors });
  }

  onClientChange(e){
    this.setState({client: e.value});
    const {codeBlockDetails} = this.state;
    _.set(codeBlockDetails, 'campaignId', '');
    _.set(codeBlockDetails, 'campaignVersionId', '');
    _.set(codeBlockDetails, 'displayBlockId', '');
    this.setState({codeBlockDetails});
  }

  onCampaignChange(e){
    this.setState({campaign: e.value});
    const {codeBlockDetails} = this.state;
    _.set(codeBlockDetails, 'campaignVersionId', '');
    _.set(codeBlockDetails, 'displayBlockId', '');
    this.setState({codeBlockDetails});
  }

  onCampaignVersionChange(e){
    this.setState({campaignVersion: e.value});
    const {codeBlockDetails} = this.state;
    _.set(codeBlockDetails, 'displayBlockId', '');
    this.setState({codeBlockDetails});
  }

  onDisplayBlockChange(e){
    this.setState({displayBlock: e.value});
    const {codeBlockDetails} = this.state;
    _.set(codeBlockDetails, 'displayBlockId', e.value);
    this.setState({codeBlockDetails});
  }

  render (){
    const { codeBlockDetails = { info: {} }, errors, sassError, cssPreProsessor  } = this.state;

    const {
      displayBlockId,
      active,
      name = '',
      htmlBody = '',
      css = '',
      scss,
      javascript = '',
      cssExternal = '',
      javascriptExternal = ''
    } = codeBlockDetails;

    return (
      <div className="codeBlock-new-codeBlock">
        <h3>Create placement content</h3>
        <form  className="c-form--flex">

          <ClientsDropbox
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId}
          />

          <CampaignDropbox
            required='true'
            client={this.state.client}
            onChange={this.onCampaignChange}
            error={errors.campaignId}
          />

          <CampaignVersionDropbox
            required='true'
            campaign={this.state.campaign}
            onChange={this.onCampaignVersionChange}
            error={errors.campaignVersionId}
          />

          <DisplayBlockDropbox
            required='true'
            campaignVersion={this.state.campaignVersion}
            onChange={this.onDisplayBlockChange}
            error={errors.displayBlockId}
          />


          <FormElement
            label='Display Block Id'
            name='displayBlockId'
            type='text'
            onChange={this.onChange.bind(this, 'displayBlockId')}
            value={displayBlockId}
            error={errors.displayBlockId}
            id='form-displayBlockId'
            required
          />

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Html body'
            name='htmlBody'
            type='codearea'
            mode='html'
            onChange={this.onChange.bind(this, 'htmlBody')}
            value={htmlBody}
            id='form-htmlBody'
          />

          <FormElement
            label='CSS Preprosessor'
            name='cssPreProsessor'
            type='select'
            onChange={this.onProssessorChange.bind(this, 'cssPreProsessor')}
            value={cssPreProsessor}
            error={errors.active}
            id='form-active'
            required
          >
            <option value="SCSS">scss</option>
            <option value="CSS">css</option>
          </FormElement>

          { cssPreProsessor == 'SCSS' &&
          <div>
            {
              sassError.line && <Message severity="error" text={'error: '+sassError.message+' line:'+sassError.line}></Message>
            }
            <FormElement
              label='SCSS body'
              name='scss'
              mode='sass'
              type='codearea'
              onChange={this.onScssChange.bind(this, 'scss')}
              value={scss}
              sassError={sassError}
            />
          </div>
          }

          { cssPreProsessor == 'CSS' &&

          <FormElement
            label='CSS body'
            name='css'
            mode='css'
            type='codearea'
            disabled
            value={css}
          />
          }

          <FormElement
            label='Javascript body'
            name='javascript'
            mode='javascript'
            type='codearea'
            onChange={this.onChange.bind(this, 'javascript')}
            value={javascript}
          />

          <FormElement
            label='head/meta body'
            name='cssExternal'
            className='head-meta'
            mode='html'
            type='codearea'
            onChange={this.onChange.bind(this, 'cssExternal')}
            value={cssExternal}
          />

          <FormElement
            label='Javascript External'
            name='javascriptExternal'
            type='text'
            onChange={this.onChange.bind(this, 'javascriptExternal')}
            value={javascriptExternal}
            error={errors.javascriptExternal}
            id='form-javascriptExternal'
          />

        </form>
        <br/>
        <PreviewHtml
          displayBlockId = {displayBlockId}
          campaignId={this.state.campaignId}
          cssExternal = {codeBlockDetails.cssExternal}
          htmlBody = {codeBlockDetails.htmlBody }
          css = {codeBlockDetails.css }
          jsExternal = {codeBlockDetails.jsExternal }
          javascript = {codeBlockDetails.javascript } >
        </PreviewHtml>
        <br/>
        <br/>
        <button onClick={()=>this.onSubmit()}>Create code block</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>

      </div>
    );
  }
}

export default CodeBlockCreate;