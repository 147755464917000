import _ from 'lodash';

import emailService from '../../../../../../utils/services/associatedEmailToCampaignVersion';
import emailTemplateService from '../../../../../../utils/services/emailTemplate';

const emailTypes = new Map([
  ['post_purchase_discount_reward_email', 'Post Purchase Discount'],
  ['post_purchase_friend_reward_email', 'Post Purchase Friend Reward'],
  ['share_with_friend_email', 'Share With Friend'],
  ['share_with_friend_email_reminder', 'Share With Friend Reminder'],
  ['reward_email', 'Reward'],
  ['shared_url_notification_personal_no_click', 'shared_url_notification_personal_no_click'],
  ['shared_url_notification_personal_no_order', 'shared_url_notification_personal_no_order'],
]);

const getAssociateEmail = campaignVersionId => emailService.getAllAssociateEmailToCampaignVersion({campaignVersionId_$: campaignVersionId})
  .then(response => response.data && response.data.page)
  .then(data => data.map(item => _.pick(item, ['_id', 'emailTemplateId', 'externalTemplateId', 'archived'])));

const getEmailTemplate = emailTemplateId => emailTemplateService.getEmailTemplateById(emailTemplateId)
  .then(response => response.data);

export const getData = campaignVersionId => getAssociateEmail(campaignVersionId)
  .then(assoc => {
    const promises = assoc && assoc.map(item => getEmailTemplate(item.emailTemplateId));

    return Promise.all(promises)
      .then(item => item && item.map(x => _.pick(x, ['_id', 'name', 'type', 'emailTemplateTypeId', 'externalTemplateId', 'archived'])))
      .then(item => item.map(i => ({...i, type: emailTypes.get(i.type) })));
  });