import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const DemoStore = (state = initialState , action) => {
  switch(action.type) {

    case constants.admin.DEMO_STORE_GET_BY_ID: {
      return { ...state, demoStoreGetById: action.payload };
    }

    case constants.admin.DEMO_STORE_EDIT: {
      return { ...state, demoStoreEdit: action.payload };
    }

    case constants.admin.DEMO_STORE_NEW: {
      return { ...state, demoStoreNew: action.payload };
    }

   default: return state;

  }
};

export default DemoStore;