import React from 'react';
import {Bar} from 'react-chartjs-2';

class ChartClick extends React.Component {
  render () {
    return (
    <div>
      <Bar data={this.props.data} options={this.props.options} />
    </div>
    );
  }
}

export default ChartClick;