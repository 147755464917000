import request from '../wrappers/axios';
import config from '../../config/config';

class RewardService {

  getRewardList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/reward/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  newReward (data) {
    return request({
      url: `${config.API.BASEURL}/reward`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateReward (data) {
    return request({
      url: `${config.API.BASEURL}/reward/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getReward (rewardId) {
    return request({
      url: `${config.API.BASEURL}/reward/${rewardId}`,
      method: 'get'
    });
  }

  getRewardsByCampaignVersion (campaignVersionId) {
    return request({
      url: `${config.API.BASEURL}/reward/campaignVersion/${campaignVersionId}`,
      method: 'get'
    });
  }

  getRewardByClient (id) {
    return request({
      url: `${config.API.BASEURL}/reward/client/${id}`,
      method: 'get'
    });
  }
}

export default new RewardService();