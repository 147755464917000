import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as rewardPoolActions from '../../../../actions/admin/rewardPoolActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import { connect } from 'react-redux';
import validator from 'validator';
import FormElement from '../../../shared/form/FormElement';
import RewardsDropbox from '../../../shared/dropbox/RewardsDropbox';
import {Panel} from 'primereact/components/panel/Panel';

export class RewardPoolEdit extends React.Component {

  constructor(props) {
    super(props);

    const rewardPool = {
      _id: '',
      name: '',
      clientId: '',
      advocatePreConversionRewardId:'',
      advocatePostConversionRewardId:'',
      friendPostRewardId: '',
      refereeRewardId: '',
      archived: false,
      limitPostReward: false,
      limitFriendPostReward: false,
      postRewardPerUser: '',
      friendPostRewardPerUser: '1000'
    };

    this.state = {
      rewardPool,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };
  }

  
  onChange(name, {target}) {
    const { rewardPool } = this.state;
    _.set( rewardPool, name, target.value);
    this.setState({rewardPool});
  }

  onChangeRewards(name, {value}) {
    const { rewardPool } = this.state;
    _.set( rewardPool, name, value);

    if(!value){
      const postRewardLimitSelect = name === 'advocatePostConversionRewardId' ? 'limitPostReward' : 'limitFriendPostReward';
      const postRewardLimitValue = name === 'advocatePostConversionRewardId' ? 'postRewardPerUser' : 'friendPostRewardPerUser';
      _.set( rewardPool, postRewardLimitSelect, false);
      _.set( rewardPool, postRewardLimitValue, false);
    }

    this.setState({rewardPool});
  }

  onChangeCheckbox(name, {target}) {
    const {rewardPool} = this.state;
    _.set(rewardPool, name, target.checked);
    this.setState({rewardPool});
  }

  onChangePostRewardLimit(name, {target}){
    const { rewardPool } = this.state;

    const value = target.value === 'true' ? true : false;
    _.set( rewardPool, name, value);

    if(!value){
      const valueField = name === 'limitPostReward' ? 'postRewardPerUser' : 'friendPostRewardPerUser';
      _.set( rewardPool, valueField, '');
    }
    
    this.setState({rewardPool});
  }

  componentDidMount() {
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New client reward pool added',
        timeout: 20000
      }));
    }
  }

  componentWillMount() {
    var rewardId = this.props.params.rewardId;
    if(rewardId){
      this.props.dispatch(rewardPoolActions.getRewardPool(rewardId));
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.rewardPool){
      this.setState({ rewardPool : nextProps.rewardPool});
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.rewardPoolEdit !=nextProps.rewardPoolEdit){
      if(_.get(nextProps,'rewardPoolEdit._id')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Reward saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'rewardPoolEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.rewardPoolEdit.data.message,
          timeout: 10000
        }));
      }
    }

  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { rewardPool, errors} = this.state;
    let checkErrors = false;

    rewardPool.postRewardPerUser = rewardPool.postRewardPerUser || null;
    rewardPool.friendPostRewardPerUser = rewardPool.friendPostRewardPerUser || null;

    this.validateForm();
    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});

        if (key == 'mustHaveOneReward'){
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Reward pool must have at least one reward',
            timeout: 10000
          }));
          break;
        }

        if (key == 'postRewardLimit'){
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Post Rewards Limit must be greater than 0.',
            timeout: 10000
          }));
          break;
        }

        if (key == 'friendPostRewardLimit'){
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Friend Post Rewards Limit must be greater than 0.',
            timeout: 10000
          }));
          break;
        }

        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){ this.props.dispatch(rewardPoolActions.updateRewardPool(rewardPool)); }
  }

  validateForm() {
    const { rewardPool, errors } = this.state;

    errors[ 'name' ] = validator.isEmpty(rewardPool.name.trim().toString());
    errors['mustHaveOneReward'] = (_.isEmpty(rewardPool.advocatePreConversionRewardId) &&
    _.isEmpty(rewardPool.advocatePostConversionRewardId) &&
    _.isEmpty(rewardPool.refereeRewardId));
    errors['postRewardLimit'] = rewardPool.limitPostReward && (!rewardPool.postRewardPerUser || parseInt(rewardPool.postRewardPerUser) <=0);
    errors['friendPostRewardLimit'] = rewardPool.limitFriendPostReward && (!rewardPool.friendPostRewardPerUser || parseInt(rewardPool.friendPostRewardPerUser) <=0);

    return this.setState({ errors });
  }

  renderRewardsDropbox() {

    const { rewardPool, errors } = this.state;
    const {
      clientId,
      advocatePreConversionRewardId,
      advocatePostConversionRewardId,
      friendPostRewardId,
      refereeRewardId,
      limitPostReward,
      limitFriendPostReward,
      friendPostRewardPerUser,
      postRewardPerUser,
    } = rewardPool;

    let renderRewards = null;

    if (clientId) {
      renderRewards = 
        (<React.Fragment>

          <br />
          <Panel header="Referrer (sharer)" style={{width: 600}}>
            <RewardsDropbox
              label='Referrer (sharer) pre-conversion reward'
              required='true'
              onChange={this.onChangeRewards.bind(this, 'advocatePreConversionRewardId')}
              name='advocatePreConversionRewardId'
              value={advocatePreConversionRewardId}
              client={clientId}
            />


            <RewardsDropbox
              label='Referrer (sharer) post-conversion reward'
              required='true'
              onChange={this.onChangeRewards.bind(this, 'advocatePostConversionRewardId')}
              name='advocatePreConversionRewardId'
              value={advocatePostConversionRewardId}
              client={clientId}
            />
            <FormElement
              label="Limit number of referrer (sharer) post-conversion rewards"
              name="limitPostReward"
              type="select"
              value={limitPostReward}
              onChange={this.onChangePostRewardLimit.bind(this, 'limitPostReward')}
              disabled= {!advocatePostConversionRewardId}
              id="form-limitPostReward"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </FormElement>

            <FormElement
              name='postRewardPerUser'
              type='number'validate
              onChange={this.onChange.bind(this, 'postRewardPerUser')}
              value={postRewardPerUser}
              error={errors.postRewardLimit}
              disabled={!limitPostReward}
              id='form-postRewardPerUser'
            />

          </Panel>

          <br />
          <Panel header="Referee (friend)" style={{width: 600}}>

            <RewardsDropbox
              label='Referee (friend) pre-conversion reward'
              required='true'
              onChange={this.onChangeRewards.bind(this, 'refereeRewardId')}
              name='advocatePreConversionRewardId'
              value={refereeRewardId}
              client={clientId}
            />
            <RewardsDropbox
              label='Referee (friend) post-conversion reward'
              required='true'
              onChange={this.onChangeRewards.bind(this, 'friendPostRewardId')}
              name='friendPostRewardId'
              value={friendPostRewardId}
              client={clientId}
            />

            <FormElement
              label="Limit number of referee (friend) post-conversion reward per user"
              name="limitFriendPostReward"
              type="select"
              value={limitFriendPostReward}
              onChange={this.onChangePostRewardLimit.bind(this, 'limitFriendPostReward')}
              disabled= {!friendPostRewardId}
              id="form-limitFriendPostReward"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </FormElement> 

            <FormElement
              name='friendPostRewardPerUser'
              type='number'validate
              onChange={this.onChange.bind(this, 'friendPostRewardPerUser')}
              value={friendPostRewardPerUser}
              error={errors.friendPostRewardLimit}
              disabled={!limitFriendPostReward}
              id='form-friendPostRewardPerUser'
            />         

          </Panel>
        </React.Fragment>);
    }

    return renderRewards;
  }

  render (){
    const { rewardPool = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      _id,
      clientId,
      name,
      archived,
    } = rewardPool;

    return (
      <div className="reward-new-reward">
        <h3>Edit Reward Pool</h3>
        <form className="c-form--flex">

          <FormElement
            label='Reward Pool Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Reward Pool Id'
            name='_id'
            type='text'
            onChange={this.onChange.bind(this, '_id')}
            value={_id}
            error={errors._id}
            id='form-clientId'
            disabled
          />

          <FormElement
            label='Client Id'
            name='client_id'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors._id}
            id='form-clientId'
            disabled
          />

          { this.renderRewardsDropbox() }

          <FormElement
            label='Archived'
            name='archived'
            type='checkbox'
            onChange={this.onChangeCheckbox.bind(this, 'archived') }
            checked={!!(archived)}
            error={errors.archived}
            id='form-active'
          />

        </form>

        <br />
        <button id="form-submit" onClick={()=>this.onSubmit()}>Edit</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/rewardPool')}>
          Back
        </button>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    rewardPool: store.adminRewardPool.rewardPool,
    rewardPoolEdit: store.adminRewardPool.rewardPoolEdit,
  };
})(RewardPoolEdit);