import BigNumber from 'bignumber.js';
import React from 'react';
import config from '../../../../config/config';
import Modal from 'react-modal';
import UpdateLogo from './Profile.ProfileImageUpdate';
import {connect} from 'react-redux';
import * as userActions from '../../../../actions/user/userActions';

const customModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    userWalletWithBalance: store.user.userWalletWithBalance
  };
})

class Profile extends React.Component{

  constructor(){
    super();
    this.state = {
      modalIsOpen: false
    };
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    });

  }

  userProfilePic(domain,imageID){

    var url =  imageID ? `${domain}/${imageID.substring(0,6)}/${imageID}` : `/images/holding-image.jpg`

    const divStyle = {
      width: '100%',
      height: '100%',
      backgroundSize:'cover',
      backgroundImage: 'url(' + url + ')',
      backgroundPosition: 'center'
    };

    return <div id="profile-pic" className="profile-pic" style={divStyle}></div>;
  }

  componentWillMount(){
    this.props.dispatch(userActions.getUser(this.props.loginCtx._id));
    this.props.dispatch(userActions.getUserWalletWithBalance(this.props.loginCtx._id));
  }

  render(){
    return(
      <div className='grid-parent profile'>
        <div className="profile-pic-holder grid-20">
          {this.userProfilePic(config.IMG.SRC,this.props.loginCtx.imageId)}
          <a onClick={(e) => this.toggleModal(e)} className="edit-image-button">
            <i className="fa fa-pencil"></i>
          </a>
        </div>
        <div className="grid-60 info-holder">
          <p>
            <span>Welcome</span><br />
            <span className="username">{this.props.loginCtx.firstName} {this.props.loginCtx.lastName}</span><br />
          </p>
          <p><span className="current-balance-title">Your Current Balance is</span><br />
            <span  className="current-balance">{this.props.userWalletWithBalance != null ? '£' + new BigNumber(this.props.userWalletWithBalance.displayBalance).toFixed(2) : false} </span>
          </p>
        </div>
        <div className="clearfix"></div>
        <Modal isOpen={this.state.modalIsOpen}
             contentLabel="profile-modal"
             shouldCloseOnOverlayClick={true}
             style={customModalStyle}
        >
          <UpdateLogo loginCtx={this.props.loginCtx} toggleModal={() => this.toggleModal()} />
        </Modal>
      </div>
    );
  }
}

export default Profile;