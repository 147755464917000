import AffiliateList from './List';
import AffiliateCreate from './Create';
import AffiliateEdit from './Edit';

const AffiliateIndex = {
  AffiliateList, 
  AffiliateCreate,
  AffiliateEdit
};

export default AffiliateIndex;