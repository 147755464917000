import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';

import * as userActions from '../../../../../actions/user/userActions';
import * as engagementActions from '../../../../../actions/user/engagementActions';
import * as globalMessageActions from '../../../../../actions/user/globalMessageActions';

import ChartClick from './SocialTractionReport.ChartClick';
import ChartRevenue from './SocialTractionReport.ChartRevenue';
import chartHelper from '../../../../../utils/helpers/chart';

import trackingService from '../../../../../utils/services/tracking';

import {connect} from 'react-redux';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    userObj: store.user.userObj,
    clicksCount: store.userEngagement.clicksCount,
    revenueCount: store.userEngagement.revenueCount
  };
})

class SocialTractionReport extends React.Component{
  constructor(){
    super();
    this.state = {
      range: {
        start: moment().subtract(1, 'years'),
        end: moment()
      },
      showFilters: false
    };
  }

  handleDateFilterChange(date, type) {
    this.dateRangeForm.classList.remove('error');
    this.setState({
      range: _.extend({}, this.state.range, {[type]: date, select: '0'})
    });
  }

  handleDateRangeChange(e) {
    this.dateRangeForm.classList.remove('error');
    var days = parseInt(e.target.value);
    this.setState({
      range: {
        select: days,
        start: moment().subtract(days, 'days'),
        end: moment()
      }
    });
  }

  triggerFilterRange (e) {
    e.preventDefault();

    if (this.state.range.end.isBefore(this.state.range.start)) {
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'End date is before start date.',
          timeout: 10000
        }
      ));

      this.dateRangeForm.classList.add('error');
      return;
    }

    this.props.dispatch(engagementActions.getSharedUrlsCountClick(this.props.loginCtx._id, {'$createdAt_$gt': this.state.range.start.format('YYYYMMDD'), '$createdAt_$lt': this.state.range.end.add(1, 'days').format('YYYYMMDD')}));
    this.props.dispatch(engagementActions.getSharedUrlsCountRevenue(this.props.loginCtx._id, {'$createdAt_$gt': this.state.range.start.format('YYYYMMDD'), '$createdAt_$lt': this.state.range.end.add(1, 'days').format('YYYYMMDD')}));
    trackingService.reportFilter(this.props.loginCtx,'Social Traction Report', 'createdAt', {from:  this.state.range.start.format('DD/MM/YYYY') , to: this.state.range.end.format('DD/MM/YYYY')});
  }

  componentWillMount () {
    this.props.dispatch(userActions.getUser(this.props.loginCtx._id));
    this.props.dispatch(engagementActions.getSharedUrlsCountClick(this.props.loginCtx._id));
    this.props.dispatch(engagementActions.getSharedUrlsCountRevenue(this.props.loginCtx._id));
  }

  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  render () {
    var chartClicksObj = chartHelper.getChartObjUserReportClicks(_.keys(this.props.clicksCount), _.values(this.props.clicksCount));
    var chartRevenueObj = chartHelper.getChartObjUserReportEarnings(_.keys(this.props.revenueCount), _.values(this.props.revenueCount));

    return (
      <div>
        {this.props.userObj && <div className="grid-100">
          <div className="trending-data-holder grid-parent grid-100">
            <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
              {
                this.state.showFilters ?
                <div>Hide Filters <i className="fa fa-minus"></i></div>
                :
                <div>Show Filters <i className="fa fa-plus"></i></div>

              }
            </div>
            <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
              <div className="report-range-finder report-social-traction">
                <form ref={(form) => this.dateRangeForm = form} action="">
                  <label className="report-range-label">Date Range</label>
                  <div className="reports-range-filter date-filter">
                    <div className="styled-select">
                      <select value={this.state.range.select} onChange={(e) => {this.handleDateRangeChange(e);}}>
                      <option value="0">Date Range</option>
                      <option value="7">Last 7 Days</option>
                      <option value="30">Last 30 Days</option>
                      <option value="60">Last 60 Days</option>
                      <option value="90">Last 90 Days</option>
                    </select>
                    </div>
                  </div>
                  <label className="report-range-label">From</label>
                  <div className="reports-range-filter date-filter">
                    <DatePicker
                      selected={this.state.range.start}
                      selectsStart  startDate={this.state.range.start}
                      endDate={this.state.range.end}
                      onChange={(e) => this.handleDateFilterChange(e, 'start') } />
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                  </div>
                  <label className="report-range-label">To</label>
                  <div className="reports-range-filter date-filter">
                    <DatePicker
                      selected={this.state.range.end}
                      selectsEnd  startDate={this.state.range.start}
                      endDate={this.state.range.end}
                      onChange={(e) => this.handleDateFilterChange(e, 'end') } />
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                  </div>
                  <button className="primary range-apply-button" onClick={(e) => this.triggerFilterRange(e)} type="submit">Apply</button>
                </form>
              </div>
            </div>
            <div className="chart-holder clearfix">
              <h1 className="chart-heading text-center">Clicks</h1>
              <div className="chart-wrapper left">
                <ChartClick data={chartClicksObj.data} options={chartClicksObj.options} />
              </div>
              <div className="legend-wrapper right">
                {_.map(chartClicksObj.data.labels, (label, index) => {
                  var data = chartClicksObj.data.datasets[0].data;
                  return <div className="legend-item-holder clearfix" key={index}><div className={`legend-colour-block legend-${label.toLowerCase()} left`}></div><span className="legend-value left">{data[index]}</span><span className="legend-platform left">{label}</span></div>;
                })}
              </div>
            </div>
            <div className="chart-holder clearfix">
              <h1 className="chart-heading text-center">Revenue</h1>
              <div className="chart-wrapper left">
                <ChartRevenue data={chartRevenueObj.data} options={chartRevenueObj.options} />
              </div>
              <div className="legend-wrapper right">
                {_.map(chartRevenueObj.data.labels, (label, index) => {
                  var data = chartRevenueObj.data.datasets[0].data;
                  return <div className="legend-item-holder clearfix" key={index}><div className={`legend-colour-block legend-${label.toLowerCase()} left`}></div><span className="legend-value left">£{parseFloat(data[index]).toFixed(2)}</span><span className="legend-platform left">{label}</span></div>;
                })}
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default SocialTractionReport;
