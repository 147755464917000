import React from 'react';
import _ from 'lodash';
import clientService from '../../../../utils/services/client';
import * as clientActions from '../../../../actions/admin/clientActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import imageUploadHelper from '../../../../utils/helpers/imageUpload';
import config from '../../../../config/config';

import { connect } from 'react-redux';
import ClientLogo from './ClientLogo';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    clients:store.adminDashboard.clients
  };
})

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateDefaultCommission(item,commission){

    var that = this;
    //removed so That we can add text to the shown commision
    // if (isNaN(parseInt(commission)) || commission < 0 || commission > 100) {
    //   that.props.dispatch(globalMessageActions.showMessage(
    //   {
    //     type: 'error',
    //     message: 'Percentage commission must be between 0 and 100.',
    //     timeout: 10000
    //   }
    //   ));
    //   return;
    // }

    var payload = {
      percentCommission: {
        default: commission,
        categories: 'default'
      }
    }

    //update client
    clientService.updateClient(item._id , payload)
    .then(function(res){
      that.props.dispatch(clientActions.updateClient(res.data));
      //clear currently editing
    })
    .catch(function(err){
      that.props.dispatch(that.state.globalMessageActions.showMessage(
      {
        type: 'error',
        message: err.response.data.message,
        timeout: 10000
      }
      ));
    });
  }

  componentWillMount(){
    this.props.dispatch(clientActions.getClients());
  };

  disableClient(id){
    this.props.dispatch(clientActions.updateClientStatus(id,{"disabled":"true"}));
  }

  enableClient(id){
    this.props.dispatch(clientActions.updateClientStatus(id,{"disabled":"false"}));
  }

  updateClientRank(item,rank){
    this.props.dispatch(clientActions.updateClientRank(item._id,{'rank':rank}));
  }

  updateClientReferer(item,value){
    var that = this;
    clientService.updateClient(item._id , {referer:value})
    .then(function(res){
      that.props.dispatch(clientActions.updateClient(res.data));
    })
    .catch(function(err){
      that.props.dispatch(that.state.globalMessageActions.showMessage(
      {
        type: 'error',
        message: err.response.data.message,
        timeout: 10000
      }
      ));
    });
  }

  updateAssocMeta(item,value){
    this.props.dispatch(clientActions.updateClientMerchantAssoc(item._id,{"meta":value}));
  }

  handleImageChange(fileInput,item){
    var that = this;
    const fileItem = document.getElementById('file'+item._id);
    const filePreview = document.getElementById('file-preview'+item._id);
    let fileIsValid = true;

    filePreview.value = '';
    that.setState({
      uploadError: null
    });

    //check image is valid size
    if(!imageUploadHelper.imageSizeIsValid(fileItem.files[0].size,config.IMG.UPLOAD_LIMIT)){
      that.setState({
        uploadError: 'Maximum upload limit is 2MB'
      });
      document.getElementById('file'+item._id).value = '';
      return;
    }

    //check image is square
    if (fileInput.files[0]) {
      var _URL = window.URL || window.webkitURL;
      var image = new Image();
      image.onload = function() {
        if ((this.width / this.height) !== 1 || this.width < 150) {
          fileInput.val = '';
          fileIsValid = false;
          that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: 'Image must be square and have dimensions of at least 150x150.',
            timeout: 10000
          }
          ));
          fileItem.value = '';
          filePreview.value = '';
          return;
        }
      };
      image.src = _URL.createObjectURL(fileInput.files[0]);
    }

    //check image is valid format and then load preview
    if(imageUploadHelper.imageIsValid(fileInput) && fileIsValid){
      filePreview.src = window.URL.createObjectURL(fileItem.files[0]);
      filePreview.value = fileItem.files[0].name;
    }else{
      fileItem.value = '';
      that.props.dispatch(globalMessageActions.showMessage(
      {
        type: 'error',
        message: 'Unsupported image format',
        timeout: 10000
      }
      ));
      that.setState({
        uploadError: 'Unsupported image format'
      });
      return;
    }
  }


  handleImageSubmit(e,fileInput,item) {
    e.preventDefault();

    var that = this;

    if(!fileInput.files.length){
      that.props.dispatch(globalMessageActions.showMessage(
      {
        type: 'error',
        message: 'Please upload an image first',
        timeout: 10000
      }
      ));
      document.getElementById('file'+item._id).value = '';
      return;
    }

    if(imageUploadHelper.imageIsValid(fileInput)){
      var data = new FormData();
      data.append('file', document.getElementById('file'+item._id).files[0]);
      clientService.uploadImage(data)
      .then(function(res){
        clientService.updateClient(item._id,{ imageId: res.data.fileId})
        .then(function(){
          that.props.dispatch(clientActions.getClients());
        })
        .catch(function(err){
          that.setState({
            uploadError: err.response.data.message
          });
        });
      })
      .catch(function(err){
        that.setState({
          uploadError: err.response.data.message
        });
      });
    }else{
      alert('unsupported format');
    }
  }


  render (){
    return (
      <div className="table-responsive">
        <table id="product-traction-report">
          <tbody>
            {_.sortBy(this.props.clients,['name']).map((item, i) =>
              <tr className="client" key={i}>
                <td>
                  <ClientLogo
                    handleSubmit={(e,file,itm)=>this.handleImageSubmit(e,file,itm)}
                    handleChange={(file,itm)=>this.handleImageChange(file,itm)}
                    item={item} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {
          this.state.fetchingData ?
            <div className="fetching loading-gif text-center">
              <img src="/images/loading-gif.gif"/>
            </div>
            :
            false
        }
      </div>
    );
  }
}

export default Dashboard;