import request from '../wrappers/axios';
import config from '../../config/config';

class RoleService {
  getRole (roleId) {
    return request({
      url: config.API.BASEURL + '/roles/'+ roleId,
      method: 'get'
    });
  }

  getAllRoles() {
    return request({
      url: config.API.BASEURL + '/roles',
      method: 'get'
    });
  }
}

export default new RoleService();