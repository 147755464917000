import request from '../wrappers/axios';
import config from '../../config/config';

class EmailTemplateService {

  getPage(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/emailTemplateType/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getById(id){
    return request({
      url: `${config.API.BASEURL}/emailTemplateType/${id}`,
      method: 'get'
    });
  }

  update(emailTemplateType){
    return request({
      url: `${config.API.BASEURL}/emailTemplateType`,
      method: 'patch',
      data: emailTemplateType
    });
  }

  create(emailTemplateType){
    return request({
      url: `${config.API.BASEURL}/emailTemplateType`,
      method: 'post',
      data: emailTemplateType
    });
  }
}

export default new EmailTemplateService();