import config from '../../config/config';
import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';
import moment from 'moment';
import _ from 'lodash';

export const setUserSocialPlatforms = (userId) => {
  return (dispatch) => {
    userService.getUserSocialAuths(userId)
      .then((res) => {
        var platforms = _.mapValues(_.omitBy(config.SOCIAL, function (obj) {return !obj.API_SUPPORT;}), (obj, key) =>{
          var authObj = _.find(res.data, ['socialPlatform', key]);
          var enabled = authObj && authObj._id ? true : false;
          var expired = authObj && moment(authObj.expires).diff(moment()) < 0 ? true : false;

          return _.merge({}, obj, {
            STATUS: enabled,
            EXPIRED: expired,
            ENABLEDFORUSER: expired ? false : enabled
          });
        });

        dispatch({
          type: constants.user.SOCIALPOST_SETUSERSOCIALPLATFORMS,
          payload: platforms
        });
      });
  };
};

export const updateCurrentlyPostingStatus = (currentlyPostingStatus) => {
  return{
    type: constants.user.SOCIALPOST_UPDATECURRENTLYPOSTINGSTATUS,
    payload: currentlyPostingStatus
  };
};

export const updateUserSocialPlatforms = (data) => {
  return (dispatch) => {
    _.forEach(data.currentPlatforms , (platform , key) => {
      if(key ==  data.platform){

        data.currentPlatforms[key].STATUS = !data.currentPlatforms[key].STATUS;

        dispatch({
          type: constants.user.SOCIALPOST_SETUSERSOCIALPLATFORMS,
          payload: data.currentPlatforms
        });

      }
    });
  };
};

export const clearUserSocialPlatforms = () => {
  return {
    type: constants.user.SOCIALPOST_SETUSERSOCIALPLATFORMS,
    payload: {}
  };
};

export const setConnectModalStatus = (modalStatus) => {
  return {
    type: constants.user.SOCIALPOST_SETCONNECTMODALSTATUS,
    payload: modalStatus
  };
};