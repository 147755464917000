import DemoStoreList from './List';
import DemoStoreCreate from './Create';
import DemoStoreEdit from './Edit';

const DemoStoreIndex = {
  DemoStoreList, 
  DemoStoreCreate,
  DemoStoreEdit
};

export default DemoStoreIndex;