import constants from '../../constants/app-constants';

const initialState = {
  modalIsOpen: false
};

const UserProfile = (state = initialState , action) => {

  switch(action.type){
  case constants.user.PROFILE_TOGGLE_MODAL : {
    return {...state , modalIsOpen: action.payload };
  }

  default: {
    return state;
  }
  }
};

export default UserProfile;