import ClientListStats from './pages/clientListStats/ClientListStats';
import Dashboard from './pages/dashboard/Dashboard';
import Layout from './layout/Layout';
import Index from './Index';
import triggerGATrack from '../shared/ga/googleAnalytics';
import WithdrawalRequests from './pages/withdrawalRequests/WithdrawalRequests';

import React from 'react';
import { Route, IndexRedirect, withRouter } from 'react-router';

const adminRoutes = (
  <Route path="/admin" component={withRouter(Layout)} onChange={triggerGATrack}>
    <IndexRedirect to={'/admin/reports/liveStats'} />
    <Route path="withdrawalrequests" component={WithdrawalRequests} />
    <Route path="dashboard" component={Dashboard} />
    <Route path="client" component={Index.ClientIndex.ClientList} />
    <Route path="client/create" component={Index.ClientIndex.ClientCreate} />
    <Route path="client/:clientId" component={Index.ClientIndex.ClientEdit} />
    <Route path="client/:clientId/configuration" component={Index.ClientIndex.ClientConfiguration} />
    <Route path="client/countryCode/:clientId" component={Index.CountryCodeIndex.Dashboard} />
    <Route path="countryCode" component={Index.CountryCodeIndex.Dashboard} />
    <Route path="clientstats" component={ClientListStats} />
    <Route path="affiliate" component={Index.AffiliateIndex.AffiliateList} />
    <Route path="affiliate/create" component={Index.AffiliateIndex.AffiliateCreate} />
    <Route path="affiliate/:affiliateId" component={Index.AffiliateIndex.AffiliateEdit} />
    <Route path="campaign" component={Index.CampaignIndex.CampaignList} />
    <Route path="campaign/create" component={Index.CampaignIndex.CampaignCreate} />
    <Route path="campaign/:campaignId" component={Index.CampaignIndex.CampaignEdit} />
    <Route path="campaignVersion" component={Index.CampaignVersionIndex.CampaignVersionList} />
    <Route path="campaignVersion/create" component={Index.CampaignVersionIndex.CampaignVersionCreate} />
    <Route path="campaignVersion/:campaignVersionId" component={Index.CampaignVersionIndex.CampaignVersionEdit} />
    <Route path="codeBlock" component={Index.CodeBlockIndex.CodeBlockList} />
    <Route path="codeBlock/create" component={Index.CodeBlockIndex.CodeBlockCreate} />

    <Route path="codeBlock/:codeBlockId" component={Index.CodeBlockIndex.CodeBlockEdit} />

    <Route path="codeBlockBeta/:codeBlockId" component={Index.CodeBlockIndex.CodeBlockEditBeta} />

    <Route path="demoStore" component={Index.DemoStoreIndex.DemoStoreList} />
    <Route path="demoStore/create" component={Index.DemoStoreIndex.DemoStoreCreate} />
    <Route path="demoStore/:demoStoreId" component={Index.DemoStoreIndex.DemoStoreEdit} />
    <Route path="displayBlock" component={Index.DisplayBlockIndex.DisplayBlockList} />
    <Route path="displayBlock/create" component={Index.DisplayBlockIndex.DisplayBlockCreate} />
    <Route path="displayBlock/:displayBlockId" component={Index.DisplayBlockIndex.DisplayBlockEdit} />
    <Route path="emailTemplate" component={Index.EmailTemplateIndex.EmailTemplateList} />
    <Route path="emailTemplate/create" component={Index.EmailTemplateIndex.EmailTemplateCreate} />
    <Route path="emailTemplateType/create" component={Index.EmailTemplateTypeIndex.Create} />
    <Route path="emailTemplateType" component={Index.EmailTemplateTypeIndex.List} />
    <Route path="emailTemplateType/:emailTemplateId" component={Index.EmailTemplateTypeIndex.Edit} />
    <Route path="emailTemplate/:emailTemplateId" component={Index.EmailTemplateIndex.EmailTemplateEdit} />
    <Route path="emailTemplate/:emailTemplateId/copy" component={Index.EmailTemplateIndex.EmailTemplateCopy} />
    <Route path="associateEmailToCampaignVersion" component={Index.AssociateEmailToCampaignVersionIndex.AssociateEmailToCampaignVersionList} />
    <Route path="associateEmailToCampaignVersion/create" component={Index.AssociateEmailToCampaignVersionIndex.AssociateEmailToCampaignVersionCreate} />
    <Route path="associateEmailToCampaignVersion/:associateEmailToCampaignVersionId" component={Index.AssociateEmailToCampaignVersionIndex.AssociateEmailToCampaignVersionEdit} />
    <Route path="order" component={Index.OrderIndex.OrderList} />
    <Route path="reward" component={Index.RewardIndex.RewardList} />
    <Route path="reward/create" component={Index.RewardIndex.RewardCreate} />
    <Route path="reward/:rewardId" component={Index.RewardIndex.RewardEdit} />
    <Route path="rewardDiscountCode" component={Index.RewardDiscountCodeIndex.RewardDiscountCodeList} />
    <Route path="rewardDiscountCode/create" component={Index.RewardDiscountCodeIndex.RewardDiscountCodeCreate} />
    <Route path="rewardDiscountCode/import" component={Index.RewardDiscountCodeIndex.RewardDiscountCodeImport} />
    <Route path="rewardDiscountCode/:rewardId" component={Index.RewardDiscountCodeIndex.RewardDiscountCodeEdit} />
    <Route path="rewardPool" component={Index.RewardPoolIndex.RewardPoolList} />
    <Route path="rewardPool/create" component={Index.RewardPoolIndex.RewardPoolCreate} />
    <Route path="rewardPool/:rewardId" component={Index.RewardPoolIndex.RewardPoolEdit} />
    <Route path="userManagement" component={Index.UserManagementIndex.UserManagementList} />
    <Route path="userManagement/create" component={Index.UserManagementIndex.UserManagementCreate} />
    <Route path="userManagement/:userManagementId" component={Index.UserManagementIndex.UserManagementEdit} />
    <Route path="reports/liveClients" component={Index.LiveClientsIndex.LiveClientsList} />
    <Route path="reports/liveRewards" component={Index.LiveRewardsIndex.LiveRewardsList} />
    <Route path="reports/liveRewardsBasic" component={Index.LiveRewardsBasicIndex.LiveRewardsBasicList} />
    <Route path="reports/liveStatsOld" component={Index.LiveStatsIndexOld.LiveStatsList} />
    <Route path="reports/liveStats" component={Index.LiveStatsIndex.LiveStatsList} />
    <Route path="reports/liveStatsValidity" component={Index.LiveStatsValidityIndex.LiveStatsList} />
    <Route path="reports/liveStatsMarketplace" component={Index.LiveStatsIndex.LiveStatsList} />
    <Route path="reports/liveStatsSummary" component={Index.LiveStatsSummaryIndex.LiveStatsSummaryList} />
    <Route path="reports/liveStatsSummaryMarketPlace" component={Index.LiveStatsSummaryIndex.LiveStatsSummaryList} />
    <Route path="reports/liveStatsCommissionPerMonth" component={Index.LiveStatsCommissionPerMonthIndex.LiveStatsCommissionPerMonthList} />
    <Route path="dynamicPage/:pageId" component={Index.DynamicPage.Dashboard} />
    <Route path="dynamicPage/passThrough/go" component={Index.DynamicPage.PassThrough} />
    <Route path="userBlacklist" component={Index.UserBlacklistIndex.UserBlacklistList} />
    <Route path="wizard" component={Index.wizardIndex.wizardCreate} />
    <Route path="explorer" component={Index.ExplorerIndex.MainList} />
    <Route path="live/integration" component={Index.LiveIntegrationIndex.Dashboard} />
  </Route>
);

export default adminRoutes;
