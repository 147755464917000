import React from 'react';
import classnames from 'classnames';
import valueOrderStatusService from '../../../utils/services/valueOrderStatus';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import commonFunctions from '../../../utils/helpers/CommonFunctions';

export default class ValueOrderStatusDropbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: this.props.value ? this.props.value : null,
      statusList: []
    };
  }

  componentDidMount = () => {
    this.getOrder();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.value != this.props.value) {
      if (typeof this.props.value === 'object') {
        this.setState({ status: null });
      } else if (typeof this.props.value === 'string') {
        this.setState({ status: this.props.value });
      }
    }
  };

  getOrder = () => {
    valueOrderStatusService.getValueOrderStatusList().then(data => {
      const formattedData = {
        data: data.data.page
      };
      data = commonFunctions.sortData(formattedData, { value: 'value' });
      const statusList = data.map(status => ({
        label: `${status.value}`,
        value: status.value
      }));
      this.setState({ statusList });
    });
  };

  orderStatusTemplate = option => {
    return (
      <div className="ui-helper-clearfix">
        <div>{option.label}</div>
        {/* <div>{option.value}</div> */}
      </div>
    );
  };

  onChange = e => {
    this.setState({ status: e.value });
    this.props.onChange(e);
  };

  render() {
    const { label, required, className, error, flex, inline, disabled } = this.props;

    const cx = classnames('c-form-element', className, {
      'c-form-element__error': error,
      'c-form-element__flex': flex,
      'c-form-element--inline': inline
    });

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {required && <span className="u-color-pink">&nbsp;*</span>}
        </label>
      );
    }

    return (
      <div className={cx}>
        {showlabel}

        <Dropdown
          value={this.state.status}
          options={this.state.statusList}
          disabled={disabled}
          showClear
          onChange={this.onChange}
          itemTemplate={this.orderStatusTemplate}
          style={{ marginLeft: '5px', width: '300px', marginTop: '4px' }}
          panelStyle={{ width: '300px' }}
          filter={true}
          filterPlaceholder="Select an Order Status"
          filterBy="name,value"
          placeholder="Select an Order Status"
        />
      </div>
    );
  }
}
