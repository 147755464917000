import * as globalMessageActions from '../../../../actions/globalMessageActions';
import imageUploadHelper from '../../../../utils/helpers/imageUpload';
import config from '../../../../config/config';

export const getImageData = ({ current }) => {
  if (current) {
    const imageElement = current.file_upload_image_logo.current.files[0];

    if (imageElement) {
      var data = new FormData();
      data.append('file', imageElement);
      return data;
    } else {
      return;
    }
  }
};

export const handleImageChange = self => {
  var that = self;
  const fileItem = self.clientLogo.current.file_upload_image_logo.current;
  const filePreview = self.clientLogo.current.file_preview_upload_image_logo.current;

  let fileIsValid = true;

  filePreview.value = '';
  that.setState({
    uploadError: null
  });

  //check image is valid size
  if (!imageUploadHelper.imageSizeIsValid(fileItem.files[0].size, config.IMG.UPLOAD_LIMIT)) {
    that.setState({
      uploadError: 'Maximum upload limit is 2MB'
    });
    fileItem.value = '';
    return;
  }

  //check image is square
  if (fileItem.files[0]) {
    var _URL = window.URL || window.webkitURL;
    var image = new Image();
    image.onload = function() {
      if (this.width / this.height !== 1 || this.width < 150) {
        fileItem.val = '';
        fileIsValid = false;
        that.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Image must be square and have dimensions of at least 150x150.',
            timeout: 10000
          })
        );
        fileItem.value = '';
        filePreview.value = '';
        return;
      }
    };
    image.src = _URL.createObjectURL(fileItem.files[0]);
  }
  //check image is valid format and then load preview
  if (imageUploadHelper.imageIsValid(fileItem) && fileIsValid) {
    filePreview.src = window.URL.createObjectURL(fileItem.files[0]);
    filePreview.value = fileItem.files[0].name;
  } else {
    fileItem.value = '';
    that.props.dispatch(
      globalMessageActions.showMessage({
        type: 'error',
        message: 'Unsupported image format',
        timeout: 10000
      })
    );
    that.setState({
      uploadError: 'Unsupported image format'
    });
    return;
  }
};
