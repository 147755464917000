import request from '../wrappers/axios';
import _ from 'lodash';

import config from '../../config/config';
import store from '../../stores/store';
import * as indexActions from '../../actions/index.jsx'; 

class AuthService {
  constructor () {
  }

  login (username, password) {
    return request({
      url: config.API.BASEURL + '/auth/login',
      method: 'post',
      auth: {
        username: username,
        password: password
      }
    });
  }

  verify (userId, token, updatePayload) {
    var data = _.merge({}, {userId: userId, token: token}, updatePayload);
    return request({
      url: config.API.BASEURL + '/auth/verify' + (updatePayload ? '?update=1' : ''),
      method: 'post',
      data: data
    });
  }

  logout () {
   
    store.dispatch(indexActions.updateLoginCtx(null));

    return request({
      url: config.API.BASEURL + '/auth/logout',
      method: 'post'
    });
  }

  isLoggedIn (context) {
    var state = store.getState();
    if(context === 'client'){
      if(state.loginCtx.loginCtx && state.loginCtx.loginCtx.roles.some(s => s.name === 'clientUser')){
        return true;
      }
      return false;
    }

    if(context === 'user'){
      if(state.loginCtx.loginCtx && state.loginCtx.loginCtx.roles.some(s => s.name === 'user')){
        return true;
      }
      return false;
    }

    if(context === 'admin'){
      if(state.loginCtx.loginCtx && state.loginCtx.loginCtx.roles.some(s => s.name === 'admin')){
        return true;
      }
      return false;
    }

 
    return false;

  }

  passwordlessLogin (email, token) {
    return request({
      url: config.API.BASEURL + '/auth/passwordless',
      method: 'post',
      data: {
        identifier: email,
        token: token
      }
    });
  }
}

const authService = new AuthService();

export { authService };