import React from 'react';
import imageService from '../../../../utils/services/image';
import socialService from '../../../../utils/services/social';
import trackingService from '../../../../utils/services/tracking';
import * as socialActions from '../../../../actions/user/socialActions';
import imageUploadHelper from '../../../../utils/helpers/imageUpload';
import socialHelper from '../../../../utils/helpers/social';
import urlHelper from '../../../../utils/helpers/urlHelper';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import SocialConnect from './SocialConnect';
import Modal from 'react-modal';
import _ from 'lodash';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone'

const customModalStyle = {
  content : {
    'width': '40%',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '300px'
  }
};

@connect((store) => {
  return {
    loginCtx : store.loginCtx.loginCtx,
    userSocialPlatforms : store.userSocial.userSocialPlatforms,
    currentlyPosting : store.userSocial.currentlyPosting,
    modalIsOpen: store.userSocial.modalIsOpen
  };
})

class SocialPost extends React.Component{

  constructor(){
    super();
    this.state = ({
      files: null,
      remainingDescriptionCharacters: 140
    });
  }

  componentWillMount(){
    this.props.dispatch(socialActions.setUserSocialPlatforms(this.props.loginCtx._id));
  }

  componentDidUpdate () {
    _.each(this.props.userSocialPlatforms, (platform, key) => {
      if (platform.EXPIRED) {
        this.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: `Looks like your ${key.toLowerCase()} session has expired. Please reconnect.`,
            timeout: 10000
          }
        ));
      }
    });
  }

  onDrop(acceptedFiles, rejectedFiles){

    if(!imageUploadHelper.imageSizeIsValid(acceptedFiles[0].size , 2000000)){
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Maximum upload limit is 2MB',
          timeout: 10000
        }
      ));
      return;
    }

    if(imageUploadHelper.imageFileIsValid(acceptedFiles[0])){
      this.setState({
        files: acceptedFiles
      });
    }else{
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Invalid Image format',
          timeout: 10000
        }
      ));
    }

  }
  
  handleSubmit(e){
    e.preventDefault();

    var that = this;
    var socialPostLink = that.refs.socialPostLink;
    var socialPostDescription = that.refs.socialPostDescription;
    var platformParams = socialHelper.getPlatformParams(that.props.userSocialPlatforms);

    //check if at least one social channel is selected
    var platformChecked = false;
    _.forEach(this.props.userSocialPlatforms,function(platform){
      if(platform.STATUS){
        platformChecked = true;
      }

    });


    if(!platformChecked){
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Please SELECT at least one Social Platform to post to',
          timeout: 10000
        }
      ));
      return;
    }

    
    if(this.state.files && imageUploadHelper.imageFileIsValid(this.state.files[0])){

      socialPostLink.classList.remove('error');
      socialPostDescription.classList.remove('error');
      var fieldError = [];

      //check if valid link is supplied
      if(!urlHelper.validateUrl(socialPostLink.value)){
        socialPostLink.classList.add('error');
        fieldError.push('Please ensure you have entered a valid URL eg (http://example.com)');
      }

      //check description if filled in
      if(socialPostDescription.value == ''){
        socialPostDescription.classList.add('error');
        fieldError.push('Please ensure description if filled in');
      }

      if(fieldError.length){
        var errorMessage = '';
        _.forEach(fieldError,function(error){
          errorMessage += error + ' ';
        });

        this.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: errorMessage,
            timeout: 10000
          }
        ));

        return;
      }

      that.props.dispatch(socialActions.updateCurrentlyPostingStatus(true));

      var data = new FormData();
      data.append('file', this.state.files[0]);

      //upload file to selected platforms
      imageService.uploadImage(data, platformParams)
        .then(function(res){
          var data = {
            message: socialPostDescription.value,
            url: socialPostLink.value,
            fileId: res.data.fileId,
            socialUploads: res.data.socialUploads
          };


          trackingService.socialShare(that.props.loginCtx,data);
          return socialService.postSocialPost(data);
        })
        .then(function (res) {
          that.props.dispatch(socialActions.updateCurrentlyPostingStatus(false));

          //reset fields
          that.setState({
            files: null
          });
          socialPostLink.value = '';
          socialPostDescription.value = '';

          //show success message
          that.props.dispatch(globalMessageActions.showMessage(
            {
              type: 'success',
              message: 'Product successfully shared',
              timeout: 10000
            }
          ));
          return res;
        })
        .catch(function(err){
          console.log(err);
          return err;
        });
    }else{
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Please upload an image first',
          timeout: 10000
        }
      ));
    }
  }
  
  handlePlatformCheckboxChange(e){
    var payload = {
      platform: e.target.value,
      currentPlatforms: this.props.userSocialPlatforms
    };

    this.props.dispatch(socialActions.clearUserSocialPlatforms());
    this.props.dispatch(socialActions.updateUserSocialPlatforms(payload));

  }

  handleDescriptionCharacterCountChange(){
    this.setState({
      remainingDescriptionCharacters: 140 - this.refs.socialPostDescription.value.length
    });
  }

  openConnectPopup(){
    this.props.dispatch(socialActions.setConnectModalStatus(!this.props.modalIsOpen));
  }
  
  render(){
    return (
      <div className="grid-100 social-post">
        <h1 className="text-center">Share</h1>
        <div className="grid-55">
          <Dropzone className={'dropzone'} ref="dropzone" onDrop={this.onDrop.bind(this)} >
            {
              this.state.files ?
                <img src={this.state.files[0].preview} alt=""/>
                :
                <div className="dropzone-holder">
                  <div className="inner text-center">
                    <i className="fa fa-camera" aria-hidden="true"></i>
                    <p>
                      <strong>Drop photo here</strong>
                      Or Click to browse
                    </p>
                  </div>
                </div>
            }
          </Dropzone>
        </div>
        <div className="grid-45">
          <form id="social-post-form" onSubmit={(e) => this.handleSubmit(e)}>
            <p>
              Add a link of the product to let your friends know where they can buy it
            </p>
            <div className="grid-100 grid-parent text-center">
              <input ref={'socialPostLink'} id="social-post-link" type="text" placeholder="URL" className="input-field" /><br/>
              <div className="description-holder">
                <textarea onKeyUp={() => this.handleDescriptionCharacterCountChange()} maxLength="140" ref={'socialPostDescription'} id="social-post-description" type="text"
                          placeholder="Make it exciting. Tell them here what you think">
              </textarea>
                <span className="word-count"><span>{this.state.remainingDescriptionCharacters}</span> / 140</span>
              </div>
            </div>
            <p>
              Share on your social networks. Select the networks you want to share on
            </p>
            <div className="social-select">
              {
                _.map(this.props.userSocialPlatforms , (platform , key) => {
                  return <div className={`social-check`} key={key}>
                    <input id={`check-${key}`} onChange={(e) => this.handlePlatformCheckboxChange(e)} type="checkbox" value={key} checked={platform.STATUS ? true : false} disabled={platform.ENABLEDFORUSER ?  false : true}/>
                    <label onClick={!platform.ENABLEDFORUSER ? () => this.openConnectPopup() : false} className={ (`check-${key} `) + (platform.ENABLEDFORUSER ? 'enabled ' : '') + (platform.STATUS ? 'checked ' : '') } htmlFor={`check-${key}`}><i className={platform.ICONCLASS}></i></label>
                    {platform.EXPIRED ? <div className="label-error"><i className="fa fa-exclamation" aria-hidden="true"></i></div> : null}
                  </div>;
                })
              }
            </div>
            <div className="clearfix">
              <button className="primary" type="submit">Submit</button>
            </div>
          </form>
        </div>
        {
          this.props.currentlyPosting ?
            <div className="loading-gif text-center">
              <img src="/images/loading-gif.gif"/>
            </div>
          : null
        }
        <Modal isOpen={this.props.modalIsOpen}
               contentLabel="profile-modal"
               shouldCloseOnOverlayClick={true}
               style={customModalStyle}>
          <SocialConnect />
        </Modal>
      </div>
    );
  }
}

export default SocialPost;