import React from 'react';
import _ from 'lodash';

export default {
  getTopProducts: (sharedUrls) => {

    var topProducts = {};
    if (sharedUrls) {
      sharedUrls.forEach((sharedUrl) => {
        if (!topProducts[sharedUrl.productUrl]) {topProducts[sharedUrl.productUrl] = {clicks: 0, shares: 0};}

        topProducts[sharedUrl.productUrl].shares += 1;
        topProducts[sharedUrl.productUrl].clicks += sharedUrl.accesses.length;
      });

      topProducts = _.orderBy(_.map(topProducts, (product, key) => {
        return _.merge(product, {productUrl: key});
      }), ['clicks'], ['desc']);

      var topProductItems = topProducts.map((product, index) => {
        return (
          <tr key={index}>
            <td><a href='{product.productUrl}'>{product.productUrl}</a></td>
            <td>{product.clicks}</td>
            <td>{product.shares}</td>
            <td>Unavailable</td>
          </tr>
        );
      });

      return topProductItems.splice(0,5);
    }
  },
  sortResults: (data , sortDirection , sortIndex) => {
    data = _.orderBy(data,sortIndex,[sortDirection]);
    return data;
  },
  getEngagementProducts(products){
    _.each(products , function(sharedUrl){
      sharedUrl.title = sharedUrl.meta && sharedUrl.meta.title ? sharedUrl.meta.title : sharedUrl.productUrl;
      sharedUrl.image = sharedUrl.meta && sharedUrl.meta.image ? sharedUrl.meta.image : null;
      sharedUrl.epc = !isNaN(sharedUrl.earnings / sharedUrl.clicks) ? sharedUrl.earnings / sharedUrl.clicks : 0;
      sharedUrl.earnings = parseFloat(sharedUrl.earnings);
    });

    return products;
  },
  getTransactions: (transactions) => {
    _.each(transactions , function(transaction){
      transaction.amount = parseFloat(parseFloat(transaction.amount));
      transaction.name = transaction.clientName ? transaction.clientName : transaction.bankPreview;
    });

    return transactions;
  },
  getActivity: (activity) => {
    _.each(activity , function(activity){
      activity.title = activity.meta && activity.meta.title ? activity.meta.title : activity.productUrl;
      activity.image = activity.meta && activity.meta.image ? activity.meta.image : null;
      activity.commission = parseFloat(parseFloat(activity.commission));
    });

    return activity;
  }
};