import React from 'react';
import _ from 'lodash';
import JSONPretty from 'react-json-pretty';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
import {InputText} from 'primereact/components/inputtext/InputText';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import reportService from './../../../../utils/services/reports';
import {Button} from 'primereact/components/button/Button';
import './Dashboard.css';

class IntegrationDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: '',
      ignoreArchived: false,
      firstAudit: 0,
      firstTag:0,
      firstTrack:0
    };

    this.rowClass = this.rowClass.bind(this);
    this.testModeTemplate = this.testModeTemplate.bind(this);
  }
    
  resetFilter = () => this.setState({searchField: null});  

  search(){
    this.onLazyLoadAudit({});
    this.onLazyLoadTrack({});
    this.onLazyLoadTag({});
  }

  rowExpansionTemplate = (data) => {

    return (<div style={{textAlign: 'justify', backgroundColor: ''}}>
      <JSONPretty id="json-pretty" data={data} theme={JSONPrettyMon}></JSONPretty>
    </div>);
  };

  rowClass(data) {

    let error = this.getNested(data, ['invalidTagArgs']) == 'true';

    return {
      'live-int-row': !error,
      'row-error': error,
    };
  }

  testModeTemplate(data) {

    let testMode = this.getNested(data, ['testMode']);
    testMode =  testMode === true || testMode == 'true';

    return testMode && (<div><i class="fa fa-gamepad" style={{color:'yellow'}} aria-hidden="true"></i></div>) || (<div><i class="fa fa-gamepad" style={{color:'gray'}} aria-hidden="true"></i></div>);

  }

  getNested = (data, fieldNames) => {

    let value = null;
    let recursion = (data) => {
  
      let nestedFind = fieldNames.find(n => _.get(data, n));

      if(nestedFind){
        value = _.get(data, nestedFind);
        return;
      }

      // iterate over all object keys
      for(let key in data){
  
        if(fieldNames.find(n => n == key)){
          value = data[key];
          break;
        }
        
        // is this property vakue a sub object?
        if(this.getType(data[key]) == 'object'){
          recursion(data[key]);
  
          continue;
        }          
      }
    };
  
    recursion(data);

    return value;
  
  }

  getField(data, fieldNames, alert) {

    let value = this.getNested(data, fieldNames);
    let isEmail = fieldNames.find(n => n.includes('email'));

    return (alert === true && (!value || _.isNil(value) || (isEmail && !value.includes('@')))) ? (<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>) : (<span>{value}</span>);

  }

  getType(p){
    if (Array.isArray(p)) return 'array';
    else if (typeof p == 'string') return 'string';
    else if (!isNaN(p)) return 'number';
    else if (p != null && typeof p == 'object') return 'object';
    else return 'other';
  }

  getQuery = e => {
    
    
    let first = e.first ? e.first : 0;

    const query = {
      $offset: first,
      $limit: e.rows,
      $sort: 'date:desc',
      freeString: encodeURIComponent(this.state.searchField)
    };

    return query;
  }

  onLazyLoadAudit = (e) => {
    this.state.firstAudit = isNaN(e.first) ? 0 : e.first;
    this.setState({loadingAudit: true});

    let query = this.getQuery(e);
    query.indexPattern = 'soreto_live_audit*';

    reportService.getLiveIntegration(query)
      .then((response) => {

        this.setState({
          auditList : response.data.hits,
          totalRecordsAudit: response.data.total.value,
          loadingAudit: false
        });
      })
      .then(() => {
        // if(this.state.list.length === 1) {
        //   this.setState({expandedRows: [this.state.list[0]]});
        // }
      });
  }

  onLazyLoadTag = (e) => {
    this.state.firstTag = isNaN(e.first) ? 0 : e.first;
    this.setState({loadingTag: true});

    let query = this.getQuery(e);
    query.indexPattern = 'soreto_live_tag*';
    
    reportService.getLiveIntegration(query)
      .then((response) => {

        this.setState({
          tagList : response.data.hits,
          totalRecordsTag: response.data.total.value,
          loadingTag: false
        });
      })
      .then(() => {
        // if(this.state.list.length === 1) {
        //   this.setState({expandedRows: [this.state.list[0]]});
        // }
      });
  }

  onLazyLoadTrack = (e) => {
    this.state.firstTrack = isNaN(e.first) ? 0 : e.first;
    this.setState({loadingTrack: true});

    let query = this.getQuery(e);
    query.indexPattern = 'soreto_live_track*';
    
    reportService.getLiveIntegration(query)
      .then((response) => {

        this.setState({
          trackList : response.data.hits,
          totalRecordsTrack: response.data.total.value,
          loadingTrack: false
        });
      })
      .then(() => {
        // if(this.state.list.length === 1) {
        //   this.setState({expandedRows: [this.state.list[0]]});
        // }
      });
  }

  render = () => {
    const {searchField} = this.state;
    return (
        
      <div>
        <br/>
        <br/>
        <div className="ui-g">
          <div className="ui-g-4">
            <label htmlFor='searchTerm'>Search term</label>
            <InputText
              id='searchTerm'
              onChange={(e) => this.setState({ searchField: e.target.value  })}
              value={searchField}
              onKeyDown = {(e) => {if(e.keyCode === 13);}}
              style={{width:'100%', height:'100%', fontSize:'25px'}}
            />
          </div>
          <div className="ui-g-1">
            <Button onClick={() => this.search(this.state)} style={{height:'100%', marginTop:'17px', paddingLeft:'25px', paddingRight:'25px'}}><i class="fa fa-search fa-3" aria-hidden="true"></i></Button>
          </div>
        </div>
        
        <br/>

        <h4>Tag Details</h4>

        <DataTable
          value={this.state.tagList}
          responsive={true}
          paginator={true} rows={10}
          rowsPerPageOptions={[5,10,20,100]}
          totalRecords={this.state.totalRecordsTag}
          lazy={true}
          onLazyLoad={this.onLazyLoadTag}
          loading={this.state.loadingTag}
          ref={(el) => this.dt = el}
          onRowToggle={e => this.setState({ expandedRows: e.data })}
          rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
          expandedRows={this.state.expandedRows}
          rowClassName={this.rowClass}
          first = {this.state.firstTag}
        >
          <Column expander={true} style={{ width: '45px' }} />
          <Column field="_source.date" header="Date"  />
          <Column field="_source.meta.params.externalId" header="External Id"  />
          <Column field="_source.meta.headers.referer" header="Referer" className='live-table-column'  />
          
          <Column body={this.editAction} style={{textAlign:'center', width: '50px'}}/>
        </DataTable>

        <br/>
        <h4>Audit</h4>
        <DataTable
          value={this.state.auditList}
          responsive={true}
          paginator={true} rows={10}
          rowsPerPageOptions={[5,10,20,100]}
          totalRecords={this.state.totalRecordsAudit}
          lazy={true}
          onLazyLoad={this.onLazyLoadAudit}
          loading={this.state.loadingAudit}
          ref={(el) => this.dt = el}
          onRowToggle={e => this.setState({ expandedRows: e.data })}
          rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
          expandedRows={this.state.expandedRows}
          var="car"
          rowClassName={this.rowClass}
          first = {this.state.firstAudit}

        >
          <Column expander={true} style={{ width: '45px' }} />
          <Column body={this.testModeTemplate} header="Test" style={{width:'50px'}} />
          <Column field="_source.date" header="Date" className='live-table-column' />
          <Column body={e => this.getField(e, ['Client.id', 'clientId'])} header="Client id"  />
          <Column body={e => this.getField(e, ['Order.id', 'orderObj.orderId'], true)} header="Order id"  />
          <Column body={e => this.getField(e, ['Order.total', 'orderObj.orderTotal'], true)} header="Order total"  />
          <Column body={e => this.getField(e, ['Order.country'])} header="Order country"  />
          <Column body={e => this.getField(e, ['User.firstName', 'firstName'], true)} header="First name"  />
          <Column body={e => this.getField(e, ['User.email', 'buyerEmail'], true)} header="Email" className='live-table-column' />
          <Column body={e => this.getField(e, ['meta.referer'])} header="Referer"  />
          
          <Column body={this.editAction} style={{textAlign:'center', width: '50px'}}/>
        </DataTable>

        <br/>

        <h4>Track</h4>
        <DataTable
          value={this.state.trackList}
          responsive={true}
          paginator={true} rows={10}
          rowsPerPageOptions={[5,10,20,100]}
          totalRecords={this.state.totalRecordsTrack}
          lazy={true}
          onLazyLoad={this.onLazyLoadTrack}
          loading={this.state.loadingTrack}
          ref={(el) => this.dt = el}
          onRowToggle={e => this.setState({ expandedRows: e.data })}
          rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
          expandedRows={this.state.expandedRows}
          rowClassName={this.rowClass}
          first = {this.state.firstTag}
          

        >
          <Column expander={true} style={{ width: '45px' }} />
          <Column body={this.testModeTemplate} header="Test" style={{width:'50px'}} />
          <Column field="_source.date" header="Date"  />
          <Column body={e => this.getField(e, ['clientId'])} header="Client Id"  />
          <Column body={e => this.getField(e, ['method'])} header="Method"  />
          <Column field="_source.meta.referer" header="Referer" className='live-table-column'/>
          <Column body={e => this.getField(e, ['country'])} header="Country"  /> 
          
          <Column body={this.editAction} style={{textAlign:'center', width: '50px'}}/>
        </DataTable>
      </div>
    );
  }

}

export default IntegrationDashboard;