import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import keyEmailTemplateTypeService from '../../../utils/services/keyEmailTemplateType';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class KeysEmailTemplateTypeDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.items = [];
    this.getItems = this.getItems.bind(this);
    
    this.state = {
      item: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getItems();
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.value === 'object') {
      this.setState({ item: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ item: nextProps.value });
    }
    if(nextProps.currentEmailTemplateTypeId && !this.state.item){
      let matchingItem = this.items.find((item)=>{
        return item.id === nextProps.currentEmailTemplateTypeId;
      });
     
      if(matchingItem) this.setState({ item: matchingItem.value });
    }
  }

  getItems() {
    keyEmailTemplateTypeService.getKeyEmailTemplateType().then((data) => {
      if(data.data) {
        const groupedKeys = _.groupBy(data.data, 'emailTemplateTypeId');
        const listItem = Object.keys(groupedKeys).map((item)=>{
          let label = groupedKeys[item][0].emailTemplateType;
          return {
            label:label,
            id:item,
            value:groupedKeys[item]
          };
        });
        this.setState({ items: listItem });
        this.items = listItem;
      }
      else {
        this.items = null;
      }
      this.forceUpdate();
    });
  }


  campaignTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span><b>Name: </b><div className='drop-down-option'>{option.label}</div></span>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ item: e.value });
    this.props.onChange(e);
  }

  render() {

    const { label, required, className, error, flex, inline } = this.props;

    const cx = classnames('c-form-element', className, {
      'c-form-element__error': error,
      'c-form-element__flex': flex,
      'c-form-element--inline': inline
    });

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label} 
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>

        { showlabel }

        <Dropdown value={this.state.item} options={this.items} 
          showClear
          onChange={this.onChange} itemTemplate={this.campaignTemplate} 
          style={{ marginLeft: '5px', width:'300px', marginTop: '4px' }} 
          filter={true} filterPlaceholder="Select Template Type" filterBy="label,value"
          placeholder="Select a Template Type"/>
      </div>
      
    );
  }
}
