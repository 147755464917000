import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import './style.css';

import config from  '../../../../../../config/config';
import Label from  '../../../../../shared/label/label';
import CampaignVersionDataTable from '../campaignVersion/dataTable';

class CampaignDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list,
      ignoreArchived: this.props.ignoreArchived
    };

    this.withToolTip = this.withToolTip.bind(this);
  }

  withToolTip(rowData, column)
  {
    const value = rowData[column.field];
    return <span title={value}>{value}</span>;
  }

  editAction =  rowData => <span className="fa fa-cog" style={{cursor: 'pointer'}} onClick={() => {
    window.open(`${config.URL.BASEFRONT}/admin/campaign/${rowData._id}`, '_blank');
  }}/>

  rowExpansionTemplate = data => <CampaignVersionDataTable campaignId={data._id} running={data.running} ignoreArchived={this.state.ignoreArchived}/>

  status = rowData => 
    <React.Fragment>
      {rowData.running &&  <Label color="green" text="Running" />}
      {rowData.active && !rowData.running && <Label color="darkslategray" text="Active" />}
      {rowData.superCampaign && <Label color="blue" text="Super Campaign" />}
      {!rowData.active && !rowData.archived && <Label color="red" text="Inactive" />}
      {rowData.archived && <Label color="orange" text="Archived" />}
      {rowData.expired && <Label color="tomato" text="Expired" />}
      {rowData.future && <Label color="pink" text="Future" />}
    </React.Fragment>

  render = () => 
    <div className="campaign-datatable">
      <DataTable
        value={this.state.list}
        responsive={true}
        ref={(el) => this.dt = el}
        onRowToggle={e => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
        expandedRows={this.state.expandedRows}
        rowClassName={(r) => ({'running-campaign': r.running})}
      >
        <Column expander={true} style={{ width: '45px' }} />
        <Column body={this.status} header="Status" style={{ width: '250px'}}/>
        <Column field="_id" header="ID" body={this.withToolTip} style={{width:'110px', overflow: 'hidden', textOverflow: 'ellipsis'}}/>
        <Column field="description" header="Description" sortable={true}/>
        <Column field="createdAt" header="Created" style={{width: '110px'}} sortable={true}/>
        <Column field="startDate" header="Start" style={{width: '110px'}} sortable={true}/>
        <Column field="expiry" header="Expiry" style={{width: '110px'}} sortable={true}/>
        <Column field="sourceTemplateName" header="Source Template" sortable={true}/>
        <Column field="orderOriginCurrency" header="SOC" style={{width: '110px'}} sortable={true}/>
        <Column body={this.editAction} style={{textAlign:'center', width: '50px'}}/>
      </DataTable> 
    </div>
}

export default CampaignDataTable;
