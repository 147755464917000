import React from 'react';
import Header from './header/Header';
import SliderMenu from '../../shared/menu/sliderMenu';
import Footer from './footer/Footer';
import GlobalMessage from '../../shared/globalMessage/GlobalMessage';
import {connect} from 'react-redux';
import config from '../../../config/config';
import * as headerActions from '../../../actions/user/headersActions';

require('../../shared/sass/style.scss');

@connect((store)=>{
  return {
    globalMessage : store.globalMessages.message,
    headerMenuVisible: store.userHeader.headerMenuVisible,
    menuItems: config.MENU.USER,
  };
})

class Layout extends React.Component {

  constructor() {
    super();
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  closeHeaderMenu(){
    this.props.dispatch(headerActions.closeHeaderMenu());
  }

  goTo(location){
    this.props.router.push({ pathname: location });
  }

  logout(){
    this.props.router.push({ pathname: '/logout' });
  }


  render () {
    return (
      <div className="grid-container page-content-wrapper off-canvas-wrapper main-container">
        <SliderMenu  menuItems={this.props.menuItems} goTo={this.goTo} logout={this.logout} closeHeaderMenu={() => this.closeHeaderMenu()} headerMenuVisible={this.props.headerMenuVisible}/>
        <div className={'off-canvas-inner '+ this.props.headerMenuVisible}>
          <Header {...this.props} menuItems={this.props.menuItems} />
          <GlobalMessage message={this.props.globalMessage}/>
          <div className="grid-container">
            <div className="content">{this.props.children}</div>
          </div>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Layout;