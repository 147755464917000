import React from 'react';

class Footer extends React.Component {
  render () {
    return (
      <div className="grid-container main-container">
        <footer className="footer">
          <div className="grid-container">
            <div className="grid-50 tablet-grid-100">
              <div className="footer-copywrite-holder">
                @2019 Soreto. All rights reserved.
              </div>
            </div>
            <div className="grid-50 tablet-grid-100">
              <div className="footer-logo-holder right hide-on-tablet hide-on-mobile">
                <img src="/images/logo.png" alt="footer logo" />
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;