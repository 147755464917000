import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import validator from 'validator';

import FormElement from '../../../shared/form/FormElement';
import * as emailTemplateActions from '../../../../actions/admin/emailTemplateActions';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import JsonMicroForm from '../../../shared/form/JsonMicroForm';

import KeysEmailTemplateTypeDropbox from '../../../shared/dropbox/KeysEmailTemplateTypeDropbox';

export class EmailTemplateCopy extends React.Component {

  constructor(props) {
    super(props);

    const {
      emailTemplateDetails = {
        clientId:'',
        clientName: '',
        name:'',
        type: '',
        externalTemplateId: '',
        meta: [],
        externalServiceName: '',
        keyEmailTemplateTypeId:'',
        emailTemplateTypeId:'',
      }
    } = props;

    this.state = {
      emailTemplateDetails,
      errors: {},
      displayErrorMessage: false
    };
  }

  componentDidMount() {
    const emailTemplateId = this.props.params.emailTemplateId;
    this.setState({ emailTemplateId: emailTemplateId });
    if (emailTemplateId) {
      this.props.dispatch(emailTemplateActions.getEmailTemplateWithTemplateKeysById(emailTemplateId));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.emailTemplateGet) {


      let newTemplate = nextProps.emailTemplateGet;

      const rawTemplate = Object.keys(newTemplate.templateValues).map(function(key) {
        return {
          type: 'input',
          label: key,
          value: newTemplate.templateValues[key],
          templateKey: key
        };
      });

      const mappedTemplate = newTemplate.keys.map((item)=>{
        item.label = item.label.indexOf('- (') < 0 ? item.label +' - ('+item.templateKey+')' : item.label;
        item.value = newTemplate.templateValues[item.templateKey];
        return item;
      });

      newTemplate.meta = mappedTemplate.concat(rawTemplate);
      newTemplate.meta = _.uniqBy(newTemplate.meta, 'templateKey');

      this.setState({ emailTemplateDetails : newTemplate });
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.emailTemplateCopy != nextProps.emailTemplateCopy){
      if(_.get(nextProps,'emailTemplateCopy')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Email Template copy saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'emailTemplateCopy.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.emailTemplateCopy.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onKeysTemplateChange({value}){
    const { emailTemplateDetails } = this.state;
    if(value){
      
      value = value.map((el)=>{
        const matchingEl = emailTemplateDetails.meta.find((element)=>element.templateKey === el.templateKey);
        el.value = matchingEl ? matchingEl.value : undefined;
        return el;
      });
      value.forEach((item)=>{
        if(item.label.indexOf('- (') < 0){
          item.label = item.label +' - ('+item.templateKey+')';
        }
        return item;
      });

      _.set(emailTemplateDetails, 'meta', value);
      _.set(emailTemplateDetails, 'emailTemplateTypeId', value[0].emailTemplateTypeId);
    }else{
      const itemsWithVals = _.filter(emailTemplateDetails.meta, (el)=>el.value);
      _.set(emailTemplateDetails, 'meta', itemsWithVals);
      _.set(emailTemplateDetails, 'emailTemplateTypeId', '');
    }

    this.setState({ emailTemplateDetails });
  }

  onChange(name, {target}) {
    const { emailTemplateDetails } = this.state;
    _.set(emailTemplateDetails, name, target.value);
    this.setState({emailTemplateDetails});
  }

  onJsonTestChange(name, i, {target}){
    const { emailTemplateDetails } = this.state;
    emailTemplateDetails.meta[i].value = target.value;
    this.setState({emailTemplateDetails});
  }

  onSubmit(){

    this.setState({ displayErrorMessage: false });
    const { emailTemplateDetails,errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      const emailTemplateDetailsReduced = emailTemplateDetails.meta.reduce((obj,el)=>{
        obj[el.templateKey] = el.value;
        return obj;
      },{});
      emailTemplateDetails.templateValues = emailTemplateDetailsReduced;

      emailTemplateDetails.updatedAt = new Date();
      this.props.dispatch(emailTemplateActions.copyEmailTemplate(this.state.emailTemplateId, emailTemplateDetails));
    }
  }

  onClientChange(e, item){
    const { emailTemplateDetails } = this.state;
    _.set(emailTemplateDetails, 'clientId', item.value);
    this.setState({ emailTemplateDetails });
  }

  validateForm() {
    const { emailTemplateDetails, errors } = this.state;

    errors['templateErr'] = _.find(emailTemplateDetails.meta, (o) => {
      return (o.required && !o.value);
    });
    errors[ 'client' ] = validator.isEmpty(emailTemplateDetails.clientName);
    errors[ 'name' ] = validator.isEmpty( emailTemplateDetails.name );
    errors[ 'type' ] = validator.isEmpty( emailTemplateDetails.type );
    errors[ 'externalTemplateId' ] = validator.isEmpty( emailTemplateDetails.externalTemplateId );
    errors[ 'externalServiceName' ] = validator.isEmpty( emailTemplateDetails.externalServiceName );
    
    // hack to make jest happy...
    if(emailTemplateDetails.templateValues.HEADER_IMAGE === '') {
      errors[ 'headerImage' ] = true;
    } else {
      errors[ 'headerImage' ] = false;
    }

    return this.setState({ errors });
  }

  render (){
    const { emailTemplateDetails = { info: {} }, errors } = this.state;

    const {
      clientName,
      clientId,
      name,
      type,
      externalTemplateId,
      externalServiceName,
      meta,
      emailTemplateTypeId,
    } = emailTemplateDetails;


    return (
      <div>
        <h3>Copy email</h3>
        <form  className="c-form--flex">

          <ClientsDropbox
            label="Client"
            name="client"
            required='true'
            onChange={this.onClientChange.bind(this,'client')}
            error={errors.client}
            value={clientId}
          />

          <FormElement
            label='Email Template'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-email-template-name'
            required
          />

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            error={errors.type}
            id='form-type'
            required
          >
            <option value="">-</option>
            <option value="reward_email">Thank you email</option>
            <option value="email_self_reward">Email me code</option>
            <option value="share_with_friend_email">Share With Friend Email</option>
            <option value="share_with_friend_email_reminder">(Forward Email) Share With Friend Reminder</option>
            <option value="post_purchase_discount_reward_email">Post Purchase Discount Reward Email</option>
            <option value="post_purchase_friend_reward_email">Post Purchase Friend Reward Email</option>            
          </FormElement >

          <FormElement
            label='External Service'
            name='externalServiceName'
            type='select'
            onChange={this.onChange.bind(this, 'externalServiceName')}
            value={externalServiceName}
            error={errors.externalServiceName}
            id='form-externalService'
            required
          >
            <option value="SENDINBLUE">Sendinblue</option>
            <option value="MANDRILL">Mandrill</option>
          </FormElement >

          <KeysEmailTemplateTypeDropbox
            label="Template Type"
            name="client"
            onChange={this.onKeysTemplateChange.bind(this)}
            currentEmailTemplateTypeId={emailTemplateTypeId}
          />

          <FormElement
            label='External Template Id'
            name='externalTemplateId'
            type='text'
            onChange={this.onChange.bind(this, 'externalTemplateId')}
            value={externalTemplateId}
            error={errors.externalTemplateId}
            id='form-externalTemplateId'
            required
          />

          <JsonMicroForm json={meta}  onChange={this.onJsonTestChange.bind(this, 'meta')}/>


        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Save Copy</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    emailTemplateGet: store.adminEmailTemplate.emailTemplateGetById,
    emailTemplateCopy: store.adminEmailTemplate.emailTemplateCopy
  };
})(EmailTemplateCopy);
