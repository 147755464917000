import request from '..//wrappers/axios';
import config from '../../config/config';

class imageService {
  
  uploadImage(data , platformParams = ''){
    return request({
      url: `${config.API.BASEURL}/uploadimage${platformParams}`,
      method: 'post',
      data: data
    })
  }
  
}

export default new imageService();