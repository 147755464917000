import rewardService from '../../utils/services/reward';
import constants from '../../constants/app-constants';

export const newReward = obj => dispatch => {
  return new Promise((resolve, reject) => {
    rewardService.newReward(obj)
      .then(res => {
        dispatch({
          type: constants.admin.REWARD_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.REWARD_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateReward = (obj) => {
  return function(dispatch){
    rewardService.updateReward(obj)
      .then(function(res){
        dispatch({
          type: constants.admin.REWARD_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_EDIT,
          payload: err.response
        });
      });
  };
};

export const getReward = (id) => {
  return function(dispatch){
    rewardService.getReward(id)
      .then(function(res){
        dispatch({
          type: constants.admin.REWARD_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_GET,
          payload: err.response
        });
      });
  };
};

export const getAllRewards = (query, extendedQuery) => {
  return function(dispatch){
    rewardService.getRewardList(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.admin.REWARD_GET_ALL,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_GET_ALL,
          payload: err.response
        });
      });
  };
};
