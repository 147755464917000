import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import ChartPanel from './Dashboard.ChartPanel';
import Profile from '../../shared/profile/Profile';


import clientHelper from '../../../../utils/helpers/client';
import chartHelper from '../../../../utils/helpers/chart';
import * as clientActions from '../../../../actions/client/clientActions';
import * as dashboardActions from '../../../../actions/client/dashboardActions';


@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    sharedUrls: store.clientDashboard.sharedUrls,
    sharedUrlsClicks: store.clientDashboard.sharedUrlsClicks,
    sharedUrlsShares: store.clientDashboard.sharedUrlsShares,
    earningsPerSocialPlatform: store.clientDashboard.earningsPerSocialPlatform
  };
})

export default class Dashboard extends React.Component {

  constructor(props){
    super(props);
    this.goToProductTractionReport = this.goToProductTractionReport.bind(this);
  }

  componentWillMount(){
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
    this.props.dispatch(dashboardActions.getSharedUrls(this.props.loginCtx.clientId, {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
    this.props.dispatch(dashboardActions.getSharedUrlsCountClicks(this.props.loginCtx.clientId, {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
    this.props.dispatch(dashboardActions.getSharedUrlsCountShares(this.props.loginCtx.clientId, {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
    this.props.dispatch(dashboardActions.getEarningsPerSocialPlatform(this.props.loginCtx.clientId, {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
  }

  goToProductTractionReport(){
    this.props.router.push({ pathname: '/client/reports/producttractionreport'});
  }

  render () {
    var topProductItems = clientHelper.getTopProducts(this.props.sharedUrls);

    var shareCountLabels = [];
    var shareCountData = [];

    _.each(this.props.sharedUrlsShares, (value, key) => {
      shareCountLabels.push(key);
      shareCountData.push(value);
    });

    var clickCountLabels = [];
    var clickCountData = [];

    _.each(this.props.sharedUrlsClicks, (value, key) => {
      clickCountLabels.push(key);
      clickCountData.push(value);
    });

    var revenueLabels = [];
    var revenueData = [];

    _.each(this.props.earningsPerSocialPlatform , (value , key) => {
      revenueLabels.push(key);
      revenueData.push(value);
    });


    // Chart data
    var revenueChart = chartHelper.getChartObjRevenue(revenueLabels, revenueData);
    var sharesChart = chartHelper.getChartObjShares(shareCountLabels, shareCountData);
    var clicksChart = chartHelper.getChartObjClicks(clickCountLabels, clickCountData);

    return (
      <div className='dashboard-client'>
        {this.props.clientObj && <div className="grid-100">
          <Profile />
          <div className="grid-100 graph-holder grid-parent">
            <h4 className="text-center">Traction (Last 30 Days)</h4>
            <ChartPanel ref="chartPanel" revenueChart={revenueChart} sharesChart={sharesChart} clicksChart={clicksChart} />
          </div>
          {this.props.sharedUrls != null ?
          <div className="grid-100 trending-data-holder">
            <h4 className="text-center">Top 5 Trending</h4>
            <div className="table-holder">
              <table id="trending-table" className='responsive'>
                <thead>
                <tr>
                  <th><i className='fa fa-link'></i> Item</th>
                  <th><i className='fa fa-share'></i> Shares</th>
                  <th><i className='fa fa-hand-o-up'></i> Clicks</th>
                  <th><i className='fa fa-money'></i> Earnings</th>
                </tr>
                </thead>
                <tbody>
                {topProductItems}
                </tbody>
              </table>
            </div>
            <div className='text-center'><button onClick={this.goToProductTractionReport} className='primary'>View More</button></div>
          </div>
            :
            <div className="grid-100 text-center">
              <div className="loading-gif text-center">
                <img src="/images/loading-gif.gif"/>
              </div>
            </div>
            }
        </div>}
      </div>
    );
  }
}