import assocClientAffilliateService from '../../utils/services/assocClientAffilliate';
import constants from '../../constants/app-constants';

export const newAssocClientAffilliate = (affiliateObj) => {
  return function(dispatch){
    assocClientAffilliateService.create(affiliateObj)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_NEW,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_NEW,
          payload: err.response
        });
      });
  };
};

export const updateAssocClientAffilliate = (affiliateObj) => {
  return function(dispatch){
    assocClientAffilliateService.update(affiliateObj)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_EDIT,
          payload: err.response
        });
      });
  };
};

export const deleteAssocClientAffilliate = (affiliateObj) => {
  return function(dispatch){
    assocClientAffilliateService.delete(affiliateObj)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_DELETE,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_DELETE,
          payload: err.response
        });
      });
  };
};

export const getAssocClientAffilliateByClientId = (clientId) => {
  return function(dispatch){
    assocClientAffilliateService.getAssocClientAffilliateByClientId(clientId)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT,
          payload: err.response
        });
      });
  };
};

export const getAssocClientAffilliateByClientIdAndCountryId = (clientId, countryId) => {
  return function(dispatch){
    assocClientAffilliateService.getAssocClientAffilliateByClientIdAndCountryId(clientId, countryId)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT,
          payload: err.response
        });
      });
  };
};