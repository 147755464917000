import Login from './shared/login/Login';
import Logout from './shared/logout/Logout';
import ForgotPassword from './shared/forgotPassword/ForgotPassword';
import ResetPassword from './shared/resetPassword/ResetPassword';
import Register from './user/pages/register/Register';
import AccountVerify from './user/pages/accountVerify/AccountVerify';
import AccountVerifyMarketplace from './user/pages/accountVerify/AccountVerifyMarketplace';

import triggerGATrack from './shared/ga/googleAnalytics';
import store from '../stores/store';
import { locationChangeEvent } from '../actions/index';
import clientRoutes from './client/ClientRoutes';
import userRoutes from './user/UserRoutes';
import adminRoutes from './admin/AdminRoutes';


import React from 'react';
import { Router, Route, browserHistory, withRouter } from 'react-router';

import { authService } from '../utils/services/auth';
import TwoFactorVerification from './shared/login/TwoFactor';

var locationChange = (nextState, replace) => {
  triggerGATrack(nextState, replace);
  store.dispatch(locationChangeEvent());
};

var isLoggedIn = (nextState, replace) => {
  var routePath = nextState.routes.length > 1 ? nextState.routes[1].path : '';

  if (!(authService.isLoggedIn('client') || authService.isLoggedIn('user') || authService.isLoggedIn('admin'))) {
    replace({
      pathname: '/login'
    });
    return;
  } else {
    checkLoggedInRoleRoute('admin', routePath, replace);
    checkLoggedInRoleRoute('client', routePath, replace);
    checkLoggedInRoleRoute('user', routePath, replace);
  }

};

var checkLoggedInRoleRoute = (context, path, replace) => {
  if(authService.isLoggedIn(context) && path !== '/' + context) {
    replace({
      pathname: '/' + context
    });
    return;
  }
};

export default () => {
  return (
    <Router history={browserHistory}>
      <Route path="/" onChange={locationChange} onEnter={isLoggedIn} >
        {clientRoutes}
        {userRoutes}
        {adminRoutes}
      </Route>
      <Route path="login" component={withRouter(Login)}  onChange={locationChange} />
      <Route path="twoFactorVerification" component={TwoFactorVerification}  onChange={locationChange} />
      <Route path="logout" component={Logout}  onChange={locationChange} />
      <Route path="forgotpassword" component={ForgotPassword}  onChange={locationChange} />
      <Route path="resetpassword" component={ResetPassword}  onChange={locationChange}/>
      <Route path="register" component={Register}  onChange={locationChange} />
      <Route path="accountverify" component={AccountVerify}  onChange={locationChange} />
      <Route path="marketplaceaccountverify" component={AccountVerifyMarketplace}  onChange={locationChange} />
    </Router>
  );
};