import request from '../wrappers/axios';
import config from '../../config/config';

class wizardService {
  setup (data, clientTemplate, version) {
    return request({
      url: `${config.API.BASEURL}/wizard/${clientTemplate}/${version}`,
      method: 'post',
      data: data
    }, true, 500)
      .catch(error =>  error.response.data);
  }
}

export default new wizardService();
