import rewardDiscountCodeService from '../../utils/services/rewardDiscountCode';
import constants from '../../constants/app-constants';

export const newRewardDiscountCode = obj => dispatch => {
  return new Promise((resolve, reject) => {
    rewardDiscountCodeService.newRewardDiscountCode(obj)
      .then(res => {
        dispatch({
          type: constants.admin.REWARD_DISCOUNT_CODE_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.REWARD_DISCOUNT_CODE_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateRewardDiscountCode = (obj) => {
  return function(dispatch){
    rewardDiscountCodeService.updateRewardDiscountCode(obj)
      .then(function(res){
        dispatch({
          type: constants.admin.REWARD_DISCOUNT_CODE_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_DISCOUNT_CODE_EDIT,
          payload: err.response
        });
      });
  };
};

export const getRewardDiscountCode = (id) => {
  console.log('getRewardDiscountCode',id);
  return function(dispatch){
    rewardDiscountCodeService.getRewardDiscountCode(id)
      .then(function(res){
        console.log(res.data);
        dispatch({
          type: constants.admin.REWARD_DISCOUNT_CODE_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_DISCOUNT_CODE_GET,
          payload: err.response
        });
      });
  };
};
