import React from 'react';
import {authService} from '../../../../utils/services/auth';
import trackingService from '../../../../utils/services/tracking';
import userService from '../../../../utils/services/user';
import formHelper from '../../../../utils/helpers/form';
import {updateLoginCtx} from '../../../../actions';
import store from '../../../../stores/store';
import FrontPageHeader from '../../../shared/frontpage/FrontPageHeader';
import FrontPageFooter from '../../../shared/frontpage/FrontPageFooter';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import * as onBoardingActions from '../../../../actions/user/onBoardingActions';
import config from '../../../../config/config';
import {connect} from 'react-redux';

@connect()
class Register extends React.Component {

  constructor(){
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getCurrent = this.getCurrent.bind(this);
  }

  handleSubmit(e){
    e.preventDefault();
    var that = this;

    var data = {
      firstName: that.firstName.value,
      lastName: that.lastName.value,
      email: that.userEmail.value,
      password: that.password.value
    };

    if(formHelper.formValid(data)){
      userService.registerUser(data)
        .then((res) => {
          if(res.data && res.data._id){
            authService.login(res.data.email, that.password.value).then(() => {

              userService.getCurrent()
                .then(function(res){
                  store.dispatch(updateLoginCtx(res.data));
                  store.dispatch(onBoardingActions.updateOnBoardingStatus(true));

                  trackingService.register(res.data , 'form');

                  that.props.router.push({ pathname: '/user/dashboard' });
                });
            }).catch((err) => {
              that.props.dispatch(globalMessageActions.showMessage(
                {
                  type: 'error',
                  message: err.response.data.message,
                  timeout: 10000
                }
              ));
            });
          }
        })
        .catch((err)=>{
          that.props.dispatch(globalMessageActions.showMessage(
            {
              type: 'error',
              message: err.response.data.message,
              timeout: 10000
            }
          ));
        });
    }else{
      this.setState({
        showMessage: true,
        message: 'Please ensure all fields are filled in correctly.'
      });
    }

  }

  componentWillMount(){
    this.state = {
      showMessage: false,
      message: ''
    };
  }

  goToPage(page){
    this.props.router.push({ pathname: page });
  }

  socialLogin(platform){

    this.setState({
      loginPlatform: platform
    });

    window.open(
      `${config.API.BASEURL}/auth/${platform}/login`,
      'targetWindow',
      'toolbar=no,' +
      'location=no,' +
      'status=no,' +
      'menubar=no,' +
      'scrollbars=yes,' +
      'resizable=yes,' +
      'width=550,' +
      'height=700');
    return false;
  }

  getCurrent(){
    var that = this;
    userService.getCurrent()
      .then(function(res){
        that.props.dispatch(onBoardingActions.updateOnBoardingStatus(true));
        that.props.dispatch(updateLoginCtx(res.data));

        trackingService.register(res.data,that.state.loginPlatform);

        this.props.router.push({ pathname: '/user/dashboard' });
      });
  }

  showError(e){
    this.props.dispatch(this.state.globalMessageActions.showMessage(
      {
        type: 'error',
        message: e.target.getAttribute('data-error'),
        timeout: 10000
      }
    ));
  }

  render () {
    return (
      <div className="front-page grid-container grid-parent">
        <FrontPageHeader/>
        <div className="grid-100 front-page-mid-section grid-parent">
          <div className="grid-100 front-page-form-holder text-center">
            <div className="form-container">
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <h2>Sign up</h2>
                <div className="login-social-icons">
                  <p className="connect-cta">Connect With</p>
                  <a className="facebook" onClick={() => this.socialLogin('facebook')}>
                    <i className="fa fa-facebook facebook"></i>
                  </a>
                  <a className="twitter" onClick={() => this.socialLogin('twitter')}>
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a className="google" onClick={() => this.socialLogin('google')}>
                    <i className="fa fa-google"></i>
                  </a>
                </div>
                <div className="section-separator">
                  <p className="connect-cta">Or</p>
                  <hr/>
                </div>
                <div className="front-page-input-holder">
                  <input required="true" ref={(input) => this.firstName = input} type="text" placeholder="Firstname"/>
                  <i className="input-icon fa fa-user"></i>
                </div>
                <div className="front-page-input-holder">
                  <input  required="true" ref={(input) => this.lastName = input} type="text" placeholder="Lastname"/>
                  <i className="input-icon fa fa-user"></i>
                </div>
                <div className="front-page-input-holder">
                  <input  required="true" ref={(input) => this.userEmail = input} type="email" placeholder="Email"/>
                  <i className="input-icon fa fa-envelope"></i>
                </div>
                <div className="front-page-input-holder">
                  <input  required="true" ref={(input) => this.password = input} type="password" placeholder="Password"/>
                  <i className="input-icon fa fa-lock"></i>
                </div>
                <p className="terms-and-conditions"><input type="checkbox" required={true}></input>Agree to our <a target="_blank" href="https://soreto.com/terms-and-conditions/">terms and conditions</a></p>
                <div>
                  <button className="primary">Sign up</button>
                </div>
              </form>
              <div className="already-have-account-section">
                <hr/>
                <p>Already Have an account?</p>
                <button onClick={() => this.goToPage('/login')} className="primary grey">Login</button>
              </div>
              <div className="hide-on-desktop hide-on-tablet hide-on-mobile">
                <div>
                  <button onClick={() => this.getCurrent() } id="get-current">Close</button>
                </div>
                <div>
                  <button onClick={(e) => this.showError(e) } id="error-show">Error show</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FrontPageFooter/>
      </div>
    );
  }
}

export default (Register);