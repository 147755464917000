import RewardDiscountCodeList from './List';
import RewardDiscountCodeCreate from './Create';
import RewardDiscountCodeEdit from './Edit';
import RewardDiscountCodeImport from './Import';

const RewardDiscountCodeIndex = {
  RewardDiscountCodeList, 
  RewardDiscountCodeCreate,
  RewardDiscountCodeEdit,
  RewardDiscountCodeImport
};

export default RewardDiscountCodeIndex;