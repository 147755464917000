import request from '../wrappers/axios';
import config from '../../config/config';

class ListStatisticsService {

  getClientListStatistics(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/clients/all/stats${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getClientStatistics(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/client/stats${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getClientStatistics_bi(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/reports/bi/stats${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

}

export default new ListStatisticsService();