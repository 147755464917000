import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';
import _ from 'lodash';


export  const getSharedUrlsAccess = (userId, query) => {
  return function(dispatch){
    userService.getSharedUrlsAccess(userId, query).then((response) => {
      dispatch({
        type: constants.user.SHAREDURLS_GET, payload: response.data
      });
    });
  };
};


export const getSharedUrlsCountClick = (userId, query) => {
  return function(dispatch){
    userService.getSharedUrlsCountClick(userId, query).then((response) => {
      var clicks = response.data;
      dispatch({
        type: constants.user.SHAREDURLS_COUNTCLICKS, payload: clicks
      });
    });
  };
};


export const getEarningsPerSocialPlatform = (userId , query) => {
  return function(dispatch){
    userService.getEarningsPerSocialPlatform(userId , query).then((response) => {
      var earnings = response.data;
      dispatch({
        type: constants.user.DASHBOARD_EARNINGSPERPLATFORM , payload : earnings
      });
    });
  };
};