import _ from 'lodash';

import codeBlockService from '../../../../../../utils/services/codeBlock';
import displayBlockService from '../../../../../../utils/services/displayBlock';

const getDisplayBlock = campaignVersionId => displayBlockService.getAllDisplayBlocks(campaignVersionId)
  .then(response => response.data)
  .then(data => data && data.map(item => _.pick(item, ['_id', 'name', 'type', 'active', 'archived'])));

const getCodeBlock = displayBlockId => codeBlockService.getCodeBlockList({displayBlockId_$: displayBlockId})
  .then(response => response.data && response.data.page);

const withCodeBlock = displayBlock => getCodeBlock(displayBlock._id)
  .then(codeBlock => ({...displayBlock, codeBlock: codeBlock && codeBlock[0]}));

const mapPlacement = placement => ({
  _id: placement._id,
  name: placement.name,
  codeBlockId: placement.codeBlock._id,
  type: placement.type

});

export const getData = campaignVersionId => getDisplayBlock(campaignVersionId)
  .then(displayBlocks => {
    const promises = displayBlocks && displayBlocks.map(withCodeBlock);
    if(!promises) return [];
    return Promise.all(promises)
      .then(_.flatten)
      .then(data => data.map(mapPlacement));
  });