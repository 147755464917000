import React from 'react';
import {authServiceUser} from '../../../../utils/services/auth';
import trackingService from '../../../../utils/services/tracking';
import FrontPageHeader from '../../../shared/frontpage/FrontPageHeader';
import FrontPageFooter from '../../../shared/frontpage/FrontPageFooter';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import {connect} from 'react-redux';

@connect()

class AccountVerify extends React.Component {
  constructor(){
    super();
    this.state = {
      verified: false
    };

    this.goToPage = this.goToPage.bind(this);
  }

  componentWillMount () {
    var query = this.props.location.query;

    this.setState({
      update: parseInt(query.update) === 1 ? true : false,
      verificationMessage: 'Verifying...'
    },function(){

      if (!this.state.update) {
        this.verify();
      }

    });

  }

  updateDetails (e) {
    e.preventDefault();
    var updatePayload = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      password: this.password.value,
      passwordConfirm: this.passwordConfirm.value
    };

    this.verify(updatePayload);
  }

  verify (updatePayload) {
    var that = this;
    var query = this.props.location.query;
    var userId = query.userId;
    var token = query.token;

    authServiceUser.verify(userId, token, updatePayload).then(() => {

      that.state.update ? trackingService.accountActivate(userId) : trackingService.accountVerify(userId);

      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'success',
          message: 'Your account has been verified',
          timeout: 10000
        }
      ));
      that.setState({
        verified: true
      });
    }).catch((err) => {
      that.setState({
        verificationMessage: err.response.data.message
      });
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: err.response.data.message,
          timeout: 10000
        }
      ));
    });
  }

  goToPage(page){
    this.props.router.push({ pathname: page });
  }

  render () {
    return(
      <div className="front-page grid-container grid-parent">
        <FrontPageHeader/>
        <div className="grid-100 front-page-mid-section grid-parent">
          <div className="grid-100 front-page-form-holder text-center">
            {
              this.state.verified ?
                <div className="form-container">
                  <h2>Account Activation</h2>
                  <p className="top-text">Your account has been verified</p>
                  <div>
                    <button onClick={() => this.goToPage('/login')} className="primary">Login</button>
                  </div>
                </div>
                :
                <div className="form-container">
                  {
                    this.state.update ?
                      <div>
                        <form onSubmit={(e) => this.updateDetails(e)}>
                          <h2>Account Activation</h2>
                          <p className="top-text">Fill your details in below to activate your account</p>
                          <div className="front-page-input-holder">
                            <input required="true" ref={(input) => this.firstName = input} type="text" placeholder="Firstname"/>
                            <i className="input-icon fa fa-user"></i>
                          </div>
                          <div className="front-page-input-holder">
                            <input  required="true" ref={(input) => this.lastName = input} type="text" placeholder="Lastname"/>
                            <i className="input-icon fa fa-user"></i>
                          </div>
                          <div className="front-page-input-holder">
                            <input  required="true" ref={(input) => this.password = input} type="password" placeholder="Password"/>
                            <i className="input-icon fa fa-lock"></i>
                          </div>
                          <div className="front-page-input-holder">
                            <input  required="true" ref={(input) => this.passwordConfirm = input} type="password" placeholder="Confirm Password"/>
                            <i className="input-icon fa fa-lock"></i>
                          </div>
                          <div>
                            <button className="primary">Submit</button>
                          </div>
                        </form>
                      </div>
                      :
                      <div>
                        <h2>Account Activation</h2>
                        <p className="top-text">{ this.state.verificationMessage }</p>
                        <button onClick={() => this.goToPage('/register')} className="primary">Create a new Account</button>
                      </div>
                  }
                </div>
            }
          </div>
        </div>
        <FrontPageFooter/>
      </div>
    );
  }
}

export default AccountVerify;