import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';

export const getPaymentMethods = (userId) => {
  return function(dispatch){
    userService.getPaymentMethods(userId)
      .then(function(res){
        dispatch({
          type: constants.user.PAYMENTMETHOD_POPULATE,
          payload: res.data
        });
      });
  };
};