const validImageFormats = ['image/jpeg','image/jpg','image/gif','image/png'];

export default {
  imageIsValid: (element) => {
    var el = element;
    var file = (el.files ? el.files[0] : el.value || undefined), supportedFormats = validImageFormats;
    if (file && file.type) {
      if (0 > supportedFormats.indexOf(file.type)) {
        return false;
      }
      return true;
    }
  },
  imageFileIsValid: (file) => {
    if(0 > validImageFormats.indexOf(file.type)){
      return false;
    }
    return true;
  },
  imageSizeIsValid(imageSize , maxSize){
    return imageSize < maxSize ? true : false;
  }
};