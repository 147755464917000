import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import moment from 'moment';

import userManagementService from '../../../../utils/services/userManagement';
import RolesDropbox from '../../../shared/dropbox/RolesDropbox';
import './List.scss';

export default class UserManagementList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roleList: [],
      startDate: moment()
        .subtract(365, 'd')
        .toDate(),
      endDate: moment().toDate(),
      filterByRoleField: [],
      searchInputField: '',
      searchClientInputField: ''
    };

    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.export = this.export.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onCreateUserManagement = this.onCreateUserManagement.bind(this);
    this.onEditUser = this.onEditUser.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  export() {
    this.dt.exportCSV();
  }

  resetFilter(event) {
    this.setState(
      {
        startDate: moment()
          .subtract(365, 'd')
          .toDate(),
        endDate: moment().toDate(),
        filterByRoleField: [],
        searchInputField: ''
      },
      () => this.onLazyLoad(event)
    );
  }

  getQuery(event) {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };

    if (this.state.filterByRoleField && this.state.filterByRoleField.length > 0) {
      const value = { $filterByRole: this.state.filterByRoleField };
      query = Object.assign(query, value);
    }

    if (this.state.searchInputField && this.state.searchInputField !== '') {
      const value = { $search: this.state.searchInputField };
      query = Object.assign(query, value);
    }

    /*
     *  Removed this code to contemplate SBR-521. Need to check the performance on production environment, and after that we can definitely get rid of it  
     *  
    var gteDate = this.state.startDate ? { $createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD') } : {};
    var lteDate = this.state.endDate
      ? {
        $createdAt_$lte: moment(this.state.endDate)
          .add(1, 'd')
          .format('YYYY-MM-DD')
      }
      : {};
    query = Object.assign(query, gteDate);
    query = Object.assign(query, lteDate);
    */

    return query;
  }

  getExtendedQuery(event) {
    var extendedQuery = '?';
    if (event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for (let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-'}${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
    for (let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');
      row.expiry = moment(row.expiry).format('YYYY-MM-DD');
    }

    this.setState({
      roleList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;


    let currentEvent = null;
    if(event.rows) {
      currentEvent = event;
      this.setState({lazyLoadEvent: event});
    } else {
      currentEvent = this.state.lazyLoadEvent;
    }

    this.setState({ loading: true });

    userManagementService.getUserManagementList(this.getQuery(currentEvent), this.getExtendedQuery(currentEvent)).then(data => {
      this.formatData(data);
    });
  }

  onCreateUserManagement() {
    this.props.router.push({ pathname: '/admin/userManagement/create' });
  }

  onEditUser(e, rowData) {
    this.props.router.push({ pathname: '/admin/userManagement/' + rowData._id });
  }

  actionTemplate(rowData) {
    return (
      <div>
        <Button
          type="button"
          icon="fa-edit"
          className="ui-button-warning"
          onClick={e => {
            this.onEditUser(e, rowData);
          }}
        />
      </div>
    );
  }

  columns() {
    return [
      { field: 'firstName', header: 'First Name', sortable: true, style: { width: '80px' } },
      { field: 'lastName', header: 'Last Name', sortable: true, style: { width: '80px' } },
      { field: 'roleNames', header: 'Roles', sortable: true, style: { width: '45px' } },
      { field: 'email', header: 'Email', sortable: true, style: { width: '80px' } },
      { field: 'clientName', header: 'Client', sortable: true, style: { width: '80px' } },
      { field: 'editIcon', style: { textAlign: 'center', width: '30px' } }
    ];
  }

  renderDataTable() {
    const columns = this.columns();

    const dataTableColumns = columns.map((col, i) => {
      if (col.field === 'editIcon') {
        return (
          <Column
            key={i}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            style={col.style}
            body={this.actionTemplate}
          />
        );
      } else {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} />;
      }
    });

    return (
      <DataTable
        value={this.state.roleList}
        responsive={true}
        sortMode="multiple"
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        loading={this.state.loading}
        ref={el => (this.dt = el)}
        first = {this.state.first}
      >
        {dataTableColumns}
      </DataTable>
    );
  }

  render() {
    const rolesDropboxStyle = { marginLeft: '0', width: '171px' };

    return (
      <div>
        <div className="content-section implementation">
          <h3>User Management</h3>

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText
                  onChange={e => this.setState({ searchInputField: e.target.value })}
                  value={this.state.searchInputField}
                />
              </span>
            </div>
          </div>

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2 roleLabel">
              <span>Filter by Role:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <RolesDropbox
                  onChange={e => this.setState({ filterByRoleField: e })}
                  value={this.state.filterByRoleField}
                  className="listRolesDropbox"
                  style={rolesDropboxStyle}
                />
              </span>
            </div>
          </div>

          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Create User" onClick={this.onCreateUserManagement} />
              </div>
            </div>
          </div>

          {this.renderDataTable()}
        </div>
      </div>
    );
  }
}
