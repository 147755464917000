import request from '../wrappers/axios';
import config from '../../config/config';

class RewardService {

  getRewardDiscountCodeList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/rewardDiscountCode/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getValidRewardDiscountCode(rewardId){
    return request({
      url: `${config.API.BASEURL}/rewardDiscountCode/${rewardId}/validDiscountCode`,
      method: 'get'
    });
  }

  batchInsertRewardDiscountCode(rewardId, payload){
    return request({
      url: `${config.API.BASEURL}/rewardDiscountCode/reward/${rewardId}`,
      method: 'post',
      data: payload
    }, true, 500)
      .catch(error =>  error.response.data);
  }

  newRewardDiscountCode (data) {
    return request({
      url: `${config.API.BASEURL}/rewardDiscountCode`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateRewardDiscountCode (data) {
    return request({
      url: `${config.API.BASEURL}/rewardDiscountCode/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getRewardDiscountCode (rewardId) {
    return request({
      url: `${config.API.BASEURL}/rewardDiscountCode/${rewardId}`,
      method: 'get'
    });
  }
}

export default new RewardService();