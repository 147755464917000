import React from 'react';
import classnames from 'classnames';

import campaignService from '../../../utils/services/campaign';

import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class CampaignDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.campaigns = [];
    this.getCampaigns = this.getCampaigns.bind(this);
    
    this.state = {
      campaign: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.client !=nextProps.client){
      if(nextProps.client){
        this.getCampaigns(nextProps.client);
      }
    }
  }

  componentWillReceiveProps(nextProps) {

    if ( !nextProps.client || typeof nextProps.value === 'object') {
      this.setState({ campaign: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ campaign: nextProps.value });
    }
  }

  getCampaigns(clientId) {
    campaignService.getAllCampaigns(clientId).then((data) => {
      if(data.data) {
        this.campaigns = data.data
          .filter(item => item.archived !== true)
          .map(campaign => ({ label: campaign.description, value: campaign._id }));
      }
      else {
        this.campaigns = null;
      }
      this.forceUpdate();
    });
  }


  campaignTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span><b>Name:</b> {option.label}</span>
          <div><b>ID:</b> {option.value}</div>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ campaign: e.value });
    this.props.onChange(e);
  }

  render() {

    const { label, required, className, error, flex, inline, disabled } = this.props;

    const cx = classnames('c-form-element', className, {
      'c-form-element__error': error,
      'c-form-element__flex': flex,
      'c-form-element--inline': inline
    });

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label} 
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>

        { showlabel }

        <Dropdown value={this.state.campaign} options={this.campaigns} 
          showClear
          disabled={disabled}
          onChange={this.onChange} itemTemplate={this.campaignTemplate} 
          style={{ marginLeft: '5px', width:'300px', marginTop: '4px' }} 
          filter={true} filterPlaceholder="Select Campaign" filterBy="label,value" 
          placeholder="Select a Campaign"/>
      </div>
      
    );
  }
}
