import constants from '../../constants/app-constants';

const initialState = {
  transactions: null
};

const UserTransactions = (state = initialState , action) => {
  switch(action.type){
  case constants.user.TRANSACTIONS_POPULATE: {
    return {...state , transactions: action.payload};
  }

  default: {
    return state;
  }
  }
};

export default UserTransactions;