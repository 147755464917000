import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';
import userHelper from '../../utils/helpers/user';

export const getTransactions = (userId , query) => {
  return function (dispatch){
    userService.getTransactions(userId , query).then(function(res){
      dispatch({
        type: constants.user.TRANSACTIONS_POPULATE,
        payload: res.data ? userHelper.getTransactions(res.data) : []
      });
    });
  };
};

export const updateTransactions = (transactions) => {
  return{
    type: constants.user.TRANSACTIONS_POPULATE,
    payload: transactions
  };
};