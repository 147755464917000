import request from '../wrappers/axios';
import config from '../../config/config';

class NotificationService {

  simulate(campaignVersionId, type, userEmail){
    return request({
      url: `${config.API.BASEURL}/notifications/simulate/campaignVersion/${campaignVersionId}/send`,
      method: 'post',
      data: {
        type,
        userEmail
      }
    });
  }
}

export default new NotificationService();