import React from 'react';
import async from 'async';

import clientService from '../../../../../utils/services/client';
import imageUploadHelper from '../../../../../utils/helpers/imageUpload';
import imageHelper from '../../../../../utils/helpers/image';
import sharePopupHelper from '../../../../../utils/helpers/sharePopup';
import urlHelper from '../../../../../utils/helpers/urlHelper';

import config from '../../../../../config/config';
import {connect} from 'react-redux';
import * as clientActions from '../../../../../actions/client/clientActions';
import * as settingsActions from '../../../../../actions/client/settingsActions';
import * as globalMessageActions from '../../../../../actions/globalMessageActions';


@connect((store)=>{
  return{
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    previews: store.clientSettings.previews,
    shareBgEnabled: store.clientSettings.shareBgEnabled
  };
})

class SharePopupCustom extends React.Component {

  constructor(){
    super();
    this.state = {
      previewLogo: false,
      previewBackground: false,
      currentlySaving: false
    };
  }

  componentWillMount () {
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
  }

  triggerFileOpen(e, type){
    e.preventDefault();
    document.getElementById(`file${type}`).click();
  }

  updateImagePreview(element , previewName , dimensions){
    var that = this;
    var files = this[element].files;


    if(!imageUploadHelper.imageFileIsValid(files[0])){
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Invalid Image format',
          timeout: 10000
        }
      ));
      this[element].value = '';
      return;
    }

    if(!imageUploadHelper.imageSizeIsValid(files[0].size , config.IMG.UPLOAD_LIMIT)){
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Maximum upload limit is 2MB',
          timeout: 10000
        }
      ));
      this[element].value = '';
      return;
    }

    var previewImage = new Image();
    previewImage.src = URL.createObjectURL(files[0]);
    previewImage.onload = function(){

      if(previewImage.width != previewImage.height || previewImage.width < dimensions || previewImage.height < dimensions){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: `Images must be square and have a minimum height and width of ${dimensions}px`,
            timeout: 10000
          }
        ));
        that[element].value = '';
        return;
      }

      var newState = {};
      newState[previewName] = URL.createObjectURL(files[0]);

      that.setState(newState);

      return;
    };



  }

  generatePreview (e) {
    e.preventDefault();
    var background = this.state.previewBackground || (this.props.clientObj.meta.sharePopup.backgroundImageId ? imageHelper.getImageUrl(this.props.clientObj.meta.sharePopup.backgroundImageId) : false);
    var logo = this.state.previewLogo || (this.props.clientObj.imageId ? imageHelper.getImageUrl(this.props.clientObj.imageId) : false) || '/images/holding-logo.png';
    var title = encodeURIComponent(this.shareTitle.value);
    var text1 = encodeURIComponent(this.shareText1.value);
    var text2 = encodeURIComponent(this.shareText2.value);
    var headingText = encodeURIComponent(this.headingText.value);
    var headingLinkText = encodeURIComponent(this.headingLinkText.value);
    var headingLinkUrl = encodeURIComponent(this.headingLinkUrl.value);
    var googleFontLink = encodeURIComponent(this.googleFontLink.value);
    var fontSize = encodeURIComponent(this.fontSize.value);
    var headingFontSize = encodeURIComponent(this.headingFontSize.value);
    var titleFontSize = encodeURIComponent(this.titleFontSize.value);

    var showBackground = this.props.shareBgEnabled;

    window.open(`${config.URL.BASEFRONT}/sharepopup.html#/?preview=1&clientId=${this.props.clientObj._id}&background=${background}&logo=${logo}&title=${title}&text1=${text1}&text2=${text2}&showBackground=${showBackground}&headingText=${headingText}&headingLinkText=${headingLinkText}&headingLinkUrl=${headingLinkUrl}&googleFontLink=${googleFontLink}&fontSize=${fontSize}&headingFontSize=${headingFontSize}&titleFontSize=${titleFontSize}`, 'Lissi Share!', 'width=390,height=380');
  }

  handleSubmit (e) {
    e.preventDefault();
    var that = this;

    if(!sharePopupHelper.validGoogleUrl(that.googleFontLink.value)){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Please enter a valid Google Fonts Url',
          timeout: 10000
        }
      ));
      return;
    }

    if(!sharePopupHelper.validFontSize(that.fontSize.value)){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Invalid Font Size. Please enter numbers only',
          timeout: 10000
        }
      ));
      return;
    }

    if(!sharePopupHelper.validFontSize(that.titleFontSize.value)){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Invalid Font Size. Please enter numbers only',
          timeout: 10000
        }
      ));
      return;
    }

    if(!sharePopupHelper.validFontSize(that.headingFontSize.value)){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Invalid Font Size. Please enter numbers only',
          timeout: 10000
        }
      ));
      return;
    }

    if(that.headingLinkUrl.value && !urlHelper.validateUrl(that.headingLinkUrl.value)){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Please enter a valid url',
          timeout: 10000
        }
      ));
      return;
    }

    if(that.shareTitle.value.length > 20){
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Maximum of 20 characters allowed. Your shared title is ' + that.shareTitle.value.length + ' characters long.',
          timeout: 10000
        }
      ));
      return;
    }
    else if(that.shareText1.value.length > 45){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Maximum of 45 characters allowed. Your shared text line 1 is ' + that.shareText1.value.length + ' characters long.',
          timeout: 10000
        }
      ));
      return;
    }
    else if(that.shareText2.value.length > 45){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Maximum of 45 characters allowed. Your shared text line 2 is ' + that.shareText2.value.length + ' characters long.',
          timeout: 10000
        }
      ));
      return;
    }
    else{
      that.setState({
        currentlySaving: true
      });

      async.auto({

        uploadLogo: function(next){
          if(that.fileLogo.value && that.fileLogo != ''){
            var data = new FormData();
            data.append('file', that.fileLogo.files[0]);

            clientService.uploadImage(data)
              .then(function(response){
                return next(null, response.data);
              })
              .catch(function(err){
                return next(err);
              });
          }else{
            next(null);
          }
        },
        uploadBackgroundImage: function(next){
          if(that.fileBg.value && that.fileBg != ''){
            var data = new FormData();
            data.append('file', that.fileBg.files[0]);

            clientService.uploadImage(data)
              .then(function(response){
                return next(null, response.data);
              })
              .catch(function(err){
                return next(err);
              });
          }else{
            next(null);
          }
        },
        updateClient: ['uploadLogo','uploadBackgroundImage',function(results,next){

          var updatePayload = {meta: that.props.clientObj.meta};
          if (results.uploadLogo) updatePayload['imageId'] = results.uploadLogo.fileId;
          if (results.uploadBackgroundImage) updatePayload.meta.sharePopup.backgroundImageId = results.uploadBackgroundImage.fileId;
          updatePayload.meta.sharePopup.title = that.shareTitle.value;
          updatePayload.meta.sharePopup.text1 = that.shareText1.value;
          updatePayload.meta.sharePopup.text2 = that.shareText2.value;
          updatePayload.meta.sharePopup.backgroundEnabled = that.props.shareBgEnabled;
          updatePayload.meta.sharePopup.headingText = that.headingText.value;
          updatePayload.meta.sharePopup.headingLinkText = that.headingLinkText.value;
          updatePayload.meta.sharePopup.headingLinkUrl = that.headingLinkUrl.value;
          updatePayload.meta.sharePopup.googleFontLink = that.googleFontLink.value;
          updatePayload.meta.sharePopup.fontSize = that.fontSize.value;
          updatePayload.meta.sharePopup.headingFontSize = that.headingFontSize.value;
          updatePayload.meta.sharePopup.titleFontSize = that.titleFontSize.value;

          clientService.updateClient(that.props.clientObj._id, updatePayload)
            .then(function(response){
              that.setState({
                currentlySaving: false
              });

              that.props.dispatch(clientActions.updateClient(response.data));
              that.props.dispatch(globalMessageActions.showMessage(
                {
                  type: 'success',
                  message: 'Popup has been successfully updated',
                  timeout: 10000
                }
              ));
              next(null);
            })
            .catch(function(err){
              that.setState({
                currentlySaving: false
              });

              that.props.dispatch(globalMessageActions.showMessage(
                {
                  type: 'error',
                  message: err.response.data.message,
                  timeout: 10000
                }
              ));
              next(null);
            });

        }]
      })
    }
  }

  handleCheckboxChange(){
    this.props.dispatch(settingsActions.updateShareBgEnabled(!this.props.shareBgEnabled));
  }

  render () {
    return (
      <div className="sharepopup-custom">
        <h1>Share Popup</h1>
        {this.props.clientObj &&
        <div className="share-popup-holder">
          <form id="upload-form" className="form" onSubmit={(event) => {this.handleSubmit(event);}} accept="image/png, image/jpeg , image/gif ,image/jpg ">
           <div className="grid-50 tablet-grid-100 settings-left-side">
             <h4 className="active">Logo</h4>
             <div className="profile-pic-holder grid-20">
               <img className="profile-pic" id="shareLogo"
                 src={this.state.previewLogo ||
               (this.props.clientObj.imageId ? imageHelper.getImageUrl(this.props.clientObj.imageId) : false) ||
               '/images/holding-logo.png'} alt=""/>
             </div>
             <div className="clearfix"></div>
             <div className="file-controller-holder">
               <label className="file-controller" >
                 <input onChange={() => this.updateImagePreview('fileLogo' , 'previewLogo' , 150)} ref={(file) => this.fileLogo = file} id="fileLogo" type="file" style={{display:'none'}} />
                 <button className="primary edit-button" onClick={(event) => {this.triggerFileOpen(event, 'Logo');}}>Edit</button>
               </label>
             </div>
             <div className="allowed-file-types">
               <p>JPG, GIF & PNG </p>
               <p>Upload image size</p>
               <p>150 x 150</p>
             </div>
           </div>
            <div className="grid-50 tablet-grid-100">
              <div className="background-upload-inner">
                <h4 className="active">Background</h4>
                <div className="file-controller-holder">
                  <label className="file-controller" >
                    <div className="left checkbox-holder"><input onChange={(e) => this.handleCheckboxChange(e) } type="checkbox" value={this.props.shareBgEnabled != null ? this.props.shareBgEnabled : true} checked={this.props.shareBgEnabled != null ? this.props.shareBgEnabled : true}/>Background Image</div>
                    <div className="left"><input onChange={() => this.updateImagePreview('fileBg','previewBackground',400) } ref={(file) => this.fileBg = file}  id="fileBg" type="file" style={{display:'none'}} /></div>
                    <div className="left background-image-holder">
                      <img className="background-image" src={this.state.previewBackground || (this.props.clientObj.meta.sharePopup.backgroundImageId ? imageHelper.getImageUrl(this.props.clientObj.meta.sharePopup.backgroundImageId) : false) || '/images/white-holding-image.jpg'} alt=""/>
                    </div>
                    <div className="left browse-button-holder"><button className="primary browse-button" onClick={(event) => {this.triggerFileOpen(event, 'Bg');}}>Edit</button></div>
                  </label>
                </div>
                <div className="clearfix"></div>
                <div className="allowed-file-types">
                  <p>JPG, GIF & PNG. Upload image size 400 x 400</p>
                  <p>By uploading this image, you clarify that you have the right to distribute this image and it is not pornographic</p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="grid-100">
              <div className="grid-100 grid-parent">
                <div className="grid-50">
                  <h4 className="active">Font</h4>
                  <input ref={(input) => this.googleFontLink = input}   className="share-input title" type="text" placeholder="Google Font Link" defaultValue={this.props.clientObj.meta.sharePopup.googleFontLink}/>
                  <br/><br/>
                  <input ref={(input) => this.fontSize = input}   className="share-input title" type="number" placeholder="General Font Size in pixels" defaultValue={this.props.clientObj.meta.sharePopup.fontSize}/>
                </div>
                <div className="grid-50">
                  <h4 className="active">Title</h4>
                  <input maxLength="20" ref={(input) => this.shareTitle = input}  className="share-input title" type="text" placeholder="Share title" defaultValue={this.props.clientObj.meta.sharePopup.title || 'share & earn'}/>
                  <br/><br/>
                  <input maxLength="20" ref={(input) => this.titleFontSize = input}  className="share-input title" type="number" placeholder="Title font size in pixels" defaultValue={this.props.clientObj.meta.sharePopup.titleFontSize}/>
                </div>
              </div>
              <div className="grid-100 grid-parent">
                <div className="grid-50">
                  <h4 className="active">Heading</h4>
                  <input ref={(input) => this.headingText = input}   className="share-input title" type="text" placeholder="Heading Text" defaultValue={this.props.clientObj.meta.sharePopup.headingText || 'powered by'}/>
                  <br/><br/>
                  <input ref={(input) => this.headingLinkText = input}   className="share-input title" type="text" placeholder="Heading link text" defaultValue={this.props.clientObj.meta.sharePopup.headingLinkText || 'soreto'}/>
                  <br/><br/>
                  <input ref={(input) => this.headingLinkUrl = input}   className="share-input title" type="text" placeholder="Heading link url" defaultValue={this.props.clientObj.meta.sharePopup.headingLinkUrl || 'https://soreto.com/'}/>
                  <br/><br/>
                  <input ref={(input) => this.headingFontSize = input}   className="share-input title" type="number" placeholder="Heading font size in pixels" defaultValue={this.props.clientObj.meta.sharePopup.headingFontSize}/>
                </div>
                <div className="grid-50">
                  <h4 className="active">Text</h4>
                  <input maxLength="45" ref={(input) => this.shareText1 = input} className="share-input" defaultValue={this.props.clientObj.meta.sharePopup.text1 || 'Would your friends love this purchase?'} type="text" placeholder="Share text line 1"/><br/><br/>
                  <input maxLength="45" ref={(input) => this.shareText2 = input}  className="share-input" type="text" defaultValue={this.props.clientObj.meta.sharePopup.text2|| 'Share it now. If they buy, you can earn ££.'} placeholder="Share text line 2"/>
                </div>
              </div>
              <div className="grid-100">
                <div className="clearfix"></div>
                <div className="text-right settings-actions-button">
                  <button className="secondary" onClick={(event) => {this.generatePreview(event);}}>Preview</button>
                  <button type="submit" className="primary">Save</button>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            {
              this.state.currentlySaving ?
                <div className="loading-gif text-center">
                  <img src="/images/loading-gif.gif"/>
                </div>
                :
                false
            }
          </form>
        </div>}
      </div>
    );
  }
}

export default SharePopupCustom;