import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';

export const getUser = (userId) => {
  return function(dispatch){
    userService.getUser(userId)
      .then(function(res){
        dispatch({
          type: constants.user.USEROBJ_GET,
          payload: res.data
        });
      })
      .catch(function(){

      });
  };
};

export const updateUser = (user) => {
  return {
    type: constants.user.USEROBJ_GET,
    payload: user
  };
};


export const getUserWallet = (userId) => {
  return function(dispatch){
    userService.getUserWallet(userId).then((response) => {
      dispatch(
        {
          type : constants.user.USERWALLET_GET ,
          payload: response.data
        }
      );
    });
  };
};

export const getUserWalletWithBalance = (userId) => {
  return function(dispatch){
    userService.getUserWalletWithBalance(userId).then((response) => {
      dispatch({
        type: constants.user.USERWALLETWITHBALANCE_GET,
        payload: response.data
      });
    });
  };
};