import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import CampaignTypeDataTable from '../campaignType/dataTable';
import Label from  '../../../../../shared/label/label';
import './style.css';

import {getData} from './data';

class CountryDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      countryList: [],
      clientId: this.props.clientId,
      ignoreArchived: this.props.ignoreArchived,
      campaignType: this.props.campaignType
    };
  }
  
  onLazyLoad = (event) => {

    if(event){
      this.state.first = isNaN(event.first) ? 0 : event.first;
    }
    
    this.setState({ loading: true});
    
    const {clientId, ignoreArchived, campaignType} = this.state;
    getData(clientId, ignoreArchived, campaignType)
      .then(data => this.setState({
        list: data,
        countryList: Object.keys(data).map(item => ({countryName: item})),
        loading: false
      }))
      .then(() => {
        if(this.state.countryList.length === 1) {
          this.setState({expandedRows: [this.state.countryList[0]]});
        }
      });
  }

  rowExpansionTemplate = data => <CampaignTypeDataTable list={this.state.list[data.countryName]} ignoreArchived={this.state.ignoreArchived} campaignType={this.state.campaignType}/>;

  componentDidMount = () => this.onLazyLoad();

  countryRowClass = (rowData) => {

    let { list } = this.state;

    let countryCampaings = list[rowData.countryName];

    let hasRunningCampaign = countryCampaings.some(c => c.running == true);
    let allInactive = !countryCampaings.some(c => c.active == true);

    return {
      'country-campaign-has-running' : hasRunningCampaign,
      'country-campaign-all-inactive' : allInactive,
      'country-campaign-warning': (!hasRunningCampaign && !allInactive),
      'countryNameHeader': true
    };    
  };

  countryBody = (rowData) => {

    let { list } = this.state;

    let countryCampaings = list[rowData.countryName];

    let hasSuperCampaign = countryCampaings.some(c => c.running && c.superCampaign);
    let warning = countryCampaings.some(c => c.active && c.expired);

    let sc = (<React.Fragment>{hasSuperCampaign &&  <Label color="blue" text="SC" />}</React.Fragment>);
    let wr = (<React.Fragment>{warning &&  <Label color="yellow" text="warning" textColor="black" />}</React.Fragment>);

    return (<div> <div>{rowData.countryName} {sc} {wr} </div><div></div> </div>);
  };

  render = () => 
    <div className="country-datatable">
      <h5 style={{marginTop: '5px', marginBottom:'5px'}}>Campaign</h5>
      <DataTable
        value={this.state.countryList}
        tableClassName="no-header"
        responsive={true}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        loading={this.state.loading}
        ref={(el) => this.dt = el}
        onRowToggle={e => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
        expandedRows={this.state.expandedRows}
        rowClassName={this.countryRowClass}
        first = {this.state.first}
      >
        <Column expander={true} style={{ width: '45px' }} />
        <Column body={this.countryBody}  />
      </DataTable> 
    </div>
}

export default CountryDataTable;