import request from '../wrappers/axios';
import config from '../../config/config';

class scssConversionService {

  convertCss (data) {
    return request({
      url: config.URL.SASS_API,
      method: 'post',
      data: data,
      withCredentials: false
    });
  }
}

export default new scssConversionService();