import React from 'react';
import ReportsMenu from './ReportsMenu';

class Reports extends React.Component{

  getReportsMenu(){
    return [
      {
        title: 'Product Conversion Report',
        code: 'productconversionreport',
        mobileIconClass: 'fa fa-percent'
      },
      {
        title: 'Social Traction Report',
        code: 'socialtractionreport',
        mobileIconClass: 'fa fa-industry'
      }
    ];
  }

  render(){
    return (
      <div className="reports-page grid-container grid-parent">
        <div className="menu-holder grid-25 tablet-grid-10 mobile-grid-20">
          <ReportsMenu menu={this.getReportsMenu()} />
        </div>
        <div className="reports-content grid-75 tablet-grid-90 mobile-grid-80">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Reports;