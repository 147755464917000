import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import ChartPanel from './Dashboard.ChartPanel';
import Profile from '../../shared/profile/Profile';

import * as userActions from '../../../../actions/user/userActions';
import * as dashboardActions from '../../../../actions/user/dashboardActions';
import OnBoarding from '../../shared/onBoarding/OnBoarding';

import chartHelper from '../../../../utils/helpers/chart';
import BigNumber from 'bignumber.js';
import Modal from 'react-modal';

import {connect} from 'react-redux';

@connect((store)=>{
  return{
    loginCtx: store.loginCtx.loginCtx,
    userObj: store.user.userObj,
    sharedUrls: store.userDashboard.sharedUrls,
    sharedUrlsClicks: store.userDashboard.sharedUrlsClicks,
    earningsPerSocialPlatform: store.userDashboard.earningsPerSocialPlatform,
    modalIsOpen : store.userDashboard.modalIsOpen,
    userWallet : store.userDashboard.userWallet,
    showOnboarding: store.userOnBoarding.show
  };
})
class Dashboard extends React.Component {

  constructor() {
    super();
    this.goToProductTractionReport = this.goToProductTractionReport.bind(this);
    this.goToSocialPost = this.goToSocialPost.bind(this);
  }

  componentWillMount () {
    this.props.dispatch(userActions.getUser(this.props.loginCtx._id));
    this.props.dispatch(dashboardActions.getSharedUrlsAccess(this.props.loginCtx._id , {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
    this.props.dispatch(dashboardActions.getSharedUrlsCountClick(this.props.loginCtx._id, {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
    this.props.dispatch(dashboardActions.getEarningsPerSocialPlatform(this.props.loginCtx._id, {$createdAt_$gt: moment().subtract(30, 'days').format('YYYY-MM-DD')}));
  }

  goToProductTractionReport(){
    this.props.router.push({ pathname: '/user/reports' });
  }

  goToSocialPost(){
    this.props.router.push({ pathname: '/user/socialpost' });
  }
  
  render () {
    var clickCountLabels = [];
    var clickCountData = [];
  
    _.each(this.props.sharedUrlsClicks, (value, key) => {
      clickCountLabels.push(key);
      clickCountData.push(value);
    });
    
    var earningsPerPlatformLabels = [];
    var earningsPerPlatformData = [];
    
    _.each(this.props.earningsPerSocialPlatform , (value , key) => {
      earningsPerPlatformLabels.push(key);
      earningsPerPlatformData.push(value);
    });
  
    var clicksChart = chartHelper.getChartObjClicks(clickCountLabels, clickCountData);
    var earningsPerPlatformChart = chartHelper.getChartObjEarningsPerPlatform(earningsPerPlatformLabels , earningsPerPlatformData);

    return (
      <div className='dashboard-user'>
        {this.props.userObj && <div className="grid-100">
         <Profile />
          <div className="grid-100 graph-holder grid-parent">
            <h4 className="text-center">Traction (Last 30 Days)</h4>
            <ChartPanel {...this.props} ref="chartPanel" revenueChart={earningsPerPlatformChart} clicksChart={clicksChart} />
          </div>
          <div className="grid-100 trending-data-holder">
            <h4 className="section-heading">Top 5 Shares</h4>
            {
              this.props.sharedUrls.length ?
            <div>
              <div className="table-holder">
                <table id="trending-table" className="responsive">
                  <thead>
                  <tr>
                    <th><i className="fa fa-link"></i> Item</th>
                    <th><i className="fa fa-calendar"></i> Date</th>
                    <th><i className="fa fa-hand-o-up"></i> Clicks</th>
                    <th><i className="fa fa-share"></i> Purchases</th>
                    <th><i className="fa fa-money"></i> Earnings</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    _.orderBy(this.props.sharedUrls, ['accesses.length'], ['desc']).slice(0,5).map((url) => {
                      return(
                        <tr key={url._id}>
                          <td className="text-left">
                            <div className="table-image-holder" style={{backgroundImage : url.productImage ? `url(${url.productImage})`  : 'url(/images/table-holding-image.jpg)'}}>
                            </div>
                            <a title={url.productTitle} className="truncate" href={url.productUrl} target='_blank'>{url.productTitle}</a>
                          </td>
                          <td>{new Date(url.createdAt).toDateString()}</td>
                          <td>{url.clicks}</td>
                          <td>{url.orders}</td>
                          <td>£{new BigNumber(url.displayEarnings).toFixed(2)}</td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
            </div>
              <div className="text-center"><button onClick={() => this.goToProductTractionReport()} className="primary">View More</button></div>
            </div>
              :
                <div className="no-data">
                  <p>There is currently no data</p>
                  <p>Share your first product now!</p>
                  <p onClick={() => this.goToSocialPost()}><button className="primary">Get Sharing</button></p>
                </div>
            }
          </div>
        </div>}
        <Modal className="onboarding-modal" contentLabel={'Onboarding'} isOpen={this.props.showOnboarding}>
          <OnBoarding />
        </Modal>
      </div>
    );
  }
}

export default Dashboard;