import constants from '../../constants/app-constants';

const initialState = {
  activity: null
};

const UserActivity = (state = initialState , action) => {
  switch(action.type){
  case constants.user.ACTIVITY_POPULATE: {
    return {...state , activity: action.payload};
  }

  default: {
    return state;
  }
  }
};

export default UserActivity;