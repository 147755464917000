export default {
  'GLOBALMESSAGE_SET' : 'GLOBALMESSAGE_SET',
  'LOAD_SPINNER_ENABLED' : 'LOAD_SPINNER_ENABLED',
  'LOCATION_CHANGE' : 'LOCATION_CHANGE',
  auth: {
    'AUTH_LOGIN': 'AUTH_LOGIN',
    'LOGOUT' : 'CLIENT_LOGOUT'
  },
  admin:{
    'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_ALL': 'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_ALL',
    'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_BY_ID' : 'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_BY_ID',
    'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_EDIT' : 'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_EDIT',
    'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_NEW' : 'ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_NEW',
    'ASSOC_CLIENT_AFFILIATE_GET_ALL': 'ASSOC_CLIENT_AFFILIATE_GET_ALL',
    'ASSOC_CLIENT_AFFILIATE_GET_BY_ID' : 'ASSOC_CLIENT_AFFILIATE_GET_BY_ID',
    'ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT' : 'ASSOC_CLIENT_AFFILIATE_GET_BY_CLIENT',
    'ASSOC_CLIENT_AFFILIATE_EDIT' : 'ASSOC_CLIENT_AFFILIATE_EDIT',
    'ASSOC_CLIENT_AFFILIATE_DELETE' : 'ASSOC_CLIENT_AFFILIATE_DELETE',
    'ASSOC_CLIENT_AFFILIATE_NEW' : 'ASSOC_CLIENT_AFFILIATE_NEW',
    'LOGINFORM_UPDATE': 'ADMIN_LOGINFORM_UPDATE',
    'LOGIN_CLEAR': 'ADMIN_LOGIN_CLEAR',
    'WITHDRAWALREQUESTS_POPULATE': 'ADMIN_WITHDRAWALREQUESTS_POPULATE',
    'GLOBALMESSAGE_SET' : 'GLOBALMESSAGE_SET',
    'CLIENTS_GET' : 'CLIENTS_GET',
    'CLIENTOBJ_GET': 'CLIENT_CLIENTOBJ_GET',
    'CLIENTOBJ_UPDATE': 'CLIENT_CLIENTOBJ_UPDATE',
    'CLIENT_NEW' : 'CLIENT_NEW',
    'CLIENT_EDIT' : 'CLIENT_EDIT',
    'CLIENT_GET' : 'CLIENT_GET',
    'CLIENTOBJ_UPDATE_STATUS' : 'CLIENTOBJ_UPDATE_STATUS',
    'CAMPAIGN_NEW' : 'CAMPAIGN_NEW',
    'CAMPAIGN_GET' : 'CAMPAIGN_GET',
    'CAMPAIGN_EDIT' : 'CAMPAIGN_EDIT',
    'CAMPAIGN_COPY' : 'CAMPAIGN_COPY',
    'AFFILIATE_NEW' : 'AFFILIATE_NEW',
    'AFFILIATE_GET' : 'AFFILIATE_GET',
    'AFFILIATE_EDIT' : 'AFFILIATE_EDIT',
    'CODE_BLOCK_NEW' : 'CODE_BLOCK_NEW',
    'CODE_BLOCK_GET' : 'CODE_BLOCK_GET',
    'CODE_BLOCK_EDIT' : 'CODE_BLOCK_EDIT',
    'CAMPAIGN_VERSION_NEW' : 'CAMPAIGN_VERSION_NEW',
    'CAMPAIGN_VERSION_EDIT' : 'CAMPAIGN_VERSION_EDIT',
    'CAMPAIGN_VERSION_GET' : 'CAMPAIGN_VERSION_GET',
    'CAMPAIGN_VERSION_LIST_BY_CAMPAIGN_ID_GET' : 'CAMPAIGN_VERSION_LIST_BY_CAMPAIGN_ID_GET',
    'CAMPAIGN_VERSION_LIST_EDIT': 'CAMPAIGN_VERSION_LIST_EDIT',
    'CAMPAIGN_VERSION_COPY': 'CAMPAIGN_VERSION_COPY',
    'DEMO_STORE_GET_BY_ID': 'DEMO_STORE_GET_BY_ID',
    'DEMO_STORE_EDIT': 'DEMO_STORE_EDIT',
    'DEMO_STORE_NEW': 'DEMO_STORE_NEW',
    'DISPLAY_BLOCK_NEW' : 'DISPLAY_BLOCK_NEW',
    'DISPLAY_BLOCK_GET' : 'DISPLAY_BLOCK_GET',
    'DISPLAY_BLOCK_EDIT' : 'DISPLAY_BLOCK_EDIT',
    'EMAIL_TEMPLATE_GET_ALL' : 'EMAIL_TEMPLATE_GET_ALL',
    'EMAIL_TEMPLATE_GET_BY_ID' : 'EMAIL_TEMPLATE_GET_BY_ID',
    'EMAIL_TEMPLATE_EDIT' : 'EMAIL_TEMPLATE_EDIT',
    'EMAIL_TEMPLATE_NEW' : 'EMAIL_TEMPLATE_NEW',
    'EMAIL_TEMPLATE_COPY' : 'EMAIL_TEMPLATE_COPY',
    'EMAIL_TEMPLATE_TYPE_GET_ALL' : 'EMAIL_TEMPLATE_TYPE_GET_ALL',
    'EMAIL_TEMPLATE_TYPE_GET_BY_ID' : 'EMAIL_TEMPLATE_TYPE_GET_BY_ID',
    'EMAIL_TEMPLATE_TYPE_EDIT' : 'EMAIL_TEMPLATE_TYPE_EDIT',
    'EMAIL_TEMPLATE_TYPE_NEW' : 'EMAIL_TEMPLATE_TYPE_NEW',
    'KEY_EMAIL_TEMPLATE_TYPE_GET_BY_ID':'KEY_EMAIL_TEMPLATE_TYPE_GET_BY_ID',
    'KEY_EMAIL_TEMPLATE_TYPE_GET':'KEY_EMAIL_TEMPLATE_TYPE_GET',
    'KEY_EMAIL_TEMPLATE_TYPE_UPDATE':'KEY_EMAIL_TEMPLATE_TYPE_UPDATE',
    'KEY_EMAIL_TEMPLATE_TYPE_NEW':'KEY_EMAIL_TEMPLATE_TYPE_NEW',
    'REWARD_NEW' : 'REWARD_NEW',
    'REWARD_GET' : 'REWARD_GET',
    'REWARD_GET_ALL' : 'REWARD_GET_ALL',
    'REWARD_EDIT' : 'REWARD_EDIT',
    'REWARD_POOL_NEW' : 'REWARD_POOL_NEW',
    'REWARD_POOL_GET' : 'REWARD_POOL_GET',
    'REWARD_POOL_EDIT' : 'REWARD_POOL_EDIT',
    'REWARD_DISCOUNT_CODE_NEW' : 'REWARD_DISCOUNT_CODE_NEW',
    'REWARD_DISCOUNT_CODE_GET' : 'REWARD_DISCOUNT_CODE_GET',
    'REWARD_DISCOUNT_CODE_EDIT' : 'REWARD_DISCOUNT_CODE_EDIT',
    'USER_MANAGEMENT_GET_BY_ID': 'USER_MANAGEMENT_GET_BY_ID',
    'USER_MANAGEMENT_EDIT': 'USER_MANAGEMENT_EDIT',
    'USER_MANAGEMENT_NEW': 'USER_MANAGEMENT_NEW',
    'LAYOUT_COUNTRIES_GET': 'LAYOUT_COUNTRIES_GET',
    'LAYOUT_COUNTRY_GET_BY_ID': 'LAYOUT_COUNTRY_GET_BY_ID',
    'LAYOUT_COUNTRY_SET': 'LAYOUT_COUNTRY_SET',
    'ORDER_GET_ALL': 'ORDER_GET_ALL',
    'ORDER_GET_HISTORY': 'ORDER_GET_HISTORY',
    'ORDER_EDIT': 'ORDER_EDIT'
  },
  client: {
    'LOGINFORM_UPDATE': 'CLIENT_LOGINFORM_UPDATE',
    'HEADER_TOGGLEMENU': 'CLIENT_HEADER_TOGGLEMENU',
    'CLIENTOBJ_GET': 'CLIENT_CLIENTOBJ_GET',
    'CLIENTOBJ_UPDATE': 'CLIENT_CLIENTOBJ_UPDATE',
    'DASHBOARD_SHAREDURLS_GET': 'CLIENT_SHAREDURLS_GET',
    'DASHBOARD_SHAREDURLS_COUNTCLICKS': 'CLIENT_SHAREDURLS_COUNTCLICKS',
    'DASHBOARD_SHAREDURLS_COUNTSHARES': 'CLIENT_SHAREDURLS_COUNTSHARES',
    'SHAREDURLS_GET': 'CLIENT_SHAREDURLS_GET',
    'PROFILE_TOGGLE_MODAL': 'CLIENT_PROFILE_TOGGLE_MODAL',
    'SHAREDURLS_COUNTCLICKS': 'CLIENT_SHAREDURLS_COUNTCLICKS',
    'SHAREDURLS_COUNTSHARES': 'CLIENT_SHAREDURLS_COUNTSHARES',
    'LOGIN_CLEAR': 'CLIENT_LOGIN_CLEAR',
    'UPDATELOGO_TOGGLEUPLOADMODAL' : 'CLIENT_UPDATELOGO_TOGGLEUPLOADMODAL',
    'SHAREPOPUP_UPDATEPREVIEWS': 'CLIENT_SHAREPOPUP_UPDATEPREVIEWS',
    'SETTINGS_UPDATESETTINGSMENU' : 'CLIENT_SETTINGS_UPDATESETTINGSMENU',
    'SETTINGS_UPDATECURRENTSETTINGSCOMPONENT' : 'CLIENT_SETTINGS_UPDATECURRENTSETTINGSCOMPONENT',
    'ENGAGEMENT_UPDATEPAGECOUNT' : 'CLIENT_ENGAGEMENT_UPDATEPAGECOUNT',
    'ENGAGEMENT_UPDATEPRODUCTS' : 'CLIENT_ENGAGEMENT_GETPRODUCTURLS',
    'SETTINGS_SHAREPOPUPCUSTOM_SETSHAREBGENABLED' : 'CLIENT_SETTINGS_SHAREPOPUPCUSTOM_SETSHAREBGENABLED',
    'SETTINGS_SHAREPOPUPCUSTOM_UPDATESHAREBGENABLED' : 'CLIENT_SETTINGS_SHAREPOPUPCUSTOM_UPDATESHAREBGENABLED',
    'SETTINGS_COMPANY_TOGGLELOCATIONMODAL' : 'CLIENT_SETTINGS_COMPANY_TOGGLELOCATIONMODAL',
    'SETTINGS_COMPANY_TOGGLELOGOMODAL' : 'CLIENT_SETTINGS_COMPANY_TOGGLELOGOMODAL',
    'DASHBOARD_UPDATEEARNINGSPERPLATFORM' : 'CLIENT_DASHBOARD_UPDATEEARNINGSPERPLATFORM',
    'ACTIVITY_UPDATECLIENTACTIVITY' : 'CLIENT_ACTIVITY_UPDATECLIENTACTIVITY',
    'REPORTS_DAILYTRACTION_FETCHREPORT': 'CLIENT_REPORTS_DAILYTRACTION_FETCHREPORT',
    'REPORTS_DAILYTRACTION_UPDATEPAGECOUNT' : 'CLIENT_REPORTS_DAILYTRACTION_UPDATEPAGECOUNT',
    'BY_CHANNEL_TOTAL_CLIENT_STATS_BY_PERIOD_GET' : 'BY_CHANNEL_TOTAL_CLIENT_STATS_BY_PERIOD_GET',
    'BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_GET' : 'BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_GET',
    'BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_BI_GET' : 'BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_BI_GET',
    'CLIENT_STATS_GET' : 'CLIENT_STATS_GET',
    'TOGGLE_CALENDAR' : 'TOGGLE_CALENDAR'
  },
  user: {
    'LOGINFORM_UPDATE': 'USER_LOGINFORM_UPDATE',
    'REGISTERFORM_UPDATE': 'USER_REGISTERFORM_UPDATE',
    'VERIFYFORM_UPDATE': 'USER_VERIFYFORM_UPDATE',
    'PROFILE_TOGGLE_MODAL': 'USER_PROFILE_TOGGLE_MODAL',
    'HEADER_TOGGLEMENU': 'USER_HEADER_TOGGLEMENU',
    'LOGIN_CLEAR': 'USER_LOGIN_CLEAR',
    'SOCIALPOST_SETUSERSOCIALPLATFORMS': 'USER_SOCIALPOST_SETUSERSOCIALPLATFORMS',
    'SOCIALPOST_UPDATECURRENTLYPOSTINGSTATUS': 'USER_SOCIALPOST_UPDATECURRENTLYPOSTINGSTATUS',
    'SOCIALPOST_UPDATESOCIALPLATFORMSTATUS': 'USER_SOCIALPOST_UPDATESOCIALPLATFORMSTATUS',
    'SOCIALPOST_SETCONNECTMODALSTATUS' : 'USER_SOCIALPOST_SETCONNECTMODALSTATUS',
    'USEROBJ_GET': 'USER_USEROBJ_GET',
    'DASHBOARD_TOGGLEUPLOADMODAL' : 'USER_DASHBOARD_TOGGLEUPLOADMODAL',
    'SHAREDURLS_GET' : 'USER_SHAREDURLS_GET',
    'SHAREDURLS_COUNTCLICKS' : 'USER_SHAREDURLS_COUNTCLICKS',
    'SHAREDURLS_EARNINGSPERPLATFORM' : 'USER_SHAREDURLS_EARNINGSPERPLATFORM',
    'DASHBOARD_EARNINGSPERPLATFORM' : 'USER_DASHBOARD_EARNINGSPERPLATFORM',
    'USERWALLET_GET' : 'USER_USERWALLET_GET',
    'USERWALLETWITHBALANCE_GET' : 'USER_USERWALLETWITHBALANCE_GET',
    'ENGAGEMENT_UPDATEPAGECOUNT' : 'USER_ENGAGEMENT_UPDATEPAGECOUNT',
    'ENGAGEMENT_SHAREDURLS_GET' : 'ENGAGEMENT_SHAREDURLS_GET',
    'TRANSACTIONS_POPULATE' : 'USER_TRANSACTIONS_POPULATE',
    'ACTIVITY_POPULATE' : 'USER_ACTIVITY_POPULATE',
    'PAYMENTMETHOD_POPULATE' : 'USER_PAYMENTMETHOD_POPULATE',
    'WITHDRAWAL_SETMODALSTATUS' : 'USER_WITHDRAWAL_SETMODALSTATUS',
    'WITHDRAWALLIST_SETMODALSTATUS' : 'USER_WITHDRAWALLIST_SETMODALSTATUS',
    'WITHDRAWALLIST_POPULATE' : 'USER_WITHDRAWALLIST_POPULATE',
    'RETAILERS_POPULATE' : 'USER_RETAILERS_POPULATE',
    'ONBOARDING_UPDATE_STATUS' : 'USER_ONBOARDING_UPDATE_STATUS'
  },
  shared: {
    'SET_CALENDAR_DATES' : 'SET_CALENDAR_DATES',
    'IS_CALENDAR_HIDDEN' : 'IS_CALENDAR_HIDDEN',
    'SET_CURRENCY' : 'SET_CURRENCY'
  }
};