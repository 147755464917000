import React from 'react';
import classnames from 'classnames';

import rolesService from '../../../utils/services/role';
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import './RolesDropbox.scss';

export default class RolesDropbox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      roles: []
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ roles: nextProps.value });
  }

  componentDidMount() {
    this.getRoles();
  }

  getRoles() {
    rolesService.getAllRoles().then((data) => {
      if(data.data) {
        this.roles = data.data.map( role => ({ label: role.name, value: role._id }));
      }
      this.forceUpdate();
    });
  }

  onChange(e){
    this.setState({ roles: e.value });
    this.props.onChange(e.value);
  }

  rolesTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <div>{option.label}</div>
        </div>
      );
    }
  }

  render() {

    const { inline ,flex, label, required, id, error, className, style } = this.props;

    const cx = classnames('c-form-element', className, {
      'c-form-element__error': error,
      'c-form-element__flex': flex,
      'c-form-element--inline': inline
    });

    let showlabel = null;
    if (label) {
      showlabel = (
        <label htmlFor={id}>
          {label}
          {
            required && <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }
    
    const innerStyle = style ? style : { marginTop: '4px', marginLeft: '0.4rem', width: '301px' };

    return (
      <div className={ cx }>
        
        {showlabel} 

        <MultiSelect 
          id={id}
          options = { this.roles ||[] }
          value = { this.state.roles }
          onChange = { e => this.onChange(e) }
          itemTemplate={this.rolesTemplate}
          panelStyle={{ width:'350px' }} 
          style={innerStyle}
          className="dropbox--element"
        />
      </div>);
  }
}
