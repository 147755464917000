import React from 'react';
import { connect } from 'react-redux';
import Header from './header/Header';
import SideMenu from '../../shared/menu/sideBarMenu';
import Footer from './footer/Footer';
import GlobalMessages from '../../shared/globalMessage/GlobalMessage';
import config from '../../../config/config';
import * as headerActions from '../../../actions/client/headersActions';

require('../../shared/sass/style.scss');

const esc = ['6698083122e444167ac95858'];

@connect(store => {
    if (!config.REMOVAL_ALLOWED_CLIENTS.includes(store.loginCtx.loginCtx.clientId.toString())) {
      config.MENU.CLIENT = config.MENU.CLIENT.filter(f => f.route != '/client/userData');
  }

   if (esc.includes(store.loginCtx.loginCtx.clientId)) {
    config.MENU.CLIENT = config.MENU.CLIENT.filter(f => f.route != '/client/byChannelBi');
   }

  return {
    globalMessage: store.globalMessages.message,
    menuItems: config.MENU.CLIENT,
    isImpersonator: store.loginCtx.loginCtx.impersonationMode ? true : false,
    headerMenuVisible: store.clientHeader.headerMenuVisible,
    toggleCalendar: store.clientHeader.toggleCalendar,
    isCalendarHidden: store.sharedDateRangePicker.isCalendarHidden,
    clientObj: store.client.clientObj,
  };
})
export default class Layout extends React.Component {
  constructor() {
    super();
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.closeHeaderMenu = this.closeHeaderMenu.bind(this);
  }

  closeHeaderMenu() {
    this.props.dispatch(headerActions.closeHeaderMenu());
  }

  goTo(location) {
    this.props.router.push({ pathname: location });
  }

  logout() {
    var query = '/logout';
    this.props.router.push({ pathname: query });
  }

  onCloseCalendar = () => {
    const { isCalendarHidden } = this.props;
    if (!isCalendarHidden) {
      this.props.dispatch(headerActions.toggleCalendar(!this.props.toggleCalendar));
    }
  };

  render() {
    return (
      <div className="page-content-wrapper-x">
        <Header
          {...this.props}
          logout={this.logout}
          isImpersonator={this.props.isImpersonator}
          menuItems={this.props.menuItems}
        />
        <div className="menu-content-container" onClick={this.onCloseCalendar}>
          <SideMenu
            menuItems={this.props.menuItems}
            goTo={this.goTo}
            logout={this.logout}
            closeHeaderMenu={() => this.closeHeaderMenu()}
            headerMenuVisible={this.props.headerMenuVisible}
            isImpersonator={this.props.isImpersonator}
          />
          <div className="content-container-view">
            <GlobalMessages message={this.props.globalMessage} />
            <div className="content-container">
              <div className="content">{this.props.children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
