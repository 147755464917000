import React from 'react';
import classnames from 'classnames';

import emailTemplateService from '../../../utils/services/emailTemplate';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class EmailTemplateDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.emailTemplates = [];
    this.getEmailTemplates = this.getEmailTemplates.bind(this);
    
    this.state = {
      emailTemplates: this.props.value ? this.props.value : null
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.clientId != nextProps.clientId){
      if(nextProps.clientId){
        this.getEmailTemplates(nextProps.clientId);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.value === 'object') {
      this.setState({ emailTemplates: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ emailTemplates: nextProps.value });
    } 
  }

  getEmailTemplates(clientId) {
    emailTemplateService.getEmailTemplateByClient(clientId).then((data) => {
      if(data.data) {
        this.emailTemplates = data.data
          .filter(item => item.archived !== true)
          .map( emailTemplate => ({ label: emailTemplate.name, value: emailTemplate._id }));
      }
      else {
        this.emailTemplates = null;
      }
      this.forceUpdate();
    });
  }

  innerLabelEmailTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ emailTemplates: e.value });
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    const { label, required } = this.props;

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>
      
        { showlabel }

        <Dropdown 
          value={this.state.emailTemplates} showClear
          options={this.emailTemplates} onChange={this.onChange} 
          itemTemplate={this.innerLabelEmailTemplate} 
          style={{ marginLeft: '5px', width:'300px', marginTop: '4px' }} 
          filter={true} filterPlaceholder="Select an Email Template" 
          filterBy="label,value" placeholder="Select an Email Template"/>
      </div>
      
    );
  }
}
