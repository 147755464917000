import React from 'react';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

export default {
  getTopProducts: (sharedUrls) => {
    var topProducts = {};
    if (sharedUrls) {
      sharedUrls.forEach((sharedUrl) => {
        if (!topProducts[sharedUrl.productUrl]) {topProducts[sharedUrl.productUrl] = {clicks: 0, shares: 0 , earnings: new BigNumber(0)};}

        topProducts[sharedUrl.productUrl].shares += 1;
        topProducts[sharedUrl.productUrl].clicks += sharedUrl.clicks;
        topProducts[sharedUrl.productUrl].earnings = topProducts[sharedUrl.productUrl].earnings.plus(sharedUrl.earnings);
        topProducts[sharedUrl.productUrl].title = sharedUrl.meta ? sharedUrl.meta.title : null;
        topProducts[sharedUrl.productUrl].image = sharedUrl.meta ? sharedUrl.meta.image : null;
      });

      topProducts = _.orderBy(_.map(topProducts, (product, key) => {
        return _.merge(product, {productUrl: key});
      }), ['clicks'], ['desc']);

      var topProductItems = _.orderBy(topProducts, ['clicks'], ['desc']).map((product, index) => {
        return (
          <tr key={index}>
            <td className="text-left">
              <div className="table-image-holder" style={{backgroundImage : product.image ? `url(${product.image})`  : 'url(/images/table-holding-image.jpg)'}}>
              </div>
              <a title={product.title || product.productUrl} className="truncate" href={product.productUrl} target='_blank'>{product.title || product.productUrl}</a>
            </td>
            <td>{product.shares}</td>
            <td>{product.clicks}</td>
            <td>£{product.earnings.toFixed(2)}</td>
          </tr>
        );
      });

      return topProductItems.splice(0,5);
    }
  },
  getEngagementProducts : (sharedUrls) => {
    var engagementProducts = {};
    if (sharedUrls) {
      sharedUrls.forEach((sharedUrl) => {
        if (!engagementProducts[sharedUrl.productUrl]) {engagementProducts[sharedUrl.productUrl] = {clicks: 0, shares: 0 , earnings: new BigNumber(0)};}
        engagementProducts[sharedUrl.productUrl].shares += 1;
        engagementProducts[sharedUrl.productUrl].clicks += sharedUrl.clicks;
        engagementProducts[sharedUrl.productUrl].earnings = engagementProducts[sharedUrl.productUrl].earnings.plus(sharedUrl.earnings);
        engagementProducts[sharedUrl.productUrl].title = sharedUrl.meta ? sharedUrl.meta.title : sharedUrl.productUrl;
        engagementProducts[sharedUrl.productUrl].image = sharedUrl.meta ? sharedUrl.meta.image : null;
      });

      engagementProducts = _.orderBy(_.map(engagementProducts, (product, key) => {
        product.cpc = !product.earnings.dividedBy(product.clicks).isNaN() ? product.earnings.dividedBy(product.clicks).toNumber() : 0;
        product.earnings = product.earnings.toNumber();
        return _.merge(product, {productUrl: key});
      }), ['clicks'], ['desc']);

      return engagementProducts;
    }
  },
  sortResults: (data , sortDirection , sortIndex) => {
    data = _.orderBy(data, sortIndex,[sortDirection]);
    return data;
  },
  getDailyTractionData: (tractionData) => {
    return _.map(tractionData , function(tractionItem){
      tractionItem.revenue = parseFloat(tractionItem.revenue);
      tractionItem.cpc = !isNaN(tractionItem.revenue / tractionItem.clicks) ?  parseFloat((tractionItem.revenue / tractionItem.clicks).toFixed()) : 0;
      return tractionItem;
    });
  },
  getActivityProducts: (activityProducts) => {
    return _.map(activityProducts, (product) => {
      product.calculatedTotal = parseFloat(product.calculatedTotal);
      product.title = product.meta ? product.meta.title : product.productUrl;
      product.image = product.meta ? product.meta.image : null;
      return product;
    });
  }
};