import request from '../wrappers/axios';
import config from '../../config/config';

class UserBlacklistService {

  getUserBlacklistList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/blacklist/user/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  createUserBlacklist (data) {
    return request({
      url: `${config.API.BASEURL}/blacklist/user`,
      method: 'post',
      data: data
    });
  }  

  deleteUserBlacklist (userBlacklistId) {
    return request({
      url: `${config.API.BASEURL}/blacklist/user/${userBlacklistId}`,
      method: 'delete'
    });
  }

  refreshCache (data) {
    return request({
      url: `${config.API.BASEURL}/blacklist/user/refresh`,
      method: 'post',
      data: data
    });
  }  

}

export default new UserBlacklistService();