import userManagementService from '../../utils/services/userManagement';
import constants from '../../constants/app-constants';

export const getUserManagementById = (id) => {
  return function(dispatch){
    userManagementService.getUserManagementById(id)
      .then(function(res){
        dispatch({
          type: constants.admin.USER_MANAGEMENT_GET_BY_ID,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.USER_MANAGEMENT_GET_BY_ID,
          payload: err.response
        });
      });
  };
};

export const updateUserManagement = (data) => {
  return function(dispatch){
    userManagementService.updateUserManagement(data)
      .then(function(res){
        dispatch({
          type: constants.admin.USER_MANAGEMENT_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.USER_MANAGEMENT_EDIT,
          payload: err.response
        });
      });
  };
};

export const createUserManagement = data => dispatch => {
  return new Promise((resolve, reject) => {
    userManagementService.createUserManagement(data)
      .then(res => {
        dispatch({
          type: constants.admin.USER_MANAGEMENT_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.USER_MANAGEMENT_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};
