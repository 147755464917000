import { ClientStats } from '../../utils/helpers/ClientStats';

const clientStats = new ClientStats();

const injectClientStats = (Target) => {

  Target.prototype.setClientTotalStats = (...args) => {
    return clientStats.setClientTotalStats(...args);
  } 

  Target.prototype.columns = (...args) => {
    return clientStats.columns(...args);
  }

  Target.prototype.columnsFooter = (...args) => {
    return clientStats.columnsFooter(...args);
  }

  return Target;
}

export const withClientStats = injectClientStats;