import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import displayBlockService from '../../../utils/services/displayBlock';

import {Dropdown} from 'primereact/components/dropdown/Dropdown';

export default class DisplayBlockDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.displayBlocks = [];
    this.getDisplayBlocks = this.getDisplayBlocks.bind(this);
    
    this.state = {
      diplayBlock: null,
      displayBlockAvailable: 'hidden'
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.campaignVersion !=nextProps.campaignVersion){
      if(nextProps.campaignVersion){
        this.getDisplayBlocks(nextProps.campaignVersion);
      }
    }
  }


  getDisplayBlocks(campaignVersionId) {
    displayBlockService.getAllDisplayBlocks(campaignVersionId).then((data) => {
      if(data.data) {
        this.displayBlocks = data.data
          .filter(item => item.archived !== true)
          .map(displayBlock => ({ label: displayBlock.name, value: displayBlock._id }));
        this.setState({displayBlockAvailable: 'hidden'});
      }
      else {
        this.displayBlocks = null;
        this.setState({displayBlockAvailable: 'visible'});
      }
      this.forceUpdate();
    });
  }


  displayBlockTemplate(option) {
    if(option) {

        return (
          <div className="ui-helper-clearfix">
            <span>{option.label}</span>
          </div>
        );
      }
  
    
  }

  onChange(e){
    this.setState({displayBlock: e.value});
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    return (
      <div className={ cx }>
          <label>
            {this.props.label} <span style={{ visibility : this.state.displayBlockAvailable }} >No display block available</span>
        </label>
        <Dropdown value={this.state.displayBlock} options={this.displayBlocks} onChange={this.onChange} itemTemplate={this.displayBlockTemplate} style={{marginLeft: '5px', width:'250px'}} filter={true} filterPlaceholder="Select Display Block" filterBy="label,value" placeholder="Select a Display Block"/>
      </div>
      
    );
  }
}
