import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { InputText } from 'primereact/components/inputtext/InputText';
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Button } from 'primereact/components/button/Button';
import { Sidebar } from 'primereact/components/sidebar/Sidebar';
import { Panel } from 'primereact/components/panel/Panel';
import { OverlayPanel } from 'primereact/components/overlaypanel/OverlayPanel';

import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import CountryDropbox from '../../../shared/dropbox/CountryDropbox';
import FormElement from '../../../shared/form/FormElement';
import Label from  '../../../shared/label/label';

import CountryCodeService from './../../../../utils/services/countryCode';
import reportService from './../../../../utils/services/reports';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

const $offset = 0;
const $limit = 20;

export class CountryCode extends React.Component {

  // CONSTRUCTOR
  constructor() {
    super();
  
    this.state = {
      list:[],
      listGenericCodes: [],
      listClientCodes: [],
      pendingList:[],
      newItem: {},
      clientIdFilter: null,
      clientName: '',
      loading: false
    };
    
    this.getData = this.getData.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
    this.onNewItemChange = this.onNewItemChange.bind(this);
    this.save = this.save.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.addNewItemToPendingList = this.addNewItemToPendingList.bind(this);
    this.openSuggestion = this.openSuggestion.bind(this);
    this.addSuggestionsToNewItem = this.addSuggestionsToNewItem.bind(this);
  }

  componentWillMount(){
    // initial load

    const { clientId } = this.props.location.query; // ClientId in the Url

    if (clientId) {
      this.state.clientIdFilter = clientId;
      this.getData({ clientIdFilter: clientId });
    } else {
      this.getData({});
    }
  }

  // GET DATA
  getData(event){
    CountryCodeService.getPage(this.getQuery(event), this.getExtendedQuery(event))
      .then((result) => {
        
        let orderedList = result.data.page;
        orderedList = orderedList.map((i) => {i.oCode = i.code; return i;});
        this.setState({ list : orderedList, totalCount : result.data.totalCount, first: event.first, rows: event.rows });
      })
      .catch((error) => {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error fetching data: ' + error,
            timeout: 10000,
            date: new Date().toISOString()
          })
        );
      });
    
  }

  // GET SUGGESTIONS
  getCountryCodeSuggestion(){

    this.setState({ loadingSuggestions: true });

    if(!this.state.clientIdFilter){
      return;
    }

    let query = {
      $offset: 0,
      $limit: 50,
      $sort: 'date:desc',
    };

    query.freeString = this.state.clientIdFilter;

    let promisses = []; 

    promisses.push(reportService.getLiveIntegration(_.extend(query, { indexPattern : 'soreto_live_audit*'})));
    promisses.push(reportService.getLiveIntegration(_.extend(_.clone(query), { indexPattern : 'soreto_live_track*'})));
    promisses.push(CountryCodeService.getPage(this.getQuery({first: 0, rows: 100})));
    promisses.push(CountryCodeService.getPage(this.getQuery({first: 0, rows: 100, clientIdFilter: this.state.clientIdFilter})));

    Promise.all(promisses)
      .then((all) => {

        let suggestions = [];

        let genericCodes = all[2].data.page.map(c => c.code);
        let clientCodes = all[3].data.page.map(c => c.code);

        for(let hit of all[0].data.hits.concat(all[1].data.hits)){
          let code = this.getNested(hit, ['country']);

          if(code){
            let genericMapped = genericCodes.find(c => c == code);
            let specificMapped = clientCodes.find(c => c == code);

            let alreadyMapped = suggestions.find(s => s.code == code);

            if(!alreadyMapped){
              suggestions.push({ sources: [hit], code, genericMapped, specificMapped, hints: 1 });
            }else{
              alreadyMapped.hints += 1;
              alreadyMapped.sources.push(hit);
            }            
          }
        }

        suggestions.sort((a, b) => (a.hints > b.hints) ? -1 : 1);

        this.setState({ suggestions, loadingSuggestions: false });
      })
      .catch((error) => {
        this.setState({ loadingSuggestions: false, addNewPanelVisible: false, suggestionPanelVisible: false });
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error fetching suggestions: ' + error,
            timeout: 10000,
            date: new Date().toISOString()
          })
        );
      });
  }

  // CREATE A NEW ITEM
  create() {

    if(!this.state.pendingList || this.state.pendingList.length == 0){
      this.setState({ addNewPanelVisible: false });
      return;
    }

    CountryCodeService.new(this.state.pendingList)
      .then(() => {
        
        this.setState({ pendingList : [], addNewPanelVisible: false });

        this.getData({ clientIdFilter: this.state.clientIdFilter });
      })
      .catch((error) => {
        this.setState({addNewPanelVisible: false});
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error adding new codes: ' + error,
            timeout: 10000,
            date: new Date().toISOString()
          })
        );
      });
  }

  // SAVE ITEM
  save(updatedItem){

    CountryCodeService.update(updatedItem)
      .then((result) => {
        let list = this.state.list;
    
        for(let i of list){
          if(i._id == result.data._id){

            i.changed = false;
            i.oCode = result.data.code;        
          }
        }

        this.setState({ list});
      })
      .catch((error) => {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error updating: ' + error,
            timeout: 10000,
            date: new Date().toISOString()
          })
        );
      });
  }

  // DElETE ITEM
  deleteItem(item){
    
    if(item.pending != true){
      CountryCodeService.delete(item._id)
        .then(() => {
          let list = this.state.list;
    
          list = list.filter(i => i._id != item._id);

          this.setState({ list });
        })
        .catch((error) => {
          this.props.dispatch(
            globalMessageActions.showMessage({
              type: 'error',
              message: 'Error updating: ' + error,
              timeout: 10000,
              date: new Date().toISOString()
            })
          );
        });
    }else {

      let pendingList = this.state.pendingList;
    
      pendingList = pendingList.filter(i => i.code != item.code);

      this.setState({ pendingList });
    }    
  }

  // CLIENT FILTER SELECTION CHANGED
  clientSelectionChanged(event) {
    this.setState(
      { clientIdFilter: event.value, 
        clientName: event.clientName, 
        pendingList: [] 
      });
    
    this.getData({ clientIdFilter: event.value });
  }

  getQuery(event) {

    var query = {
      $offset: event.first || $offset,
      $limit: event.rows || $limit
    };

    let clientIdFilter = event.clientIdFilter;

    if(clientIdFilter && clientIdFilter != '') {
      var value = { '$clientId' : clientIdFilter };
      query = Object.assign(query ,value);
    }

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    
    let sortField = event.sortField || this.state.sortField;
    let headerClick = (event.sortField != null);
    
    if(sortField) {

      let sortVar = `sort_${sortField}`;
      let sortAsc = this.state[sortVar] ? (headerClick ? !this.state[sortVar] : this.state[sortVar]) : true;

      var field = `$sort=${sortAsc == true ? '' : '-' }${sortField}`;
      extendedQuery = extendedQuery + field;

      this.setState({ sortField : sortField, [sortVar] : sortAsc });
    }

    return extendedQuery;
  }

  onItemChange(e, item){

    let list = this.state.list;
    
    for(let i of list){
      if(i._id == item._id){

        i.changed = (i.oCode == e.code) ? false : true;
        i.code = e.code;
        i.confirmDelete = e.confirmDelete;      
      }
    }
    
    this.setState({ list });
  }

  onNewItemChange(e){

    let { newItem, clientIdFilter, clientName } = this.state;
    
    newItem = _.assign(newItem, e);
    newItem.clientId = clientIdFilter;
    newItem.clientName = clientName;
    newItem.pending = true;

    this.setState({ newItem });
  }

  addNewItemToPendingList(){

    let { pendingList, newItem } = this.state;
    
    if(newItem.code && newItem.code.includes(',')){

      let codes = newItem.code.split(',').map(i => i.trim());

      for(let code of codes){
        let clone = _.cloneDeep(newItem);
        clone.code = code;
        pendingList.push(clone);
      }
    }else{
      pendingList.push(_.cloneDeep(newItem));
    }
    
    newItem = {};

    this.setState({ pendingList, newItem });
  }

  addSuggestionsToNewItem(){
    if(this.state.selectedSugestions){

      let { newItem } = this.state;

      newItem.code = this.state.selectedSugestions.join(',');
    }

    this.setState({ selectedSugestions: [], suggestionPanelVisible : false });
  }

  addSuggestion(event){

    if(!event.target){
      return;
    }

    let { newItem, selectedSugestions } = this.state;

    if(!selectedSugestions){
      selectedSugestions = [];
    }

    if(event.target.checked){
      selectedSugestions.push(event.target.defaultValue);
    }else{
      selectedSugestions = selectedSugestions.filter(s => s != event.target.defaultValue);
    }    

    selectedSugestions = _.uniq(selectedSugestions);

    newItem.code = selectedSugestions.join(',');

    this.setState({ newItem, selectedSugestions });    
  }

  // OPEN SUGGESTION
  openSuggestion(){
    this.getCountryCodeSuggestion();

    this.setState({ suggestionPanelVisible : true });
  }

  // RENDER SUGGESTIONS
  renderSuggestions(){

    if(this.state.loadingSuggestions == true){
      return (<div>Loading Suggestions</div>);
    }

    if(!this.state.clientIdFilter || !this.state.suggestions){
      return (<div></div>);
    }

    let suggestion = (s, index) => {
      let rowColor = (index & 1) ? '#7dc792' : '#c5e8cf';
      return (
                
        <div className="ui-g" style={{backgroundColor: rowColor}}>

          <div className="ui-g-12 ui-md-12">

            <div className="ui-g">
              <b>{s.code}</b>
            </div>

            <div className="ui-g">
              <div className="ui-g-1 ui-md-1">
                <FormElement
                  style={{width:'10px'}}
                  type='checkbox' 
                  value={s.code}
                  onChange={e => this.addSuggestion(e)}
                />
              </div>
              <div className="ui-g-11 ui-md-11">
                {!s.genericMapped && !s.specificMapped ? <Label color='red' text='Unmmaped' /> :''}
                {s.genericMapped ? <Label color='green' text='Generic Mapped' /> :''}
                {s.specificMapped ? <Label color='blue' text='Client Mapped' /> :''}
                <Panel header={`hints:${s.hints}`} toggleable='true' collapsed={true}>
                  <div style={{overflowY:'scroll', maxHeight:'500px', backgroundColor:'grey' }}>
                    {s.sources.map(s => (<FormElement
                      rows={10}
                      cols={60}
                      type='textArea' 
                      value={JSON.stringify(s)}/>))}
                  </div>
                </Panel>
              </div>
            </div>

          </div>             
          
        </div>          
               
      );
    };

    let suggestions = this.state.suggestions.map(suggestion);

    return (
      <div>
        <div className="ui-g">
          <Panel header='Suggestions' className="ui-g-12 ui-md-12" style={{maxHeight:'800px', overflowY:'scroll'}}>
            {suggestions}
          </Panel>
        </div>
        <div className="ui-g">
          <div className="ui-g-3 ui-md-3">
            <Button disabled={!this.state.selectedSugestions} onClick={this.addSuggestionsToNewItem}><span>Add selected</span></Button>
          </div>          
        </div>
      </div>
    );
  }

  // RENDER ITEMS
  renderItems(pendingList){

    let list = pendingList == false ? this.state.list : this.state.pendingList;

    let items = (list && list.length > 0) ? 
      list.map((i, index) => this.renderItem(i, index)) :
      this.renderEmptyPanel();

    return (items);
  }

  // RENDER COUNTRY ITEM
  renderItem(item, index) {

    let rowColor = (index & 1) ? '#7dc792' : '#c5e8cf';

    return (
            
      <div className="ui-g">
        <div className="ui-g-12 ui-md-12" style={{backgroundColor:rowColor, color:'black'}}>
          <div className="ui-g-3 ui-md-3 ">
            <InputText style={{width:'80%'}} disabled={!item._id} value={item.code} onChange={(e) => this.onItemChange({code : e.target.value}, item)}></InputText>
            <i className="fa fa-long-arrow-right" style={{paddingLeft:'3px'}} aria-hidden="true"></i>
          </div>
          <div className="ui-g-3 ui-md-3" style={{paddingTop:'12px'}}>
            {item.countryName}
          </div>
          <div className="ui-g-4 ui-md-4" style={{paddingTop:'10px'}}>
            {!item.clientName ? <Label color="black" text="SORETO" /> : <span>{item.clientName}</span>}
          </div>          
          <div className="ui-g-1 ui-md-1" style={{paddingTop:'10px'}}>
            {!item.confirmDelete ? <i className="fa fa-trash" style={{cursor: 'pointer', fontSize:'20px'}} onClick={() => {if(!item.pending) { this.onItemChange({ code: item.code, confirmDelete : true }, item);} else {this.deleteItem(item);}}} aria-hidden="true"></i> : ''}
            {item.confirmDelete == true ? <i className="fa fa-times-circle-o" style={{cursor: 'pointer', fontSize:'20px'}} onClick={() => this.onItemChange({ code: item.code, confirmDelete : false }, item)} aria-hidden="true"></i> : ''}
            {item.confirmDelete == true ? <i className="fa fa-check-circle-o" style={{cursor: 'pointer', fontSize:'20px', paddingLeft: '5px'}} onClick={() => this.deleteItem(item)} aria-hidden="true"></i> : ''}
            
          </div>
          <div className="ui-g-1 ui-md-1" style={{paddingTop:'10px'}}>
            {item.changed == true ? <i className="fa fa-floppy-o" style={{cursor: 'pointer', fontSize:'20px'}} onClick={() => this.save(item)} aria-hidden="true"></i> : ''}
          </div>
        </div>        
      </div> 
    );
  }

  // RENDER EMPTY PANEL
  renderEmptyPanel(){
    return (<div>No records!</div>);
  }

  // RENDER
  render () {

    return (

      // MAIN DIV
      <div>

        <div className="ui-g">

          <div className="ui-g-3 ui-md-3">
          </div>
          <div className="ui-g-3 ui-md-3">
            <h3>Country Codes management</h3>
          </div>

        </div>
        
        <hr/>
        {/* FILTER */}
        <div className="ui-g">

          <div className="ui-g-3 ui-md-3">
          </div>
          
          <div className="ui-g-3 ui-md-3">
            <ClientsDropbox clientId={this.state.clientIdFilter } onChange={(e) => this.clientSelectionChanged(e)} ></ClientsDropbox>
          </div>

          <div className="ui-g-3 ui-md-3">
            <Button onClick={() => { this.setState({addNewPanelVisible: true }); }}><i class="fa fa-plus" aria-hidden="true"></i></Button>
          </div>

        </div>

        {/* LIST */}

        <div className="ui-g">
          <div className="ui-g-3 ui-md-3">
          </div>
          <div className="ui-g-6 ui-md-6">

            <div className="ui-g" style={{backgroundColor:'darkgrey'}}>
              <div className="ui-g-3 ui-md-3" onClick={() => this.getData({sortField: 'code' })} style={{cursor: 'pointer'}}>
                Code
                { this.state.sortField == 'code' ? 
                  this.state[`sort_code`] == true ? 
                    <i className="fa fa-arrow-down" aria-hidden="true"></i> : 
                    <i className="fa fa-arrow-up" aria-hidden="true"></i>: 
                  ''}
              </div>
              <div className="ui-g-3 ui-md-3" onClick={() => this.getData({sortField: 'countryName'})} style={{cursor: 'pointer'}}>
                Country
                { this.state.sortField == 'countryName' ? 
                  this.state[`sort_countryName`] == true ? 
                    <i className="fa fa-arrow-down" aria-hidden="true"></i> : 
                    <i className="fa fa-arrow-up" aria-hidden="true"></i>: 
                  ''}
              </div>
              <div className="ui-g-4 ui-md-4">Client</div>
              <div className="ui-g-2 ui-md-2"> </div>
            </div>

            {this.renderItems(false)}
            
          </div>          
        </div>
        
        <Paginator 
          first={this.state.first} 
          onPageChange={(e) => this.getData(_.merge({ ...e, clientIdFilter: this.state.clientIdFilter }))} 
          totalRecords={this.state.totalCount} 
          rows={this.state.rows || $limit} rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}></Paginator>

        {this.renderAddNewDialog()}
        {this.renderSuggestionDialog()}
      </div>
    );
  }

  renderAddNewDialog = () =>{

    let { newItem } = this.state;
    let instructions = this.state.clientIdFilter ?
      'Once you add a code for a Client X Country, the requests will be mapped based exclusively on the mapped ones, the generic map will be ignored. Make sure you will add all the required ones.':
      'A generic map entry will track a request to the correct country. Generic entries will affect all clients with no specific mapping.';

    return( 
      <Sidebar style={{width:'55%'}} visible={this.state.addNewPanelVisible} fullScreen={false} onHide={() => this.setState({addNewPanelVisible:false})} blockScroll={false}>
        <div className="ui-g">
          <div className="ui-g-12 ui-md-12">
            <Panel header="Add new">
              <OverlayPanel ref={(el) => this.op = el}>
                <div>
                  <span>{instructions}</span>
                </div>
              </OverlayPanel>
              <div className="ui-g">
                <div className="ui-g-4 ui-md-4" >
                  <FormElement
                    placeholder="Code"
                    type='text'
                    onChange={(e) => this.onNewItemChange({code: e.target.value})}
                    value={newItem.code}
                    error={ ((!newItem.code || newItem.code == '' ) && newItem.countryId != undefined ) ? 'Required' : ''}
                    style={{width:'200px', height:'30px'}}
                    required
                  />
                  
                </div>
                <div className="ui-g-5 ui-md-5">
                  <CountryDropbox value={newItem.countryId}
                    overwriteStyle={{width:'200px'}}
                    onChange={this.onNewItemChange}></CountryDropbox>
                </div>
                <div className="ui-g-1 ui-md-1">
                  <Button disabled={!newItem.code || !newItem.countryId} onClick={this.addNewItemToPendingList}><i class="fa fa-plus" aria-hidden="true"></i></Button>
                </div>
                <div className="ui-g-1 ui-md-1">
                  <Button type="button" onClick={(e) => this.op.toggle(e)}><i class="fa fa-question-circle" aria-hidden="true"></i></Button>
                </div>
                
              </div>
            </Panel>
            
          </div>
        </div>
        
        <div className="ui-g" style={{maxHeight:'70%', overflowY:'scroll'}}>
          <div className="ui-g-12 ui-md-12">
            <h5>New items</h5>
            {this.renderItems(true)}
          </div>
        </div>
                
        <hr/>
        <div className="ui-g">
          <div className="ui-g-2 ui-md-2">
            <Button disabled={!this.state.clientIdFilter} onClick={this.openSuggestion}>Suggestions</Button>
          </div>
          <div className="ui-g-8 ui-md-8">            
          </div>
          <div className="ui-g-2 ui-md-2">
            <Button onClick={() => this.create()}>Save</Button>
          </div>          
        </div>  
      </Sidebar>);
  }

  renderSuggestionDialog = () => {

    return (
      <Sidebar 
        position="right"
        style={{width:'40%'}} 
        visible={this.state.suggestionPanelVisible} 
        fullScreen={false} onHide={() => this.setState({suggestionPanelVisible:false})} 
        blockScroll={false}>

        {this.renderSuggestions()}
      </Sidebar>
    );
  }

  getNested = (data, fieldNames) => {

    let value = null;
    let recursion = (data) => {
  
      let nestedFind = fieldNames.find(n => _.get(data, n));

      if(nestedFind){
        value = _.get(data, nestedFind);
        return;
      }

      // iterate over all object keys
      for(let key in data){
  
        if(fieldNames.find(n => n == key)){
          value = data[key];
          break;
        }
        
        // is this property vakue a sub object?
        if(this.getType(data[key]) == 'object'){
          recursion(data[key]);
  
          continue;
        }          
      }
    };
  
    recursion(data);

    return value;
  
  }

  getType(p){
    if (Array.isArray(p)) return 'array';
    else if (typeof p == 'string') return 'string';
    else if (!isNaN(p)) return 'number';
    else if (p != null && typeof p == 'object') return 'object';
    else return 'other';
  }
}

export default connect(() => {
  return {};
})(CountryCode);