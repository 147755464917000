import request from '../wrappers/axios';
import config from '../../config/config';

class ByChannelService {

  getTotalClientStatsByPeriod(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/byChannel/totalClientStatsByPeriod${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getClientStatsPerChannelByPeriod(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/byChannel/clientStatsPerChannelByPeriod${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getClientStatsPerChannelByPeriod_bi(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/reports/bi/statsByChannel${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

}

export default new ByChannelService();