import request from '../wrappers/axios';
import config from '../../config/config';

class ValueOrderStatusService {
  getValueOrderStatusList(query = '', extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/valueOrderStatus${extendedQuery}`,
      method: 'get',
      params: query
    });
  }
}

export default new ValueOrderStatusService();
