import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import validator from 'validator';

import FormElement from '../../../shared/form/FormElement';
import CampaignVersionDropbox from '../../../shared/dropbox/CampaignVersionDropbox';
import EmailTemplateDropbox from '../../../shared/dropbox/EmailTemplateDropbox';

import * as globalMessageActions from '../../../../actions/globalMessageActions';
import * as associateEmailToCampaignVersionActions from '../../../../actions/admin/associateEmailToCampaignVersionActions';

@connect((store) => {
  return {
    associateEmailToCampaignVersionGet: store.adminAssociateEmailToCampaignVersion.associateEmailToCampaignVersionGetById,
    associateEmailToCampaignVersionEdit: store.adminAssociateEmailToCampaignVersion.associateEmailToCampaignVersionEdit
  };
})
class AssociateEmailToCampaignVersionEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      associateEmailToCampaignVersionDetails = {
        associateEmailToCampaignVersionId:'',
        client: '',
        clientId: '',
        campaignName: '',
        campaignId: '',
        campaignVersionId: null,
        emailTemplateId: null,
        archived: false,
      }
    } = props;

    this.state = {
      associateEmailToCampaignVersionDetails,
      errors: {},
      displayErrorMessage: false
    };

    this.onCampaignVersionChange = this.onCampaignVersionChange.bind(this);
    this.onEmailTemplateChange = this.onEmailTemplateChange.bind(this);
  }

  componentDidMount() {
    const associateEmailToCampaignVersionId = this.props.params.associateEmailToCampaignVersionId;
    this.setState({ associateEmailToCampaignVersionId });
    if (associateEmailToCampaignVersionId) {
      this.props.dispatch(associateEmailToCampaignVersionActions.getAssociateEmailToCampaignVersionById(associateEmailToCampaignVersionId));
    }

    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New Associate Email to Campaign Version added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.associateEmailToCampaignVersionGet) {
      this.setState({ associateEmailToCampaignVersionDetails : nextProps.associateEmailToCampaignVersionGet });
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.associateEmailToCampaignVersionEdit != nextProps.associateEmailToCampaignVersionEdit){
      if(_.get(nextProps,'associateEmailToCampaignVersionEdit')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Associate Email to CampaignVersion saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'associateEmailToCampaignVersionEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.associateEmailToCampaignVersionEdit.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onChangeCheckbox(name, {target}) {
    const {associateEmailToCampaignVersionDetails} = this.state;
    _.set(associateEmailToCampaignVersionDetails, name, target.checked);
    this.setState({associateEmailToCampaignVersionDetails});
  }

  onCampaignVersionChange(e){
    const { associateEmailToCampaignVersionDetails } = this.state;
    _.set(associateEmailToCampaignVersionDetails, 'campaignVersionId', e.value);
    this.setState({ associateEmailToCampaignVersionDetails });
  }

  onEmailTemplateChange(e){
    const { associateEmailToCampaignVersionDetails } = this.state;
    _.set(associateEmailToCampaignVersionDetails, 'emailTemplateId', e.value);
    this.setState({ associateEmailToCampaignVersionDetails });
  }

  onSubmit(){

    this.setState({ displayErrorMessage: false });
    const { associateEmailToCampaignVersionDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      associateEmailToCampaignVersionDetails.updatedAt = new Date();
      this.props.dispatch(
        associateEmailToCampaignVersionActions.updateAssociateEmailToCampaignVersion(associateEmailToCampaignVersionDetails)
      );
    }
  }

  validateForm() {
    const { associateEmailToCampaignVersionDetails, errors } = this.state;

    errors[ 'client' ] = validator.isEmpty(associateEmailToCampaignVersionDetails.client);
    errors[ 'campaignName' ] = validator.isEmpty(associateEmailToCampaignVersionDetails.campaignName);
    errors[ 'campaignVersion' ] = _.isNil(associateEmailToCampaignVersionDetails.campaignVersionId);
    errors[ 'emailTemplate' ] = _.isNil(associateEmailToCampaignVersionDetails.emailTemplateId);

    return this.setState({ errors });
  }

  render (){
    const { associateEmailToCampaignVersionDetails = { info: {} }, errors } = this.state;

    const {
      client,
      clientId,
      campaignName,
      campaignId,
      campaignVersionId,
      emailTemplateId,
      archived,
    } = associateEmailToCampaignVersionDetails;

    return (
      <div>
        <h3>Edit Associate Email to Campaign Version</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Client'
            name='client'
            type='text'
            value={client}
            id='form-client'
            required
            disabled='true'
            error={errors.client}
          />

          <FormElement
            label='Campaign'
            name='campaign'
            type='text'
            value={campaignName}
            id='form-campaign'
            required
            disabled='true'
            error={errors.campaignName}
          />

          <CampaignVersionDropbox
            label="Campaign Version"
            name='campaignVersion'
            required
            campaign={campaignId}
            onChange={this.onCampaignVersionChange}
            value={campaignVersionId}
            error={errors.campaignVersion}
          />

          <EmailTemplateDropbox
            label="Email Template"
            name='emailTemplate'
            required
            clientId={clientId}
            onChange={this.onEmailTemplateChange}
            value={emailTemplateId}
            error={errors.emailTemplate}
          />

          <FormElement
            label='Archived'
            name='archived'
            type='checkbox'
            onChange={this.onChangeCheckbox.bind(this, 'archived') } 
            checked={(!!(archived))}
            error={errors.archived}
            id='form-active'
          />

        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Save</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/associateEmailToCampaignVersion')}>
          Back
        </button>
      </div>
    );
  }
}

export default AssociateEmailToCampaignVersionEdit;