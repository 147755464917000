import React from 'react';
import {Doughnut, Bar} from 'react-chartjs-2';
import chartHelper from '../../../../utils/helpers/chart';
import BigNumber from 'bignumber.js';

import _ from 'lodash';

class ChartPanel extends React.Component {

  constructor(props) {
    super(props);
    this.goToSocialPost = this.goToSocialPost.bind(this);
  }

  goToSocialPost(){
    this.props.router.push({ pathname: '/user/socialpost' });
  }

  render () {
    return (
      <div className="grid-container">
        <div className="grid-70 mobile-grid-100 tablet-grid-70  user-dashboard-graph-holder">
          <div className={"grid-50 mobile-grid-100 tablet-grid-50 chart-holder "+ (this.props.revenueChart.data.datasets[0].data.every(chartHelper.chartDataEmpty) ? "chart-empty" : null)}>
            <div className="chart-holder-inner">
              <div className="text-center"><span className="big-titles">Earnings</span></div>
              <div className="show-on-empty empty-message text-center">
                <div className="">
                  You currently have no data within this time period
                </div>
                <button onClick={() => this.goToSocialPost() } className="primary">Get Sharing</button>
              </div>
              <div className="show-on-empty empty-chart-inner">
                <img src="/images/ring-placeholder.png" alt="ring"/>
              </div>
              {
                !this.props.revenueChart.data.datasets[0].data.every(chartHelper.chartDataEmpty) ?
                  <div className="chart-padder">
                    <Doughnut data={this.props.revenueChart.data} options={this.props.revenueChart.options} />
                    <div className="earnings-amount">
                      £{
                          _.reduce(this.props.revenueChart.data.datasets[0].data, function(sum, n) {
                            return new BigNumber(sum).plus(new BigNumber(n)).toFixed(2);
                          }, 0)
                        }
                    </div>
                  </div>
                  :
                  false
              }
            </div>
          </div>
              <div className={"grid-50 mobile-grid-100 tablet-grid-50 chart-holder "+ (this.props.clicksChart.data.datasets[0].data.every(chartHelper.chartDataEmpty) ? "chart-empty" : null)}>
                   <div className="chart-holder-inner">
              <div className="text-center"><span className="big-titles">Clicks</span></div>
              <div className="show-on-empty empty-message text-center">
                <div className="">You currently have no data within this time period</div>
                <button onClick={() => this.goToSocialPost() } className="primary">Get Sharing</button>
              </div>
              <div className="show-on-empty empty-chart-inner">
                <img src="/images/bar-placeholder.png" alt="ring"/>
              </div>
              {
                !this.props.clicksChart.data.datasets[0].data.every(chartHelper.chartDataEmpty) ?
                  <div className="chart-padder">
                    <Bar data={this.props.clicksChart.data} options={this.props.clicksChart.options} />
                  </div>
                  :
                  false
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartPanel;
