import constants from '../../constants/app-constants';

const initialState = {
  modalIsOpen: false,
  previews: null,
  shareBgEnabled: true
}

const ClientSettings = (state = initialState , action) => {
  switch(action.type){

  case constants.client.SETTINGS_COMPANY_TOGGLELOCATIONMODAL : {
    return {...state, modalIsOpen: action.payload };
  }

  case constants.client.SHAREPOPUP_UPDATEPREVIEWS : {
    return {...state, previews: action.payload};
  }

  case constants.client.SETTINGS_SHAREPOPUPCUSTOM_UPDATESHAREBGENABLED : {
    return {...state, shareBgEnabled: action.payload};
  }

  default:
    return state;
  }
};

export default ClientSettings;