import request from '../wrappers/axios';
import config from '../../config/config';

class ClientService {

  getClientShareDetails (clientId) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/sharebrand`,
      method: 'get'
    });
  }

  getClient (clientId) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}`,
      method: 'get'
    }, true, 500);
  }

  newClient (data) {
    return request({
      url: `${config.API.BASEURL}/clients`,
      method: 'post',
      data: data
    }, true, 500);
  }

  getAllClients(){
    return request({
      url: `${config.API.BASEURL}/clients/listings/all`,
      method: 'get'
    });
  }

  getClientPage(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/clients/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getSharedUrls (clientId, query) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/sharedurls`,
      method: 'get',
      params: query
    });
  }

  getSharedUrlsAccess (clientId, query) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/sharedurls/meta`,
      method: 'get',
      params: query
    });
  }

  setClientStatus(clientId,data){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/status`,
      method: 'post',
      data: data
    }, true, 500);
  }
  setClientRank(clientId,data){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/rank`,
      method: 'put',
      data: data
    }, true, 500);
  }
  updateClientMerchantAssoc(clientId,data){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/merchant_assoc`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getSharedUrlsAccessProducts (clientId, query , extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/products/meta${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getSharedUrlsAccessProductsCount (clientId, query) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/products/meta/count`,
      method: 'get',
      params: query
    });
  }

  getSharedUrlsCountClick (clientId, query) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/sharedurls/counts/socialclicks`,
      method: 'get',
      params: query
    });
  }

  getSharedUrlsCountShare (clientId, query) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/sharedurls/counts/socialshares`,
      method: 'get',
      params: query
    });
  }

  uploadImage(data){
    return request({
      url: `${config.API.BASEURL}/uploadimage`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateClient(clientId,data){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  getEarningsPerSocialPlatform (clientId , query) {
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/sharedurls/counts/socialearnings`,
      method: 'get',
      params: query
    });
  }

  getClientActivity(clientId , query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/activity${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getClientActivityCount(clientId , query){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/activity/count`,
      method: 'get',
      params: query
    });
  }

  updateOrder(clientId, orderId, payload){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/orders/${orderId}`,
      method: 'put',
      data: payload
    }, true, 500);
  }

  getProductTractionData(clientId , query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/reports/dailytraction${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getProductTractionDataCount(clientId , query){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/reports/dailytraction/count`,
      method: 'get',
      params: query
    });
  }

  reIndexClient(clientId, query){
    return request({
      url: `${config.BI.API.BASEURL}/reprocess/client/${clientId}`,
      method: 'post',
      params: query,
      timeout: 10000
    });
  }

  flushTagDetails(clientId, query){
    return request({
      url: `${config.API.BASEURL}/clients/${clientId}/cache/flushTagDetails`,
      method: 'post',
      params: query,
      timeout: 10000
    });
  }
}

export default new ClientService();