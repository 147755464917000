import clientService from '../../utils/services/client';
import constants from '../../constants/app-constants';

export const getClient = (clientId) => {
  return function(dispatch){
    clientService.getClient(clientId)
      .then(function(res){
        dispatch({
          type: constants.client.CLIENTOBJ_GET,
          payload: res.data
        });
      })
      .catch(function(){

      });
  };
};

export const updateClient = (client) => {
  return {
    type: constants.client.CLIENTOBJ_UPDATE,
    payload: client
  };
};

export const updateAndRefreshClient = (clientId , payload) => {
  return function(dispatch){
    clientService.updateClient(clientId , payload)
      .then(function(res){
        dispatch({
          type: constants.client.CLIENTOBJ_UPDATE,
          payload: res.data
        });
      })
      .catch(function(){

      });
  };
};