import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as displayBlockActions from '../../../../actions/admin/displayBlockActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import CampaignDropbox from '../../../shared/dropbox/CampaignDropbox';
import CampaignVersionDropbox from '../../../shared/dropbox/CampaignVersionDropbox';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    displayBlockObject: store.adminDisplayBlock.requests
  };
})
class DisplayBlockCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      displayBlockDetails = {
        campaignVersionId:'',
        active:'',
        name:'',
        type:''
      }
    } = props;

    this.state = {
      displayBlockDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      client: null,
      campaign: null
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onCampaignChange = this.onCampaignChange.bind(this);
    this.onCampaignVersionChange = this.onCampaignVersionChange.bind(this);
  }

  onChange(name, {target}) {
    const {displayBlockDetails} = this.state;
    _.set(displayBlockDetails, name, target.value);
    this.setState({displayBlockDetails});
  }

  componentWillUpdate(nextProps){
    if(this.props.displayBlockObject !=nextProps.displayBlockObject){
      if(_.get(nextProps,'displayBlockObject.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.displayBlockObject.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { onSubmit } = this.props;
    const { displayBlockDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      this.props.dispatch(displayBlockActions.newDisplayBlock(displayBlockDetails))
        .then(created => browserHistory.push({ pathname: `/admin/displayBlock/${created._id}` , state: { fromCreatePage: true } }));
    }

    console.log(errors); //for debugging left in for internal use only as it is an admin tool.

  }

  validateForm() {
    const { displayBlockDetails, errors } = this.state;

    errors[ 'campaignVersionId' ] = validator.isEmpty(displayBlockDetails.campaignVersionId);
    errors[ 'active' ] = validator.isEmpty( displayBlockDetails.active );
    errors[ 'name' ] = validator.isEmpty( displayBlockDetails.name );
    errors[ 'type' ] = validator.isEmpty( displayBlockDetails.type);

    return this.setState({ errors });
  }

  onClientChange(e){
    this.setState({client: e.value});
    this.setState({campaign: null});
    const {displayBlockDetails} = this.state;
    _.set(displayBlockDetails, 'campaignVersionId', '');
    this.setState({displayBlockDetails});
    //this.forceUpdate();
  }

  onCampaignChange(e){
    this.setState({campaign: e.value});
    const {displayBlockDetails} = this.state;
    _.set(displayBlockDetails, 'campaignVersionId', '');
    this.setState({displayBlockDetails});
  }

  onCampaignVersionChange(e){
    const {displayBlockDetails} = this.state;
    _.set(displayBlockDetails, 'campaignVersionId', e.value);
    this.setState({displayBlockDetails});
  }

  render (){
    const { displayBlockDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      campaignVersionId,
      active,
      name,
      type
    } = displayBlockDetails;

    return (
      <div className="displayBlock-new-displayBlock">
        <h3>Create placement</h3>
        <form  className="c-form--flex">

          <ClientsDropbox
            label="Client"
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId}
          />

          <CampaignDropbox
            label="Campaign"
            required='true'
            client={this.state.client}
            onChange={this.onCampaignChange}
            error={errors.campaignId}
          />

          <CampaignVersionDropbox
            label="Campaign Version"
            required='true'
            campaign={this.state.campaign}
            onChange={this.onCampaignVersionChange}
            error={errors.campaignVersionId}
          />

          <FormElement
            label='Campaign Version Id'
            name='campaignVersionId'
            type='text'
            onChange={this.onChange.bind(this, 'campaignVersionId')}
            value={campaignVersionId}
            error={errors.campaignVersionId}
            id='form-campaignVersionId'
            required
            disabled='true'
          />

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            error={errors.type}
            id='form-type'
            required
          >
            <option value=""> </option>
            <option value="lightbox">Lightbox</option>
            <option value="interstitial">Interstitial</option>
            <option value="shareviaemail">Share via email</option>
            <option value="voucher-page">Voucher page</option>
            <option value="lightboxwrapper">Lightbox wrapper</option>
            <option value="sharestaticpage">Share static page</option>
          </FormElement>

        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Create display block</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default DisplayBlockCreate;
