import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import * as clientActions from '../../../../../actions/client/clientActions';
import clientService from '../../../../../utils/services/client';
import * as globalMessageActions from '../../../../../actions/globalMessageActions';


@connect((store)=>{
  return{
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj
  };
})

class SettingsCommission extends React.Component{
  constructor () {
    super();
    this.state = {
      currentlyEditingCommissionCategory : '',
      currentlyEditingDefaultCommission: false
    };
  }

  componentWillMount(){
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
  }

  addNewCategoryCommissionEntry(e){
    e.preventDefault();
    var that = this;

    that.props.dispatch(globalMessageActions.showMessage(null));
    that.categoryInput.classList.remove('error');
    that.commissionInput.classList.remove('error');

    if(!that.categoryInput.value){
      that.categoryInput.classList.add('error');
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Please enter a category name',
          timeout: 10000
        }
      ));
      return;
    }

    if (isNaN(parseInt(that.commissionInput.value)) || that.commissionInput.value < 0 || that.commissionInput.value > 100) {
      that.commissionInput.classList.add('error');
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Percentage commission must be between 0 and 100.',
          timeout: 10000
        }
      ));
      return;
    }

    var categories = this.props.clientObj.percentCommission.categories ?
      this.props.clientObj.percentCommission.categories.slice(0) : [];

    //push new commission values
    categories.push(
      {
        name: that.categoryInput.value,
        value: that.commissionInput.value
      }
    );

    //create payload
    var payload = {
      percentCommission: {
        default: that.props.clientObj.percentCommission.default,
        categories: categories
      }
    };

    //update client
    clientService.updateClient(that.props.clientObj._id , payload)
      .then(function(res){
        that.props.dispatch(clientActions.updateClient(res.data));
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Category Commission added successfully',
            timeout: 10000
          }
        ));
      })
      .catch(function(err){
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });

    //clear inputs
    that.categoryInput.value = '';
    that.commissionInput.value = '';

  }

  updateCategoryCommissionEntry(categoryId){
    var that = this;

    that.props.dispatch(globalMessageActions.showMessage(null));

    if(!that.editCategoryCommissionName.value){
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Please enter a category name',
          timeout: 10000
        }
      ));
      return;
    }

    if (isNaN(parseInt(that.editCategoryCommissionValue.value)) || that.editCategoryCommissionValue.value < 0 || that.editCategoryCommissionValue.value > 100) {
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Percentage commission must be between 0 and 100.',
          timeout: 10000
        }
      ));
      return;
    }



    //build payload
    var categories = that.props.clientObj.percentCommission.categories.slice(0);
    var currentlyEditingCommissionCategory = _.find(categories , { name : categoryId });
    currentlyEditingCommissionCategory.name = that.editCategoryCommissionName.value;
    currentlyEditingCommissionCategory.value = that.editCategoryCommissionValue.value;

    var payload = {
      percentCommission: {
        default: that.props.clientObj.percentCommission.default,
        categories: categories
      }
    };

    //update client
    clientService.updateClient(that.props.clientObj._id , payload)
      .then(function(res){
        that.props.dispatch(clientActions.updateClient(res.data));
        //clear currently editing
        that.editCommissionCategory('');
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Category Commission added updated',
            timeout: 10000
          }
        ));
      })
      .catch(function(err){
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  editCommissionCategory(commissionCategoryId){
    this.setState({
      currentlyEditingCommissionCategory : commissionCategoryId
    });
  }

  deleteCategoryCommission(categoryId){
    var that = this;
    var categories = this.props.clientObj.percentCommission.categories.slice(0);
    _.remove(categories , { name : categoryId });

    var payload = {
      percentCommission: {
        default: that.props.clientObj.percentCommission.default,
        categories: categories
      }
    };

    clientService.updateClient(that.props.clientObj._id , payload)
      .then(function(res){
        that.props.dispatch(clientActions.updateClient(res.data));
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Category Commission successfully removed',
            timeout: 10000
          }
        ));
      })
      .catch(function(err){
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  updateDefaultCommission(){

    var that = this;

    that.props.dispatch(globalMessageActions.showMessage(null));
    that.defaultCommission.classList.remove('error');

    //validate inputs
    if (isNaN(parseInt(that.defaultCommission.value)) || that.defaultCommission.value < 0 || that.defaultCommission.value > 100) {
      that.defaultCommission.classList.add('error');
      that.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Percentage commission must be between 0 and 100.',
          timeout: 10000
        }
      ));
      return;
    }

    //prepare payload
    var categories = that.props.clientObj.percentCommission.categories.slice(0);
    var payload = {
      percentCommission: {
        default: that.defaultCommission.value,
        categories: categories
      }
    };

    //update client
    clientService.updateClient(that.props.clientObj._id , payload)
      .then(function(res){
        that.props.dispatch(clientActions.updateClient(res.data));
        //clear currently editing
        that.updateCurrentlyEditingDefaultCommissionState(false);
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Your default Commission has been updated',
            timeout: 10000
          }
        ));
      })
      .catch(function(err){
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });

  }

  updateCurrentlyEditingDefaultCommissionState(state){
    this.props.dispatch(globalMessageActions.showMessage(null));
    this.setState({
      currentlyEditingDefaultCommission : state
    });
  }

  render(){
    var that = this;
    var categories = [];

    if(this.props.clientObj && this.props.clientObj.percentCommission.categories){
      categories = _.map(this.props.clientObj.percentCommission.categories,function(category,key){
        var currentlyEditing = false;
        if(category.name == that.state.currentlyEditingCommissionCategory){
          currentlyEditing = true;
        }

        return(
         <div key={key}>
           {
             currentlyEditing ?
             <div className="category-commission">
               <input type="text"  ref={ (input) => that.editCategoryCommissionName = input } defaultValue={category.name} />
               <input className="percentage-box" type="text"  ref={ (input) => that.editCategoryCommissionValue = input } defaultValue={category.value} />
               <div className="button-clearer hide-on-desktop hide-on-tablet"></div>
               <button className="primary" onClick={() => that.updateCategoryCommissionEntry(category.name) }><i  className="fa fa-plus"></i><span>Save</span></button>
               <button className="secondary-v2" onClick={ (e) => that.editCommissionCategory('') }><i className="fa fa-times"></i><span>Cancel</span></button>
             </div>
             :
             false
           }
           {
             !currentlyEditing ?
             <div className="category-commission">
               <input type="text" defaultValue={category.name} disabled/>
               <input className="percentage-box" type="text" defaultValue={category.value} disabled/>
               <div className="button-clearer hide-on-desktop hide-on-tablet"></div>
               <button className="primary" onClick={ () => that.editCommissionCategory(category.name) }><i className="fa fa-pencil"></i><span>Edit</span></button>
               <button className="secondary-v2" onClick={ () => that.deleteCategoryCommission(category.name) }><i className="fa fa-times"></i><span>Remove</span></button>
             </div>
             :
             false
           }
         </div>
        );
      });
    }


    return (
      <div className="commission-settings">
        <h1>Commission %</h1>
        {this.props.clientObj ?
          <div>
            <h4 className="active">
              Your Default Commission
            </h4>
            { this.state.currentlyEditingDefaultCommission ?
              <div className="commission-form-section">
                <input className="percentage-box" ref={ (input) => this.defaultCommission = input } type="text" defaultValue={this.props.clientObj.percentCommission.default} />
                <button className="primary" onClick={() => this.updateDefaultCommission()  }>
                  <i  className="fa fa-plus"></i><span>Save</span>
                </button>
                <button onClick={() => this.updateCurrentlyEditingDefaultCommissionState(false) } className="secondary-v2">
                  <i className="fa fa-times"></i><span>Cancel</span>
                </button>

              </div>
              :
              false
            }
            {
              !this.state.currentlyEditingDefaultCommission ?
              <div className="commission-form-section">
                <input type="text" defaultValue={this.props.clientObj.percentCommission.default}  disabled />
                <button className="primary" onClick={() => this.updateCurrentlyEditingDefaultCommissionState(true) }>
                  <i className="fa fa-pencil"></i><span>Edit</span>
                </button>
              </div>
              :
              false
            }
            <div className="new-commission-setting commission-form-section">
              <form action="" onSubmit={(e) => this.addNewCategoryCommissionEntry(e) }>
                <h4 className="active">
                  Add New Commission Setting
                </h4>
                <input type="text" ref={(input) => this.categoryInput = (input) } placeholder="Enter Category"/>
                <input type="text" className="percentage-box" ref={(input) => this.commissionInput = (input)} placeholder="%"/>
                <button className="primary"><i  className="fa fa-plus"></i><span>Save</span></button>
              </form>
            </div>
            <div className="commission-form-section">
              <h4 className="active">
                Your Active Commission Settings
              </h4>
              { categories }
            </div>
          </div>
        :
          false
        }
      </div>
    );
  }
}

export default SettingsCommission;