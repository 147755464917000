import CodeBlockList from './List';
import CodeBlockCreate from './Create';
import CodeBlockEdit from './Edit';
import CodeBlockEditBeta from './EditBeta';

const CodeBlockIndex = {
  CodeBlockList, 
  CodeBlockCreate,
  CodeBlockEdit,
  CodeBlockEditBeta
};

export default CodeBlockIndex;