import constants from '../../constants/app-constants';

const initialState = {
  retailers: null
};

const UserRetailers = (state = initialState ,action) => {
  switch(action.type){
  case constants.user.RETAILERS_POPULATE: return { ...state , retailers : action.payload };
  default: return state;
  }
};

export default UserRetailers;