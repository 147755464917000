import associatedEmailToCampaignVersion from '../../utils/services/associatedEmailToCampaignVersion';
import constants from '../../constants/app-constants';

export const getAllAssociateEmailToCampaignVersion = (query, extendedQuery) => {
  return function(dispatch){
    associatedEmailToCampaignVersion.getAllAssociateEmailToCampaignVersion(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_ALL,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_ALL,
          payload: err.response
        });
      });
  };
};

export const getAssociateEmailToCampaignVersionById = (id) => {
  return function(dispatch){
    associatedEmailToCampaignVersion.getAssociateEmailToCampaignVersionById(id)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_BY_ID,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_BY_ID,
          payload: err.response
        });
      });
  };
};

export const updateAssociateEmailToCampaignVersion = (data) => {
  return function(dispatch){
    associatedEmailToCampaignVersion.updateAssociateEmailToCampaignVersion(data)
      .then(function(res){
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_EDIT,
          payload: err.response
        });
      });
  };
};

export const newAssociateEmailToCampaignVersion = data => dispatch => {
  return new Promise((resolve, reject) => {
    associatedEmailToCampaignVersion.newAssociateEmailToCampaignVersion(data)
      .then(res => {
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};
