import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import constants from '../../constants/app-constants';

const moment = extendMoment(originalMoment);
const today = moment();

var start = new Date(today.clone().startOf('month'));
var end  = new Date(today.clone());

//In case of the first day of month, the last month period have to be selected
if(today.date() === 1){
  const todayLastMonth = today.clone().subtract(1, 'month');

  start = new Date(todayLastMonth.clone().startOf('month'));
  end  = new Date(todayLastMonth.clone().endOf('month'));
}

const initialState = {
  setCalendarDates: [
    {
      startDate: start,
      endDate: end,
      key: 'firstSelection'
    }
  ],
  isCalendarHidden: true
};

const DataRangePicker = (state = initialState, action) => {
  switch (action.type) {
  case constants.shared.SET_CALENDAR_DATES: {
    return { ...state, setCalendarDates: action.payload };
  }

  case constants.shared.IS_CALENDAR_HIDDEN: {
    return { ...state, isCalendarHidden: action.payload };
  }

  default:
    return state;
  }
};

export default DataRangePicker;
