import constants from '../../constants/app-constants';

const initialState = {
  withdrawalModalIsOpen: false,
  withdrawalListModalIsOpen: false,
  withdrawals: null
};

const UserWithdrawal = (state = initialState , action) => {
  switch(action.type){
  case constants.user.WITHDRAWAL_SETMODALSTATUS : 
    return {...state , withdrawalModalIsOpen : action.payload};
  case constants.user.WITHDRAWALLIST_SETMODALSTATUS :
    return {...state , withdrawalListModalIsOpen : action.payload};
  case constants.user.WITHDRAWALLIST_POPULATE :
    return {...state , withdrawals : action.payload};
  default:
    return state;
  }
};

export default UserWithdrawal;