import React from 'react';
import moment from 'moment';
import { cloneDeep, get } from 'lodash';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import { withQueryHelper } from '../../../../decorators/queryHelperDecorator/queryHelper-decorator';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import ValueOrderStatusDropbox from '../../../shared/dropbox/ValueOrderStatusDropbox';
import DateRange from '../../../shared/dateRange/DateRange';
import commonFunctions from '../../../../utils/helpers/CommonFunctions';
import orderService from '../../../../utils/services/order'
import * as orderActions from '../../../../actions/admin/orderActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import './List.scss';

@withQueryHelper
export class OrderList extends React.Component {
  
  state = {
    filterByClient: '',
    filterByStatus: '',
    searchInputField:'',
    startDate: moment()
      .subtract(7, 'd')
      .toDate(),
    endDate: moment().toDate(),
    loading: false,
    totalRecords: 0,
    individualOrderStatus: ''
  };  

  componentDidUpdate = prevProps => {
    if (this.props.order != prevProps.order) this.formatData();
  };

  componentWillUpdate(nextProps) {
    if (this.props.orderEdit != nextProps.orderEdit) {
      if (get(nextProps, 'orderEdit.status')) {
        this.updateSelectedOrderOnTable();
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'Order Status saved with success',
            timeout: 10000
          })
        );
      }
      if (get(nextProps, 'orderEdit.data')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error: ' + nextProps.orderEdit.data.message,
            timeout: 10000
          })
        );
      }
    }
  }

  updateSelectedOrderOnTable = () => {
    const status = this.state.individualOrderStatus.slice();
    if (status) {
      this.state.individualOrderObj.status = status;
    }
  };

  formatData = () => {
    const data = commonFunctions.formatData(this.props.order);
    if (data.page && Array.isArray(data.page)) {
      for (let row of data.page) {
        row.testMode = row.testMode ? 'True' : 'False';
      }
    }

    this.setState({
      orderList: data.page,
      totalRecords: data.totalCount,
      loading: false
    });
  };

  resetFilter = event => {
    this.setState(
      {
        startDate: moment()
          .subtract(7, 'd')
          .toDate(),
        endDate: moment().toDate(),
        filterByClient: '',
        filterByStatus: '',
        searchInputField:'',
        individualOrderStatus: ''
      },
      () => this.onLazyLoad(event)
    );
  };

  onLazyLoad = event => {
    
    if(!event || !event.rows){
      event.rows = 10;
    }

    this.state.first = !event || isNaN(event.first) ? 0 : event.first;
    
    this.setState({ loading: true });
    const query = this.getSimpleQuery(event, this);
    const extendedQuery = this.getExtendedQuery(event, this);
    this.props.dispatch(orderActions.getOrders(query, extendedQuery));    
  };
  
  onChangeOrderStatus = e => {
    const status = e.originalEvent.target.textContent;
    this.setState({ individualOrderStatus: status });
  };

  showDialog = (e, { rowData }) => {
    const { status } = rowData;
    this.setState({ individualOrderStatus: status, dialogVisible: true, individualOrderObj: rowData });
  };

  triggerPostReward = (e, {rowData}) => {

    this.setState({ loading: true });

    const { _id, postRewardVersion, type } = rowData;
    let extendedQuery = `?postRewardVersion=${postRewardVersion}&type=${type}`;

    orderService.triggerOrderPostReward(_id, extendedQuery)
      .then((result) => {

        let { orderList } = this.state;

        _.map(orderList, (o) => {

          if(o._id == _id){
            o.rewardAvailableToSend = false;
          }

        });

        this.setState({ orderList: orderList });

        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'The order was succesfully sent to post reward process.',
            timeout: 5000
          })
        );
      })
      .catch((error) => {
        
        let errorObj = {
          type: 'error',
          message: (error.response.status == 400) 
            ? error.response.data
            : 'Something went wrong trying to send data to post reward process: ' + error.response.data,
          timeout: 5000
        };

        this.props.dispatch(
          globalMessageActions.showMessage(errorObj)
        );

      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  showHistoryDialog = (e, { rowData }) => {
    
    let extendedQuery = '?$sort=-effectivatedAt&$limit=10';

    this.setState({ loadingOrderHistory: true, orderHistoryDialogVisible: true });

    orderService.getOrderHistory(rowData._id, extendedQuery)
    .then((res) => {
      
      let { data } = res;

      // order by date ASC
      data.page = _.orderBy(data.page, ['effectivatedAt'], ['asc']);

      // supress "order status" repetiton on result list
      data = commonFunctions.suppressArraySequencialFieldValueRepetition(
        data.page, 'objectSnapshot.status'
      );

      // order by date DESC
      data = _.orderBy(data, ['effectivatedAt'], ['desc']);

      // transform properties
      data.map((item) => {
        item.effectivatedDate = commonFunctions.formatDate(item.effectivatedAt, 'YYYY-MM-DD HH:mm');
        item.status = item.objectSnapshot.status;
        item.changer = `${item.userName} ${item.userLastName}`;        
      });

      this.setState({ selectedOrderHistory: data, loadingOrderHistory: false });
    })
    .catch((err) => {
      this.props.dispatch(
        globalMessageActions.showMessage({
          type: 'error',
          message: 'Was not possible to retrieve Order History: ' + err,
          timeout: 10000
        })
      );
    });    
  };

  actionTemplate = (e, rowData) => {
    return (
      <div>
        <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={e => this.showDialog(e, rowData)} disabled={e.type === 'external-order'}/>
        <Button type="button" icon="fa-history" className="ui-button-warning" onClick={e => this.showHistoryDialog(e, rowData)}  disabled={e.type === 'external-order'}/>
        <Button type="button" icon="fa-envelope" className="ui-button-warning" title={ 'Post Reward Version:' + e.postRewardVersion} onClick={e => this.triggerPostReward(e, rowData)}></Button>
        
      </div>
    );
  };


  withToolTip(rowData, column)
  {
    const value = rowData[column.field];
    return <span title={value}>{value}</span>;
  }


  columns() {
    return [
      { field: 'clientName', header: 'Client', sortable: true, style: { width: '70px' } },
      { field: 'clientOrderId', header: 'Client Order Id', sortable: true, style: { width: '80px' } },
      { field: 'createdAt', header: 'Date', sortable: true, style: { width: '40px' } },
      { field: 'status', header: 'Status', sortable: true, style: { width: '40px', overflow: 'hidden', textOverflow: 'ellipsis'}, body: this.withToolTip},
      { field: 'sharerId', header: 'Sharer Id', sortable: true, style: { width: '100px' } },
      { field: 'buyerEmail', header: 'Buyer Email', sortable: true, style: { width: '80px', overflow: 'hidden', textOverflow: 'ellipsis' }, body: this.withToolTip },
      { field: 'total', header: 'Total', sortable: true, style: { width: '40px' } },
      { field: 'currency', header: 'Currency', sortable: true, style: { width: '40px' } },
      { field: 'commission', header: 'Commission', sortable: true, style: { width: '50px' } },
      { field: 'testMode', header: 'Test mode', sortable: true, style: { width: '40px' } },
      { field: 'type', header: 'Type', sortable: true, style: { width: '40px' } },
      { field: 'actionsIcon', style: { textAlign: 'center', width: '50px' } }
    ];
  }

  onUpdateOrderStatus = () => {
    const status = this.state.individualOrderStatus.slice();
    if (status) {
      this.setState({ dialogVisible: false });
      const { clientId, _id } = this.state.individualOrderObj;
      let payload = cloneDeep(this.state.individualOrderObj);
      payload.status = status;
      this.props.dispatch(orderActions.updateOrderById(clientId, _id, { status }));
    } else {
      this.props.dispatch(
        globalMessageActions.showMessage({
          type: 'warning',
          message: 'Invalid order status. Please check!',
          timeout: 5000
        })
      );
    }
  };

  dialogFooter = () => {
    return <Button label="Update" icon="fa fa-check" onClick={() => this.onUpdateOrderStatus()} />;
  };

  orderHistoryDialogFooter = () => {
    return <Button label="close" icon="fa fa-close" onClick={ () => this.setState({ orderHistoryDialogVisible: false } )} />;
  };

  renderDialog = () => {
    return (
      <Dialog
        header="Update Order Status"
        footer={this.dialogFooter()}
        visible={this.state.dialogVisible}
        width="350px"
        modal={true}
        resizable={true}
        responsive={true}
        
        onHide={e => this.setState({ dialogVisible: false })}
      >
        <ValueOrderStatusDropbox
          name="individualOrderStatus"
          required="true"
          onChange={e => this.onChangeOrderStatus(e)}
          value={this.state.individualOrderStatus}
        />
      </Dialog>
    );
  };

  renderHistoryDialog = () => {
    return (
      <Dialog
        header="Order Update History"
        footer={this.orderHistoryDialogFooter()}
        visible={this.state.orderHistoryDialogVisible}
        width="850px"
        modal={true}
        resizable={true}
        responsive={true}
        onHide={e => this.setState({ selectedOrderHistory: null, orderHistoryDialogVisible: false })}
      >
        
        <DataTable
          value={this.state.selectedOrderHistory}
          loading={this.state.loadingOrderHistory}
          responsive={true}
          rows={10}
          ref={el => (this.dt = el)}
          >

          <Column key='effectivatedDate' field='effectivatedDate' header='Change Date' style={{width : '200px'}} />
          <Column key='status' field='status' header='Status' style={{width : '200px'}} />
          <Column key='changer' field='changer' header='Changer' style={{width : '200px'}} />
          <Column key='originType' field='originType' header='Origin' style={{width : '200px'}} />
       
        </DataTable>

      </Dialog>
    );
  };

  renderDataTable = () => {
    const dataTableColumns = this.columns().map((col, i) => {
      if (col.field === 'actionsIcon') {
        return (
          <Column
            key={i}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            style={col.style}
            body={this.actionTemplate}
          />
        );
      } else {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} body={col.body}/>;
      }
    });

    return (
      <DataTable
        value={this.state.orderList}
        responsive={true}
        sortMode="multiple"
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        ref={el => (this.dt = el)}
        loading={this.state.loading}
        first = {this.state.first}
      >
        {dataTableColumns}
      </DataTable>
    );
  };

  render() {
    return (
      
      <div>        
        <div className="content-section implementation">
          <h3>Orders</h3>
          <DateRange
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChangeStartDate={e => this.setState({ startDate: e.value })}
            onChangeEndDate={e => this.setState({ endDate: e.value })}
          />

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Filter by Client:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <ClientsDropbox
                  name="clientId"
                  required="true"
                  onChange={e => this.setState({ filterByClient: e.value })}
                  value={this.state.filterByClient}
                />
              </span>
            </div>
          </div>

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Filter by Status:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <ValueOrderStatusDropbox
                  name="value_orderStatus"
                  required="true"
                  onChange={e => {
                    this.setState({ filterByStatus: e.originalEvent.target.textContent });
                  }}
                  value={this.state.filterByStatus}
                />
              </span>
            </div>
          </div>

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText onChange={(e) => this.setState({ searchInputField: e.target.value })} value={this.state.searchInputField}/>
              </span>
            </div>
          </div>

          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
            </div>
          </div>


          {this.renderDataTable()}
          {this.renderDialog()}
          {this.renderHistoryDialog()}
        </div>
      </div>
    );
  }
}

export default connect(store => {
  return {
    order: store.adminOrder.orderGetAll,
    orderEdit: store.adminOrder.orderEdit
  };
})(OrderList);
