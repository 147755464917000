import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'chartjs-plugin-datalabels';

export default function ChartDataComparison( { ...props } ) {

  const { title, chartData, type, chartDate, className } = props;

  const  renderLineChart = () => {

    const tooltipColor = '#485465', typography = ' \'Montserrat\', sans-serif', fontSize = 11, legendPosition = 'top'; 

    if(chartData.datasets && chartData.datasets.length === 6) {
      var options = {
        responsive: true,
        legend: {
          position: legendPosition,
          labels: {
            fontFamily: typography,
            boxWidth: 12
          }
        },
        scales: {
          xAxes: [   
            {
              gridLines: { display:false, drawBorder: true },
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Current Period',
                fontSize: fontSize + 1,
                fontFamily: typography,
                fontStyle: 'bold'
              },
              id: 'x-axis-1',
              ticks:{
                fontSize: fontSize,
                fontFamily: typography,
                callback:function(labelx) {
                  var label = labelx.split('#')[0];
                  return label;
  
                }
              }
            },
            {
              gridLines: { display:false, drawBorder: true },
              display: true,
              id: 'x-axis-2',
              scaleLabel: {
                display: true,
                labelString: 'Previous Period',
                fontSize: fontSize + 1,
                fontFamily: typography,
                fontStyle: 'bold'
              },
              ticks: {
                fontSize: fontSize,
                callback:function(labely) {
                  var label = labely.split('#')[1];
                  return label;
  
                }
              }
            }
          ],
  
          yAxes: [{ gridLines: { display:true, drawBorder: false, lineWidth: 2 } }]
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          titleFontFamily: typography,
          titleFontStyle: 'lighter',
          bodyFontFamily: typography,
          backgroundColor: tooltipColor,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.cornerRadius = 1;
          },
          callbacks: {
            label: function (tooltipItems, data) {
              tooltipItems.xLabel = moment(tooltipItems.xLabel).format('DD/MM/YYYY');
              return  data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel;
           }
          }
        },
        //https://chartjs-plugin-datalabels.netlify.com/
        plugins: {
          datalabels: {
            display: false
          }
        }
      };
    } else {
      var options = {
        responsive: true,
        legend: {
          position: legendPosition,
          labels: {
            fontFamily: typography,
            boxWidth: 12
          }
        },
        scales: {
          xAxes: [   
            {
              gridLines: { display:false, drawBorder: true },
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Current Period',
                fontSize: fontSize + 1,
                fontFamily: typography,
                fontStyle: 'bold'
              },
              id: 'x-axis-1',
              ticks:{
                fontSize: fontSize,
                fontFamily: typography,
                callback:function(labelx) {
                  var label = labelx.split('#')[0];
                  return label;
  
                }
              }
            }
          ],
  
          yAxes: [{ gridLines: { display:true, drawBorder: false, lineWidth: 2 } } ]
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          titleFontFamily: typography,
          titleFontStyle: 'lighter',
          bodyFontFamily: typography,
          backgroundColor: tooltipColor,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.cornerRadius = 1;
          },
          callbacks: {
            label: function (tooltipItems, data) {
              tooltipItems.xLabel = moment(tooltipItems.xLabel).format('DD/MM/YYYY');
              return  data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel;
            }
          }
        },
        // https://chartjs-plugin-datalabels.netlify.com/
        plugins: {
          datalabels: {
            display: false
          }
        }
      };
    }

    return(  
      <Line data={chartData} options={options} />
    );
  };

  return (
    <div className={className}>

      <div className="label">
        <span>{title}</span>
        <span className="date-range">{chartDate}</span>
      </div>
          
      { type === 'LineChart' && renderLineChart() }  
          
    </div>
  );

}