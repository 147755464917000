export default {
  
  formValid: (data) => {
    var formValid = true;
    _.forEach(data,(value) => {
      if(value == ''){
        formValid = false;
      }
    })
    return formValid;
  },

  isValidEmail: (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
};