import constants from '../../constants/app-constants';
import layoutService from '../../utils/services/layout';

export const getCountryById = (countryId) => {
  return function(dispatch){
    layoutService.getCountryById(countryId)
      .then(function(res){
        dispatch({
          type: constants.admin.LAYOUT_COUNTRY_GET_BY_ID,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.LAYOUT_COUNTRY_GET_BY_ID,
          payload: err.response
        });
      });
  };
};

export const getCountries = () => {
  return function(dispatch){
    layoutService.getCountries()
      .then(function(res){

        // se values on local storage
        localStorage.setItem('countries', JSON.stringify(res.data));

        dispatch({
          type: constants.admin.LAYOUT_COUNTRIES_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.LAYOUT_COUNTRIES_GET,
          payload: err.response
        });
      });
  };
};

export const getCountriesFromCache = () => {
  
  // get countries value from local storage
  let cStr = localStorage.getItem('countries');

  if(cStr){
    return JSON.parse(cStr);
  }
  
  return [];
};

export const setCountry = (countryId) => { 
  localStorage.setItem('countryId', JSON.stringify(countryId));
  return {
    type: constants.admin.LAYOUT_COUNTRY_SET,
    payload: countryId
  };
};