import React from 'react';
import { Link } from 'react-router';
import Menu from '../../../shared/menu/menu';
import {connect} from 'react-redux';
import * as headerActions from '../../../../actions/user/headersActions';

@connect((store) => {
  return {
    headerMenuVisible: store.userHeader.headerMenuVisible,
  };
})

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.returnToRoot = this.returnToRoot.bind(this);
  }

  showMobileMenu(e){
    e.preventDefault();
    this.props.dispatch(headerActions.toggleHeaderMenu(this.props.headerMenuVisible));
  }

  logout(){
    this.props.router.push({ pathname: '/logout' });
  }

  returnToRoot(){
    this.props.router.push({ pathname: '/user' });
  }

  render () {
    return (
      <div className="grid-container  main-container">
        <header className="header">
          <div className="grid-container  grid-parent">
            <div className="grid-20 hide-on-desktop mobile-grid-10 tablet-grid-5">
              <div onClick={(event) => {this.showMobileMenu(event);}} className="mobile-menu-handle">
                <a href=""><i className="fa fa-align-justify"></i></a>
              </div>
            </div>
            <div className="grid-15 mobile-grid-20 tablet-grid-20">
              <div className="logo">
                <img onClick={this.returnToRoot} src="/images/logo-white.png" />
              </div>
            </div>
            <div children={"grid-parent"} className="grid-50 mobile-grid-100 tablet-grid-100  main-menu-holder">
              <Menu menuItems={this.props.menuItems} />
            </div>
            <div className="grid-35 tablet-grid-30  header-extra-holder">
              <ul id="headerExtra">
                <li>
                  <button onClick={this.logout} className="primary hide-on-tablet hide-on-mobile">Logout</button>
                </li>
                <li>
                  <Link to={'/user/settings'}><i className="fa fa-cog"></i></Link>
                </li>
                {/*<li>*/}
                  {/*<a href=""><i className="fa fa-bell"></i></a>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;