import React from 'react';
import UpdateLogo from '../../updateLogo/UpdateLogo';
import Modal from 'react-modal';
import config from '../../../../../config/config';
import EditCountry from './Settings.Company.EditCountry';
import {connect} from 'react-redux';
import * as clientActions from '../../../../../actions/client/clientActions';
import * as profileActions from '../../../../../actions/client/profileActions';
import * as settingsActions from '../../../../../actions/client/settingsActions';

const logoModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

const editCountryModalStyle = {
  content : {
    'width': '500px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

@connect((store)=>{
  return {
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    modalIsOpen: store.clientProfile.modalIsOpen,
    editCountryModalIsOpen: store.clientSettings.modalIsOpen
  };
})
class SettingsCompany extends React.Component{
  constructor(){
    super();
    this.state = {
      showSecretKey: false
    };
  }
  componentWillMount () {
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
  }

  ToggleSecretKey (){
    this.setState({showSecretKey: !this.state.showSecretKey});
  }

  openModal (e) {
    e.preventDefault();
    this.props.dispatch(profileActions.toggleProfileModal(!this.props.modalIsOpen));
  }

  openEditCountryModal(e){
    e.preventDefault();
    this.props.dispatch(settingsActions.toggleEditLocationModal(!this.props.editCountryModalIsOpen));
  }

  render(){
    var secretKeyInputType = 'password';
    var secretKeyShowButton = 'fa fa-eye';
    if(this.state.showSecretKey){
      secretKeyInputType = 'text';
      secretKeyShowButton = 'fa fa-eye-slash';
    }

    return (
      <div>
        <h1>Company</h1>
        { this.props.clientObj ?
          <div id="settings-company">
            <div className="grid-20 settings-company-image-holder">
              <div className="settings-company-image">
                <img src={this.props.clientObj.imageId ? `${config.IMG.SRC}/${this.props.clientObj.imageId.substring(0,6)}/${this.props.clientObj.imageId}` : `/images/holding-logo.png` } alt="profile image"/>
              </div>
              <button onClick={(e) => this.openModal(e)} className="primary">Edit</button>
              <Modal contentLabel={'Logo Update'} isOpen={this.props.modalIsOpen}
                shouldCloseOnOverlayClick={true}
                style={logoModalStyle}
              >
                <UpdateLogo/>
              </Modal>
            </div>
            <div className="grid-80 settings-company-info-section">
              <div className="settings-company-group-name">{this.props.clientObj.name}</div>
              {/* <div className="settings-company-trade-name settings-company-bold">{this.props.clientObj.tradingName}</div>
              <div className="settings-company-website settings-company-bold">{this.props.clientObj.website}</div>
              <div className="settings-company-location settings-company-bold">{this.props.clientObj.location}</div> */}
              <div className="settings-company-api-details">
                <h4 className="active">Retailer Id</h4>
                <p>{this.props.clientObj._id}</p>
                {/* <h4 className="active">Secret key</h4>
                <div>
                  <input ref={(input) => this.secretKey = (input)} type={secretKeyInputType} defaultValue={this.props.clientObj.secret}/>
                  <div className="settings-company-toggle-holder">
                    <i onClick={ (e) => this.ToggleSecretKey(e) } ref={(revealIcon) => this.revealIcon = (revealIcon)} className={secretKeyShowButton}></i>
                  </div>
                </div> */}
              </div>
            </div>
            <Modal  contentLabel={'Edit Country'} isOpen={this.props.editCountryModalIsOpen}
              shouldCloseOnOverlayClick={true}
              style={editCountryModalStyle}
            >
              <EditCountry clientObj={this.props.clientObj} />
            </Modal>
          </div>

          :
          false
        }
      </div>
    );
  }
}

export default SettingsCompany;