import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import request from 'axios';
import config from '../../../config/config';
import {MultiSelect} from 'primereact/components/multiselect/MultiSelect';
import { ToggleButton } from 'primereact/components/togglebutton/ToggleButton';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Button} from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import FormElement from '../../shared/form/FormElement';

import './_GlobalVar.scss';

/**
 * The Global Var component 
 * 
 * This component handles with Soretos's Global Var environment
 * 
 * It can be used anywhere and standalone regardless any other side implementation
 * 
 */

class globalVar extends React.Component {

  /**
   * LOCAL VARIABLES
   */

  // backend API endpoint
  endpoint = config.API.BASEURL + '/globalVars';
  
  // available config types
  availableTypes = { 
    NUMERIC : 'NUMERIC', 
    TEXT : 'TEXT', 
    ARRAY : 'ARRAY', 
    BOOLEAN : 'BOOLEAN', 
    DATE : 'DATE', 
    DATETIME : 'DATETIME', 
    TIMESTAMP : 'TIMESTAMP',
    JSON : 'JSON'
  };

  // time to keep the message div visible
  messageFadeOut = 2000;

  /**
   * Class constructor
   * @param {*} props 
   */
  constructor(props){
    super(props);

    this.state = {
      context : props.context,
      objectId : props.objectId,
      result : [],
      pendingTransaction: true,
      modalMode : props.modalMode,
      settingsModalVisible: props.settingsModalVisible
    };
  }

  componentDidMount(){

    if(this.state.context && this.state.objectId){
    
      // basic properties validation
      this.state.renderLoadError = this.propertyValidation(this.state);

      // fetch the new values from API
      this.fetchVars(this.state.context, this.state.objectId);
    }
  }

  /**
   * Component will receive props
   * @param {*} nextProps 
   */
  componentWillReceiveProps(nextProps) {
    
    // check if the 'context' or 'objectId' has changed
    if(nextProps.context != this.state.context 
        || nextProps.objectId != this.state.objectId || nextProps.settingsModalVisible != this.state.settingsModalVisible){
      
      // basic properties validation
      this.state.renderLoadError = this.propertyValidation(nextProps);

      // set the new properties on state
      this.setState(
        { 
          context : nextProps.context, 
          objectId : nextProps.objectId, 
          settingsModalVisible: nextProps.settingsModalVisible,
          result: {}
        });

      // fetch the new values from API
      this.fetchVars(nextProps.context, nextProps.objectId);
    }
  }

  /**
   * Basic adjustments on data from API
   * @param {*} data 
   */
  adjustValues(data){

    // resolve the 'considered' value
    // choose between value or falback and store it in a new property in common
    let resolved = _.map(data, (item) => {

      item.consideredValue = item.value ? item.value : item.fallbackValue;
      
      if(!item.multiValue){
        item.consideredValue = item.consideredValue[0]; 
      }
      
      // keep the original object ID
      // when we fallback a value the object ID is removed from it
      // it's gonna map to inform the backend to remove it from cache if exists
      item.originalObjectId = this.state.objectId;

      return item;
    });

    // order list by 'clientId' in order to let the custom vars after the global ones
    return _.sortBy(resolved, ['clientId']);
  }

  /**
   * Finger Print original list
   * create an 'original' version finger print to handle with changes
   * @param {*} originalResult 
   */
  fingerPrintOriginalList(originalResult){

    return _.map(originalResult, (item) => {

      item.fingerPrint = this.getItemFingerPrint(item);

      return item;
    }); 
  }

  /**
   * Get item finger print
   * by create a string from row
   * @param {*} item 
   */
  getItemFingerPrint(item){

    return JSON.stringify(_.omit(item, 'consideredValue', 'fingerPrint', 'originalObjectId')).trim();
  }

  /**
   * Get changed values
   * retrieve all registers that has changed
   */
  getChangedValues(){

    return _.filter(this.state.result, item => !_.isEqual(this.getItemFingerPrint(item), item.fingerPrint));
  }

  /**
   * Handle value change from value components
   */
  handleValueChange(definition, newValue){

    // only global configurations can be updated
    if(!definition.clientId){

      delete definition.setToFallback;
      definition.objectId = this.state.objectId;
      definition.value = (definition.multiValue) ? newValue : [newValue];

      this.updateContext(definition);
    }
  }

  /**
   * Handle boolean value change from value components
   */
  handleBooleanValueChange(definition, newValue){

    newValue = (newValue === true) ? 'true' : 'false';
    this.handleValueChange(definition, newValue);
  }

  /**
   * Updated context
   * @param {*} updatedValue 
   */
  updateContext(updatedValue){

    // get value to update from context
    let valueToUpdate = _.findLast(this.state.result, (item) => {

      if(updatedValue._id){
        return item._id == updatedValue._id;
      }else{
        return item.settingKey == updatedValue.settingKey;
      }

    });

    // value was found
    if(valueToUpdate){

      // check if the updated value is equal to fallback
      if(_.isEqual(updatedValue.value, updatedValue.fallbackValue)){
        
        updatedValue.value = null;
        updatedValue.objectId = null;
        updatedValue.clientId = null;

        if(!updatedValue.fallbacked){
          updatedValue.setToFallback = true;
        }else{
          delete updatedValue.setToFallback;
        }    
      }

      valueToUpdate = updatedValue;

      // set state
      this.setState({ result : this.adjustValues(this.state.result) });
    }
  }

  /**
   * Fallback value
   * set the value to be equal to the default
   * @param {*} updatedValue
   */
  fallbackValue(updatedValue){

    updatedValue.value = updatedValue.fallbackValue;

    this.updateContext(updatedValue);
  }

  /**
   * Add custom value
   */
  addCustomValue(){
    alert('This functionality was not implemented yet');
  }

  /**
   * Build array option from plain array
   * 
   * @param {*} items 
   */
  buildArrayOptionsFromPlainArray(items){

    return _.map(items, item => {return { label: item, value: item};} );
  }

  /**
   * Build config item
   * 
   * This method is gonna render the configuration item
   *
   * @param {*} data 
   */
  configItem(data){

    let valueInput = null;
    let fallbackButton = null;
    let deleteButton = null;
    let restrictCheck = null;

    // is the value a multi value?
    if(data.multiValue){

      valueInput = <MultiSelect value={data.consideredValue} options={this.buildArrayOptionsFromPlainArray(data.valueOption)} onChange={(e) => this.handleValueChange(data, e.value)} />;
    
    }else{

      switch(data.type){
      
      case this.availableTypes.TEXT:
        valueInput = <InputText value={data.consideredValue} onChange={(e) => this.handleValueChange(data, e.target.value)}/>;
        break;
      case this.availableTypes.BOOLEAN:
        valueInput = <ToggleButton checked={data.consideredValue === 'true' || data.consideredValue === true } onChange={(e) => this.handleBooleanValueChange(data, e.value)} />;
        break;
      case this.availableTypes.JSON:
        valueInput = <FormElement value={data.consideredValue} type='codearea' mode='javascript' className="json-var" onChange={(e) => this.handleValueChange(data, e.target.value)} ></FormElement>
        break;

      default:
        valueInput = <InputText value={data.consideredValue} onChange={(e) => this.handleValueChange(data, e.target.value)} />;
      }
    }

    ///
    // handle action items
    ///

    // should the fallback button be available?
    // the fallback button should only be available when the value was specialized
    // (!data.clientId) = global configuration | (data.objectId) = specialized
    if(!data.clientId && data.objectId){
      fallbackButton = <Button className="p-button-icon" label="fallback" onClick={() => this.fallbackValue(data)} style={{ marginTop:  '-0.5vh'}} />;
    }

    // should delete button be available?
    // only custom configuration can be deleted
    if(data.clientId){
      deleteButton = <Checkbox label="X" onChange={e => this.setState({checked: e.checked})} checked={this.state.checked} className="p-button-danger"></Checkbox>; 
    }

    // should restric checkbox be available?
    // restrict configuration can only be used by a specific objectId
    if(data.restrict){
      restrictCheck = <Checkbox onChange={e => this.setState({checked: e.checked})} checked={this.state.checked}></Checkbox>;
    }

    return (

      <div>

        <div className="ui-g ui-fluid">

          {/* setting key */}
          <div className="ui-g-12 ui-md-5">
            <div className="ui-inputgroup">
              <span className="ui-inputgroup-addon"><i className="fa fa-fw fa-key"></i></span>
              <InputText disabled value={data.settingKey} tooltip="Enter your username" />   
            </div>
          </div>
 
          {/* value input */}
          <div className="ui-g-12 ui-md-5">
            <div className="ui-inputgroup">
              <span className="ui-inputgroup-addon"><i className="fa fa-fw fa-pencil"></i></span>
              {valueInput} 
            </div>
          </div>
 

          <div className="ui-g-12 ui-md-2">
            {/* fallback button */}
            <div className="ui-inputgroup">
              {fallbackButton}    
            </div>

            {/* delete button */}
            <div className="ui-inputgroup">
              {deleteButton}    
            </div>

            {/* restrict checkbox */}
            <div className="ui-inputgroup">
              {restrictCheck}    
            </div>
          </div>
        </div>

      </div>        
    
    );
  }

  /**
   * Render 'no results' panel
   */
  noResultsPanel(){

    if(this.state.pendingTransaction === true){
      return null;
    }

    return (
      <div className="ui-g ui-fluid">
        <div className='ui-g-12 ui-md-4 gv-message-panel'>
          <h3>No configurations found !</h3>
        </div>
      </div>      
    );
  }

  /**
   * Render context title
   */
  contextTitle(){
    return (
      <div className="gv-context-title">
        <h3><i className="fa fa-fw fa-folder-open"></i> {this.state.context}</h3>
      </div>
      
    );
  }

  /**
   * Render base action button
   */
  baseActionButtons(){

    let saveButton = (this.state.result && this.state.result.length > 0) 
      ? <Button onClick={() => this.saveVars()} label="SAVE SETTINGS" /> 
      : null;

    return(

      <div>
        <div className="ui-g">
          <div className='ui-g-12 ui-md-1'>
            <Button onClick={this.addCustomValue}><i className="fa fa-plus"></i></Button>
          </div>
        </div>

        <div className="ui-g">
          <div className='ui-g-12 ui-md-4'>
            {saveButton}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Set message to be shown on overlay div
   * 
   * @param {*} message 
   * @param {*} error 
   */
  setMessage(message, error){
    
    // set message on state
    this.setState({ message : {message : message, error: error }});

    // clean message after timeout
    setTimeout(() => {
      this.setState({ message : null });
    }, this.messageFadeOut);
  }

  /**
   * Overlay message DIV
   */
  overlayMessageDiv(){

    const divClass = ['gv-overlay'];

    // check if there is a message
    if(this.state.message){ 
      if(!this.state.message.error){
        divClass.push('show'); 
      }else{
        divClass.push('show-error'); 
      }
    }else{
      return null;
    }

    return (
      <div className={divClass.join(' ')}>
        <h2>{this.state.message.message}</h2>
      </div>
    );
  }

  /**
   * Render HTML
   */
  getRender() {

    // config item list
    const listItems = (this.state.result && this.state.result.length > 0) 
      ? this.state.result.map((d) => this.configItem(d)) 
      : this.noResultsPanel();

    const buttons = this.baseActionButtons();

    if(!this.state.renderLoadError){

      return (
        
        <div style={{position: 'relative'}}>

          {this.overlayMessageDiv()}
          
          {this.contextTitle()}

          {listItems}
          
          {buttons}          

        </div>

      );
    }
    else{

      // Load render
      return (
        <div style={{position: 'relative'}}>
          {this.contextTitle()}
          <div className="gv-message-panel">
            <h3>An error occured trying to load the component</h3>
            <p>{this.state.renderLoadError}</p>
          </div>
        </div>
      );
    }
  }

  render(){
    if (this.state.modalMode){
      return(
        <Dialog
          header={'Custom Fields'}
          visible={this.state.settingsModalVisible}
          width="800px"
          modal={true}
          resizable={true}
          responsive={true} 
          onHide={this.closeDialog.bind(this)}
        >
          {this.getRender()}
        </Dialog>
      );
    }else{
      return this.getRender();
    }
  }

  closeDialog(){
    this.props.oncloseModal();
    // this.setState({settingsModalVisible: false});
  }

  /**
   * Call API to retrieve Global Vars data
   * 
   * @param {*} context 
   * @param {*} objectId 
   */
  fetchVars(context = '', objectId = ''){

    // validate param
    if(!context || !objectId){
      return;
    }
    
    // set pending transaction
    this.setState({ pendingTransaction: true });

    // HTTP request
    request({
      url: this.endpoint,
      method: 'get',
      params: { 
        context : context, 
        objectId: objectId 
      }
    })
      .then((result) => {

      // finger print the list retrieved from API
        result.data = this.fingerPrintOriginalList(result.data);
        // basic adjustments
        result.data = this.adjustValues(result.data);

        // set result on state
        this.setState({ result: result.data });

      }).catch(() => {
        this.setState({ renderLoadError: 'Error retrieving data from API' });
      })
      .finally(() => this.setState({ pendingTransaction: false }));

  }

  /**
   * Save vars
   */
  saveVars(){

    // get values that has changed
    let changedValues = this.getChangedValues();

    // any changes?
    if(changedValues && changedValues.length > 0){

      // HTTP post
      request.post(this.endpoint, {
        globalVars: changedValues
      })
        .then(() => {

          // set success message
          this.setMessage('Global Vars saved');

          // fetch vars again
          this.fetchVars(this.state.context, this.state.objectId);
          
        })
        .catch((error) => {

          // set error message
          this.setMessage(`Error saving data : ${error}`, true);
        });
      
    }else{

      this.setMessage('Nothing has changed');
    }
  }

  /**
   * Properties validations
   */
  propertyValidation = (props) => {

    let errorMsg = '';

    if(!props.context){
      errorMsg += '; Property "context" not defined';
    }
    else if (!props.objectId){
      errorMsg += '; Property "objectId" not defined';
    }

    return errorMsg;
  }

  static propTypes = { 
    context: PropTypes.string.isRequired,
    objectId: PropTypes.string.isRequired
  };

}

// export
export default globalVar;