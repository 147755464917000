import React from 'react';

import ChartShare from './Dashboard.ChartShare';
import ChartRevenue from './Dashboard.ChartRevenue';
import ChartClick from './Dashboard.ChartClick';


class ChartPanel extends React.Component {
  render () {
    var chartPanel;
    if (this.props.sharesChart.data.labels.length > 0 && this.props.revenueChart.data.labels.length > 0 && this.props.clicksChart.data.labels.length > 0) {
      chartPanel = <div>
        <div className="grid-33 tablet-grid-33 mobile-grid-100">
          <ChartShare data={this.props.sharesChart.data} options={this.props.sharesChart.options} />
        </div>
        <div className="grid-33 tablet-grid-33 mobile-grid-100">
          <ChartRevenue data={this.props.revenueChart.data} options={this.props.revenueChart.options} />
        </div>
        <div className="grid-33 tablet-grid-33 mobile-grid-100">
          <ChartClick data={this.props.clicksChart.data} options={this.props.clicksChart.options} />
        </div>
      </div>;
    }

    return (
      <div className='grid-container'>
       {chartPanel}
      </div>
    );
  }
}

export default ChartPanel;