import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const UserManagement = (state = initialState , action) => {
  switch(action.type){
  
    case constants.admin.USER_MANAGEMENT_GET_BY_ID: {
      return {...state, userManagementGetById: action.payload};
    }

    case constants.admin.USER_MANAGEMENT_EDIT: {
      return {...state, userManagementEdit: action.payload};
    }

    case constants.admin.USER_MANAGEMENT_NEW: {
      return {...state, userManagementNew: action.payload};
    }

    default: return state;

  }
};

export default UserManagement;