
import React from 'react';
import DynamicPageService from '../../../../utils/services/dynamicPage';

export class DynamicReport extends React.Component {

  constructor(props) {
    super(props);

    const model = {
      externalSourceUrl : '',
      contentWidth : '',
      contentHeight : '',
      params : {}
    };

    this.state = { model };
  }

  componentWillMount(){
    
    DynamicPageService.getPageSettings(this.props.params.pageId)
      .then((result) => {
        this.setState({ model: result.data && result.data.length > 0 ? result.data[0] : null });
      })
      .catch(() => {

      });
  }

  render() {

    const { model } = this.state;
    return (
      <div>
        {
          model && model.externalSourceUrl &&
          <iframe src={model.externalSourceUrl} height={model.contentHeight} width={model.contentWidth}></iframe>
        }
      </div>
    );    
  }
}

export default DynamicReport;