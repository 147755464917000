import React from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import {Sidebar} from 'primereact/components/sidebar/Sidebar';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import moment from 'moment';
import _ from 'lodash';

import campaignVersionService from '../../../../utils/services/campaignVersion';
import FormElement from '../../../shared/form/FormElement'; 
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import * as campaignVersionActions from '../../../../actions/admin/campaignVersionActions';
import GlobalVar from '../../../shared/globalVar/GlobalVar';

export class CampaignVersionList extends React.Component {

  constructor() {
    super();
    this.event = {
      filters: null,
      multiSortMeta: null,
      rows: 10,
      sortField: null,
      sortOrder: null
    };
    this.state = {
      list: [],
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
      newCopyCampaignVersionId: null,
      newCopyCampaignVersionName: 'Default',
      selectedCampaignVersionId: '',
      selectedCampaignVersionName: '',
      copyCampaignDialogVisible: false,
      showArchived: false,
      selectedCampaignId: '',
      settingsPanelVisible: false,
      first: 0
    };

    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.export = this.export.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  export() {
    this.dt.exportCSV();
  }

  componentWillUpdate(nextProps) {
    if (this.props.campaignVersionCopy != nextProps.campaignVersionCopy) {
      if (_.get(nextProps, 'campaignVersionCopy._id')) {
        this.closeCopyPopup();
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: `Campaign ${nextProps.campaignVersionCopy.name} copied with success`,
            timeout: 5000
          }));
        this.onLazyLoad(this.event);
      }
      else if(_.get(nextProps,'campaignVersionCopy.data')){
        this.closeCopyPopup();
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.campaignVersionCopy.data.message,
          timeout: 10000
        }));
      }
    }
  }
  resetFilter = (event) => {
    this.setState({
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  getQuery(event) {

    var query = {
      $offset: event.first,
      $limit: event.rows,
      archived_$eq: this.state.showArchived,
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }

    var gteDate  = this.state.startDate ? {$createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$createdAt_$lte: moment(this.state.endDate).add(1,'d').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
    for(let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');  
      row.expiry = moment(row.expiry).format('YYYY-MM-DD');  
      row.active = row.active.toString();
    }
      
    this.setState({
      list: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});
    
    campaignVersionService.getCampaignVersionList(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });
  } 

  onCreate(){
    this.props.router.push({ pathname: '/admin/campaignVersion/create'});
  }

  onEdit(e,rowData){
    this.props.router.push({ pathname: '/admin/campaignVersion/' + rowData._id });
  }

  showSettingsPanel(e, rowData){
    this.setState(
      {
        selectedCampaignClientName: rowData.clientName,
        selectedCampaignName: rowData.campaignName,
        selectedCampaignId: rowData._id, 
        settingsPanelVisible:true
      }
    );
  }

  showCopyCampaignDialog = (e, rowData) => {
    var suggestedCampaignVersionName = 'Default - Copy';
    var oldCampaignVersionName = '';

    if(rowData && !_.isEmpty(rowData.name)){
      suggestedCampaignVersionName = rowData.name+' - Copy';
      oldCampaignVersionName = rowData.name;
    }
    this.setState({ copyCampaignDialogVisible: true, individualCampaignVersionObj: rowData, newCopyCampaignVersionName : suggestedCampaignVersionName, selectedCampaignVersionName : oldCampaignVersionName, selectedCampaignVersionId: rowData._id });
  };
  copyCampaignDialogFooter = () => {
    return <Button label="Copy" icon="fa fa-check" onClick={() => this.onCopyCampaignVersionSubmit()} />;
  };
  onCopyCampaignVersionSubmit = () => {
    const selectedCampaignVersion = this.state.individualCampaignVersionObj;
    if (selectedCampaignVersion) {
      var copyCampaign = {};
      copyCampaign._id = this.state.selectedCampaignVersionId;
      copyCampaign.name = this.state.newCopyCampaignVersionName; 
      this.props.dispatch(campaignVersionActions.copyCampaignVersion(copyCampaign));
    } else {
      this.props.dispatch(
        globalMessageActions.showMessage({
          type: 'warning',
          message: 'No campaign selected to copy. Please check!',
          timeout: 5000
        })
      );
    }
  }
  closeCopyPopup = () => {
    var reset = {
      newCopyCampaignVersionId: null,
      newCopyCampaignVersionName: 'Default',
      selectedCampaignVersionId: '',
      selectedCampaignVerName: '',
      copyCampaignDialogVisible: false
    };
    this.setState(reset);
  }
  renderCopyCampaignDialog = () => {
    var headerName = `Copy campaign ${(this.state.selectedCampaignVersionName) ? this.state.selectedCampaignVersionName : ''}`;
    return (
      <Dialog
        header={headerName}
        footer={this.copyCampaignDialogFooter()}
        visible={this.state.copyCampaignDialogVisible}
        width="550px"
        modal={true}
        resizable={true}
        responsive={true}       
        onHide={this.closeCopyPopup}
      >
        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-2 ui-md-2">
              <span>Name:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <InputText id='copyCampaignNameInput' style={{width: '100%'}} onChange={e => this.setState({ newCopyCampaignVersionName: e.target.value })} value={this.state.newCopyCampaignVersionName}/>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  renderSettingsDialog = () =>{
    return( 
      <Sidebar visible={this.state.settingsPanelVisible} fullScreen={true} onHide={(e) => this.setState({settingsPanelVisible:false})} blockScroll={true}>
        <h3>
          <span style={{color:'#184a99'}}>Client:</span> {this.state.selectedCampaignClientName}
              
          <span style={{color:'#184a99'}}> Campaign:</span> {this.state.selectedCampaignName}
        </h3>
        <hr></hr>
        <ScrollPanel style={{ height: '100%'}}>
          <GlobalVar context='CAMPAIGN_VERSION.USER_JOURNEY' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.LIGHTBOX' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.LANDING_PAGE' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.SECURITY' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.REWARD' objectId={this.state.selectedCampaignId}  modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.POST_REWARD' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.CUSTOM_FIELD' objectId={this.state.selectedCampaignId}  modalMode={false} />
          <br/><br/><br/><br/><br/><br/>
        </ScrollPanel>
      </Sidebar>);
  }
  actionTemplate(rowData) {
    return <div>
      <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={(e) => {this.onEdit(e, rowData);}}> </Button>
      <Button type="button" icon="fa-copy" className="ui-button-warning" onClick={e => this.showCopyCampaignDialog(e, rowData)}> </Button>
      <Button type="button" icon="fa-gear" className="ui-button-warning" onClick={e => this.showSettingsPanel(e, rowData)}> </Button>
    </div>;
  }

  onCloseSettingsModal = () =>{
    this.setState({settingsPanelVisible:false});
  }

  render() {
    var header = <div style={{textAlign:'left'}}><Button type="button" icon="fa-file-o" iconPos="left" label="CSV" onClick={this.export}></Button></div>;

    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };

    return (
      <div>
        <div className="content-section implementation">

          <h3>Campaign Version</h3>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Archived:</span>
              </div>
              <div className="ui-g-10 ui-md-2 archivedCheckbox">
                <FormElement
                  name='archived'
                  type='checkbox'
                  onChange={(e) => this.setState({showArchived: e.target.checked})} 
                  checked={this.state.showArchived}
                  id='form-active'
                />
              </div>
            </div>
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Date range:</span>
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateFrom'
                  type='calendar'
                  onChange={(e) => this.setState({startDate: e.value})}
                  value={this.state.startDate}
                  id='dateFrom'
                  required
                  style={calendarStyle}
                />
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateTo'
                  type='calendar'
                  onChange={(e) => this.setState({endDate: e.value})}
                  value={this.state.endDate}
                  id='dateTo'
                  required
                  style={calendarStyle}
                />
              </div>
            </div>
          </div>
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField}/>
              </span>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />         
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />         
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Create Version" onClick={this.onCreate} />         
              </div>
            </div>
          </div>

          <DataTable value={this.state.list} responsive={true} sortMode="multiple" paginator={true} rows={10} rowsPerPageOptions={[5,10,20,100,500,1000]} totalRecords={this.state.totalRecords}
            lazy={true} onLazyLoad={this.onLazyLoad} header={header}
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter} first = {this.state.first}>
            <Column field="countryName" header="Country" sortable={true} style={{width:'30px'}} />
            <Column field="clientName" header="Client" sortable={true} style={{width:'80px'}} />
            <Column field="campaignName" header="Campaign" sortable={true} style={{width:'90px'}} />
            <Column field="name" header="Version" sortable={true} style={{width:'90px'}} />   
            <Column field="exposure" header="Exposure" sortable={true} style={{width:'90px'}} />   

            <Column field="active" header="Active" sortable={true} style={{width:'50px'}} />
            <Column field="createdAt" header="Created" sortable={true} style={{width:'50px'}} />
            <Column field="updatedAt" header="Updated" sortable={true} style={{width:'50px'}} />
            <Column field="Archived" header="Archived" body={({archived})=>(!!(archived)).toString()} style={{width:'30px'}} />
            <Column body={this.actionTemplate} style={{textAlign:'center', width: '50px'}}/>
          </DataTable>
          {this.renderCopyCampaignDialog()}
          {this.renderSettingsDialog()}
        </div>
      </div>
    );
  }
}
export default connect((store) => {
  return {
    campaignVersionCopy: store.adminCampaignVersion.campaignVersionCopy
  };
})(CampaignVersionList);
