import React from 'react';
import _ from 'lodash';

const RetailerListing = (props) => {
  return(
    <div className="retailer-listing">
      {
        props.retailers ?
          _.map(props.retailers , function(retailer , key){
            return(
              <div key={key} className="grid-25 tablet-grid-33 retailer-item">
                <div className="image-container">
                  <img src={retailer.imageUrl}/>
                </div>
                <h4>{retailer.name}</h4>
                <p className="commission">Commission: {retailer.commission}%</p>
                <p>
                  <a href={retailer.website} target="_blank">
                    <button className="primary">Visit site</button>
                  </a>
                </p>
              </div>
            );
          })
          :
          false
      }
      <div className="clearfix"></div>
    </div>
  );
};

export default RetailerListing;