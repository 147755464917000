import React from 'react';
import classnames from 'classnames';

import { Controlled as CodeMirror } from 'react-codemirror2';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { Password } from 'primereact/components/password/Password';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

import './FormElement.scss';

require('codemirror/mode/htmlembedded/htmlembedded');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/css/css');
require('codemirror/mode/sass/sass');
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/jump-to-line.js';
import 'codemirror/addon/dialog/dialog.js';
import 'codemirror/addon/dialog/dialog.css';


export default function FormElement( { ...props } ) {

  const cx = classnames('c-form-element', props.className, {
    'c-form-element__error': props.error,
    'c-form-element__flex': props.flex,
    'c-form-element--inline': props.inline
  });

  const { checked, label, name, type, onChange, onKeyPress, value = '', options, required, id, placeholder, error, disabled, style, mode, className, passwordLabel, passwordValue, fillingOptions, sassError = {} } = props;

  const renderPassword = () => {
    return (
      <Password
        promptLabel={passwordLabel && passwordLabel} value={passwordValue} onChange={onChange}
      />
    );
  };

  const renderTextArea = () => {
    return (
      <InputTextarea id="textArea" placeholder={placeholder} value={value} onChange={onChange} rows={props.rows || 5} cols={props.cols || 38}></InputTextarea>
    );
  };

  const renderSelect = () => {
    return (
      <div className="c-select__styled-select">
        <select
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          disabled = {disabled}
          style= {style}
        >
          { props.children }
        </select>
      </div>
    );
  };

  const renderDropDown = () => {

    return(
      <Dropdown
        autoComplete="nope"
        label={label}
        id={id}
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
        disabled={disabled}
      />
    );
  };

  const renderDatalist = () => {
    return (
      <div className="c-select__styled-select">
        <input
          autoComplete="nope"
          name={name}
          type={type}
          checked={checked}
          onChange={onChange}
          value={value}
          id={id}
          list={id + 'list'}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
        <datalist
          type={type}
          id={id + 'list'}
          onChange={onChange}
          value={value}
        >
          { props.children }
        </datalist>
      </div>
    );
  };

  const renderCodeArea = () => {
    let resolvedMode = mode === 'html' ? 'htmlembedded' : mode;
    return (
      <div className={'code-area ' + className}>
        <CodeMirror
          value={value}
          name={name}
          readOnly={disabled}
          type={type}
          options={{
            mode: resolvedMode,
            theme: 'material',
            lineNumbers: true,
            viewportMargin: 10,
            //foldGutter: true,
            gutters: ['CodeMirror-search']//,'CodeMirror-foldgutter'] //, 'CodeMirror-foldgutter' - removed because of performance issues
          }}

          onBeforeChange={(editor, data, value) => {
            onChange({target:{value:value}});
          }}
        />
      </div>
    );
  };

  const renderInput = () => {
    return (
      <input
        autoComplete="nope"
        name={name}
        type={type}
        checked={checked}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        style={style}
        onFocus={ () => { toogleOptions(id, true); }}
        onBlur={ () => { toogleOptions(id, false); }}
        autocomplete="off"
      />
    );
  };

  const renderCalendar = () => {
    return (
      <Calendar inputStyle={style} disabled={disabled}
        dateFormat="yy-mm-dd" value={value}
        onChange={onChange} ></Calendar>
    );
  };

  const renderCalendarWithTime = () => {
    return (      
      <Calendar id="calendarWithTime" inputStyle={style} disabled={disabled}
        dateFormat="yy-mm-dd" value={value}
        onChange={onChange} 
        showTime showSeconds ></Calendar>
    );
  };

  const renderColor = () => {
    return (
      <div className='color-picker'>
        <input
          autoComplete="nope"
          name={name}
          type='text'
          onChange={onChange}
          value={value}
          id={id}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
        <input
          autoComplete="nope"
          name={name}
          type={type}
          checked={checked}
          onChange={onChange}
          value={value}
          id={id}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    );
  };

  const renderErrorMessage = () => {
    return  <small className="u-color-red" style={{color: 'red'}}>{ error }</small>;
  };

  let showlabel = null;
  if (label) {
    showlabel = (
      <label htmlFor={id}>
        {label}
        {
          props.required && <span className="u-color-pink">&nbsp;*</span>
        }
      </label>
    );
  }

  const fillOptions = (parentId) => {

    if(!fillingOptions || fillingOptions.length <= 0){
      return null;
    }

    let onclickEvent = (e, r) => {
      
      if(!e || !e.currentTarget){
        return;
      }     
      
      e.currentTarget.value = e.currentTarget.innerText;
      e.currentTarget.fromSugestion = true;
      
      onChange(e);
    };

    let options = fillingOptions.map(o => <span className="suggestion-item" onClick={onclickEvent}> {o} </span>);

    return (
      <div id={`options-${parentId}`} className="suggestion-panel">
        {options}
      </div>
    );
  };

  const toogleOptions = (parentId, show) => {
    

    setTimeout(() => {
      
      let elem = document.getElementById(`options-${parentId}`);
    
      if(elem){
        if(show){
          elem.style.display = 'block';
        }else{
          elem.style.display = 'none';
        }      
      }    

    }, show ? 0 : 1000);    
  };

  return (
    <div className={ cx }>

      {showlabel}

      { type === 'password' && renderPassword() }
      { type === 'textArea' && renderTextArea() }
      { type === 'calendar' && renderCalendar() }
      { type === 'calendarWithTime' && renderCalendarWithTime() }
      { type === 'color' && renderColor() }
      { type === 'select' && renderSelect() }
      { type === 'dropdown' && renderDropDown() }
      { type === 'datalist' && renderDatalist() }
      { type === 'codearea' && renderCodeArea()}
      { (type !== 'select' && type !== 'calendar' && type !== 'datalist' && type !== 'codearea' && type !== 'color' && type !== 'textArea' && type !== 'password' && type !== 'dropdown')  && renderInput() }
      { (error && error.length) && renderErrorMessage() }

      { fillOptions(id) }

    </div>
  );

}
