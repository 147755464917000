import request from '../wrappers/axios';
import config from '../../config/config';

class LayoutService {

  getCountryById(countryId){
    return request({
      url: `${config.API.BASEURL}/country/${countryId}`,
      method: 'get',
    });
  }

  getCountries(){
    return request({
      url: `${config.API.BASEURL}/country/all`,
      method: 'get',
    });
  }
}

export default new LayoutService();