import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as affiliateActions from '../../../../actions/admin/affiliateActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    affiliate: store.adminAffiliate.affiliate,
    affiliateEdit: store.adminAffiliate.affiliateEdit,
  };
})

class AffiliateEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      affiliateDetails = {
        name:'',
        module:'',
        imageUrl:'',
      }
    } = props;

    this.state = {
      affiliateDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };
  }

  onChange(name, {target}) {
    const {affiliateDetails} = this.state;
    _.set(affiliateDetails, name, target.value);
    this.setState({affiliateDetails});
  }

  componentWillMount() {
    var affiliateId = this.props.params.affiliateId;
    this.setState({ affiliateId: affiliateId });
    if(affiliateId){
      this.props.dispatch(affiliateActions.getAffiliate(affiliateId));
    }

    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New affiliate added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.affiliate){
      this.setState({ affiliateDetails : nextProps.affiliate});
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.affiliateEdit !=nextProps.affiliateEdit){
      if(_.get(nextProps,'affiliateEdit.name')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Affiliate saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'affiliateEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.affiliateEdit.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onChangeExpiry(e) {
    const {affiliateDetails} = this.state;
    _.set(affiliateDetails, 'expiry', e.value);
    this.setState({affiliateDetails});
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const {affiliateDetails,errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    
    if( !checkErrors ){ 
      affiliateDetails.updatedAt = new Date();
      this.props.dispatch(affiliateActions.updateAffiliate(affiliateDetails));
    }
    
    console.log(errors); //for debugging left in for internal use only as it is an admin tool.
    
  }

  validateForm() {
    const { affiliateDetails, errors } = this.state;

    errors[ 'name' ] = validator.isEmpty(affiliateDetails.name.toString());
    
    return this.setState({ errors });
  }

  render (){
    const {affiliateDetails = { info: {} }, errors, displayErrorMessage } = this.state;
    
    const {
      name,
      module,
      imageUrl,
    } = affiliateDetails;
    
    return (
      <div className="affiliate-new-affiliate">
        <h3>Edit affiliate</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />
       
          <FormElement
            label='Module'
            name='module'
            type='text'
            onChange={this.onChange.bind(this, 'module')}
            value={module}
            id='module'
          />

          <FormElement
            label='Image Url'
            name='imageUrl'
            type='text'
            onChange={this.onChange.bind(this, 'imageUrl')}
            value={imageUrl}
            error={errors.imageUrl}
            id='image-url'
          />

        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Edit affiliate</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/affiliate')}>
          Back
        </button>
      </div>
    );
  }
}

export default AffiliateEdit;