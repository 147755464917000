import * as segment from '../../components/shared/segment/analytics';
import ReactGA from 'react-ga'; // analytics already initialised in src/components/shared/ga/googleAnalytics.js
import _ from 'lodash';

class trackingService {

  login(user , method){
    segment.track('LOGGED IN',{
      code: 'auth_login',
      category: 'AUTH',
      method: method,
      user_id: user._id,
      user_username: user.email
    });

    ReactGA.event({
      category: 'auth_login',
      action: 'LOGGED IN',
      label: user._id,
    });
  }

  logout(user){
    segment.track('LOGGED OUT',{
      code: 'auth_logout',
      category: 'AUTH',
      user_id: user._id,
      user_username: user.email
    });

    ReactGA.event({
      category: 'auth_logout',
      action: 'LOGGED OUT',
      label: user._id,
    });
  }

  register(user , method){
    segment.track('REGISTERED',{
      code: 'auth_register',
      category: 'AUTH',
      method: method,
      user_id: user._id,
      user_username: user.email
    });

    ReactGA.event({
      category: 'auth_register',
      action: 'REGISTERED',
      label: user._id,
    });
  }

  accountActivate(userId){

    segment.track('ACTIVATED ACCOUNT',{
      code: 'auth_activate',
      category: 'AUTH',
      user_id: userId,
      user_type: 'user'
    });

    ReactGA.event({
      category: 'auth_activate',
      action: 'ACTIVATED ACCOUNT',
      label: userId,
    });
  }

  accountVerify(userId){
    segment.track('VERIFIED ACCOUNT',{
      code: 'auth_verify',
      category: 'AUTH',
      user_id: userId,
      user_type: 'user'
    });

    ReactGA.event({
      category: 'auth_verify',
      action: 'VERIFIED ACCOUNT',
      label: userId,
    });
  }

  socialShare(user , data){
    _.forEach(data.socialUploads , function(platform){
      segment.track('SHARED TO SOCIAL CHANNEL',{
        code: 'social_share',
        category: 'SOCIAL',
        message: data.message,
        url: data.url,
        fileId: data.fileId,
        social_platform: platform.socialPlatform,
        user_id: user._id,
        user_username: user.email,
        location: 'share_page'
      });

      ReactGA.event({
        category: 'social_share',
        action: 'SHARED TO SOCIAL CHANNEL',
        label: user._id,
      });
    });
  }

  socialPopupShare(data){
    segment.track('SHARED TO SOCIAL CHANNEL',{
      code: 'social_share',
      category: 'SOCIAL',
      client_id: data.client_id,
      product_url: data.product_url,
      social_platform: data.platform.toLowerCase(),
      location: data.location,
      entry_point: 'client_site',
      user_username: data.email
    });
  }

  socialConnect(user , platform){
    segment.track('CONNECTED TO SOCIAL CHANNEL',{
      code: 'social_connect',
      category: 'SOCIAL',
      social_platform: platform.toLowerCase(),
      user_id: user._id,
      user_username: user.email
    });

    ReactGA.event({
      category: 'social_connect',
      action: 'CONNECTED TO SOCIAL CHANNEL',
      label: user._id,
    });
  }

  socialDisconnect(user , platform){
    segment.track('DISCONNECTED FROM SOCIAL CHANNEL',{
      code: 'social_disconnect',
      category: 'SOCIAL',
      social_platform: platform.toLowerCase(),
      user_id: user._id,
      user_username: user.email
    });

    ReactGA.event({
      category: 'social_disconnect',
      action: 'DISCONNECTED FROM SOCIAL CHANNEL',
      label: user._id,
    });
  }

  paymentMethodAdd(user , data){
    segment.track('ADDED PAYMENT METHOD',{
      code: 'bankinfos_add',
      category: 'PAYMENT',
      bank_infos_id: data._id,
      user_id: user._id,
      user_username: user.email,
      type: data.type
    });

    ReactGA.event({
      category: 'bankinfos_add',
      action: 'ADDED PAYMENT METHOD',
      label: user._id,
    });
  }

  paymentMethodDelete(user , data){
    segment.track('DELETED PAYMENT METHOD',{
      code: 'bankinfos_delete',
      category: 'PAYMENT',
      bank_infos_id: data._id,
      user_id: user._id,
      user_username: user.email,
      type: data.type
    });

    ReactGA.event({
      category: 'bankinfos_delete',
      action: 'DELETED PAYMENT METHOD',
      label: user._id,
    });
  }

  reportSort(user ,reportName , sortField , direction){
    segment.track('SORTED REPORT',{
      code: 'sort_options',
      category: 'REPORTS',
      user_id: user._id,
      user_username: user.email,
      report_name: reportName,
      sortField: sortField,
      direction: direction
    });

    ReactGA.event({
      category: 'sort_options',
      action: 'SORTED REPORT',
      label: user._id,
    });
  }

  reportSearch(user , reportName , searchTerm){
    segment.track('SEARCHED REPORT',{
      code: 'report_search',
      category: 'REPORTS',
      user_id: user._id,
      user_username: user.email,
      report_name: reportName,
      search_term: searchTerm
    });

    ReactGA.event({
      category: 'report_search',
      action: 'SEARCHED REPORT',
      label: user._id,
    });
  }

  reportFilter(user , reportName , filterField , range){
    segment.track('FILTERED REPORT',{
      code: 'filter_options',
      category: 'REPORTS',
      user_id: user._id,
      user_username: user.email,
      report_name: reportName,
      filter_field: filterField,
      range: range
    });

    ReactGA.event({
      category: 'filter_options',
      action: 'FILTERED REPORT',
      label: user._id,
    });
  }

  withdrawalRequest(user,withdrawalData){
    segment.track('REQUESTED WITHDRAWAL',{
      code: 'payment_request',
      category: 'PAYMENT',
      user_id: user._id,
      user_username: user.email,
      payment_request_id : withdrawalData._id,
      bank_info_id: withdrawalData.bankInfoId,
      type: withdrawalData.type.toLowerCase(),
      amount: withdrawalData.amount
    });

    ReactGA.event({
      category: 'payment_request',
      action: 'REQUESTED WITHDRAWAL',
      label: user._id,
    });
  }

  withdrawalRequestCancel(user,payment_request_id){
    segment.track('CANCELLED WITHDRAWAL',{
      code: 'payment_cancel',
      category: 'PAYMENT',
      user_id: user._id,
      user_username: user.email,
      payment_request_id : payment_request_id
    });

    ReactGA.event({
      category: 'payment_cancel',
      action: 'CANCELLED WITHDRAWAL',
      label: user._id,
    });
  }

  profileUpdate(user){
    segment.track('UPDATED PROFILE',{
      code: 'profile_update',
      category: 'PROFILE',
      user_id: user._id,
      user_username: user.email
    });

    ReactGA.event({
      category: 'profile_update',
      action: 'UPDATED PROFILE',
      label: user._id,
    });
  }

}

export default new trackingService();