import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as rewardPoolActions from '../../../../actions/admin/rewardPoolActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import RewardsDropbox from '../../../shared/dropbox/RewardsDropbox';
import validator from 'validator';
import {Panel} from 'primereact/components/panel/Panel';

export class RewardPoolCreate extends React.Component {

  constructor(props) {
    super(props);

    const rewardPool = {
      advocatePreConversionRewardId:'',
      advocatePostConversionRewardId:'',
      friendPostRewardId: '',
      refereeRewardId: '',
      limitPostReward: false,
      postRewardPerUser: '',
      limitFriendPostReward: false,
      friendPostRewardPerUser: ''
    };

    this.state = {
      rewardPool,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };

    this.onClientChange = this.onClientChange.bind(this);
  }

  onChange(name, {target}) {
    const { rewardPool } = this.state;
    _.set( rewardPool, name, target.value);
    this.setState({rewardPool});
  }

  onChangeRewards(name, {value}) {
    const { rewardPool } = this.state;
    _.set( rewardPool, name, value);

    if(!value){
      const postRewardLimitSelect = name === 'advocatePostConversionRewardId' ? 'limitPostReward' : 'limitFriendPostReward';
      const postRewardLimitValue = name === 'advocatePostConversionRewardId' ? 'postRewardPerUser' : 'friendPostRewardPerUser';
      _.set( rewardPool, postRewardLimitSelect, false);
      _.set( rewardPool, postRewardLimitValue, false);
    }

    this.setState({rewardPool});
  }

  onChangePostRewardLimit(name, {target}){
    const { rewardPool } = this.state;

    const value = target.value === 'true' ? true : false;
    _.set( rewardPool, name, value);

    if(!value){
      const valueField = name === 'limitPostReward' ? 'postRewardPerUser' : 'friendPostRewardPerUser';
      _.set( rewardPool, valueField, '');
    }
    
    this.setState({rewardPool});
  }

  componentWillUpdate(nextProps){
    if(this.props.rewardPoolRequest !=nextProps.rewardPoolRequest){
      if(_.get(nextProps,'rewardPoolRequest.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.rewardPoolRequest.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { rewardPool, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        
        if (key == 'mustHaveOneReward'){
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Reward pool must have at least one reward',
            timeout: 10000
          }));
          break;
        }

        if (key == 'postRewardLimit'){
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Post Rewards Limit per User  must be greater than 0.',
            timeout: 10000
          }));
          break;
        }

        if (key == 'friendPostRewardLimit'){
          this.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Friend Post Rewards Limit per User  must be greater than 0.',
            timeout: 10000
          }));
          break;
        }

        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    const rewardPoolWithoutEmptyStrings = _.pickBy(rewardPool, _.identity); // remove keys with empty strings from request

    if( !checkErrors ){
      this.props.dispatch(rewardPoolActions.newRewardPool(rewardPoolWithoutEmptyStrings))
        .then(created => browserHistory.push({ pathname: `/admin/rewardPool/${created._id}` , state: { fromCreatePage: true } }));
    }
    
  }

  validateForm() {

    const { rewardPool, errors } = this.state;
    
    errors['name'] = _.isNil(rewardPool.name) || validator.isEmpty(rewardPool.name);
    errors['clientId'] = _.isNil(rewardPool.clientId);
    errors['mustHaveOneReward'] = (_.isEmpty(rewardPool.advocatePreConversionRewardId) &&
      _.isEmpty(rewardPool.advocatePostConversionRewardId) &&
      _.isEmpty(rewardPool.refereeRewardId)); 
    errors['postRewardLimit'] = rewardPool.limitPostReward && (!rewardPool.postRewardPerUser || parseInt(rewardPool.postRewardPerUser) <=0);
    errors['friendPostRewardLimit'] = rewardPool.limitFriendPostReward && (!rewardPool.friendPostRewardPerUser || parseInt(rewardPool.friendPostRewardPerUser) <=0);

    return this.setState({ errors });
  }

  onClientChange(e){
    this.setState({client: e.value});
    const {rewardPool} = this.state;
    _.set(rewardPool, 'clientId', e.value);
    this.setState({rewardPool});
  }

  renderRewardsDropbox() {

    const { rewardPool, errors } = this.state;
    const {
      clientId,
      advocatePreConversionRewardId,
      advocatePostConversionRewardId,
      friendPostRewardId,
      limitPostReward,
      postRewardPerUser,
      limitFriendPostReward,
      friendPostRewardPerUser,
      refereeRewardId,
    } = rewardPool;

    return (
      <React.Fragment>
        <br />
        <Panel header="Referrer (sharer)" style={{width: 600}}>
          <RewardsDropbox
            label='Referrer (sharer) pre-conversion reward'
            required='true'
            onChange={this.onChangeRewards.bind(this, 'advocatePreConversionRewardId')}
            name='advocatePreConversionRewardId'
            value={advocatePreConversionRewardId}
            client={clientId}
          />

          <RewardsDropbox
            label='Referrer (sharer) post-conversion reward'
            required='true'
            onChange={this.onChangeRewards.bind(this, 'advocatePostConversionRewardId')}
            name='advocatePreConversionRewardId'
            value={advocatePostConversionRewardId}
            client={clientId}
          />
          <FormElement
            label="Limit number of referrer (sharer) post-conversion rewards"
            name="limitPostReward"
            type="select"
            value={limitPostReward}
            onChange={this.onChangePostRewardLimit.bind(this, 'limitPostReward')}
            disabled= {!advocatePostConversionRewardId}
            id="form-limitPostReward"
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </FormElement>

          <FormElement
            name='postRewardPerUser'
            type='number'validate
            onChange={this.onChange.bind(this, 'postRewardPerUser')}
            value={postRewardPerUser}
            error={errors.postRewardLimit}
            disabled={!limitPostReward}
            id='form-postRewardPerUser'
          />
        </Panel>

        <br />
        <Panel header="Referee (friend)" style={{width: 600}}>
          <RewardsDropbox
            label='Referee (friend) pre-conversion reward'
            required='true'
            onChange={this.onChangeRewards.bind(this, 'refereeRewardId')}
            name='advocatePreConversionRewardId'
            value={refereeRewardId}
            client={clientId}
          />

          <RewardsDropbox
            label='Referee (friend) post-conversion reward'
            required='true'
            onChange={this.onChangeRewards.bind(this, 'friendPostRewardId')}
            name='friendPostRewardId'
            value={friendPostRewardId}
            client={clientId}
          />
          <FormElement
            label="Limit number of referee (friend) post-conversion reward per user"
            name="limitFriendPostReward"
            type="select"
            value={limitFriendPostReward}
            onChange={this.onChangePostRewardLimit.bind(this, 'limitFriendPostReward')}
            disabled= {!friendPostRewardId}
            id="form-limitPostReward"
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </FormElement>

          <FormElement
            name='friendPostRewardPerUser'
            type='number'validate
            onChange={this.onChange.bind(this, 'friendPostRewardPerUser')}
            value={friendPostRewardPerUser}
            error={errors.friendPostRewardLimit}
            disabled={!limitFriendPostReward}
            id='form-friendPostRewardPerUser'
          />

          
        </Panel>
      </React.Fragment> 
    );
  }

  render (){
    const { errors, client: clientId, rewardPool : rewardPool } = this.state;

    return (
      <div className="reward-new-reward">
        <h3>Create reward pool</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Reward Pool Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={rewardPool.name}
            error={errors.name}
            id='form-name'
            required
          />

          <br />

          <label>Client</label>
          <ClientsDropbox
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId} 
          />

          <FormElement
            label='Client Id'
            name='clientId'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors.clientId}
            id='form-clientId'
            disabled
          />

          { this.renderRewardsDropbox() } 

        </form>
        <br />
        <button id="form-submit" onClick={()=>this.onSubmit()}>Create</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    rewardPoolRequest: store.adminRewardPool.requests,
  };
})(RewardPoolCreate);
