import constants from '../../constants/app-constants';

const initialState = {
  dailyTractionData: null,
  pageCount: 30
}

const ClientReportsDailyTraction = (state = initialState , action) => {
  switch(action.type){
  case constants.client.REPORTS_DAILYTRACTION_FETCHREPORT : {
    return {...state , dailyTractionData: action.payload};
  }

  case constants.client.REPORTS_DAILYTRACTION_UPDATEPAGECOUNT : {
    return{...state, pageCount: action.payload};
  }

  default:{
    return state;
  }
  }
};

export default ClientReportsDailyTraction;