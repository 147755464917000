import config from '../../config/config';

export default {
  getBackgroundStyle: (url) => {
    return `url('${url}') no-repeat`;
  },
  getImageUrl: (imageId) => {
    if (!imageId) {
      return null;
    }

    return `${config.IMG.SRC}/${imageId.substring(0,6)}/${imageId}`;
  }
};