import constants from '../../constants/app-constants';

const initialState = {
  headerMenuVisible: '',
  toggleCalendar: false,
  closeCalendar: false
};

const ClientHeader = (state = initialState, action) => {
  switch (action.type) {
    case constants.client.HEADER_TOGGLEMENU: {
      return { ...state, headerMenuVisible: action.headerMenuVisible };
    }

    case constants.client.TOGGLE_CALENDAR: {
      return { ...state, toggleCalendar: action.payload };
    }

    default:
      return state;
  }
};

export default ClientHeader;
