import React from 'react';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Button} from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import ClientDataTable from  './innerComponents/client/dataTable';
import './MainList.css';

class MainList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: '',
      ignoreArchived: false,
      campaignType: null,
      first: 0
    };

    this.dataTable = React.createRef();
  }
    
  resetFilter = () => this.setState({searchField: ''});  

  render = () => {
    const {ignoreArchived, searchField} = this.state;
    return (
      <div className="content-section implementation">
        <h3>Client Explorer</h3>
        <div>
          <div className="ui-g">
            <div className="ui-g-1 ui-md-1">
              <span>Name:</span>
            </div>
            <div className="ui-g-12 ui-md-2">
              <span>
                <InputText
                  onChange={(e) => this.setState({ searchField: e.target.value  })}
                  value={searchField}
                  onKeyDown = {(e) => {if(e.keyCode === 13) this.dataTable.current.setSearch(this.state);}}
                />
              </span>
            </div>
            <div className="ui-g-1 ui-md-1">
              <span>Campaign Type:</span>
            </div>
            <div className="ui-g-8 ui-md-8">
              <Dropdown
                value={this.state.campaignType}
                options={[
                  { label: 'All', value: null },
                  { label: 'On Site Referral', value: 'on_site_referral' }, 
                  { label: 'Marketplace', value: 'marketplace'}
                ]}
                onChange={(e) => { this.setState({ campaignType: e.value }); }}
                style={{'width':'160px'}}
                className={'country-selector'}
              /> 
            </div>
          </div>
  
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Ignore Archived:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <input name="ignoreArchived" type="checkbox" checked={ignoreArchived} onChange={() => this.setState({ignoreArchived: !ignoreArchived})} />
              </span>
            </div>
          </div>
  
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={() => this.dataTable.current.setSearch(this.state)}/>         
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={() => this.dataTable.current.setSearch({})}/>         
              </div>
            </div>
          </div>
        </div>
        <ClientDataTable ref={this.dataTable} searchField={this.state.searchField} ignoreArchived={ignoreArchived} first={this.state.first}/>
      </div>
    );
  }

}

export default MainList;