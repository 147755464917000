import { QueryHelper } from '../../utils/helpers/QueryHelper';

const queryHelper = new QueryHelper();

const injectQueryHelper = Target => {
  Target.prototype.getQuery = (...args) => {
    return queryHelper.getQuery(...args);
  };

  Target.prototype.getExtendedQuery = (...args) => {
    return queryHelper.getExtendedQuery(...args);
  };

  Target.prototype.getSimpleQuery = (...args) => {
    return queryHelper.getSimpleQuery(...args);
  };

  return Target;
};

export const withQueryHelper = injectQueryHelper;
