import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Panel } from 'primereact/components/panel/Panel';
import Label from '../../../../../shared/label/label';
import getLiveRewardsBasic from '../../../../../../utils/services/reports';
import config from '../../../../../../config/config';
import { getData } from '../reward/data';

class RewardPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remaining: {},
      list: [],
      campaignVersionId: this.props.campaignVersionId,
      rewardPoolId: this.props.rewardPoolId,
      first: 0
    };
  }

  editRewardAction =  rowData => <span className="fa fa-cog" style={{cursor: 'pointer'}} onClick={() => {
    window.open(`${config.URL.BASEFRONT}/admin/reward/${rowData._id}`, '_blank');
  }}/>

  discountCodeBody = rowData => {
    if(rowData.discountCode._id) {
      return (
        <span title="Click to edit">
          <a href={`${config.URL.BASEFRONT}/admin/rewardDiscountCode/${rowData.discountCode._id}`} target="_blank">{rowData.discountCode.code}</a>
        </span>
      );
    }

    return rowData.discountCode.code;
  }

  remainingCodeBody = (rowData) => {
    return (
      <div>
        <span> {rowData.remainingCodes}</span>
      </div>
    );
  }

  nameBody = (rowData) => {
    return (
      <div>
        <div>
          {rowData.giftCardReward &&
          <Label color="gray" text="Gift Card" />
          }
        </div>
        <span> {rowData.name}</span>
      </div>
    );
  }

  rewardTypeBody = rowData => {
    if (rowData.meta && rowData.meta) {
      return (
        <div>
          <span>{rowData.rewardType}</span> <span title={rowData.meta.holder} className="fa fa-eye"></span>
        </div>
      );
    }

    return rowData.rewardType;
  }

  onLazyLoad = (event) => {

    if(event){
      this.state.first = isNaN(event.first) ? 0 : event.first;
    }
    
    this.setState({ loading: true });
    let obj = {};

    // Get rewards data
    getData(this.state.rewardPoolId)
      .then(response => {
        obj = { ...response };
        return obj;
      })
      .then((obj) => {
        if (obj && obj.rewards) {
          Promise.all(obj.rewards.filter(reward => reward.type === 'batch-discount').map((reward) => {
            // getting quantity of remaining code
            return getLiveRewardsBasic.getLiveRewardsBasic({ rewardId_$eq: reward._id }, '?$limit=1');
          }))
            .then((values) => {
              const rewardsWithRemaningCode = values.map((result) => {
                return result.data.page[0];
              });

              //New array of rewards
              const arrOfRewards = obj.rewards;

              arrOfRewards.forEach((originalReward) => {
                let remaningCodesReward = rewardsWithRemaningCode.find((rwd) => {
                  return rwd ? rwd.rewardId === originalReward._id : false;
                });
              
                originalReward.remainingCodes = (remaningCodesReward && remaningCodesReward.remainingCodes) ? remaningCodesReward.remainingCodes : '-';
              });

              const newState = {...this.state};
              newState.list = arrOfRewards;
              newState.loading = false;

              this.setState(newState);
            });
        }
      });
  }

  componentDidMount = () => this.onLazyLoad();

  getHeader = () => {
    const { rewardPoolId, rewardPoolName } = this.state;

    return (
      <div style={{ clear: 'both', color: '#485465' }}>
        <span>{rewardPoolName}</span>
        <span
          className='fa fa-cog'
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={() => window.open(`${config.URL.BASEFRONT}/admin/rewardPool/${rewardPoolId}`, '_blank')}
        />
      </div>);
  };

  render = () =>
    <Panel header={this.getHeader()}>
      <DataTable
        value={this.state.list}
        responsive={true}
        sortMode="multiple"
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        loading={this.state.loading}
        ref={(el) => this.dt = el}
        first={this.state.first}
      >
        <Column field="rewardType" header="Type" body={e => this.rewardTypeBody(e)} />
        <Column field="name" body={e => this.nameBody(e)} header="Name" />
        <Column field="discountCode.valueAmount" header="Value" style={{ textAlign: 'center', width: '80px' }} />
        <Column field="discountCode.code" header="Code" body={e => this.discountCodeBody(e)} />
        <Column field="remaining" body={e => this.remainingCodeBody(e)} header="Remaining" />
        <Column body={e => this.editRewardAction(e)} style={{ textAlign: 'center', width: '50px' }} />
      </DataTable>
    </Panel>
}

export default RewardPanel;