import RewardPoolList from './List';
import RewardPoolCreate from './Create';
import RewardPoolEdit from './Edit';

const RewardPoolIndex = {
  RewardPoolList, 
  RewardPoolCreate,
  RewardPoolEdit
};

export default RewardPoolIndex;