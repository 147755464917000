import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

import byChannelService from '../../../../utils/services/byChannel';
import listStatisticsService from '../../../../utils/services/listStatisticsService';

import * as byChannelActions from '../../../../actions/client/byChannelActions';
import * as clientStatisticActions from '../../../../actions/client/clientStatisticActions';

import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataRangePicker, dateIndexSelection } from '../../../shared/dataRangePicker/dataRangePicker';

import ChartDataLine from './ByChannel.ChartLine';
import ChartDataPie from './ByChannel.ChartPie';
import ChartHorizontalBar from './ByChannel.ChartHorizontalBar';

import { formatterNumber } from '../../../../utils/helpers/ClientStats';
import filterPlatforms from '../../../../utils/helpers/filterPlatforms';
import { withQueryHelper } from '../../../../decorators/queryHelperDecorator/queryHelper-decorator';

const OPTIONS = [
  { label: 'SHARES', value: 'SHARES' },
  { label: 'CLICKS', value: 'CLICKS' },
  { label: 'SALES', value: 'SORETO SALES' }
];

const _labelMapper = {
  SHARES: 0,
  SHARES_RATE: 1,
  CLICKS: 2,
  SORETO_SALES: 3,
  CONVERSION_RATE: 4
};

const _chartLineLabels = [
  'SHARES',
  'SHARE RATE',
  'CLICKS',
  'SORETO SALES',
  'CONVERSION RATE'
];

const _chartLabels = [
                      { label:'E-mail', socialPostSocialPlatform: 'EMAIL' },
                      { label:'Facebook', socialPostSocialPlatform: 'FACEBOOK' },
                      { label:'Pinterest', socialPostSocialPlatform: 'PINTEREST' },
                      { label:'Twitter', socialPostSocialPlatform: 'TWITTER' },
                      { label:'Whatsapp', socialPostSocialPlatform: 'WHATSAPP' },
                      { label:'Messenger', socialPostSocialPlatform: 'MESSENGER' },
                      { label:'Snapchat', socialPostSocialPlatform: 'SNAPCHAT' },
                      { label:'Telegram', socialPostSocialPlatform: 'TELEGRAM' },
                      { label: 'Unallocated', socialPostSocialPlatform: 'UNTRACKED' },
                    ];
                    
const _emailColor = '#000000',
  _facebookColor = '#058dc7',
  _pinterestColor = '#b2f2de',
  _twitterColor = '#55acee',
  _whatsappColor = '#6aca00',
  _messColor = '#0084ff',
  _snapchatColor = '#f0ca4d',
  _telegramColor = '#44aee2',
  _untrackedColor = '#b4afaf';


const _dataPlatformColors = [
  _emailColor,
  _facebookColor,
  _pinterestColor,
  _twitterColor,
  _whatsappColor,
  _messColor,
  _snapchatColor,
  _telegramColor,
  _untrackedColor
];

@withQueryHelper
export class ByChannel extends React.Component {
  constructor(props) {
    super(props);

    this.event = {
      filters: null,
      multiSortMeta: null,
      rows: 100000,
      sortField: null,
      sortOrder: null
    };

    this.state = {
      chartDataShares: '',
      chartDataClicks: '',
      chartDataSoretoSales: '',
      chartDataSharesBar: '',
      chartDataClicksBar: '',
      chartDataSalesBar: '',
      chartDataConversionRate: '',
      labelsForOneSet: [],
      labelsTwoSet: [],

      dropDownValue: 'SHARES',
      startDate: props.calendar[0].startDate,
      endDate: props.calendar[0].endDate,
      previousStartDate: '',
      previousEndDate: '',
      showDaysWithoutSales: true,
      showDaysWithoutClicks: true,
      showDaysWithoutShares: true,
      showDaily: false,
      showCampaignLevel: false,
      showCampaignVersionLevel: false,
      //showActiveClients: true,
      showUntrackedClicks: false,
      searchField: '',
      first: 0,
      rows: 100000,
      isPreviousChartHidden: true,
      multiSortMeta: [{field: 'eventDateTerm', order: 1}]
    };
  }

  componentDidMount = () => {
    this.fetchCurrentData();
  };

  componentDidUpdate = prevProps => {
    if (this.props.clientStats !== prevProps.clientStats) {
      this.setClientSales(this.props.clientStats.data);
      this.setLineChart();

      const query = this.getQuery(this.event, false, this);
      const extendedQuery = this.getExtendedQuery(this.event, this);
      this.props.dispatch(byChannelActions.getClientStatsPerChannelByPeriod_bi(query,extendedQuery));
    }

    if(this.props.clientStatsPerChannelByPeriod !== prevProps.clientStatsPerChannelByPeriod) {
      this.setHorizontalBarChart();
    }
  };

  fetchCurrentData = (setPreviousData = false) => {
    const query = this.getQuery(this.event, false, this);
    const extendedQuery = this.getExtendedQuery(this.event, this);

    // set flag on search evenr
    this.state.showUntrackedClicks = false;

    query.dateGrouping = 'daily';
    this.props.dispatch(clientStatisticActions.getClientStats_bi(query, extendedQuery));

    if (setPreviousData) {
      this.fetchPreviousData(setPreviousData);
    }

    // byChannelService
    //   .getTotalClientStatsByPeriod(query, extendedQuery)
    //   .then(data => {

    //     let page = data.data.page[0];
        

    //     page.countSoretoSales = page.countSales;
    //     page.countClicks = page.countClicks;
        
    //     this.currentTotalClientStatsByPeriod = page;
        
    //     query.daily = true;
    //     this.props.dispatch(clientStatisticActions.getClientStats_bi(query, extendedQuery));

    //     if (setPreviousData) {
    //       this.fetchPreviousData(setPreviousData);
    //     }
    //   });
  };

  fetchPreviousData = (previousData = false) => {
    
    const query = this.getQuery(this.event, previousData, this);
    query.$limit = 1000000;
    const extendedQuery = this.getExtendedQuery(this.event, this);

    query.dateGrouping = 'daily';
    listStatisticsService
      .getClientStatistics_bi(query, extendedQuery)
      .then(data => {
        
        query.daily = false;
        this.oldClientStats = data.data.data;
        const currentData = false;
        
        this.setClientSales(this.oldClientStats, currentData);

        byChannelService
              .getClientStatsPerChannelByPeriod_bi(query, extendedQuery)
              .then(data => {
                
                const filteredPlatforms = filterPlatforms({page: data.data.data});
                this.oldClientStatsPerChannelByPeriod = filteredPlatforms.page;
                this.setHorizontalBarChart();
              });

        // byChannelService
        //   .getTotalClientStatsByPeriod(query, extendedQuery)
        //   .then(data => {

        //     let page = data.data.page[0];
                        

        //     page.countSoretoSales = page.countSales;
        //     page.clicks = page.clicks;
            
        //     this.oldTotalClientStatsByPeriod = page;
            
        //     byChannelService
        //       .getClientStatsPerChannelByPeriod_bi(query, extendedQuery)
        //       .then(data => {
                
        //         const filteredPlatforms = filterPlatforms({page: data.data.data});
        //         this.oldClientStatsPerChannelByPeriod = filteredPlatforms.page;
        //         this.setHorizontalBarChart();
        //       });
        //   });
      });
  };

  setLineChart = () => {
    const data = this.props.clientStats;

    let valid = _.get(data, 'data') && _.get(data, 'data').length > 0;
    if (valid) {

      const primaryColor = '#46b29d', pointBackgroundColor = '#30827f', backgroundColor = 'rgba(70, 178, 162, 0.18)';
      var chartData = this.createLineChart(backgroundColor, primaryColor, pointBackgroundColor);
      chartData = this.setDataIntoLineChart(data.data, chartData);

      this.setState({
        clientName: data.data[0].clientName,
        chartDataShares: {
          labels: chartData.labels,
          datasets: [chartData.datasets[_labelMapper.SHARES]]
        },
        chartDataClicks: {
          labels: chartData.labels,
          datasets: [chartData.datasets[_labelMapper.CLICKS]]
        },
        chartDataSoretoSales: {
          labels: chartData.labels,
          datasets: [chartData.datasets[_labelMapper.SORETO_SALES]]
        }
      });
    }
  };

  setPreviousLineChart = () => {
    const data = this.oldClientStats;
    const primaryColor = '#f53855', pointBackgroundColor = '#f50c4e', backgroundColor = 'rgba(251, 159, 173, 0.43)';

    var chartData = this.createLineChart(backgroundColor, primaryColor, pointBackgroundColor);
    chartData = this.setDataIntoLineChart(data, chartData);

    this.setState({
      chartPreviousDataShares: {
        labels: chartData.labels,
        datasets: [chartData.datasets[_labelMapper.SHARES]]
      },
      chartPreviousDataClicks: {
        labels: chartData.labels,
        datasets: [chartData.datasets[_labelMapper.CLICKS]]
      },
      chartPreviousDataClientSales: {
        labels: chartData.labels,
        datasets: [chartData.datasets[_labelMapper.SORETO_SALES]]
      }
    });
  };

  setClientSales = (data = [], currentData = true) => {
    let clientSales = 0;

    if(data && Array.isArray(data)){
      data.forEach(data => {
        clientSales += +data.clientSales;
      });
    }
    
    if (currentData) {
      this.setState({ currentClientSales: clientSales });
    } else {
      this.setState({ previousClientSales: clientSales });
    }
  };

  createLineChart = (backgroundColor, primaryColor, pointBackgroundColor) => {
    var chartData = {
      labels: [],
      datasets: []
    };

    for (let i = 0; i < _chartLineLabels.length; i++) {
      chartData.datasets[i] = {
        label: _chartLineLabels[i],
        data: [],
        backgroundColor: backgroundColor,
        borderColor: primaryColor,
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointBackgroundColor: pointBackgroundColor
      };
    }
    return chartData;
  };

  setDataIntoLineChart = (data, chartData) => {

    if(data && Array.isArray(data)){

      data.forEach(dataIn => {

        chartData.labels.push(moment(dataIn.eventDateTerm).format('DD MMM YYYY'));
        chartData.datasets[_labelMapper.SHARES].data.push(dataIn.shares);
        chartData.datasets[_labelMapper.CLICKS].data.push(Number(dataIn.refClicks));
        chartData.datasets[_labelMapper.SORETO_SALES].data.push(
          dataIn.soretoSales
        );
  
      });
    }
    
    return chartData;
  };

  setPieChart = () => {
    if (_.get(this.props.clientStatsPerChannelByPeriod, 'page')) {
      let data = this.props.clientStatsPerChannelByPeriod.page;
      data = this.sortPlatformsByName(data);
      
      this.setState({
        chartDataPie: this.createPieChart(data),
        // totalShares: totalValues.shares,
        // soretoTotalSales: totalValues.countSales
      });
    }
  };

  setPreviousPieChart = () => {
    let data = this.oldClientStatsPerChannelByPeriod;
    data = this.sortPlatformsByName(data);
    
    this.setState({
      chartPreviousDataPie: this.createPieChart(data),
      //previousTotalShares: oldTotalValues.shares,
      //previousTotalSoretoSales: oldTotalValues.countSales
    });    
  };

  createPieChart = data => {

    const dropDownValue = this.state.dropDownValue;
    let dataPie = [];

    // Use 'UNTRACKED' option only for "clicks" chart
    let chartDataLables = _.filter(_chartLabels, chartLable => {
      if( (chartLable.socialPostSocialPlatform !== 'UNTRACKED')){
        return chartLable;
      }else if (dropDownValue === _chartLineLabels[_labelMapper.CLICKS]){

        if(_.first(_.filter(data, (d) => d.socialPostSocialPlatform == 'UNTRACKED' && d.clicks > 0))){
          return chartLable;
        }
      }
    });

    for (var i = 0; i < chartDataLables.length; i++) {
      var key = chartDataLables[i].socialPostSocialPlatform;
      var indexMatch = _.findIndex(data, ['socialPostSocialPlatform', key]);

      if (dropDownValue === _chartLineLabels[_labelMapper.SHARES]) {        
        (indexMatch >= 0) ? dataPie[i] = data[indexMatch].shares : dataPie[i] = 0 ;
      } else if (dropDownValue === _chartLineLabels[_labelMapper.CLICKS]) {          
        (indexMatch >= 0) ? dataPie[i] = data[indexMatch].clicks : dataPie[i] = 0 ;
      } else if (dropDownValue === _chartLineLabels[_labelMapper.SORETO_SALES]) {
          (indexMatch >= 0) ? dataPie[i] = data[indexMatch].sales : dataPie[i] = 0 ;
      }
    }       
    
    chartDataLables = chartDataLables.map(chartLable => 
    {
        return chartLable.label
    });

    var chartData = {
      labels: chartDataLables,
      datasets: [
        {
          label: chartDataLables,
          data: dataPie,
          backgroundColor: _dataPlatformColors,
          borderColor: _dataPlatformColors
        }
      ]
    };

    return chartData;
  };

  setShareRate = (totalShares, totalShareRate, sharesData, showShareRateLabels = false) => {
    let shareRatesByPlatforms = [];
    if (totalShareRate && sharesData) {
      const formattedTotalShareRate = totalShareRate.replace('%', '');
      sharesData.forEach(share => {
        const value = (share / totalShares) * 100;
        if (showShareRateLabels) {
          shareRatesByPlatforms.push((value).toFixed(2) + '%');
        } else {
          shareRatesByPlatforms.push((value).toFixed(2));
        }
      });
    }
    return shareRatesByPlatforms;
  };

  sortPlatformsByName = channels => {
    if (channels && Array.isArray(channels)) {
      return channels.sort((a, b) => {
        const x = a.socialPostSocialPlatform.toLowerCase();
        const y = b.socialPostSocialPlatform.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }
  };

  setHorizontalBarChart = () => {
    let clientStatsPerChannelByPeriod = this.props.clientStatsPerChannelByPeriod.page;
    let listOfPlatforms = _chartLabels.map((item)=>{ return {
      'socialPostSocialPlatform':item.socialPostSocialPlatform,
      shares: 0, clicks: 0, sales: 0
    }});
    clientStatsPerChannelByPeriod = _.uniqBy(_.concat(clientStatsPerChannelByPeriod,listOfPlatforms), 'socialPostSocialPlatform');

    let oldClientStatsPerChannelByPeriod = this.oldClientStatsPerChannelByPeriod;
    oldClientStatsPerChannelByPeriod =  this.oldClientStatsPerChannelByPeriod ? _.uniqBy(_.concat(oldClientStatsPerChannelByPeriod,listOfPlatforms), 'socialPostSocialPlatform'): undefined

    clientStatsPerChannelByPeriod = this.sortPlatformsByName(clientStatsPerChannelByPeriod);
    oldClientStatsPerChannelByPeriod = this.sortPlatformsByName(oldClientStatsPerChannelByPeriod);

    let sharesChartData = [], sharesChartLabels = [], sharesRateChartData = [], sharesRateChartLabels = [], clicksChartData = [];
    let clicksChartLabels = [], soretoSalesChartData = [], soretoSalesChartLabels = [], conversionRateChartData = [], conversionRateChartLabels = [];
    let oldSharesChartData = [], oldShareRateChartData = [], oldClicksChartData = [], oldSoretoSalesChartData = [], oldConversionRateChartData = [];
    let variationInShares = [], variationInShareRate = [], variationInClicks = [], variationInSoretoSales = [], variationInConversionRate = [];

    /**
     * Calculate the total clicks by adding the values. In this way, we discard the clicks made by the app...
     * We want to be consistent with the other pages, e.g. Analytics.
     */
    let totalClicks = 0, previousTotalClicks = 0, totalShares = 0, soretoTotalSales =0, previousShares = 0, totalShareRate = 0, previousTotalSharesRate = 0, previousSoretoTotalSales = 0;

    for(var i = 0; i < _chartLabels.length; i++) {

      var key = _chartLabels[i].socialPostSocialPlatform; // socialPostSocialPlatform iteration

      // Current rage values
      var indexMatch = _.findIndex(clientStatsPerChannelByPeriod, ['socialPostSocialPlatform', key]);

      if(!_.some(clientStatsPerChannelByPeriod, { socialPostSocialPlatform : 'UNTRACKED', clicks : 0})){
        this.state.showUntrackedClicks = true;
      }

      sharesChartLabels[i] = 0;
      sharesChartData[i] = 0;
      clicksChartData[i] = 0;
      clicksChartLabels[i] = 0;
      soretoSalesChartData[i] = 0;
      soretoSalesChartLabels[i] = 0;

      if(indexMatch >= 0)
      {
        sharesChartLabels[i] = clientStatsPerChannelByPeriod[indexMatch].shares;
        sharesChartData[i] = clientStatsPerChannelByPeriod[indexMatch].shares;
        clicksChartData[i] = clientStatsPerChannelByPeriod[indexMatch].clicks;
        clicksChartLabels[i] = clientStatsPerChannelByPeriod[indexMatch].clicks;
        soretoSalesChartData[i] = clientStatsPerChannelByPeriod[indexMatch].sales;
        soretoSalesChartLabels[i] = clientStatsPerChannelByPeriod[indexMatch].sales;
        
        conversionRateChartData[i] = formatterNumber.format(+clientStatsPerChannelByPeriod[indexMatch].clicks > 0 ? (+clientStatsPerChannelByPeriod[indexMatch].sales / +clientStatsPerChannelByPeriod[indexMatch].clicks)*100 : 0);
        conversionRateChartLabels[i] = +formatterNumber.format(+clientStatsPerChannelByPeriod[indexMatch].clicks > 0 ? (+clientStatsPerChannelByPeriod[indexMatch].sales / +clientStatsPerChannelByPeriod[indexMatch].clicks)*100 : 0) + '%';
        
        totalClicks += +clientStatsPerChannelByPeriod[indexMatch].clicks;
        totalShares += +clientStatsPerChannelByPeriod[indexMatch].shares;
        soretoTotalSales += clientStatsPerChannelByPeriod[indexMatch].sales;
      }

      // If selected "Compare To" mode, then calculates the previus rage values
      if(oldClientStatsPerChannelByPeriod) {

        oldSharesChartData[i] = 0;
        oldClicksChartData[i] = 0;
        oldSoretoSalesChartData[i] = 0;
        oldConversionRateChartData[i] = 0;

        var indexMatchOld = _.findIndex(oldClientStatsPerChannelByPeriod, ['socialPostSocialPlatform', key]);

        if(indexMatchOld >= 0)
        {
          oldSharesChartData[i] = oldClientStatsPerChannelByPeriod[indexMatchOld].shares;
          oldClicksChartData[i] = oldClientStatsPerChannelByPeriod[indexMatchOld].clicks;
          oldSoretoSalesChartData[i] = oldClientStatsPerChannelByPeriod[indexMatchOld].sales;
          oldConversionRateChartData[i] = +formatterNumber.format(+oldClientStatsPerChannelByPeriod[indexMatchOld].clicks > 0 ? (+oldClientStatsPerChannelByPeriod[indexMatchOld].sales / +oldClientStatsPerChannelByPeriod[indexMatchOld].clicks)*100 : 0);
        
          previousTotalClicks += +oldClientStatsPerChannelByPeriod[indexMatchOld].clicks;
          previousShares += +oldClientStatsPerChannelByPeriod[indexMatchOld].shares;
          previousSoretoTotalSales += +oldClientStatsPerChannelByPeriod[indexMatchOld].sales;
          
          /* The % rates are  */

          let shares = (((sharesChartData[i] / oldClientStatsPerChannelByPeriod[indexMatchOld].shares) - 1) * 100).toFixed(0);
          shares = shares === 'Infinity' ? '100%' : shares + '%';
  
          let clicks = (((clicksChartData[i] / oldClientStatsPerChannelByPeriod[indexMatchOld].clicks) - 1) * 100).toFixed(0);
          clicks = clicks === 'Infinity' ? '100%' : clicks + '%';
  
          let soretoSales = (((soretoSalesChartData[i] / oldClientStatsPerChannelByPeriod[indexMatchOld].sales) - 1) * 100).toFixed(0);
          soretoSales = soretoSales === 'Infinity' ? '100%' : soretoSales + '%';
  
          let conversionRate = (((conversionRateChartData[i] / oldConversionRateChartData[i]) - 1) * 100).toFixed(0);
          conversionRate = conversionRate === 'Infinity' ? '100%' : conversionRate + '%';
  
          variationInShares[i] = shares;
          variationInClicks[i] =  clicks;
          variationInSoretoSales[i] = soretoSales;
          variationInConversionRate[i] = conversionRate;
        }
      }
    }    
  
    const currentClientSales = this.state.currentClientSales;
    const previousClientSales = this.state.previousClientSales;

    totalShareRate = +formatterNumber.format(+currentClientSales > 0 ? (+totalShares / +currentClientSales)*100 : 0)+ '%';
    sharesRateChartData = this.setShareRate(totalShares, totalShareRate, sharesChartData);
    const showShareRateLabels = true;
    sharesRateChartLabels = this.setShareRate(totalShares, totalShareRate, sharesChartData, showShareRateLabels);

    if(oldClientStatsPerChannelByPeriod) { 
      previousTotalSharesRate = +formatterNumber.format(+previousClientSales > 0 ? (+previousShares / +previousClientSales)*100 : 0)+ '%';
      oldShareRateChartData = this.setShareRate(previousShares, previousTotalSharesRate, oldSharesChartData);

      for(var i = 0; i < _chartLabels.length; i++) { 
        
        let shareRate = (((sharesRateChartData[i] / oldShareRateChartData[i]) - 1) * 100).toFixed(0);
        shareRate = shareRate === 'Infinity' ? '100%' : shareRate + '%';
        variationInShareRate[i] = shareRate;
      }
    }

    const labels = ['Shares', 'Share Rate', 'Clicks', 'Sor. Sales', 'Co. Rate'];
    const labelsForOneSet = [sharesChartLabels, sharesRateChartLabels, clicksChartLabels, soretoSalesChartLabels, conversionRateChartLabels];
    const labelsTwoSet = variationInShares.length !== 0 ? [variationInShares, variationInShareRate, variationInClicks, variationInSoretoSales, variationInConversionRate] : [];
    const data = [sharesChartData, sharesRateChartData, clicksChartData, soretoSalesChartData, conversionRateChartData];
    const oldData = [oldSharesChartData, oldShareRateChartData, oldClicksChartData, oldSoretoSalesChartData, oldConversionRateChartData];
    let dataChart = this.createHorizontalBarChart(oldClientStatsPerChannelByPeriod, labels, data, oldData, labelsForOneSet, labelsTwoSet);
    
    this.setState(
      {
        chartDataSharesBar: dataChart[_labelMapper.SHARES],
        chartDataShareRateBar: dataChart[_labelMapper.SHARES_RATE],
        chartDataClicksBar: dataChart[_labelMapper.CLICKS],
        chartDataSalesBar: dataChart[_labelMapper.SORETO_SALES],
        chartDataConversionRate: dataChart[_labelMapper.CONVERSION_RATE],
        labelsForOneSet,
        labelsTwoSet,
        totalClicks: totalClicks,
        totalShares: totalShares,
        soretoTotalSales: soretoTotalSales,
        previousTotalClicks: previousTotalClicks,
        totalShareRate: totalShareRate,
        previousTotalShares : previousShares,
        previousTotalSharesRate: previousTotalSharesRate,
        previousTotalSoretoSales: previousSoretoTotalSales
      },
      () => {
        this.setPieChart();
        if (this.oldClientStatsPerChannelByPeriod) {
          this.setPreviousLineChart();
          this.setPreviousPieChart();
        }
      }
    );
  };

  createHorizontalBarChart = (oldClientStatsPerChannelByPeriod, labels, data, oldData, labelsForOneSet, labelsTwoSet) => {
    let dataChart = [];
    const firstDatasetBackgoundColor = '#46b2a3', secondDatasetBackgoundColor = '#f53855';
    
    const { showUntrackedClicks } = this.state;

    for (var i = 0; i < labelsForOneSet.length; i++) {

      // do not show labels for untracked values when they do not exists
      // do not show conversion rate for untracked data
      if(!showUntrackedClicks || labels[i] == 'Co. Rate'){
        
        let untrackedIndex = _.findIndex(_chartLabels, (cl) => cl.socialPostSocialPlatform == "UNTRACKED");
        data[i].splice(untrackedIndex, 1);
        oldData[i].splice(untrackedIndex, 1);
      }
      
      data[i] = _.map(data[i], (v) => {         
         return isNaN(v) ? 0 : v;        
      });

      oldData[i] = _.map(oldData[i], (v) => {
        return isNaN(v) ? 0 : v;
      });

      /**
       * Set the labels. They must be different.
       */
      if (oldClientStatsPerChannelByPeriod) {
        dataChart[i] = {
          labels: labelsTwoSet[i],
          datasets: [
            {
              label: `${labels[i]}`, //!important
              data: data[i],
              comparisonMode : true,
              comparisonLabel : labelsTwoSet[i],
              borderWidth: 2,
              backgroundColor: firstDatasetBackgoundColor
            },
            {
              label: `Previous ${labels[i]}`, //!important
              previousInfo : true,
              data: oldData[i],
              borderWidth: 2,
              backgroundColor: secondDatasetBackgoundColor
            }
          ]
        };
      } else {
        dataChart[i] = {
          labels: labelsForOneSet[i],
          datasets: [
            {
              label: labels[i],
              data: data[i],
              comparisonMode : false,
              borderWidth: 2,
              backgroundColor: firstDatasetBackgoundColor
            }
          ]
        };
      }
    }
    return dataChart;
  };

  updateDateRange = date => {
    if (date && Array.isArray(date) && date.length === 1) {
      const startDate = date[dateIndexSelection.FIRST].startDate;
      const endDate = date[dateIndexSelection.FIRST].endDate;

      this.oldClientStats = null;
      //this.oldTotalClientStatsByPeriod = null;
      this.oldClientStatsPerChannelByPeriod = null;

      this.setState(
        {
          startDate,
          endDate,
          isPreviousChartHidden: true,
          previousStartDate: null,
          previousEndDate: null,
          chartPreviousDataPie: null,
          previousTotalShares: null,
          previousTotalShareRate: null,
          previousTotalClicks: null,
          previousTotalSoretoSales: null,
          chartPreviousDataShares: null,
          chartPreviousDataClicks: null,
          chartPreviousDataClientSales: null
        },
        () => {
          this.fetchCurrentData();
        }
      );
    } else if (date && Array.isArray(date) && date.length === 2) {
      const startDate = date[dateIndexSelection.FIRST].startDate;
      const endDate = date[dateIndexSelection.FIRST].endDate;
      const previousStartDate = date[dateIndexSelection.SECOND].startDate;
      const previousEndDate = date[dateIndexSelection.SECOND].endDate;
      const setPreviousData = true;

      this.setState(
        {
          startDate,
          endDate,
          previousStartDate,
          previousEndDate,
          isPreviousChartHidden: false
        },
        () => {
          this.fetchCurrentData(setPreviousData);
        }
      );
    }
  };

  renderDropdown = () => {
    return (
      <div className="header header--dropdown">
        <Dropdown
          value={this.state.dropDownValue}
          options={OPTIONS}
          onChange={e => {
            this.setState({ dropDownValue: e.value }, () => {
              this.setPieChart();
              this.setLineChart();
              this.setPreviousPieChart();
            });
          }}
        />
      </div>
    );
  };

  renderPieChart = () => {
    const dropDownValue = this.state.dropDownValue;
    let totalData = 0;
    if (dropDownValue === _chartLineLabels[_labelMapper.SHARES]) {
      totalData = this.state.totalShares;
    } else if (dropDownValue === _chartLineLabels[_labelMapper.CLICKS]) {
      totalData = this.state.totalClicks;
    } else {
      totalData = this.state.soretoTotalSales;
    }

    const shares = this.state.chartDataPie && (
      <ChartDataPie
        className="byChannel__charts--shares"
        title={this.state.dropDownValue}
        chartData={this.state.chartDataPie}
        type="PieChart"
        totalData={totalData}
      />
    );

    return shares;
  };

  renderPreviousPieChart = () => {
    const dropDownValue = this.state.dropDownValue;
    let totalData = 0;
    if (dropDownValue === _chartLineLabels[_labelMapper.SHARES]) {
      totalData = this.state.previousTotalShares;
    } else if (dropDownValue === _chartLineLabels[_labelMapper.CLICKS]) {
      totalData = this.state.previousTotalClicks;
    } else {
      totalData = this.state.previousTotalSoretoSales;
    }

    const shares = this.state.chartPreviousDataPie && (
      <ChartDataPie
        className="byChannel__charts--shares"
        title={this.state.dropDownValue}
        chartData={this.state.chartPreviousDataPie}
        type="PieChart"
        totalData={totalData}
      />
    );

    return shares;
  };

  renderFirstLineChart = () => {
    const dropDownValue = this.state.dropDownValue;
    let chartData = this.state.chartDataClicks,
      label = _chartLineLabels[_labelMapper.CLICKS];

    if (dropDownValue === _chartLineLabels[_labelMapper.CLICKS]) {
      chartData = this.state.chartDataShares;
      label = _chartLineLabels[_labelMapper.SHARES];
    }

    const chart = this.state.chartDataClicks && (
      <React.Fragment>
        <div className="byChannel__charts--separator">&nbsp;</div>
        <ChartDataLine
          className="byChannel__charts--clicks"
          title={label}
          chartData={chartData}
          type="LineChart"
        />
      </React.Fragment>
    );

    return chart;
  };

  renderSecondLineChart = () => {
    const dropDownValue = this.state.dropDownValue;
    let chartData = this.state.chartDataSoretoSales,
      label = _chartLineLabels[_labelMapper.SORETO_SALES];

    if (dropDownValue === _chartLineLabels[_labelMapper.SORETO_SALES]) {
      chartData = this.state.chartDataShares;
      label = _chartLineLabels[_labelMapper.SHARES];
    }

    const chart = this.state.chartDataShares && (
      <React.Fragment>
        <div className="byChannel__charts--separator separator_two">&nbsp;</div>
        <ChartDataLine
          className="byChannel__charts--sales"
          title={label}
          chartData={chartData}
          type="LineChart"
        />
      </React.Fragment>
    );

    return chart;
  };

  renderPreviousFirstLineChart = () => {
    const dropDownValue = this.state.dropDownValue;
    let chartData = this.state.chartPreviousDataClicks,
      label = _chartLineLabels[_labelMapper.CLICKS];

    if (dropDownValue === _chartLineLabels[_labelMapper.CLICKS]) {
      chartData = this.state.chartPreviousDataShares;
      label = _chartLineLabels[_labelMapper.SHARES];
    }

    const chart = this.state.chartPreviousDataClicks && (
      <React.Fragment>
        <div className="byChannel__charts--separator">&nbsp;</div>
        <ChartDataLine
          className="byChannel__charts--clicks"
          title={label}
          chartData={chartData}
          type="LineChart"
        />
      </React.Fragment>
    );

    return chart;
  };

  renderPreviousSecondLineChart = () => {
    const dropDownValue = this.state.dropDownValue;
    let chartData = this.state.chartPreviousDataClientSales,
      label = _chartLineLabels[_labelMapper.SORETO_SALES];

    if (dropDownValue === _chartLineLabels[_labelMapper.SORETO_SALES]) {
      chartData = this.state.chartPreviousDataShares;
      label = _chartLineLabels[_labelMapper.SHARES];
    }

    const chart = this.state.chartPreviousDataShares && (
      <React.Fragment>
        <div className="byChannel__charts--separator separator_two">&nbsp;</div>
        <ChartDataLine
          className="byChannel__charts--sales"
          title={label}
          chartData={chartData}
          type="LineChart"
        />
      </React.Fragment>
    );

    return chart;
  };

  renderShares = () => {
    const chart = this.state.chartDataSharesBar && (
      <ChartHorizontalBar
        type="HorizontalBar"
        title={_chartLineLabels[_labelMapper.SHARES]}
        className="barChart"
        total={this.state.totalShares}
        previousTotal={this.state.previousTotalShares}
        chartData={this.state.chartDataSharesBar}
      />
    );

    return chart;
  };

  renderSharesRate = () => {

    this.state.chartDataShareRateBar.datasets[0].percentageMode = true;
    const chart = this.state.chartDataShareRateBar && (
      <ChartHorizontalBar
        type="HorizontalBar"
        title={_chartLineLabels[_labelMapper.SHARES_RATE]}
        className="barChart"
        total={this.state.totalShareRate}
        previousTotal={this.state.previousTotalSharesRate}
        chartData={this.state.chartDataShareRateBar}
      />
    );

    return chart;
  };

  renderClicks = () => {

    const chart = this.state.chartDataClicksBar && (
      <ChartHorizontalBar
        type="HorizontalBar"
        title={_chartLineLabels[_labelMapper.CLICKS]}
        className="barChart"
        total={this.state.totalClicks}
        previousTotal={this.state.previousTotalClicks}
        chartData={this.state.chartDataClicksBar}
      />
    );

    return chart;
  };

  renderSoretoSales = () => {
    const previousTotalSoretoSales = this.state.previousTotalSoretoSales === '0' ? undefined : this.state.previousTotalSoretoSales;
    const chart = this.state.chartDataSalesBar && (
      <ChartHorizontalBar
        type="HorizontalBar"
        title={_chartLineLabels[_labelMapper.SORETO_SALES]}
        className="barChart"
        total={this.state.soretoTotalSales}
        previousTotal={previousTotalSoretoSales}
        chartData={this.state.chartDataSalesBar}
      />
    );

    return chart;
  };

  renderConversionRate = () => {
    const totalConversionRate = +formatterNumber.format(+this.state.totalClicks > 0 ? (+this.state.soretoTotalSales / +this.state.totalClicks)*100 : 0) + '%';
    let previousTotalConversionRate = +formatterNumber.format(+this.state.previousTotalClicks > 0 ? (+this.state.previousTotalSoretoSales / +this.state.previousTotalClicks)*100 : 0) + '%';
    previousTotalConversionRate = previousTotalConversionRate === '0%' ? undefined : previousTotalConversionRate;
    
    this.state.chartDataConversionRate.datasets[0].percentageMode = true;
    
    const chart = this.state.chartDataConversionRate && (
      
      <ChartHorizontalBar
        type="HorizontalBar"
        title={_chartLineLabels[_labelMapper.CONVERSION_RATE]}
        className="barChart"
        total={totalConversionRate}
        previousTotal={previousTotalConversionRate}
        chartData={this.state.chartDataConversionRate}
      />
    );

    return chart;
  };

  renderComparisonData = () => {
    const previousStartDate = moment(this.state.previousStartDate).format('DD/MM/YYYY');
    const previousEndDate = moment(this.state.previousEndDate).format('DD/MM/YYYY');
    const { isPreviousChartHidden } = this.state;

    let previousData = !isPreviousChartHidden && (
      <div className={classnames('byChannel__comparisonData', { hideComparisonData: isPreviousChartHidden })}>
        <div className="byChannel__charts--header">
          <div className="header ">
            {previousStartDate} to {previousEndDate}
          </div>
        </div>

        {this.renderPreviousPieChart()}

        {this.renderPreviousFirstLineChart()}

        {this.renderPreviousSecondLineChart()}
      </div>
    );

    return previousData;
  };

  renderSocialPlatformItems = () => {
    let counter = 0;
    const { isPreviousChartHidden, showUntrackedClicks } = this.state;

    // Show untracked clicks only if it has results
    let chartDataLables = _.filter(_chartLabels, chartLable => {
      if((chartLable.socialPostSocialPlatform !== 'UNTRACKED') || showUntrackedClicks){
        return chartLable;
      }
    });

    return chartDataLables.map(label => {
      counter++;
      return (
        <div 
          key={`platform-${label.label}`} 
          className={classnames(`item item-${counter}`, { [`noComparison__${label.label}`]: isPreviousChartHidden })}>
          <i
            className={`fa fa-square fa-square--${label.label}`}
            aria-hidden="true"
          />
          {label.label}
        </div>
      );
    });
  };

  renderHorizontalBar = value => {

    var ticksLabel = [], data = [], chartBar = null; 
    ticksLabel  = this.state.labelsTwoSet.length !== 0 ? this.state.labelsTwoSet.slice() : this.state.labelsForOneSet.slice();

    if (ticksLabel && ticksLabel.length !== 0) {
      if (value === _labelMapper.SHARES) {
        data = ticksLabel[_labelMapper.SHARES];
        chartBar = this.renderShares();
      } else if (value === _labelMapper.SHARES_RATE) {
        data = ticksLabel[_labelMapper.SHARES_RATE];
        chartBar = this.renderSharesRate();
      } else if (value === _labelMapper.CLICKS) {
        data = ticksLabel[_labelMapper.CLICKS];
        chartBar = this.renderClicks();
      } else if (value === _labelMapper.SORETO_SALES) {
        data = ticksLabel[_labelMapper.SORETO_SALES];
        chartBar = this.renderSoretoSales();
      } else if (value === _labelMapper.CONVERSION_RATE) {
        data = ticksLabel[_labelMapper.CONVERSION_RATE];
        chartBar = this.renderConversionRate();
      }
    }

    if (data && data.length === _chartLabels.length) {
   

      return (

        <div className="horizontal__Chart">
          
          {chartBar}

        </div>
      );
    }
  };

  render() {
    const startDate = moment(this.state.startDate).format('DD/MM/YYYY');
    const endDate = moment(this.state.endDate).format('DD/MM/YYYY');

    return (
      <div className="byChannel-page grid-container grid-parent">

        <div className="byChannel-page--header">
          <h3 className="clientName"
            dangerouslySetInnerHTML={{ __html: this.state.clientName }} />

          <DataRangePicker dates={data => this.updateDateRange(data)} />          
        </div>

        <div className="wrapper">
          <div className="byChannel__charts">
            <div className="byChannel__charts--header">
              <div className="header "
                dangerouslySetInnerHTML={{ __html: `${startDate} to ${endDate}` }}
              />
              {this.renderDropdown()}
            </div>

            {this.renderPieChart()}

            {this.renderFirstLineChart()}

            {this.renderSecondLineChart()}
          </div>

          {this.renderComparisonData()}

          {/* HORIZONTAL BAR SECTION */}
          <div className="byChannel__elements">

            {
              this.renderSocialPlatformItems()
            }

            <div className="dataWrapper2">
              
              <div className="dataWrapper">
                
                {this.renderHorizontalBar(_labelMapper.SHARES)}

                {this.renderHorizontalBar(_labelMapper.SHARES_RATE)}

                {this.renderHorizontalBar(_labelMapper.CLICKS)}

                {this.renderHorizontalBar(_labelMapper.SORETO_SALES)}

                {this.renderHorizontalBar(_labelMapper.CONVERSION_RATE)} 

              </div> 

            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default connect(store => {
  return {
    clientStats: store.clientStats.clientStatsGet,
    clientStatsPerChannelByPeriod:
      store.clientByChannel.byChannelClientStatsPerChannelByPeriodBiGet,
    calendar: store.sharedDateRangePicker.setCalendarDates
  };
})(ByChannel);
