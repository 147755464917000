import constants from '../constants/app-constants';

const initialState = {
  message: null
};

const globalMessage = (state = initialState , action) => {
  switch(action.type){

  case constants.GLOBALMESSAGE_SET : {
    return {...state, message: action.payload };
  }

  case constants.LOCATION_CHANGE : {
    return {...state, message: null };
  }

  default:
    return state;
  }
};

export default globalMessage;