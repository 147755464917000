import React from 'react';
import config from '../../../../../config/config';
import _ from 'lodash';
import {connect} from 'react-redux';
import * as clientActions from '../../../../../actions/client/clientActions';

@connect((store) => {
  return{
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj
  };
})

class SettingsCustomTags extends React.Component{

  constructor(){
    super();
    this.state = {
      currentlyEditingTag: {
        selectedTag: '',
        customTag: ''
      }
    };
  }

  componentWillMount(){
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
  }

  addTag(e){
    e.preventDefault();

    if(this.selectedTag.value && this.customTag.value){
      var customTags = this.props.clientObj.tags.slice(0);
      customTags.push(
        {
          selectedTag: this.selectedTag.value,
          customTag: this.customTag.value
        }
      );

      var payload = {
        tags: customTags
      }

      this.props.dispatch(clientActions.updateAndRefreshClient(this.props.clientObj._id , payload));
      this.customTag.value = '';
    }
  }

  editTag(tag){
    this.setState({
      currentlyEditingTag: {
        selectedTag: tag.selectedTag,
        customTag: tag.customTag
      }
    });
  }

  updateTag(tag){

    if(this.editingCustomTag.value){
      var customTags = this.props.clientObj.tags.slice(0);
      var currentlyEditingTag =  _.find(customTags , { customTag : tag.customTag });
      currentlyEditingTag.customTag = this.editingCustomTag.value;

      var payload = {
        tags: customTags
      }

      this.props.dispatch(clientActions.updateAndRefreshClient(this.props.clientObj._id , payload));
      this.cancelEdit();
    }else{
      alert('Custom tag value required');
    }
  }

  removeTag(tag){
    var customTags = this.props.clientObj.tags.slice(0);
    _.remove(customTags , { customTag : tag.customTag });

    var payload = {
      tags: customTags
    }

    this.props.dispatch(clientActions.updateAndRefreshClient(this.props.clientObj._id , payload));
    this.cancelEdit();
  }

  cancelEdit(){
    this.setState({
      currentlyEditingTag: {
        selectedTag: '',
        customTag: ''
      }
    });
  }

  render(){
    var that = this;
    var clientTags = false;

    if(this.props.clientObj){
      clientTags = _.map(this.props.clientObj.tags,function(tag){
        var className = 'tags-list';
        var currentlyEditing = false;
        if(tag.selectedTag == that.state.currentlyEditingTag.selectedTag
          && tag.customTag == that.state.currentlyEditingTag.customTag){
          className = 'tags-list currently-editing';
          currentlyEditing = true;
        }

        return (
          <div className={className} key={tag.customTag}>
            <input type="text" defaultValue={tag.selectedTag} disabled/>
            {
              currentlyEditing?
                <input type="text" ref={(input) => that.editingCustomTag = input} defaultValue={tag.customTag} required /> :
                <input type="text" defaultValue={tag.customTag} disabled />
            }
            <button className="currently-editing-button primary" onClick={() => that.updateTag(tag) }><i className="fa fa-plus"></i><span>Save</span></button>
            <button className="currently-editing-button secondary" onClick={() => that.cancelEdit() } ><i className="fa fa-times"></i><span>Cancel</span></button>
            <button className="edit-btn primary" onClick={()=> that.editTag(tag)}><i className="fa fa-pencil"></i><span>Edit</span></button>
            <button className="remove-btn secondary-v2" onClick={() => that.removeTag(tag) } ><i className="fa fa-times"></i><span>Remove</span></button>
          </div>
        );
      });
    }

    return (
      <div id="custom-tags">
       <h1>Custom tags</h1>
        <form onSubmit={(e) => this.addTag(e)} action="">
          <h4 className="active">
            Add New Tag
          </h4>
          <div className="styled-select">
            <select ref={(select) => this.selectedTag = select} name="" id="" required>
              {
                _.map(config.TAGS,function(tag){
                  return(
                    <option key={tag} value={tag}>{tag}</option>
                  );
                })
              }
            </select>
          </div>
          <input ref={(input) => this.customTag = input } type="text" required />
          <button className="primary"><i  className="fa fa-plus"></i><span>Add</span></button>
        </form>
        <div>
          <h4 className="active">
            Active Tags
          </h4>
          {
            this.props.clientObj ?
              this.props.clientObj.tags.length ?
                clientTags :
                <i>No active tags found</i>
              :
              false
          }
        </div>
      </div>
    );
  }
}

export default SettingsCustomTags;