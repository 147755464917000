import config from '../../config/config';
import _ from 'lodash';

export default {
  getSocialAuthUrl: (socialPlatform) => {
    return config.URL.BASEBACK + '/socialconnect?p=' + socialPlatform;
  },
  getPlatformParams(platforms){
  
    var platformParams = [];
    _.forEach(platforms,(platform , key) =>{
      if(platform.STATUS){
        platformParams.push(`p=${key.toLowerCase()}`);
      }
    });
    
    
    return '?' + platformParams.join('&');
  }
};