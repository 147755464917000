import React from 'react';
import PaymentForm from './PaymentForm';
import * as paymentMethodActions from '../../../../../actions/user/paymentMethodActions';
import userService from '../../../../../utils/services/user';
import trackingService from '../../../../../utils/services/tracking';
import {connect} from 'react-redux';
import * as globalMessageActions from '../../../../../actions/user/globalMessageActions';
import * as withdrawalActions from '../../../../../actions/user/withdrawalActions';
import Withdrawal from '../../../shared/withdrawal/Withdrawal';

@connect((store)=>{
  return {
    loginCtx: store.loginCtx.loginCtx,
    paymentMethods: store.userPaymentMethod.paymentMethods
  };
})
class PaymentMethod extends React.Component{

  constructor(){
    super();
    this.state = {
      showPaymentMethodForm: false
    };
  }

  componentWillMount(){
    this.props.dispatch(paymentMethodActions.getPaymentMethods(this.props.loginCtx._id));
  }

  handleSubmit(data){
    var that = this;
    userService.addPaymentMethod(that.props.loginCtx._id , data)
      .then(function(res){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Payment method successfully added',
            timeout: 10000
          }
        ));
        that.props.dispatch(paymentMethodActions.getPaymentMethods(that.props.loginCtx._id));

        trackingService.paymentMethodAdd(that.props.loginCtx,res.data , data.type);

        that.closePaymentMethodForm();
      })
      .catch(function(err){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  showPaymentMethodForm(){
    this.setState({
      showPaymentMethodForm : true
    });
  }

  closePaymentMethodForm(){
    this.setState({
      showPaymentMethodForm : false
    });
  }

  openWithdrawalDialog(){
    this.props.dispatch(withdrawalActions.setWithdrawalModalStatus(true));
  }

  deletePaymentMethod(paymentMethod){
    var that = this;

    userService.deletePaymentMethod(that.props.loginCtx._id ,paymentMethod._id )
      .then(function(){
        that.props.dispatch(paymentMethodActions.getPaymentMethods(that.props.loginCtx._id));
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Payment method successfully deleted',
            timeout: 10000
          }
        ));

        trackingService.paymentMethodDelete(that.props.loginCtx,paymentMethod);
      })
      .catch(function(err){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }


  render(){
    var that = this;
    return(
      <div>
        <h1 className="settings-title">Payment Methods</h1>
        {
          this.props.paymentMethods && this.props.paymentMethods.length && !this.state.showPaymentMethodForm ?
            <div>
              <div className="grid-50">
                <button onClick={() => this.showPaymentMethodForm()} className="primary">Add New Payment Method</button>
                <p>&nbsp;</p>
              </div>
              <div className="grid-50 text-right">
                <button onClick={() => this.openWithdrawalDialog()} className="primary">Withdraw Funds</button>
                <p>&nbsp;</p>
              </div>
              <div className="grid-100">
                <table>
                  <thead>
                  <tr>
                    <td>Method</td>
                    <td>Details</td>
                    <td></td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    _.map(this.props.paymentMethods,function(paymentMethod){
                      return(
                        <tr key={paymentMethod._id}>
                          <td>{paymentMethod.type}</td>
                          <td>{paymentMethod.preview}</td>
                          <td><a onClick={() => that.deletePaymentMethod(paymentMethod)}>Delete</a></td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
              </div>
            </div>

            :

            <div>
              {
                this.props.paymentMethods ?
                  <div>
                    {
                      this.state.showPaymentMethodForm ?
                        <div className="grid-100">
                          <PaymentForm handleSubmit={ (data) => this.handleSubmit(data) } closeForm={() => this.closePaymentMethodForm()}>
                          </PaymentForm>
                        </div>
                        :
                        <div className="no-data">
                          <p>You currently have no payment methods setup</p>
                          <p><button onClick={() => this.showPaymentMethodForm()} className="primary">Add New Payment Method</button></p>
                        </div>
                    }
                  </div>
                  :
                  <div className="loading-gif text-center">
                    <img src="/images/loading-gif.gif"/>
                  </div>
              }
            </div>
        }

      <Withdrawal />
      </div>
    );
  }
}

export default PaymentMethod;