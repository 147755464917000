import React from 'react';
import serialize from 'form-serialize';


class BankForm extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      paymentMethod: ''
    };
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.handleSubmit(serialize(this.paymentMethodForm , { hash: true }));
  }

  closeForm(e){
    e.preventDefault();
    this.props.closeForm();
  }

  checkCharactersLength(e , max){
    if(e.target.value.length > max){
      e.target.value = e.target.value.slice(0,max);
    }
  }

  getPaymentForm(){
    switch(this.state.paymentMethod){
    case 'BANK': return (
      <div>
        <p><strong>Enter Bank Details Below</strong></p>
        <div className="input-holder">
          <input onInput={(e) => this.checkCharactersLength(e , 6)} maxLength="6" required={true} type="number" name="sortCode"  placeholder="Sort Code"/>
        </div>
        <div className="input-holder">
          <input onInput={(e) => this.checkCharactersLength(e , 10)} maxLength="10" required={true}  type="number" name="accountNumber" placeholder="Account Number"/>
        </div>
        <div maxLength="" className="input-holder">
          <input maxLength="70" required={true}  type="text" name="accountName" placeholder="Account Name"/>
        </div>
      </div>
    );

    case 'PAYPAL': return (
      <div>
        <p><strong>Enter PayPal Details Below</strong></p>
        <div className="input-holder">
          <input required={true}  name="email" type="text"  placeholder="Email"/>
        </div>
      </div>
    );

    default: return (
      <div></div>
    );
    }
  }

  getMethodForm(){
    this.setState({
      paymentMethod: this.selectedPaymentMethod.value
    });
  }

  render(){
    return(
      <div className="payment-methods-add">
        <h4>Add New Payment Method</h4>
        <div  className="settings-section-divider grid-100 grid-parent input-holder">
          <div className="styled-select">
            <select ref={(select) => this.selectedPaymentMethod = select} onChange={() => this.getMethodForm()} name="" id="">
              <option value="">Select Payment Option</option>
              <option value="BANK">Bank</option>
              {/*<option value="PAYPAL">Paypal</option>*/}
            </select>
          </div>
          {
            !this.state.paymentMethod ?
              <div  className="top-actions action-buttons">
                <button className="secondary-v2" onClick={(e) => this.closeForm(e)}>Cancel</button>
              </div>
              :
              false
          }
          <div className="clearfix"></div>
        </div>
        {
          this.state.paymentMethod ?
            <div className="settings-section-divider grid-100 grid-parent">
              <form id="payment-method-form" ref={(form) => this.paymentMethodForm = form } onSubmit={(e) => this.handleSubmit(e)} action="">
                <div>
                  <input type="hidden" name="type" value={this.state.paymentMethod} />
                </div>
                {
                  this.getPaymentForm()
                }
                <div className="action-buttons">
                  <button className="primary">Save</button> &nbsp;
                  <button className="secondary-v2" onClick={(e) => this.closeForm(e)}>Cancel</button>
                </div>
              </form>
            </div>
          :
          false
        }
      </div>
    );
  }
}

export default BankForm;