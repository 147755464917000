import emailTemplateService from '../../utils/services/emailTemplate';
import keyEmailTemplateTypeService from '../../utils/services/keyEmailTemplateType';
import {set} from 'lodash';

import constants from '../../constants/app-constants';

export const getAllEmailTemplates = (query, extendedQuery) => {
  return function(dispatch){
    emailTemplateService.getEmailTemplates(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_GET_ALL,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_GET_ALL,
          payload: err.response
        });
      });
  };
};

export const getEmailTemplateWithTemplateKeysById = (id) => {
  return function(dispatch){
    emailTemplateService.getEmailTemplateById(id)
      .then(function(res){
        let templateDetails = res.data;
        if(templateDetails.emailTemplateTypeId){
          keyEmailTemplateTypeService.getKeyEmailTemplateType({
            '$emailTemplateTypeId_$':res.data.emailTemplateTypeId
          }).then((res)=>{
            set(templateDetails, 'keys',res.data);
            return dispatch({
              type: constants.admin.EMAIL_TEMPLATE_GET_BY_ID,
              payload: templateDetails
            });
          });
        }else{
          return dispatch({
            type: constants.admin.EMAIL_TEMPLATE_GET_BY_ID,
            payload: res.data
          });
        }


      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_GET_BY_ID,
          payload: err.response
        });
      });
  };
};


export const getEmailTemplateById = (id) => {
  return function(dispatch){
    emailTemplateService.getEmailTemplateById(id)
      .then(function(res){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_GET_BY_ID,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_GET_BY_ID,
          payload: err.response
        });
      });
  };
};

export const updateEmailTemplate = (emailTemplate) => {
  return function(dispatch){
    emailTemplateService.updateEmailTemplate(emailTemplate)
      .then(function(res){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_EDIT,
          payload: err.response
        });
      });
  };
};


export const copyEmailTemplate = (id,emailTemplate) => {
  return function(dispatch){
    emailTemplateService.copyEmailTemplate(id,emailTemplate)
      .then(function(res){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_COPY,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_COPY,
          payload: err.response
        });
      });
  };
};

export const newEmailTemplate = emailTemplate => dispatch => {
  return new Promise((resolve, reject) => {
    emailTemplateService.newEmailTemplate(emailTemplate)
      .then(res => {
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.EMAIL_TEMPLATE_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};