import request from '../wrappers/axios';
import config from '../../config/config';

class UserSegmentationPool {

  get(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/userSegmentationPool${extendedQuery}`,
      method: 'get',
      params: query
    }, true, 500);
  }
}

export default new UserSegmentationPool();