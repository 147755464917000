import React from 'react';
import Modal from 'react-modal';
import config from '../../../../config/config';
import _ from 'lodash';


const requestModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

const getRequestStatuses = () => {
  return _.map(config.WITHDRAWAL_REQUEST_STATUSES , (status , i) => (<option key={i} value={status}>{status}</option>));
}


const WithdrawalRequestModal = (props) => {
  return(
    <Modal isOpen={props.settings.modalIsOpen} contentLabel={'Withdrawal Request'} style={requestModalStyle}>
      <div className="withdrawal-request-modal-admin">
        <i className="fa fa-times close-icon" onClick={() => props.closeModal() }></i>
        {
          props.withdrawalRequestItem ?
            <div>
              <div className="grid-100">
                <h4>Details</h4>
                <table>
                  <tbody>
                  <tr>
                    <td><strong>Name</strong></td>
                    <td>{ `${props.withdrawalRequestItem.firstName} ${props.withdrawalRequestItem.lastName}` }</td>
                  </tr>
                  <tr>
                    <td><strong>Email</strong></td>
                    <td>{ props.withdrawalRequestItem.email }</td>
                  </tr>
                  <tr>
                    <td><strong>Account</strong><br/>
                    name:<br/>
                    number:<br/>
                    sort:
                    </td>
                    <td><strong>{ props.withdrawalRequestItem.bankInfoType } - { props.withdrawalRequestItem.bankInfoPreview }</strong><br/>
                    { props.withdrawalRequestItem.secureBankInfo.accountName }<br/>
                    { props.withdrawalRequestItem.secureBankInfo.accountNumber }<br/>
                    { props.withdrawalRequestItem.secureBankInfo.sortCode }</td>
                  </tr>
                  <tr>
                    <td><strong>Request Date</strong></td>
                    <td>{ props.withdrawalRequestItem.createdAt }</td>
                  </tr>
                  <tr>
                    <td><strong>Amount</strong></td>
                    <td>{props.withdrawalRequestItem.amount}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="clearfix"></div>
              <h4>Current Status</h4>
              {
                !props.settings.currentlyEditingStatus ?
                  <div>
                    <p>
                      {props.withdrawalRequestItem.status}  <button onClick={() => props.toggleCurrentlyEditingStatus() } className="secondary edit-status-button">Edit</button>
                    </p>
                    {
                      props.withdrawalRequestItem.meta.reason ?
                        <table className="request-notes-table">
                          <tr>
                            <td><strong>Rejection Reason</strong></td>
                            <td>{ props.withdrawalRequestItem.meta.reason }</td>
                          </tr>
                        </table>
                        :
                        false
                    }

                    {
                      props.withdrawalRequestItem.meta.reference ?
                        <table className="request-notes-table">
                          <tr>
                            <td><strong>Bank Reference</strong></td>
                            <td>{ props.withdrawalRequestItem.meta.reference }</td>
                          </tr>
                        </table>
                        :
                        false
                    }
                  </div>
                  :
                  false
              }

              {
                props.settings.currentlyEditingStatus ?
                  <div>
                    <form onSubmit={(e) => props.updateStatus(e)} action="">
                      <input name="requestId" type="hidden" value={props.withdrawalRequestItem._id}/>
                      <input name="userId" type="hidden" value={props.withdrawalRequestItem.userId}/>
                      <div className="styled-select">
                        <select defaultValue={props.withdrawalRequestItem.status} onChange={(e) => props.changeWithdrawalRequestStatus(e)} name="status">
                          {
                            getRequestStatuses()
                          }
                        </select>
                      </div>
                      {
                        (props.withdrawalRequestItem.meta.reason && props.settings.showRejectStatusReasonInput) || props.settings.showRejectStatusReasonInput ?
                          <textarea defaultValue={props.withdrawalRequestItem.meta.reason ? props.withdrawalRequestItem.meta.reason : ''} className="reason-input"  name="reason" placeholder="Reason" required={true}>
                          </textarea>
                          :
                          false
                      }
                      {
                        (props.withdrawalRequestItem.meta.reference && props.settings.showBankReferenceInput) || props.settings.showBankReferenceInput ?
                          <input defaultValue={props.withdrawalRequestItem.meta.reference ? props.withdrawalRequestItem.meta.reference : ''} className="reference-input"  name="reference" placeholder="Transaction Reference" required={true}>
                          </input>
                          :
                          false
                      }
                      <div className="clearfix"></div>
                      <div className="button-set">
                        <button className="primary">Update</button> &nbsp;
                        <button className="secondary-v2" onClick={() => props.toggleCurrentlyEditingStatus()}>Cancel</button>
                      </div>
                    </form>
                  </div>
                  :
                  false
              }

              {
                !props.settings.currentlyEditing ?
                  <button onClick={() => props.closeModal()} className="secondary-v2">Close</button>
                  :
                  false
              }
            </div>
            :
            false
        }
      </div>

    </Modal>
  );
};

export default WithdrawalRequestModal;