import React from 'react';
import ReactDOM from 'react-dom';
import request from 'axios';
import AppClient from './components/App.jsx';

import userService from './utils/services/user';
import { Provider } from 'react-redux';
import * as indexActions from './actions/index';

import store from './stores/store';

request.defaults.withCredentials = true;

userService.getCurrent().then((response) => {
  store.dispatch(indexActions.updateLoginCtx(response.data));
  ReactDOM.render(<Provider store={store}><AppClient /></Provider>, document.getElementById('mainClient'));
}).catch(() => {
  ReactDOM.render(<Provider store={store}><AppClient /></Provider>, document.getElementById('mainClient'));
});

if (module.hot) {
  module.hot.accept();
}