export const constants = {
  DOMAIN_OPTIONS : {
    industries: {
      dropdownValues: [
        {label: 'Accessories', value: 'Accessories'}, 
        {label: 'Health & Beauty', value: 'Health & Beauty'},
        {label: 'Home & Garden', value: 'Home & Garden'},
        {label: 'Fashion', value: 'Fashion'},
        {label: 'Other', value: 'Other'},
        {label: 'Technology', value: 'Technology'}
      ]
    }
  }
};