import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classnames from 'classnames';
import * as headerActions from '../../../../actions/client/headersActions';
import config from '../../../../config/config';

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.isImpersonator = props.isImpersonator;
  }

  showMobileMenu = e => {
    e.preventDefault();
    this.props.dispatch(
      headerActions.toggleHeaderMenu(this.props.headerMenuVisible)
    );
  };

  logout = () => {
    var query = '/logout';
    this.props.router.push({ pathname: query });
  };

  returnToRoot = () => {
    var query = '/client';
    this.props.router.push({ pathname: query });
  };

  getActivePageName = () => {
    const pathArr = this.props.location.pathname.split('/');
    const path = '/' + pathArr[1] + '/' + pathArr[2];
    const locObj = this.props.menuItems.find(mI => mI.route == path);
    if (!locObj) return;
    return locObj.name;
  };

  showCalendar = () => {
    const routes = this.props.routes;

    for (let counter = routes.length - 1; counter > -1; counter -= 1) {
      const route = routes[counter];
      if (route.index) {
        return route.index;
      }
    }
  };

  onToggleCalendar = () => {
    this.props.dispatch(
      headerActions.toggleCalendar(!this.props.toggleCalendar)
    );
  };

  renderCalendar = () => {
    const pageName = this.getActivePageName();
    if (pageName === 'User Data') {
      return null;
    }

    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const startDate = this.props.calendar[0] && this.props.calendar[0].startDate.toLocaleDateString('en-GB', options);
    const endDate = this.props.calendar[0] && this.props.calendar[0].endDate.toLocaleDateString('en-GB', options);

    return (
      <div className={classnames('calendar', {
        showCalendar: this.showCalendar(),
        hideCalendar: !this.showCalendar()
      })}
      >
        <div className="calendar__date">{startDate}</div>
        <div className="calendar__logo"
          onClick={() => this.onToggleCalendar()}
        />
        <div className="calendar__date">{endDate}</div>
      </div>
    );
  };

  render() {
    return (
      <header className="client header">
        <Link className="logo" to={'/client/'}/>
        <div className="content">
          <span className="page-title">Soreto {this.getActivePageName()}</span>
        </div>

        {this.renderCalendar()}

        <div className="settings-menu">
          {this.isImpersonator ? (
            <a href={config.URL.BASEBACK + '/unimpersonate'}>
              <i className="fa fa-sign-out" /> unimpersonate
            </a>
          ) : (
            ''
          )}
          <Link to={'/client/settings'} onClick={this.props.logout}>
            <i className="fa fa-cog" /> settings
          </Link>
          <a onClick={this.props.logout}>
            <i className="fa fa-sign-out" /> logout
          </a>
        </div>
      </header>
    );
  }
}

export default connect(store => {
  return {
    headerMenuVisible: store.clientHeader.headerMenuVisible,
    calendar: store.sharedDateRangePicker.setCalendarDates,
    toggleCalendar: store.clientHeader.toggleCalendar,
    selectedCurrency: store.currencySwitcher.selectedCurrency
  };
})(Header);
