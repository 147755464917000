import request from '../wrappers/axios';
import querystring from 'querystring';
import config from '../../config/config';

class AssocClientAffilliateService {

  create (data) {
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate`,
      method: 'post',
      data: data
    });
  }

  getAll(){
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate`,
      method: 'get',
    });
  }

  update (data) {
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate/${data._id}`,
      method: 'patch',
      data: data
    });
  }

  get (affiliateId) {
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate/${affiliateId}`,
      method: 'get'
    });
  }

  delete (affiliateId) {
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate/${affiliateId}`,
      method: 'delete'
    });
  }

  getAssocClientAffilliateByClientId (clientId) {
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate?clientId=${clientId}`,
      method: 'get',
    });
  }

  getAssocClientAffilliateByClientIdAndCountryId (clientId, countryId) {
    return request({
      url: `${config.API.BASEURL}/assocclientaffilliate?clientId=${clientId}&countryId=${countryId}`,
      method: 'get'
    });
  }
}

export default new AssocClientAffilliateService();