import React from 'react';
import { Route, IndexRedirect, withRouter } from 'react-router';

import Dashboard from './pages/dashboard/Dashboard';
import Layout from './layout/Layout';
import Activity from './pages/activity/Activity';
import SocialPost from './pages/social/SocialPost';
import Reports from './pages/reports/Reports';
import ProductTractionReport from './pages/reports/productTractionReport/ProductTractionReport';
import SocialTractionReport from './pages/reports/socialTractionReport/SocialTractionReport';
import Transactions from './pages/transactions/Transactions';
import Settings from './pages/settings/Settings';
import Account from './pages/settings/account/Account';
import Social from './pages/settings/social/Social';
import PaymentMethod from './pages/settings/paymentMethod/PaymentMethod';
import Retailers from './pages/retailers/Retailers';

const userRoutes =  (
  <Route path="/user" component={withRouter(Layout)} >
    <IndexRedirect to={'/user/dashboard'} />
    <Route path="dashboard" component={withRouter(Dashboard)} />
    <Route path="activity" component={Activity} />
    <Route path="socialpost" component={SocialPost} />
    <Route path="transactions" component={Transactions} />
    <Route path="brands" component={Retailers} />
    <Route path="reports" component={Reports} >
      <IndexRedirect to={'/user/reports/productconversionreport'} />
      <Route path="productconversionreport" component={ProductTractionReport} />
      <Route path="socialtractionreport" component={SocialTractionReport} />
    </Route>
    <Route path="settings" component={Settings} >
      <IndexRedirect to={'/user/settings/account'} />
      <Route path="paymentmethods" component={PaymentMethod} />
      <Route path="account" component={Account} />
      <Route path="social" component={Social}/>
    </Route>
  </Route>
);
  
export default userRoutes;