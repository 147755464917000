export default {
  validFontSize: (fontSize)=>{
    if(fontSize && isNaN(fontSize)){
      return false;
    }
    return true;
  },
  validGoogleUrl: (url) => {
    if(url && !url.includes('https://fonts.googleapis.com/css?family=')){
      return false;
    }
    return true;
  },
  getFontFamily: (props) => {
    return props.split(':');
  }
};