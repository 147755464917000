import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import validator from 'validator';
import { connect } from 'react-redux';
import common from './common';
import FormElement from '../../../shared/form/FormElement';
import * as campaignVersionActions from '../../../../actions/admin/campaignVersionActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import RewardPoolDropbox from '../../../shared/dropbox/RewardPoolDropbox';
import campaignService from '../../../../utils/services/campaign';

import './_campaignVersion.scss';

@connect((store) => {
  return {
    campaignVersionGet: store.adminCampaignVersion.campaignVersionGet,
    campaignVersionEdit: store.adminCampaignVersion.campaignVersionEdit,
  };
})

class CampaignVersionEdit extends React.Component {

  constructor(props) {
    super(props);

    this.flowTypeOptions = [
      { label: 'None', value: null },
      { label: 'Sharer Triggered Reward', value: 'sharer_triggered_reward' },
      { label: 'Friend Triggered Reward', value: 'friend_triggered_reward' },
      { label: 'Both Triggered Reward', value: 'both_triggered_reward' },
      { label: 'Advertisement', value: 'advertisement' },
      { label: 'Direct Reward', value:'direct_reward' },
      { label: 'Custom Flow', value: 'custom_flow' },
      { label: 'Marketplace Simple', value: 'mp_simple' },
      { label: 'Marketplace Promotion', value: 'mp_promotion' },
    ];

    const {
      campaignVersionDetails = {
        clientId:'',
        campaignId:'',
        name:'',
        alias:'',
        active: '',
        exposure: '',
        rewardPoolId: '',
        linkExpiryDays: '',
        privateLinkExpiryDays: '',
        documentUrl: '',
        sourceTags: [],
        archived: false,
        publicSharedUrlExpiresAt:'',
        privateSharedUrlExpiresAt:'',
        flowType: null,
        shopifyImgUrl: '',
      }
    } = props;

    this.state = {
      campaignVersionDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      flowType: null
    };

    this.onRewardPoolChange = this.onRewardPoolChange.bind(this);
  }

  onChange(name, {target}) {
    const {campaignVersionDetails} = this.state;
    _.set(campaignVersionDetails, name, target.value);
    this.setState({campaignVersionDetails});
  }

  onActiveChange(name, {target}) {
    const {campaignVersionDetails} = this.state;
    if(target.value === 'true'){
      _.set(campaignVersionDetails, 'archived', false);
    }
    _.set(campaignVersionDetails, name, target.value);
    this.setState({campaignVersionDetails});
  }

  onChangeCheckbox(name, {target}) {
    const {campaignVersionDetails} = this.state;
    _.set(campaignVersionDetails, name, target.checked);
    this.setState({campaignVersionDetails});
  }

  componentWillMount() {
    var campaignVersionId = this.props.params.campaignVersionId;
    console.log(campaignVersionId);
    if(campaignVersionId){
      this.props.dispatch(campaignVersionActions.getCampaignVersion(campaignVersionId));

      if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
        this.props.router.replace({...this.props.location, state: null});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'New campaign version added',
          timeout: 20000
        }));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.campaignVersionGet){
    //nextProps.campaign.expiry = new Date(nextProps.campaign.expiry);
      campaignService.getCampaign(nextProps.campaignVersionGet.campaignId).then((data) => {
        if(data.data) {

          const {campaignVersionDetails} = this.state;
          _.set(campaignVersionDetails, 'clientId', data.data.clientId);
          this.setState({campaignVersionDetails});
        }
        else {
          nextProps.campaignVersionGet.clientId = null;
        }
      });

      this.setState({ campaignVersionDetails : nextProps.campaignVersionGet, hardStop : nextProps.campaignVersionGet.linkExpiryDays == -1 ? true : false });
      this.setState({ campaignVersionDetails : nextProps.campaignVersionGet, hardStop : nextProps.campaignVersionGet.privateLinkExpiryDays == -1 ? true : false });
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.campaignVersionEdit != nextProps.campaignVersionEdit){
      if(_.get(nextProps,'campaignVersionEdit.name')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Campaign version saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'campaignVersionEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.campaignVersionEdit.data.message,
          timeout: 10000
        }));
      }
    }


  }

  onPublicSharedUrlExpiresAtChange(date){
    const {campaignVersionDetails} = this.state;
    _.set(campaignVersionDetails, 'publicSharedUrlExpiresAt', date.value);
    this.setState({campaignVersionDetails});
  }

  onPrivateSharedUrlExpiresAtChange(date){
    const {campaignVersionDetails} = this.state;
    _.set(campaignVersionDetails, 'privateSharedUrlExpiresAt', date.value);
    this.setState({campaignVersionDetails});
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const {campaignVersionDetails,errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }


    if( !checkErrors ){
      campaignVersionDetails.updatedAt = new Date();
      let campaignVersionDetailsWithNulls = _.mapValues(campaignVersionDetails, function (v) { // explicitly set empty vals to null
        if( v === ''){
          return null;
        }else{
          return v;
        }
      });

      this.props.dispatch(campaignVersionActions.updateCampaignVersion(campaignVersionDetailsWithNulls));
    }
  }

  validateForm() {
    const { campaignVersionDetails, errors } = this.state;

    errors[ 'campaignId' ] = validator.isEmpty(campaignVersionDetails.campaignId);
    errors[ 'name' ] = validator.isEmpty( campaignVersionDetails.name );
    errors[ 'exposure' ] = validator.isEmpty( campaignVersionDetails.exposure.toString() );
    errors[ 'active' ] = validator.isEmpty( campaignVersionDetails.active.toString() );
    errors[ 'alias' ] = validator.isEmpty( campaignVersionDetails.alias );
    errors[ 'linkExpiryDays' ] = (()=>{
      if (campaignVersionDetails.linkExpiryDays === ''){
        return true;
      }
      return !validator.isInt(campaignVersionDetails.linkExpiryDays+'',{min:-1,max:999});
    }
    )();

    errors[ 'privateLinkExpiryDays' ] = (()=>{
      if (campaignVersionDetails.privateLinkExpiryDays === ''){
        return true;
      }
      return !validator.isInt(campaignVersionDetails.privateLinkExpiryDays+'',{min:-1,max:999});
    }
    )();

    return this.setState({ errors });
  }

  onRewardPoolChange(e){
    this.setState({rewardPoolId: e.value});
    const {campaignVersionDetails} = this.state;
    _.set(campaignVersionDetails, 'rewardPoolId', e.value);
    this.setState({campaignVersionDetails});
  }

  render (){
    const { campaignVersionDetails = { info: {} }, sourceTag, errors } = this.state;
    const { hardStop } = this.state;

    const {
      clientId,
      campaignId,
      name,
      alias,
      sourceTags,
      active,
      exposure,
      rewardPoolId,
      linkExpiryDays,
      privateLinkExpiryDays,
      mpOfferTitle,
      documentUrl,
      trackingLink,
      affTrackingLinkOnLoad,
      archived,
      shopifyImgUrl
    } = campaignVersionDetails;

    return (
      <div>
        <h3>Edit campaign version</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Campaign Id'
            name='campaignId'
            type='text'
            onChange={this.onChange.bind(this, 'campaignId')}
            value={campaignId}
            error={errors.campaignId}
            id='form-campaignId'
            required
            disabled
          />

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Exposure'
            name='exposure'
            type='text'
            onChange={this.onChange.bind(this, 'exposure')}
            value={exposure}
            error={errors.exposure}
            id='form-exposure'
            required
          />

          <RewardPoolDropbox
            label='Reward Pool'
            client={clientId}
            value={rewardPoolId}
            onChange={this.onRewardPoolChange}
            error={errors.rewardPoolId}
            id='form-rewardpoolid'
          />

          <fieldset>
            <legend>Expiration</legend>
            <div className="ui-g">
              <div className="ui-g-12 ui-md-6 ui-lg-3">
                <FormElement
                  label='Public Links Expiration Absolute Date (This field uses UTC time)'
                  name='publicShareUrlExpiresAt'
                  tooltip="A Disabled Button"
                  type='calendarWithTime'
                  onChange={(e) => 
                    this.onPublicSharedUrlExpiresAtChange(e)
                  } 
                  value={ this.state.campaignVersionDetails.publicSharedUrlExpiresAt? new Date(this.state.campaignVersionDetails.publicSharedUrlExpiresAt) : ''}
                  id='start-date'
                  style={{ marginLeft: '5px', width: '200px' }}
                />
              </div>

              <div className="ui-g-12 ui-md-6 ui-lg-3">
                <FormElement
                  label='Private Links Expiration Absolute Date (This field uses UTC time)'
                  name='privateShareUrlExpiresAt'
                  type='calendarWithTime'
                  onChange={(e) => 
                    this.onPrivateSharedUrlExpiresAtChange(e)
                  } 
                  value={ this.state.campaignVersionDetails.privateSharedUrlExpiresAt? new Date(this.state.campaignVersionDetails.privateSharedUrlExpiresAt) : ''}
                  id='expiry'
                  style={{ marginLeft: '5px', width: '200px' }}
                />                  
              </div>
              <div className="ui-g-12 ui-md-6 ui-lg-3">
                <div className='daysExpiry'>
                  <FormElement
                    label='days until public link expiry'
                    name='linkExpiryDays'
                    type='text'
                    onChange={this.onChange.bind(this, 'linkExpiryDays')}
                    disabled={hardStop}
                    value={linkExpiryDays}
                    error={errors.linkExpiryDays}
                    id='form-link-expiry-days'
                    required
                  />                  
                </div>
              </div>

              <div className="ui-g-12 ui-md-6 ui-lg-3">
                <div className='daysExpiry'>
                  <FormElement
                    label='days until private link expiry'
                    name='priviteLinkExpiryDays'
                    type='text'
                    onChange={this.onChange.bind(this, 'privateLinkExpiryDays')}
                    disabled={hardStop}
                    value={privateLinkExpiryDays}
                    error={errors.priviteLinkExpiryDays}
                    id='form-private-link-expiry-days'
                    required
                  />
                  <div class="tooltip"><i class="fa fa-question-circle" aria-hidden="true"></i>
                    <span class="tooltiptext">
                    The Shared Url will expire according to the <b> Absolute Date</b> or <b> Days until link expiry</b>. 
                      The closest expiration date will be considered. The absolute date can be null, so only <b> Days until link expiry</b> will be considered. 
                      <b> Days until link expiry</b> is calculated from the Shared Url creation’s date.
                      <b>Public links</b> are the ones generated during the sharing process. <b>Private links</b> are the ones generated for a single targeted person.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <FormElement
            label='Marketplace Offer Title'
            name='mpOfferTitle'
            type='text'
            onChange={this.onChange.bind(this, 'mpOfferTitle')}
            value={mpOfferTitle}
            id='form-marketplace-offer-title'
          />

          <FormElement
            label='Document URL'
            name='documentUrl'
            type='text'
            onChange={this.onChange.bind(this, 'documentUrl')}
            disabled={hardStop}
            value={documentUrl}
            id='form-document-url'
          />

          <FormElement
            label='Tracking Link'
            name='trackingLink'
            type='text'
            onChange={this.onChange.bind(this, 'trackingLink')}
            value={trackingLink}
            id='tracking-link'
          />
          
          <div className='affTrackingLinkOnLoad'>
            <FormElement
              label='Affiliate Tracking Link on Landing Page Load'
              name='affTrackingLinkOnLoad'
              type='text'
              onChange={this.onChange.bind(this, 'affTrackingLinkOnLoad')}
              value={affTrackingLinkOnLoad}
              id='affTrackingLinkOnLoad'
            />
            <div class="tooltip"><i class="fa fa-question-circle" aria-hidden="true"></i>
              <span class="tooltiptext">This tracking link is used by the landing page on its automatic redirection process on load. The user has no access to this link. This value must implement 2 dynamic values, <b>{'{sua_id}'}</b> to the click identification and <b>{'{red_url}'}</b> to the callback url
              </span>
            </div>
          </div> 

          <FormElement
            label='Shopify image URL'
            name='shopifyImgUrl'
            type='text'
            onChange={this.onChange.bind(this, 'shopifyImgUrl')}
            value={shopifyImgUrl}
            id='tracking-link'
          />

          <FormElement
            label='Hard Stop'
            name='hardStop'
            type='checkbox'
            onChange={ 
              (e) => { 
                _.set(campaignVersionDetails, 'linkExpiryDays', e.target.checked ? -1 : 30);
                _.set(campaignVersionDetails, 'privateLinkExpiryDays', e.target.checked ? -1 : 30);
                this.setState({ hardStop : e.target.checked, campaignVersionDetails }); 
              } 
            } 
            checked={(!!(hardStop))}
            error={errors.hardStop}
            id='form-active'
          />

          <FormElement
            label='Source Tag'
            name='sourceTag'
            type='text'
            onChange={ (e) => {  
              if(e.target.fromSugestion){ 
                common.addTagOption(this, e.target.value) ;
              } else { 
                this.setState({sourceTag : e.target.value}); 
              }
            }}
            onKeyPress={(e) => { if(e.key == 'Enter'){  common.addTagOption(this, sourceTag); } }}
            value={sourceTag}
            error={errors.sourceTag}
            id='form-source-tag'
            fillingOptions={['CONFIRMATION_PAGE', 'STATIC_PAGE_ON_SORETO', 'STATIC_PAGE_ON_CLIENT']}>
     
          </FormElement> 

          <div>
            { common.sourceTagOptions(this, sourceTags) }
          </div>

          <FormElement
            label='Flow Type'
            name='flowType'
            type='dropdown'
            onChange={(e) => { 
              const { campaignVersionDetails } = this.state;
              _.set(campaignVersionDetails, 'flowType', e.value);
              this.setState({campaignVersionDetails});
            }}
            value={this.state.campaignVersionDetails.flowType}
            style={{ marginLeft: '5px', width:'300px' }} 
            error={errors.flowType}
            options={this.flowTypeOptions}
            id='type'
          />

          <FormElement
            label='Alias'
            name='alias'
            type='text'
            onChange={this.onChange.bind(this, 'alias')}
            value={alias}
            error={errors.alias}
            id='form-alias'
            required
          />

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onActiveChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Archived'
            name='archived'
            type='checkbox'
            onChange={this.onChangeCheckbox.bind(this, 'archived') } 
            disabled={(active == 'true' || active === true)}
            checked={(!!(archived))}
            error={errors.archived}
            id='form-active'
          />
        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Save</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/campaignVersion')}>
          Back
        </button>
      </div>
    );
  }
}

export default CampaignVersionEdit;
