import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const AssociateEmailToCampaignVersion = (state = initialState , action) => {
  switch(action.type) {
  
  case constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_ALL: {
    return { ...state, associateEmailToCampaignVersionGetAll: action.payload };
  }

  case constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_GET_BY_ID: {
    return { ...state, associateEmailToCampaignVersionGetById: action.payload };
  }

  case constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_EDIT: {
    return { ...state, associateEmailToCampaignVersionEdit: action.payload[0] };
  }

  case constants.admin.ASSOCIATE_EMAIL_TO_CAMPAIGN_VERSION_NEW: {
    return { ...state, associateEmailToCampaignVersionNew: action.payload };
  }

  default: return state;

  }
};

export default AssociateEmailToCampaignVersion;