import React from 'react';
import classnames from 'classnames';

import environmentService from '../../../utils/services/environment';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class EnvironmentDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.getEnvironmentList = this.getEnvironmentList.bind(this);
    
    this.state = {
      environment: this.props.value ? this.props.value : null,
      environmentList: null
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getEnvironmentList();  
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.value === 'object') {
      this.setState({ environment: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ environment: nextProps.value });
    }
  }

  getEnvironmentList() {

    let environmentList = null;
    environmentService.getEnvironmentList().then((data) => {
      if(data.data.page) {
        environmentList = data.data.page.map( environment => ({ label: environment.name, value: environment.name }));
      }
      
      this.setState({ environmentList });
    });
  }

  innerEnvironmentTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ environment: e.value });
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    const { label, required } = this.props;

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>
      
        { showlabel }

        <Dropdown 
          value={this.state.environment} showClear
          options={this.state.environmentList} onChange={this.onChange} 
          itemTemplate={this.innerEnvironmentTemplate} 
          style={{ marginLeft: '5px', width:'300px', marginTop: '4px' }} 
          filter={true} filterPlaceholder="Select an Environment" 
          filterBy="label,value" placeholder="Select an Environment"/>
      </div>
      
    );
  }
}
