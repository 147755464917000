import React from 'react';
import socialHelper from '../../../../utils/helpers/social';
import userService from '../../../../utils/services/user';
import trackingService from '../../../../utils/services/tracking';
import * as socialActions from '../../../../actions/user/socialActions';
import _ from 'lodash';
import { connect } from 'react-redux';


@connect((store) => {
  return {
    loginCtx : store.loginCtx.loginCtx,
    userSocialPlatforms : store.userSocial.userSocialPlatforms
  };
})

class SocialConnect extends React.Component{
  componentWillMount(){
    this.props.dispatch(socialActions.setUserSocialPlatforms(this.props.loginCtx._id));
  }

  connect (e, url , platform){
    e.preventDefault();

    trackingService.socialConnect(this.props.loginCtx,platform);

    window.open(
      url,
      'targetWindow',
      'toolbar=no,' +
      'location=no,' +
      'status=no,' +
      'menubar=no,' +
      'scrollbars=yes,' +
      'resizable=yes,' +
      'width=550,' +
      'height=700');
    return false;
  }

  getSocialAuthUrl (socialPlatform) {
    return socialHelper.getSocialAuthUrl(socialPlatform);
  }

  deleteSocialAuth (e, socialPlatform) {
    e.preventDefault();

    trackingService.socialDisconnect(this.props.loginCtx , socialPlatform);

    userService.deleteUserSocialAuths(this.props.loginCtx._id, socialPlatform).then(() => {
      this.props.dispatch(socialActions.clearUserSocialPlatforms());
      this.props.dispatch(socialActions.setUserSocialPlatforms(this.props.loginCtx._id));
    }).catch((err) => {
      console.log(err);
    });
  }

  closeModal(){
    this.props.dispatch(socialActions.setConnectModalStatus(false));
  }

  reloadPlatforms(){
    this.props.dispatch(socialActions.clearUserSocialPlatforms());
    this.props.dispatch(socialActions.setUserSocialPlatforms(this.props.loginCtx._id));
  }

  render(){
    return(
      <div className="social-connect">
        <i className="fa fa-times close-modal" onClick={() => this.closeModal() }></i>
        <div className="grid-parent grid-100" parent={true} desktop={'100'}>
          <h1 className="text-center">Manage Networks</h1>
          <table id="social-connect-panel-table">
            <tbody>
            {_.map(this.props.userSocialPlatforms, (obj, key) => {
              return (
                <tr key={key}>
                  <td><i className={`${obj.ICONCLASS} icon-${key.toLowerCase()}`}></i></td>
                  <td>{obj.TITLE}</td>
                  <td className={'text-right'}>
                    {
                      obj.STATUS ? <button className={'secondary btn-connect'} onClick={(event) => {this.deleteSocialAuth(event, key);}}>Disconnect</button> : <button className={'primary btn-connect'} onClick={(event) => {this.connect(event, this.getSocialAuthUrl(key.toLowerCase()),key);}}>Connect</button>
                    }
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        <button style={{display:'none'}} onClick={() => this.reloadPlatforms() } id="platform-reloader">Reload</button>
      </div>
    );
  }
}

export default SocialConnect;