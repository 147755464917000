import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as rewardDiscountCodeActions from '../../../../actions/admin/rewardDiscountCodeActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';
import RewardsDropbox from '../../../shared/dropbox/RewardsDropbox';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';

export class RewardDiscountCodeEdit extends React.Component {
  constructor(props) {
    super(props);

    const {
      rewardDiscountCode = {
        _id: '',
        discountType: '',
        code: '',
        valueAmount: '',
        activeFrom: '',
        activeTo: '',
        activeToOriginal:'',
        validFrom: '',
        validTo: '',
        validToOriginal:'',
        rewardId: '',
        clientId: '',
        active: false,
        hasExpirationDate: false,
        hasVoucherExpirationDate: false
      }
    } = props;

    this.state = {
      rewardDiscountCode,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false
    };
  }

  onChange({ target }, name) {
    const { rewardDiscountCode } = this.state;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if(name === 'hasExpirationDate' && value === false) {
      _.set(rewardDiscountCode, 'activeTo', null);  
    }

    if(name === 'hasVoucherExpirationDate' && value === false) {
      _.set(rewardDiscountCode, 'validTo', null);  
    }

    _.set(rewardDiscountCode, name, value);
    this.setState({ rewardDiscountCode });
  }

  onChangeActiveFrom = e => {
    const { rewardDiscountCode } = this.state;
    _.set(rewardDiscountCode, 'activeFrom', e.value);
    this.setState({ rewardDiscountCode });
  };

  onChangeActiveTo = e => {
    const { rewardDiscountCode } = this.state;
    _.set(rewardDiscountCode, 'activeTo', e.value);
    this.setState({ rewardDiscountCode });
  };

  onChangeValidFrom = e => {
    const { rewardDiscountCode } = this.state;
    _.set(rewardDiscountCode, 'validFrom', e.value);
    this.setState({ rewardDiscountCode });
  };

  onChangeValidTo = e => {
    const { rewardDiscountCode } = this.state;
    _.set(rewardDiscountCode, 'validTo', e.value);
    this.setState({ rewardDiscountCode });
  };

  componentWillMount() {
    var rewardId = this.props.params.rewardId;
    if (rewardId) {
      this.props.dispatch(rewardDiscountCodeActions.getRewardDiscountCode(rewardId));
    }
  }

  componentDidMount() {
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New reward discount code',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.rewardDiscountCode){
      nextProps.rewardDiscountCode.hasExpirationDate = !!nextProps.rewardDiscountCode.activeTo;
      nextProps.rewardDiscountCode.hasVoucherExpirationDate = !!nextProps.rewardDiscountCode.validTo;
  
      this.setState({ rewardDiscountCode: nextProps.rewardDiscountCode });
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.rewardDiscountCodeEdit != nextProps.rewardDiscountCodeEdit) {
      if (_.get(nextProps, 'rewardDiscountCodeEdit._id')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: 'Reward saved with success',
            timeout: 20000
          })
        );
      }
      if (_.get(nextProps, 'rewardDiscountCodeEdit.data')) {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Error: ' + nextProps.rewardDiscountCodeEdit.data.message,
            timeout: 10000
          })
        );
      }
    }
  }

  onSubmit() {
    this.setState({ displayErrorMessage: false });
    const { rewardDiscountCode, errors } = this.state;
    let checkErrors = false;
    
    this.setState({ rewardDiscountCode });

    this.validateForm();

    for (const key in errors) {
      if (errors[key]) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Please fill all the required',
            timeout: 10000
          })
        );
        break;
      }
    }
    
    if (!checkErrors) {
      this.props.dispatch(rewardDiscountCodeActions.updateRewardDiscountCode(rewardDiscountCode));
    }
  }

  validateForm() {
    const { rewardDiscountCode, errors } = this.state;
    errors['valueAmount'] = validator.isEmpty(rewardDiscountCode.valueAmount.toString());
    errors['activeFrom'] = validator.isEmpty(rewardDiscountCode.activeFrom.toString());
    errors['activeTo'] = rewardDiscountCode.hasExpirationDate && !rewardDiscountCode.activeTo;
    errors['validFrom'] = validator.isEmpty(rewardDiscountCode.validFrom.toString());
    errors['validTo'] = rewardDiscountCode.hasVoucherExpirationDate && !rewardDiscountCode.validTo;
    errors['code'] = validator.isEmpty(rewardDiscountCode.code);

    return this.setState({ errors });
  }

  render() {
    const { rewardDiscountCode = { info: {} }, errors, displayErrorMessage } = this.state;
    const {
      discountType,
      valueAmount,
      activeFrom,
      activeTo,
      validFrom,
      validTo,
      code,
      active,
      rewardId,
      clientId,
      hasExpirationDate,
      hasVoucherExpirationDate
    } = rewardDiscountCode;

    const calendarStyle = {
      marginLeft: '5px',
      width: '300px'
    };

    return (
      <div className="reward-new-reward">
        <h3>Edit Reward Discount Code</h3>
        <form className="c-form--flex">
          <ClientsDropbox
            disabled
            label="Client"
            value={clientId}
            onChange={this.onClientChange}
            error={errors.client}
          />
          <RewardsDropbox
            disabled
            label="Reward"
            client={clientId}
            value={rewardId}
            onChange={this.onRewardChange}
            error={errors.rewardId}
          />

          <FormElement
            label="discountType"
            name="discountType"
            type="select"
            value={discountType}
            onChange={event => this.onChange(event, 'discountType')}
            //error={errors.discountType}
            id="form-discountType"
            required
          >
            <option value=""> </option>
            <option value="percentage">percentage</option>
          </FormElement>

          <FormElement
            label="valueAmount"
            name="valueAmount"
            type="text"
            onChange={event => this.onChange(event, 'valueAmount')}
            value={valueAmount}
            error={errors.type}
            id="form-valueAmount"
            required
          />

          <FormElement
            label="Code"
            name="code"
            type="text"
            onChange={event => this.onChange(event, 'code')}
            value={code}
            error={errors.type}
            id="form-code"
            required
          />

          <FormElement
            label='Has expiry date'
            name='hasExpirationDate'
            type='checkbox'
            onChange={e => {this.onChange(e, 'hasExpirationDate');} } 
            checked={hasExpirationDate}
            id='form-activeCheck'
          />

          <FormElement
            label="Active From"
            name="activeFrom"
            type="calendar"
            onChange={this.onChangeActiveFrom}
            value={new Date(activeFrom)}
            error={errors.activeFrom}
            id="form-activeFrom"
            required
            style={calendarStyle}
          />

          <FormElement
            label="Active To"
            name="activeTo"
            type="calendar"
            onChange={this.onChangeActiveTo}
            value={activeTo ? new Date(activeTo) : null}
            error={errors.activeTo}
            id="form-activeTo"
            disabled={!hasExpirationDate}
            style={calendarStyle}
          />

          <FormElement
            label='Has the voucher a expiry date'
            name='hasVoucherExpirationDate'
            type='checkbox'
            onChange={e => {this.onChange(e, 'hasVoucherExpirationDate');}} 
            checked={(hasVoucherExpirationDate)}
            id='form-validCheck'
          />

          <FormElement
            label="Valid From"
            name="validFrom"
            type="calendar"
            onChange={this.onChangeValidFrom}
            value={new Date(validFrom)}
            error={errors.validFrom}
            id="form-validFrom"
            required
            style={calendarStyle}
          />

          <FormElement
            label="Valid To"
            name="validTo"
            type="calendar"
            onChange={this.onChangeValidTo}
            value={ validTo ? new Date(validTo) : null}
            error={errors.validTo}
            disabled={!hasVoucherExpirationDate}
            id="form-validTo"
            style={calendarStyle}
          />

          <FormElement
            label="active"
            name="active"
            type="select"
            value={active}
            onChange={event => this.onChange(event, 'active')}
            //error={errors.active}
            id="form-active"
            required
          >
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>
        </form>

        <br />
        <button onClick={() => this.onSubmit()}>Edit</button>

        <button className="button icon-left" onClick={() => browserHistory.push('/admin/rewardDiscountCode')}>
          Back
        </button>
      </div>
    );
  }
}

export default connect(store => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    rewardDiscountCode: store.adminRewardDiscountCode.rewardDiscountCode,
    rewardDiscountCodeEdit: store.adminRewardDiscountCode.rewardDiscountCodeEdit
  };
})(RewardDiscountCodeEdit);
