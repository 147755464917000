import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Panel } from 'primereact/components/panel/Panel';
import CampaignVersionService from '../../../../../../utils/services/campaignVersion';
import NotificationService from '../../../../../../utils/services/notification';
import EmailTemplateService from '../../../../../../utils/services/emailTemplate';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';

import config from '../../../../../../config/config';
import { getData } from './data';

// import * as globalMessageActions from '../../../../../../actions/user/globalMessageActions';

class EmailPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      campaignVersionId: this.props.campaignVersionId,
      simulationStatus: {
        status: null,
        message: '',
        id: null,
        dialogConfirmationVisible: false,
        selectedEmailTemplate: ''
      }
    };
  }

  dialogConfirmationFooter = () => {
    return <div style={{ display: 'inline-block', width: '100%' }} className="ui-dialog-buttonpanel p-clearfix">
      <Button label="Delete" onClick={(e) => this.deleteEmailTemplateById(e,this.state.selectedEmailTemplate)} />
      <Button label="Cancel" onClick={this.closeConfirmationPopup} />
    </div>;
  };

  dialogFooter = () => {
    return <Button label="Create" icon="fa fa-check" onClick={(e) => this.onAddBlacklistEmail(e)} />;
  };

  closeConfirmationPopup = () => {
    var reset = {
      dialogConfirmationVisible: false,
      selectedEmailAssociation: '' 
    };

    this.setState(reset);
  }

  resetFilter=(e)=> {
    this.setState({
      campaignVersionId: this.props.campaignVersionId,
      simulationStatus: {
        status: null,
        message: '',
        id: null,
        dialogConfirmationVisible: false,
        selectedEmailTemplate: ''
      }

    }); 
    this.onLazyLoad(e);
  }

  renderConfirmationDialog = () => {
    var headerName = `Confirmation`;

    return (

      <Dialog
        header={headerName}
        footer={this.dialogConfirmationFooter()}
        visible={this.state.dialogConfirmationVisible}
        width="550px"
        modal={true}
        resizable={true}
        responsive={true}
        onHide={this.closeConfirmationPopup}
      >
        <div className="content-section implementation">
          <span>Are you sure you want to delete this email template?</span>
        </div>

      </Dialog>
    );
  };


  editAction = (rowData) => {

    
    let emulation = [
      'Post Purchase Friend Reward', 
      'Post Purchase Discount', 
      'shared_url_notification_personal_no_click',
      'shared_url_notification_personal_no_order'].includes(rowData.type);

    let emulationIcon = <span 
      className="fa fa-paper-plane-o" title="teste" style={{cursor: 'pointer', marginRight: '10px'}}
      onClick={() => { this.simulateAction(rowData); }}/>;

    if(rowData._id == this.state.simulationStatus.id){


      switch(this.state.simulationStatus.status){
      case 'error':
        emulationIcon = <span 
          className="fa fa-exclamation-triangle" 
          title={this.state.simulationStatus.message}
          style={{cursor: 'pointer', marginRight: '10px'}}
          onClick={() => { this.setState({ simulationStatus: {}}); }}></span>;
        break;
      case 'success':
        emulationIcon = <span
          className="fa fa-check"
          title="The email was sent"
          style={{cursor: 'pointer', marginRight: '10px'}}
          onClick={() => { this.setState({ simulationStatus: {}}); }}
        ></span>;
        break;
      case 'executing':
        emulationIcon = <span 
          style={{cursor: 'pointer', marginRight: '10px'}}
          title="wait"
          class="fa fa-spinner" aria-hidden="true"></span>;
        break;
      }
    }

    return (
      <div>

        {emulation && emulationIcon}
        <span
          className="fa fa-cog" style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(`${config.URL.BASEFRONT}/admin/emailTemplate/${rowData._id}`, '_blank');
          }} />
        <div>
          <Button
            type="button"
            icon="fa fa-trash"
            style={{color: '#485465', backgroundColor: 'transparent', padding: 0, margin: 0}}
            onClick={(e) => { this.showConfirmationDialog(e,rowData); }}> </Button>
        </div>


      </div>
    );
  }

  simulateAction = (rowData) => {

    
    
    this.setState({ simulationStatus: { status: 'executing', message: '', id: rowData._id }});

    if(['shared_url_notification_personal_no_click',
      'shared_url_notification_personal_no_order'].includes(rowData.type)){

      let type = rowData.type; // == 'shared_url_notification_personal_no_click' ? 'blah' : 'blah2';
      NotificationService.simulate(this.state.campaignVersionId, type, 'sarah@soreto.com')
        .then(() => {
          this.setState({ simulationStatus: { status: 'success', message: '', id: rowData._id }});
        })
        .catch((error)=> {
          let message = _.get(error, 'response.data') || error;
          
          try {
            message = JSON.stringify(message);
          } catch (error) {
            message = error;
          }

          this.setState({ simulationStatus: { status: 'error', message, id: rowData._id }});  
        });
    }else {
      CampaignVersionService.simulatePostReward(this.state.campaignVersionId, 'sarah@soreto.com', rowData.type == 'Post Purchase Discount' ? 'SHARER_POST_REWARD' : 'FRIEND_POST_REWARD' )
        .then(() => {
          this.setState({ simulationStatus: { status: 'success', message: '', id: rowData._id }});
        })
        .catch((error) => {
          let message = _.get(error, 'response.data') || error;

          try {
            message = JSON.stringify(message);
          } catch (error) {
            message = error;
          }

          this.setState({ simulationStatus: { status: 'error', message, id: rowData._id }});
        });
    }
  }

  onLazyLoad = (event) => {
    if (event){
      if ('first' in event){
        this.setState({first: event.first});
      }else{
        this.setState({first:0});
      }
    }

    this.setState({ loading: true});
    getData(this.state.campaignVersionId)
      .then(response => {
        this.setState({
          list: response,
          rewardPoolName: response.rewardPoolName,
          loading: false
        });
      });

  }

  componentDidMount = () => this.onLazyLoad();

  deleteEmailTemplateById = (e,id) => {
    EmailTemplateService.deleteEmailTemplateById(id)
      .then(() => {
        this.resetFilter(e);        
      })
      .catch((err) => {
        // this.props.dispatch(
        //   globalMessageActions.showMessage({
        //     type: 'error',
        //     message: err.message,
        //     timeout: 10000
        //   }));
        console.log(err);
      });     
    
    this.closeConfirmationPopup();
  };

  showConfirmationDialog = (e,rowData) => {
    this.setState({
      dialogConfirmationVisible: true,
      selectedEmailTemplate: rowData._id
    });
  };


  render = () =>
    <div>
      <Panel header={<span style={{color: '#485465'}} >Emails</span>}>
        <DataTable
          value={this.state.list}
          responsive={true}
          sortMode="multiple"
          lazy={true}
          onLazyLoad={this.onLazyLoad}
          loading={this.state.loading}
          ref={(el) => this.dt = el}
          first = {this.state.first}
        >
          <Column field="name" header="Name" style={{textOverflow:'ellipsis'}} />
          <Column field="externalTemplateId" header="External Template" style={{overflow: 'auto', display: 'block', textOverflow:'ellipsis'}}/>
          <Column body={e => this.editAction(e)} style={{ textAlign: 'center', width: '60px' }} />
        </DataTable>
      </Panel>
      {this.renderConfirmationDialog()}
    </div>
}

export default EmailPanel;
