import React from 'react';

import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import FormElement from '../../../shared/form/FormElement'; 

import moment from 'moment';
import * as globalMessageActions from '../../../../actions/user/globalMessageActions';
import { connect } from 'react-redux';
import userBlacklistService from '../../../../utils/services/userBlacklist';
import validator from 'validator';
import _ from 'lodash';

export class UserBlacklistList extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      userBlacklistList: [],
      searchField: '',

      dialogVisible: false,
      newEmail: '',
      newReason: '',
      newExpiryDate: '',

      errors: {},

      dialogConfirmationVisible : false,
      selectedBlacklistId: ''
    };
  
    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onAddBlacklistEmail = this.onAddBlacklistEmail.bind(this);
    this.onDeleteBlacklist = this.onDeleteBlacklist.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.refreshCache = this.refreshCache.bind(this);
  }

  resetFilter(event) {
    this.setState({
      dialogVisible: false,
      searchField: '',
      dialogConfirmationVisible: false,
      selectedBlacklistId: ''

    }, () => this.onLazyLoad(event));
  }
  
  getQuery(event) {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };
  
    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }
  
    return query;
  }
  
  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }
  
  formatData(data) {
    
    for(let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.expiryAt = _.isEmpty(row.expiryAt) ? ' - ' : moment(row.expiryAt).format('YYYY-MM-DD');  
    }
        
    this.setState({
      userBlacklistList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }
  
  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});
      
    userBlacklistService.getUserBlacklistList(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });
      
  } 
  
  onAddBlacklistEmail = (e) => {

    const {errors} = this.state;
    let checkErrors = false;

    const newEmailBlacklist = {};

    newEmailBlacklist.email = this.state.newEmail.trim();
    newEmailBlacklist.reason = this.state.newReason;
    newEmailBlacklist.expiryAt = ( Boolean(this.state.newExpiryDate) == false ) ? null : moment(this.state.newExpiryDate).format('YYYY-MM-DD');
    
    // validation
    this.validateForm(newEmailBlacklist);

    for (const key in errors) {
      if (errors[key]) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: 'Please fill all the required',
            timeout: 10000
          })
        );
        break;
      }
    }
    
    if( !checkErrors ){ 
      userBlacklistService.createUserBlacklist(newEmailBlacklist)
        .then((data) => {
          if(data && (data.status == 200 || data.status == 201)){
            
            this.props.dispatch(
              globalMessageActions.showMessage({
                type: 'success',
                message: 'Email created with success',
                timeout: 20000
              }));            

            this.closePopup();
            this.onLazyLoad(e);
          }
        })
        .catch( (err) => {

          let message = err.message;
          
          //specific cases for error
          if(err.response.status == 409){
            message = 'Email already exists in blacklist, check expiry date';
          }

          this.props.dispatch(
            globalMessageActions.showMessage({
              type: 'error',
              message: message,
              timeout: 10000
            }));
        }); 
    }
  }

  validateForm(newEmailBlacklist) {

    const {errors} = this.state;

    errors['email' ]   = validator.isEmpty(newEmailBlacklist.email.toString()) || !validator.isEmail(newEmailBlacklist.email);
    errors['reason']   = validator.isEmpty(newEmailBlacklist.reason.toString());
    
    return this.setState({ errors });
  }

  onDeleteBlacklist(e,selectedBlacklistId){
    userBlacklistService.deleteUserBlacklist(selectedBlacklistId)
      .then((data) => {
        if(data && data.status == 200){
          this.props.dispatch(
            globalMessageActions.showMessage({
              type: 'success',
              message: 'Email removed with success',
              timeout: 20000
            }));

          this.closeConfirmationPopup();
          this.resetFilter(e);
        }
      })
      .catch( (err) => {
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: err.message,
            timeout: 10000
          }));
        this.closeConfirmationPopup();
      });
  }
  
  actionTemplate(rowData) {
    return <div>
      <Button type="button" icon="fa-trash" className="ui-button-warning" onClick={(e) => {this.showConfirmationDialog(e, rowData);}}> </Button>
    </div>;
  }
  
  showDialog = () => {
    this.setState({ 
      dialogVisible: true
    });
  };

  showConfirmationDialog = (e, rowData) => {
    this.setState({       
      dialogConfirmationVisible: true,
      selectedBlacklistId: rowData._id
    });
  };

  closePopup = () => {
    this.setState({ 
      dialogVisible: false,
      newEmail: '',
      newReason: '',
      newExpiryDate: '',

      errors: {}
    });
  }

  requiredFieldErrorControl(errors, specivalue){
    return ( !_.isNil(errors) && (!_.isNil(specivalue) && specivalue == true) ) ? {width: '100%',border: '1px solid red' } : {width: '100%'};
  }

  closeConfirmationPopup = () => {
    var reset = {
      dialogConfirmationVisible: false,
      selectedBlacklistId: ''
    };

    this.setState(reset);
  }
  
  refreshCache() {
      
    userBlacklistService.refreshCache({})
      .then((data) => {

        if(data && (data.status == 200 || data.status == 201)){
        
          this.props.dispatch(
            globalMessageActions.showMessage({
              type: 'success',
              message: 'Blacklist cache successfully refreshed',
              timeout: 20000
            }));            
        }
      })
      .catch( (err) => {
        let message = err.message;
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'error',
            message: message,
            timeout: 10000
          }));
      }); 
      
  } 

  dialogConfirmationFooter = () => {
    return <div style={{display:'inline-block', width:'100%'}} className="ui-dialog-buttonpanel p-clearfix">
      <Button label="Delete" onClick={(e) => this.onDeleteBlacklist(e, this.state.selectedBlacklistId) }/>
      <Button label="Cancel" onClick={this.closeConfirmationPopup}/>
    </div>;    
  };

  dialogFooter = () => {
    return <Button label="Create" icon="fa fa-check" onClick={(e) => this.onAddBlacklistEmail(e)} />;
  };

  renderConfirmationDialog = () => {
    var headerName = `Confirmation`;
    
    return (

      <Dialog
        header={headerName}
        footer={this.dialogConfirmationFooter()}
        visible={this.state.dialogConfirmationVisible}
        width="550px"
        modal={true}
        resizable={true}
        responsive={true}       
        onHide={this.closeConfirmationPopup}
      >
        <div className="content-section implementation">
          <span>Are you sure you want to delete this email?</span>
        </div>

      </Dialog>
    );
  };

  renderDialog = () => {
    var headerName = `User email blacklist`;
    const {errors} = this.state;

    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };
    
    return (

      <Dialog
        header={headerName}
        footer={this.dialogFooter()}
        visible={this.state.dialogVisible}
        width="550px"
        modal={true}
        resizable={true}
        responsive={true}       
        onHide={this.closePopup}
      >
        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-5 ui-md-4">
              <span>Email:</span><span className="u-color-pink">&nbsp;*</span>
            </div>
            <div className="ui-g-10 ui-md-8">
              <InputText id='emailInput' 
                style={ this.requiredFieldErrorControl(errors, errors.email) }
                onChange={e => this.setState({ newEmail: e.target.value })} 
                value={this.state.newEmail}
              />
                
            </div>

          </div>
        </div>

        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-5 ui-md-4">
              <span>Reason:</span><span className="u-color-pink">&nbsp;*</span>
            </div>
            <div className="ui-g-10 ui-md-8">
              <InputText id='reasonInput' 
                style={ this.requiredFieldErrorControl(errors, errors.reason) }
                onChange={e => this.setState({ newReason: e.target.value })} 
                value={this.state.newReason}/>
            </div>
          </div>
        </div>

        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-5 ui-md-4">
              <span>Expiry Date:</span>
            </div>
            <div className="ui-g-10 ui-md-8">
              <FormElement
                name='newExpiryDate'
                type='calendar'
                onChange={(e) => this.setState({newExpiryDate: e.value})}
                value={this.state.newExpiryDate}
                id='newExpiryDate'
                style={calendarStyle}
              />
            </div>
          </div>
        </div>

      </Dialog>
    );
  };

  render() {
  
    return (
      <div>
        <div className="content-section implementation">
  
          <h3>User Blacklist</h3>

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField}/>
              </span>
            </div>
          </div>

          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Add email" onClick={this.showDialog} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Refresh Cache" onClick={this.refreshCache} />
              </div>
            </div>
          </div>
  
          <DataTable 
            value={this.state.userBlacklistList} 
            responsive={true} 
            sortMode="multiple" 
            paginator={true} 
            rows={10} 
            rowsPerPageOptions={[5,10,20,100,500,1000]} 
            totalRecords={this.state.totalRecords}
            lazy={true} onLazyLoad={this.onLazyLoad}
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter} 
            first = {this.state.first}>
            <Column field="createdAt" header="Created" sortable={true} style={{width:'10%'}} />
            <Column field="expiryAt" header="Expiry Date" sortable={true} style={{width:'10%'}} />
            <Column field="email" header="Email" sortable={true} style={{width:'15%'}} />
            <Column field="reason" header="Reason" sortable={true} style={{width:'60%'}} />
            <Column body={this.actionTemplate} style={{textAlign:'center', width: '5%'}}/>
          </DataTable>

          {this.renderDialog()}
          {this.renderConfirmationDialog()}
          
        </div>
      </div>
    );
  }
}

export default connect(() => {
  return {};
})(UserBlacklistList);
