import request from '../wrappers/axios';
import config from '../../config/config';

class UserManagementService {

  getUserManagementList(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/userManagement/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getUserManagementById(id) {
    return request({
      url: `${config.API.BASEURL}/userManagement/${id}`,
      method: 'get'
    });
  }
  getResponsibles() {
    return request({
      url: `${config.API.BASEURL}/userManagement/responsibles`,
      method: 'get'
    });
  }

  updateUserManagement(data){
    return request({
      url: `${config.API.BASEURL}/userManagement`,
      method: 'patch',
      data: data
    }, true, 500);
  }

  createUserManagement(data){
    return request({
      url: `${config.API.BASEURL}/userManagement`,
      method: 'post',
      data: data
    }, true, 500);
  }
}

export default new UserManagementService();