import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as codeBlockActions from '../../../../actions/admin/codeBlockActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';
import {Message} from 'primereact/components/message/Message';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

import config from '../../../../config/config';
import PreviewHtml from './codeBlockPreview';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    codeBlock: store.adminCodeBlock.codeBlock,
    codeBlockEdit: store.adminCodeBlock.codeBlockEdit,
  };
})
class CodeBlockEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      codeBlockDetails = {
        _id: '',
        displayBlockId:'',
        active:'',
        name:'',
        htmlBody: '',
        css: '',
        scss: '',
        javascript: '',
        cssExternal: '',
        javascriptExternal: '',
        codeBlockPreviewHtml: '',
        archived: false,
      }
    } = props;

    this.state = {
      cssPreProsessor: 'SCSS',
      sassError:{},
      codeBlockDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };
  }

  preProcessCountDownStarted = false;
  preProcessCount = 2;

  onChange(name, {target}) {
    const { codeBlockDetails } = this.state;
    _.set(codeBlockDetails, name, target.value);
    this.setState({ codeBlockDetails });
  }

  onProssessorChange(name, {target}) {
    this.setState({ cssPreProsessor: target.value });
  }

  preProcessCountdown(){
    var count = this.preProcessCount;
    const { codeBlockDetails } = this.state;
    let self = this;

    let httpTimer = setTimeout(function() {
      if(count > 0 ){
        self.preProcessCount = count - 1;
        clearTimeout(httpTimer);
        return self.preProcessCountdown();

      }else{
        self.props.dispatch(codeBlockActions.convertScss({
          sass:codeBlockDetails.scss
        })).then(({css,error})=>{
          const { codeBlockDetails } = self.state;
          self.preProcessCountDownStarted = false;
          if(error){
            return self.setState({ sassError:error});
          }
          _.set(codeBlockDetails, 'css', css);
          return self.setState({ sassError:{}, codeBlockDetails });

        }).catch(()=>{
          self.props.dispatch(globalMessageActions.showMessage({
            type: 'error',
            message: 'Error: Unable to update CSS SASS_API Network error',
            timeout: 10000
          }));
          return self.preProcessCountDownStarted = false;
        });
      }
    }, 100 );
  }

  onScssChange(name, {target}) {
    const { codeBlockDetails} = this.state;
    let preProcessCountDownStarted = this.preProcessCountDownStarted;
    _.set(codeBlockDetails, name, target.value);
    this.preProcessCount = 2;
    this.setState({ codeBlockDetails });
    if(!preProcessCountDownStarted){
      this.preProcessCountDownStarted = true;
      this.preProcessCountdown();
    }
  }
  onActiveChange(name, {target}) {
    const {codeBlockDetails} = this.state;
    if(target.value === 'true'){
      _.set(codeBlockDetails, 'archived', false);
    }
    _.set(codeBlockDetails, name, target.value);
    this.setState({codeBlockDetails});
  }

  onChangeCheckbox(name, {target}) {
    const {codeBlockDetails} = this.state;
    _.set(codeBlockDetails, name, target.checked);
    this.setState({codeBlockDetails});
  }

  componentWillMount() {
    var codeBlockId = this.props.params.codeBlockId;
    if(codeBlockId){
      this.props.dispatch(codeBlockActions.getCodeBlock(codeBlockId));
    }
  }

  componentDidMount () {
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New code block added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.codeBlock) {
      this.setState((prevState) => {
        return {
          codeBlockDetails : nextProps.codeBlock,
          codeBlockPreviewHtml: `${config.API.BASEURL}/codeBlock/previewHtml/` + nextProps.codeBlock._id
        };
      });
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.codeBlockEdit !=nextProps.codeBlockEdit){
      if(_.get(nextProps,'codeBlockEdit.name')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Code Block saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'codeBlockEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.codeBlockObject.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { codeBlockDetails, errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){ this.props.dispatch(codeBlockActions.updateCodeBlock(codeBlockDetails)); }

  }

  validateForm() {
    const { codeBlockDetails, errors } = this.state;

    errors[ 'displayBlockId' ] = validator.isEmpty(codeBlockDetails.displayBlockId);
    errors[ 'active' ] = validator.isEmpty(codeBlockDetails.active.toString());
    errors[ 'name' ] = validator.isEmpty(codeBlockDetails.name);

    return this.setState({ errors });
  }

  render (){
    const { codeBlockDetails = { info: {} }, errors, displayErrorMessage, sassError, cssPreProsessor  } = this.state;

    const {
      displayBlockId,
      active,
      name,
      htmlBody,
      css,
      scss,
      javascript,
      cssExternal,
      javascriptExternal,
      archived
    } = codeBlockDetails;

    return (
      <div className="codeBlock-new-codeBlock">
        <h3>Edit placement content</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Display Block Id'
            name='displayBlockId'
            type='text'
            onChange={this.onChange.bind(this, 'displayBlockId')}
            value={displayBlockId}
            error={errors.displayBlockId}
            id='form-displayBlockId'
            disabled='true'
          />

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onActiveChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Archived'
            name='archived'
            type='checkbox'
            onChange={this.onChangeCheckbox.bind(this, 'archived') }
            disabled={(active == 'true' || active === true)}
            checked={(!!(archived))}
            error={errors.archived}
            id='form-active'
          />

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Html body'
            name='htmlBody'
            type='codearea'
            mode='html'
            onChange={this.onChange.bind(this, 'htmlBody')}
            value={htmlBody}
            id='form-htmlBody'
          />

          <FormElement
            label='CSS Preprosessor'
            name='cssPreProsessor'
            type='select'
            onChange={this.onProssessorChange.bind(this, 'cssPreProsessor')}
            value={cssPreProsessor}
            error={errors.active}
            id='form-active'
            required
          >
            <option value="SCSS">scss</option>
            <option value="CSS">css</option>
          </FormElement>

          { cssPreProsessor == 'SCSS' &&
          <div>
            {
              sassError.line && <Message severity="error" text={'error: '+sassError.message+' line:'+sassError.line}></Message>
            }
            <FormElement
              label='SCSS body'
              name='scss'
              mode='sass'
              type='codearea'
              onChange={this.onScssChange.bind(this, 'scss')}
              value={scss}
              sassError={sassError}
            />
          </div>
          }

          { cssPreProsessor == 'CSS' &&

          <FormElement
            label='CSS body'
            name='css'
            mode='css'
            type='codearea'
            disabled
            value={css}
          />
          }

          <FormElement
            label='Javascript body'
            name='javascript'
            mode='javascript'
            type='codearea'
            onChange={this.onChange.bind(this, 'javascript')}
            value={javascript}
          />

          <FormElement
            label='head/meta body'
            name='cssExternal'
            className='head-meta'
            mode='html'
            type='codearea'
            onChange={this.onChange.bind(this, 'cssExternal')}
            value={cssExternal}
          />

          <FormElement
            label='Javascript External'
            name='javascriptExternal'
            type='text'
            onChange={this.onChange.bind(this, 'javascriptExternal')}
            value={javascriptExternal}
            error={errors.javascriptExternal}
            id='form-javascriptExternal'
          />

        </form>

        <br />
        <button onClick={()=>this.onSubmit()}>Save changes to code block</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/codeBlock')}>
          Back
        </button>

        <br />
        <PreviewHtml
          displayBlockId = {displayBlockId}
          campaignId={this.state.campaignId}
          cssExternal = {codeBlockDetails.cssExternal}
          htmlBody = {codeBlockDetails.htmlBody }
          css = {codeBlockDetails.css }
          jsExternal = {codeBlockDetails.jsExternal }
          javascript = {codeBlockDetails.javascript } >
        </PreviewHtml>
        <br />
        <br />
      </div>
    );
  }
}

export default CodeBlockEdit;