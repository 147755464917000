import React from 'react';
import { Slider } from 'primereact/components/slider/Slider';
import { TabView, TabPanel } from 'primereact/components/tabview/TabView';
import CSVReader from 'react-csv-reader';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Panel } from 'primereact/components/panel/Panel';
import FormElement from '../../../shared/form/FormElement';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import CampaignDropbox from '../../../shared/dropbox/CampaignDropbox';
import CampaignVersionDropbox from '../../../shared/dropbox/CampaignVersionDropbox';
import rewardService from '../../../../utils/services/reward';
import rewardDiscountCodeService from '../../../../utils/services/rewardDiscountCode';
import { Message } from 'primereact/components/message/Message';
import { isValidDate } from '../../../../utils/helpers/date';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

@connect((store) => ({}))
class RewardDiscountCodeImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: null,
      campaignId: null,
      campaignVersionId: null,
      rewardId: null,
      rewardList: [],
      csvCodes: [],
      textCodes: '',
      activeIndex: 0,
      isValidCsvDates: true,
      validationMessage: '',
      active: true
    };
  }

  onClientChange(e) {
    this.setState({
      clientId: e.value,
      campaignId: null,
      campaignVersionId: null,
      rewardId: null
    });
  }

  onCampaignChange(e) {
    this.setState({
      campaignId: e.value,
      campaignVersionId: null,
      rewardId: null
    });
  }

  onCodesChange(e) {
    this.setState({ textCodes: e.target.value });
  }

  onUpload = data => {
    const keys = data[0];

    if (!keys) {
      this.setState({ validationMessage: 'No header found' });

      return;
    }

    if(keys.length != 3 
        || keys[0] != 'code' 
        || keys[1] != 'from' 
        || keys[2] != 'to'){
      
      this.setState({ validationMessage: `The header must follow the pattern: "code;from;to"` });

      return;
    }

    const values = data.slice(1, data.length);
    const csvCodes = values.map(array => {
      const object = {};

      keys.forEach((key, i) => object[key] = array[i]);

      return object;
    });

    this.setState({ csvCodes, validationMessage : '' });
  };

  onCampaignVersionChange(e) {
    const campaignVersionId = e.value;

    rewardService.getRewardsByCampaignVersion(campaignVersionId)
      .then(res => {
        const rewardList = res.data;
        this.setState({
          campaignVersionId,
          rewardList,
          rewardId: null
        });
      });
  }

  onRewardChange(e) {
    this.setState({ rewardId: e.value });

    let reward = _.find(this.state.rewardList, (a) => a.rewardId == e.value);
    
    if(reward && reward.rewardMeta && reward.rewardMeta.selfManaged){
      this.props.dispatch(
        globalMessageActions.showMessage({
          type: 'error',
          message: 'You are trying to upload codes to a self managed reward!! Do not proceed!',
          timeout: 10000
        })
      );
    }
  }

  onSliderChange(e) {
    this.setState({ valueAmount: e.value });
  }

  onDateChange(e, name) {
    this.setState({ [name]: e.value });
  }

  onActiveChange(e) {
    this.setState({ active: e.target.value });
  }

  import() {
    const { activeIndex, textCodes, csvCodes, active, validationMessage, valueAmount } = this.state;
        
    let codes = [];

    if (activeIndex === 0) {

      let lines = [];

      lines = textCodes.split('\n').filter(Boolean);

      for (let index = 0; index < lines.length; index++) {
      
        let line = lines[index];
  
        let items = line.split(';');
  
        if(items.length == 0){
  
          this.setState({ validationMessage: `The line ${index + 1} has no data.` });
  
          return;
        }
  
        let code = { 
          code: items[0], 
          from: (items[1] ? moment(items[1]) : (this.state.from ? moment(this.state.from).format('YYYY-MM-DD') : null)),
          to: (items[2] ? moment(items[2]) : (this.state.to ? moment(this.state.to).format('YYYY-MM-DD') : null))
        };     
  
        codes.push(code);
      }

    } else {

      csvCodes.forEach(code => {
        code.from = (code.from ? moment(code.from) : (this.state.from ? moment(this.state.from).format('YYYY-MM-DD') : null));
        code.to = (code.to ? moment(code.to) : (this.state.to ? moment(this.state.to).format('YYYY-MM-DD') : null));
      });
      
      codes = csvCodes;
    }    

    // validate codes
    for (let index = 0; index < codes.length; index++) {

      let code = codes[index];

      if(!code.from){

        this.setState({ validationMessage: `The line ${index + 1} has no "from" date. You must set a fallback "from" date if you want to use a default date` });
        
        return;
      }

      if(!isValidDate(code.from)){
        
        this.setState({ validationMessage: `The line ${index + 1} has a invalid "from" date. :"${code.from}"` });
        
        return;
      }

      if(code.to && !isValidDate(code.to)){

        this.setState({ validationMessage: `The line ${index + 1} has a invalid "to" date.` });
        
        return;
      }

      if(code.to && moment(code.from).diff((code.to), 'day') > 0){

        this.setState({ validationMessage: `The line ${index + 1} has a "from" date bigger than "to" date.` });
        
        return;
      }
    }

    const payload = { valueAmount, active, codes };

    rewardDiscountCodeService.batchInsertRewardDiscountCode(this.state.rewardId, payload)
      .then(res => {
        const message = res.status === 201 ? 'Discount Codes imported with success!' : 'Something went wrong while importing...';

        this.props.dispatch(globalMessageActions.showMessage({
          type: res.status === 201 ? 'success' : 'error',
          message: message,
          timeout: 20000
        }));

        if (res.status === 201) {
          this.setState({ csvCodes: [], textCodes: '', validationMessage: '' });
        }
      })
      .catch((error) => {
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: error.data ? error.data : 'An unknown error happened during the import.',
          timeout: 20000
        }));
      });
  }

  render() {
    const {
      clientId,
      campaignId,
      campaignVersionId,
      valueAmount,
      rewardId,
      rewardList,
      from,
      to,
      csvCodes,
      textCodes,
      activeIndex,
      isValidCsvDates,
      validationMessage,
      active
    } = this.state;

    const calendarStyle = { marginLeft: '5px', width: '300px' };

    return (
      <div className="ui-g">

        <div className="ui-g-6">
          <h3>Reward Discount Codes Import</h3>

          <form className="c-form--flex">
            <ClientsDropbox
              label="Client"
              required='true'
              onChange={e => this.onClientChange(e)}
            />

            <CampaignDropbox
              label="Campaign"
              required='true'
              client={clientId}
              disabled={!clientId}
              onChange={e => this.onCampaignChange(e)}
            />

            <CampaignVersionDropbox
              label="Campaign Version"
              required
              campaign={campaignId}
              disabled={!campaignId}
              onChange={e => this.onCampaignVersionChange(e)}
            />

            <FormElement
              label='Reward'
              type='dropdown'
              id='form-reward'
              style={{ width: '300px', marginLeft: '5px' }}
              name='reward'
              value={rewardId}
              disabled={!campaignVersionId}
              options={rewardList && rewardList.map(reward => ({ label: reward.rewardName, value: reward.rewardId }))}
              onChange={e => { this.onRewardChange(e); }}
              error={null}
              placeholder="Select a Reward"
              required
            />

            <div className="c-form-element" disabled={!rewardId}>
              <label style={{ marginTop: '10px', marginBottom: '5px' }}>Value Amount<span className={'u-color-pink'}>&nbsp;*</span>: {valueAmount}%</label>

              <Slider
                style={{ width: '295px', marginLeft: '10px' }}
                value={valueAmount}
                onChange={e => this.onSliderChange(e)}
              />
            </div>

            <Panel header="Fallback Dates" style={{ width: 600, marginTop: '20px' }}>

              <FormElement
                label='From'
                type='calendar'
                onChange={e => this.onDateChange(e, 'from')}
                value={from}
                style={calendarStyle}
              />

              <FormElement
                label='To'
                type='calendar'
                onChange={e => this.onDateChange(e, 'to')}
                value={to}
                style={calendarStyle}
              />

            </Panel>

            <FormElement
              label='Active'
              name='active'
              type='select'
              onChange={e => this.onActiveChange(e)}
              value={active}
            >
              <option value="true">true</option>
              <option value="false">false</option>
            </FormElement>

          </form>
          <br />
          <br />

          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
            
            <TabPanel header="Manual" contentStyle={{ minHeight: '400px', border: '1px solid lightgray' }}>
              { validationMessage != '' && <Message severity="warn" text={validationMessage}></Message>}  
              <FormElement
                label='Codes'
                type='textArea'
                placeholder='code;from;to'
                rows={20}
                cols={80}
                onChange={e => { this.onCodesChange(e); }}
                value={textCodes}
              />
            </TabPanel>
            <TabPanel header="CSV" contentStyle={{ minHeight: '400px', border: '1px solid lightgray' }}>
              { validationMessage != '' && <Message severity="warn" text={validationMessage}></Message>}
              <CSVReader onFileLoaded={e => this.onUpload(e)} />
              <br />
              {!isValidCsvDates && <Message severity="warn" text="there are some invalid dates in the file. It is necessary to correct them or inform in the fields above."></Message>}

            </TabPanel>
          </TabView>
        </div>

        <div className="ui-g-6"></div>
        <button
          style={{ marginTop: '10px' }}
          onClick={() => this.import()}
          disabled={(!rewardId || (activeIndex === 0 && !textCodes) || (activeIndex === 1 & csvCodes.length === 0))}>
          Import
        </button>
      </div>
    );
  }

}

export default RewardDiscountCodeImport;