import request from '../wrappers/axios';
import config from '../../config/config';

class UserService {
  getCurrent () {
    return request({
      url: config.API.BASEURL + '/users/current',
      method: 'get'
    });
  }

  registerUser(data){
    return request({
      url: config.API.BASEURL + '/users',
      method: 'post',
      data: data
    });
  }
  
  getUser (userId) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}`,
      method: 'get'
    });
  }
  
  uploadImage(data){
    return request({
      url: `${config.API.BASEURL}/uploadimage`,
      method: 'post',
      data: data
    });
  }
  
  updateUser(userId,data){
    return request({
      url: `${config.API.BASEURL}/users/${userId}`,
      method: 'put',
      data: data
    });
  }
  
  getSharedUrlsAccess (userId, query , extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/sharedurls/meta${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getSharedUrlsAccessCount (userId, query) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/sharedurls/meta/count`,
      method: 'get',
      params: query
    });
  }
  
  getSharedUrlsCountClick (userId, query) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/sharedurls/counts/socialclicks`,
      method: 'get',
      params: query
    });
  }
  
  getEarningsPerSocialPlatform (userId , query) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/sharedurls/counts/socialearnings`,
      method: 'get',
      params: query
    });
  }
  
  getUserWallet (userId){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/wallets`,
      method: 'get'
    });
  }

  getUserSocialAuths (userId) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/socialauths`,
      method: 'get'
    });
  }

  deleteUserSocialAuths (userId, socialPlatform) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/socialauths`,
      method: 'delete',
      params: {p: socialPlatform}
    });
  }

  getTransactions (userId, query , extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/transactions${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getTransactionsCount (userId, query) {
    return request({
      url: `${config.API.BASEURL}/users/${userId}/transactions/count`,
      method: 'get',
      params: query
    });
  }

  getUserActivity(userId , query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/activity${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getUserActivityCount(userId , query){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/activity/count`,
      method: 'get',
      params: query
    });
  }

  forgotPassword(query){
    return request({
      url: `${config.API.BASEURL}/auth/forgot`,
      method: 'post',
      data: query
    });
  }

  resetPassword(query){
    return request({
      url: `${config.API.BASEURL}/auth/reset`,
      method: 'post',
      data: query
    });
  }

  getPaymentMethods(userId){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/bankinfos`,
      method: 'get',
    });
  }

  addPaymentMethod(userId , data){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/bankinfos`,
      method: 'post',
      data: data
    });
  }

  deletePaymentMethod(userId , paymentMethodId){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/bankinfos/${paymentMethodId}`,
      method: 'delete'
    });
  }

  withdraw(userId , data){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/withdrawals`,
      method: 'post',
      data: data
    });
  }

  getUserWalletWithBalance(userId){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/balance`,
      method: 'get'
    });
  }

  getWithdrawals(userId){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/withdrawals`,
      method: 'get'
    });
  }

  cancelWithdrawal(userId , requestId){
    return request({
      url: `${config.API.BASEURL}/users/${userId}/withdrawals/${requestId}/cancel`,
      method: 'put'
    });
  }

  getRetailers(){
    return request({
      url: `${config.API.BASEURL}/clients/listings`
    });
  }

  getUsersToRemoval (email) {
    return request({
      url: `${config.API.BASEURL}/userManagement/email/${email}`,
      method: 'get'
    }, true, 500);
  }

  removal (userId) {
    return request({
      url: `${config.API.BASEURL}/userManagement/unsubscribed`,
      method: 'put',
      data: {userId}
    });
  }
}

export default new UserService();