import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { DataView } from 'primereact/components/dataview/DataView';
import { Slider } from 'primereact/components/slider/Slider';

import * as campaignVersionActions from '../../../actions/admin/campaignVersionActions';
import * as globalMessageActions from '../../../actions/globalMessageActions';

const globalMessages = {
  Success: {
    type: 'success',
    message: 'New exposure values added',
    timeout: '20000'
  },
  Error: {
    type: 'error',
    message: 'Available exposure to use must be 0',
    timeout: '10000'
  },
  Warning: {
    type: 'warning',
    message: 'No changes made. Please check!',
    timeout: '10000'
  },
};

@connect((store) => {
  return {
    campaignVersion: store.adminCampaignVersion
  };
})
export default class CampaignVersionExposurePanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = { campaignVersions: [],
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      valueAmount: 0,
      availableToUse: 0,
      displayErrorMessage: false,
      showGlobalMessage: false
    };

    this.onChangeSlider = this.onChangeSlider.bind(this);
    this.amountFromPercentage = this.amountFromPercentage.bind(this);
    this.percentageAmount = this.percentageAmount.bind(this);
    this.rebalanceSliders = this.rebalanceSliders.bind(this);
  }

  componentDidMount() {
    this.getCampaignVersionListByCampaignId();
  }

  componentWillReceiveProps(nextProps) {
    if(_.get(nextProps,'campaignVersion.campaignVersionListByCampaignIdGet')) {
      const campaignVersionList = nextProps.campaignVersion.campaignVersionListByCampaignIdGet.slice();
      this.setState({ campaignVersions: campaignVersionList }, () => {
        this.setCurrentExposure();
      });
    }

    if(_.get(nextProps,'campaignVersion.campaignVersionListEdit')) {
      if(nextProps.campaignVersion.campaignVersionListEdit.page.length > 0) {
        if(this.state.showGlobalMessage) {
          this.showGlobalMessage(globalMessages.Success);
          this.setState({ showGlobalMessage: false });
        }
      }
    }
  }

  getCampaignVersionListByCampaignId() {
    this.props.dispatch(campaignVersionActions.getCampaignVersionListByCampaignId(this.props.campaignId));
  }

  setCurrentExposure() {
    const campaignVersionsLocal = this.state.campaignVersions.slice();

    for(var index in campaignVersionsLocal){
      if(!campaignVersionsLocal[index].exposure){
        campaignVersionsLocal[index].exposure = 0;
        campaignVersionsLocal[index].changed = false;
      }
    }

    for(var index2 in campaignVersionsLocal){
      campaignVersionsLocal[index2].exposurePercentage = +this.percentageAmount(
        campaignVersionsLocal[index2]._id,campaignVersionsLocal) + 1;
    }
    this.setState({ campaignVersions : campaignVersionsLocal });
  }

  onChangeSlider(e, campaignVersionId) {

    let totalPercentageUsed = 0;
    let campaignVersionsLocal = this.state.campaignVersions.slice();
    let value = +e.value - 1;

    for(let index in campaignVersionsLocal){
      if(campaignVersionId != campaignVersionsLocal[index]._id)
        totalPercentageUsed += campaignVersionsLocal[index].exposurePercentage - 1;
    }

    let index = _.findIndex(this.state.campaignVersions, function(version) {
      return version._id == campaignVersionId;
    });

    if(value != 1 && value <= 100 - totalPercentageUsed){
      campaignVersionsLocal[index].exposurePercentage = +value + 1;
      campaignVersionsLocal[index].changed = true;
      this.setState({ campaignVersions : campaignVersionsLocal });
      let notUsed = 100 - totalPercentageUsed - value;
      this.setState({ availableToUse: notUsed });
    } else {
      return;
    }
  }

  rebalanceSliders(){
    this.setState({ displayErrorMessage: false });

    if(this.state.availableToUse > 0){
      console.log('Available needs to be 0 to save changes.');
      this.showExposureGreaterThanZeroError();
    } else {
      let campaignVersionsLocal = this.state.campaignVersions.slice();

      let exposureChangeMade = false;
      campaignVersionsLocal.forEach(campaign => {
        if (_.get(campaign,'changed')) {
          exposureChangeMade = true;
        }
      });

      if (!exposureChangeMade) {
        this.showGlobalMessage(globalMessages.Warning);
      } else {
        let originalTotal = 0;
        for(let index in campaignVersionsLocal){
          originalTotal += +campaignVersionsLocal[index].exposure;
        }

        if(originalTotal == 0){
          originalTotal = 100;
        }

        for(let index in campaignVersionsLocal){
          if(campaignVersionsLocal[index].changed){
            campaignVersionsLocal[index].exposure = +this.amountFromPercentage(
              originalTotal, campaignVersionsLocal[index].exposurePercentage - 1, campaignVersionsLocal[index].exposure);
            campaignVersionsLocal[index].changed = false;
          }
        }
        this.updateCampaignVersionExposure();
      }
    }
  }

  updateCampaignVersionExposure() {
    this.setState({ showGlobalMessage: true });
    const campaignVersionList = this.state.campaignVersions.slice();
    this.props.dispatch(campaignVersionActions.updateCampaignVersionList(campaignVersionList));
  }

  showExposureGreaterThanZeroError() {
    this.showGlobalMessage(globalMessages.Error);
  }

  showGlobalMessage(msgType) {
    this.props.dispatch(globalMessageActions.showMessage({
      type: msgType.type,
      message: msgType.message,
      timeout: msgType.timeout
    }));
  }

  percentageAmount(id,campaignVersions){
    let totalAmount = +0;
    let actualExposure = 0;

    for(let index in campaignVersions){
      totalAmount += +campaignVersions[index].exposure;
      if(campaignVersions[index]._id == id){
        actualExposure = campaignVersions[index].exposure;
      }
    }
    let result = 100*actualExposure/totalAmount;
    return result.toFixed(0);
  }

  amountFromPercentage(originalTotal, actualPercentage, originalExposure){
    let result = originalExposure;

    if(originalTotal > 0){
      result = originalTotal*actualPercentage/(100);
    }
    return result.toFixed(0);
  }

  itemTemplate(campaignVersion,layout) {

    if(!campaignVersion) {
      return;
    }

    let src = 'https://www.primefaces.org/primereact/showcase/resources/demo/images/car/BMW.png';
    if(!_.isEmpty(this.state.campaignVersions))
    {
      let index = _.findIndex(this.state.campaignVersions, function(version) {
        return version._id == campaignVersion._id;
      });
      if(layout === 'list') {
        return (
          <div className="ui-g" style={{padding: '2em', borderBottom: '1px solid #d9d9d9'}}>
            <div className="ui-g-12 ui-md-3">
              <img style={{ width: '100px' }} src={campaignVersion.previewDesktopThumbnailUrl ? campaignVersion.previewDesktopThumbnailUrl : '/images/logo.png' } alt={campaignVersion.name}/>
            </div>
            <div className="ui-g-12 ui-md-3 search-icon" style={{marginTop:'40px'}}>
              <div className="ui-g-2 ui-sm-6">Exposure: </div>
              <div className="ui-g-10 ui-sm-6">
                <Slider min={1} max={101} animate='true' id={'slider_'+this.state.campaignVersions[index]}
                  style={{ width: '300px', marginLeft: '10px'}} value={this.state.campaignVersions[index].exposurePercentage}
                  onChange={ e => this.onChangeSlider(e,this.state.campaignVersions[index]._id)} />
              </div> <label style={{marginLeft: '150px'}}>{this.state.campaignVersions[index].exposurePercentage - 1} %</label>

            </div>
          </div>
        );
      }
      if(layout === 'grid') {
        return (
          <div style={{ padding: '.5em' }} className="ui-g-12 ui-md-3">
            <Panel header='XX' style={{ textAlign: 'center' }}>
              <img src={src} alt='xx' />
              <div className="car-detail">xx</div>
              <hr className="ui-widget-content" style={{ borderTop: 0 }} />
              <i className="fa fa-search" style={{ cursor: 'pointer' }}></i>
            </Panel>
          </div>
        );
      }
    }
    else {
      return '<div></div>';
    }
  }

  render() {
    const { displayErrorMessage } = this.state;

    return (
      <div className={"content"}>
        <h3>Campaign A/B Test Panel</h3>
        <button onClick={()=>this.rebalanceSliders()}>Save</button>
        <label className={displayErrorMessage ? "u-color-pink" : ""}
          style={{marginLeft: '50px'}}>Available to use: {this.state.availableToUse} %</label>
        <DataView value={this.state.campaignVersions} itemTemplate={this.itemTemplate.bind(this)}
          style={{marginTop: '20px'}}>
        </DataView>
      </div>
    );
  }
}
