import constants from '../../constants/app-constants';

const initialState = {
  modalIsOpen: false
}

const ClientProfile = (state = initialState , action) => {
  switch(action.type){

  case constants.client.PROFILE_TOGGLE_MODAL : {
    return {...state, modalIsOpen: action.payload };
  }

  default:
    return state;
  }
};

export default ClientProfile;