import React from 'react';
import clientService from '../../../../utils/services/client';
import imageUploadHelper from '../../../../utils/helpers/imageUpload';
import {connect} from 'react-redux';
import * as profileActions from '../../../../actions/client/profileActions';
import * as clientActions from '../../../../actions/client/clientActions';
import config from '../../../../config/config';

@connect((store)=>{
  return {
    modalIsOpen: store.clientProfile.modalIsOpen,
    clientObj: store.client.clientObj
  }
})
class UpdateLogo extends React.Component{
  
  constructor(){
    super();
    this.state = {
      previewUrl : false,
      uploadError: null
    };
  }
  
  imagePreview() {
    this.setState({previewUrl: window.URL.createObjectURL(document.getElementById('file').files[0])});
    document.getElementById('file-preview').value = document.getElementById('file').files[0].name;
  }

  clearPreview () {
    this.setState({previewUrl: ''});
    document.getElementById('file-preview').value = '';
  }
  
  handleChange(){
    var that = this;
    var fileInput = document.getElementById('file');

    that.clearPreview ();
    that.setState({
      uploadError: null
    });

    //check image is valid size
    if(!imageUploadHelper.imageSizeIsValid(document.getElementById('file').files[0].size,config.IMG.UPLOAD_LIMIT)){
      that.setState({
        uploadError: 'Maximum upload limit is 2MB'
      });
      document.getElementById('file').value = '';
      return;
    }

    //check image is square
    if (fileInput.files[0]) {
      var _URL = window.URL || window.webkitURL;
      var image = new Image();
      image.onload = function() {
        if ((this.width / this.height) !== 1 || this.width < 150) {
          fileInput.val = '';
          that.setState({
            uploadError: 'Image must be square and have dimensions of at least 150x150.'
          });
          document.getElementById('file').value = '';
          that.clearPreview();
          return;
        }
      };
      image.src = _URL.createObjectURL(fileInput.files[0]);
    }

    //check image is valid format and then load preview
    if(imageUploadHelper.imageIsValid(fileInput)){
      this.imagePreview();
    }else{
      document.getElementById('file').value = '';
      that.setState({
        uploadError: 'Unsupported image format'
      });
      return;
    }
  }
  
  triggerFileOpen(){
    document.getElementById('file').click();
  }
  
  handleSubmit(e) {
    e.preventDefault();

    var that = this;
    var fileInput = document.getElementById('file');

    if(!fileInput.files.length){
      that.setState({
        uploadError: 'Please upload an image first'
      });
      that.clearPreview();
      document.getElementById('file').value = '';
      return;
    }

    if(imageUploadHelper.imageIsValid(fileInput)){
      var data = new FormData();
      data.append('file', document.getElementById('file').files[0]);

      clientService.uploadImage(data)
        .then(function(res){
          clientService.updateClient(that.props.clientObj._id,{ imageId: res.data.fileId})
            .then(function(res){
              that.props.dispatch(clientActions.getClient(res.data._id));
              that.props.dispatch(profileActions.toggleProfileModal());
            })
            .catch(function(err){
              that.setState({
                uploadError: err.response.data.message
              });
            });
        })
        .catch(function(err){
          that.setState({
            uploadError: err.response.data.message
          });
        });
    }else{
      alert('unsupported format');
    }
  }
  
  closeModal(e) {
    e.preventDefault();
    this.props.dispatch(profileActions.toggleProfileModal());
  }

  render(){
    return (
      <div id="image-upload-preview">
        <span  onClick={(e) => this.closeModal(e)} className="close-modal">
          <i className="fa fa-times"></i>
        </span>
        <div className="grid-100">
          <h3 className="text-center">Update Logo</h3>
        </div>
        <div className="errors text-center">
          { this.state.uploadError }
        </div>
        <div className="grid-100">
          <p className="preview-text">Preview</p>
          <div className="profile-pic-holder">
            { this.state.previewUrl ? <img id="profile-pic" className="profile-pic" src={this.state.previewUrl} alt=""/> : null }
          </div>
        </div>
        <div className="grid-100">
          <form id="upload-form" className="form" onSubmit={(event) => {this.handleSubmit(event);}} accept="image/png, image/jpeg , image/gif ,image/jpg ">
            <div className="file-controller-holder">
              <label className="file-controller" onChange={(event) => {this.handleChange(event);}} >
                <input id="file" type="file" className=""/>
                <input id="file-preview" type="text" disabled="true" onClick={(event) => {this.triggerFileOpen(event);}}/>
                <span><i className="fa fa-cloud-upload"></i></span>
              </label>
            </div>
            <div className="text-center upload-bottom">
              <p>Image must be square, Max 2mb</p>
              <button id="upload" type="submit" className="primary">Save</button><br />
              <button  onClick={(event) => {this.closeModal(event);}} className="secondary-v2">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default UpdateLogo;