import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as displayBlockActions from '../../../../actions/admin/displayBlockActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    displayBlock: store.adminDisplayBlock.displayBlock,
    displayBlockEdit: store.adminDisplayBlock.displayBlockEdit,
  };
})
class DisplayBlockEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      displayBlockDetails = {
        campaignId:'',
        active:'',
        name:'',
        type:'',
        archived: false,
      }
    } = props;

    this.state = {
      displayBlockDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };
  }

  onChange(name, {target}) {
    const {displayBlockDetails} = this.state;
    _.set(displayBlockDetails, name, target.value);
    this.setState({displayBlockDetails});
  }

  onActiveChange(name, {target}) {
    const {displayBlockDetails} = this.state;
    if(target.value === 'true'){
      _.set(displayBlockDetails, 'archived', false);
    }
    _.set(displayBlockDetails, name, target.value);
    this.setState({displayBlockDetails});
  }

  onChangeCheckbox(name, {target}) {
    const {displayBlockDetails} = this.state;
    _.set(displayBlockDetails, name, target.checked);
    this.setState({displayBlockDetails});
  }

  componentWillMount() {
    var displayBlockId = this.props.params.displayBlockId;
    if(displayBlockId){
      this.props.dispatch(displayBlockActions.getDisplayBlock(displayBlockId)); 
    }
  }

  componentDidMount() {
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New display block',
        timeout: 20000
      }));
    } 
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.displayBlock){
      this.setState({ displayBlockDetails : nextProps.displayBlock});
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.displayBlockEdit !=nextProps.displayBlockEdit){
      if(_.get(nextProps,'displayBlockEdit.name')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Display Block saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'displayBlockEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.displayBlockObject.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const {displayBlockDetails,errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){ this.props.dispatch(displayBlockActions.updateDisplayBlock(displayBlockDetails)); }

    console.log(errors); //for debugging left in for internal use only as it is an admin tool.

  }

  validateForm() {
    const { displayBlockDetails, errors } = this.state;

    errors[ 'campaignVersionId' ] = validator.isEmpty(displayBlockDetails.campaignVersionId);
    //errors[ 'active' ] = validator.isEmpty( displayBlockDetails.active );
    errors[ 'name' ] = validator.isEmpty( displayBlockDetails.name );
    errors[ 'type' ] = validator.isEmpty( displayBlockDetails.type);

    return this.setState({ errors });
  }

  render (){
    const { displayBlockDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      campaignVersionId,
      active,
      name,
      type,
      archived,
    } = displayBlockDetails;

    return (
      <div className="displayBlock-new-displayBlock">
        <h3>Edit placement</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Campaign Version Id'
            name='campaignVersionId'
            type='text'
            onChange={this.onChange.bind(this, 'campaignVersionId')}
            value={campaignVersionId}
            error={errors.campaignVersionId}
            id='form-campaignVersionId'
            disabled='true'
          />

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            error={errors.type}
            id='form-type'
            required
          >
            <option value=""> </option>
            <option value="lightbox">Lightbox</option>
            <option value="interstitial">Interstitial</option>
            <option value="shareviaemail">Share via email</option>
            <option value="voucher-page">Voucher page</option>
            <option value="lightboxwrapper">Lightbox wrapper</option>
            <option value="sharestaticpage">Share static page</option>
          </FormElement>

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onActiveChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>
          <FormElement
            label='Archived'
            name='archived'
            type='checkbox'
            onChange={this.onChangeCheckbox.bind(this, 'archived') } 
            disabled={(active == 'true' || active === true)}
            checked={(!!(archived))}
            error={errors.archived}
            id='form-active'
            required
          />
          
        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Edit display block</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/displayBlock')}>
          Back
        </button>
      </div>
    );
  }
}

export default DisplayBlockEdit;