import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';
import userHelper from '../../utils/helpers/user';
import sortHelper from '../../utils/helpers/sortHelper';

export const getSortedSharedUrlsAccess =  (userId, query ,sortDirection , sortIndex) => {
  return function(dispatch){
    userService.getSharedUrlsAccess(userId, query).then((response) => {
      var sharedUrls = userHelper.getEngagementProducts(response.data);
      sharedUrls = sortHelper.sortResults(sharedUrls , sortDirection , sortIndex);

      dispatch({
        type: constants.user.ENGAGEMENT_SHAREDURLS_GET, payload: sharedUrls
      });
    });
  };
};

export const getSharedUrlsCountClick = (userId, query) => {
  return function(dispatch){
    userService.getSharedUrlsCountClick(userId, query).then((response) => {
      var clicks = response.data;
      dispatch({
        type: constants.user.SHAREDURLS_COUNTCLICKS, payload: clicks
      });
    });
  };
};

export const getSharedUrlsCountRevenue = (userId, query) => {
  return function(dispatch){
    userService.getEarningsPerSocialPlatform(userId, query).then((response) => {
      var earnings = response.data;
      dispatch({
        type: constants.user.SHAREDURLS_EARNINGSPERPLATFORM, payload: earnings
      });
    });
  };
};

export const updateSharedUrlsAccess =  (sharedUrls) => {
  return { type: constants.user.ENGAGEMENT_SHAREDURLS_GET, payload : sharedUrls };
};

export const updateEngagementPageCount = (pageCount) => {
  return {type : constants.user.ENGAGEMENT_UPDATEPAGECOUNT , payload :  pageCount};
};