import DisplayBlockList from './List';
import DisplayBlockCreate from './Create';
import DisplayBlockEdit from './Edit';

const DisplayBlockIndex = {
  DisplayBlockList, 
  DisplayBlockCreate,
  DisplayBlockEdit
};

export default DisplayBlockIndex;