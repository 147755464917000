import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import moment from 'moment';

import campaignService from '../../../../utils/services/campaign';
import FormElement from '../../../shared/form/FormElement'; 
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import * as campaignActions from '../../../../actions/admin/campaignActions';

export class CampaignList extends React.Component {

  constructor() {
    super();

    this.event = {
      filters: null,
      multiSortMeta: null,
      rows: 10,
      sortField: null,
      sortOrder: null
    };

    this.state = {
      campaignList: [],
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
      errors: {},
      newCopyCampaignId: null,
      newCopyCampaignName: 'Default',
      selectedCampaignId: '',
      selectedCampaignName: '',
      newCopyStartDate: moment().toDate(),
      newCopyExpiryDate: moment().add(365,'d').toDate(),
      newCopyActiveOnly: false,
      showArchived:false,
      first: 0
    };

  }

  export = () => {
    this.dt.exportCSV();
  }

  componentWillUpdate(nextProps) {
    if (this.props.campaignCopy != nextProps.campaignCopy) {
      if (_.get(nextProps, 'campaignCopy._id')) {
        this.closeCopyPopup();
        this.props.dispatch(
          globalMessageActions.showMessage({
            type: 'success',
            message: `Campaign ${nextProps.campaignCopy.description} copied with success`,
            timeout: 5000
          }));
        this.onLazyLoad(this.event);
      }
      else if(_.get(nextProps,'campaignCopy.data')){
        this.closeCopyPopup();
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.campaignCopy.data.message,
          timeout: 10000
        }));
      }
    }
  }

  resetFilter = (event) => {
    this.setState({
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  getQuery(event) {

    var query = {
      $offset: event.first,
      $limit: event.rows,
      archived_$eq: this.state.showArchived,
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }

    var gteDate  = this.state.startDate ? {$createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$createdAt_$lte: moment(this.state.endDate).add(1,'d').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
  
    for(let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');  
      row.expiry = moment(row.expiry).format('YYYY-MM-DD');
      row.startDate = moment(row.startDate).format('YYYY-MM-DD');
      row.active = row.active.toString();

    }

    this.setState({
      campaignList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad = (event) => {
   
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});

    campaignService.getCampaignList(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });
    
  } 

  onCreateCampaign = () => {
    this.props.router.push({ pathname: '/admin/campaign/create'});
  }

  onEditCampaign = (e,rowData) => {
    this.props.router.push({ pathname: '/admin/campaign/' + rowData._id });
  }

  onRefreshCache = () => {
    campaignService.refreshCache()
      .then(() => {
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'The cache was freshed.',
          timeout: 10000
        }));
      });
  }

  showDialog = (e, rowData) => {
    var suggestedCampaignName = 'Default - Copy';
    var oldCampaignName = '';

    if(rowData){
      suggestedCampaignName = (rowData.description) ? rowData.description+' - Copy' : suggestedCampaignName ;
      oldCampaignName = (rowData.description) ? rowData.description : oldCampaignName ;
    }
    this.setState({ dialogVisible: true, individualCampaignObj: rowData, newCopyCampaignName : suggestedCampaignName, selectedCampaignName : oldCampaignName, selectedCampaignId: rowData._id });
  };

  dialogFooter = () => {
    return <Button label="Copy" icon="fa fa-check" onClick={() => this.onCopyCampaignSubmit()} />;
  };

  onCopyCampaignSubmit = () => {

    const selectedCampaign = this.state.individualCampaignObj;

    if (selectedCampaign) {

      var copyCampaign = {};
      copyCampaign._id = this.state.selectedCampaignId;
      copyCampaign.name = this.state.newCopyCampaignName; 
      copyCampaign.activeOnly = this.state.newCopyActiveOnly; 
      copyCampaign.startDate = moment(this.state.newCopyStartDate).format('YYYY-MM-DD'); 
      copyCampaign.expiryDate = moment(this.state.newCopyExpiryDate).format('YYYY-MM-DD');

      this.props.dispatch(campaignActions.copyCampaign(copyCampaign));

    } else {
      this.props.dispatch(
        globalMessageActions.showMessage({
          type: 'warning',
          message: 'No campaign selected to copy. Please check!',
          timeout: 5000
        })
      );
    }
  }

  actionTemplate = (rowData, column) => {
    return <div>
      <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={(e) => {this.onEditCampaign(e, rowData);}}> </Button>
      <Button type="button" icon="fa-copy" className="ui-button-warning" onClick={e => this.showDialog(e, rowData)}> </Button>
    </div>;
  }

  closeCopyPopup = () => {
    var reset = {
      newCopyCampaignId: null,
      newCopyCampaignName: 'Default',
      selectedCampaignId: '',
      selectedCampaignName: '',
      newCopyStartDate: moment().toDate(),
      newCopyExpiryDate: moment().add(365,'d').toDate(),
      newCopyActiveOnly: false,
      dialogVisible: false
    };

    this.setState(reset);
  }

  renderDialog = () => {
    const campaignName = (this.state.selectedCampaignName) ? this.state.selectedCampaignName : '';
    
    var headerName = `Copy campaign ${campaignName}`;
    
    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };
    
    return (

      <Dialog
        header={headerName}
        footer={this.dialogFooter()}
        visible={this.state.dialogVisible}
        width="550px"
        modal={true}
        resizable={true}
        responsive={true}       
        onHide={this.closeCopyPopup}
      >
        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-5 ui-md-4">
              <span>Name:</span>
            </div>
            <div className="ui-g-10 ui-md-8">
              <InputText id='copyCampaignNameInput' style={{width: '100%'}} onChange={e => this.setState({ newCopyCampaignName: e.target.value })} value={this.state.newCopyCampaignName}/>
            </div>
          </div>
        </div>

        <div className="ui-g">
          <div className="ui-g-5 ui-md-4">
            <span>Copy active only:</span>
          </div>
          <div className="ui-g-10 ui-md-8">
            <span>
              <input
                name="newCopyActiveOnly"
                type="checkbox"
                checked={this.state.newCopyActiveOnly}
                onChange={e => this.setState({ newCopyActiveOnly : e.target.checked })} />
            </span>
          </div>
        </div>

        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-5 ui-md-4">
              <span>Start Date:</span>
            </div>
            <div className="ui-g-10 ui-md-8">
              <FormElement
                name='newCopyStartDate'
                type='calendar'
                onChange={(e) => this.setState({newCopyStartDate: e.value})}
                value={this.state.newCopyStartDate}
                id='newCopyStartDate'
                required
                style={calendarStyle}
              />
            </div>
          </div>
        </div>
        <div className="content-section implementation">
          <div className="ui-g">
            <div className="ui-g-5 ui-md-4">
              <span>Expiry Date:</span>
            </div>
            <div className="ui-g-10 ui-md-8">
              <FormElement
                name='newCopyExpiryDate'
                type='calendar'
                onChange={(e) => this.setState({newCopyExpiryDate: e.value})}
                value={this.state.newCopyExpiryDate}
                id='newCopyExpiryDate'
                required
                style={calendarStyle}
              />
            </div>
          </div>
        </div>



      </Dialog>
    );
  };

  render() {
    var header = <div style={{textAlign:'left'}}><Button type="button" icon="fa-file-o" iconPos="left" label="CSV" onClick={this.export}></Button></div>;

    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };

    return (
      <div>
        <div className="content-section implementation">

          <h3>Campaigns</h3>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Archived:</span>
              </div>
              <div className="ui-g-10 ui-md-2 archivedCheckbox">
                <FormElement
                  name='archived'
                  type='checkbox'
                  onChange={(e) => this.setState({showArchived: e.target.checked})} 
                  checked={this.state.showArchived}
                  id='form-active'
                />
              </div>
            </div>
            <div className="ui-g">

              <div className="ui-g-5 ui-md-2">
                <span>Date range:</span>
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateFrom'
                  type='calendar'
                  onChange={(e) => this.setState({startDate: e.value})}
                  value={this.state.startDate}
                  id='dateFrom'
                  required
                  style={calendarStyle}
                />
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateTo'
                  type='calendar'
                  onChange={(e) => this.setState({endDate: e.value})}
                  value={this.state.endDate}
                  id='dateTo'
                  required
                  style={calendarStyle}
                />
              </div>
            </div>
          </div>
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText id='searchInputText' onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField}/>
              </span>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
              <div className="ui-g-3 ui-md-2">
                <Button label="Create Campaign" onClick={this.onCreateCampaign} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Refresh Cache" onClick={this.onRefreshCache} />
              </div>
            </div>
          </div>

          <DataTable value={this.state.campaignList} responsive={true} sortMode="multiple" paginator={true} rows={10} rowsPerPageOptions={[5,10,20,100,500,1000]} totalRecords={this.state.totalRecords}
            lazy={true} onLazyLoad={this.onLazyLoad} header={header} 
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter} first = {this.state.first}>
            <Column field="countryName" hidden="true" header="Country" sortable={true} style={{width:'30px'}}/>
            <Column field="clientName" header="Client" sortable={true} style={{width:'80px'}} />
            <Column field="description" header="Campaign Name" sortable={true} style={{width:'90px'}} />
            {/* <Column field="clientId" header="Client Id" sortable={true} style={{width:'100px'}} /> */}
            <Column field="startDate" header="Start Date" sortable={true} style={{width:'50px'}} />
            <Column field="expiry" header="Expiry" sortable={true} style={{width:'50px'}} />
            <Column field="shortUrlCustomStringComponent" header="URL Custom String" sortable={true} style={{width:'50px'}} />
            <Column field="active" header="Active" sortable={true} style={{width:'50px'}} />
            <Column field="createdAt" header="Created" sortable={true} style={{width:'50px'}} />
            <Column field="updatedAt" header="Updated" sortable={true} style={{width:'50px'}} />
            <Column field="Archived" header="Archived" body={(row)=>{
              if(row){
                return (!!(row.archived)).toString();
              }
              return;
            }} style={{width:'30px'}} />
            <Column body={this.actionTemplate} style={{textAlign:'center', width: '30px'}}/>
          </DataTable>

          {this.renderDialog()}
        </div>
      </div>
    );
  }
  
}

export default connect((store) => {
  return {
    campaignCopy: store.adminCampaign.campaignCopy
  };
})(CampaignList);