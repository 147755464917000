import CampaignList from './List';
import CampaignCreate from './Create';
import CampaignEdit from './Edit';

const CampaignIndex = {
  CampaignList, 
  CampaignCreate,
  CampaignEdit
};

export default CampaignIndex;