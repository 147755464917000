import React from 'react';
import _ from 'lodash';

import FormElement from '../../../shared/form/FormElement';
import LiveFrameBeta from './liveFrameBeta';
import PreviewPopup from './PreviewPopup';
import ejs from 'ejs';
import config from '../../../../config/config';
import * as displayBlockActions from '../../../../actions/admin/displayBlockActions';

import { connect } from 'react-redux';

const _uglyPopWrap = `
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />

  <style>
    .reverb-lightbox {
      border-radius: 10px;
      background-color: white;
      overflow: hidden;
      -webkit-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.75);
      height: 100%;
    }
    .uglipop_content_fixed {
      width: 800px;
      height: 500px;
      top: 50%;
      left: 50%;
      position: fixed;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
    @media screen and (max-width: 1024px) {
      .uglipop_content_fixed {
        width: 800px;
        height: 500px;
        top: 50%;
        left: 50%;
      }
    }
    @media screen and (max-width: 850px) {
      .uglipop_content_fixed {
        width: 300px;
        height: 450px;
        top: 50%;
        left: 50%;
      }
    }
    .uglipop_content_fixed {
      z-index: 16777271;
    }
    #uglipop_overlay_wrapper {
      z-index: 16777270;
    }
    #uglipop_popbox > iframe {
      display: block;
    }
    .reverb-lightbox-iframe {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border: none;
    }
    .hide {
      display: none !important;
      height: 0px !important;
      opacity: 0 !important;
      transition: all 200ms linear !important;
    }
    div.reverb-lightbox-close {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -13px;
      margin-right: -13px;
      cursor: pointer;
      color: white;
      border-radius: 50%;
      background: #2d2d2d;
      text-align: center;
      display: inline-block;
      -webkit-box-shadow: -1px 1px 5px 0px rgba(77, 77, 77, 1);
      -moz-box-shadow: -1px 1px 5px 0px rgba(77, 77, 77, 1);
      box-shadow: -1px 1px 5px 0px rgba(77, 77, 77, 1);
    }
    div.reverb-lightbox-close span {
      font-family: sans-serif;
      -webkit-font-smoothing: auto;
      line-height: 35px;
      font-size: 20px;
      font-weight: 400;
    }
  </style>
  <style>
    .uglipop_content_fixed_mini {
      position: fixed;
      opacity: 1;
    }

    /* hide toogle button when the lightbox window is in regular size */
    div.reverb-lightbox-close > .toggle-button {
      display: none;
    }

    /* display toogle button when the lightbox window is in mini mode */
    div.reverb-lightbox-close-mini > .toggle-button {
      display: block;
    }

    /* hide regular close button content when the lightbox window is in mini mode */
    div.reverb-lightbox-close-mini > .default-close-text {
      display: none;
    }

    @media screen and (min-width: 992px) {
      /* this class defines the size and position of the mini version of the lightbox */

      .uglipop_content_fixed_mini {
        width: 480px;
        height: 145px;
        top: auto;
        bottom: 0px;
        left: auto;
        right: 0px;
      }

      /* this class defines the size and position of the close icon */

      div.reverb-lightbox-close-mini {
        display: block !important;
        top: 0px !important;
        bottom: auto !important;
        right: auto !important;
        left: 0px !important;
        margin-top: -10px !important;
        margin-bottom: auto !important;
        margin-right: auto !important;
        margin-left: -10px !important;
      }

      /* this class defines the aspects of the "border" of the lightbox */

      .reverb-lightbox-mini {
        border-radius: 10px !important;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
      /* this class defines the size and position of the mini version of the lightbox */

      .uglipop_content_fixed_mini {
        width: 100%;
        height: 100px;
        top: auto;
        bottom: 0px;
        left: auto;
        right: 0px;
      }

      /* this class defines the size and position of the close icon */

      div.reverb-lightbox-close-mini {
        display: block !important;
        top: 0px !important;
        bottom: auto !important;
        right: auto !important;
        left: 0px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-right: auto !important;
        margin-left: auto !important;
      }

      /* this class defines the aspects of the "border" of the lightbox */

      .reverb-lightbox-mini {
        border-radius: 10px !important;
      }
    }

    @media screen and (min-width: 0px) and (max-width: 768px) {
      /* this class defines the size and position of the mini version of the lightbox */

      .uglipop_content_fixed_mini {
        width: 100%;
        height: 100px;
        top: auto;
        bottom: 0px;
        left: auto;
        right: 0px;
      }

      /* this class defines the size and position of the close icon */

      div.reverb-lightbox-close-mini {
        display: block !important;
        top: 0px !important;
        bottom: auto !important;
        right: auto !important;
        left: 0px !important;
        margin-top: -10px !important;
        margin-bottom: auto !important;
        margin-right: auto !important;
        margin-left: 0px !important;
      }

      /* this class defines the aspects of the "border" of the lightbox */

      .reverb-lightbox-mini {
        border-radius: 10px !important;
      }
    }
  </style>
</head>

<body
  data-new-gr-c-s-check-loaded="14.1038.0"
  data-gr-ext-installed=""
  class="vsc-initialized"
  cz-shortcut-listen="true"
  style="background-color: white;">
  <div>
    <h1>Test</h1>
  </div>
  
  <div
    id="uglipop_overlay_wrapper"
    style="position:absolute;top:0;bottom:0;left:0;right:0;"
    class=""
  >
    <div
      id="uglipop_overlay"
      style="position:fixed;top:0;bottom:0;left:0;right:0;width:100%;height:100%;opacity:0.3;background-color:black;"
      class=""
    ></div>
  </div>
  <div id="uglipop_content_fixed" class="uglipop_content_fixed">
    <div id="closeButton" class=" reverb-lightbox-close" onClick="toggleLightboxState()">
      <span class="default-close-text">-</span>
      <div class="toggle-button">
        <img
          src="https://s3-eu-west-1.amazonaws.com/dist.soreto.com/clientsrc/assets/X_SORETO.svg"
          alt="x"
        />
      </div>
    </div>
    <div id="uglipop_popbox" class="reverb-lightbox reverb-lightbox-mini">
      
    <iframe class="reverb-lightbox-iframe"
        srcdoc="<%=lightboxContent %>"></iframe>
    </div>
  </div>

  <script>
  
  function toggleLightboxState (eventFrom) {

    // get close button from DOM
    let close_button = document.getElementById('closeButton');

    // if an event is from the close button and the lightbox is already minimized
    // it means the lightbox must be closed
    if(eventFrom == 'closeButton'
      && close_button.classList
      && close_button.classList.contains('reverb-lightbox-close-mini')){
      remove();
      return;
    }

    // backdrop overlay
    document.getElementById('uglipop_overlay_wrapper').classList.toggle('hide');
    document.getElementById('uglipop_overlay').classList.toggle('hide');

    // iframe container
    let contentFixed = document.getElementById('uglipop_content_fixed');
    contentFixed.classList.toggle('uglipop_content_fixed');
    contentFixed.classList.toggle('uglipop_content_fixed_mini');

    // close button
    close_button.classList.toggle('reverb-lightbox-close-mini');
  }

    // IFRAME body click event
    // It must listen to the click event inside the the IFRAME document

    window.addEventListener('message', function (e) {

      if(e.data == 'toogle-lightbox'){
        // toogle lishtbox state
        toggleLightboxState();
      }

    }, false)
  
  </script>
</body>
</html>

`;

@connect((store) => {
  return {
    displayBlock: store.adminDisplayBlock.displayBlock
  };
})
class PreviewHtmlBeta extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      size:{
        height:'100%',
        width:'100%'
      },
      details: {
        layout: 'desktop',
        pageState: 'LOAD',

      },
      previewHtml : '',
      campaignDetailsLightbox: {
        'clientId':'PREVIEW',
        'sharedUrlAccessId':'PREVIEW',
        'page':'emailRequest',
        'discountDetails':{
          'discountCode':'codeNotShown',
          'discountValue':'0',
          'linkExpired':false
        },
        'urlType': 'SHARED',
        'campaignVersion':'PREVIEW',
        'campaignVersionId':'PREVIEW',
        'sharedUrlId':'PREVIEW',
        'requiresUserEmail':false,'testMode':true
      },
      campaignDetails: {
        'discountDetails': {
          'expires': {
            'days': 30,
            'hours': 8
          },
          'discountCode': 'Not-Available',
          'discountValue': '0'
        },
        'requiresUserEmail': true,
        'testMode': true,
        'sharedUrlInfo': {
          'type': 'SHARED',
          'state': 'VALID',
          'expires': {
            'days': 30,
            'hours': 8
          }
        },
        'pageInfo': {
          'loadState': 'LOAD'
        }
      },
      errors: {},
    };
  }

  componentDidMount() {


    this.updatePreview(
      this.props.cssExternal,
      this.props.htmlBody,
      this.props.css,
      this.props.jsExternal,
      this.props.javascript,
      this.state.campaignDetails
    );
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.displayBlockId != this.props.displayBlockId){
      this.props.dispatch(displayBlockActions.getDisplayBlock(nextProps.displayBlockId));
    }

    this.updatePreview(
      nextProps.cssExternal,
      nextProps.htmlBody,
      nextProps.css,
      nextProps.jsExternal,
      nextProps.javascript,
      this.state.campaignDetails
    );
  }

  updatePreview(
    cssExternal = '',
    htmlBody = '',
    css = '',
    jsExternal = '',
    javascript = '',
    campaignDetails = {},
  ){

    var sharerEmail = '';

    var htmlContent = `
          <!DOCTYPE html>
          <html>
          <head>
            <title>Soreto Placement</title>
            ${cssExternal}
            <style>
              .srt-hide { display: none; }
              ${css}
            </style>
          </head>

          <body>
            <script id="reverb-sdk" src="<%= apiBaseUrl %>/scripts/reverb-placement-sdk.min.js"></script>
            ${htmlBody}
            ${jsExternal}
            <script>
              var campaignDetails = ${JSON.stringify(campaignDetails)};
              var details = ${JSON.stringify(campaignDetails)};
              var sharerEmail = '${sharerEmail}';

              document.addEventListener("DOMContentLoaded", document.querySelectorAll('[data-srt-reward-group-alias]').forEach((element)=>{
              setTimeout(function(){ element.classList.remove("srt-hide"); }, 500);
              }))
              

            </script>
            <script>
              var interstitialStateHandling = Webpack.interstitialStateHandling();
              interstitialStateHandling.initInterstitialStateHandling(details);
              ${javascript}
              function sendTracking(empty,empty,cb){ //null tracking on testing env
                if(cb){
                  return cb
                }
              }
            </script>
          </body>
          </html>
    `;
  
    let templateParams = {
      apiBaseUrl:config.URL.BASEBACK,
      backUrl:config.URL.BASEBACK,
      clientId: '',
      hostingSrc: '',
      title:  'Soreto Discount!',
      discountCode: 'discount code',
      discountValue: 'discount val',
      imageUrl: '',
      productUrl: '',
      firstName: 'a friend',
      sharedUrlId: ''
    };

    try {
      
      let html = ejs.render(htmlContent, templateParams);

      if (this.props.displayBlock && this.props.displayBlock.type == 'lightbox'){
        html = ejs.render(_uglyPopWrap, { lightboxContent: html });
      }

      this.setState({previewHtml:html});
    } catch(error) {
      console.log('ejs template error', error);
      this.setState({previewHtml:htmlContent});
    }
  }

  onResize(name, {target}) {
    const { size, details } = this.state;
    if(target.value === 'desktop') {
      _.set(size, 'height', '550px');
      _.set(size, 'width', '100%');
    } else if(target.value === 'mobile') {
      _.set(size, 'height', '550px');
      _.set(size, 'width', '400px');
    }

    _.set(details, 'layout', target.value);
    this.setState({details});
    this.setState({size});
  }

  onUpdateStore(name, {target}) {
    const { campaignDetails } = this.state;

    if(target.id == 'page-state'){
      _.set(campaignDetails, 'pageInfo', {loadState: target.value } );
    }
    if(target.id == 'url-state'){
      _.set(campaignDetails, 'sharedUrlInfo.state', target.value );
    }
    if(target.id == 'url-type'){
      _.set(campaignDetails, 'sharedUrlInfo.type', target.value );
    }
    if(target.id == 'email-required'){
      _.set(campaignDetails, 'requiresUserEmail', target.value );
    }

    this.updatePreview(
      this.props.cssExternal,
      this.props.htmlBody,
      this.props.css,
      this.props.jsExternal,
      this.props.javascript,
      campaignDetails
    );
    this.setState({campaignDetails});
  }


  render() {
    const { details, campaignDetails, size, previewHtml } = this.state;

    return (
      <div style={{marginTop: '25px', height: '550px'}}>
        
        <LiveFrameBeta size={size} htmlContent={previewHtml} />
        
        <FormElement
          label='Layout'
          name='layout'
          type='select'
          onChange={this.onResize.bind(this, 'layout')}
          value={details.layout}
          id='form-active'
        >
          <option value="desktop">desktop</option>
          <option value="mobile">mobile</option>
        </FormElement>

        <PreviewPopup htmlContent={previewHtml}/>

        {this.props.displayBlock && this.props.displayBlock.type == 'interstitial' &&

        <div>
          <FormElement
            label='Page Load state'
            name='loadState'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'loadState')}
            value={campaignDetails.pageInfo.loadState}
            id='page-state'
          >
            <option value="LOAD">LOAD</option>
            <option value="SHOWCODE">SHOWCODE</option>
          </FormElement>
          <div className="drop-down-info">
            data-srt-page-state="{campaignDetails.pageInfo.loadState}"
          </div>
          <br/>
          <br/>

          <FormElement
            label='Page URL state'
            name='urlState'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'urlState')}
            value={campaignDetails.sharedUrlInfo.state}
            id='url-state'
          >
            <option value="EXPIRED">EXPIRED</option>
            <option value="VALID">VALID</option>
            <option value="BLOCKED_SHARER_ACCESS">BLOCKED SHARER ACCESS</option>
          </FormElement>
          <div className="drop-down-info">
          data-srt-shared-url-state="{campaignDetails.sharedUrlInfo.state}"
          </div>
          <br/>
          <br/>
          <FormElement
            label='shared url type'
            name='urlType'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'urlType')}
            value={campaignDetails.sharedUrlInfo.type}
            id='url-type'
          >
            <option value="SHARED">SHARED</option>
            <option value="PERSONAL">PERSONAL</option>
            <option value="SHARER_POST_REWARD">SHARER POST REWARD</option>
            <option value="FRIEND_POST_REWARD">FRIEND POST REWARD</option>
          </FormElement>
          <div className="drop-down-info">
          data-srt-shared-url-type="{campaignDetails.sharedUrlInfo.type}"
          </div>
          <br/>
          <br/>
          <FormElement
            label='require email'
            name='emailRequired'
            type='select'
            onChange={this.onUpdateStore.bind(this, 'emailRequired')}
            value={campaignDetails.requiresUserEmail}
            id='email-required'
          >
            <option value="TRUE">TRUE</option>
            <option value="FALSE">FALSE</option>
          </FormElement>
          <div className="drop-down-info">
          data-srt-require-email="{!campaignDetails.requiresUserEmail || campaignDetails.requiresUserEmail == 'FALSE'  ?'FALSE':'TRUE'}"
          </div>
          <br/>
          <br/>
        </div>
        }
        
      </div>
    );
  }
}

export default PreviewHtmlBeta;
