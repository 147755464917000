import constants from '../../constants/app-constants';
import filterPlatforms from '../../utils/helpers/filterPlatforms';

const initialState = {}

const ByChannel = (state = initialState , action) => {
  switch(action.type){

  case constants.client.BY_CHANNEL_TOTAL_CLIENT_STATS_BY_PERIOD_GET: {
    return {...state, byChannelTotalClientStatsByPeriodGet: action.payload };
  }

  case constants.client.BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_GET: {
    
    return {...state, byChannelClientStatsPerChannelByPeriodGet: filterPlatforms(action.payload) };
  }

  case constants.client.BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_BI_GET: {
    
    return {...state, byChannelClientStatsPerChannelByPeriodBiGet: filterPlatforms({ page : (action.payload) ? action.payload.data : []}) };
  }

  default:
    return state;
  }
};

export default ByChannel