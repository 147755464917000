import constants from '../constants/app-constants';

const initialState = {
  enabled: false
};

const loadSpinner = (state = initialState , action) => {
  switch(action.type){

  case constants.LOAD_SPINNER_ENABLED : {
    return {...state, enabled: action.payload };
  }

  default:
    return state;
  }
};

export default loadSpinner;