import codeBlockService from '../../utils/services/codeBlock';
import scssConversionService from '../../utils/services/scssConversion';
import constants from '../../constants/app-constants';

export const newCodeBlock = obj => dispatch => {
  return new Promise((resolve, reject) => {
    codeBlockService.newCodeBlock(obj)
      .then(res => {
        dispatch({
          type: constants.admin.CODE_BLOCK_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.CODE_BLOCK_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const convertScss = (obj) => {
  return function(dispatch){

    return new Promise(function(resolve,reject) {
      scssConversionService.convertCss(obj)
        .then(function(res){
          dispatch({
            type: 'constants.admin.CONVERT_CSS',
            payload: res.data
          });
          resolve(res.data);
        })
        .catch(function(err){
          reject(err);
        });
    });
  };
};

export const updateCodeBlock = (obj) => {
  return function(dispatch){
    codeBlockService.updateCodeBlock(obj)
      .then(function(res){
        dispatch({
          type: constants.admin.CODE_BLOCK_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CODE_BLOCK_EDIT,
          payload: err.response
        });
      });
  };
};

export const getCodeBlock = (id) => {
  return function(dispatch){
    codeBlockService.getCodeBlock(id)
      .then(function(res){
        dispatch({
          type: constants.admin.CODE_BLOCK_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CODE_BLOCK_GET,
          payload: err.response
        });
      });
  };
};
