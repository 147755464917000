import orderService from '../../utils/services/order';
import clientService from '../../utils/services/client';
import constants from '../../constants/app-constants';

export const getOrders = (query, extendedQuery) => {
  return function(dispatch) {
    orderService
      .getOrdersJoined(query, extendedQuery)
      .then(function(res) {
        dispatch({
          type: constants.admin.ORDER_GET_ALL,
          payload: res.data
        });
      })
      .catch(function(err) {
        dispatch({
          type: constants.admin.ORDER_GET_ALL,
          payload: err.response
        });
      });
  };
};

export const updateOrderById = (clientId, orderId, payload) => {
  return function(dispatch) {
    clientService
      .updateOrder(clientId, orderId, payload)
      .then(function(res) {
        dispatch({
          type: constants.admin.ORDER_EDIT,
          payload: res.data
        });
      })
      .catch(function(err) {
        dispatch({
          type: constants.admin.ORDER_EDIT,
          payload: err.response
        });
      });
  };
};

export const getOrderHistory = (orderId, extendedQuery) => {
  return function(dispatch) {
    orderService
      .getOrderHistory(orderId, extendedQuery)
      .then(function(res) {
        dispatch({
          type: constants.admin.ORDER_GET_HISTORY,
          payload: res.data
        });
      })
      .catch(function(err) {
        dispatch({
          type: constants.admin.ORDER_GET_HISTORY,
          payload: err.response
        });
      });
  };
};
