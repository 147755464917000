import React from 'react';
import config from '../../../config/config';
import { authService } from '../../../utils/services/auth';
import userService from '../../../utils/services/user';
import { connect } from 'react-redux';
import * as clientGlobalMessageActions from '../../../actions/globalMessageActions';
import trackingService from '../../../utils/services/tracking';
import *  as segmentAnalytics from '../../shared/segment/analytics';
import * as indexActions from '../../../actions/index';
import GlobalMessages from '../globalMessage/GlobalMessage';
import { browserHistory } from 'react-router';

@connect((store) => {
  return {
    baseUrl: config.URL.BASEFRONT,
    globalMessage : store.globalMessages.message
  };
})
export default class Login extends React.Component {

  constructor(){
    super();
    this.login.bind();
    this.getCurrent.bind();
    this.goToPage.bind();
  }
  componentWillMount(){
    this.setState(this.setInitialState());
  }

  setInitialState(){
    return {
      authService: authService,
      indexActions: indexActions,
      globalMessageActions: clientGlobalMessageActions
    };
  }
  
  login(e) {
    e.preventDefault();
    var that = this;
    this.state.authService
      .login(that.username.value, that.password.value)
      .then((response) => {

      if(response.data && response.data.twoFactorAuthEnabled){

        localStorage.setItem('twoFactorVerificationEmail', that.username.value);
        browserHistory.push('/twoFactorVerification');
        return;
      }

      userService.getCurrent()
        .then((res) => { //check if in correct context
          //TODO Ricardo Fix
          //var context = that.state.authService.context;
          //var role = res.data.roles.name;
          // if (context == 'client' && role == 'user' || context == 'user' && role == 'clientUser'){
          //   var altDisplayContext = context == 'user' ? 'business area':'consumer area'
          //   var displayContext = context != 'user' ? 'business area':'consumer area'
          //   var displayUserType = role == 'user' ? 'user':'business user'
          //   that.state.authService.logout().then(function(){
          //     that.props.dispatch(that.state.globalMessageActions.showMessage(
          //       {
          //         type: 'error',
          //         message: `Woops! You are a ${displayUserType} trying to log into the ${displayContext}. Try logging into the ${altDisplayContext}` ,
          //         timeout: 10000
          //       }
          //     ));
          //   });
          //   return;
          // }
          that.props.dispatch(that.state.indexActions.updateLoginCtx(res.data));
          segmentAnalytics.identify(res.data);
          trackingService.login(res.data , 'form');

          if(res.data.roles.some(s => s.name === 'clientUser')) {
            this.goToPage('/client');
          } else if(res.data.roles.some(s => s.name === 'user')){
            this.goToPage('/user');
          } else if(res.data.roles.some(s => s.name === 'admin')){
            window.location.href = config.URL.MELISSA_PORTAL;
          }
        });
    }).catch((err) => {
      that.props.dispatch(that.state.globalMessageActions.showMessage(
        {
          type: 'error',
          message: err.response.data.message,
          timeout: 10000
        }
      ));
    });
  }

  goToPage(page){
    this.props.router.push({ pathname: page });
  }


  socialLogin(platform){
    this.setState({
      loginPlatform: platform
    });

    window.open(
      `${config.API.BASEURL}/auth/${platform}/login`,
      'targetWindow',
      'toolbar=no,' +
      'location=no,' +
      'status=no,' +
      'menubar=no,' +
      'scrollbars=yes,' +
      'resizable=yes,' +
      'width=550,' +
      'height=700');
    return false;
  }

  getCurrent(){
    var that = this;
    userService.getCurrent()
      .then(function(res){
        that.props.dispatch(that.state.indexActions.updateLoginCtx(res.data));
        segmentAnalytics.identify(res.data);
        trackingService.login(res.data,that.state.loginPlatform);

        if(res.data.roles.some(s => s.name === 'clientUser')) {
          this.goToPage('/client');
        } else if(res.data.roles.some(s => s.name === 'user')){
          this.goToPage('/user');
        } else if(res.data.roles.some(s => s.name === 'admin')){
          this.goToPage('/admin');
        }
      });
  }

  showError(e){
    this.props.dispatch(this.state.globalMessageActions.showMessage(
      {
        type: 'error',
        message: e.target.getAttribute('data-error'),
        timeout: 10000
      }
    ));
  }

  render () {
    return (
      <div className="front-page grid-container grid-parent">
        
        <div className="grid-100 front-page-mid-section grid-parent">
          <GlobalMessages message={this.props.globalMessage}/>

          <div className="grid-100 front-page-form-holder text-center">
            <div className="soreto__logo"></div>
            <div className="form-container">
              <form onSubmit={(e) => this.login(e)}>  
                <p className="form-container__title">Enter your Email and password to access your admin panel.</p>
                <div className="front-page-input-holder">
                  <input required="true" ref={(input) => this.username = input} type="email" placeholder="email"/>
                </div>
                <div  className="front-page-input-holder">
                  <input required="true" ref={(input) => this.password = input} type="password" placeholder="Password"/>
                </div>
                <div>
                  <button className="login--btn">LOG IN</button>
                </div>
                <p className="forgot-password">
                  <span className="forgot-password__title">Forgotten password? </span>
                  <span onClick={() => this.goToPage('/forgotpassword')} className="forgot-password-link">Recover your password</span>
                </p>
              </form>
              <div className="hide-on-desktop hide-on-tablet hide-on-mobile">
                <div>
                  <button onClick={() => this.getCurrent() } id="get-current">Close</button>
                </div>
                <div>
                  <button onClick={(e) => this.showError(e) } id="error-show">Error show</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}