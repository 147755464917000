import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const ClientOnboard = (state = initialState , action) => {
  switch(action.type){
  case constants.admin.CLIENT_NEW : return {...state , requests : action.payload};
  default: return state;
  }
};

export default ClientOnboard;