import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import { DateRangePicker } from 'react-date-range';
import enGb from 'react-date-range/dist/locale/en-GB';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { Calendar } from 'primereact/components/calendar/Calendar';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import * as globalMessageActions from '../../../actions/globalMessageActions';
import * as dateRangePickerActions from '../../../actions/shared/dataRangePickerActions';

const moment = extendMoment(originalMoment);

export const dateIndexSelection = { FIRST: 0, SECOND: 1 };

@connect(store => {
  return {
    toggleCalendar: store.clientHeader.toggleCalendar,
    calendar: store.sharedDateRangePicker.setCalendarDates,
    isCalendarHidden: store.sharedDateRangePicker.isCalendarHidden
  };
})
export class DataRangePicker extends React.Component {
  constructor(props) {
    super(props);

    const selectionRange = [
      {
        startDate: props.calendar[0].startDate,
        endDate: props.calendar[0].endDate,
        key: 'firstSelection'
      }
    ];

    const today = new Date();

    this.state = {
      today: today,
      dates: selectionRange,
      isHidden: props.isCalendarHidden,
      defaultPeriod: 'thisMonth',
      input: { start: '', end: '' },
      comparePeriods: false,
      previousPeriod: '',
      previousPeriodCheckBox: true,
      maxDate: today,
      focusedRange: [0,0],
      onFocusFirstSelection: false,
      onFocusSecondSelection: false
    };
  };

  componentDidUpdate = prevProps => {
    if (prevProps.toggleCalendar !== this.props.toggleCalendar) {
      this.onToggle();
    }
  };

  closeCalendar = () => {
    this.setState({ isHidden: true });
  };

  onSelect = ({ firstSelection, secondSelection }) => {
    var { dates, defaultPeriod, focusRange, previousPeriodCheckBox, defaultPeriod, previousPeriod} = this.state;

    if(!focusRange)
      focusRange = [0,0];

    if(focusRange[0] == 0) {
      defaultPeriod = 'custom';
    }

    if(focusRange[0] == 1){
      previousPeriod = 'custom';
      previousPeriodCheckBox = false;
    }

    if (defaultPeriod !== 'custom') {
      if (secondSelection) {
        dates[dateIndexSelection.SECOND] = secondSelection;
        this.setState({ onFocusSecondSelection: true, onFocusFirstSelection: false })
      }
    } else {
      if (firstSelection) {
        dates[dateIndexSelection.FIRST] = firstSelection;
        this.setState({ onFocusFirstSelection: true, onFocusSecondSelection: false })
      } else if (secondSelection) {
        dates[dateIndexSelection.SECOND] = secondSelection;
        this.setState({ onFocusSecondSelection: true, onFocusFirstSelection: false })
      }
    }

    if(defaultPeriod === 'custom')
    {
      var newFocus = focusRange;
      if(!focusRange)
        focusRange = [0,0];
      else if(focusRange[0] == 0 && focusRange[1] == 0)
        newFocus = [0,1];
      else if(focusRange[0] == 0 && focusRange[1] == 1) {
        //Check if previous period checkbox is enabled (enabled == false)
        if(previousPeriodCheckBox == false && previousPeriod === 'custom')
          newFocus = [1,0];
        else
          newFocus = [0,0];
      } else if(focusRange[0] == 1 && focusRange[1] == 0)
        newFocus = [1,1];
      else if(focusRange[0] == 1 && focusRange[1] == 1)
        newFocus = [0,0];

      this.setState({ dates, focusRange: newFocus, defaultPeriod });
    } else if (previousPeriodCheckBox == false && previousPeriod === 'custom') {
      if(!focusRange)
        focusRange = [1,0];
      else if(focusRange[0] == 1 && focusRange[1] == 0)
        newFocus = [1,1];
      else if(focusRange[0] == 1 && focusRange[1] == 1)
        newFocus = [1,0];

      this.setState({ dates, focusRange: newFocus, defaultPeriod });
    } else {
      this.setState( { dates });
    }
  };

  areDatesOverLapping = () => {
    const firstSelectionStartDate = moment(this.state.dates[dateIndexSelection.FIRST].startDate).format('YYYY-MM-DD');
    const secondSelectionEndDate = moment(this.state.dates[dateIndexSelection.SECOND].endDate).format('YYYY-MM-DD');
    const areDatesOverLapping = moment(secondSelectionEndDate).isSameOrAfter(firstSelectionStartDate);
    return areDatesOverLapping;
  };

  setPreviousPeriod = e => {
    const dates = this.state.dates.slice();
    const period = this.state.defaultPeriod;
    var startDayOfTheWeek = moment(dates[dateIndexSelection.FIRST].startDate).day('Monday');
    this._startDayOfTheWeek = startDayOfTheWeek;
    var oneMonthsSubtracted = moment(dates[dateIndexSelection.FIRST].startDate).subtract(1, 'M');
    var daysToSubtractFromStarDate = moment(dates[dateIndexSelection.FIRST].startDate)
      .startOf('day')
      .format('D');
    var monthStart = oneMonthsSubtracted.clone().subtract(daysToSubtractFromStarDate - 1, 'days');
    this._monthStart = monthStart;

    let start = 0,
      end = 0;

    if (e && e.value) {
      this.previousPeriod = e.value;
    } else {
      e = {};
      e.value = 'custom'
      this.previousPeriod = e.value;
    }

    if (this.previousPeriod && this.previousPeriod === 'previousPeriod') {
      switch (period) {
        case 'today':
        case 'yesterday':
          start = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .subtract(1, 'days')
          );
          end = new Date(
            moment(dates[dateIndexSelection.FIRST].endDate)
              .subtract(1, 'days')
          );
          break;

        case 'thisWeek':
          const weekDates = this.setPreviousThisWeek();
          start = weekDates[0];
          end = weekDates[1];
          break;

        case 'thisMonth':
          const monthDates = this.setPreviousThisMonth();
          start = monthDates[0];
          end = monthDates[1];
          break;

        case 'lastWeek':
          var sundayOfTheLastWeek = startDayOfTheWeek
            .clone()
            .subtract(7, 'days');

          start = new Date(sundayOfTheLastWeek);
          end = new Date(
            startDayOfTheWeek
              .clone()
              .subtract(1, 'days')
          );
          break;

        case 'lastMonth':
          const lastMonthDates = this.setPreviousLastMonth();
          start = lastMonthDates[0];
          end = lastMonthDates[1];
          break;

        case 'last7days':
          start = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .clone()
              .subtract(7, 'days')
          );
          end = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .clone()
              .subtract(1, 'days')
          );
          break;

        case 'last30days':
          start = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .clone()
              .subtract(30, 'days')
          );
          end = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .clone()
              .subtract(1, 'days')
          );
          break;

        default:
          const previousDefaultDates = this.setPreviousDefaultDates();
          start = previousDefaultDates[0];
          end = previousDefaultDates[1];
          break;
      }
    } else if (this.previousPeriod && this.previousPeriod === 'previousYear') {
      switch (period) {
        case 'today':
        case 'yesterday':
          start = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .subtract(1, 'Y')
          );
          end = start;
          break;

        case 'thisWeek':

          const thisWeekStartDay = moment(this._thisWeekStart).date();
          const today = moment(this._thisWeekEnd).date();
          const lasWeekDaysToSubtract = today - thisWeekStartDay;

          // Finds the day of todat a year ago, and select x numbers of days from Monday
          var startDayOfLastYearWeek = moment(this._thisWeekEnd).clone().subtract(1, 'Y').day('Monday');
          start = new Date(startDayOfLastYearWeek);
          end = new Date(startDayOfLastYearWeek.clone().add(lasWeekDaysToSubtract, 'days'));          

          break;

        case 'thisMonth':
          const monthDateStart = this._thisMonthStart ?  this._thisMonthStart : this.props.calendar[0].startDate;
          const monthEndDate = this._thisMonthEnd ? this._thisMonthEnd : this.props.calendar[0].endDate;
          start = new Date(
            moment(monthDateStart)
              .clone(monthEndDate)
              .subtract(1, 'Y')
          );
          end = new Date(
            moment()
              .clone()
              .subtract(1, 'Y')
          );
          break;

        case 'lastWeek':

          var dayOfTodayLastYearLastWeek = moment().subtract(1, 'Year').subtract(1, 'week');
          
          var firstDayOfLastYearLastWeek = dayOfTodayLastYearLastWeek.clone().day('Monday');

          start = new Date(firstDayOfLastYearLastWeek);
          end = new Date(firstDayOfLastYearLastWeek.clone().add(6,'days'));
          break;

        case 'lastMonth':
          var startDate = moment(dates[dateIndexSelection.FIRST].startDate);
          var daysToSubtractFromStarDate = moment(dates[dateIndexSelection.FIRST].startDate)
            .startOf('day')
            .format('D');
          var previousMonthStart = startDate.clone().subtract(daysToSubtractFromStarDate - 1, 'days');
          var previousMonthEnd = previousMonthStart
            .clone()
            .add(1, 'M')
            .subtract(1, 'days');

          start = new Date(previousMonthStart.subtract(1, 'Y'));
          end = new Date(previousMonthEnd.subtract(1, 'Y'));
          break;

        case 'last7days':
          start = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .clone()
              .subtract(1, 'Y')
          );
          end = new Date(
            moment(start)
              .clone()
              .add(6, 'days')
          );
          break;

        case 'last30days':
          start = new Date(
            moment(dates[dateIndexSelection.FIRST].startDate)
              .clone()
              .subtract(1, 'Y')
          );
          end = new Date(
            moment(start)
              .clone()
              .add(29, 'days')
          );
          break;

        default:
          start = new Date(moment(dates[dateIndexSelection.FIRST].startDate).subtract(1, 'Y'));
          end = new Date(moment(dates[dateIndexSelection.FIRST].endDate).subtract(1, 'Y'));
          break;
      }
    } else if (this.previousPeriod && this.previousPeriod === 'custom') {
      const { defaultPeriod } = this.state;
      if (defaultPeriod === 'thisWeek') {
        const weekDates = this.setPreviousThisWeek();
        start = weekDates[0];
        end = weekDates[1];
      } else if (defaultPeriod === 'thisMonth') {
        const monthDates = this.setPreviousThisMonth();
        start = monthDates[0];
        end = monthDates[1];
      } else if (defaultPeriod === 'lastMonth') {
        const monthDates = this.setPreviousLastMonth();
        start = monthDates[0];
        end = monthDates[1];
      } else if (defaultPeriod === 'today') {
        start = new Date(
          moment(dates[dateIndexSelection.FIRST].startDate)
            .subtract(1, 'days')
        );
        end = new Date(
          moment(dates[dateIndexSelection.FIRST].endDate)
            .subtract(1, 'days')
        );
      } else {
        const previousDefaultDates = this.setPreviousDefaultDates();
        start = previousDefaultDates[0];
        end = previousDefaultDates[1];
      }

      this.setState({ previousPeriod: e.value, focusRange: [1,0] });
    }

    if (e) {
      dates[dateIndexSelection.SECOND] = {
        startDate: start,
        endDate: end,
        key: 'secondSelection'
      };
      this.setState({ previousPeriod: e.value, dates: dates });
    } else {
      dates[dateIndexSelection.SECOND] = {
        startDate: start,
        endDate: end,
        key: 'secondSelection'
      };
      this.setState({ dates: dates, previousPeriodCheckBox: false, comparePeriods:true  });
    }
  };

  setPreviousThisWeek = () => {
    const thisWeekStartDay = moment(this._thisWeekStart).date();
    const today = moment(this._thisWeekEnd).date();
    const lasWeekDaysToSubtract = today - thisWeekStartDay;

    const startDayOfTheLastWeek = this._startDayOfTheWeek
      .clone()
      .subtract(7, 'days');
    const lastWeekEndDay = this._startDayOfTheWeek
      .clone()
      .subtract(7, 'days')
      .add(lasWeekDaysToSubtract, 'days');

    const start = new Date(startDayOfTheLastWeek);
    const end = new Date(lastWeekEndDay);

    return [start, end];
  };

  setPreviousThisMonth = () => {
    const currentElapsedDaysOfTheMonth = moment(this._thisMonthEnd).date() - 1;
    var currentMonthEndDay = this._monthStart.clone().add(currentElapsedDaysOfTheMonth, 'days');

    const start = new Date(this._monthStart);
    const end = new Date(currentMonthEndDay);

    return [start, end];
  };

  setPreviousLastMonth = () => {
    var currentMonthEndDay = this._monthStart
        .clone()
        .add(1, 'M')
        .subtract(1, 'days');

    const start = new Date(this._monthStart);
    const end = new Date(currentMonthEndDay);

    return [start, end];
  };

  setPreviousDefaultDates = () => {
    const { dates } = this.state;
    var now = moment(dates[dateIndexSelection.FIRST].startDate);
    var endDate = moment(dates[dateIndexSelection.FIRST].endDate);
    var duration = moment.duration(now.clone().diff(endDate)).asDays() * -1;
    duration += 1;
    var daysToAdd = duration - 1;

    const start = new Date(
      moment(dates[dateIndexSelection.FIRST].startDate)
        .clone()
        .subtract(duration, 'days')
    );
    const end = new Date(
      moment(start)
        .clone()
        .add(daysToAdd, 'days')
    );

    return [start, end];
  };

  setCurrentPeriod = e => {
    const today = moment();
    let start = 0,
      end = 0;
    let maxDate = 0;
    let startDayOfTheWeek = today.clone().day('Monday');

    switch (e.value) {
      case 'today':
        start = new Date(today.startOf('day'));
        end = new Date(today.endOf('day'));
        //maxDate = end;
        break;

      case 'thisWeek':
        start = new Date(startDayOfTheWeek);
        end = new Date(today.startOf('day'));
        this._thisWeekStart = start;
        this._thisWeekEnd = end;
        //maxDate = end;
        break;

      case 'thisMonth':
        start = new Date(
          today
            .clone()
            .subtract(today.date() - 1, 'days')
        );
        end = new Date(today.startOf('day'));
        this._thisMonthStart = start;
        this._thisMonthEnd = end;
        //maxDate = end;
        break;

      case 'yesterday':
        start = new Date(
          today
            .clone()
            .subtract(1, 'days')
        );
        end = new Date(
          today
            .clone()
            .subtract(1, 'days')
        );
        //maxDate = end;
        break;

      case 'lastWeek':
        var sundayOfTheLastWeek = startDayOfTheWeek
          .clone()
          .subtract(7, 'days');

        start = new Date(sundayOfTheLastWeek);
        end = new Date(
          startDayOfTheWeek
            .clone()
            .subtract(1, 'days')
        );
        //maxDate = end;
        break;

      case 'lastMonth':
        var oneMonthSubtracted = today.clone().subtract(1, 'M');
        var daysToSubtract = today.startOf('day').format('D');

        start = new Date(
          oneMonthSubtracted
            .clone()
            .subtract(daysToSubtract - 1, 'days')
        );
        end = new Date(
          today
            .clone()
            .subtract(daysToSubtract, 'days')
        );
        //maxDate = end;
        break;

      case 'last7days':
        start = new Date(
          today
            .clone()
            .subtract(7, 'days')
        );
        end = new Date(
          today
            .clone()
            .subtract(1, 'days')
        );
        //maxDate = end;
        break;

      case 'last30days':
        start = new Date(
          today
            .clone()
            .subtract(30, 'days')
        );
        end = new Date(
          today
            .clone()
            .subtract(1, 'days')
        );
        //maxDate = end;
        break;

      case 'custom':
        start = new Date(moment(undefined));
        end = new Date(moment(undefined));
        const { dates } = this.state;
        if (dates && dates.length === 2) dates.pop();
        this.setState({ dates });
        break;

      default:
        start = this.props.calendar[0].startDate;
        end = this.props.calendar[0].endDate;
        //maxDate = end;
        break;
    }

    const dates = this.state.dates.slice();
    dates[dateIndexSelection.FIRST] = {
      startDate: start,
      endDate: end,
      key: 'firstSelection'
    };

    if (dates && dates.length === 2) dates.pop();

    if (e.value === 'custom') {
      this.setState(
        {
          dates,
          defaultPeriod: e.value,
          comparePeriods: false,
          previousPeriod: '',
          previousPeriodCheckBox: true,
          onFocusFirstSelection: true,
          onFocusSecondSelection: false
         }
        );
    } else {
      this.setState(
        {
          //maxDate,
          dates,
          defaultPeriod: e.value,
          comparePeriods: false,
          previousPeriod: '',
          previousPeriodCheckBox: true,
          onFocusFirstSelection: true,
          onFocusSecondSelection: false
        },
        () => this.state.comparePeriods && this.setPreviousPeriod()
      );
    }
  };

  setStartDateByInput = e => {
    if (this.state.defaultPeriod === 'custom') {
      var dates = this.state.dates.slice();
      dates[dateIndexSelection.FIRST].startDate = e.value;
      this.setState({ dates, onFocus: [0,0] });
    }
  };

  setEndDateByInput = e => {
    if (this.state.defaultPeriod === 'custom') {
      var dates = this.state.dates.slice();
      const firstSelectionStartDate = moment(dates[dateIndexSelection.FIRST].startDate).format('YYYY-MM-DD');
      const secondSelectionEndDate = moment(e.value).format('YYYY-MM-DD');
      const areDatesOverLapping = moment(secondSelectionEndDate).isSameOrBefore(firstSelectionStartDate);

      if (!areDatesOverLapping) {
        dates[dateIndexSelection.FIRST].endDate = e.value;
      }
      this.setState({ dates, onFocus: [0,0] });
    }
  };

  showErrorMsg = () => (
    this.props.dispatch(
      globalMessageActions.showMessage({
        type: 'error',
        message: 'Error: Please check your dates',
        timeout: 5000
      })
    )
  );

  setCompareDates = () => {
    if (this.state.dates && Array.isArray(this.state.dates) && this.state.dates.length === 1) {
      this.props.dates(this.state.dates);
      this.props.dispatch(dateRangePickerActions.setDates(this.state.dates));
      this.onToggle();
    } else {
      if (this.areDatesOverLapping()) {
        this.showErrorMsg();
      } else {
        this.props.dates(this.state.dates);
        this.props.dispatch(dateRangePickerActions.setDates(this.state.dates));
        this.onToggle();
      }
    }
  };

  setStartDateSecondPeriod = e => {
    const { previousPeriod, dates } = this.state;
    if (previousPeriod === 'custom') {
      dates[dateIndexSelection.SECOND] = {
        startDate: new Date(e.value),
        endDate: dates[dateIndexSelection.FIRST].endDate,
        key: 'secondSelection'
      };
    }
    this.setState({ dates });
  };

  setEndDateSecondPeriod = e => {
    const { previousPeriod } = this.state;
    var dates = this.state.dates.slice();
    if (previousPeriod === 'custom') {
      const firstSelectionStartDate = moment(dates[dateIndexSelection.SECOND].startDate).format('YYYY-MM-DD');
      const secondSelectionEndDate = moment(e.value).format('YYYY-MM-DD');
      const areDatesOverLapping = moment(secondSelectionEndDate).isBefore(firstSelectionStartDate);
        if (!areDatesOverLapping) {
        dates[dateIndexSelection.SECOND].endDate = new Date(e.value);
      }
    }
    this.setState({ dates });
  };

  setCalendarFocusToGreen1 = e => {
    this.setState({ focusRange: [0,0], onFocusFirstSelection: true, onFocusSecondSelection: false });    
  };

  setCalendarFocusToGreen2 = e => {
    this.setState({ focusRange: [0,1], onFocusFirstSelection: true, onFocusSecondSelection: false });
  };

  setCalendarFocusToRed1 = e => {
    this.setState({ 
      focusRange: [1,0], 
      previousPeriod: 'custom', 
      previousPeriodCheckBox: false, 
      comparePeriods: true,
      onFocusFirstSelection: false, 
      onFocusSecondSelection: true
    });
    this.setPreviousPeriod();
  };

  setCalendarFocusToRed2 = e => {
    this.setState({ 
      focusRange: [1,1], 
      previousPeriod: 'custom', 
      previousPeriodCheckBox: false, 
      comparePeriods: true,
      onFocusFirstSelection: false, 
      onFocusSecondSelection: true
    });
    this.setPreviousPeriod();
  };

  onToggle = () => {
    this.setState(
      prevState => {
        return {
          isHidden: !prevState.isHidden
        };
      },
      () => this.props.dispatch(dateRangePickerActions.isDataRangePickerOpened(this.state.isHidden))
    );
  };

  onComparePeriodsChange = () => {
    const dates = this.state.dates.slice();

    if (dates.length === 2) {
      dates.pop();
    }

    let disableCheckBox = false;
    if (this.state.previousPeriodCheckBox === true) {
      var previousPeriod = 'previousPeriod';
    } else {
      disableCheckBox = true;
    }

    this.setState(prevState => {
      return {
        comparePeriods: !prevState.comparePeriods,
        dates: dates,
        previousPeriodCheckBox: disableCheckBox,
        previousPeriod,
        focusRange: [0,0],
        onFocusFirstSelection: true,
        onFocusSecondSelection: false
      };
    });

    if (this.state.previousPeriodCheckBox === true) {
      this.setPreviousPeriod({value: previousPeriod});
    }
  };

  renderDropdownCurrentPeriod = () => {
    const periodSelectItems = [
      { label: 'Custom', value: 'custom' },
      { label: 'Today', value: 'today' },
      { label: 'Yesterday', value: 'yesterday' },
      { label: 'This Week', value: 'thisWeek' },
      { label: 'This Month', value: 'thisMonth' },
      { label: 'Last Week', value: 'lastWeek' },
      { label: 'Last Month', value: 'lastMonth' },
      { label: 'Last 7 days', value: 'last7days' },
      { label: 'Last 30 days', value: 'last30days' }
    ];

    return (
      <Dropdown
        value={this.state.defaultPeriod}
        className="item item--2"
        options={periodSelectItems}
        onChange={this.setCurrentPeriod}
      />
    );
  };

  renderDropdownPreviousPeriod = () => {
    const periodSelectItems = [
      { label: 'Custom', value: 'custom' },
      { label: 'Previous period', value: 'previousPeriod' },
      { label: 'Previous year', value: 'previousYear' },
    ];

    return (
      <Dropdown
        value={this.state.previousPeriod}
        className="item--6--dropdown"
        disabled={this.state.previousPeriodCheckBox}
        options={periodSelectItems}
        onChange={e => this.setPreviousPeriod(e)}
      />
    );
  };

  renderCurrentTimePeriod = () => {
    const { defaultPeriod, dates, comparePeriods } = this.state;
    const disableCalendar = defaultPeriod === 'custom' ? false : true;

    let start = '';
    let end = '';

    if (dates && Array.isArray(dates)) {
      start = dates[dateIndexSelection.FIRST].startDate;
      end = dates[dateIndexSelection.FIRST].endDate;
    }

    const showFocus = this.state.onFocusFirstSelection;

    return (
      <React.Fragment>
        <div className={classnames({
          onFocus: showFocus
        })}>
          <Calendar
            className={classnames("item item--3", {
              onFocus: showFocus
            })}
            maxDate={new Date()}
            required
            //disabled={disableCalendar}
            readOnlyInput={true}
            value={start && start}
            showOnFocus={false}
            name="dateRangeFrom"
            dateFormat="dd/mm/yy"
            onInit={e => this.setStartDateByInput(e)}
            onChange={e => this.setStartDateByInput(e)}
            onFocus={e => this.setCalendarFocusToGreen1(e)}
          />
        </div>

        <div className="item item--4">-</div>

        <div className={classnames({
          onFocus: showFocus
        })}>
          <Calendar
            className={classnames("item item--5", {
              onFocus: showFocus
            })}
            maxDate={new Date()}
            required
            //disabled={disableCalendar}
            readOnlyInput={true}
            showOnFocus={false}
            value={end && end}
            name="dateRangeTo"
            dateFormat="dd/mm/yy"
            onChange={e => this.setEndDateByInput(e)}
            onFocus={e => this.setCalendarFocusToGreen2(e)}
          />
        </div>
      </React.Fragment>
    );
  };

  renderPreviousTimePeriod = () => {
    const { maxDate, previousPeriod, dates, comparePeriods } = this.state;
    const disableCalendar = previousPeriod === 'custom' ? false : true;

    let start = '',
      end = '';

    if (dates && Array.isArray(dates) && dates.length === 2) {
      start = dates[dateIndexSelection.SECOND].startDate;
      end = dates[dateIndexSelection.SECOND].endDate;
      /**
        * Calendar is not updated according when props.value is changed
        * source: https://github.com/primefaces/primereact/issues/110
        */
      end = new Date(end);
    }

    const showFocus = this.state.onFocusSecondSelection;

    return (
      <React.Fragment>
        <div className={classnames({
          onFocus: showFocus
        })}>
          <Calendar
            className={classnames("item item--7", {
              onFocus: showFocus
            })}
            showOnFocus={false}
            maxDate={maxDate}
            required
            //disabled={disableCalendar}
            readOnlyInput={true}
            value={start && start}
            name="compareDateRangeFrom"
            dateFormat="dd/mm/yy"
            onChange={ (e) => this.setStartDateSecondPeriod(e)}
            onFocus={e => this.setCalendarFocusToRed1(e)}
          />
        </div>

        <div className="item item--8">-</div>

        <div className={classnames({
          onFocus: showFocus
        })}>
          <Calendar
            className={classnames("item item--9", {
              onFocus: showFocus
            })}
            maxDate={maxDate}
            showOnFocus={false}
            required
            readOnlyInput={true}
            //disabled={disableCalendar}
            value={end && end}
            name="compareDateRangeTo"
            dateFormat="dd/mm/yy"
            onChange={ (e) => this.setEndDateSecondPeriod(e)}
            onFocus={e => this.setCalendarFocusToRed2(e)}
          />
        </div>
      </React.Fragment>
    );
  };

  stopPropagation = e => {
    e.stopPropagation && e.stopPropagation();
  };

  render() {
    const { isHidden, dates } = this.state;
    const oneMonthsSubtracted = moment(dates[dateIndexSelection.FIRST].startDate).subtract(1, 'M');
    
    return (
      <div className="dateRangePicker" onClick={this.stopPropagation}>
        <div
          className={classnames('dateRangePicker--box', {
            hideDateRangePicker: isHidden,
            openDateRangePicker: !isHidden
          })}
        >
          <DateRangePicker
            ranges={this.state.dates}
            months={2}
            className={this.state.isHidden ? 'hideDateRangePicker' : 'open--dateRangePicker'}
            maxDate={this.state.maxDate}
            onChange={this.onSelect}
            direction="horizontal"
            rangeColors={['#46b29d', '#f53855']}
            focusedRange={this.state.focusRange}
            locale={enGb}
            shownDate={new Date(oneMonthsSubtracted)}
          />

          <div className={classnames('timePeriod', { hideDateRangePicker: isHidden, openDateRangePicker: !isHidden })}>
            <div className="item item--1">TIME PERIOD</div>

            {this.renderDropdownCurrentPeriod()}

            {this.renderCurrentTimePeriod()}

            <div className="item item--6">
              <input
                className="item--6--checkbox"
                id="compare"
                name="compare"
                type="checkbox"
                checked={this.state.comparePeriods}
                onChange={this.onComparePeriodsChange}
              />
              <label htmlFor="compare" className="item--6--text">
                COMPARE TO
              </label>

              {this.renderDropdownPreviousPeriod()}
            </div>

            {this.renderPreviousTimePeriod()}

            <input
              type="button"
              className="item item--10 btn btn--compare"
              value="FILTER DATA"
              onClick={() => this.setCompareDates()}
            />
          </div>
        </div>
      </div>
    );
  };
}
