import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as rewardActions from '../../../../actions/admin/rewardActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

import config from '../../../../config/config';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    reward: store.adminReward.reward,
    rewardEdit: store.adminReward.rewardEdit,
  };
})



class RewardEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      rewardDetails = {
        _id: '',
        clientId:'',        
        name:'',
        giftCardReward: false,
        type: '',
        meta: {}   
      }
    } = props;

    this.state = {
      rewardDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };
  }

  onChange(name, {target}) {
    const { rewardDetails } = this.state;
    _.set(rewardDetails, name, (target.type === 'checkbox') ? target.checked : target.value);
    this.setState({ rewardDetails });
  }

  componentWillMount() {
    var rewardId = this.props.params.rewardId;    
    if(rewardId){
      this.props.dispatch(rewardActions.getReward(rewardId));
    }
  }

  componentDidMount(){
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New reward added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.reward){
      this.setState({ rewardDetails : nextProps.reward});
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.rewardEdit !=nextProps.rewardEdit){
      if(_.get(nextProps,'rewardEdit.name')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Reward saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'rewardEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.rewardObject.data.message,
          timeout: 10000
        }));
      }
    }

    
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { rewardDetails, errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){ this.props.dispatch(rewardActions.updateReward(rewardDetails)); }
        
  }

  validateForm() {
    const { rewardDetails, errors } = this.state;

    errors[ 'clientId' ] = validator.isEmpty(rewardDetails.clientId);
    errors[ 'name' ] = validator.isEmpty(rewardDetails.name);
    errors[ 'type' ] = validator.isEmpty(rewardDetails.type);
    
    return this.setState({ errors });
  }

  render (){
    const { rewardDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      _id,
      clientId,
      name,
      giftCardReward,
      type, 
      meta
    } = rewardDetails;

    return (
      <div className="reward-new-reward">
        <h3>Edit reward</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Client Id'
            name='clientId'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors.clientId}
            id='form-clientId'
            disabled='true'
          />

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />


          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            error={errors.type}
            id='form-type'
            required
          >
            <option value=""> </option>
            <option value="discount">discount</option>
            <option value="batch-discount">batch-discount</option>
          </FormElement>

          <FormElement
            label='Gift Card'
            name='giftCardReward'
            type='checkbox'
            onChange={this.onChange.bind(this, 'giftCardReward')}
            checked={giftCardReward}
            error={errors.giftCardReward}
            id='form-active'
          />


          <FormElement
            label='meta'
            type='codearea'
            mode='javascript'
            className='client-meta'
            onChange={this.onChange.bind(this, 'meta')}
            value={JSON.stringify(meta)}
          >
          </FormElement>
        </form>

        <br />
        <button onClick={()=>this.onSubmit()}>Edit</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/reward')}>
          Back
        </button>
      </div>
    );
  }
}

export default RewardEdit;