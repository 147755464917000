import request from '../wrappers/axios';
import config from '../../config/config';

class CampaignVersionService {

  getCampaignVersionList(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/campaignVersion/list${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  newCampaignVersion (data) {
    return request({
      url: `${config.API.BASEURL}/campaignVersion`,
      method: 'post',
      data: data
    }, true, 500);
  }

  updateCampaignVersion (data) {
    return request({
      url: `${config.API.BASEURL}/campaignVersion/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  updateCampaignVersionList (data){
    return request({
      url: `${config.API.BASEURL}/campaignVersion`,
      method: 'patch',
      data: data
    }, true, 500);
  }

  getCampaignVersion (campaignVersionId) {
    return request({
      url: `${config.API.BASEURL}/campaignVersion/${campaignVersionId}`,
      method: 'get'
    });
  }

  getAllCampaignVersions (clientId) {
    return request({
      url: `${config.API.BASEURL}/campaignVersion/all?campaignId=${clientId}`,
      method: 'get'
    });
  }
  copyCampaignVersion (data){
    return request({
      url: `${config.API.BASEURL}/campaignVersion/${data._id}/copyDeep`,
      method: 'post',
      data: data
    }, true, 500);
  }

  simulatePostReward(campaignVersionId, userEmail, postRewardType){

    let url =  new URL(`${config.API.BASEURL}/postreward/simulate`);

    url.searchParams.append('campaignVersionId', campaignVersionId);
    url.searchParams.append('userEmail', userEmail);
    url.searchParams.append('postRewardType', postRewardType);

    return request(
      {
        url: url.toString(),
        method: 'post'
      }
    );
  }

  copyCampaignVersionTemplate (data){
    return request({
      url: `${config.API.BASEURL}/campaignVersion/saas/copyTemplate`,
      method: 'post',
      data: data
    }, true, 500);
  }

  getCampaignVersionAssets (campaignId){
    return request({
      url: `${config.API.BASEURL}/campaignVersion/saas/assets/${campaignId}`,
      method: 'get'
    }, true, 500);
  }
}

export default new CampaignVersionService();