import constants from '../../constants/app-constants';

const initialState = {};

const Order = (state = initialState, action) => {
  switch (action.type) {
  case constants.admin.ORDER_GET_ALL: {
    return { ...state, orderGetAll: action.payload };
  }

  case constants.admin.ORDER_EDIT: {
    return { ...state, orderEdit: action.payload };
  }

  default:
    return state;
  }
};

export default Order;
