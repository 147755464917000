import RewardList from './List';
import RewardCreate from './Create';
import RewardEdit from './Edit';

const RewardIndex = {
  RewardList, 
  RewardCreate,
  RewardEdit
};

export default RewardIndex;