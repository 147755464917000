var objectHelper = {
  getObjectClone: function(obj){
    try{
      return JSON.parse(JSON.stringify(obj));
    }catch(err) {
      console.log(err);
      return {};
    }
  },
  changeObjectKeysToCamelCase: function(obj){
    var newObj = {};
    _.map(obj ,function(value , key){
      key = key.replace(/([A-Z])/g, function($1){return '_'+$1.toLowerCase();})
      newObj[key] = value;
    })

    return newObj;
  }
};

export default objectHelper;