import keyEmailTemplateTypeService from '../../utils/services/keyEmailTemplateType';
import constants from '../../constants/app-constants';

export const getKeyEmailTemplateTypeById = (id) => {
  return function(dispatch){
    keyEmailTemplateTypeService.getKeyEmailTemplateTypeById(id)
      .then(function(res){
        dispatch({
          type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_GET_BY_ID,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_GET_BY_ID,
          payload: err.response
        });
      });
  };
};

export const create = (emailTemplate) => {
  return function(dispatch){
    return new Promise(function(resolve) {
      keyEmailTemplateTypeService.newKeyEmailTemplateType(emailTemplate)
        .then(function(res){
          dispatch({
            type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_NEW,
            payload: res.data
          });
          resolve(res.data);
        })
        .catch(function(err){
          dispatch({
            type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_NEW,
            payload: err.response
          });
        });
    });
  };
};

export const update = (emailTemplate) => {
  return function(dispatch){
    return new Promise(function(resolve) {
      keyEmailTemplateTypeService.updateKeyEmailTemplateType(emailTemplate)
        .then(function(res){
          dispatch({
            type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_UPDATE,
            payload: res.data
          });
          resolve(res.data);
        })
        .catch(function(err){
          dispatch({
            type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_UPDATE,
            payload: err.response
          });
        });
    });
  };
};

export const getKeyEmailTemplateType = (query) => {
  return function(dispatch){
    return new Promise(function(resolve) {
      keyEmailTemplateTypeService.getKeyEmailTemplateType(query)
        .then(function(res){
          dispatch({
            type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_GET,
            payload: res.data
          });
          resolve(res.data);
        })
        .catch(function(err){
          dispatch({
            type: constants.admin.KEY_EMAIL_TEMPLATE_TYPE_GET,
            payload: err.response
          });
        });
    });
  };
};