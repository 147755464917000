import React from 'react';
import classnames from 'classnames';
import clientService from '../../../utils/services/client';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import commonFunctions from '../../../utils/helpers/CommonFunctions';

export default class ClientsDropbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: this.props.value ? this.props.value : null,
      clients: []
    };

    this.getClients = this.getClients.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = () => {
    this.getClients();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.value != this.props.value) {
      if (typeof this.props.value === 'object') {
        this.setState({ client: null });
      } else if (typeof this.props.value === 'string') {
        this.setState({ client: this.props.value });
      }
    }
  };

  getClients = () => {
    clientService.getAllClients().then(data => {
      data = commonFunctions.sortData(data, { value: 'name' });
      data = commonFunctions.suppressArraySequencialFieldValueRepetition(data, '_id');
      const clients = data.map(client => ({
        label: client.name,
        name: client.name,
        value: client._id,
        imageUrl: client.imageUrl ? client.imageUrl : '/images/logo.png',
        disabled: client.disabled
      }));
      this.setState({ clients });
    });
  };

  clientTemplate = option => {
    return (
      <div
        className="ui-helper-clearfix"
        style={{ width: '250px', backgroundColor: option.disabled && option.disabled == 'true' ? 'red' : 'white' }}
      >
        <img
          alt={option.name}
          src={option.imageUrl}
          style={{ display: 'inline-block', margin: '5px 0 0 5px' }}
          width="24"
          onError={e => {
            e.target.src = '/images/logo.png';
          }}
        />
        <span style={{ float: 'right', margin: '.5em .25em 0 0 ' }}>{option.name}</span>
      </div>
    );
  };

  onChange = e => {
    this.setState({ client: e.value });

    if(this.state.clients){
      let selectedClient = this.state.clients.find(c => c.value == e.value);

      if(selectedClient){
        e.clientName = selectedClient.name;
      }
    }

    this.props.onChange(e);
  };

  render() {
    const { label, required, className, error, flex, inline, disabled, clientId } = this.props;

    const cx = classnames('c-form-element', className, {
      'c-form-element__error': error,
      'c-form-element__flex': flex,
      'c-form-element--inline': inline
    });

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {required && <span className="u-color-pink">&nbsp;*</span>}
        </label>
      );
    }

    return (
      <div className={cx}>
        {showlabel}

        <Dropdown
          value={clientId ? clientId : this.state.client}
          options={this.state.clients}
          disabled={disabled}
          showClear
          onChange={this.onChange}
          itemTemplate={this.clientTemplate}
          style={{ marginLeft: '5px', width: '300px', marginTop: '4px' }}
          panelStyle={{ width: '300px' }}
          filter={true}
          filterPlaceholder="Select Client"
          filterBy="name,value"
          placeholder="Select a Client"
        />
      </div>
    );
  }
}
