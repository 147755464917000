import React from 'react';
import AcrossTabs from 'across-tabs';



class PreviewPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      html: '',
      errors: {},
    };
  }

  parent = new AcrossTabs.Parent({
    onHandshakeCallback: function (cb) { console.log('item',cb);},
  });

  componentDidMount() {
    this._updateIframe();
  }

  componentDidUpdate() {
    this._updateIframe();
  }

  toggleWindowPortal(){
    var self = this;
    
    this.parent.openNewTab({url: '/previewpopup.html', windowFeatures:'location=yes,height=570,width=520', windowName: 'AcrossTab'});

    setTimeout(function(){ self._updateIframe(); }, 500);

  }

  _updateIframe() {
    this.parent.broadCastAll(this.props.htmlContent);
  }

  render() {
    return <button style={{marginTop: '15px'}} onClick={() => this.toggleWindowPortal()} type="button">
      Preview popup
    </button>;
  }
}

export default PreviewPopup;