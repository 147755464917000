import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';
import sortHelper from '../../utils/helpers/sortHelper';
import userHelper from '../../utils/helpers/user';

export const getUserActivity = (userId , query) => {
  return function(dispatch){
    userService.getUserActivity(userId , query).then(function(res){
      dispatch({
        type: constants.user.ACTIVITY_POPULATE,
        payload: sortHelper.sortResults(userHelper.getActivity(res.data) , 'desc' , 'createdAt')
      });
    });
  };
};

export const updateUserActivity = (activity) => {
  return{
    type: constants.user.ACTIVITY_POPULATE,
    payload: activity
  };
};