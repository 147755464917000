import request from '../wrappers/axios';
import config from '../../config/config';

class CurrencyService {

  get(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/currency${extendedQuery}`,
      method: 'get',
      params: query
    }, true, 500);
  }

  new (data) {
    return request({
      url: `${config.API.BASEURL}/currency`,
      method: 'post',
      data: data
    }, true, 500);
  }

  update (data) {
    return request({
      url: `${config.API.BASEURL}/currency/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  delete (id) {
    return request({
      url: `${config.API.BASEURL}/currency/${id}`,
      method: 'delete'
    }, true, 500);
  }
}

export default new CurrencyService();