import constants from '../../constants/app-constants';

export const toggleHeaderMenu = headerMenuVisible => {
  return {
    type: constants.client.HEADER_TOGGLEMENU,
    headerMenuVisible: headerMenuVisible == '' ? 'show' : ''
  };
};

export const closeHeaderMenu = () => {
  return {
    type: constants.client.HEADER_TOGGLEMENU,
    payload: ''
  };
};

export const toggleCalendar = toggleCalendar => {
  return {
    type: constants.client.TOGGLE_CALENDAR,
    payload: toggleCalendar
  };
};
