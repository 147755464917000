import React from 'react';

class SettingsAssociatedManagers extends React.Component{

  render(){
    return (
      <div>AssociatedManagers</div>
    );
  }
}

export default SettingsAssociatedManagers;