import request from '../wrappers/axios';
import config from '../../config/config';

class AssociateEmailToCampaignVersionService {

  getAllAssociateEmailToCampaignVersion(query , extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/associateEmailToCampaignVersion/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }
  
  getAssociateEmailToCampaignVersionById(id) {
    return request({
      url: `${config.API.BASEURL}/associateEmailToCampaignVersion/${id}`,
      method: 'get'
    });
  }

  updateAssociateEmailToCampaignVersion(data) {
    return request({
      url: `${config.API.BASEURL}/associateEmailToCampaignVersion`,
      method: 'patch',
      data: data
    }, true, 500);
  }
  
  newAssociateEmailToCampaignVersion(data) {
    return request({
      url: `${config.API.BASEURL}/associateEmailToCampaignVersion`,
      method: 'post',
      data: data
    }, true, 500);
  }
}

export default new AssociateEmailToCampaignVersionService();