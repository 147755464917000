import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ColumnGroup} from 'primereact/components/columngroup/ColumnGroup';
import {Row} from 'primereact/components/row/Row';
import {Button} from 'primereact/components/button/Button';
import {InputText} from 'primereact/components/inputtext/InputText';
import moment from 'moment';
import _ from 'lodash';

import reportServiceService from '../../../../../utils/services/reports';
import FormElement from '../../../../shared/form/FormElement';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

import { withClientStats } from '../../../../../decorators/clientStatsDecorator/clientStats-decorator-bi';
import AffiliateDropbox from '../../../../shared/dropbox/AffiliateDropbox';
import ResponsibleDropbox from '../../../../shared/dropbox/ResponsibleDropbox';

@withClientStats
export default class ClientListStats extends React.Component {

  constructor() {
    super();

    const affiliateDetails = {
        affiliateId: '',
        name: '',
        label: ''
    };

    this.state = {
      campaignStats: [],
      startDate: moment().subtract(7,'d').toDate(),
      endDate: moment().subtract(1,'d').toDate(),
      showInactiveDays:true,
      showDaily:false,
      showCampaignLevel:false,
      showCampaignVersionLevel:false,
      showActiveClients: 'true',
      searchField: '',
      cols: [],
      colsFooter: [],
      first: 0,
      rows: 1000,
      affiliateDetails,
      responsibleId: null
    };

    this.cellTemplate = this.cellTemplate.bind(this);
    this.onAffiliateChange = this.onAffiliateChange.bind(this);
    this.onResponsibleChange = this.onResponsibleChange.bind(this);    
  }

  componentDidMount() {
  }

  resetFilter = (event) => {
    this.setState({
      startDate: moment().subtract(7,'d').toDate(),
      endDate: moment().subtract(1,'d').toDate(),
      showInactiveDays:true,
      showDaily:false,
      showCampaignLevel: false,
      showCampaignVersionLevel: false,
      showActiveClients:true,
      searchField: '',
      affiliateDetails: { affiliateId: '', name: '',label: '' },
      responsibleId: null
    }, () => this.onLazyLoad(event));
  }

  getQuery(event) {
    var first = this.state.first;
    var rows = this.state.rows;
    if(_.isNumber(event.first)){
      first = event.first;
      this.setState({ first : first});
    }
    if(_.isNumber(event.rows)){
      rows = event.rows;
      this.setState({ rows: rows });
    }

    var query = {
      $offset: first,
      $limit: rows
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : `*${this.state.searchField}*` };
      query = Object.assign(query ,value);
    }
    
    var gteDate  = this.state.startDate ? {$date_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$date_$lte: moment(this.state.endDate).format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);
1
    query = Object.assign(query, { daily: this.state.showDaily });
    query = Object.assign(query, { showInactiveDays: this.state.showInactiveDays });
    query = Object.assign(query, { showCampaignLevel: this.state.showCampaignLevel });
    query = Object.assign(query, { showCampaignVersionLevel: this.state.showCampaignVersionLevel });
    
    if(Boolean(this.state.showActiveClients)){
      query = Object.assign(query, { showActiveClients: this.state.showActiveClients });
    }

    if(this.state.affiliateDetails && this.state.affiliateDetails.affiliateId) {
      query = Object.assign(query , {affiliateId: this.state.affiliateDetails.affiliateId });
    }

    if(this.state.responsibleId){
      query = Object.assign(query, {responsibleId: this.state.responsibleId});
    }

    return query;
  }

  getExtendedQuery(event){
    if(event.multiSortMeta){
      this.setState({ multiSortMeta: event.multiSortMeta});
    }
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        if((this.state.showDaily || this.state.showDaily == false && sortMeta.field != 'date')
          && (this.state.showCampaignLevel || this.state.showCampaignLevel == false && sortMeta.field != 'campaignName')
          && (this.state.showCampaignVersionLevel || this.state.showCampaignVersionLevel == false && sortMeta.field != 'campaignVersionName')
        ) {
          var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
          extendedQuery = extendedQuery + sortField;
        }
      }
    }
    return extendedQuery;
  }

  formatData(result) {

    this.totals = this.setClientTotalStats({ data : {page : result.data.data } });

    this.setState({
      campaignStats: result.data.data,
      totalRecords: result.data.metaData.totalResults,
      loading: false,
      cols: this.columns(this.state),
      colsFooter: this.columnsFooter(this.state, this.totals)
    });
  }

  onLazyLoad = (event) => {
    this.setState({ loading: true, first: 0});

    reportServiceService.getLiveStats(this.getQuery(event), this.getExtendedQuery(event)).then((result) => {
      this.formatData(result);
    });
  }

  onChangeCheckbox = (e) => {
    const name = e.target.name;
    this.setState({ [name] : e.target.checked });
  }

  cellTemplate = (rowData, column) => {
        
    if(rowData.refClicksUntracked && rowData.refClicksUntracked > 0){
      return <span title={'Unallocated: ' + rowData.refClicksUntracked + ' added to this point for Report purpose'}><b>{rowData.refClicks}</b></span>
    }else{
      return <span>{rowData.refClicks}</span>
    }
  }

  onAffiliateChange(e) {
    const { affiliateDetails } = this.state;
    const id = _.get(e, 'value.value') ? e.value.value : undefined;
    const name = _.get(e, 'value.name') ? e.value.name : undefined;
    const label = _.get(e, 'value.label') ? e.value.label : undefined;
    
    _.set(affiliateDetails, 'affiliateId', id);
    _.set(affiliateDetails, 'name', name);
    _.set(affiliateDetails, 'label', label);

    this.setState({ affiliateDetails });
  }

  onResponsibleChange(e) {
    this.setState({responsibleId: e.value});
  }

  render() {
    
    const { affiliateDetails, responsibleId } = this.state;

    const { 
      affiliateId, 
      name, 
      label
    } = affiliateDetails;

    let columnsFooter = this.state.colsFooter.map((col,i) => {
      return <Column key={i} footer={col.footer} colSpan={col.colSpan} />;
    });
    let footerGroup = <ColumnGroup>
      <Row>
        {columnsFooter}
      </Row>
    </ColumnGroup>;


    let columns = this.state.cols.map((col,i) => {

      if(col.field !== 'refClicks'){
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} />;
      }
      else{
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} body={this.cellTemplate} />;
      }
    });

    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };
    
    const clientsActiveSelectItems = [
      {label: 'All', value: ''},
      {label: 'Active', value: 'true'},
      {label: 'Inactive', value: 'false'},      
    ];

    return (
      <div>

        <div className="content-section implementation">

          <h3>Client Statistics</h3>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-7 ui-md-2">
                <span>Show Inactive Days:</span>
              </div>
              <div className="ui-g-1 ui-md-4">
                <span>
                  <input
                    name="showInactiveDays"
                    type="checkbox"
                    checked={this.state.showInactiveDays}
                    onChange={this.onChangeCheckbox} />
                </span>
              </div>
            </div>
            <div className="ui-g">
              <div className="ui-g-7 ui-md-2">
                <span>Show daily:</span>
              </div>
              <div className="ui-g-1 ui-md-4">
                <span>
                  <input
                    name="showDaily"
                    type="checkbox"
                    checked={this.state.showDaily}
                    onChange={this.onChangeCheckbox} />
                </span>
              </div>
            </div>
            <div className="ui-g">
              <div className="ui-g-7 ui-md-2">
                <span>Show campaign level:</span>
              </div>
              <div className="ui-g-1 ui-md-4">
                <span>
                  <input
                    name="showCampaignLevel"
                    type="checkbox"
                    checked={this.state.showCampaignLevel}
                    onChange={this.onChangeCheckbox} />
                </span>
              </div>
            </div>
            <div className="ui-g">
              <div className="ui-g-7 ui-md-2">
                <span>Show AB test level:</span>
              </div>
              <div className="ui-g-1 ui-md-4">
                <span>
                  <input
                    name="showCampaignVersionLevel"
                    type="checkbox"
                    checked={this.state.showCampaignVersionLevel}
                    onChange={this.onChangeCheckbox} />
                </span>
              </div>
            </div>
            <div className="ui-g">
              <div className="ui-g-7 ui-md-2">
                <span>Show Active clients:</span>
              </div>
              <div className="ui-g-1 ui-md-1" style={{width:'100px', display: 'flex'}}>              
                <Dropdown 
                  value={this.state.showActiveClients} 
                  options={clientsActiveSelectItems} 
                  onChange={(e) => {this.setState({showActiveClients: e.value})}} 
                  placeholder="All"/>
              </div>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Date range:</span>
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateFrom'
                  type='calendar'
                  onChange={(e) => this.setState({startDate: e.value})}
                  value={this.state.startDate}
                  id='dateFrom'
                  required
                  style={calendarStyle}
                />
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateTo'
                  type='calendar'
                  onChange={(e) => this.setState({endDate: e.value})}
                  value={this.state.endDate}
                  id='dateTo'
                  required
                  style={calendarStyle}
                />
              </div>
            </div>
          </div>
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Search:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <InputText onChange={(e) => this.setState({ searchField: e.target.value })} value={this.state.searchField}/>
              </span>
            </div>
          </div>

          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Network:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
                <AffiliateDropbox value={affiliateId} filterPlaceholder="Select Network" showInternalOption="true" onChange={this.onAffiliateChange} overwriteStyle={ { marginLeft: '0px', width:'300px', marginTop: '0px' } } />  
              </span>
            </div>
          </div>
          
          <div className="ui-g">
            <div className="ui-g-10 ui-md-2">
              <span>Responsible:</span>
            </div>
            <div className="ui-g-10 ui-md-10">
              <span>
              <ResponsibleDropbox value={responsibleId} onChange={ e => this.onResponsibleChange(e)}  overwriteStyle={ { marginLeft: '0px', width:'300px', marginTop: '0px' } } />
              </span>
            </div>
          </div>

          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
            </div>
          </div>

          <DataTable
            value={this.state.campaignStats}
            responsive={true}
            sortMode="multiple"
            paginator={true}
            rows={1000}
            loading={this.state.loading}
            rowsPerPageOptions={[5,10,20,100,500,1000]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            onLazyLoad={this.onLazyLoad}
            //header={header}
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter}
            footerColumnGroup={footerGroup}
            first = {this.state.first}>
            { columns }
          </DataTable>
        </div>
      </div>
    );
  }
}
