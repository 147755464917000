import constants from '../../constants/app-constants';

const initialState = {}

const ClientStats = (state = initialState , action) => {
  switch(action.type){

  case constants.client.CLIENT_STATS_GET : {
    return {...state, clientStatsGet: action.payload };
  }

  default:
    return state;
  }
};

export default ClientStats