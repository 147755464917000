import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import moment from 'moment';

import affiliateService from '../../../../utils/services/affiliate';
import FormElement from '../../../shared/form/FormElement'; 

export default class AffiliateList extends React.Component {

  constructor() {
    super();
    this.state = {
      affiliateList: [],
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
      first:0
    };

    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.export = this.export.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onCreateAffiliate = this.onCreateAffiliate.bind(this);
    this.onEditAffiliate = this.onEditAffiliate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  export() {
    this.dt.exportCSV();
  }

  componentDidMount() {
  }

  resetFilter(event) {
    this.setState({
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  getQuery(event) {

    let first = this.state.first;

    var query = {
      $offset: first,
      $limit: event.rows
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }

    var gteDate  = this.state.startDate ? {$createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$createdAt_$lte: moment(this.state.endDate).add(1,'d').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
  
    for(let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');  
    }
      
    this.setState({
      affiliateList: data.data.page,//this.datasource.slice(event.first, (event.first + event.rows)),
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});
    
    affiliateService.getAffiliateList(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });
    
  } 

  onCreateAffiliate(){
    this.props.router.push({ pathname: '/admin/affiliate/create'});
  }

  onEditAffiliate(e,rowData){
    this.props.router.push({ pathname: '/admin/affiliate/' + rowData._id });
  }

  actionTemplate(rowData, column) {
    return <div>
      <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={(e) => {this.onEditAffiliate(e, rowData);}}> </Button>
    </div>;
  }

  render() {

    return (
      <div>
        <div className="content-section implementation">

          <h3>Affiliates</h3>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Create Affiliate" onClick={this.onCreateAffiliate} />
              </div>
            </div>
          </div>

          <DataTable value={this.state.affiliateList} responsive={true} sortMode="multiple" paginator={true} rows={10} rowsPerPageOptions={[5,10,20,100,500,1000]} totalRecords={this.state.totalRecords}
            lazy={true} onLazyLoad={this.onLazyLoad}
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter} first = {this.state.first}>
            <Column field="name" header="name" sortable={true} style={{width:'50px'}} />
            <Column field="module" header="Module" sortable={true} style={{width:'90px'}} />
            <Column field="imageUrl" header="Image url" sortable={true} style={{width:'80px'}} />
            <Column field="createdAt" header="Created" sortable={true} style={{width:'50px'}} />
            <Column field="updatedAt" header="Updated" sortable={true} style={{width:'50px'}} />
            <Column body={this.actionTemplate} style={{textAlign:'center', width: '30px'}}/>
          </DataTable>
        </div>
      </div>
    );
  }
}