import request from '../wrappers/axios';
import config from '../../config/config';

class DemoStoreService {

  getDemoStores(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/demoStore/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getDemoStoreById(id){
    return request({
      url: `${config.API.BASEURL}/demoStore/${id}`,
      method: 'get'
    });
  }


  updateDemoStore(data){
    return request({
      url: `${config.API.BASEURL}/demoStore`,
      method: 'patch',
      data: data
    }, true, 500);
  }

  newDemoStore(data){
    return request({
      url: `${config.API.BASEURL}/demoStore`,
      method: 'post',
      data: data
    }, true, 500);
  }

}

export default new DemoStoreService();
