import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import validator from 'validator';

import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';

import FormElement from '../../../shared/form/FormElement';
import * as formActions from '../../../../actions/admin/emailTemplateTypeActions';
import * as keyEmailTemplateTypeActions from '../../../../actions/admin/keyEmailTemplateTypeActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

export class EmailTemplateEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      formValues = {
        value: '',
        name:'',
      }
    } = props;

    this.state = {
      formValues,
      keys:[],
      errors: {},
      displayErrorMessage: false
    };

    this.save = this.save.bind(this);
    this.onKeySelect = this.onKeySelect.bind(this);
    this.addNew = this.addNew.bind(this);

  }

  componentDidMount() {
    const emailTemplateId = this.props.params.emailTemplateId;
    this.setState({ emailTemplateId: emailTemplateId });
    if (emailTemplateId) {
      this.props.dispatch(formActions.getById(emailTemplateId))
        .then((object)=>{
          return this.props.dispatch(keyEmailTemplateTypeActions.getKeyEmailTemplateType({'$emailTemplateTypeId_$':object._id}));
        })
        .then((keys)=>{
          this.setState({keys:keys});
        })
      ;
    }

    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New email template type added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.emailTemplatetypeGet) {
      this.setState({ formValues : nextProps.emailTemplatetypeGet });
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.emailTemplateTypeEdit != nextProps.emailTemplateTypeEdit){
      
      if(_.get(nextProps,'emailTemplateTypeEdit')){
        browserHistory.push('/admin/emailTemplateType');
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Email Template saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'emailTemplateTypeEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.emailTemplateTypeEdit.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onChange(name, {target}) {
    const { formValues } = this.state;
    _.set(formValues, name, target.value);
    this.setState({formValues});
  }

  onSubmit(){

    this.setState({ displayErrorMessage: false });
    const { formValues,errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if(!checkErrors){
      this.props.dispatch(formActions.update(formValues));
    }
  }

  validateForm() {
    const { formValues, errors } = this.state;

    errors[ 'name' ] = validator.isEmpty( formValues.name );
    errors[ 'value' ] = validator.isEmpty( formValues.value );
    return this.setState({ errors });
  }

  validateSubForm() {
    let { key, errors } = this.state;

    errors[ 'templateKey' ] = validator.isEmpty( key.templateKey ? key.templateKey : '');
    errors[ 'label' ] = validator.isEmpty( key.label ?  key.label : '');
    errors[ 'inputType' ] = validator.isEmpty( key.inputType ? key.inputType : '');

    return this.setState({ errors });
  }


  save() {
    this.validateSubForm();
    const {key , keys, errors } = this.state;
    
    let checkErrors = false;

    for( const i in errors ) {
      if( errors[i] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if(!checkErrors){
      
      if(this.newKey){
        key.emailTemplateTypeId = this.props.params.emailTemplateId;
        
        this.props.dispatch(keyEmailTemplateTypeActions.create(key)).then((key)=>{
          keys.push(key);
          this.setState({keys:keys, selectedKey:null, key: null, displayDialog:false});
        });
      }
      else{
        this.props.dispatch(keyEmailTemplateTypeActions.update(this.state.key)).then((key)=>{
          keys[this.findSelectedKeyIndex()] = key;
          this.setState({keys:keys, selectedKey:null, key: null, displayDialog:false});
        });
      }
    }

  }

  findSelectedKeyIndex() {
    return this.state.keys.indexOf(this.state.selectedKey);
  }

  updateProperty(property, value) {
    let key = this.state.key;
    key[property] = value;
    this.setState({key: key});
  }

  updatePropertyForceCapital(property, value) {
    let key = this.state.key;
    key[property] = value.toUpperCase();
    this.setState({key: key});
  }

  updatePropertyBooleanDropDown(property, value) {
    let key = this.state.key;
    key[property] = value === 'false' || '' ? false : true;
    this.setState({key: key});
  }


  onKeySelect(e){
    this.newKey = false;
    this.setState({
      selectedKey:e.data,
      displayDialog:true,
      key: Object.assign({}, e.data)
    });
  }

  addNew() {
    this.newKey = true;
    this.setState({
      key: {
        templateKey:'',
        defaultValue:'',
        label:'',
        required:false,
        inputType:'text'
      },
      displayDialog: true
    });
  }

  render (){
    const { formValues = { info: {} }, key , keys , errors } = this.state;

    let header = <div className="p-clearfix" style={{lineHeight:'1.87em'}}>Email variables </div>;

    let footer = <div  style={{display:'flex', width:'100%'}} className="p-clearfix">
      <Button style={{float:'left'}} label="Add" onClick={this.addNew}/>
    </div>;

    let dialogFooter = <div style={{display:'inline-block', width:'100%'}} className="ui-dialog-buttonpanel p-clearfix">
      <Button label="Save" onClick={this.save}/>
    </div>;

    const {
      name,
      value,
    } = formValues;


    return (
      <div>
        <h3>Edit Email Template</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-email-template-name'
            required
          />

          <FormElement
            label='Template Type'
            name='value'
            type='text'
            onChange={this.onChange.bind(this, 'value')}
            value={value}
            error={errors.value}
            id='form-email-template-value'
            required
          />


        </form>

        <br />
        <div style={{maxWidth:'800px'}} className="content-section implementation">
          <DataTable 
            value={keys}
            paginator={false}
            width="800px" header={header} 
            footer={footer}
            selectionMode="single"
            selection={this.state.selectedKey}
            onSelectionChange={e => this.setState({selectedKeyX: e.value})}
            onRowSelect={this.onKeySelect}
          >
            <Column field="inputType" header="Input Type" sortable={true} />
            <Column field="label"  header="Label" sortable={true} />
            <Column field="defaultValue" header="Default Value" sortable={true} />
            <Column field="required" body={(d)=>{ return d.required ? 'true':'false';}} header="Required" sortable={true} />
            <Column field="templateKey" style={{'overflow':'hidden','textOverflow': 'ellipsis', 'whiteSpace': 'nowrap'}} header="Template Key" sortable={true} />
          </DataTable>

          <Dialog visible={this.state.displayDialog}  header="Key Details" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
            {
              this.state.key &&        
                <div className="p-grid p-fluid">
                  <div className="p-col-8" style={{padding:'.5em'}}>
                    <FormElement
                      label='input type'
                      name='inputType'
                      type='select'
                      onChange={(e) => {this.updateProperty('inputType', e.target.value);}}
                      value={key.inputType}
                      error={errors.inputType}
                      id="input-type"
                      required
                    >
                      <option value='text'>text</option>
                      <option value='textArea'>textArea</option>
                      <option value='color'>color</option>
                    </FormElement>
                  </div>
                  <div className="p-col-8" style={{padding:'.5em'}}>
                    <FormElement
                      label='label'
                      name='name'
                      type='text'
                      onChange={(e) => {this.updateProperty('label', e.target.value);}}
                      value={key.label}
                      error={errors.label}
                      id="label"
                      required
                    />
                  </div>
                  <div className="p-col-8" style={{padding:'.5em'}}>
                    <FormElement
                      label='default value'
                      name='defaultValue'
                      type='text'
                      onChange={(e) => {this.updateProperty('defaultValue', e.target.value);}}
                      value={key.defaultValue}
                      error={errors.defaultValue}
                      id="Default Value"
                    />
                  </div>
                  <div className="p-col-8" style={{padding:'.5em'}}>
                    <FormElement
                      label='required'
                      name='required'
                      type="select"
                      onChange={(e) => {this.updatePropertyBooleanDropDown('required', e.target.value);}} 
                      value={key.required?true:false}
                      error={errors.required}
                      id="required"
                    >
                      <option value={true}>true</option>
                      <option value={false}>false</option>
                    </FormElement>
                  </div>
                  <div className="p-col-8" style={{padding:'.5em'}}>
                    <FormElement
                      label='template key'
                      name='templateKey'
                      type='text'
                      onChange={(e) => {this.updatePropertyForceCapital('templateKey', e.target.value);}}
                      value={key.templateKey}
                      error={errors.templateKey}
                      id="template-key"
                      required
                    />
                  </div>
                </div>
            }
          </Dialog>
        </div>

        <br />
        <button onClick={()=>this.onSubmit()}>Save</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/emailTemplateType')}>
          Back
        </button>
      </div>
    );
  }
}

export default connect((store) => {
  return { 
    emailTemplatetypeGet: store.adminEmailTemplateType.getById,
    emailTemplateTypeEdit: store.adminEmailTemplateType.edit
  };
})(EmailTemplateEdit);
