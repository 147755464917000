import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Panel } from 'primereact/components/panel/Panel';
import Label from '../../../shared/label/label';
import reportServiceService from '../../../../utils/services/reports';
import moment from 'moment';

export default class PanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      loading: true
    };
  }

  domainDisplay = rowData => {
    return <div style={{ wordWrap: 'break-word' }}>{rowData.key}</div>;
  };

  status = rowData => {
    let presentInWhiteList = this.props.whiteList 
      && this.props.whiteList.find(whiteListItem => rowData.key.includes(whiteListItem));

    let presentInBlackList = this.props.blackList 
      && this.props.blackList.find(blackListItem => rowData.key.includes(blackListItem));


    return (
      <React.Fragment>
        {!presentInWhiteList && !presentInBlackList ? (
          <Label color="grey" text={'Not mapped'} />
        ) : (
          <React.Fragment>
            {presentInWhiteList && (
              <Label color="white" text={'White Listed'} textColor="black" />
            )}
            {presentInBlackList && (
              <Label color="black" text={'Black Listed'} />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  onLazyLoad = event => {
    this.setState({ loading: true });
    let externalId = this.props.externalId;
    let internalId = this.props.internalId;
    const startDate = moment(new Date())
      .subtract(7, 'days')
      .format('YYYY-MM-DD');
    const endDate = moment(new Date()).format('YYYY-MM-DD');

    let query = { internalId, externalId, startDate, endDate };
    reportServiceService.getOriginDomains(query).then(response => {
      this.setState({
        resultData: JSON.parse(JSON.stringify(response.data)),
        loading: false
      });
    });

  };

  componentDidUpdate(prevProps){
    if(prevProps.update !== this.props.update){
      this.onLazyLoad();
    }
  }

  render() {
    return (
      <div className="content-section implementation">
        <Panel
          header={<span style={{ color: '#485465' }}>{this.props.name}</span>}
        >
          <DataTable
            value={this.state.resultData}
            responsive={true}
            sortMode="multiple"
            paginator={false}
            scrollable 
            scrollHeight="717px"
            lazy={true}
            onLazyLoad={this.onLazyLoad}
            ref={el => (this.dt = el)}
            loading={this.state.loading}
          >
            <Column
              // field="key"
              body={this.domainDisplay}
              header="Domain"
              // sortable={true}
              style={{ width: '80px' }}
            />
            <Column
              field="doc_count"
              header="Count"
              style={{ width: '40px' }}
            />
            <Column
              body={this.status}
              header="Mapping"
              style={{ width: '40px' }}
            />
          </DataTable>
        </Panel>
      </div>
    );
  }
}
