import React from 'react';
import {Doughnut} from 'react-chartjs-2';

class ChartRevenue extends React.Component {
  render () {
    return (
    <div>
      <Doughnut data={this.props.data} options={this.props.options} />
    </div>
    );
  }
}

export default ChartRevenue;