import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import * as associateEmailToCampaignVersionActions from '../../../../actions/admin/associateEmailToCampaignVersionActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';
import CampaignDropbox from '../../../shared/dropbox/CampaignDropbox';
import CampaignVersionDropbox from '../../../shared/dropbox/CampaignVersionDropbox';
import EmailTemplateDropbox from '../../../shared/dropbox/EmailTemplateDropbox';

@connect((store) => {
  return {
    associateEmailToCampaignVersionNew: store.adminAssociateEmailToCampaignVersion.associateEmailToCampaignVersionNew
  };
})
class AssociateEmailToCampaignVersionCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      associateEmailToCampaignVersionDetails = {
        clientId: null,
        campaignId: null,
        campaignVersionId: null,
        emailTemplateId: null
      }
    } = props;

    this.state = {
      associateEmailToCampaignVersionDetails,
      errors: {},
      displayErrorMessage: false
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onCampaignChange = this.onCampaignChange.bind(this);
    this.onCampaignVersionChange = this.onCampaignVersionChange.bind(this);
    this.onEmailTemplateChange = this.onEmailTemplateChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.associateEmailToCampaignVersionNew != nextProps.associateEmailToCampaignVersionNew){
      if(_.get(nextProps,'associateEmailToCampaignVersionNew.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.associateEmailToCampaignVersionNew.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onClientChange(e){
    this.setState({ clientId: e.value });
    const { associateEmailToCampaignVersionDetails } = this.state;
    _.set(associateEmailToCampaignVersionDetails, 'clientId', e.value);
    this.setState({ associateEmailToCampaignVersionDetails });
  }

  onCampaignChange(e){
    this.setState({ campaignId: e.value });
    const { associateEmailToCampaignVersionDetails } = this.state;
    _.set(associateEmailToCampaignVersionDetails, 'campaignId', e.value);
    this.setState({ associateEmailToCampaignVersionDetails });
  }

  onCampaignVersionChange(e){
    const { associateEmailToCampaignVersionDetails } = this.state;
    _.set(associateEmailToCampaignVersionDetails, 'campaignVersionId', e.value);
    this.setState({ associateEmailToCampaignVersionDetails });
  }

  onEmailTemplateChange(e){
    const { associateEmailToCampaignVersionDetails } = this.state;
    _.set(associateEmailToCampaignVersionDetails, 'emailTemplateId', e.value);
    this.setState({ associateEmailToCampaignVersionDetails });
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { associateEmailToCampaignVersionDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({ displayErrorMessage: true });
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      this.props.dispatch(associateEmailToCampaignVersionActions.newAssociateEmailToCampaignVersion(associateEmailToCampaignVersionDetails))
        .then(created => browserHistory.push({ pathname: `/admin/associateEmailToCampaignVersion/${created._id}` , state: { fromCreatePage: true } }));
    }
  }

  validateForm() {
    const { associateEmailToCampaignVersionDetails, errors } = this.state;

    errors[ 'clientId' ] = _.isNil(associateEmailToCampaignVersionDetails.clientId);
    errors[ 'campaignId' ] = _.isNil(associateEmailToCampaignVersionDetails.campaignId);
    errors[ 'campaignVersionId' ] = _.isNil(associateEmailToCampaignVersionDetails.campaignVersionId);
    errors[ 'emailTemplateId' ] = _.isNil(associateEmailToCampaignVersionDetails.emailTemplateId);

    return this.setState({ errors });
  }

  render (){
    const { associateEmailToCampaignVersionDetails = { info: {} }, errors } = this.state;

    const {
      clientId,
      campaignId,
      emailTemplateId
    } = associateEmailToCampaignVersionDetails;

    return (
      <div className="displayBlock-new-displayBlock">
        <h3>Create Associate Email to Campaign Version</h3>
        <form  className="c-form--flex">

          <ClientsDropbox
            label="Client"
            name="clientId"
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId}
          />

          <CampaignDropbox
            label="Campaign"
            name="campaignId"
            required='true'
            client={clientId}
            onChange={this.onCampaignChange}
            error={errors.campaignId}
          />

          <CampaignVersionDropbox
            label="Campaign Version"
            name='campaignVersionId'
            required
            campaign={campaignId}
            value={emailTemplateId}
            onChange={this.onCampaignVersionChange}
            error={errors.campaignVersionId}
          />

          <EmailTemplateDropbox
            label="Email Template"
            name='emailTemplateId'
            clientId={clientId}
            required
            onChange={this.onEmailTemplateChange}
            value={emailTemplateId}
            error={errors.emailTemplateId}
          />

        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Create</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default AssociateEmailToCampaignVersionCreate;