import React from 'react';
import SettingsMenu from './Settings.Menu';

class Settings extends React.Component{
  getSettingsMenu(){
    return [
      {
        title: 'Company',
        code: 'company',
        mobileIconClass: 'fa fa-industry'
      }//,
      // {
      //   title: 'Commission',
      //   code: 'commission',
      //   mobileIconClass: 'fa fa-percent'
      // },
      // {
      //   title: 'Share Popup',
      //   code: 'sharepopupcustom',
      //   mobileIconClass: 'fa fa-share-alt'
      // },
      // {
      //   title: 'Custom Tags',
      //   code: 'customtags',
      //   mobileIconClass: 'fa fa-tags'
      // }
    ];
  }

  render(){
    return (
      <div className="settings-page grid-100">
        <div className="menu-holder grid-25 tablet-grid-30 mobile-grid-20">
          <SettingsMenu menu={this.getSettingsMenu()} />
        </div>
        <div className="grid-75 tablet-grid-70 settings-content content mobile-grid-80">
          { this.props.children }
        </div>
      </div>
    );
  }

}

export default Settings;