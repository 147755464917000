import constants from '../constants/app-constants';

export const updateLoginCtx = (loginCtx) => {
  return {
    type: constants.auth.AUTH_LOGIN,
    payload: loginCtx
  };
};

export const logout = () => {
  return {
    type: constants.auth.LOGOUT,
    payload: null
  };
};

export const locationChangeEvent = () => {
  return {
    type: constants.LOCATION_CHANGE
  };
};

export const showLoadSpinner = (value) => {
  return {
    type: constants.LOAD_SPINNER_ENABLED,
    payload: value
  };
};