import List from './List';
import Create from './Create';
import Edit from './Edit';

const EmailTemplateTypeIndex = {
  List, 
  Create,
  Edit
};

export default EmailTemplateTypeIndex;