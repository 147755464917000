import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import _ from 'lodash';
import * as withdrawalActions from '../../../../actions/user/withdrawalActions';

import trackingService from '../../../../utils/services/tracking';

import moment from 'moment';

const withdrawalListModalStyle = {
  content : {
    'width': '40%',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height' : 'auto'
  }
};

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    withdrawalListModalIsOpen: store.userWithdrawal.withdrawalListModalIsOpen,
    withdrawals: store.userWithdrawal.withdrawals
  };
})

class WithdrawalList extends React.Component{

  componentWillMount(){
    this.props.dispatch(withdrawalActions.getWithdrawals(this.props.loginCtx._id));
  }

  cancelWithdrawal(requestId){
    this.props.dispatch(withdrawalActions.cancelWithdrawal(this.props.loginCtx._id , requestId));
    trackingService.withdrawalRequestCancel(this.props.loginCtx , requestId);
  }

  closeWithdrawalsModal(){
    this.props.dispatch(withdrawalActions.setWithdrawalListModalStatus(false));
  }

  render(){
    var that = this;
    return(
      <Modal style={withdrawalListModalStyle} contentLabel={'Withdrawal List'} isOpen={that.props.withdrawalListModalIsOpen}>
        <i onClick={() => that.closeWithdrawalsModal()} className="fa fa-times close-modal"></i>
        <h3 className="text-center">Withdrawals</h3>
        {
          that.props.withdrawals ?
            <div>
              {
                that.props.withdrawals.length ?
                  <table>
                    <thead>
                    <tr>
                      <td>Date</td>
                      <td>Account</td>
                      <td>Status</td>
                      <td>Amount</td>
                      <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      _.map(that.props.withdrawals, function (withdrawal) {
                        return (
                          <tr key={withdrawal._id}>
                            <td>{ moment(withdrawal.createdAt).format('DD/MM/YYYY') }</td>
                            <td>{withdrawal.preview}</td>
                            <td>{withdrawal.status}</td>
                            <td>£{parseFloat(withdrawal.amount).toFixed(2)}</td>
                            <td>
                              {
                                withdrawal.status == 'PENDING' ?
                                  <a onClick={() => that.cancelWithdrawal(withdrawal._id) }>Cancel</a>
                                  :
                                  false
                              }

                            </td>
                          </tr>
                        );
                      })
                    }
                    </tbody>
                  </table>
                  :
                  <div className="no-data">
                    <p>No withdrawal data</p>
                  </div>
              }
              </div>
            :

            false
        }
        <div onClick={() => that.closeWithdrawalsModal() } className="actions text-center">
          <p>&nbsp;</p>
          <button className="secondary-v2">Close</button>
        </div>
      </Modal>
    );
  }
}


export default WithdrawalList;