import React from 'react';
import classnames from 'classnames';
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export default function ChartHorizontalBar({ ...props }) {
  const { title, chartData, type, className, total, previousTotal } = props;

  const renderHorizontalBarChart = () => {
    const tooltipColor = '#485465', typography = ' \'Montserrat\', sans-serif';

    var options = {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 55,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            display: false,
            gridLines: { display: false, drawBorder: false },
            ticks: {
              beginAtZero: true
            }
          }
        ],
        yAxes: [
          {
            display: false,
            gridLines: { display: false, drawBorder: false },
            barThickness: 20,
            categoryPercentage: 1,
            barPercentage: 1
          }
        ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        position: 'average',
        titleFontFamily: typography,
        titleFontStyle: 'lighter',
        bodyFontFamily: typography,
        backgroundColor: tooltipColor,
        bodyFontSize: 8,
        titleFontSize: 10,
        custom: function(tooltip) {
          if (!tooltip) return;
          tooltip.cornerRadius = 1;
        },
        callbacks: {
          title: function(tooltipItems, data) {
            if (data.datasets && data.datasets.length === 2) {
              const previousValue = isNaN(previousTotal) || previousTotal === 'Infinity' 
                ? '0'
                : previousTotal;
              const a = 'Total Current: ' + total;
              const b = 'Total Previous: ' + previousValue;
              return [a, b];
            } else {
              return 'Total ' + total;
            }
          }
        }
      },
      // https://chartjs-plugin-datalabels.netlify.com/
      plugins: {
        datalabels: {
          display: true,
          clamp: false,
          align: (context) => {

            if(context.dataset.comparisonMode){
              return '145';
            }
            else{
              return 'start';
            }
          }, // 'start',
          anchor: 'start',
          offset: 5,

          formatter: function(value, context) {
            
            if(context.dataset.previousInfo){
              return '';
            }

            if(context.dataset.comparisonMode){
              let compLabel = String(context.dataset.comparisonLabel[context.dataIndex]).replace('%', '');
              
              return isNaN(compLabel) ? '0%' : context.dataset.comparisonLabel[context.dataIndex];   
            }
            else{

              let value = String(context.dataset.data[context.dataIndex]).replace('%', '');
              
              return  (isNaN(value) ? '0' : value) + (context.dataset.percentageMode ? '%' : '');
            }
          },
          color: function(context) {
            
            if(context.dataset.comparisonMode){
              
              let compLabel = String(context.dataset.comparisonLabel[context.dataIndex]).replace('%', '');

              if(compLabel < 0){
                return '#f53855';
              }               
            }
          }
        }
      }
    };

    return <HorizontalBar data={chartData} options={options} />;
  };

  const renderVariation = () => {
    let renderVariation = null;
    let isPositiveVariation = true;
    let previousTotalFormatted = previousTotal;
    let totalFormatted = total;
    if (previousTotalFormatted !== undefined && title.endsWith('RATE')) {
      previousTotalFormatted = previousTotal.toString().replace('%', '');
      totalFormatted = totalFormatted.replace('%', '');
    }

    previousTotalFormatted = isNaN(previousTotalFormatted)
      ? 0
      : previousTotalFormatted;

    let variation = ((totalFormatted / previousTotalFormatted - 1) * 100).toFixed(2);
    variation = isNaN(variation) || variation === 'Infinity' ? 0 : variation;

    if (previousTotalFormatted === null || previousTotalFormatted === undefined) {
      return renderVariation;
    } else if (variation < 0) {
      isPositiveVariation = false;
    }

    const icon = (
      <i
        className={classnames(
          'fa',
          { 'fa-long-arrow-up': isPositiveVariation },
          { 'fa-long-arrow-down': !isPositiveVariation },
          'variation variation__icon',
          { 'variation--red': !isPositiveVariation }
        )}
        aria-hidden="true"
      />
    );

    return (
      <React.Fragment>
        { variation !== 0 ? icon : <div style={{ marginTop: '-8px' }}>&nbsp;</div> }

        <span className={classnames('variation variation__number', { 'variation--red': !isPositiveVariation })} >
          {previousTotalFormatted !== null ? variation + '%' : null}
        </span>
      </React.Fragment>
    );
  };

  return (
    <div className={className}>
      <div className="label">
        <div>{title}</div>

        { previousTotal ? renderVariation() : <div className="label__total">{total}</div> }
      </div>

      {type === 'HorizontalBar' && renderHorizontalBarChart()}
    </div>
  );
}
