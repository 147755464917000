import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import moment from 'moment';

import reportService from '../../../../../utils/services/reports';

class LiveRewardsListBasic extends React.Component {
  constructor() {
    super();
    this.state = {
      clientNameField: '',
      rewardNameField: '',
      showActiveClients: 'true',
      totalRecords:0 
    };
    this.onLazyLoad = this.onLazyLoad.bind(this);

  }

  getQuery(event) {
    let query = {
      $offset: event.first,
      $limit: event.rows
    };

    if (this.state.clientNameField && this.state.clientNameField != '') {
      let value = { 'clientName_$like': `%${this.state.clientNameField}%` };
      query = Object.assign(query, value);
    }

    if (this.state.rewardNameField && this.state.rewardNameField != '') {
      let value = { 'rewardName_$like': `%${this.state.rewardNameField}%` };
      query = Object.assign(query, value);
    }


    if (this.state.showActiveClients) {
      let value = { 'clientActive_$eq': `${this.state.showActiveClients}` };
      query = Object.assign(query, value);
    }

    return query;
  }

  getExtendedQuery(event) {
    var extendedQuery = '?';
    if (event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for (let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-'}${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
    this.setState({
      liveRewardList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.setState({ loading: true });
    reportService.getLiveRewardsBasic(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });

  }

  resetFilter = (event) => {
    this.setState({
      clientNameField: '',
      clientActiveFilter: true,
      rewardNameField: '',
    }, () => this.onLazyLoad(event));
  }

  columnFormatDate = (rowData, column) => {
    return moment(rowData[column.field]).format('YYYY-MM-DD');
  }

  columnFormatDaysLeft = (rowData, column) => {
    if (rowData['usedLast30Days'] == 0 && rowData['totalRemaining'] > 0) {
      return '-';
    } else {
      return rowData[column.field];
    }
  }
  renderDataTable() {

    return (
      <DataTable
        value={this.state.liveRewardList}
        responsive={true}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={(e)=>this.onLazyLoad(e)}
        loading={this.state.loading}
        globalFilter={this.state.globalFilter}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 100, 500, 1000]}
        totalRecords={this.state.totalRecords}
        ref={(el) => this.dt = el}
      >
        <Column field="clientName" header="Client Name"  style={{ width: '40px' }} />
        <Column field="rewardName" header="Reward Name" style={{ width: '65px' }} />
        <Column field="remainingCodes" header="Remaining Codes" style={{ width: '20px' }} />
      </DataTable>
    );
  }
  render() {
    /**
     * Client status option
     */
    const clientsActiveSelectItems = [
      { label: 'All', value: '' },
      { label: 'Active', value: 'true' },
      { label: 'Inactive', value: 'false' },
    ];

    return (
      <div>
        <div className="content-section implementation">
          <h3>Live Rewards Basic</h3>
          <div className="ui-g">
            <div className="ui-g-4 ">
              <div style={{ paddingBottom: '5px' }}>
                <span>Client name:</span>
              </div>
              <InputText onChange={(e) => this.setState({ clientNameField: e.target.value })} value={this.state.clientNameField} />
            </div>
            <div className="ui-g-4" >
              <div style={{ paddingRight: '15px', paddingTop: '8px' }}>
                <span>Client Status:</span>
              </div>
              <Dropdown
                value={this.state.showActiveClients}
                options={clientsActiveSelectItems}
                style={{ height: '30px' }}
                onChange={(e) => { this.setState({ showActiveClients: e.value }); }}
                placeholder="All" />
            </div>
            <div className="ui-g-4 ">
              <div style={{ paddingBottom: '5px' }}>
                <span>Reward name:</span>
              </div>
              <InputText onChange={(e) => this.setState({ rewardNameField: e.target.value })} value={this.state.rewardNameField} />
            </div>
          </div>
        </div>
        <div className="ui-g">
          <div className="ui-g-1">
            <Button label="Search" onClick={this.onLazyLoad} />
          </div>
          <div className="ui-g-1">
            <Button label="Reset" onClick={this.resetFilter} />
          </div>
        </div >
        {this.renderDataTable()}
      </div >
    );
  }

}

export default LiveRewardsListBasic;
