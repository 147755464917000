import React from 'react';

class ClientLogo extends React.Component {
  constructor(props) {
    super(props);

    this.file_upload_image_logo = React.createRef();
    this.file_preview_upload_image_logo = React.createRef();
  }

  render() {
    const { item } = this.props;
    return(
      <div>
        <h2>{item.name}</h2>
        <div id="upload-form" className="form" accept="image/png, image/jpeg , image/gif ,image/jpg ">
          <label htmlFor={"file-upload-image-logo"} >
            <div>
              { 
                item.imageUrl ? 
                  <img id={'file-preview-upload-image-logo'} 
                    src={item.imageUrl} alt="Client logo" height="75" width="75" ref={this.file_preview_upload_image_logo}/> 
                  :
                  <img id={'file-preview-upload-image-logo'} 
                    src='/images/default-upload.png' alt="Client logo" height="75" width="75" ref={this.file_preview_upload_image_logo}/>
              }
            </div>
          </label>
          <input 
            id={"file-upload-image-logo"} 
            onChange={() => this.props.handleChange()} 
            type="file"
            className="file_input_dialog"
            ref={this.file_upload_image_logo} />
        </div>
      </div>
    );
  }
}

export default ClientLogo;