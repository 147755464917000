import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import rewardPoolService from '../../../utils/services/rewardPool';

import {Dropdown} from 'primereact/components/dropdown/Dropdown';

export default class RewardPoolDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.list = [];
    this.getList = this.getList.bind(this);
    
    this.state = {
      item: null,
      itemAvailable: 'hidden',
      rewardPool: this.props.value ? this.props.value : null
    };
    this.noItemAvailable = false;
    this.onChange = this.onChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.client != nextProps.client){
      if(nextProps.client){
        this.state.rewardPool = nextProps.value;
        this.getList(nextProps.client);
      }
    }
  }


  getList(clientId) {    
    rewardPoolService.getAllRewardPool({clientId:clientId}).then((data) => {
      if(!_.isNil(data.data)) {

        this.list = data.data
          .filter(item => item.archived !== true)
          .map(list => ({ label: `${list.name} - ${list._id}`, value: list._id, name: list.name }));
        this.setState({rewardPoolAvailable: 'hidden'});
      }
      else {
        this.list = null;
        this.setState({rewardPoolAvailable: 'visible'});
      }
      this.forceUpdate();
    });
  }



  campaignTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span><b>Name:</b> {option.name}</span>
          <div><b>ID:</b> {option.value}</div>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({rewardPool: e.value});
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    return (
      <div className={ cx }>
        <label>
          {this.props.label} <span style={{ visibility : this.state.rewardPoolAvailable }} >  (No Reward Pool available)</span>
        </label>
        <Dropdown value={this.state.rewardPool} 
          options={this.list} 
          onChange={this.onChange} 
          itemTemplate={this.campaignTemplate} 
          style={{ marginLeft: '5px', width:'300px'}} 
          filter={true} 
          filterPlaceholder="Select rewardPool" 
          filterBy="label,value" 
          laceholder="Select a rewardPool"
          showClear/>
      </div>
      
    );
  }
}
