import constants from '../../constants/app-constants';

const initialState = {
  sharedUrls: null,
  pageCount: 30,
  clicksCount: {},
  revenueCount: {}
};

const UserEngagement = (state = initialState , action) => {
  switch(action.type){
  case constants.user.ENGAGEMENT_SHAREDURLS_GET: {
    return {...state , sharedUrls: action.payload};
  }

  case constants.user.ENGAGEMENT_UPDATEPAGECOUNT: {
    return {...state , pageCount: action.payload};
  }

  case constants.user.SHAREDURLS_COUNTCLICKS: {
    return {...state , clicksCount: action.payload};
  }

  case constants.user.SHAREDURLS_EARNINGSPERPLATFORM: {
    return {...state , revenueCount: action.payload};
  }

  default: {
    return state;
  }
  }
};

export default UserEngagement;