import constants from '../../constants/app-constants';
import userService from '../../utils/services/user';

export const setWithdrawalModalStatus = (status) => {
  return {
    type: constants.user.WITHDRAWAL_SETMODALSTATUS,
    payload: status
  };
};

export const setWithdrawalListModalStatus = (status) => {
  return {
    type: constants.user.WITHDRAWALLIST_SETMODALSTATUS,
    payload: status
  };
};

export const getWithdrawals = (userId) => {
  return function(dispatch){
    userService.getWithdrawals(userId).then(function(res){
      dispatch({
        type: constants.user.WITHDRAWALLIST_POPULATE,
        payload: res.data
      });
    });
  };
};

export const cancelWithdrawal = (userId , requestId) => {
  return function(dispatch){
    userService.cancelWithdrawal(userId , requestId)
      .then(function(){
        userService.getWithdrawals(userId).then(function(res){
          dispatch({
            type: constants.user.WITHDRAWALLIST_POPULATE,
            payload: res.data
          });
        });
      });
  };
};