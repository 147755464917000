import {request as axiosRequest} from 'axios';
import store from '../../stores/store';
import { showLoadSpinner } from '../../actions/index';

const request = (requestParams, useLoadSpinner = false, timeout = 0) => {

  if(useLoadSpinner){
    store.dispatch(showLoadSpinner(true));
  }

  const finallyRequest = () => {
    if(useLoadSpinner){
      setTimeout(() => store.dispatch(showLoadSpinner(false)), timeout);
    }
  };

  return axiosRequest(requestParams).finally(finallyRequest);
};

module.exports = request;