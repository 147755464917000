import React from 'react';
import SocialConnect from '../../social/SocialConnect';

class Social extends React.Component{
  render(){
    return(
      <div className="social-settings">
        <h1>Social Settings</h1>
        <SocialConnect />
      </div>
    );
  }
}

export default Social;