const _ = require('lodash');

export const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatterNumber = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatterNumberOneCase = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export class ClientStats {
  constructor() {}

  setClientTotalStats = (data, excludeReachMultiple = false) => {
    
    var totals = {
      clientSales: 0,
      offerClicks: 0,
      soretoTraffic: 0,
      countSoretoSales: 0,
      countSoretoSalesPending: 0,
      countSoretoSalesPaid: 0,
      countSoretoSalesDeclined: 0,
      totalValueSoretoSales: 0,
      totalValueSoretoSalesPending: 0,
      totalValueSoretoSalesPaid: 0,
      totalValueSoretoSalesDeclined: 0,
      totalValueSoretoCommission: 0,
      totalValueSoretoCommissionPending: 0,
      totalValueSoretoCommissionPaid: 0,
      totalValueSoretoCommissionDeclined: 0,
      clicks: 0,
      shares: 0,
      shareRate: 0,
      purchaseRate: 0,
      reachMultiple: 0,
      interstitialRate: 0,
      conversionRate: 0
    };
    
    for(let row of data.data.page) {
            
      totals.clientSales += +row.clientSales;
      totals.offerClicks += +row.offerClicks;
      totals.soretoTraffic += +row.interstitialClicks;

      // SALES COUNT
      totals.countSoretoSales += +row.soretoSales;
      totals.countSoretoSalesPending += +row.pendingSalesCount;
      totals.countSoretoSalesPaid += +row.paidSalesCount;
      totals.countSoretoSalesDeclined += +row.declinedSalesCount;
      
      // SALES TOTALS SUM
      totals.totalValueSoretoSales += +row.totalValueSales;
      totals.totalValueSoretoSalesPending += +row.totalValueSalesPending;
      totals.totalValueSoretoSalesPaid += +row.totalValueSalesPaid;
      totals.totalValueSoretoSalesDeclined += +row.totalValueSalesDeclined;
      
      // SALES COMMISSION SUM
      totals.totalValueSoretoCommission += +row.salesCommission;
      totals.totalValueSoretoCommissionPending += +row.pendingSalesCommission;
      totals.totalValueSoretoCommissionPaid += +row.paidSalesCommission;
      totals.totalValueSoretoCommissionDeclined += +row.declinedSalesCommission;
            
      // CLICKS
      totals.clicks += Number(row.refClicks);
      totals.shares += +row.shares;

      row.refClicks = Number(row.refClicks);
      row.pendingSalesCount = row.pendingSalesCount;
      row.paidSalesCount = row.paidSalesCount;
      row.declinedSalesCount = row.declinedSalesCount;

      row.purchaseRate = formatterNumber.format(row.purchaseRate) + '%';
      row.conversionRate = formatterNumber.format(row.conversionRate) + '%';
      row.shareRate = formatterNumber.format(row.shareRate)+ '%';
      row.reachMultiple = formatterNumberOneCase.format(row.reachMultiple);
      row.interstitialClicksRate = formatterNumber.format(row.interstitialClicksRate)+ '%';
      
      row.totalValueSoretoSales = row.totalValueSoretoSales_GBP;
      row.totalValueSoretoSalesPending = formatter.format(row.pendingSales);
      row.totalValueSoretoSalesPaid = formatter.format(row.paidSales);
      row.totalValueSoretoSalesDeclined = formatter.format(row.declinedSales);

      row.salesCommission = formatter.format(row.salesCommission);
      row.pendingSalesCommission = formatter.format(row.pendingSalesCommission);
      row.paidSalesCommission = formatter.format(row.paidSalesCommission);
      row.declinedSalesCommission = formatter.format(row.declinedSalesCommission);
    }
    
    /**
     * FORMAT
     */
    totals.purchaseRate = formatterNumber.format(+totals.soretoTraffic > 0 ? (+totals.countSoretoSales / +totals.soretoTraffic)*100 : 0) + '%';
    totals.conversionRate = formatterNumber.format(+totals.clicks > 0 ? (+totals.countSoretoSales / +totals.clicks)*100 : 0) + '%';
    totals.shareRate = formatterNumber.format(+totals.clientSales > 0 ? (+totals.shares / +totals.clientSales)*100 : 0)+ '%';
    totals.reachMultiple = formatterNumberOneCase.format(+totals.shares > 0 ? (+totals.clicks / +totals.shares) : 0);
    totals.interstitialRate = formatterNumber.format(+totals.clicks > 0 ? (+totals.soretoTraffic / +totals.clicks)*100 : 0)+ '%';

    totals.totalValueSoretoSales = totals.totalValueSoretoSales.toFixed(0);
    totals.totalValueSoretoSalesPending = formatter.format(totals.totalValueSoretoSalesPending);
    totals.totalValueSoretoSalesPaid = formatter.format(totals.totalValueSoretoSalesPaid);
    totals.totalValueSoretoSalesDeclined = formatter.format(totals.totalValueSoretoSalesDeclined);

    totals.totalValueSoretoCommission = formatter.format(totals.totalValueSoretoCommission);
    totals.totalValueSoretoCommissionPending = formatter.format(totals.totalValueSoretoCommissionPending);
    totals.totalValueSoretoCommissionPaid = formatter.format(totals.totalValueSoretoCommissionPaid);
    totals.totalValueSoretoCommissionDeclined = formatter.format(totals.totalValueSoretoCommissionDeclined);

    if (excludeReachMultiple) {
      delete totals.reachMultiple;
    }

    return totals;    
  }

  columns = (state, showClient = true, excludeReachMultiple = false) => {

    var s = new Set();
    
    if(state.showDaily){
      s.add({ field:'eventDateTerm', header:'Date', sortable:true, style:{width:'100px'} });
    }

    if(state.showCampaignLevel){
      s.add({ field:'campaignName', header:'Campaign', sortable:true, style:{width:'100px'} });
    }

    if(state.showCampaignVersionLevel){
      s.add({ field:'campaignVersionName', header:'Version', sortable:true, style:{width:'100px'} });
    }

    if (showClient) {
      s.add({ field:'clientName', header:'Client', sortable:true, style:{width:'120px'} });
    }

    s.add({ field:'clientSales', header:'Lightbox Views', sortable:true, style:{width:'60px'} });
    s.add({ field:'shares', header:'Shares', sortable:true, style:{width:'60px'} });
    s.add({ field:'shareRate', header:'Share Rate', sortable:true, style:{width:'60px'}  });
    s.add({ field:'refClicks', header:'Ref Clicks', sortable:true, style:{width:'60px'} });

    if (!excludeReachMultiple) {
      s.add({ field:'reachMultiple', header:'Reach Multiple', sortable:true, style:{width:'60px'} });
    }
    
    if(showClient){
      s.add({ field:'interstitialClicks', header:'Int.', sortable:true, style:{width:'60px'} });
      s.add({ field:'interstitialClicksRate', header:'Int. Rate', sortable:true, style:{width:'60px'} });
    }

    s.add({ field:'soretoSales', header:'Soreto Sales', sortable:true, style:{width:'60px'} });
    s.add({ field:'purchaseRate', header:'Soreto Sales Rate', sortable:true, style:{width:'60px'} });
    s.add({ field:'conversionRate', header:'Conv. Rate', sortable:true, style:{width:'60px'} });
    s.add({ field:'pendingSalesCount', header:'Pend #', sortable:true, style:{width:'60px'} });
    s.add({ field:'paidSalesCount', header:'Conf #', sortable:true, style:{width:'60px'} });
    s.add({ field:'declinedSalesCount', header:'Decl #', sortable:true, style:{width:'60px'} });
    s.add({ field:'salesCommission', header:'Total £', sortable:true, style:{width:'80px'} });
    s.add({ field:'pendingSalesCommission', header:'Pend £', sortable:true, style:{width:'80px'} });
    s.add({ field:'paidSalesCommission', header:'Conf £', sortable:true, style:{width:'80px'} });
    s.add({ field:'declinedSalesCommission', header:'Decl £', sortable:true, style:{width:'80px'} });

    return Array.from(s);
  }

  columnsFooter = (state, totals, showClient = true, excludeReachMultiple = false) => {
    var s = new Set();

    if(state.showDaily && showClient){
      s.add({ footer:'Totals:', colSpan:2 });
    }
    else {
      s.add({ footer:'Totals:', colSpan:1 });
    }

    if(state.showCampaignLevel){
      s.add({ footer:'', colSpan:1  });
    }
    
    if(state.showCampaignVersionLevel){
      s.add({ footer:'', colSpan:1  });
    }
    
    s.add({ footer: totals.clientSales, colSpan:1  });
    s.add({ footer: totals.shares, colSpan:1  });
    s.add({ footer: totals.shareRate, colSpan:1  });
    s.add({ footer: totals.clicks, colSpan:1  });
    
    if (!excludeReachMultiple) {
      s.add({ footer: totals.reachMultiple, colSpan:1  });
    }
    
    s.add({ footer: totals.soretoTraffic, colSpan:1  });
    s.add({ footer: totals.interstitialRate, colSpan:1  });
    s.add({ footer: totals.countSoretoSales, colSpan:1  });
    s.add({ footer: totals.purchaseRate, colSpan:1  });
    s.add({ footer: totals.conversionRate, colSpan:1  });
    s.add({ footer: totals.countSoretoSalesPending, colSpan:1  });
    s.add({ footer: totals.countSoretoSalesPaid, colSpan:1  });
    s.add({ footer: totals.countSoretoSalesDeclined, colSpan:1  });
    s.add({ footer: totals.totalValueSoretoCommission, colSpan:1  });
    s.add({ footer: totals.totalValueSoretoCommissionPending, colSpan:1  });
    s.add({ footer: totals.totalValueSoretoCommissionPaid, colSpan:1  });
    s.add({ footer: totals.totalValueSoretoCommissionDeclined, colSpan:1  });

    return Array.from(s);
  }
}