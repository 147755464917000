import constants from '../../constants/app-constants';
import clientService from '../../utils/services/client';
import clientHelper from '../../utils/helpers/client';
import sortHelper from '../../utils/helpers/sortHelper';

export const getDailyTractionData = function(clientId , query){
  return function(dispatch){
    clientService.getProductTractionData(clientId , query).then((response) => {
      dispatch({
        type: constants.client.REPORTS_DAILYTRACTION_FETCHREPORT,
        payload: sortHelper.sortResults(clientHelper.getDailyTractionData(response.data),'desc','createdAt')
      });
    });
  };
};

export const updateDailyTractionData = function(tractionData){
  return {
    type: constants.client.REPORTS_DAILYTRACTION_FETCHREPORT,
    payload: tractionData
  };
};

export const updateDailyTractionPageCount = (pageCount) => {
  return {
    type : constants.client.REPORTS_DAILYTRACTION_UPDATEPAGECOUNT ,
    payload : pageCount
  };
};