import React from 'react';
import classname from 'classnames';
import config from '../../../config/config';
import userService from '../../../utils/services/user';
import { connect } from 'react-redux';
import * as clientGlobalMessageActions from '../../../actions/globalMessageActions';
import GlobalMessages from '../globalMessage/GlobalMessage';

const coverStyle = {
  backgroundImage: 'url(/images/login-image.jpg)',
  backgroundSize: 'cover',
  height: '420px'
};

@connect((store) => {
  return {
    baseUrl: config.URL.BASEFRONT,
    globalMessage : store.globalMessages.message
  };
})
class ResetPassword extends React.Component {

  constructor(){
    super();
    this.state = {
      userTokenError: false,
      resetPasswordSuccess: false,
      isCancelBtnHovered: false
    };

    this.goToPage = this.goToPage.bind(this);
  }


  componentWillMount(){
    var that = this;

    that.setState({
      globalMessageActions: clientGlobalMessageActions
    });

    if(!that.props.location.query.userId || !that.props.location.query.token){
      this.setState({
        userTokenError : true
      }, function(){
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: 'Invalid Password Reset Link',
            timeout: 10000000
          }
        ));
      });
    }
  }

  changePassword(e){
    e.preventDefault();
    var that = this;

    userService.resetPassword({
      userId: this.props.location.query.userId,
      token: this.props.location.query.token,
      password: this.password.value
    })
      .then(function(){
        that.setState({
          resetPasswordSuccess: true
        });

        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'success',
            message: 'Your password has been updated',
            timeout: 10000
          }
        ));
      })
      .catch(function(err){
        console.log('the message is here' , JSON.stringify(err));
        that.props.dispatch(that.state.globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  goToPage(page){
    
    if('/login' && this.props.location.query.sourceModule == 'marketplace'){
      window.location.href = config.MARKETPLACE.URL;
      return;
    }

    this.props.router.push({ pathname: page });
  }

  hoverOn = () => {
    this.setState({ isCancelBtnHovered: true });
  }

  hoverOff = () => {
    this.setState({ isCancelBtnHovered: false });
  }

  render () {
    const { isCancelBtnHovered } = this.state;

    return (
      <div className="front-page grid-container grid-parent">
        
        <div className="grid-100 front-page-mid-section grid-parent">
          <GlobalMessages message={this.props.globalMessage}/>

          <div className="grid-100 front-page-form-holder text-center">
            <div className="soreto__logo"></div>
            {
              !this.state.resetPasswordSuccess ?
                <div className="form-container" id="form-container">
                  {
                    this.state.userTokenError ?
                      <div>
                        <h2>RESET PASSWORD</h2>
                        <p className="top-text form-container__title">This reset link is invalid</p>
                        <button  
                          onClick={() => this.goToPage('/forgotpassword')} 
                          className={classname('btn btn--reset', {
                            blurResetBtn: isCancelBtnHovered
                          })}
                        >RESEND LINK</button>
                        
                        <button 
                          onClick={() => this.goToPage('/login')} 
                          className="btn btn--resetcancel"
                          onMouseEnter={this.hoverOn}
                          onMouseLeave={this.hoverOff}
                        >CANCEL
                        </button>
                       
                      </div>
                      :
                      <form onSubmit={(e) => this.changePassword(e)}>
                        <h2>RESET PASSWORD</h2>
                        <p className="top-text form-container__title">Enter your new password below</p>
                        <div className="front-page-input-holder">
                          <input required="true" ref={(input) => this.password = input} type="password" placeholder="New Password"/>
                        </div>
                        <div>
                          <button className="submit--btn">SUBMIT</button>
                        </div>
                      </form>
                  }
                </div>
                :
                <div className="form-container" id="form-container__password_updated">
                  <h2>RESET PASSWORD</h2>
                  <p className="top-text form-container__title">Your password has been updated</p>
                  <button onClick={() => this.goToPage(`/login`)} className="login--btn">LOG IN</button>
                </div>
            }
          </div>
        </div>
        
      </div>
    );
  }
}

export default ResetPassword;