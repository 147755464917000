import React from 'react';
import { browserHistory } from 'react-router';
import GlobalVar from '../../../shared/globalVar/GlobalVar';
import clientService from '../../../../utils/services/client';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

/**
 * This component gonna handle with client Global Var configurations
 */
export default class ClientConfiguration extends React.Component {

  /**
   * Class constructor
   * @param {*} props 
   */
  constructor(props){
    super(props);

    this.state ={
      clientId: this.props.params.clientId
    };

  }

  /**
   * Component will mount
   */
  componentWillMount() {
    
    let that = this;

    if (this.state.clientId) {

      // get client data
      clientService.getClient(this.state.clientId)
        .then((result) => {

          this.setState({client : result.data});
        })
        .catch((err) => {
          that.props.dispatch(globalMessageActions.showMessage(
            {
              type: 'error',
              message: err.response.data.message,
              timeout: 10000
            }
          ));
        });
    }    
  }

  /**
   * Main render
   */
  render(){
    return(
      <div>
        
        <h3> <b>{this.state.client ? this.state.client.name : '' }</b> : GLOBAL VARIABLES</h3>
        
        <hr></hr>
        
        <GlobalVar context='POST_REWARD' objectId={this.state.clientId} />
        <GlobalVar context='LANDING_PAGE' objectId={this.state.clientId} />
        <GlobalVar context='CLIENT.ANTI_FRAUD' objectId={this.state.clientId} />
        <GlobalVar context='CLIENT.TRACKING' objectId={this.state.clientId} />
        <GlobalVar context='CLIENT.SECURITY' objectId={this.state.clientId} />
        <GlobalVar context='CLIENT.INFRA' objectId={this.state.clientId} />
        <GlobalVar context='CLIENT.USER_SEGMENTATION' objectId={this.state.clientId} />
        
        <br></br>

        <div className="ui-g">
          <div className='ui-g-12 ui-md-2'>
            <button className="button icon-left" onClick={browserHistory.goBack}>
            Back
            </button>
          </div>
        </div>

      </div>        
    );
  }
}