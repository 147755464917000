import config from '../../../config/config';

export const load = () => {
  window.analytics.load(config.ANALYTICS.SEGMENT.WRITE_KEY);
};

export const page = (location , user_type = 'user') => {
  if (location.action === 'POP') {
    window.analytics.page(location.pathname,{
      path: location.pathname,
      code: 'page_load',
      entry_point: 'web_app',
      user_type: user_type
    });
  }
};

export const pageUrl = (url , additional = null , user_type = 'user') => {
  var payload = {};
  payload.path = url;
  payload.user_type = user_type;

  if(additional){
    Object.assign(payload , additional);
  }

  window.analytics.page(url,payload);
};

export const identify = (user , user_type = 'user') => {
  window.analytics.identify(user._id, {
    id: user._id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    entry_point: 'web_app',
    user_type: user_type,
    distinct_id: user._id
  });
};

export const track = (event , trackInfo) => {
  trackInfo.entry_point = trackInfo.entry_point || 'web_app';
  window.analytics.track(event,trackInfo);
};