import request from '../wrappers/axios';
import config from '../../config/config';

class DynamicMenuService {

  getDynamicUserMenus(){
    
    return new Promise((resolve, reject) => {

      let cache =  localStorage.getItem('userMenus');

      if(cache){
        try{
          let data = JSON.parse(cache);

          if(data && data.expiration && data.expiration > new Date().getTime()){
            return resolve(data.menus);  
          }

        }catch(err){
          console.error(err);
        }
      }

      request({
        url: `${config.API.BASEURL}/dynamicMenu/userMenu`,
        method: 'get',
      }, true, 500)
        .then((r) => {
          localStorage.setItem('userMenus', JSON.stringify({ menus : r.data, expiration : new Date().getTime() + 180000 }));
          return resolve(r.data);
        })
        .catch((e) => {
          return reject(e);
        });
    });     
  }

  getPage(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/dynamicMenu/page${extendedQuery}`,
      method: 'get',
      params: query
    }, true, 500);
  }

  create(data) {
    return request({
      url: `${config.API.BASEURL}/dynamicMenu`,
      method: 'post',
      data: data
    }, true, 500);
  }

  update(data) {
    return request({
      url: `${config.API.BASEURL}/dynamicMenu/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  delete(id) {
    return request({
      url: `${config.API.BASEURL}/dynamicReport/${id}`,
      method: 'delete'
    }, true, 500);
  }
}

export default new DynamicMenuService();