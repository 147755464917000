const colours = [
  '#3B5998', // Facebook
  '#FCCC63', // Instagram
  '#55acee', // Twitter
  '#cb2027', // Pinterest
  '#dd4b39', // Google
  '#7E7E7E' // Other
];

export default {
  getChartObjShares: (labels, data) => {
    return {
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: colours,
          label: 'Test Dataset' // for legend
        }]
      },
      options: {
        legend: {
          labels: {
            generateLabels: () => {
              return '';
            }
          }
        },
        animation: false
      }
    };
  },
  getChartObjRevenue: (labels , data) => {
    return {
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colours,
            hoverBackgroundColor: colours
          }
        ]
      },
      options: {
        legend: {
          labels: {
            generateLabels: () => {
              return '';
            }
          }
        },
        animation: false
      }
    };
  },
  getChartObjClicks: (labels, data) => {
    return {
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Clicks per Platform',
            backgroundColor: colours,
            borderWidth: 1,
            data: data,
          }
        ]
      },
      options: {
        legend: {
          labels: {
            generateLabels: () => {
              return '';
            }
          }
        },
        animation: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }]
        }
      }
    };
  },
  getChartObjEarningsPerPlatform: (labels , data) => {
    return {
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colours,
            hoverBackgroundColor: colours
          }
        ]
      },
      options: {
        legend: {
          labels: {
            generateLabels: () => {
              return '';
            }
          }
        },
        animation: false
      }
    };
  },
  getChartObjUserReportClicks: (labels, data) => {
    return {
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Clicks per Platform',
            backgroundColor: colours,
            borderWidth: 1,
            data: data,
          }
        ]
      },
      options: {
        legend: {
          labels: {
            generateLabels: () => {
              return '';
            }
          }
        },
        animation: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }]
        }
      }
    };
  },
  getChartObjUserReportEarnings: (labels , data) => {
    return {
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colours,
            hoverBackgroundColor: colours
          }
        ]
      },
      options: {
        legend: {
          labels: {
            generateLabels: () => {
              return '';
            }
          }
        },
        animation: false
      }
    };
  },
  chartDataEmpty: (data) => {
    return data === 0;
  }
};