import React from 'react';

export class PassThrough extends React.Component {

  constructor(props) {
    super(props);

  }

  render() {

    return (
      <div>
          <br/>
          <p>Redirecting...</p>
      </div>
    );    
  }
}

export default PassThrough;