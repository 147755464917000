import constants from '../../constants/app-constants';

const initialState = {
  engagementProducts: null,
  pageCount: 0
}

const ClientEngagement = (state = initialState , action) => {
  switch(action.type){
  case constants.client.ENGAGEMENT_UPDATEPRODUCTS : {
    return{
      ...state,
      engagementProducts: action.payload
    };
  }
  case constants.client.ENGAGEMENT_UPDATEPAGECOUNT : {
    return{
      ...state,
      pageCount: action.payload
    };
  }
  default: {
    return state;
  }
  }
}

export default ClientEngagement;