import constants from '../../constants/app-constants';

const initialState = {
  requests: null
};

const AdminWithdrawalRequests = (state = initialState , action) => {
  switch(action.type){
  case constants.admin.WITHDRAWALREQUESTS_POPULATE : return {...state , requests : action.payload};
  default: return state;
  }
};

export default AdminWithdrawalRequests;