import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

export class GlobalMessages extends React.Component{

  constructor(props) {
    super(props);

    this.globalMessageContainer = React.createRef();

    this.closeMessage = this.closeMessage.bind(this);
  }

  closeMessage(){
    this.globalMessageContainer.current.classList.add('hide');
  }

  componentDidUpdate = prevProps => {
    if (prevProps.globalMessage != this.props.globalMessage) {
      this.globalMessageContainer.current.classList.remove('hide');
      this.hideMessageTimeout();
    }
    if (prevProps.message != this.props.message) {
      this.globalMessageContainer.current.classList.remove('hide');
      this.hideMessageTimeout();
    }
  }

  hideMessageTimeout(){
    var that = this;
    if(that.props.message && that.props.message.timeout){
      setTimeout(function(){ 
        if (_.has(that, 'globalMessageContainer') && (!_.isNil(that.globalMessageContainer.current))) { 
          that.globalMessageContainer.current.classList.add('hide'); 
        }
      }, that.props.message.timeout);
    }
  }

  render(){
    return(
      <div ref={this.globalMessageContainer} className={this.props.message ? 'global-message show' : 'global-message' }>
        {
          this.props.message ?
            <div className={this.props.message.type + ' message text-center'}>
              { window.scrollTo(0 , 0) }
              { this.props.message.message }
              <i onClick={() => this.closeMessage()} className="fa fa-times"></i>
            </div>
            :
            false
        }
      </div>
    );
  }
}

export default connect(store => {
  return {
    globalMessage: store.globalMessages.message
  };
})(GlobalMessages);
