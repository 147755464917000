import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export default function ChartDataPie( { ...props } ) {

  const { title, chartData, type, chartDate, className, totalData } = props;

  const  renderPieChart = () => {

    const tooltipColor = '#485465', typography = ' \'Montserrat\', sans-serif';

    var options = {
      responsive: true,
      legend: {
        onClick: (e) => e.stopPropagation(),
        position: 'right',
        labels: {
          fontFamily: typography,
          boxWidth: 12
        }
      },
      tooltips: {
        enabled: true,
        mode: 'nearest',
        titleFontFamily: typography,
        titleFontStyle: 'lighter',
        bodyFontFamily: typography,
        backgroundColor: tooltipColor,
        custom: function(tooltip) {
          if (!tooltip) return;
        },
        callbacks: {
          label: function (tooltipItems, data) {
            var socialPatform = data.labels[tooltipItems.index];
            var percentage = (data.datasets[0].data[tooltipItems.index] / totalData * 100).toFixed(2) + '%';
            return socialPatform + `: ${data.datasets[0].data[tooltipItems.index]} users, ` + percentage;
          }
        }
      },
      plugins: {
        datalabels: {
          align: 'center',
          color: '#324d5c',
          formatter: function(value, context) {
            const percentage = (Number(value) / totalData * 100).toFixed(2);
            if(Number(percentage) >= 10)
              return percentage + '%';
            else
              return '';  
          },
          font: {
            size: '12'
          },
          title: true
        }
      }
    };

    return(  
      <Pie data={chartData} options={options} />
    );
  };

  return (
    <div className={className}>

      <div className="label label--shares">
        <span>{title}</span>
        <span className="date-range">{chartDate}</span>
      </div>

      <div className="pieChart">
        { type === 'PieChart' && renderPieChart() }
      </div>    
        
    </div>
  );

}