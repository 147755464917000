import React from 'react';
import classnames from 'classnames';

export default function DataBox({ ...props }) {
  var { title, currentValue, currencySign, className, variation, previousValue } = props;

  currentValue = currentValue == null ? 0 : currentValue;
  previousValue = previousValue == null ? 0 : previousValue;

  if(typeof currentValue == 'string'){
    currentValue = currentValue.replace(/[^\d|.-]/g, '');
  }

  if(typeof previousValue == 'string'){
    previousValue = previousValue.replace(/[^\d|.-]/g, '');
  }

  const cx = classnames(className, 'data-box');

  function abbreviateNumber(value) {
    
    if (title === 'ROI') {

      if (Number(value) >= 0)
      { 
        return Number(value).toFixed(2) + '%';
      }
      else {
        return value;
      }
    } else if (!title.endsWith('RATE')) {
      
      if(isNaN(value)){
        return value;
      }

      if (Number(value) <= 1000) {

        if (title.endsWith('VALUE') || title === 'COMMISSION') {
          return (currencySign || '£') + value.toString();
        } else {
          return value.toString();
        }

      }

      // adjust commission number
      if (title === 'COMMISSION' || title === 'SALES VALUE') {
        value = Number(String(value).replace(',',''));
        value = Math.trunc(value);
      }

      const numDigits = ('' + value).length;
      const suffixIndex = Math.floor(numDigits / 3);

      const normalisedValue = value / Math.pow(1000, suffixIndex);
      
      let precision = 2;
      if (normalisedValue < 1) {
        precision = 1;
      }

      const suffixes = ['', 'K', 'M', 'B', 'T'];
      
      if (title.endsWith('VALUE') || title === 'COMMISSION') {
        return (currencySign || '£') + normalisedValue.toPrecision(precision) + suffixes[suffixIndex];
      } else {
        return normalisedValue.toPrecision(precision) + suffixes[suffixIndex];
      }

    } else {
      if (title === 'CONVERSION RATE' || title === 'SHARE RATE') {
        
        if (!value.toString().endsWith('%')) {
          return value + '%';
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }

  const renderVariationValue = () => {
    let renderVariation = null;
    let isPositiveVariation = true;

    if (previousValue === null || previousValue === undefined) {
      return renderVariation;
    } else if (Number(variation.replace('%', '')) < 0) {
      isPositiveVariation = false;
    }

    return (
      <div className="variation__block">
        <i
          className={classnames(
            'fa',
            { 'fa-long-arrow-up': isPositiveVariation },
            { 'fa-long-arrow-down': !isPositiveVariation },
            'variation variation__icon',
            { 'variation--red': !isPositiveVariation }
          )}
          aria-hidden="true"
        />
        <span className={classnames('variation variation__number', { 'variation--red': !isPositiveVariation })}>
          {previousValue !== null ? variation : null}
        </span>
      </div>
    );
  };

  const renderCurrentAndPreviousValue = () => {
    
    return (
      <div className="currentAndPreviousValue">
        <span className="currentAndPreviousValue__currentValue">{abbreviateNumber(currentValue)}</span>
        <span>vs</span>
        <span className="currentAndPreviousValue__previousValue">{abbreviateNumber(previousValue)}</span>
      </div>
    );
  };

  return (
    <div className={cx}>
      <span className="label">{title}</span>
      {variation && renderVariationValue()}

      {variation ? (
        renderCurrentAndPreviousValue()
      ) : (
        <span className={className}>{abbreviateNumber(currentValue)}</span>
      )}
    </div>
  );
}
