import constants from '../../constants/app-constants';

export const setDates = dates => {
  return {
    type: constants.shared.SET_CALENDAR_DATES,
    payload: dates
  };
};

export const isDataRangePickerOpened = value => {
  return {
    type: constants.shared.IS_CALENDAR_HIDDEN,
    payload: value
  };
};
