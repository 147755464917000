import React from 'react';
import classnames from 'classnames';

import layoutService  from '../../../utils/services/layout';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class CountryDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.getCountryList = this.getCountryList.bind(this);
    
    this.state = {
      country: this.props.value ? this.props.value : null,
      countryList: null
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getCountryList();  
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.value === 'object') {
      this.setState({ country: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ country: nextProps.value });
    } else{
      this.setState({ country: null });
    }
  }

  getCountryList() {

    let countryList = null;
    layoutService.getCountries()
      .then((data) => {
        if(data.data) {
          countryList = data.data.map( country => ({ label: country.name, value: country._id }));
        }
      
        this.setState({ countryList });
      });
  }

  itemTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ country: e.value });

    if(!e.value){
      this.props.onChange({countryId: null });
      return;
    }

    let country = {};
    
    if(this.state.countryList){
      country = this.state.countryList.find((o => o.value === e.value));
    }
    
    this.props.onChange({countryId: country.value, countryName: country.label });
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    const { label, required, overwriteStyle, placeholder, disabled } = this.props;

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>
      
        { showlabel }

        <Dropdown 
          value={this.state.country}
          showClear
          options={this.state.countryList}
          onChange={this.onChange} 
          itemTemplate={this.itemTemplate} 
          style={ overwriteStyle ? overwriteStyle : { marginLeft: '5px', width:'300px', marginTop: '4px' }}
          panelStyle={{width:'300px'}} 
          filter={true} 
          filterPlaceholder={ "Select a country" }
          disabled={disabled}
          filterBy="label,value" 
          placeholder={ placeholder || "Select a country" }/>
      </div>
      
    );
  }
}
