import constants from '../../constants/app-constants';

export const toggleHeaderMenu = (headerMenuVisible) => {
  return {
    type: constants.user.HEADER_TOGGLEMENU,
    payload: headerMenuVisible == '' ? 'show' : ''
  };
};

export const closeHeaderMenu = () => {
  return {
    type: constants.user.HEADER_TOGGLEMENU,
    payload: ''
  };
};