import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import moment from 'moment';

import demoStoresService from '../../../../utils/services/demoStore';

export default class DemoStoreList extends React.Component {

  constructor() {
    super();
    this.state = {
      demoStoreList: [],
      first: 0
    };

    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.onCreateDemoStore = this.onCreateDemoStore.bind(this);
    this.onEditDemoStore = this.onEditDemoStore.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  getQuery(event) {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }

    var gteDate  = this.state.startDate ? {$createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$createdAt_$lte: moment(this.state.endDate).add(1,'d').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    else {
      var sortField = `$sort=${event.sortOrder == 1 ? '' : '-' }${event.sortField}&`;
      extendedQuery = extendedQuery + sortField;
    }
    return extendedQuery;
  }

  formatData(data) {

    for(let row of data.data.page) {
      row.createdAt = moment(row.createdAt).format('YYYY-MM-DD');
      row.updatedAt = moment(row.updatedAt).format('YYYY-MM-DD');
      row.expiry = moment(row.expiry).format('YYYY-MM-DD');
    }

    this.setState({
      demoStoreList: data.data.page,
      totalRecords: data.data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});

    demoStoresService.getDemoStores(this.getQuery(event), this.getExtendedQuery(event)).then((data) => {
      this.formatData(data);
    });

  }

  onCreateDemoStore(){
    this.props.router.push({ pathname: '/admin/demoStore/create'});
  }

  onEditDemoStore(e,rowData){
    this.props.router.push({ pathname: '/admin/demoStore/' + rowData._id });
  }

  actionTemplate(rowData) {
    return <div>
      <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={(e) => {this.onEditDemoStore(e, rowData);}}> </Button>
    </div>;
  }

  storeLinkTemplate(rowData) {
    return <a href={rowData.storeLink} className="storeLink" target="_blank">{rowData.storeLink}</a>;
  }

  columns(){

    return [
      { field:'storeName', header:'Store Name', sortable:true, style:{ width:'60px' } },
      { field:'storeLink', header:'Store Link', sortable:true, style:{ width:'80px' } },
      { field:'clientName', header:'Client', sortable:true, style:{ width:'80px' } },
      { field:'clientId', header:'Client ID', sortable:true, style:{ width:'70px' } },
      { field:'campaignVersionName', header:'Version', sortable:true, style:{ width:'80px' } },
      { field:'campaignVersionId', header:'Version ID', sortable:true, style:{ width:'70px' } },
      { field:'environment', header:'Environment', sortable:true, style:{ width:'40px' } },
      { field:'notes', header:'Notes', sortable:true, style:{ width:'100px' } },
      { field: 'editIcon', style:{ textAlign:'center', width:'30px' } }
    ];

  }

  renderDataTable() {

    const header = <div style={{ textAlign:'left' }}><Button label="Create" onClick={this.onCreateDemoStore} /></div>;

    const columns =  this.columns();

    const dataTableColumns = columns.map((col,i) => {
      if (col.field === 'editIcon') {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} body={this.actionTemplate}/>;
      } else if (col.field === 'storeLink') {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} body={this.storeLinkTemplate}/>;
      } else {
        return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} style={col.style} />;
      }
    });

    return (
      <DataTable
        value={this.state.demoStoreList}
        sortField='storeName'
        sortOrder='1'
        responsive={true} sortMode="multiple"
        paginator={true} rows={100}
        rowsPerPageOptions={[5,10,20,100,500,1000]}
        totalRecords={this.state.totalRecords}
        lazy={true}
        onLazyLoad={this.onLazyLoad}
        header={header}
        ref={(el) => this.dt = el}
        globalFilter={this.state.globalFilter} 
        first = {this.state.first}>
        { dataTableColumns }
      </DataTable>
    );
  }

  render() {

    return (
      <div>
        <div className="content-section implementation">

          <h3>Demo Stores</h3>

          { this.renderDataTable() }

        </div>
      </div>
    );
  }
}