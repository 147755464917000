import constants from '../../constants/app-constants';

const initialState = {
  sharedUrls: null,
  sharedUrlsClicks: null,
  sharedUrlsCount: null,
  sharedUrlsShares: null,
  modalIsOpen: false,
  earningsPerSocialPlatform: null
}

const ClientDashboard = (state = initialState , action) => {
  switch(action.type){
  case constants.client.DASHBOARD_SHAREDURLS_GET: {
    return {
      ...state,
      sharedUrls: action.payload
    };
  }

  case constants.client.DASHBOARD_SHAREDURLS_COUNTCLICKS: {
    return {
      ...state,
      sharedUrlsClicks: action.payload
    };
  }

  case constants.client.DASHBOARD_SHAREDURLS_COUNTSHARES: {
    return {
      ...state,
      sharedUrlsShares: action.payload
    };
  }

  case constants.client.DASHBOARD_TOGGLEUPLOADMODAL: {
    return {
      ...state,
      modalIsOpen: action.payload
    };
  }

  case constants.client.DASHBOARD_UPDATEEARNINGSPERPLATFORM: {
    return {
      ...state,
      earningsPerSocialPlatform: action.payload
    };
  }

  default: {
    return state;
  }
  }
}

export default ClientDashboard;