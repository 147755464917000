import CampaignVersionList from './List';
import CampaignVersionCreate from './Create';
import CampaignVersionEdit from './Edit';

const CampaignVersionIndex = {
  CampaignVersionList, 
  CampaignVersionCreate,
  CampaignVersionEdit
};

export default CampaignVersionIndex;