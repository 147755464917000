import request from '../wrappers/axios';
import config from '../../config/config';

class OrderService {
  
  getOrders(query = '', extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/order/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getOrdersJoined(query = '', extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/order/joined/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getOrderHistory(orderId, extendedQuery = '') {
    return request({
      url: `${config.API.BASEURL}/orders/${orderId}/history${extendedQuery}`,
      method: 'get'
    });
  }

  triggerOrderPostReward(orderId, extendedQuery = ''){
    return request(
      {
        url: `${config.API.BASEURL}/postconversionreward/fire/${orderId}${extendedQuery}`,
        method: 'post'
      }
    );
  }
}

export default new OrderService();
