import userService from '../../utils/services/user';
import constants from '../../constants/app-constants';

export const getRetailers = () =>{
  return function(dispatch){
    userService.getRetailers()
      .then(function(res){
        dispatch({
          type: constants.user.RETAILERS_POPULATE,
          payload: res.data
        });
      });
  };
};