import React from 'react';

class SliderMenu extends React.Component{

  goTo(route){
    this.props.closeHeaderMenu();
    this.props.goTo(route)
  }

  render(){
    const routes = this.props.menuItems;
    const listItems = routes.map((route) =>
      <li key={route.name} ><a onClick={() => this.goTo(route.route)}>{route.name}</a></li>
    );
    return(
      <div id="sliderMainMenu" className={this.props.headerMenuVisible}>
        <ul>
          {listItems}
          <li><a onClick={this.props.logout} >Logout</a></li>
        </ul>
      </div>
    );
  }
}

export default SliderMenu;