import React from 'react';
import ProfileImageUpdate from '../../../shared/profile/Profile.ProfileImageUpdate';
import Modal from 'react-modal';
import userService from '../../../../../utils/services/user';
import trackingService from '../../../../../utils/services/tracking';
import {updateLoginCtx} from '../../../../../actions/index';
import {connect} from 'react-redux';
import * as globalMessageActions from '../../../../../actions/user/globalMessageActions';

const profileModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

@connect((store)=>{
  return{
    loginCtx: store.loginCtx.loginCtx
  };
})

class Account extends React.Component{

  constructor(){
    super();
    this.state = {
      modalIsOpen: false,
      currentlyEditing: false
    };
  }

  toggleModal(){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    });
  }

  setCurrentlyEditing(e,field){
    if(e){
      e.preventDefault();
    }

    this.setState({
      currentlyEditing: field
    });
  }

  updateUser(e,field){
    e.preventDefault();

    var that = this;
    var payload = {};
    payload[field] = this[field].value;

    userService.updateUser(this.props.loginCtx._id , payload)
      .then(function(){
        userService.getCurrent()
          .then(function(res){
            that.props.dispatch(updateLoginCtx(res.data));
            that.setCurrentlyEditing(false);

            trackingService.profileUpdate(res.data);
          });
      })
      .catch(function(err){
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  getUserField(field){
    return(
      <div className={this.state.currentlyEditing == field.name ? 'input-holder currently-editing' : 'input-holder' }>

        {
          this.state.currentlyEditing == field.name ?
            <div>
              <form onSubmit={(e) => this.updateUser(e,field.name)}>
                <input type={field.type} ref={input => this[field.name] = input}  defaultValue={field.name != 'password' ? this.props.loginCtx[field.name] : ''}
                  disabled={false} placeholder={`Enter ${field.title}`} required={true}/>
                <button className="primary"><i className="hide-on-desktop fa fa-plus"></i><span className="hide-on-mobile hide-on-tablet">Save</span></button>
                <button className="secondary-v2" onClick={(e) => this.setCurrentlyEditing(e,false)}><i className="hide-on-desktop fa fa-minus"></i><span className="hide-on-mobile hide-on-tablet">Cancel</span></button>
              </form>
            </div>
            :
            false
        }

        {
          this.state.currentlyEditing != field.name ?
            <div>
              <input type={field.name != 'password' ? 'text' : 'password'}  value={field.name != 'password' ? this.props.loginCtx[field.name] : 'password'} disabled={true}/>

              {
                !field.disabled &&
                <button onClick={(e) => this.setCurrentlyEditing(e,field.name)} className="primary"><i className="hide-on-desktop fa fa-pencil"></i><span className="hide-on-mobile hide-on-tablet">Edit</span></button>
              }
              
            </div>
            :
            false
        }
      </div>
    );
  }

  render(){
    return(
      <div className="account-settings grid-100 grid-parent">
        <h1>Account</h1>
        <h4 className="active">Your account settings</h4>
        <div className="grid-20 tablet-grid-30 mobile-grid-100 text-center">
          <img className="profile-image" src={this.props.loginCtx.imageUrl || '/images/holding-image.jpg'} alt=""/>
          <button onClick={() => this.toggleModal()} className="edit-profile-image primary">Edit</button>
        </div>
        <div className="user-inputs grid-80 mobile-grid-100 tablet-grid-70">
          {
            this.getUserField({type: 'text' , name: 'firstName' , title: 'Firstname'})
          }
          {
            this.getUserField({type: 'text' , name: 'lastName' , title: 'Lastname'})
          }
          {
            this.getUserField({type: 'email' , name: 'email' , title: 'Email', disabled: true})
          }
          {
            this.getUserField({type: 'password' , name: 'password' , title: 'New Password'})
          }
        </div>
        <div className="clearfix"></div>
        <Modal contentLabel={'Profile image modal'} isOpen={this.state.modalIsOpen} style={profileModalStyle} >
          <ProfileImageUpdate toggleModal={() => this.toggleModal()} loginCtx={this.props.loginCtx} />
        </Modal>
      </div>
    );
  }
}

export default Account;