import React from 'react';
import _ from 'lodash';

import * as userActions from '../../../../../actions/user/userActions';
import * as engagementActions from '../../../../../actions/user/engagementActions';
import * as globalMessageActions from '../../../../../actions/user/globalMessageActions';
import userService from '../../../../../utils/services/user';
import trackingService from '../../../../../utils/services/tracking';
import {connect} from 'react-redux';
import BigNumber from 'bignumber.js';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    userObj: store.user.userObj,
    products: store.userEngagement.sharedUrls,
    pageCount: store.userEngagement.pageCount
  };
})
class ProductTractionReport extends React.Component {

  constructor(){
    super();
    this.state = {
      currentSort: 'clicks',
      direction: 'desc',
      range: {
        from: '',
        to: ''
      },
      searchTerm: '',
      searchQuery: null,
      showFilters: false,
      pageCount: 30,
      rangeQuery: null,
      currentPage: 1,
      nextPageAvailable: false,
      fetchingData: false
    };

    this.goToSocialPost = this.goToSocialPost.bind(this);
  }

  goToSocialPost(){
    this.props.router.push({ pathname: '/user/socialpost' });
  }

  componentWillMount () {
    this.props.dispatch(userActions.getUser(this.props.loginCtx._id));
    this.initialLoad();
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-_id`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount
    };

    userService.getSharedUrlsAccessCount(that.props.loginCtx._id , {})
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getSharedUrlsAccess(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1
            });
            that.props.dispatch(engagementActions.updateSharedUrlsAccess(res.data));
          })
          .catch(function(err){
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=-_id`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount
    };

    if(that.state.rangeQuery){
      Object.assign(query , that.state.rangeQuery);
    }

    that.setState({
      fetchingData: true
    });

    userService.getSharedUrlsAccessCount(that.props.loginCtx._id , that.state.rangeQuery ? that.state.rangeQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getSharedUrlsAccess(that.props.loginCtx._id , query ,extendedQuery)
          .then(function(res){
            var products = that.props.products.concat(res.data);
            that.props.dispatch(engagementActions.updateSharedUrlsAccess(products));
            that.setState({
              nextPageAvailable: products.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  sort(sortBy){
    var that = this;
    var direction = this.state.direction === 'desc' ? 'asc' : 'desc';


    this.setState({
      currentSort: sortBy,
      direction: direction,
      fetchingData: true
    });

    var sortString = that.state.direction === 'desc' ? sortBy : `-${sortBy}` ;
    var extendedQuery = `?$sort=${sortString}&$sort=-_id`;

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.currentPage * that.state.pageCount,
    };

    if(that.state.rangeQuery){
      query = Object.assign(query,that.state.rangeQuery);
    }

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    trackingService.reportSort(that.props.loginCtx ,'Product Conversion Report', sortBy , direction);

    userService.getSharedUrlsAccess(this.props.loginCtx._id , query , extendedQuery)
      .then(function(res){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(engagementActions.updateSharedUrlsAccess(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  searchProducts (e) {
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-_id`;

    var query = {
      $offset: 0,
    };

    var searchQuery = that.searchBox.value ? {$search: that.searchBox.value} : null;
    query = Object.assign(query ,searchQuery);
    that.setState({
      searchQuery: searchQuery,
      fetchingData: true
    });

    trackingService.reportSearch(that.props.loginCtx , 'Product Conversion Report' , that.searchBox.value);

    userService.getSharedUrlsAccess(that.props.loginCtx._id , query , extendedQuery)
      .then(function(res){

        //clear range filter
        that.from.value = '';
        that.to.value = '';

        that.setState({
          nextPageAvailable: false,
          searchTerm: that.searchBox.value,
          range: {
            from: '',
            to: ''
          },
          currentPage: 1,
          fetchingData: false
        });
        that.props.dispatch(engagementActions.updateSharedUrlsAccess(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  clearSearch(){

    this.searchBox.value = '';
    this.setState({
      searchTerm: '',
      searchQuery: null
    });
    this.initialLoad();
  }

  triggerFilterRange(e){

    if(e){
      e.preventDefault();
    }

    var that = this;

    this.rangeFilterContainer.classList.remove('error');
    this.from.classList.remove('error');
    this.to.classList.remove('error');

    if(!this.rangeFilter.value){
      this.rangeFilterContainer.classList.add('error');
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Select \'Filter By\' Option',
          timeout: 10000
        }
      ));
      return;
    }

    //check that From value is not greater than To value
    if((this.from.value && this.to.value) && (parseInt(this.from.value) > parseInt(this.to.value))){
      this.from.classList.add('error');
      this.to.classList.add('error');
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Start must not be greater than End',
          timeout: 10000
        }
      ));
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=-_id`;
    var rangeQuery = {};

    if(this.from.value){
      rangeQuery[`$${this.rangeFilter.value}_$gt`] = parseInt(this.from.value) - 1;
    }

    if(this.to.value){
      rangeQuery[`$${this.rangeFilter.value}_$lt`] = parseInt(this.to.value) + 1;
    }

    if(!that.from.value && !that.to.value){
      rangeQuery = null;
    }

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.pageCount,
    };

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    if(rangeQuery){
      query = Object.assign(query, rangeQuery);
      trackingService.reportFilter(that.props.loginCtx,'Product Traction Report' ,that.rangeFilter.value , {from: this.from.value , to : this.to.value});
    }

    that.setState({
      rangeQuery: rangeQuery,
      fetchingData: true
    });

    userService.getSharedUrlsAccessCount(that.props.loginCtx._id , rangeQuery ? rangeQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        userService.getSharedUrlsAccess(that.props.loginCtx._id , query , extendedQuery)
          .then(function(res){
            that.props.dispatch(engagementActions.updateSharedUrlsAccess(res.data));
            that.setState({
              offset: 0,
              range:{
                from: that.from.value,
                to: that.to.value
              },
              currentPage: 1,
              nextPageAvailable: that.state.searchQuery ? false : that.state.pageCount < totalCount,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }


  getSortDirectionImage(direction){
    return(
      direction == 'desc' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>
    );
  }

  clearRange(rangeItem){

    this.from.classList.remove('error');
    this.to.classList.remove('error');

    this[rangeItem].value = '';
    this.setState({
      range:  {
        from: rangeItem === 'from' ? '' : this.state.range.from,
        to: rangeItem === 'to' ? '' : this.state.range.to
      }
    });

    this.triggerFilterRange();
  }

  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  render () {
    return (
      <div>
        {this.props.userObj && <div className="grid-100">
          <div className='trending-data-holder grid-parent grid-100'>
            <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
              {
                this.state.showFilters ?
                <div>Hide Filters <i className="fa fa-minus"></i></div>
                :
                <div>Show Filters <i className="fa fa-plus"></i></div>

              }
            </div>
            <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
              <div className="report-search-form">
                <form onSubmit={(e) => this.searchProducts(e) }>
                  <input ref={ (input) => this.searchBox = input } type="text" placeholder="Search"/>
                  <button className="primary" type="submit">Search</button>
                </form>
                {
                  this.state.searchTerm != '' ?
                  <div className="toolbar-tab">
                    {this.state.searchTerm}
                    <i onClick={() => this.clearSearch() } className="fa fa-times"></i>
                  </div>
                    :
                    false
                }
              </div>
              <div className="report-range-finder">
                <form>
                  <div ref={ (selectContainer) => this.rangeFilterContainer = selectContainer } className="styled-select">
                    <select ref={ (select) => this.rangeFilter = select }>
                      <option value="">Filter By</option>
                      <option value="clicks">Clicks</option>
                      <option value="epc">EPC</option>
                      <option value="earnings">Commission</option>
                    </select>
                  </div>
                  <label className="report-range-label">Inbetween</label>
                  <div className="reports-range-filter">
                    <input type="number" ref={ (input) => this.from = input } placeholder="Start" />
                  </div>
                  <div className="reports-range-filter">
                    <input type="number"  ref={ (input) => this.to = input } placeholder="End"/>
                  </div>
                  <button className="primary range-apply-button" onClick={(e) => this.triggerFilterRange(e)} type="submit">Apply</button>
                  <div className="clearfix"></div>
                  <div className="text-right">
                    {
                      this.state.range.from != '' ?
                        <span className="toolbar-tab">
                          Start: {this.state.range.from}
                          <i onClick={() => this.clearRange('from')} className="fa fa-times"></i>
                        </span>
                        :
                        false
                    }
                    {
                      this.state.range.to != '' ?
                        <span  className="toolbar-tab">
                          End: {this.state.range.to}
                          <i onClick={() => this.clearRange('to')} className="fa fa-times"></i>
                        </span>
                        :
                        false
                    }
                  </div>
                </form>
              </div>
            </div>
            {
              this.props.products && this.props.products.length ?

                <div className="table-container">
                  <table id="product-traction-report" className="report-table responsive">
                    <thead>
                    <tr>
                      <th width="50%" className={this.state.currentSort === 'productTitle' ? 'current' : ''}><a onClick={() => this.sort('productTitle')}><i className="fa fa-link"></i> Item { this.state.currentSort === 'productTitle' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="15%" className={this.state.currentSort === 'clicks' ? 'current' : ''}><a onClick={() => this.sort('clicks')}><i className="fa fa-hand-o-up"></i> Clicks { this.state.currentSort === 'clicks' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="15%" className={this.state.currentSort === 'epc' ? 'current' : ''}><a title="Earnings Per Click" onClick={() => this.sort('epc')}><i className="fa fa-line-chart"></i> EPC { this.state.currentSort === 'epc' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="20%" className={this.state.currentSort === 'displayEarnings' ? 'current' : ''}><a onClick={() => this.sort('displayEarnings')}><i className="fa fa-money"></i> Revenue Generated { this.state.currentSort === 'displayEarnings' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.props.products.length ?

                        _.map(this.props.products , function (url) {
                          return(
                            <tr key={url._id}>
                              <td className="text-left">
                                <div className="table-image-holder" style={{backgroundImage : url.productImage ? `url(${url.productImage})`  : 'url(/images/table-holding-image.jpg)'}}>
                                </div>
                                <a title={url.productTitle} className="truncate" href={url.productUrl} target='_blank'>{url.productTitle}</a>
                              </td>
                              <td>{url.clicks}</td>
                              <td>£{new BigNumber(url.epc).toFixed(2)}</td>
                              <td>£{new BigNumber(url.displayEarnings).toFixed(2)}</td>
                            </tr>
                          );
                        })

                        :
                        false
                    }
                    </tbody>
                  </table>
                  {
                    this.state.fetchingData ?
                      <div className="fetching loading-gif text-center">
                        <img src="/images/loading-gif.gif"/>
                      </div>
                      :
                      false
                  }
                </div>

                :
                <div>
                  {
                    this.props.products?
                      <div className="no-data">
                        <p>No Results</p>
                        <p><button onClick={() => this.goToSocialPost()} className="primary">Get Sharing</button></p>
                      </div>
                      :
                      <div className="loading-gif text-center">
                        <img src="/images/loading-gif.gif"/>
                      </div>
                  }
                </div>

            }
            {
              this.state.nextPageAvailable ?
                <div className="text-center">
                  <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
                </div>
                :
                false
            }
          </div>
        </div>}
      </div>
    );
  }
}

export default ProductTractionReport;