const _ = require('lodash');

export const formatter_GBP = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatter_USD = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatter_EUR = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatter_generic = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

var _currencyFormatter = {};
export const dynamicFormatter = (currencyCode) => {
   
  let f = _currencyFormatter[currencyCode];

  if(f){
    return f;
  }else{

    _currencyFormatter[currencyCode] = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return _currencyFormatter[currencyCode];
  }    
};
export const formatterNumber = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatterNumberOneCase = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export class ClientStats {
  constructor() {}

  setClientTotalStats = (data, excludeReachMultiple = false, currency) => {

    var totals = {
      clientSales: 0,
      offerClicks: 0,
      soretoTraffic: 0,
      countSoretoSales: 0,
      totalValueSales: 0,
      totalValueSoretoSales: 0,
      totalValueRawCommission: 0,
      totalValueSoretoCommission_USD: 0,
      totalValueSoretoCommission_CLI_CURRENCY: 0,
      totalValueSoretoCommission: 0,
      countSoretoSales_New: 0,
      totalValueSales_New: 0,
      salesCommission_New: 0,
      countSoretoSales_Repeated: 0,
      totalValueSales_Repeated: 0,
      salesCommission_Repeated: 0,
      countSoretoSales_New_Proportion: 0,
      countSoretoSales_Repeated_Proportion: 0,
      clicks: 0,
      shares: 0,
      shareRate: 0,
      reachMultiple: 0,
      interstitialRate: 0,
      conversionRate: 0,
      aov: 0,
      roi: 0
    };

    let currencyFormater = null; 
    
    switch(currency){
    case 'GBP':
      currencyFormater = formatter_GBP;
      break;
    case 'USD':
      currencyFormater = formatter_USD;
      break;
    case 'EUR':
      currencyFormater = formatter_EUR;
      break;
    default:
      currencyFormater = formatter_generic;
      break;
    }
        
    for(let row of data.data.page) {

      let specificFormatter = null;

      if(currency == 'ClientCountryCurrency'){

        specificFormatter = dynamicFormatter(row.clientCountryCurrencyCode);
      }else{

        specificFormatter = currencyFormater;
      }
      
      let totalValueSales;
      let commission;
      
      if (currency === 'OriginalValue'){
        totalValueSales = row[`totalValueSales`];
        commission = row[`salesCommission`];
      }else{
        totalValueSales = row[`totalValueSales_${currency}`];
        commission = row[`salesCommission_${currency}`];
      }

      let aov = row.soretoSales ? totalValueSales / row.soretoSales : 0;
      let roi = commission ? (((totalValueSales - commission)/commission) * 100) : 0;

      totals.clientSales += +row.clientSales;
      totals.offerClicks += +row.offerClicks;
      totals.soretoTraffic += +row.interstitialClicks;

      // SALES COUNT
      totals.countSoretoSales += +row.soretoSales;
      
      // SALES TOTALS SUM
      if (currency === 'OriginalValue'){
        totals.totalValueSales += +row[`totalValueSales`];
      }else{
        totals.totalValueSales += +row[`totalValueSales_${currency}`];
      }
      totals.totalValueSoretoSales += +row.totalValueSales;
      
      // SALES COMMISSION SUM
      totals.totalValueRawCommission += +row.salesCommission;
      totals.totalValueSoretoCommission_USD += +row.salesCommission_USD;
      totals.totalValueSoretoCommission_CLI_CURRENCY += +row.salesCommission_ClientCountryCurrency;
      totals.totalValueSoretoCommission += +commission;
            
      // CLICKS
      totals.clicks += Number(row.refClicks);
      totals.shares += +row.shares;

      let repeated =  row.soretoSalesRepeated;
      let new_totaValueSales = 0;
      let new_salesCommission = 0;
      let repeated_totaValueSales = 0;
      let repeated_salesCommission = 0;
      
      if(repeated){
        totals.countSoretoSales_New += +repeated.new_soretoSales;
        
        if (currency === 'OriginalValue'){
          new_totaValueSales = repeated[`new_totalValueSales`] || 0;
          repeated_totaValueSales = repeated[`repeated_totalValueSales`] || 0;
          new_salesCommission = repeated[`new_salesCommission`] || 0;
          repeated_salesCommission = repeated[`repeated_salesCommission`] || 0;
        }else{
          new_totaValueSales = repeated[`new_totalValueSales_${currency}`] || 0;
          repeated_totaValueSales = repeated[`repeated_totalValueSales_${currency}`] || 0;
          new_salesCommission = repeated[`new_salesCommission_${currency}`] || 0;
          repeated_salesCommission = repeated[`repeated_salesCommission_${currency}`] || 0;
        }
        
        totals.totalValueSales_New += +new_totaValueSales;
        totals.totalValueSales_Repeated += +repeated_totaValueSales;
        totals.salesCommission_New += +new_salesCommission;
        totals.salesCommission_Repeated += +repeated_salesCommission;

        totals.countSoretoSales_Repeated += +repeated.repeated_soretoSales;        
      }
      
      ///////////////////////////////////////////////////////////////////////////////////////////////

      row.refClicks = Number(row.refClicks);
      
      row.conversionRate = formatterNumber.format(row.conversionRate) + '%';
      row.shareRate = formatterNumber.format(row.shareRate)+ '%';
      row.reachMultiple = formatterNumberOneCase.format(row.reachMultiple);
      row.interstitialClicksRate = formatterNumber.format(row.interstitialClicksRate)+ '%';
      
      row.totalValueSoretoSales = row.totalValueSoretoSales;

      if (currency === 'OriginalValue'){
        row[`totalValueSales`] = specificFormatter.format(totalValueSales);
      }else{
        row[`totalValueSales_${currency}`] = specificFormatter.format(totalValueSales);
      }
      
      row[`salesCommission`] = formatterNumber.format(row.salesCommission);
      row[`salesCommission_ClientCountryCurrency`] = `${row.clientCountryCurrencyCode} ${formatterNumber.format(row.salesCommission_ClientCountryCurrency)}`;
      
      if (currency === 'OriginalValue'){
        row[`salesCommission`] = specificFormatter.format(commission);
      }else{
        row[`salesCommission_${currency}`] = specificFormatter.format(commission);
      }
      

      // AOV
      row.aov = specificFormatter.format(aov);

      // ROI
      row.roi = formatterNumber.format(roi) + '%';

      // NEW X REPEAT

      // default values
      row.countSoretoSales_New = 0;
      row.countSoretoSales_Repeated = 0;
      row.countSoretoSales_New_Proportion = formatterNumber.format(0) + '%';
      row.countSoretoSales_Repeated_Proportion = formatterNumber.format(0) + '%';

      if(repeated){

        let newOrderProportion = 0;
        let repeatedOrderProportion = 0;

        row.countSoretoSales_New = repeated.new_soretoSales;
        row.countSoretoSales_Repeated = repeated.repeated_soretoSales;
        
        newOrderProportion = row.soretoSales ? (( repeated.new_soretoSales / row.soretoSales ) * 100) : 0;
        repeatedOrderProportion = row.soretoSales ? (( repeated.repeated_soretoSales / row.soretoSales ) * 100) : 0;

        row.countSoretoSales_New_Proportion = formatterNumber.format(newOrderProportion) + '%';
        row.countSoretoSales_Repeated_Proportion = formatterNumber.format(repeatedOrderProportion) + '%';
      }
      
      if (currency === 'OriginalValue'){
        row[`totalValueSales_New`] = specificFormatter.format(new_totaValueSales);
        row[`totalValueSales_Repeated`] = specificFormatter.format(repeated_totaValueSales);
        row[`salesCommission_New`] = specificFormatter.format(new_salesCommission);
        row[`salesCommission_Repeated`] = specificFormatter.format(repeated_salesCommission);
      }else{
        row[`totalValueSales_New_${currency}`] = specificFormatter.format(new_totaValueSales);
        row[`totalValueSales_Repeated_${currency}`] = specificFormatter.format(repeated_totaValueSales);
        row[`salesCommission_New_${currency}`] = specificFormatter.format(new_salesCommission);
        row[`salesCommission_Repeated_${currency}`] = specificFormatter.format(repeated_salesCommission);
      }

    }
    
    /**
     * FORMAT
     */
    totals.roi = formatterNumber.format(
      totals.totalValueSoretoCommission ? 
        ((totals.totalValueSales - totals.totalValueSoretoCommission)/ totals.totalValueSoretoCommission) * 100 : 0) + '%';

    totals.aov = currencyFormater.format(totals.countSoretoSales ? totals.totalValueSales/totals.countSoretoSales : 0);

    totals.conversionRate = formatterNumber.format(+totals.clicks > 0 ? (+totals.countSoretoSales / +totals.clicks)*100 : 0) + '%';
    totals.shareRate = formatterNumber.format(+totals.clientSales > 0 ? (+totals.shares / +totals.clientSales)*100 : 0)+ '%';
    totals.reachMultiple = formatterNumberOneCase.format(+totals.shares > 0 ? (+totals.clicks / +totals.shares) : 0);
    totals.interstitialRate = formatterNumber.format(+totals.clicks > 0 ? (+totals.soretoTraffic / +totals.clicks)*100 : 0)+ '%';

    totals.totalValueSales = currencyFormater.format(totals.totalValueSales);
    totals.totalValueSoretoSales = totals.totalValueSoretoSales.toFixed(0);

    totals.totalValueRawCommission = currencyFormater.format(totals.totalValueRawCommission);
    totals.totalValueSoretoCommission_USD = formatter_USD.format(totals.totalValueSoretoCommission_USD);
    totals.totalValueSoretoCommission_CLI_CURRENCY = formatterNumber.format(totals.totalValueSoretoCommission_CLI_CURRENCY);
    totals.totalValueSoretoCommission = currencyFormater.format(totals.totalValueSoretoCommission);

    totals.countSoretoSales_New_Proportion = formatterNumber.format(totals.countSoretoSales ? ((totals.countSoretoSales_New / totals.countSoretoSales) * 100) : 0) + '%';
    totals.countSoretoSales_Repeated_Proportion = formatterNumber.format(totals.countSoretoSales ? ((totals.countSoretoSales_Repeated / totals.countSoretoSales) * 100) : 0) + '%';

    totals.totalValueSales_New = currencyFormater.format(totals.totalValueSales_New);
    totals.totalValueSales_Repeated = currencyFormater.format(totals.totalValueSales_Repeated);
    totals.salesCommission_New = currencyFormater.format(totals.salesCommission_New);
    totals.salesCommission_Repeated = currencyFormater.format(totals.salesCommission_Repeated);

    if (excludeReachMultiple) {
      delete totals.reachMultiple;
    }

    return totals;    
  }

  columns = (state, showClient = true, excludeReachMultiple = false, showClientCountry = false, currency = 'GBP') => {

    var s = new Set();
    
    if(state.dateGrouping === 'daily' || state.dateGrouping === 'monthly'){
      s.add({ field:'eventDateTerm', header:'Date', sortable:true, style:{width:'100px'} });
    }

    if (showClient) {
      s.add({ field:'clientName', header:'Client', sortable:true, style:{width:'120px'} });
    }

    if(state.showResponsible){
      s.add({ field:'clientResponsibleName', header:'Representative', sortable:true, style:{width:'100px'} });
    }

    if (showClientCountry) {
      s.add({ field:'clientCountry', header:'Client Country', sortable:true, style:{width:'50px'} });
    }

    if(state.showCampaignCountryLevel){
      s.add({ field:'campaignCountryName', header:'Campaign Country', sortable:true, style:{width:'100px'} });
    }

    if(state.showCampaignLevel){
      s.add({ field:'campaignName', header:'Campaign', sortable:true, style:{width:'100px'} });
    }

    if(state.showCampaignVersionLevel){
      s.add({ field:'campaignVersionName', header:'Version', sortable:true, style:{width:'100px'} });
    }

    if(state.showCampaignVersionAliasLevel){
      s.add({ field:'campaignVersionAlias', header:'Alias', sortable:true, style:{width:'100px'} });
    }

    if (state.campaignVersionSourceTagGroup) {
      s.add({ field: 'campaignVersionSourceTagGroup', header: 'Source Tag', sortable: true, style:{width:'120px'}});
    }

    if (state.socialPostSocialPlatform) {
      s.add({ field: 'socialPostSocialPlatform', header: 'Social Platform', sortable: true, style:{width:'120px'}});
    }

    if (!state.socialPostSocialPlatform) {
      s.add({ field:'clientSales', header:'Lightbox Views', sortable:true, style:{width:'50px'} });
    }

    s.add({ field:'shares', header:'Shares', sortable:true, style:{width:'50px'} });

    if (!state.socialPostSocialPlatform) {
      s.add({ field:'shareRate', header:'Share Rate', sortable:true, style:{width:'50px'}  });
    }

    s.add({ field:'refClicks', header:'Ref Clicks', sortable:true, style:{width:'50px'} });

    if (!excludeReachMultiple) {
      s.add({ field:'reachMultiple', header:'CTR', sortable:true, style:{width:'50px'} });
    }

    let currencySign = '';
    switch (currency) {
    case 'GBP':
      currencySign = '£';
      break;
    case 'USD':
      currencySign = '$';
      break;
    case 'EUR':
      currencySign = '€';
      break;
    case 'ClientCountryCurrency':
    case 'OriginalValue':
      currencySign = '';
      break;
    }

    s.add({ field:'soretoSales', header:'Soreto Sales', sortable:true, style:{width:'50px'} });
    s.add({ field:'conversionRate', header:'Conv. Rate', sortable:true, style:{width:'50px'} });

    if(state.showNewRepeatComparison){
      s.add({ field:'countSoretoSales_Repeated', header:'Repeated Sales', style:{width:'50px'} });
      s.add({ field:'countSoretoSales_Repeated_Proportion', header:'Repeated Sales %', style:{width:'50px'} });
      s.add({ field:'countSoretoSales_New', header:'New Sales', style:{width:'50px'} });
      s.add({ field:'countSoretoSales_New_Proportion', header:'New Sales %', style:{width:'50px'} });
    }
    
    if (currency === 'OriginalValue'){
      s.add({ field:`totalValueSales`, header:'Revenue', sortable:true, style:{width:'70px'} });

      if(state.showNewRepeatComparison){
        s.add({ field:`totalValueSales_New`, header:'New Revenue', style:{width:'70px'} });
        s.add({ field:`totalValueSales_Repeated`, header:'Repeated Revenue', style:{width:'70px'} });
      }
    }else{
      s.add({ field:`totalValueSales_${currency}`, header:'Revenue', sortable:true, style:{width:'70px'} });

      if(state.showNewRepeatComparison){
        s.add({ field:`totalValueSales_New_${currency}`, header:'New Revenue', style:{width:'70px'} });
        s.add({ field:`totalValueSales_Repeated_${currency}`, header:'Repeated Revenue', style:{width:'70px'} });
      }
    }

    
    if(!state.showNewRepeatComparison){
      s.add({ field:'aov', header:'AOV', style:{width:'70px'} });
    }    
    
    if (currency === 'OriginalValue'){
      s.add({ field:`salesCommission`, header:`Com. ${currencySign}`, sortable:true, style:{width:'70px'} });

      if(state.showNewRepeatComparison){
        s.add({ field:`salesCommission_New`, header:`New Com. ${currencySign}`, style:{width:'70px'} });
        s.add({ field:`salesCommission_Repeated`, header:`Repeated Com. ${currencySign}`, style:{width:'70px'} });
      }
    }else{
      s.add({ field:`salesCommission_${currency}`, header:`Com. ${currencySign}`, sortable:true, style:{width:'70px'} });

      if(state.showNewRepeatComparison){
        s.add({ field:`salesCommission_New_${currency}`, header:`New Com. ${currencySign}`, style:{width:'70px'} });
        s.add({ field:`salesCommission_Repeated_${currency}`, header:`Repeated Com. ${currencySign}`, style:{width:'70px'} });
      }
    }


    if(!state.showNewRepeatComparison){
      s.add({ field:'roi', header:'ROI', style:{width:'70px'} });
    }

    return Array.from(s);
  }

  columnsFooter = (state, totals, showClient = true, excludeReachMultiple = false, showClientCountry = false) => {
    var s = new Set();

    let cl = [
      (state.dateGrouping === 'daily' || state.dateGrouping === 'monthly'),  
      showClient, 
      showClientCountry, 
      state.showCampaignCountryLevel, 
      state.showCampaignLevel, 
      state.showCampaignVersionLevel,
      state.showCampaignVersionAliasLevel,
      state.showResponsible];
    let span = cl.filter(c => c === true).length;

    s.add({ footer:'Totals:', colSpan: span});
    
    if (state.campaignVersionSourceTagGroup) {
      s.add({ footer: '', colSpan:1  });
    }

    if (!state.socialPostSocialPlatform) {
      s.add({ footer: totals.clientSales, colSpan:1  });
    } else {
      s.add({ footer: '', colSpan:1  });
    }


    s.add({ footer: totals.shares, colSpan:1  });

    if (!state.socialPostSocialPlatform) {
      s.add({ footer: totals.shareRate, colSpan:1  });
    } 

    s.add({ footer: totals.clicks, colSpan:1  });

    if (!excludeReachMultiple) {
      s.add({ footer: totals.reachMultiple, colSpan:1  });
    }
    
    s.add({ footer: totals.countSoretoSales, colSpan:1  });
    s.add({ footer: totals.conversionRate, colSpan:1  });

    if(state.showNewRepeatComparison){
      s.add({ footer:totals.countSoretoSales_Repeated, colSpan: 1});
      s.add({ footer:totals.countSoretoSales_Repeated_Proportion, colSpan: 1});
      s.add({ footer:totals.countSoretoSales_New, colSpan: 1});
      s.add({ footer:totals.countSoretoSales_New_Proportion, colSpan: 1});
    }

    s.add({ footer: totals.totalValueSales, colSpan:1  });

    if(state.showNewRepeatComparison){
      s.add({ footer: totals.totalValueSales_New, colSpan:1  });
      s.add({ footer: totals.totalValueSales_Repeated, colSpan:1  });
    }

    if(!state.showNewRepeatComparison){
      s.add({ footer: totals.aov, colSpan:1  });
    }

    s.add({ footer: totals.totalValueSoretoCommission, colSpan:1  });

    if(state.showNewRepeatComparison){
      s.add({ footer: totals.salesCommission_New, colSpan:1  });
      s.add({ footer: totals.salesCommission_Repeated, colSpan:1  });
    }

    if(!state.showNewRepeatComparison){
      s.add({ footer: totals.roi, colSpan:1  });
    }
    
    return Array.from(s);
  }
}