import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import currencyService from '../../../utils/services/currency';

import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class CurrencyDropbox extends React.Component {
  constructor(props) {
    super(props);

    this.currencies = [];
    this.getCurrencies = this.getCurrencies.bind(this);

    this.state = {
      currency: this.props.value ? this.props.value : null,
      currencyAvailable: 'hidden',
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = () => {
    this.getCurrencies();
  };

  componentWillUpdate = prevProps => {
    if (prevProps.value != this.props.value) {
      if (typeof this.props.value === 'object') {
        this.setState({ currency: null });
      } else if (typeof this.props.value === 'string') {
        this.setState({ currency: prevProps.value });
      }
    }
  }

  getCurrencies() {
    currencyService
      .get()
      .then((result) => {
        if (result.data.resultData) {
          this.currencies = result.data.resultData.page
            .map((displayBlock) => ({
              label: displayBlock.currencyCode,
              value: displayBlock._id,
            }));
          this.setState({ currencyAvailable: 'hidden' });
        } else {
          this.currencies = null;
          this.setState({ currencyAvailable: 'visible' });
        }
        this.forceUpdate();
      });
  }

  displayBlockTemplate(option) {
    if (option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e) {
    this.setState({ currency: e.value });
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline,
    });

    return (
      <div className={cx}>
        <label>
          {this.props.label}{' '}
          <span
            style={{ visibility: !this.state.currencyAvailable }}
          >
            Soreto Order Original Currency
          </span>
        </label>
        <Dropdown
          value={this.state.currency}
          options={this.currencies}
          onChange={this.onChange}
          itemTemplate={this.displayBlockTemplate}
          style={{ marginLeft: '5px', width: '100%' }}
          filter={true}
          filterPlaceholder="Select Currency"
          filterBy="label,value"
          placeholder="Select a Currency"
        />
      </div>
    );
  }
}
