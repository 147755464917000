import constants from '../../constants/app-constants';


const initialState = {
  selectedCurrency: 'GBP'
};

const CurrencySwitcher = (state = initialState, action) => {
  
  switch (action.type) {
  case constants.shared.SET_CURRENCY: {
    return { ...state, selectedCurrency: action.payload };
  }

  default:
    return state;
  }
};

export default CurrencySwitcher;