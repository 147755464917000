import byChannelService from '../../utils/services/byChannel';
import constants from '../../constants/app-constants';

export const getTotalClientStatsByPeriod = (query, extendedQuery) => {
  return function(dispatch){
    byChannelService.getTotalClientStatsByPeriod(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.client.BY_CHANNEL_TOTAL_CLIENT_STATS_BY_PERIOD_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.client.BY_CHANNEL_TOTAL_CLIENT_STATS_BY_PERIOD_GET,
          payload: err.response
        });
      });
  };
};

export const getClientStatsPerChannelByPeriod = (query, extendedQuery) => {
  return function(dispatch){
    byChannelService.getClientStatsPerChannelByPeriod(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.client.BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.client.BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_GET,
          payload: err.response
        });
      });
  };
};

export const getClientStatsPerChannelByPeriod_bi = (query, extendedQuery) => {
  return function(dispatch){
    byChannelService.getClientStatsPerChannelByPeriod_bi(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.client.BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_BI_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.client.BY_CHANNEL_CLIENT_STATS_PER_CHANNEL_BY_PERIOD_BI_GET,
          payload: err.response
        });
      });
  };
};