import React from 'react';
import classnames from 'classnames';

import userManagementService  from '../../../utils/services/userManagement';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class ResponsibleDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.getResponsiblesList = this.getResponsiblesList.bind(this);
    
    this.state = {
      responsible: this.props.value ? this.props.value : null,
      responsibleList: null
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getResponsiblesList();  
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.value === 'object') {
      this.setState({ responsible: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ responsible: nextProps.value });
    }
  }

  getResponsiblesList() {

    let responsibleList = null;
    userManagementService.getResponsibles().then((data) => {
      if(data.data) {
        responsibleList = data.data.map( responsible => ({ label: `${responsible.firstName} ${responsible.lastName}`, value: responsible._id }));
      }
      
      this.setState({ responsibleList });
    });
  }

  itemTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ responsible: e.value });
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    const { label, required, overwriteStyle } = this.props;

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label}
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>
      
        { showlabel }

        <Dropdown 
          value={this.state.responsible}
          showClear
          options={this.state.responsibleList}
          onChange={this.onChange} 
          itemTemplate={this.itemTemplate} 
          style={ overwriteStyle ? overwriteStyle : { marginLeft: '5px', width:'300px', marginTop: '4px' }}
          panelStyle={{width:'300px'}} 
          filter={true} 
          filterPlaceholder="Select a Responsible" 
          filterBy="label,value" placeholder="Select a Responsible"/>
      </div>
      
    );
  }
}
