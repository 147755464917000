import listStatisticsService from '../../utils/services/listStatisticsService';
import constants from '../../constants/app-constants';

export const getClientStats = (query, extendedQuery) => {
  return function(dispatch){
    listStatisticsService.getClientStatistics(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.client.CLIENT_STATS_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.client.CLIENT_STATS_GET,
          payload: err.response
        });
      });
  };
};

export const getClientStats_bi = (query, extendedQuery) => {
  return function(dispatch){
    listStatisticsService.getClientStatistics_bi(query, extendedQuery)
      .then(function(res){
        dispatch({
          type: constants.client.CLIENT_STATS_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.client.CLIENT_STATS_GET,
          payload: err.response
        });
      });
  };
};