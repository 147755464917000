import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';

import FormElement from '../../../shared/form/FormElement';
import * as formActions from '../../../../actions/admin/emailTemplateTypeActions';

export class EmailTemplateList extends React.Component {

  constructor() {
    super();
    this.state = {
      emailTemplateList: [],
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
    };

    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.export = this.export.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onCreateEmailTemplate = this.onCreateEmailTemplate.bind(this);
    this.onEditEmailTemplate = this.onEditEmailTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  export() {
    this.dt.exportCSV();
  }

  componentWillReceiveProps(nextProps) {
    if(_.get(nextProps,'emailTemplate.page')){
      this.formatData(nextProps.emailTemplate);
    }
  }

  resetFilter(event) {
    this.setState({
      startDate: moment().subtract(365,'d').toDate(),
      endDate: moment().toDate(),
      searchField: '',
    }, () => this.onLazyLoad(event));
  }

  getQuery(event) {
    var query = {
      $offset: event.first,
      $limit: event.rows
    };

    if(this.state.searchField && this.state.searchField != '') {
      var value = { '$search' : this.state.searchField };
      query = Object.assign(query ,value);
    }

    var gteDate  = this.state.startDate ? {$createdAt_$gte: moment(this.state.startDate).format('YYYY-MM-DD')} :  {};
    var lteDate  = this.state.endDate ? {$createdAt_$lte: moment(this.state.endDate).add(1,'d').format('YYYY-MM-DD')} :  {};
    query = Object.assign(query ,gteDate);
    query = Object.assign(query ,lteDate);

    return query;
  }

  getExtendedQuery(event){
    var extendedQuery = '?';
    if(event.multiSortMeta != null && event.multiSortMeta.length > 0) {
      for(let sortMeta of event.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  formatData(data) {
    for(let row of data.page) {
      row.createdAt = moment(row.createdAt).format('DD/MM/YYYY HH:mm');
      row.updatedAt = moment(row.updatedAt).format('DD/MM/YYYY HH:mm');
    }

    this.setState({
      emailTemplateList: data.page,
      totalRecords: data.totalCount,
      loading: false
    });
  }

  onLazyLoad(event) {
    this.state.first = isNaN(event.first) ? 0 : event.first;

    this.setState({ loading: true});
    this.props.dispatch(formActions.getPage(this.getQuery(event), this.getExtendedQuery(event)));
  }

  onCreateEmailTemplate(){
    this.props.router.push({ pathname: '/admin/emailTemplateType/create'});
  }

  onEditEmailTemplate(e,rowData){
    this.props.router.push({ pathname: '/admin/emailTemplateType/' + rowData._id });
  }

  actionTemplate(rowData) {
    return <div>
      <Button type="button" icon="fa-edit" className="ui-button-warning" onClick={(e) => {this.onEditEmailTemplate(e, rowData);}}> </Button>
    </div>;
  }

  render() {
    var header = <div style={{textAlign:'left'}}><Button type="button" icon="fa-file-o" iconPos="left" label="CSV" onClick={this.export}></Button></div>;

    const calendarStyle = {
      marginLeft: '5px', width: '172px', top: '-12px', position: 'relative', border: '1px solid #cccccc', margin: '0'
    };

    return (
      <div>
        <div className="content-section implementation">

          <h3>Email Template Type</h3>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-5 ui-md-2">
                <span>Date range:</span>
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateFrom'
                  type='calendar'
                  onChange={(e) => this.setState({startDate: e.value})}
                  value={this.state.startDate}
                  id='dateFrom'
                  required
                  style={calendarStyle}
                />
              </div>
              <div className="ui-g-10 ui-md-2">
                <FormElement
                  name='dateTo'
                  type='calendar'
                  onChange={(e) => this.setState({endDate: e.value})}
                  value={this.state.endDate}
                  id='dateTo'
                  required
                  style={calendarStyle}
                />
              </div>
            </div>
          </div>
          <div className="content-section implementation">
            <div className="ui-g">
              <div className="ui-g-3 ui-md-1">
                <Button label="Search" onClick={this.onLazyLoad} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Reset" onClick={this.resetFilter} />
              </div>
              <div className="ui-g-3 ui-md-1">
                <Button label="Create" onClick={this.onCreateEmailTemplate} />
              </div>
            </div>
          </div>

          <DataTable 
            value={this.state.emailTemplateList}
            responsive={true} sortMode="multiple"
            paginator={true} 
            rows={10} 
            rowsPerPageOptions={[5,10,20,100,500,1000]}
            totalRecords={this.state.totalRecords}
            lazy={true}
            onLazyLoad={this.onLazyLoad}
            header={header}
            loading={this.state.loading}
            ref={(el) => this.dt = el}
            globalFilter={this.state.globalFilter} 
            first = {this.state.first}
          >
            <Column field="createdAt" header="Created" sortable={true} style={{width:'50px'}} />
            <Column field="updatedAt" header="Updated" sortable={true} style={{width:'50px'}} />
            <Column field="name" header="Name" sortable={true} style={{width:'100px'}} />
            <Column field="value" header="Value" sortable={true} style={{width:'100px'}} />
            <Column body={this.actionTemplate} style={{textAlign:'center', width: '30px'}}/>
          </DataTable>
        </div>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    emailTemplate: store.adminEmailTemplateType.getAll
  };
})(EmailTemplateList);