import campaignService from '../../../../../../utils/services/campaign';
import moment from 'moment';
import _ from 'lodash';

const map = campaign => ({
  ...campaign,
  createdAt: moment(campaign.createdAt).format('YYYY-MM-DD'),
  startDate: moment(campaign.startDate).format('YYYY-MM-DD'),
  expiry: moment(campaign.expiry).format('YYYY-MM-DD'),
  expired: moment(campaign.expiry).isBefore(moment()),
  future: moment(campaign.startDate).isAfter(moment()),
  sourceTemplateName: campaign.sourceTemplateDescription && `${campaign.sourceTemplateClientName} - ${campaign.sourceTemplateDescription}`
});

const getCampaignList = (clientId, ignoreArchived, campaignType) => {
  const query = {
    $sort: '-startDate',
    clientId_$: clientId,
    type_$eq:campaignType
  };
  if(ignoreArchived) query.archived_$ = false;

  return campaignService.getCampaignList(query)
    .then(response => response.data.page);
};

const getActiveCampaign = (clientId, countryCode) => campaignService.getActiveCampaign(clientId, countryCode).then(response => response.data);

export const getData = (clientId, ignoreArchived, campaignType) => {

  return getCampaignList(clientId, ignoreArchived, campaignType)
    .then((campaignList) => {

      return Promise.all(_.uniqBy(campaignList, 'countryCode')
        .map(c => getActiveCampaign(clientId, c.countryCode)))
        .then((activeCampaigns) => {

          activeCampaigns = activeCampaigns.filter(ac => ac != null);
          if(activeCampaigns && activeCampaigns.length > 0){
            campaignList = campaignList.map((c) => {
              c.running = activeCampaigns.find(ac => ac._id == c._id) ? true : false;
  
              return c;
            });
          }          

          return _.groupBy(campaignList.map(map), 'countryName');
        });
    });
}; 