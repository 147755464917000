import React from 'react';
import classnames from 'classnames';

import campaignVersionService from '../../../utils/services/campaignVersion';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';

export default class CampaignVersionDropbox extends React.Component {

  constructor(props) {
    super(props);
    
    this.campaignVersions = [];
    this.getCampaignVersions = this.getCampaignVersions.bind(this);
    
    this.state = {
      campaignVersion: this.props.value ? this.props.value : null, 
    };
    
    this.onChange = this.onChange.bind(this);
  }

  componentWillUpdate(nextProps){
    if(this.props.campaign != nextProps.campaign){
      if(nextProps.campaign){
        this.getCampaignVersions(nextProps.campaign);
      }
    }
  } 

  componentWillReceiveProps(nextProps) {
    if (!nextProps.campaign || typeof nextProps.value === 'object') {
      this.setState({ campaignVersion: null });
    } else if (typeof nextProps.value === 'string') {
      this.setState({ campaignVersion: nextProps.value });
    }
  }

  getCampaignVersions(campaignId) {
    campaignVersionService.getAllCampaignVersions(campaignId).then((data) => {
      if(data.data) {
        this.campaignVersions = data.data
          .filter(item => item.archived !== true)
          .map(campaignVersion => ({ label: campaignVersion.name, value: campaignVersion._id }));
      }
      else {
        this.campaignVersions = null;
      }
      this.forceUpdate();
    });
  }


  campaignVersionTemplate(option) {
    if(option) {
      return (
        <div className="ui-helper-clearfix">
          <span>{option.label}</span>
        </div>
      );
    }
  }

  onChange(e){
    this.setState({ campaignVersion: e.value });
    this.props.onChange(e);
  }

  render() {
    const cx = classnames('c-form-element', this.props.className, {
      'c-form-element__error': this.props.error,
      'c-form-element__flex': this.props.flex,
      'c-form-element--inline': this.props.inline
    });

    const { label, required, disabled } = this.props;

    let showlabel = null;
    if (label) {
      showlabel = (
        <label>
          {label} 
          {
            required &&
            <span className="u-color-pink">&nbsp;*</span>
          }
        </label>  
      );
    }

    return (
      <div className={ cx }>
      
        { showlabel }

        <Dropdown 
          value={this.state.campaignVersion} 
          showClear
          disabled={disabled}
          options={this.campaignVersions}
          onChange={this.onChange}
          itemTemplate={this.campaignVersionTemplate} 
          style={{ marginLeft: '5px', width:'300px', marginTop: '4px'}}
          filter={true}
          filterPlaceholder="Select Version" 
          filterBy="label,value"
          placeholder="Select a Version"
        />
      </div>
      
    );
  }
}
