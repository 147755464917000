import { combineReducers } from 'redux';
import constants from '../constants/app-constants';

import clientActivity from './client/activity';
import client from './client/client';
import clientDashboard from './client/dashboard';
import globalMessages from './globalMessage';
import loadSpinner from './loadSpinner';
import clientEngagement from './client/engagement';
import clientHeader from './client/header';
import loginCtx from './loginCtx';
import clientProfile from './client/profile';
import clientReportsDailyTraction from './client/reports.dailyTraction';
import clientSettings from './client/settings';
import clientByChannel from './client/byChannel';
import clientStats from './client/clientStats';

import userActivity from './user/activity';
import userDashboard from './user/dashboard';
import userEngagement from './user/engagement';
import userHeader from './user/header';
import userOnBoarding from './user/onBoarding';
import userPaymentMethod from './user/paymentMethod';
import userProfile from './user/profile';
import userRetailers from './user/retailers';
import userSocial from './user/social';
import userTransactions from './user/transactions';
import user from './user/user';
import userWithdrawal from './user/withdrawal';

import adminHeader from './admin/header';
import adminDashboard from './admin/dashboard';
import adminWithdrawalRequests from './admin/withdrawalRequests';
import clientOnboard from './admin/clientOnboard';
import adminCampaign from './admin/campaign';
import adminAffiliate from './admin/affiliate';
import adminClient from './admin/client';
import adminCampaignVersion from './admin/campaignVersion';
import adminDisplayBlock from './admin/displayBlock';
import adminCodeBlock from './admin/codeBlock';
import adminReward from './admin/reward';
import adminRewardDiscountCode from './admin/rewardDiscountCode';
import adminRewardPool from './admin/rewardPool';
import adminEmailTemplate from './admin/emailTemplate';
import adminEmailTemplateType from './admin/emailTemplateType';
import adminAssociateEmailToCampaignVersion from './admin/associateEmailToCampaignVersion';
import adminAssociateClientAffiliate from './admin/associateClientAffiliate';
import adminDemoStore from './admin/demoStore';
import adminUserManagement from './admin/userManagement';
import adminLayout from './admin/layout';
import adminOrder from './admin/order';
import keyEmailType from './admin/keyEmailTemplateType';


import sharedDateRangePicker from './shared/dateRangePicker';
import currencySwitcher from './shared/currencySwitcher';

const clientAppReducer = combineReducers({
  loginCtx,
  globalMessages,
  loadSpinner,
  client,
  clientActivity,
  clientDashboard,
  clientEngagement,
  clientHeader,
  clientProfile,
  clientReportsDailyTraction,
  clientSettings,
  clientOnboard,
  clientByChannel,
  clientStats,

  user,
  userActivity,
  userDashboard,
  userEngagement,
  userHeader,
  userOnBoarding,
  userPaymentMethod,
  userProfile,
  userRetailers,
  userSocial,
  userTransactions,
  userWithdrawal,

  adminHeader,
  adminDashboard,
  adminWithdrawalRequests,
  adminClient,
  adminCampaign,
  adminAffiliate,
  adminCampaignVersion,
  adminDisplayBlock,
  adminCodeBlock,
  adminReward,
  adminRewardDiscountCode,
  adminRewardPool,
  adminEmailTemplate,
  adminEmailTemplateType,
  adminAssociateEmailToCampaignVersion,
  adminAssociateClientAffiliate,
  adminDemoStore,
  adminUserManagement,
  adminLayout,
  adminOrder,
  keyEmailType,

  sharedDateRangePicker,
  currencySwitcher
});

const rootReducer = (state, action) => {
  if (action.type == constants.client.LOGOUT) {
    state = undefined;
  }
  return clientAppReducer(state, action);
};

export default rootReducer;
