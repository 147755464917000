import campaignVersionService from '../../utils/services/campaignVersion';
import constants from '../../constants/app-constants';

function timezoneOffsetIn(dateString){
  var dateIn = new Date(dateString);
  dateIn.setTime(dateIn.getTime() - dateIn.getTimezoneOffset()*60*1000);
  return dateIn;
}

function timezoneOffsetOut(dateString){
  var dateIn = new Date(dateString);
  dateIn.setTime(dateIn.getTime() + dateIn.getTimezoneOffset()*60*1000);
  return dateIn;
}

export const newCampaignVersion = clientObj => dispatch => {
  return new Promise((resolve, reject) => {

    let dataIn = { ...clientObj};

    dataIn.publicSharedUrlExpiresAt = timezoneOffsetIn(dataIn.publicSharedUrlExpiresAt);
    dataIn.privateSharedUrlExpiresAt = timezoneOffsetIn(dataIn.privateSharedUrlExpiresAt);

    campaignVersionService.newCampaignVersion(dataIn)
      .then(res => {
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateCampaignVersion = (campaignVersionObj) => {
  return function(dispatch){

    let dataIn = { ...campaignVersionObj};
    dataIn.publicSharedUrlExpiresAt = dataIn.publicSharedUrlExpiresAt ? timezoneOffsetIn(dataIn.publicSharedUrlExpiresAt) : null;
    dataIn.privateSharedUrlExpiresAt = dataIn.privateSharedUrlExpiresAt ? timezoneOffsetIn(dataIn.privateSharedUrlExpiresAt) : null;

    campaignVersionService.updateCampaignVersion(dataIn)
      .then(function(res){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_EDIT,
          payload: err.response
        });
      });
  };
};

export const getCampaignVersion = (campaignVersionId) => {
  return function (dispatch) {
    campaignVersionService
      .getCampaignVersion(campaignVersionId)
      .then(function (res) {
        let dataOut = { ...res.data };
        dataOut.publicSharedUrlExpiresAt = dataOut.publicSharedUrlExpiresAt ? timezoneOffsetOut(
          dataOut.publicSharedUrlExpiresAt) : "";

        dataOut.privateSharedUrlExpiresAt = dataOut.privateSharedUrlExpiresAt ? timezoneOffsetOut(
          dataOut.privateSharedUrlExpiresAt) : "";

        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_GET,
          payload: dataOut,
        });
      })
      .catch(function (err) {
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_GET,
          payload: err.response,
        });
      });
  };
};

export const getCampaignVersionListByCampaignId = (campaignId) => {
  return function(dispatch){
    campaignVersionService.getAllCampaignVersions(campaignId)
      .then(function(res){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_LIST_BY_CAMPAIGN_ID_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_LIST_BY_CAMPAIGN_ID_GET,
          payload: err.response
        });
      });
  };
};

export const updateCampaignVersionList = (campaignVersionList) => {
  return function(dispatch){
    campaignVersionService.updateCampaignVersionList(campaignVersionList)
      .then(function(res){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_LIST_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_LIST_EDIT,
          payload: err.response
        });
      });
  };
};
export const copyCampaignVersion = (clientObj) => {
  return function(dispatch){
    campaignVersionService.copyCampaignVersion(clientObj)
      .then(function(res){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_COPY,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.CAMPAIGN_VERSION_COPY,
          payload: err.response
        });
      });
  };
};
