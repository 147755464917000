import React from 'react';
import {Link} from 'react-router';

class ReportsMenu extends React.Component {
  render() {
    var menuItems  = this.props.menu.map((item) => {
      return (
        <Link activeClassName={'current'} to={`/user/reports/${item.code}`} key={item.code}><i className={item.mobileIconClass}></i><span>{item.title}</span></Link>
      );
    });

    return (
      <div id="reports-menu">
        { menuItems }
      </div>
    );
  }
}

export default ReportsMenu;