import affiliateService from '../../utils/services/affiliate';
import constants from '../../constants/app-constants';

export const newAffiliate = affiliateObj => dispatch => {
  return new Promise((resolve, reject) => {
    affiliateService.newAffiliate(affiliateObj)
      .then(res => {
        dispatch({
          type: constants.admin.AFFILIATE_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.AFFILIATE_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateAffiliate = (affiliateObj) => {
  return function(dispatch){
    affiliateService.updateAffiliate(affiliateObj)
      .then(function(res){
        dispatch({
          type: constants.admin.AFFILIATE_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.AFFILIATE_EDIT,
          payload: err.response
        });
      });
  };
};

export const getAffiliate = (affiliateId) => {
  return function(dispatch){
    affiliateService.getAffiliate(affiliateId)
      .then(function(res){
        dispatch({
          type: constants.admin.AFFILIATE_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.AFFILIATE_GET,
          payload: err.response
        });
      });
  };
};