import request from '../wrappers/axios';
import config from '../../config/config';

class CountryCodeService {

  getPage(query , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/countryCode/page${extendedQuery}`,
      method: 'get',
      params: query
    }, true, 500);
  }

  new (data) {
    return request({
      url: `${config.API.BASEURL}/countryCode`,
      method: 'post',
      data: data
    }, true, 500);
  }

  update (data) {
    return request({
      url: `${config.API.BASEURL}/countryCode/${data._id}`,
      method: 'put',
      data: data
    }, true, 500);
  }

  delete (id) {
    return request({
      url: `${config.API.BASEURL}/countryCode/${id}`,
      method: 'delete'
    }, true, 500);
  }
}

export default new CountryCodeService();