import config from '../../../config/config';

import ReactGA from 'react-ga';
ReactGA.initialize(config.ANALYTICS.GA.ID);

export const trackSinglePage = (page) => {
  ReactGA.set({
    page: page
  });
  ReactGA.pageview(page);
};

export default (location) => {
  if (location.action === 'POP') {
    ReactGA.set({
      page: window.location.pathname + location.pathname
    });
    ReactGA.pageview(window.location.pathname + location.pathname);
  }
};