import React from 'react';
import userService from '../../../../utils/services/user';
import imageService from '../../../../utils/services/image';
import trackingService from '../../../../utils/services/tracking';
import store from '../../../../stores/store';
import imageUploadHelper from '../../../../utils/helpers/imageUpload';
import {updateLoginCtx} from '../../../../actions/index';
import config from '../../../../config/config';



class ProfileImageUpdate extends React.Component{
  
  constructor(){
    super();
    this.state = {
      previewUrl : false,
      uploadError: null
    };
  }
  
  imagePreview() {
    this.setState({previewUrl: window.URL.createObjectURL(document.getElementById('file').files[0])});
    document.getElementById('file-preview').value = document.getElementById('file').files[0].name;
  }

  clearPreview () {
    this.setState({previewUrl: ''});
    document.getElementById('file-preview').value = '';
  }
  
  handleChange(){
    var that = this;
    var fileInput = document.getElementById('file');

    that.clearPreview ();
    that.setState({
      uploadError: null
    });


    //check image is valid size
    if(!imageUploadHelper.imageSizeIsValid(document.getElementById('file').files[0].size,config.IMG.UPLOAD_LIMIT)){
      this.setState({
        uploadError: 'Maximum upload limit is 2MB'
      });
      document.getElementById('file').value = '';
      return;
    }


    //check image is square
    if (fileInput.files[0]) {
      var _URL = window.URL || window.webkitURL;
      var image = new Image();
      image.onload = function() {
        if (this.width < 150) {
          fileInput.val = '';
          that.setState({
            uploadError: 'Image must have dimensions of at least 150x150.'
          });
          document.getElementById('file').value = '';
          that.clearPreview();
          return;
        }
      };

      image.src = _URL.createObjectURL(fileInput.files[0]);
    }

    //check image is valid format and then load preview
    if(imageUploadHelper.imageIsValid(fileInput)){
      that.imagePreview();
    }else{
      document.getElementById('file').value = '';
      that.setState({
        uploadError: 'Unsupported format'
      });
      document.getElementById('file').value = '';
      return;
    }
  }
  
  triggerFileOpen(){
    document.getElementById('file').click();
  }
  
  handleSubmit(e) {
    e.preventDefault();
    var that = this;


    var fileInput = document.getElementById('file');

    if(!fileInput.files.length){
      that.setState({
        uploadError: 'Please upload an image first'
      });
      that.clearPreview();
      document.getElementById('file').value = '';
      return;
    }

    if(imageUploadHelper.imageIsValid(fileInput)){
      var data = new FormData();
      data.append('file', document.getElementById('file').files[0]);
  
      imageService.uploadImage(data)
        .then(function(res){
          //update client with new  info
          userService.updateUser(that.props.loginCtx._id,{ imageId: res.data.fileId})
            .then(function(){
              userService.getCurrent()
                .then(function(res){
                  that.props.toggleModal();
                  store.dispatch(updateLoginCtx(res.data));
                  trackingService.profileUpdate(res.data);
                });
            })
            .catch(function(err){
              that.setState({
                uploadError: err.response.data.message
              });
            });
        })
        .catch(function(err){
          that.setState({
            uploadError: err.response.data.message
          });
        });
    }else{
      that.setState({
        uploadError: 'Unsupported image format'
      });
    }
  }

  userProfilePic(domain){

    var url = domain;

    const divStyle = {
      width: '100%',
      height: '100%',
      backgroundSize:'cover',
      backgroundImage: 'url(' + url + ')',
      backgroundPosition: 'center'
    };
  
    return <div id="profile-pic" className="profile-pic" style={divStyle}></div>;
  }

  closeModal(e) {
    e.preventDefault();
    this.props.toggleModal();
  }

  render(){
    return (
      <div id="image-upload-preview">
        <span  onClick={this.closeModal.bind(this)} className="close-modal">
          <i className="fa fa-times"></i>
        </span>
        <div className="grid-100">
          <h3 className="text-center">Update Profile Picture</h3>
        </div>
        <div className="errors text-center">
          { this.state.uploadError }
        </div>
        <div className="grid-100">
          <p className="preview-text">Preview</p>
          <div className="profile-pic-holder">
            { this.state.previewUrl ? this.userProfilePic(this.state.previewUrl) : null }
          </div>
        </div>
        <div className="grid-100">
          <form id="upload-form" className="form" onSubmit={this.handleSubmit.bind(this)} accept="image/png, image/jpeg , image/gif ,image/jpg ">
            <div className="file-controller-holder">
              <label className="file-controller" onChange={this.handleChange.bind(this)} >
                <input id="file" type="file" className=""/>
                <input id="file-preview" type="text" disabled="true" onClick={this.triggerFileOpen.bind(this)}/>
                <span><i className="fa fa-cloud-upload"></i></span>
              </label>
            </div>
            <div className="text-center upload-bottom">
              <p>Max size 2mb</p>
              <button id="upload" type="submit" className="primary">Save</button><br />
              <button onClick={(e) => this.closeModal(e)} className="secondary-v2">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default ProfileImageUpdate;