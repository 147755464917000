import request from '../wrappers/axios';
import config from '../../config/config';

class EmailTemplateService {

  getEmailTemplates(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/emailTemplate/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getEmailTemplateById(id){
    return request({
      url: `${config.API.BASEURL}/emailTemplate/${id}`,
      method: 'get'
    });
  }

  deleteEmailTemplateById(id){
    return request({
      url: `${config.API.BASEURL}/emailTemplate/${id}`,
      method: 'delete'
    });
  }

  getEmailTemplateByClient(clientId) {
    return request({
      url: `${config.API.BASEURL}/emailTemplate/client/${clientId}`,
      method: 'get',
      data: clientId
    });
  }

  updateEmailTemplate(emailTemplate){
    return request({
      url: `${config.API.BASEURL}/emailTemplate`,
      method: 'patch',
      data: emailTemplate
    }, true, 500);
  }

  newEmailTemplate(emailTemplate){
    return request({
      url: `${config.API.BASEURL}/emailTemplate`,
      method: 'post',
      data: emailTemplate
    }, true, 500);
  }

  copyEmailTemplate(id,emailTemplate){
    return request({
      url: `${config.API.BASEURL}/emailTemplate/${id}/copy`,
      method: 'post',
      data: emailTemplate
    }, true, 500);
  }
}

export default new EmailTemplateService();