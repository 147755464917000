const filterPlatforms = (data) => {

  let formattedPlatforms = { page: [] };
    let channels;
    if(data && Array.isArray(data.page)) {
      channels = data.page.filter((channel) => {
        if(channel.socialPlatform !== 'GOOGLE') {
          return channel;
        }
      });
    }
    
    if(channels && Array.isArray(channels)) {
      formattedPlatforms['page'] = channels;
    }

    return formattedPlatforms;
}

export default filterPlatforms;