import UserManagementList from './List';
import UserManagementCreate from './Create';
import UserManagementEdit from './Edit';

const UserManagementIndex = {
  UserManagementList, 
  UserManagementCreate,
  UserManagementEdit
};

export default UserManagementIndex;