import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as rewardActions from '../../../../actions/admin/rewardActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    rewardObject: store.adminReward.requests
  };
})



class RewardCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      rewardDetails = {
        clientId:'',
        name:'',        
        type: '',
        giftCardReward: false        
      }
    } = props;

    this.state = {
      rewardDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      client: null      
    };

    this.onClientChange = this.onClientChange.bind(this);    
  }

  onChange(name, {target}) {
    const { rewardDetails } = this.state;
    _.set(rewardDetails, name, (target.type === 'checkbox') ? target.checked : target.value);
    this.setState({rewardDetails});
  }

  componentWillUpdate(nextProps){
    if(this.props.rewardObject !=nextProps.rewardObject){
      if(_.get(nextProps,'rewardObject.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.rewardObject.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const { rewardDetails, errors } = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] ) {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        break;
      }
    }

    if( !checkErrors ){
      this.props.dispatch(rewardActions.newReward(rewardDetails))
        .then(created => browserHistory.push({ pathname: `/admin/reward/${created._id}` , state: { fromCreatePage: true } }));

    }
    
  }

  validateForm() {
    const { rewardDetails, errors } = this.state;

    errors[ 'clientId' ] = validator.isEmpty(rewardDetails.clientId);
    errors[ 'name' ] = validator.isEmpty( rewardDetails.name );
    errors[ 'type' ] = validator.isEmpty( rewardDetails.type );
    
    return this.setState({ errors });
  }

  onClientChange(e){
    this.setState({client: e.value});
    const {rewardDetails} = this.state;
    _.set(rewardDetails, 'clientId', e.value);
    this.setState({rewardDetails});
  }

  render (){
    const { rewardDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      clientId,
      name = '',
      type = '',
      giftCardReward = false     
    } = rewardDetails;

    return (
      <div className="reward-new-reward">
        <h3>Create reward</h3>
        <form  className="c-form--flex">

          <ClientsDropbox  
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId}
          />

          <FormElement
            label='Client Id'
            name='clientId'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors.clientId}
            id='form-clientId'
            required
          />

          <FormElement
            label='Name'
            name='name'
            type='text'
            onChange={this.onChange.bind(this, 'name')}
            value={name}
            error={errors.name}
            id='form-name'
            required
          />

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            error={errors.type}
            id='form-type'
            required
          >
            <option value=""> </option>
            <option value="discount">discount</option>
            <option value="batch-discount">batch-discount</option>
          </FormElement>

          <FormElement
            label='Gift Card'
            name='giftCardReward'
            type='checkbox'
            onChange={this.onChange.bind(this, 'giftCardReward')}
            checked={giftCardReward}
            error={errors.giftCardReward}
            id='form-active'
          />


        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Create</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default RewardCreate;