import rewardPoolService from '../../utils/services/rewardPool';
import constants from '../../constants/app-constants';

export const newRewardPool = obj => dispatch => {
  return new Promise((resolve, reject) => {
    rewardPoolService.newRewardPool(obj)
      .then(res => {
        dispatch({
          type: constants.admin.REWARD_POOL_NEW,
          payload: res.data
        });

        resolve(res.data);
      })
      .catch(err => {
        dispatch({
          type: constants.admin.REWARD_POOL_NEW,
          payload: err.response
        });
        reject(err.response);
      });
  });
};

export const updateRewardPool = (obj) => {
  return function(dispatch){
    rewardPoolService.updateRewardPool(obj)
      .then(function(res){
        dispatch({
          type: constants.admin.REWARD_POOL_EDIT,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_POOL_EDIT,
          payload: err.response
        });
      });
  };
};

export const getRewardPool = (id) => {
  return function(dispatch){
    rewardPoolService.getRewardPool(id)
      .then(function(res){
        if(res.data){
          res.data.limitPostReward = res.data.postRewardPerUser ? true : false;
          res.data.limitFriendPostReward = res.data.friendPostRewardPerUser ? true : false;
        }
        
        dispatch({
          type: constants.admin.REWARD_POOL_GET,
          payload: res.data
        });
      })
      .catch(function(err){
        dispatch({
          type: constants.admin.REWARD_POOL_GET,
          payload: err.response
        });
      });
  };
};
