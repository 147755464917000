import constants from '../../constants/app-constants';

const initialState = {
  paymentMethods: null
};

const UserPaymentMethod = (state = initialState , action) => {
  switch(action.type) {
  case constants.user.PAYMENTMETHOD_POPULATE : {
    return {...state, paymentMethods: action.payload};
  }

  default: {
    return state;
  }
  }
};

export default UserPaymentMethod;