import React from 'react';

import { setTable } from '../../../../utils/helpers/table';

const withdrawalRequestList = (props) => {
  return(
    setTable(props.withdrawalRequests , props.tableKeysArray , props.rowEventListener , {id: 'withdrawal-request-table-admin' , className: 'report-table responsive'})
  );
};

export default withdrawalRequestList;