import request from '../wrappers/axios';
import config from '../../config/config';

class DynamicPageService {

  getPageSettings(pageId){
    return request({
      url: `${config.API.BASEURL}/dynamicPage/${pageId}`,
      method: 'get'
    }, true, 500);
  }
}

export default new DynamicPageService();