import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export default function ChartDataLine( { ...props } ) {

  const { title, chartData, type, chartDate, className } = props;

  const  renderLineChart = () => {

    const tooltipColor = '#485465', typography = ' \'Montserrat\', sans-serif';

    var options = {  
      maintainAspectRatio: true, 
      responsive: true,
      legend: {
        display: false,
        position: 'right'
      },
      scales: {
        xAxes: [{ gridLines: { display:false, drawBorder: true }, type: 'time', time: { unit: 'day', displayFormats: { day: 'DD MMM' } } }],
        yAxes: [{ gridLines: { display:true, drawBorder: false } }]
      },
      tooltips: {
        enabled: true,
        mode: 'nearest',
        titleFontFamily: typography,
        titleFontStyle: 'lighter',
        bodyFontFamily: typography,
        backgroundColor: tooltipColor,
        custom: function(tooltip) {
          if (!tooltip) return;
          tooltip.cornerRadius = 1;
        },
        callbacks: {
          label: function (tooltipItems, data) {
            return data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel;
          }
        }
      },
      // https://chartjs-plugin-datalabels.netlify.com/
      plugins: {
        datalabels: {
          display: false
        }
      }
    };

    return(  
      <Line data={chartData} options={options} />
    );
  };

  return (
    <div className={className}>

      <div className="label">
        <span>{title}</span>
        <span className="date-range">{chartDate}</span>
      </div>

      <div className="lineChart">
        { type === 'LineChart' && renderLineChart() }  
      </div>    
        
    </div>
  );

}