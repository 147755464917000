import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import config from '../../../../config/config';
import UpdateLogo from '../../pages/updateLogo/UpdateLogo.jsx';
import * as profileActions from '../../../../actions/client/profileActions.jsx';

const customModalStyle = {
  content : {
    'width': '360px',
    'left': '0',
    'right': '0',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'height': '500px'
  }
};

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    modalIsOpen: store.clientProfile.modalIsOpen
  }
})

class Profile extends React.Component{

  openModal (e) {
    e.preventDefault();
    this.props.dispatch(profileActions.toggleProfileModal(!this.props.modalIsOpen));
  }

  render(){
    return(
      <div className="grid-parent profile">
        <div className='profile-pic-holder grid-20'>
          <img className="profile-pic" src={this.props.clientObj.imageId ? `${config.IMG.SRC}/${this.props.clientObj.imageId.substring(0,6)}/${this.props.clientObj.imageId}` : `/images/holding-logo.png` } alt="profile image"/>
          <a onClick={(event) => {this.openModal(event);}} className="edit-image-button">
            <i className="fa fa-pencil"></i>
          </a>
          <Modal contentLabel={'Profile'} isOpen={this.props.modalIsOpen}
                 shouldCloseOnOverlayClick={true}
                 style={customModalStyle}
          >
            <UpdateLogo />
          </Modal>
        </div>
        <div className="grid-desktop-60 info-holder">
          <p>
            <span className="username">{this.props.loginCtx.firstName} {this.props.loginCtx.lastName}</span><br />
            <span className="company-name">{this.props.clientObj.name}</span>
          </p>
          <p><span className="territory">Region</span><br />
            <span className="company-territory">{this.props.clientObj.location}</span>
          </p>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default Profile;

