import constants from '../../constants/app-constants';

const initialState = {
  userObj: null,
  userWallet: null,
  userWalletWithBalance: null
}

const User = (state = initialState , action) => {
  switch(action.type){
  case constants.user.USEROBJ_GET: {
    return {...state, userObj: action.payload};
  }

  case constants.user.USERWALLET_GET: {
    return {...state, userWallet: action.payload};
  }

  case constants.user.USERWALLETWITHBALANCE_GET: {
    return {...state, userWalletWithBalance: action.payload};
  }

  default: {
    return state;
  }
  }
};

export default User;