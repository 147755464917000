import React from 'react';
import {Polar} from 'react-chartjs-2';

class ChartShare extends React.Component {
  render () {
    return (
    <div>
      <div className="text-center"><span className="big-titles">Shares</span></div>
      <Polar ref="polarChart" data={this.props.data} options={this.props.options} />
    </div>
    );
  }
}

export default ChartShare;