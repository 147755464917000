import EmailTemplateList from './List';
import EmailTemplateCreate from './Create';
import EmailTemplateEdit from './Edit';
import EmailTemplateCopy from './Copy';

const EmailTemplateIndex = {
  EmailTemplateList, 
  EmailTemplateCreate,
  EmailTemplateEdit,
  EmailTemplateCopy
};

export default EmailTemplateIndex;