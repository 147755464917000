import constants from '../../constants/app-constants';

const Client = (state = {}, action) => {
  switch(action.type){
  case constants.client.CLIENTOBJ_GET: {
    return {...state, clientObj: action.payload};
  }
  case constants.client.CLIENTOBJ_UPDATE: {
    return {...state, clientObj: action.payload};
  }
  default: {
    return state;
  }
  }
}

export default Client;