import request from '../wrappers/axios';
import config from '../../config/config';

class KeyEmailTemplateTypeService {

  getKeyEmailTemplateTypePage(query = '' , extendedQuery = ''){
    return request({
      url: `${config.API.BASEURL}/keyEmailTemplateType/page${extendedQuery}`,
      method: 'get',
      params: query
    });
  }

  getKeyEmailTemplateType(query = ''){
    return request({
      url: `${config.API.BASEURL}/keyEmailTemplateType`,
      method: 'get',
      params: query
    });
  } 

  getKeyEmailTemplateTypeById(id){
    return request({
      url: `${config.API.BASEURL}/keyEmailTemplateType/${id}`,
      method: 'get'
    });
  }

  updateKeyEmailTemplateType(keyEmailTemplateType){
    return request({
      url: `${config.API.BASEURL}/keyEmailTemplateType/${keyEmailTemplateType._id}`,
      method: 'put',
      data: keyEmailTemplateType
    }, true, 500);
  }

  newKeyEmailTemplateType(keyEmailTemplateType){
    return request({
      url: `${config.API.BASEURL}/keyEmailTemplateType`,
      method: 'post',
      data: keyEmailTemplateType
    }, true, 500);
  }
}

export default new KeyEmailTemplateTypeService();